import React, { useState } from "react";
import styled, { css } from "styled-components";
import { navigate, useMatch, useParams } from "@reach/router";
import { ValueType } from "react-select";
import { InMemoryCache } from "apollo-cache-inmemory";
import {
  SinglePageSampleBar,
  SinglePageSampleBarProps,
} from "./SinglePageSampleBar";
import { PlusIcon } from "../../../images/icons/PlusIcon";
import { EditIcon } from "../../../images/icons/EditIcon";
// import { SearchBar } from "./SearchBar";
import { styledTheme } from "../../../theme/theme";
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import {
  EntityStatus,
  ProjectWithAllSamplesDocument,
  useAddSampleToProjectMutation,
  UserProjectDocument,
} from "../../../generated/graphql";
import { updateForMutationsWithOnlyServerSideLogic } from "../../../utils/helpers/updateForMutationsWithOnlyServerSideLogic";
import { useSideBarsContext } from "../../../utils/hooks/useSideBarsState";
import { LeftBlueExpandIcon } from "../../../images/icons/LeftBlueExpandIcon";
import { RightBlueExpandIcon } from "../../../images/icons/RightBlueExpandIcon";
import { Label } from "../../common/Label/Label";
import { useActionContext } from "../../../utils/hooks/useActionContext";
import { ROUTES } from "../../../constants/routeConstants";
import { Spinner } from "../../common/Spinner/Spinner";
import { useUserRolesContext } from "../../../utils/hooks/useUserRolesContext";

// TODO when implementing PM view SearchBar need to be updated to the current design

export const NavBar = (props: NavBarPropsT): JSX.Element => {
  const { projectsList, samples, selectedSample, onSampleClick } = props;
  const { navBarOpen, setNavBarOpen, setSideDrawboardOpen } =
    useSideBarsContext();
  const { setSelectedEntity } = useActionContext();
  const { useUserPermissions } = useUserRolesContext();

  const addNewPathIsEnabled = useUserPermissions("addNewPath");
  const goToProjectDataIsEnabled = useUserPermissions("goToProjectData");

  const [showSpinner, setShowSpinner] = useState(false);

  const [addSampleToProjectMutation] = useAddSampleToProjectMutation();

  const projectsDropdown = projectsList.map((projectItem) => ({
    value: projectItem.id,
    label: projectItem.name,
  }));

  let urlParams = useParams();

  if (!urlParams) {
    urlParams = (useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_EVENT) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_SERVICE) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_WORKFLOW) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE)) as {
      uri: string;
      path: string;
      [param: string]: string;
    };
  }

  let dropdownDefaultValue;
  const selectedProject = projectsList.find(
    (project) => project.id === urlParams.projectId
  );

  if (selectedProject) {
    dropdownDefaultValue = {
      value: selectedProject.id,
      label: selectedProject.name,
    };
  }

  const handleDropdownChange = async (
    selectedValue: ValueType<{ label: string; value: string }, boolean>
  ): Promise<void> => {
    const { value } = selectedValue as { label: string; value: string };
    if (value) {
      if (value !== selectedProject?.id) {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
      }

      const selProj = projectsList.find((project) => project.id === value);

      if (selProj) {
        await navigate(
          `/projects/${value}/timeline/samples/${selProj.samples[0].id}`
        );
      }
    }
  };

  const newSampleClick = async (): Promise<void> => {
    if (!addNewPathIsEnabled) {
      return;
    }

    setShowSpinner(true);
    const options = {
      variables: { projectSampleInput: { projectId: urlParams.projectId } },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: urlParams.projectId },
        },
        {
          query: ProjectWithAllSamplesDocument,
          variables: { id: urlParams.projectId },
        },
      ],
      awaitRefetchQueries: true,
      update(cache: InMemoryCache, { data }: any) {
        updateForMutationsWithOnlyServerSideLogic(
          { id: urlParams.projectId },
          UserProjectDocument,
          "Project",
          "AddSampleToProject",
          cache,
          data,
          "samples"
        );
      },
    };

    await addSampleToProjectMutation(options);
    setShowSpinner(false);
  };

  const configuratorOnClick = async (): Promise<void> => {
    if (!goToProjectDataIsEnabled) {
      return;
    }

    setSideDrawboardOpen(false);
    await navigate(
      `/projects/${urlParams.projectId}/configurator/samples/${selectedSample}/configurator`
    );
  };

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <Container open={navBarOpen}>
        <TopContainer open={navBarOpen}>
          <ProjectContainer open={navBarOpen}>
            {navBarOpen ? (
              <>
                <Breadcrumbs />
                <Dropdown
                  options={projectsDropdown}
                  defaultValue={dropdownDefaultValue}
                  onChange={handleDropdownChange}
                />
              </>
            ) : (
              <StyledLabel label={dropdownDefaultValue?.label || ""} />
            )}
          </ProjectContainer>
          {/* <SearchBar /> */}
          {navBarOpen && (
            <>
              <SectionHeader>PROJECT PATHS</SectionHeader>
              <SamplesContainer>
                {samples
                  .filter((sample) => sample.status === EntityStatus.Ongoing)
                  .map((sample) => (
                    <SinglePageSampleBar
                      key={sample.id}
                      id={sample.id}
                      percent={sample.percent}
                      name={sample.name}
                      active={sample.id === selectedSample}
                      onClick={(sampleId: string): void =>
                        onSampleClick(sampleId)
                      }
                      status={sample.status}
                    />
                  ))}
              </SamplesContainer>
              {/* <SectionHeader>SAMPLES TO RUN</SectionHeader> */}
              <SamplesContainer>
                {samples
                  .filter((sample) => sample.status !== EntityStatus.Ongoing)
                  .map((sample) => (
                    <SinglePageSampleBar
                      key={sample.id}
                      id={sample.id}
                      percent={sample.percent}
                      name={sample.name}
                      active={sample.id === selectedSample}
                      onClick={(sampleId: string): void =>
                        onSampleClick(sampleId)
                      }
                      status={sample.status}
                    />
                  ))}
              </SamplesContainer>
            </>
          )}
        </TopContainer>
        <BottomContainer>
          <BottomButton
            onClick={newSampleClick}
            disabled={!addNewPathIsEnabled}
          >
            <IconContainer>
              <PlusIcon color={styledTheme.newColors.secondary.basic} />
            </IconContainer>
            {navBarOpen && <ButtonText>Add a new path</ButtonText>}
          </BottomButton>
          <StyledLine />
          <BottomButton
            onClick={configuratorOnClick}
            disabled={!goToProjectDataIsEnabled}
          >
            <IconContainer>
              <EditIcon color={styledTheme.newColors.secondary.basic} />
            </IconContainer>
            {navBarOpen && <ButtonText>Go to the project data</ButtonText>}
          </BottomButton>
        </BottomContainer>
        <ExpandIconContainer
          onClick={(): void => setNavBarOpen(!navBarOpen)}
          open={navBarOpen}
        >
          {navBarOpen ? <LeftBlueExpandIcon /> : <RightBlueExpandIcon />}
        </ExpandIconContainer>
      </Container>
    </>
  );
};

type ProjectListT = {
  id: string;
  name: string;
  samples: {
    id: string;
    name: string;
  }[];
};

type NavBarPropsT = {
  projectsList: ProjectListT[];
  samples: SinglePageSampleBarProps[];
  selectedSample?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSampleClick?: any;
};

const Container = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  border-right: 1px solid
    ${(props): string => props.theme.newColors.grayscale.bordersInside};
  position: relative;
  // width: 337px;
  width: ${({ open }): string => (open ? "337px" : "72px")};
  flex-shrink: 0;
`;

const TopContainer = styled.div<{ open: boolean }>`
  background-color: ${styledTheme.newColors.grayscale.white};
  padding: 0 24px;
  //margin-bottom: 32px;
  overflow-y: auto;
  height: 100%
    ${({ open }) =>
      !open &&
      css`
        display: flex;
        align-items: center;
        & > div {
          margin-bottom: 0;
          width: 1.5rem;
        }
      `};
`;

const ProjectContainer = styled.div<{ open: boolean }>`
  margin-bottom: 16px;
  padding-top: 32px;
  padding-bottom: 16px;

  ${({ open }) =>
    open &&
    css`
      border-bottom: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
    `}

  > div:first-child {
    margin-bottom: 8px;
  }
`;

const StyledLabel = styled(Label)`
  writing-mode: vertical-rl;
  transform: scale(-1);
  width: 18px;

  & label {
    height: 149px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;

const SectionHeader = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${styledTheme.colors.lightText};
  margin-bottom: 8px;
  margin-top: 24px;
`;

const SamplesContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }
`;

const BottomContainer = styled.div`
  border-top: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
`;

const BottomButton = styled.div<{
  disabled: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1.5rem 0 1.5rem 1.5rem;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      div {
        color: ${styledTheme.newColors.grayscale.placeholder};
      }

      path {
        fill: ${styledTheme.newColors.grayscale.placeholder};
      }
    `}
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  flex-grow: 1;
  display: flex;
  margin-left: 8px;
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.newColors.secondary.basic};
`;

const StyledLine = styled.div`
  border-bottom: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
`;

const ExpandIconContainer = styled.div<{ open: boolean }>`
  cursor: pointer;
  top: 6.2rem;
  left: ${({ open }): string => (open ? "320px" : "54px")};
  position: absolute;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${styledTheme.shadows.icons.expandIcon};
  z-index: 1;

  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover circle {
    fill: ${styledTheme.newColors.primary.hover};
    transition: fill linear 0.2s;
  }

  & > svg:active circle {
    fill: ${styledTheme.newColors.primary.darkestTint};
    transition: fill linear 0.2s;
  }
`;
