import React from "react";
import styled from "styled-components";

import { PlatformLogo } from "../../images/PlatformLogo";

function ServerError() {
  return (
    <Container>
      <ErrorBox>
        <Logo>
          <PlatformLogo color="white" />
        </Logo>
        <ContentBox>
          <Title>An error occurred</Title>
          <Message>
            We&apos;re sorry, but there was a problem connecting to the server.
            Please refresh the page again or try again later.
          </Message>
        </ContentBox>
      </ErrorBox>
    </Container>
  );
}

export default ServerError;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
`;

const ErrorBox = styled.div`
  width: 400px;
  border: 1px solid #d1d5d8;
  box-shadow: 0px 1px 0px #d1d5d8;
  border-radius: 8px;
`;

const Logo = styled.div`
  background-color: #232f3c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px 8px 0 0;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 32px 72px;

  & > * {
    margin: 0;
  }
`;

const Title = styled.p`
  letter-spacing: -0.02em;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 6px;
`;

const Message = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #77808e;
`;
