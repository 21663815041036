import styled from "styled-components";
import * as React from "react";
import { ServiceCardHeader } from "./ServiceCardHeader";
import { CardSeparator } from "./CardSeparator";
import { ServicePill } from "./ServicePill";
import { ButtonType } from "../Button/ButtonType";
import { AddIcon } from "../../../images/icons/AddIcon";
import { RemoveIcon } from "../../../images/icons/RemoveIcon";
import { Button } from "../Button/Button";
import { Service } from "../../Projects/Configurator/Samples/ServicePackages/helpers/TypesForServicePackages";
import { WorkflowMetadata } from "../../../utils/types/WorkflowMetadata";

export type BasicServiceCardProps = {
  title: string;
  id: string;
  services: Service[];
  selected?: boolean;
  metaData: WorkflowMetadata;
  disabled: boolean;
};

export type OnClickAction = {
  onClick: (metaData: WorkflowMetadata) => void;
};

export const BasicServiceCard = (
  props: BasicServiceCardProps & OnClickAction
) => {
  const { id, title, services, selected, onClick, metaData, disabled } = props;
  const count = services.length;

  const onButtonClicked = () => {
    onClick(metaData);
  };

  return (
    <Container>
      <Content>
        <Header>
          <ServiceCardHeader title={title} description={`${count} Services`} />
        </Header>
        <IncludedServices>
          <CardSeparator text={"What's included"} />
          <ServicesList>
            {services.map((service) => (
              <ServicePill
                key={id + service.id}
                name={service.name}
                tip={service.tip}
                disabled={service.disabled}
                selected={service.selected}
              />
            ))}
          </ServicesList>
        </IncludedServices>
      </Content>
      <Footer>
        <StyledButton
          label={selected ? "Remove" : "Add"}
          type={selected ? ButtonType.Tertiary : ButtonType.Secondary}
          icon={selected ? <RemoveIcon /> : <AddIcon />}
          onClick={onButtonClicked}
          disabled={disabled}
        />
      </Footer>
    </Container>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  background: ${({ theme }) => theme.newColors.grayscale.white};

  border: 1px solid ${({ theme }) => theme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 4px;

  width: 290px;
  height: fit-content;
`;

const Content = styled.div`
  padding: 24px 22.5px 16px 22.5px;
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const IncludedServices = styled.div`
  padding: 0 2px;
`;

const ServicesList = styled.div`
  margin-top: 16px;

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Footer = styled.div`
  padding: 12px 23px;

  background: ${({ theme }) => theme.newColors.grayscale.background};
  border-radius: 0px 0px 3px 3px;
`;
