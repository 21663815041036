import * as d3 from "d3";
import moment from "moment";

export const ZOOM_LEVELS = {
  DEFAULT: "DEFAULT",
  HIGHER: "HIGHER",
};

export const dayAxisDisplayByScale = 40;
export const weekAxisDisplayByScale = 5;
export const monthAxisDisplayByScale = 1.7;
export const quarterAxisDisplayByScale = 1;
export const defaultAxisHeight = 113;

const oneWeek = 1000 * 60 * 60 * 24 * 7;
const threeMonths = 1000 * 60 * 60 * 24 * 30.4 * 3;
const oneYear = 1000 * 60 * 60 * 24 * 30.4 * 12;

export const getMonthsAxis = (x: any) =>
  d3
    .axisBottom(x)
    .tickFormat(d3.timeFormat("%b"))
    .ticks(d3.timeMonth.every(1))
    .tickSize(20);

export const formatMonthsAxis = (monthsAxis: any) => {
  monthsAxis.select(".domain").remove();
  monthsAxis.selectAll("text").attr("y", 6).attr("x", 8);
};

export const getQuarterAxis = (x: any) =>
  d3
    .axisTop(x)
    .ticks(d3.timeMonth.every(3))
    .tickSize(defaultAxisHeight)
    .tickFormat((formatAttrib: any) => {
      const momentObj = moment(formatAttrib.getTime());
      return `Q${momentObj.quarter()} ${momentObj.format("YYYY")} `;
    });

export const formatQuarterAxis = (quarterAxis: any) => {
  quarterAxis.select(".domain").remove();
  quarterAxis.selectAll("text").attr("y", -46).attr("x", 8);
};

export const getWeekAxis = (x: any) => {
  const xDomain = x.domain();
  const xDomainLength =
    xDomain[0] && xDomain[1] && xDomain[1].valueOf() - xDomain[0].valueOf();
  if (xDomainLength > oneYear) {
    return d3
      .axisBottom(x)
      .ticks(d3.timeYear.every(1))
      .tickFormat(d3.timeFormat(""))
      .tickSize(0);
  }
  return d3
    .axisBottom(x)
    .tickFormat(d3.timeFormat("W%V"))
    .ticks(d3.timeMonday.every(1))
    .tickSize(10);
};

export const getProjectStartWeekAxis = (
  x: any,
  firstGhostEventStartDate: number
) => {
  const xDomain = x.domain();
  const xDomainLength =
    xDomain[0] && xDomain[1] && xDomain[1].valueOf() - xDomain[0].valueOf();
  if (xDomainLength > oneYear) {
    return d3
      .axisBottom(x)
      .ticks(d3.timeYear.every(1))
      .tickFormat(d3.timeFormat(""))
      .tickSize(0);
  }
  return d3
    .axisBottom(x)
    .ticks(d3.timeMonday.every(1))
    .tickFormat((thisDate) => {
      const startDiff = (thisDate as Date).valueOf() - firstGhostEventStartDate;
      const weeks = Math.round(startDiff / oneWeek);
      return `${weeks}PW`;
    })
    .tickSize(10);
};

export const formatWeekAxis = (weekAxis: any) => {
  weekAxis.select(".domain").remove();
  weekAxis.selectAll("text").attr("y", 2).attr("x", 8);
};

export const getDayAxis = (x: any) => {
  const xDomain = x.domain();
  const xDomainLength =
    xDomain[0] && xDomain[1] && xDomain[1].valueOf() - xDomain[0].valueOf();
  if (xDomainLength > threeMonths) {
    return d3
      .axisBottom(x)
      .ticks(d3.timeYear.every(1))
      .tickFormat(d3.timeFormat(""))
      .tickSize(0);
  }
  return d3
    .axisBottom(x)
    .tickFormat(d3.timeFormat("%e %a"))
    .ticks(d3.timeDay.every(1))
    .tickSize(10);
};

export const formatDayAxis = (dayAxis: any) => {
  dayAxis.select(".domain").remove();
  dayAxis.selectAll("text").attr("y", 2).attr("x", 4);
};

export const getYearAxis = (x: any) =>
  d3
    .axisTop(x)
    .ticks(d3.timeYear.every(1))
    .tickSize(defaultAxisHeight)
    .tickFormat((formatAttrib: any) => {
      const momentObj = moment(formatAttrib.getTime());
      return `${momentObj.format("YYYY")}`;
    });

export const formatYearAxis = (yearAxis: any) => {
  yearAxis.select(".domain").remove();
  yearAxis.selectAll("text").attr("y", -46).attr("x", 8);
};

export const getZoomedInLevel = (transformZoom: number): number => {
  let zoomedInLevel = 1;
  if (transformZoom > 2) {
    zoomedInLevel = 2;
  }
  if (transformZoom > 5) {
    zoomedInLevel = 4;
  }
  if (transformZoom > 10) {
    zoomedInLevel = 6;
  }
  if (transformZoom > 20) {
    zoomedInLevel = 10;
  }
  if (transformZoom > 40) {
    zoomedInLevel = 18;
  }
  return zoomedInLevel;
};

export const getOwnScale = (transformZoom: number): number => {
  return 0.5 / getZoomedInLevel(transformZoom);
};

export const getBlockHeight = (transformZoom: number): number => {
  const height = 100;
  return (height + 8) * getOwnScale(transformZoom);
};

export const getIsBlockVeryThin = (width: number): boolean => {
  return width < 10;
};

export const getMaxTextWidth = (ownScale: number, width: number): number => {
  const widthOfOtherUIElements =
    (getIsBlockVeryThin(width) ? -9 : 37) * ownScale;
  const availableWidth = width - widthOfOtherUIElements;
  return availableWidth;
};

export const getCurrentSize = (size: number, zoom: number) => {
  return size * getOwnScale(zoom) * 2;
};

export const getCurrentEventScale = (zoom: number) => {
  return getOwnScale(zoom) * 2;
};

export const getInsideIconPosition = (zoom: number) => {
  return getOwnScale(zoom) * 4;
};

export const getRXSize = (zoom: number) => {
  return getOwnScale(zoom) * 8;
};
