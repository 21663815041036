import * as React from "react";
import styled, { css } from "styled-components";

type TextFieldAutoHightProps = {
  title: string;
  value: string;
  editable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLDivElement>) => void;
  className?: string;
};

export const TextFieldAutoHight = (
  props: TextFieldAutoHightProps
): JSX.Element => {
  const { title, value, editable = true, onChange, onBlur, className } = props;

  return (
    <>
      <Container className={className}>
        <Title>{title}</Title>
        <ValueContainer
          contentEditable={editable}
          suppressContentEditableWarning
          editMode={editable}
          onChange={onChange}
          onBlur={onBlur}
        >
          {value}
        </ValueContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin: 0 1rem;
  padding: 1rem 0;
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.newColors.primary.basic};
  font-size: 0.8rem;
`;

const ValueContainer = styled.div<{
  editMode: boolean | undefined;
}>`
  border: solid 1px ${(props) => props.theme.newColors.grayscale.bordersInside};
  border-radius: 4px;
  padding: 9px;
  line-height: 1rem;

  ${({ editMode }) =>
    editMode &&
    css`
      min-width: calc(100% - 106px);

      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px ${(props) => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `}
`;
