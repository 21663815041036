import React, { useRef, useState } from "react";
import { Box } from "rebass";
import styled, { css } from "styled-components";
import { MenuDotsHorizontallyIcons } from "../../../../images/icons/MenuDotsHorizontallyIcons";
import useOnClickOutside from "../../../../utils/hooks/useOnClickOutside";
import { DotsDropdownMenuContent } from "./DotsDropdownMenuContent/DotsDropdownMenuContent";
import { ROLES } from "../../../../constants/roles";

interface DotsDropdownMenuProps {
  isMenuOpen: boolean;
  mainQuestionGroupId?: string;
  userRoleInSample?: ROLES;
}

export const DotsDropdownMenu = ({
  isMenuOpen,
  mainQuestionGroupId,
  userRoleInSample,
}: DotsDropdownMenuProps): JSX.Element => {
  const dropdownMenuRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownButtonRef: any = useRef(null);

  const [menuOpen, setMenuOpen] = useState(isMenuOpen);

  useOnClickOutside(dropdownMenuRef, (event) => {
    if (!dropdownButtonRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  });

  return (
    <>
      <Container>
        <DotsIconContainer
          ref={dropdownButtonRef}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <MenuDotsHorizontallyIcons />
        </DotsIconContainer>
        <Menu ref={dropdownMenuRef} isMenuOpen={menuOpen}>
          <DotsDropdownMenuContent
            closeMenu={(): void => setMenuOpen(false)}
            mainQuestionGroupId={mainQuestionGroupId}
            userRoleInSample={userRoleInSample}
          />
        </Menu>
      </Container>
    </>
  );
};

const DotsIconContainer = styled(Box)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.25rem !important;

  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover circle {
    fill: ${({ theme }): string => theme.newColors.grayscale.primaryGray};
    transition: fill linear 0.2s;
  }

  & > svg:active circle {
    fill: ${({ theme }): string => theme.newColors.grayscale.secondaryGray};
    transition: fill linear 0.2s;
  }
`;

const Container = styled(Box)`
  position: relative;
`;

const Menu = styled.nav<{ isMenuOpen: boolean }>`
  background: ${({ theme }): string => theme.newColors.grayscale.white};
  border-radius: 0.5rem;
  position: absolute;
  top: 2rem;
  right: 0;
  min-width: 240px;
  box-shadow: ${({ theme }): string => theme.shadows.userInputs.basicInside};
  border: 1px solid
    ${(props): string => props.theme.newColors.grayscale.bordersInside};
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 10;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `}
`;
