import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { Calendar2Icon } from "../../../images/icons/Calendar2Icon";
import { styledTheme } from "../../../theme/theme";
import { EditIcon } from "../../../images/icons/EditIcon";

export const ProjectHeaderSummary = (props: ProjectHeaderSummaryProps) => {
  const { name, lastActivity, projectId } = props;

  const handleEditIcon = (event: any) => {
    navigate(`projects/${projectId}/configurator`);
    event.stopPropagation();
  };

  return (
    <Container>
      <NameContainer>
        {name}
        {name !== "Add a new project" ? (
          <EditIconContainer onClick={handleEditIcon}>
            <EditIcon
              width={14}
              height={12}
              color={styledTheme.newColors.secondary.basic}
            />
          </EditIconContainer>
        ) : null}
      </NameContainer>

      {lastActivity ? (
        <TimeContainer>
          <Calendar2Icon />{" "}
          <LastActivityTitle>LAST ACTIVITY:</LastActivityTitle>{" "}
          <LastActivityValue>{lastActivity}</LastActivityValue>
        </TimeContainer>
      ) : null}
    </Container>
  );
};

type ProjectHeaderSummaryProps = {
  projectId?: string;
  name: string;
  lastActivity?: string;
};

const EditIconContainer = styled.div`
  display: inline-block;
  margin: 0 10px;
`;

const Container = styled.div``;

const NameContainer = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #141419;
  margin-bottom: 4px;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;

  svg {secondaryGray
    margin-top: -2px;
  }
`;

const LastActivityTitle = styled.span`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: -0.01em;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
  padding-left: 6px;
`;

const LastActivityValue = styled.span`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  letter-spacing: -0.01em;
  color: ${styledTheme.newColors.primary.basic};
  padding-left: 4px;
`;
