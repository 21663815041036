import * as React from "react";
import { SideBarsContext } from "../../contexts/SideBarsContext";

export const useSideBarsContext = () => {
  const context = React.useContext(SideBarsContext);

  if (context === undefined) {
    throw new Error("SideBarsContext not defined");
  }

  return context;
};
