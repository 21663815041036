import * as React from "react";
import styled from "styled-components";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";
import {
  SelectedElementType,
  SelectedQuestionPath,
} from "../../utils/types/ActionContextTypes";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { Button } from "../common/Button/Button";
import { DeleteIcon } from "../../images/icons/DeleteIcon";
import { Label } from "../common/Label/Label";
import {
  Question,
  QuestionGroup,
  QuestionSet,
  useRemoveQuestionsFromSampleMutation,
} from "../../generated/graphql";
import { FormQuestions } from "../Projects/Configurator/FormQuestionsContainer/FormQuestions";
import { Separator } from "./Elements/Separator";
import { styledTheme } from "../../theme/theme";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { EditIcon } from "../../images/icons/EditIcon";
import { projectSampleCacheUpdater } from "../../utils/helpers/dynamicForms/projectSampleCacheUpdater";

export const DynamicEditableFormWrapper = (
  props: DynamicEditableFormWrapperProps
) => {
  const {
    title,
    stepNumber,
    wrapped,
    wrapCardsCallback,
    goToNextGroupCallback,
    goTo1stSampleCallback,
    questionGroup,
    projectId,
    sampleId,
    isReport = false,
    workflowId,
    serviceId,
  } = props;
  const { saveCurrentSelectedEntityAndSet, setSelectedEntity, selectedEntity } =
    useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();

  const formQuestionsArray = FormQuestions();

  function getCurrentNumberOfQuestions() {
    const questions = questionGroup.questionSets;
    return questions.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.questions.length,
      0
    );
  }

  const [numberOfQuestions, setNumberOfQuestions] = React.useState(
    getCurrentNumberOfQuestions()
  );

  React.useEffect(() => {
    setNumberOfQuestions(getCurrentNumberOfQuestions());
  }, [questionGroup]);

  const [removeQuestionsFromSampleMutation] =
    useRemoveQuestionsFromSampleMutation();

  const handleFinishEditing = () => {
    setSelectedEntity(undefined);
    setSideDrawboardOpen(false);
  };

  const getSectionButtons = (): React.ReactNode[] => {
    if (goToNextGroupCallback) {
      return [
        <Button label="Finish Editing" onClick={handleFinishEditing} />,
        <Button label="Go to next section" onClick={goToNextGroupCallback} />,
      ];
    }
    if (goTo1stSampleCallback) {
      return [
        <Button label="Go to 1st sample" onClick={goTo1stSampleCallback} />,
      ];
    }
    return [<Button label="Finish Editing" onClick={handleFinishEditing} />];
  };

  const deleteSelectedQuestion = (
    questionSet: QuestionSet,
    questionItem: Question
  ) => {
    if (selectedEntity) {
      const { questionGroupId } =
        selectedEntity.selectedElementPath as SelectedQuestionPath;

      const options = {
        variables: {
          projectSampleInput: {
            projectId,
            sampleId,
            fromReport: isReport,
            workflowId,
            serviceId,
          },
          questionGroupId,
          questionSetId: questionSet.id,
          questionIds: [questionItem.id],
        },
        update(cache: InMemoryCache, mutationResult: FetchResult): void {
          projectSampleCacheUpdater(
            "RemoveQuestionsFromSample",
            cache,
            mutationResult,
            projectId,
            sampleId
          );
        },
      };

      removeQuestionsFromSampleMutation(options);
    }
  };

  const getFormQuestion = (
    id: string,
    questionObject: Question
  ): JSX.Element => {
    const formElement = formQuestionsArray.find(
      (question) => question.id === id
    );

    if (formElement) return formElement.createComponent(questionObject);
    return <></>;
  };

  const componentEditModeAvailable = (answerType: string): boolean => {
    const formElement = formQuestionsArray.find(
      (question) => question.id === answerType
    );
    return formElement?.editMode as boolean;
  };

  const handleOnQuestionEdit = (
    questionSet: QuestionSet,
    question: Question
  ): void => {
    setSideDrawboardOpen(true);
    saveCurrentSelectedEntityAndSet({
      selectedElementType: SelectedElementType.QUESTION,
      selectedElementPath: {
        projectId,
        sampleId,
        questionGroupId: questionGroup.id,
        questionId: question.id,
        questionSetId: questionSet.id,
        questionName: question.question,
        questionDescription: question.description || "",
        questionIsObligatory: question.isObligatory,
        fromReport: isReport,
        workflowId,
        serviceId,
      },
    });
  };

  const handleOnComponentEdit = (
    questionSet: QuestionSet,
    question: Question
  ): void => {
    // eslint-disable-next-line default-case
    switch (question.answer.answerType) {
      case "OneOfMany":
        setSideDrawboardOpen(true);
        saveCurrentSelectedEntityAndSet({
          selectedElementType: SelectedElementType.ONE_OF_MANY,
          selectedElementPath: {
            projectId,
            sampleId,
            questionGroupId: questionGroup.id,
            questionId: question.id,
            questionSetId: questionSet.id,
            questionName: question.question,
            questionChoices: question.choices || [],
          },
        });
        break;
    }
  };

  const droppableFormId = `FORM_ID_$$_${workflowId}_$$_${serviceId}`;

  return (
    <ContentSection
      title={title}
      stepNumber={stepNumber}
      wrapped={wrapped}
      wrapCardsCallback={wrapCardsCallback}
      buttons={getSectionButtons()}
    >
      <Droppable droppableId={droppableFormId}>
        {(droppableProvided, snapshot) =>
          numberOfQuestions > 0 ? (
            <FormContainer ref={droppableProvided.innerRef}>
              {questionGroup.questionSets.map(
                (questionSetItem, questionSetIdx) => (
                  <Draggable
                    key={questionSetItem.id}
                    draggableId={questionSetItem.id}
                    index={questionSetIdx}
                    isDragDisabled
                  >
                    {(draggableProvided) => {
                      if (questionSetItem.questions.length === 0) {
                        return (
                          <div
                            key={questionSetItem.id}
                            ref={draggableProvided.innerRef}
                          />
                        );
                      }

                      return (
                        <>
                          {questionSetItem.questions.map(
                            (questionItem, questionIdx) => (
                              <FormElementContainer
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={
                                  questionItem.question === "Path name"
                                    ? undefined
                                    : draggableProvided.draggableProps.style
                                }
                                key={questionItem.id}
                              >
                                <Label
                                  label={questionItem.question}
                                  onEditClick={(): void =>
                                    handleOnQuestionEdit(
                                      questionSetItem,
                                      questionItem
                                    )
                                  }
                                  edit
                                >
                                  {questionItem.description}
                                </Label>
                                <FormElement>
                                  {getFormQuestion(
                                    questionItem.answer.answerType,
                                    questionItem
                                  )}
                                  {/* <IconContainer {...provided.dragHandleProps}><DragIcon /></IconContainer> */}
                                  {componentEditModeAvailable(
                                    questionItem.answer.answerType
                                  ) && (
                                    <IconContainer
                                      onClick={(): void =>
                                        handleOnComponentEdit(
                                          questionSetItem,
                                          questionItem
                                        )
                                      }
                                    >
                                      <EditIcon
                                        width={18}
                                        height={18}
                                        color={
                                          styledTheme.newColors.secondary.basic
                                        }
                                      />
                                    </IconContainer>
                                  )}
                                  <IconContainer
                                    onClick={() =>
                                      deleteSelectedQuestion(
                                        questionSetItem,
                                        questionItem
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconContainer>
                                </FormElement>
                                {!(
                                  questionGroup.questionSets.length - 1 ===
                                    questionSetIdx &&
                                  questionSetItem.questions.length - 1 ===
                                    questionIdx
                                ) && <Separator />}
                              </FormElementContainer>
                            )
                          )}
                        </>
                      );
                    }}
                  </Draggable>
                )
              )}
              {droppableProvided.placeholder}
            </FormContainer>
          ) : (
            <NewDropZoneContainer>
              <NewDropZone
                ref={droppableProvided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                <NewDropZoneCenterDiv isDraggingOver={snapshot.isDraggingOver}>
                  DROP HERE
                </NewDropZoneCenterDiv>
              </NewDropZone>
              {droppableProvided.placeholder}
            </NewDropZoneContainer>
          )
        }
      </Droppable>
    </ContentSection>
  );
};

interface DynamicEditableFormWrapperProps {
  title: string;
  stepNumber: number;
  wrapped: boolean;
  questionGroup: QuestionGroup;
  wrapCardsCallback: () => void;
  goToNextGroupCallback?: () => void;
  goTo1stSampleCallback?: () => void;
  projectId: string;
  sampleId: string;
  isReport?: boolean;
  workflowId?: string;
  serviceId?: string;
}

const FormElementContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormElement = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const IconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
`;

const FormContainer = styled.div`
  margin-bottom: 1rem;
`;

const NewDropZoneContainer = styled.div`
  & > [data-rbd-placeholder-context-id] {
    display: none !important;
    height: 0;
  }
`;

const NewDropZone = styled.div<{ isDraggingOver: boolean }>`
  border: 1px
    ${(props) =>
      props.isDraggingOver
        ? `solid ${styledTheme.newColors.grayscale.secondaryGray}`
        : `dashed ${styledTheme.newColors.secondary.basic}`};

  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(82, 183, 159, 0.1);
`;

const NewDropZoneCenterDiv = styled.div<{ isDraggingOver: boolean }>`
  color: ${(props) =>
    props.isDraggingOver
      ? `${styledTheme.newColors.grayscale.secondaryGray}`
      : `${styledTheme.newColors.secondary.basic}`};
  padding: 6px 16px;
  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  border-radius: 4px;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;
