import * as React from "react";
import { BaseIconProps } from "./icons/BaseIconProps";
import { styledTheme } from "../theme/theme";

export const PlatformSign = ({
  width,
  height,
  color,
  ...props
}: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6321 24 24 18.6321 24 12C24 5.36791 18.6321 0 12 0C5.36791 0 0 5.36791 0 12C0 18.6321 5.36791 24 12 24ZM18.4767 5.13477C18.4572 6.28225 17.5431 7.17691 16.4151 7.17691H8.57715C8.5966 6.02942 9.5107 5.13477 10.6387 5.13477H18.4767ZM17.4068 10.9696C17.3873 12.0393 16.5899 12.9145 15.5397 13.0312H15.3258H9.91895L9.91895 13.0312C9.97729 12.7784 10.0356 12.5256 10.0551 12.2727V11.981C10.0551 11.8059 10.0551 11.6309 10.0162 11.4559C10.0162 11.2808 9.97729 11.1058 9.9384 10.9502H17.4068V10.9696ZM16.8235 18.8459C17.8348 18.7487 18.6323 17.8735 18.6323 16.8232H10.3859C9.37455 16.9399 8.57715 17.7957 8.57715 18.8459H16.8235ZM8.18825 11.086C8.30494 11.3778 8.36329 11.6889 8.36329 12.0001C8.36329 12.3697 8.28549 12.7003 8.14935 13.0309C7.76037 13.8867 6.88517 14.4701 5.89327 14.4701C5.75713 14.4701 5.62099 14.4507 5.5043 14.4312C4.5902 14.2951 3.83169 13.6533 3.53995 12.7586C3.44271 12.5058 3.40381 12.253 3.40381 11.9807C3.40381 11.7084 3.44271 11.4556 3.53995 11.2222C3.83169 10.347 4.59019 9.6857 5.52374 9.53011C5.64044 9.49121 5.77658 9.49121 5.89327 9.49121C6.90462 9.49121 7.76037 10.0941 8.14935 10.9499C8.14935 11.0082 8.1688 11.0666 8.18825 11.086Z"
        fill={color}
      />
    </svg>
  );
};

PlatformSign.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.white,
};
