import {
  FomSchemaRendererField,
  FormSchema,
  FormSchemaField,
  QuestionFormData,
} from "../../../utils/types/QuestionMetadata";

export type RequiredItemsT = {
  renderer: boolean;
  id: string;
};

const getRequiredItems = (formSchema: FormSchema): RequiredItemsT[] => {
  const requiredItems: RequiredItemsT[] = [];
  Object.keys(formSchema).forEach((id) => {
    const formSchemaRendererItem = formSchema[id] as FomSchemaRendererField;
    const formSchemaItem = formSchema[id] as FormSchemaField;
    if (
      formSchemaRendererItem.renderer &&
      formSchemaRendererItem.formSchemaItems.some(
        (formSchemaItemElement) => formSchemaItemElement.required
      )
    ) {
      requiredItems.push({ renderer: true, id });
    }
    if (
      !formSchemaRendererItem.renderer &&
      formSchemaItem.required &&
      formSchemaItem.visibility
    ) {
      requiredItems.push({ renderer: false, id });
    }
  });
  return requiredItems;
};

const getFilledRequiredItemsIds = (
  requiredItems: RequiredItemsT[],
  formData: QuestionFormData,
  formSchema?: FormSchema
): string[] => {
  const filledRequiredItemsIds: string[] = [];
  requiredItems.forEach((requiredItemsItem) => {
    if (requiredItemsItem.renderer) {
      const renderedFormSchemaItems =
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        formSchema[requiredItemsItem.id].formSchemaItems;
      const rendererRequiredItems = getRequiredItems(renderedFormSchemaItems);
      const filledRendererItemsIds = getFilledRequiredItemsIds(
        rendererRequiredItems,
        formData
      );
      if (rendererRequiredItems.length === filledRendererItemsIds.length) {
        filledRequiredItemsIds.push(requiredItemsItem.id);
      }
    }

    if (!requiredItemsItem.renderer && formData[requiredItemsItem.id]) {
      filledRequiredItemsIds.push(requiredItemsItem.id);
    }
  });
  return filledRequiredItemsIds;
};

export { getRequiredItems, getFilledRequiredItemsIds };
