import * as React from "react";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import { SideBarAction } from "./SideBarAction";
import { ProjectsIcon } from "../../images/icons/ProjectsIcon";
import { ReportsMenuIcon } from "../../images/icons/ReportsMenuIcon";
import { ROUTES } from "../../constants/routeConstants";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { TeamIcon } from "../../images/icons/TeamIcon";
import { useUserRolesContext } from "../../utils/hooks/useUserRolesContext";
import { HelpMenuIcon } from "../../images/icons/HelpMenuIcon";
import { TableIcon } from "../../images/icons/TableIcon";

type SideBarActionsProps = {
  isSideBarOpen: boolean;
};

export const SideBarActions = (props: SideBarActionsProps): JSX.Element => {
  const { isSideBarOpen } = props;
  const location = useLocation();
  const { setSelectedEntity } = useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();
  const { useUserPermissions } = useUserRolesContext();

  const isActiveLink = (route: string): boolean =>
    location.pathname.startsWith(route);

  const navigateToPage = (path: string): void => {
    navigate(path);
    setSelectedEntity(undefined);
    setSideDrawboardOpen(false);
  };

  const manageUsersIsEnabled = useUserPermissions("manageUsers");
  const reportsAreEnabled = useUserPermissions("accessReports");

  return (
    <Container>
      <SideBarAction
        name="Projects"
        isSideBarOpen={isSideBarOpen}
        icon={<ProjectsIcon isActive={isActiveLink(ROUTES.GRID_OF_PROJECTS)} />}
        onClick={(): void => navigateToPage(ROUTES.GRID_OF_PROJECTS)}
        isActive={isActiveLink(ROUTES.GRID_OF_PROJECTS)}
      />
      {reportsAreEnabled && (
        <SideBarAction
          name="Reports"
          isSideBarOpen={isSideBarOpen}
          icon={<ReportsMenuIcon isActive={isActiveLink(ROUTES.REPORTS)} />}
          onClick={(): void => navigateToPage(ROUTES.REPORTS)}
          isActive={isActiveLink(ROUTES.REPORTS)}
        />
      )}
      {/* <SideBarAction */}
      {/*  name="Help" */}
      {/*  isSideBarOpen={isSideBarOpen} */}
      {/*  icon={<HelpMenuIcon />} */}
      {/* /> */}
      {manageUsersIsEnabled && (
        <>
          <SideBarAction
            name="Project Users"
            isSideBarOpen={isSideBarOpen}
            icon={<TeamIcon isActive={isActiveLink(ROUTES.MANAGE_TEAMS)} />}
            onClick={(): void => navigateToPage(ROUTES.MANAGE_TEAMS)}
            isActive={isActiveLink(ROUTES.MANAGE_TEAMS)}
          />
          <SideBarAction
            name="Groups"
            isSideBarOpen={isSideBarOpen}
            icon={<TeamIcon isActive={isActiveLink(ROUTES.MANAGE_GROUPS)} />}
            onClick={(): void => navigateToPage(ROUTES.MANAGE_GROUPS)}
            isActive={isActiveLink(ROUTES.MANAGE_GROUPS)}
          />
          <SideBarAction
            name="Users"
            isSideBarOpen={isSideBarOpen}
            icon={<TeamIcon isActive={isActiveLink(ROUTES.MANAGE_USERS)} />}
            onClick={(): void => navigateToPage(ROUTES.MANAGE_USERS)}
            isActive={isActiveLink(ROUTES.MANAGE_USERS)}
          />
        </>
      )}
      <SideBarAction
        name="Dashboard"
        isSideBarOpen={isSideBarOpen}
        icon={<HelpMenuIcon isActive={isActiveLink(ROUTES.DASHBOARD)} />}
        onClick={(): void => navigateToPage(ROUTES.DASHBOARD)}
        isActive={isActiveLink(ROUTES.DASHBOARD)}
      />
      <SideBarAction
        name="Tasks"
        isSideBarOpen={isSideBarOpen}
        icon={<TableIcon isActive={isActiveLink(ROUTES.TASKS)} />}
        onClick={(): void => navigateToPage(ROUTES.TASKS)}
        isActive={isActiveLink(ROUTES.TASKS)}
      />
    </Container>
  );
};

const Container = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;
