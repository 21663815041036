import React, { useState } from "react";
import styled, { css } from "styled-components";
import {
  EntityStatus,
  Group,
  ServiceForCustomer,
  WorkflowForCustomer,
} from "../../../../generated/graphql";
import { definedColors } from "../../../SideDrawboard/InformationsContainer/ColorChooser";
import { TasksTable, SimplifiedTasks } from "./TasksTable";
import { TeamUser } from "../TeamBlock/useTeamBlock";

const tabsData = [
  {
    id: 0,
    title: "New tasks",
  },
  {
    id: 1,
    title: "On going",
  },
  {
    id: 2,
    title: "Near ending",
  },
  {
    id: 3,
    title: "Completed",
  },
];

interface TabsProps {
  workflows: WorkflowForCustomer[];
  users: TeamUser[];
  groups: Group[];
}

export const Tabs = (props: TabsProps): JSX.Element => {
  const { workflows, users, groups } = props;

  const [tab, setTab] = useState(0);

  const getColorFromIndex = (index: number): string => {
    const colorIndex = index % definedColors.length;
    const color = definedColors[colorIndex];
    return color.color;
  };

  const parseTasks = (
    tasksToParse: WorkflowForCustomer[]
  ): SimplifiedTasks[] => {
    return tasksToParse.map((workflow) => {
      const workflowIndex = workflows.findIndex(
        (enabledWorkflow) => workflow.id === enabledWorkflow.id
      );

      const subtasks = workflow.services.map((service: ServiceForCustomer) => {
        const collaborators: { id: string; name: string; picture: string }[] =
          [];

        service.collaborators?.groupIds.forEach((groupId) => {
          const foundGroup = groups.find((group) => group.id === groupId);
          if (foundGroup) {
            collaborators.push({
              id: foundGroup.id,
              name: foundGroup.name,
              picture: "",
            });
          }
        });

        service.collaborators?.userIds.forEach((userId) => {
          const foundUser = users.find((user) => user.id === userId);
          if (foundUser) {
            collaborators.push({
              id: foundUser.id,
              name: foundUser.name,
              picture: foundUser.avatar,
            });
          }
        });

        return {
          id: service.id,
          name: service.name,
          assignee: service.assignee
            ? users.find((user) => user.id === service.assignee)?.avatar
            : undefined,
          collaborators,
        };
      });

      return {
        id: workflow.id,
        color: getColorFromIndex(workflowIndex),
        name: workflow.name,
        subtasks,
      };
    });
  };

  const getNewTasks = (): SimplifiedTasks[] => {
    const tasks = workflows
      .map((workflow) => {
        const filteredServices = workflow.services.filter(
          (service) => service.status === EntityStatus.Pending
        );

        return {
          ...workflow,
          services: filteredServices,
        };
      })
      .filter((workflow) => workflow.services.length > 0);

    return parseTasks(tasks);
  };

  const getOngoingTasks = (): SimplifiedTasks[] => {
    const tasks = workflows
      .map((workflow) => {
        const filteredServices = workflow.services.filter(
          (service) => service.status === EntityStatus.Ongoing
        );

        return {
          ...workflow,
          services: filteredServices,
        };
      })
      .filter((workflow) => workflow.services.length > 0);

    return parseTasks(tasks);
  };

  const getNearEndingTasks = (): SimplifiedTasks[] => {
    const tasks = workflows
      .map((workflow) => {
        const filteredServices = workflow.services.filter((service) => {
          const msToEnd = parseInt(service.endDate, 10) - new Date().getTime();
          const twoWeeksInMs = 1209600000;

          return msToEnd < twoWeeksInMs && msToEnd >= 0;
        });

        return {
          ...workflow,
          services: filteredServices,
        };
      })
      .filter((workflow) => workflow.services.length > 0);

    return parseTasks(tasks);
  };

  const getCompletedTasks = (): SimplifiedTasks[] => {
    const tasks = workflows
      .map((workflow) => {
        const filteredServices = workflow.services.filter((service) => {
          const msToEnd = parseInt(service.endDate, 10) - new Date().getTime();

          return msToEnd < 0 || service.status === EntityStatus.Ongoing;
        });

        return {
          ...workflow,
          services: filteredServices,
        };
      })
      .filter((workflow) => workflow.services.length > 0);

    return parseTasks(tasks);
  };

  return (
    <Container>
      <TabsContainer>
        {tabsData.map((tabData, index) => (
          <Tab
            aria-hidden
            onClick={() => setTab(tabData.id)}
            active={tab === index}
          >
            {tabData.title}
          </Tab>
        ))}
      </TabsContainer>
      <ContentContainer firstTab={tab === 0}>
        <Content key={0} active={tab === 0}>
          <TasksTable tasks={getNewTasks()} />
        </Content>
        <Content key={1} active={tab === 1}>
          <TasksTable tasks={getOngoingTasks()} />
        </Content>
        <Content key={2} active={tab === 2}>
          <TasksTable tasks={getNearEndingTasks()} />
        </Content>
        <Content key={3} active={tab === 3}>
          <TasksTable tasks={getCompletedTasks()} />
        </Content>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  height: inherit;
  box-shadow: 0px 1px 0px #d1d5d8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 568px;
`;

const TabsContainer = styled.ul`
  display: flex;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

const Tab = styled.li<{ active?: boolean }>`
  font-size: 14px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 8px;

  ${({ active }) =>
    active &&
    css`
      background: white;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #dedede;
      border-bottom: 1px solid white;
    `}
`;

const ContentContainer = styled.div<{ firstTab?: boolean }>`
  background-color: white;
  border-radius: 8px;
  border-top-left-radius: ${({ firstTab }) => (firstTab ? 0 : "8px")};
  border: 1px solid #dedede;
  border-top: 0;
  max-height: 235px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const Content = styled.div<{ active?: boolean }>`
  display: none;
  padding: 4px 16px 20px 16px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;

  ${({ active }) =>
    active &&
    css`
      display: flex;
      border-radius: 8px;
    `}
`;
