import React from "react";
import styled from "styled-components";
import { MiniTimeline } from "../../Timeline/MiniTimeline";
import { useUserProjectQuery } from "../../../generated/graphql";
import { SpinnerElement } from "../../common/Spinner/SpinnerElement";

export const ProjectListElementTimeline = (
  props: ProjectListElementTimelinePropsT
) => {
  const { projectId } = props;

  const { loading, error, data } = useUserProjectQuery({
    variables: {
      id: projectId,
    },
  });

  if (error) {
    return <p>Query Error: {error}</p>;
  }
  if (loading || !data) {
    return (
      <SpinnerContainer>
        <SpinnerElement size={150} />
      </SpinnerContainer>
    );
  }

  return (
    <MiniTimeline samples={data.Project.samples} width="100%" height="100%" />
  );
};

type ProjectListElementTimelinePropsT = {
  projectId: string;
};

const SpinnerContainer = styled.div<{ opaque?: boolean }>`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
