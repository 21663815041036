import * as React from "react";
import { styledTheme } from "../../theme/theme";

type RoundedRectProps = {
  x: number;
  y: number;
  height: number;
  width: number;
  radius: number;
  fill: string;
  type: "LEFT" | "RIGHT";
};

export function RectArrow(props: RoundedRectProps) {
  const totalArrowHeight = props.height * 0.6;
  return props.type === "LEFT" ? (
    <>
      <path
        className="leftDragElement"
        style={{ cursor: "col-resize" }}
        d={`
      M${props.x},${props.y + props.height}
      
      h${props.width}
      
      v-${props.height}
     
      h-${props.width}
      
      q-${props.radius},0 ${-props.radius},${props.radius}
      
      v${props.height - props.radius * 2}
      
      q0,${props.radius} ${props.radius},${props.radius}
      
      z`}
        fill={props.fill}
      />
      <path
        className="leftDragElement"
        style={{ cursor: "col-resize" }}
        d={`
      M${props.x + props.width * 0.5},${
          props.y + (props.height - totalArrowHeight) / 2
        }
      
      l-${props.width * 0.83},${totalArrowHeight / 2}
      
      l${props.width * 0.83},${totalArrowHeight / 2}
      
      `}
        stroke="white"
        strokeWidth={props.width / 6}
        fillOpacity={0}
      />
    </>
  ) : (
    <>
      <path
        className="rightDragElement"
        style={{ cursor: "col-resize" }}
        d={`
      M${props.x - props.radius},${props.y + props.height}
      
      h${props.width}
      
      q${props.radius},0 ${props.radius},${-props.radius}
      
      v-${props.height - props.radius * 2}
      
      q0,${-props.radius} ${-props.radius},${-props.radius}
      
      h-${props.width}
      
      v${props.height - props.radius * 2}

      z`}
        fill={props.fill}
      />
      <path
        className="rightDragElement"
        style={{ cursor: "col-resize" }}
        d={`
      M${props.x - props.radius + props.width * 0.5},${
          props.y + (props.height - totalArrowHeight) / 2
        }
      
      l${props.width * 0.83},${totalArrowHeight / 2}
      
      l-${props.width * 0.83},${totalArrowHeight / 2}
      
      `}
        stroke="white"
        strokeWidth={props.width / 6}
        fillOpacity={0}
      />
    </>
  );
}
RectArrow.defaultProps = {
  x: 100,
  y: 100,
  height: 100,
  width: 100,
  radius: 100,
  fill: styledTheme.newColors.grayscale.primaryGray,
};
