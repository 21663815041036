// @flow
import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

type Props = BaseIconProps;
export const AddIcon = (props: Props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88878 2.6665H7.111V7.11092H2.6665V8.8887H7.111V13.3332H8.88878V8.8887H13.3332V7.11092H8.88878V2.6665Z"
        fill={color}
      />
    </svg>
  );
};

AddIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.white,
};
