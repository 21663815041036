import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import styled from "styled-components";
import { ValueType } from "react-select";
import { TeamBlock } from "./Blocks/TeamBlock/TeamBlock";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import { Spinner } from "../common/Spinner/Spinner";
import { Dropdown } from "../common/Dropdown/Dropdown";
import { useDashboard } from "./useDashboard";
import { SamplesBlock } from "./Blocks/SamplesBlock/SamplesBlock";
import { SamplesBlockPerProject } from "./Blocks/SamplesBlock/SamplesBlockPerProject";
import { BudgetBlock } from "./Blocks/BudgetBlock/BudgetBlock";
import { Currency } from "../../generated/graphql";
import { useUserRolesContext } from "../../utils/hooks/useUserRolesContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Dashboard = (_props: DashboardProps): JSX.Element => {
  const { error, loading, projects, selectedProject, setSelectedProject } =
    useDashboard();

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  const { useUserPermissions } = useUserRolesContext();
  const budgetIsVisible = useUserPermissions("seeBudget");
  const teamIsVisible = useUserPermissions("seeTeam");

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  if (loading) {
    return <Spinner />;
  }

  const handleDropdownChange = async (
    selectedValue: ValueType<{ label: string; value: string }, boolean>
  ): Promise<void> => {
    setSelectedProject(selectedValue as { label: string; value: string });
  };

  return (
    <LayoutDefault pageTitle="Dashboard">
      <DropdownContainer>
        <StyledDropdown
          options={projects}
          defaultValue={selectedProject}
          onChange={handleDropdownChange}
        />
      </DropdownContainer>
      <BlocksContainer>
        <SamplesBlock projectId={selectedProject.value} />
        <SamplesBlockPerProject projectId={selectedProject.value} />
        {teamIsVisible ? <TeamBlock projectId={selectedProject.value} /> : null}
        {budgetIsVisible ? (
          <>
            <BudgetBlock
              projectId={selectedProject.value}
              currency={Currency.Pln}
            />
            <BudgetBlock
              projectId={selectedProject.value}
              currency={Currency.Eur}
            />
            <BudgetBlock
              projectId={selectedProject.value}
              currency={Currency.Usd}
            />
          </>
        ) : null}
      </BlocksContainer>
    </LayoutDefault>
  );
};

type DashboardProps = RouteComponentProps;

const DropdownContainer = styled.div`
  margin-bottom: 16px;
`;

const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 36px;
  row-gap: 40px;
`;

const StyledDropdown = styled(Dropdown)`
  padding: 0;
  max-width: 294px;
`;
