import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const StatusIcon = (props: StatusIconProps) => {
  const { width, height, color, editing } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {editing ? (
        <circle cx="4" cy="4" r="3.5" stroke={color} />
      ) : (
        <circle cx="4" cy="4" r="4" fill={color} />
      )}
    </svg>
  );
};

type StatusIconProps = BaseIconProps & {
  editing: boolean;
};

StatusIcon.defaultProps = {
  width: "8",
  height: "8",
  editing: false,
  color: styledTheme.newColors.grayscale.primaryGray,
};
