import * as React from "react";
import styled from "styled-components";
import { FileLabel } from "./FileLabel";

const FilesContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 5px 5px 0;
  }
`;

export const Files = (props: FilesProps) => {
  const { files } = props;

  if (files.length === 0) {
    return <></>;
  }

  return (
    <FilesContainer>
      {files.map((file) => (
        <FileLabel
          key={file.s3FileName}
          name={file.fileName}
          size={file.fileSize}
          percent={file.percent !== undefined ? file.percent : 100}
        />
      ))}
    </FilesContainer>
  );
};

interface FilesProps {
  files: FileT[];
}

type FileT = {
  fileName: string;
  s3FileName: string;
  fileSize: number;
  percent?: number;
};
