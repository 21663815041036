import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  DragDropContext,
  DragStart,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import {
  navigate,
  RouteComponentProps,
  useMatch,
  useParams,
} from "@reach/router";
import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";
import { useAuth } from "react-use-auth";
import { LayoutWithoutHeader } from "../../Layouts/LayoutWithoutHeader";
import { NavBar } from "./NavBar";
import { Timeline } from "../../Timeline/Timeline";
import { Spinner } from "../../common/Spinner/Spinner";
import {
  Sample,
  useAddEventToSampleMutation,
  useAddServiceToWorkflowMutation,
  useAddWorkflowToSampleMutation,
  UserProjectDocument,
  useUserPlainProjectsQuery,
  useUserProjectQuery,
  WorkflowForCustomer,
} from "../../../generated/graphql";
import { ROUTES } from "../../../constants/routeConstants";
import { SideDrawboard } from "../../SideDrawboard/SideDrawboard";
import { EditSubtask } from "./EditSubtask/EditSubtask";
import { useSideBarsContext } from "../../../utils/hooks/useSideBarsState";
import { useActionContext } from "../../../utils/hooks/useActionContext";
import { BlocksAdder } from "./BlocksAdder/BlocksAdder";
import { ReadQueryResult } from "../../../utils/types/QueryResult";
import {
  SelectedElementType,
  SelectedEventPath,
  SelectedServicePath,
  SelectedWorkflowPath,
} from "../../../utils/types/ActionContextTypes";
import { EditTask } from "./EditTask/EditTask";
import { EditEvent } from "./EditEvent/EditEvent";
import { projectSampleCacheUpdater } from "../../../utils/helpers/dynamicForms/projectSampleCacheUpdater";
import { useUserRolesContext } from "../../../utils/hooks/useUserRolesContext";
import {
  getProjectsList,
  getSamples,
  getGhostTimelineElements,
  ProjectEssentialData,
} from "./helpers";
import { getProjectName } from "../../../utils/helpers/getProjectName";
import { ROLES } from "../../../constants/roles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SingleProject = (_props: RouteComponentProps): JSX.Element => {
  let urlParams = useParams();
  const { user } = useAuth();

  if (!urlParams) {
    urlParams = (useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_EVENT) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_SERVICE) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_WORKFLOW) ||
      useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE)) as {
      uri: string;
      path: string;
      [param: string]: string;
    };
  }

  const {
    selectedWorkflowIndex,
    selectedEntity,
    setSelectedEntity,
    droppedElementCoordinates,
    setDroppedElementCoordinates,
    setElementOnDragAction,
  } = useActionContext();
  const { setSideDrawboardOpen, sideDrawboardOpen, navBarOpen } =
    useSideBarsContext();
  const { useUserPermissions } = useUserRolesContext();

  const {
    error: userPlainProjectsError,
    loading: userPlainProjectsLoading,
    data: userPlainProjectsData,
  } = useUserPlainProjectsQuery();

  const {
    error: userProjectError,
    loading: userProjectLoading,
    data: userProjectData,
  } = useUserProjectQuery({
    variables: {
      id: urlParams.projectId,
    },
  });

  const [addServiceToWorkflow] = useAddServiceToWorkflowMutation();
  const [addWorkflowToSample] = useAddWorkflowToSampleMutation();
  const [addEventToSample] = useAddEventToSampleMutation();

  const [selectedSampleId, setSelectedSampleId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let projectName = "";
    if (!userProjectLoading && userProjectData) {
      const project = userProjectData.Project;
      projectName = `${getProjectName(project)} `;
    }

    document.title = `${projectName}Leaderna Platform`;
  }, [urlParams.projectId]);

  if (userProjectError || userPlainProjectsError) {
    return <p>Query Error: {userProjectError || userPlainProjectsError}</p>;
  }

  if (
    userPlainProjectsLoading ||
    !userPlainProjectsData ||
    userProjectLoading ||
    !userProjectData
  ) {
    return <Spinner />;
  }

  const projectsList = getProjectsList(
    userPlainProjectsData.Projects as ProjectEssentialData[]
  );

  const project = userProjectData.Project;

  const samples = getSamples(project);

  if (!project) {
    navigate(ROUTES.GRID_OF_PROJECTS);
    return <Spinner />;
  }

  // this redirects to the configurator when project has none ongoing services
  // if (project && !projectHasOngoingSamples(project)) {
  //   navigate(`/projects/${project.id}/configurator`);
  //   return <Spinner />;
  // }

  // const selectFirstSampleWithTimeline = (): void => {
  //   const sampleWithTimeline = project.samples.find(
  //     (sample: Sample) => sample.status === "Ongoing"
  //   );
  //   if (sampleWithTimeline) {
  //     setSelectedSampleId(sampleWithTimeline.id);
  //   }
  // };

  const ghostTimelineElements = getGhostTimelineElements(project);

  const getSelectedSample = (sampleId: string): Sample | undefined => {
    return project.samples.find((sample: Sample) => sample.id === sampleId);
  };

  const selectFirstSample = (): void => {
    if (urlParams.sampleId === "1") {
      const firstSampleId = project.samples[0].id;
      setSelectedSampleId(firstSampleId);
    }

    const sampleToSelect = getSelectedSample(urlParams.sampleId);
    if (!sampleToSelect) return;

    setSelectedSampleId(sampleToSelect.id);

    if (urlParams.eventId) {
      const event = sampleToSelect.events.find(
        (e) => e.id === urlParams.eventId
      );

      if (!event) return;

      const visibleOnAllPaths =
        ghostTimelineElements?.ghostEvents.some(
          (ghostEvent) =>
            ghostEvent.sampleId === urlParams.sampleId &&
            ghostEvent.events.some((ghEvent) => ghEvent.id === event.id)
        ) || false;

      const selectedElementPath: SelectedEventPath = {
        projectId: urlParams.projectId,
        sampleId: urlParams.sampleId,
        event,
        originPathId: urlParams.sampleId,
        visibleOnAllPaths,
      };

      setSelectedEntity({
        selectedElementType: SelectedElementType.EVENT,
        selectedElementPath,
      });

      setSideDrawboardOpen(true);

      return;
    }

    if (urlParams.workflowId) {
      const workflow = sampleToSelect.enabledWorkflows.find(
        (w) => w.id === urlParams.workflowId
      );

      if (!workflow) return;

      if (urlParams.serviceId) {
        const service = workflow.services.find(
          (s) => s.id === urlParams.serviceId
        );

        if (service) {
          const visibleOnAllPaths =
            ghostTimelineElements?.ghostServices.some(
              (ghostService) =>
                ghostService.sampleId === urlParams.sampleId &&
                ghostService.services.some(
                  (ghService) => ghService.id === service.id
                )
            ) || false;

          const selectedElementPath: SelectedServicePath = {
            projectId: urlParams.projectId,
            sampleId: urlParams.sampleId,
            workflowName: workflow.name,
            workflowId: urlParams.workflowId,
            service,
            originPathId: urlParams.sampleId,
            visibleOnAllPaths,
          };

          setSelectedEntity({
            selectedElementType: SelectedElementType.SERVICE,
            selectedElementPath,
          });
          setSideDrawboardOpen(true);

          return;
        }
      }

      const selectedElementPath: SelectedWorkflowPath = {
        projectId: urlParams.projectId,
        sampleId: urlParams.sampleId,
        workflowName: workflow.name,
        workflowId: workflow.id,
        workflow,
      };

      setSelectedEntity({
        selectedElementType: SelectedElementType.WORKFLOW,
        selectedElementPath,
      });

      setSideDrawboardOpen(true);
    }
  };

  if (selectedSampleId === "") {
    selectFirstSample();
  }

  const sample = getSelectedSample(selectedSampleId);
  if (!sample) {
    selectFirstSample();
  }

  const handleChangeSample = (sampleId: string): void => {
    const selectedSample = getSelectedSample(sampleId);

    if (selectedSampleId !== sampleId) {
      setSelectedEntity(undefined);
      setSideDrawboardOpen(false);
    }

    if (selectedSample) {
      setSelectedSampleId(sampleId);
      navigate(`/projects/${urlParams.projectId}/timeline/samples/${sampleId}`);
    }
  };

  const addService = (
    newServiceId: string,
    startDate: string,
    baseline: number
  ): void => {
    const currentWorkflowId =
      sample &&
      sample.enabledWorkflows[selectedWorkflowIndex] &&
      sample.enabledWorkflows[selectedWorkflowIndex].id;

    if (!currentWorkflowId) {
      return;
    }

    setIsLoading(true);

    addServiceToWorkflow({
      variables: {
        projectSampleInput: {
          projectId: project.id,
          sampleId: selectedSampleId,
        },
        workflowInput: {
          workflowId: currentWorkflowId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceId: newServiceId,
        startDate,
        baseline,
      },
      update(cache: InMemoryCache, { data }): void {
        const incomingData = data?.["AddServiceToWorkflow"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: project.id },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleData = samplesHere.find((s) => s.id === selectedSampleId);

          if (sampleData) {
            const workflowIndex = sampleData.enabledWorkflows.findIndex(
              (w) => w.id === currentWorkflowId
            );

            if (workflowIndex !== -1) {
              sampleData.enabledWorkflows[workflowIndex] = incomingData;
            }

            cache.writeQuery({
              variables: { id: project.id },
              query: UserProjectDocument,
              data: {
                Project: {
                  ...existingQueryResult,
                  samples: samplesHere,
                },
              },
            });
          }
        }
      },
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const addWorkflow = (
    newWorkflowId: string,
    startDate: string,
    serviceBaseline: number
  ): void => {
    setIsLoading(true);

    addWorkflowToSample({
      variables: {
        projectSampleInput: {
          projectId: project.id,
          sampleId: selectedSampleId,
        },
        workflowId: newWorkflowId,
        startDate,
        serviceBaseline,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: project.id },
        },
      ],
      update(cache: InMemoryCache, mutationResult: FetchResult): void {
        projectSampleCacheUpdater(
          "AddWorkflowToSample",
          cache,
          mutationResult,
          project.id,
          selectedSampleId
        );
      },
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const addEvent = (
    newEventId: string,
    startDate: string,
    baseline: number
  ): void => {
    setIsLoading(true);

    addEventToSample({
      variables: {
        projectSampleInput: {
          projectId: project.id,
          sampleId: selectedSampleId,
        },
        eventId: newEventId,
        startDate,
        baseline,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: project.id },
        },
      ],
      update(cache: InMemoryCache, mutationResult: FetchResult): void {
        projectSampleCacheUpdater(
          "AddWorkflowToSample",
          cache,
          mutationResult,
          project.id,
          selectedSampleId
        );
      },
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onDragEnd = (result: DropResult): void => {
    setElementOnDragAction(null);

    if (result.destination?.droppableId !== "TIMELINE") return;

    const newElementId = result.draggableId;

    const startDate = droppedElementCoordinates.date.getTime().toString();
    const { baseline } = droppedElementCoordinates;
    const source = result.source.droppableId;

    if (newElementId === "NewServiceBlock") {
      addService(newElementId, startDate, baseline);
    } else if (newElementId === "NewWorkflowBlock") {
      addWorkflow(newElementId, startDate, baseline);
    } else if (newElementId.includes("EventBlock")) {
      addEvent(newElementId, startDate, baseline);
    }

    if (source === "WorkflowTemplatesAdder") {
      addWorkflow(newElementId, startDate, baseline);
    }

    setDroppedElementCoordinates({
      date: new Date(),
      baseline: 0,
    });
  };

  const onDragStart = (dragStart: DragStart): void => {
    let type = "";

    switch (dragStart.draggableId) {
      case "NewServiceBlock":
        type = "service";
        break;
      case "NewWorkflowBlock":
        type = "workflow";
        break;
      case "NewEventBlock":
      case "NewEventBlock_Meeting":
        type = "event";
        break;
      default:
        break;
    }

    setElementOnDragAction({ type });
  };

  const numberOfExistingWorkflows = sample ? sample.enabledWorkflows.length : 1;

  const userRoleInSample = sample?.assignedUsers?.find(
    (assignedUser) => assignedUser?.id === user.sub
  )?.role as ROLES | undefined;

  const addItemsIsEnabled = useUserPermissions(
    "addItemsToTimeline",
    userRoleInSample
  );

  let sideDrawboardContent: JSX.Element | null = null;
  if (addItemsIsEnabled) {
    sideDrawboardContent = (
      <BlocksAdder numberOfExistingWorkflows={numberOfExistingWorkflows} />
    );
  }

  if (
    selectedEntity &&
    selectedEntity.selectedElementType === SelectedElementType.SERVICE
  ) {
    const parentEnabledWorkflow = project.samples
      ?.find((s: Sample) => selectedSampleId === s.id)
      ?.enabledWorkflows.find(
        (w: WorkflowForCustomer) =>
          w.id ===
          (selectedEntity.selectedElementPath as SelectedServicePath).workflowId
      );
    sideDrawboardContent = (
      <EditSubtask
        parentEnabledWorkflow={parentEnabledWorkflow}
        userRoleInSample={userRoleInSample}
      />
    );
  } else if (
    selectedEntity &&
    selectedEntity.selectedElementType === SelectedElementType.WORKFLOW
  ) {
    sideDrawboardContent = <EditTask userRoleInSample={userRoleInSample} />;
  } else if (
    selectedEntity &&
    selectedEntity.selectedElementType === SelectedElementType.EVENT
  ) {
    sideDrawboardContent = <EditEvent />;
  }

  return (
    <LayoutWithoutHeader>
      {isLoading && <Spinner opaque />}
      <Container>
        <NavBar
          projectsList={projectsList}
          samples={samples}
          selectedSample={selectedSampleId}
          onSampleClick={(sampleId: string): void =>
            handleChangeSample(sampleId)
          }
        />
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="TIMELINE">
            {(droppableProvided): JSX.Element => (
              <DroppableContent
                ref={droppableProvided.innerRef}
                width={`calc(100% - ${sideDrawboardOpen ? "337px" : "72px"} - ${
                  navBarOpen ? "337px" : "72px"
                })`}
              >
                <Timeline
                  width="100%"
                  sample={sample}
                  projectId={project.id}
                  ghostTimelineElements={ghostTimelineElements}
                  disableDnD={!addItemsIsEnabled}
                />
                <div
                  style={{
                    // backgroundColor: "black",
                    width: 1000,
                    // height: 1000,
                  }}
                >
                  {droppableProvided.placeholder}
                </div>
              </DroppableContent>
            )}
          </Droppable>
          <SideDrawboard
            dropdownData={project.samples}
            selectedDropdownDataId={selectedSampleId}
            onDataClick={(sampleId: string): void =>
              handleChangeSample(sampleId)
            }
            numberOfExistingWorkflows={numberOfExistingWorkflows}
            userRoleInSample={userRoleInSample}
          >
            {sideDrawboardContent}
          </SideDrawboard>
        </DragDropContext>
      </Container>
    </LayoutWithoutHeader>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const DroppableContent = styled.div<{ width: string }>`
  width: ${(props): string => props.width};
  height: 100%;
`;
