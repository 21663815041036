import React from "react";
import styled from "styled-components";
import { PlatformLogo } from "../../../images/PlatformLogo";
import { styledTheme } from "../../../theme/theme";

interface MessageBoxProps {
  children: JSX.Element;
}

export const MessageBox = (props: MessageBoxProps): JSX.Element => {
  const { children } = props;

  return (
    <Container>
      <Header>
        <LogoContainer>
          <PlatformLogo height="100%" width="100%" />
        </LogoContainer>
      </Header>
      <Main>{children}</Main>
    </Container>
  );
};

const Container = styled.div`
  width: 600px;
  background-color: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  box-shadow: 0px 1px 0px ${styledTheme.newColors.grayscale.bordersOutside};
  border-radius: 8px;
  box-sizing: border-box;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  background: ${styledTheme.newColors.grayscale.primaryGray};
  border-radius: 8px 8px 0px 0px;
`;

const LogoContainer = styled.div`
  width: 164px;
  height: 32px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  font-size: 18px;
`;
