import React from "react";
import styled from "styled-components";
import { styledTheme } from "../../theme/theme";

export const ReportsHeader = (props: ReportsHeaderProps) => {
  const { projectsNumber } = props;

  return (
    <Container>
      <Header>Projects ({projectsNumber})</Header>
    </Container>
  );
};

type ReportsHeaderProps = {
  projectsNumber: number | undefined;
};

ReportsHeader.defaultProps = {
  projectsNumber: 0,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
`;
