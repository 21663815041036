import React from "react";
import styled, { css } from "styled-components";
import { Currency } from "../../../generated/graphql";

export type PriceInputValue = {
  price: string;
  currency: string;
};

type PriceInputProps = {
  editMode?: boolean;
  value?: PriceInputValue;
  onChange?: (p: PriceInputValue) => void;
  onBlur?: (p: PriceInputValue) => void;
  maxWidth?: number;
  small?: boolean;
};

export const PLACEHOLDER_TEXT = "0.00";

const currencies = Object.keys(Currency).map((key: string) => Currency[key]);

export const PriceInput = (props: PriceInputProps): JSX.Element => {
  const { editMode = true, value, onChange, onBlur, maxWidth, small } = props;

  let currentValues = {
    price: value?.price || "",
    currency: value?.currency || currencies[0] || "",
  };

  const sendOnChangeEvent = (field: string, newValue: string): void => {
    currentValues = {
      ...currentValues,
      [field]: newValue,
    };
    if (onChange) onChange(currentValues);
  };

  const sendOnBlurEvent = (): void => {
    if (onBlur) onBlur(currentValues);
  };

  const getSize = (): number | undefined => {
    if (maxWidth !== undefined && maxWidth >= 0) {
      return maxWidth > 3 ? maxWidth + 4 : 8;
    }

    return undefined;
  };

  return (
    <Container>
      <StyledInput
        type="number"
        placeholder={PLACEHOLDER_TEXT}
        value={currentValues.price}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          sendOnChangeEvent("price", e.target.value)
        }
        onBlur={sendOnBlurEvent}
        disabled={!editMode}
        size={getSize()}
        small={small}
      />
      <StyledSelect
        value={currentValues.currency}
        disabled={!editMode}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
          sendOnChangeEvent("currency", e.target.value);
          sendOnBlurEvent();
        }}
        small={small}
      >
        {currencies.map((c) => (
          <option key={c}>{c}</option>
        ))}
      </StyledSelect>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 4px 0;
`;

const StyledInput = styled.input<{ size?: number; small?: boolean }>`
  width: calc(100% - 70px);
  border-radius: 6px 0 0 6px;
  border-color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};
  border-width: 1px;
  border-right: none;
  text-align: right;

  ${({ size }) =>
    size &&
    css`
      width: ${size}ch;
    `}

  ${({ small }) =>
    small &&
    css`
      font-size: 11px;
    `}
`;

const StyledSelect = styled.select<{ small?: boolean }>`
  border-radius: 0 6px 6px 0;
  border-color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};

  ${({ small }) =>
    small &&
    css`
      font-size: 11px;
    `}

  ${({ small }) =>
    !small &&
    css`
      width: 70px;
    `}
`;
