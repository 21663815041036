import get from "lodash/get";
import { Project } from "../../generated/graphql";

export const getProjectName = (project: Project) => {
  return get(
    project,
    "selectedQuestionGroups[0].questionSets[0].questions[0].answer.textBody", // first question (Project codename) answer
    project.name
  );
};
