import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import moment from "moment";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import { ProjectsHeader } from "./common/ProjectsHeader";
import { ProjectElement } from "./ProjectElement";
import ServerError from "../ServerError";
import {
  Project,
  useNewProjectMutation,
  UserPlainProjectsWithSamplesDocument,
  useUserPlainProjectsWithSamplesQuery,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { NewProjectElement } from "./NewProjectElement";
import { getProjectName } from "../../utils/helpers/getProjectName";
import { useUserRolesContext } from "../../utils/hooks/useUserRolesContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Projects = (_props: ProjectsProps): JSX.Element => {
  const [showSpinner, setShowSpinner] = useState(false);
  const { useUserPermissions } = useUserRolesContext();

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  const { error, loading, data } = useUserPlainProjectsWithSamplesQuery();
  const newProjectMutation = useNewProjectMutation()[0];

  if (error) {
    return <ServerError />;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const projects = data.Projects || [];
  const projectsNumber = projects.length;

  const createNewProject = async (): Promise<void> => {
    setShowSpinner(true);

    newProjectMutation({
      refetchQueries: [
        {
          query: UserPlainProjectsWithSamplesDocument,
          variables: {},
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setShowSpinner(false);
      })
      .catch(() => {
        setShowSpinner(false);
      });
  };

  const addNewProjectIsEnabled = useUserPermissions("addNewProject");

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <LayoutDefault pageTitle="All projects">
        <ProjectsHeader projectsNumber={projectsNumber} selectedView="grid" />
        <ProjectsContainer id="projects-container">
          {projects.map((project) => {
            const onClickLink = `/projects/${project.id}/timeline/samples/${project.samples[0].id}`;
            const projectLastActivityDateString = moment(
              project.lastActivity
                ? parseInt(project.lastActivity, 10)
                : undefined
            ).format("Do MMMM YYYY");
            return (
              <ProjectElement
                key={project.id}
                projectId={project.id}
                projectLastActivity={projectLastActivityDateString}
                projectName={getProjectName(project as unknown as Project)}
                currentService={project.currentService}
                onClick={(): Promise<void> => navigate(onClickLink)}
              />
            );
          })}
          {addNewProjectIsEnabled ? (
            <NewProjectElement onClick={createNewProject} />
          ) : null}
        </ProjectsContainer>
      </LayoutDefault>
    </>
  );
};

type ProjectsProps = RouteComponentProps;

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;
