import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const CalendarIcon = (props: CalendarIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 11.3333C10.7364 11.3333 11.3333 10.7364 11.3333 10C11.3333 9.26362 10.7364 8.66667 10 8.66667C9.26362 8.66667 8.66667 9.26362 8.66667 10C8.66667 10.7364 9.26362 11.3333 10 11.3333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2H4ZM3.33333 12V4.66667H12.6667V12C12.6667 12.3682 12.3682 12.6667 12 12.6667H4C3.63181 12.6667 3.33333 12.3682 3.33333 12Z"
        fill={color}
      />
    </svg>
  );
};

CalendarIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};

type CalendarIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
