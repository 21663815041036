import React from "react";
import styled from "styled-components";
import { useSamplesBlock } from "./useSamplesBlock";
import { BlockHeader } from "../BlockHeader";
import { Spinner } from "../../../common/Spinner/Spinner";
import { Tabs } from "./Tabs";

interface SamplesBlockPerProjectProps {
  projectId: string;
}

export const SamplesBlockPerProject = (
  props: SamplesBlockPerProjectProps
): JSX.Element => {
  const { projectId } = props;

  const { error, loading, samples, users, groups } = useSamplesBlock({
    projectId,
  });

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  const workflows = samples
    .map((sample) => sample.enabledWorkflows)
    .reduce((accWorkflows, ws) => [...new Set([...accWorkflows, ...ws])], []);

  return (
    <Container>
      <StyledBlockHeader title="Subtasks per project" />
      {samples.length > 0 && (
        <Tabs workflows={workflows} users={users} groups={groups} />
      )}
      {loading && (
        <SpinnerContainer>
          <Spinner containerSize opaque />
        </SpinnerContainer>
      )}
    </Container>
  );
};

const StyledBlockHeader = styled(BlockHeader)`
  box-shadow: 0px 1px 0px #d1d5d8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #dedede;
`;

const Container = styled.div`
  height: 338px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
