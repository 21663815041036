import React, { useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { ApolloError } from "apollo-client";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import ServerError from "../ServerError";
import {
  ProjectUser,
  useProjectUsersQuery,
  useAssignUsersToProjectMutation,
  useUserPlainProjectsWithSamplesQuery,
  Project,
  useRemoveUserFromProjectMutation,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { Button } from "../common/Button/Button";
import { BaseInput } from "../common/BaseInput";
import { AddTeamMemberModal } from "./AddTeamMemberModal/AddTeamMemberModal";
import { Option } from "../../utils/types/QuestionMetadata";
import { Table } from "../Table/Table";
import { UserAvatar } from "../common/UserAvatar/UserAvatar";
import {
  TableInLineButton,
  RedTableInLineButton,
} from "../Table/StyledTableComponents";
import { getProjectName } from "../../utils/helpers/getProjectName";
import { ConfirmationModal } from "../common/Modal/ConfirmationModal";
import { PathAccessManagementModal } from "./PathAccessManagementModal/PathAccessManagementModal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ManageTeam = (_props: ManageTeamProps): JSX.Element => {
  const [
    isConfirmationModalRemoveUserOpen,
    setIsConfirmationModalRemoveUserOpen,
  ] = useState(false);
  const [isInviteTeamMemberFormOpen, setIsInviteTeamMemberFormOpen] =
    useState(false);
  const [isPathAccessManagementModal, setIsPathAccessManagementModal] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: string;
    name: string;
    role: string;
  }>({ id: "", name: "", role: "" });
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [assignUsersToProjectMutation] = useAssignUsersToProjectMutation();
  const [removeUserFromProjectMutation] = useRemoveUserFromProjectMutation();

  const urlParams = useParams();

  const { error, loading, data, refetch } = useProjectUsersQuery({
    variables: { projectId: urlParams.projectId },
  });
  const projectsQuery = useUserPlainProjectsWithSamplesQuery();

  if (error || projectsQuery.error) {
    return <ServerError />;
  }

  if (loading || !data || projectsQuery.loading || !projectsQuery.data) {
    return <Spinner />;
  }

  const projectUsers = data?.ProjectUsers || [];
  const project = projectsQuery.data.Projects.find(
    (projectData) => projectData.id === urlParams.projectId
  );

  const searchUsers = () => {
    const lowerCasedSearchTerm = currentSearchTerm.toLowerCase();
    return projectUsers.filter(
      (user) =>
        user?.name.toLowerCase().includes(lowerCasedSearchTerm) ||
        user?.email.toLowerCase().includes(lowerCasedSearchTerm)
    );
  };

  const onInviteNewMember = (selectedUsers: Option[]): void => {
    const usersAuth0Ids = selectedUsers.map((usr) => usr.value);
    assignUsersToProjectMutation({
      variables: {
        projectId: urlParams.projectId,
        usersAuth0Ids,
      },
    }).then(() => {
      setIsInviteTeamMemberFormOpen(false);
      refetch();
    });
  };

  const removeUserFromProjectCallback = (userIdToRemove: string): void => {
    setIsLoading(true);

    removeUserFromProjectMutation({
      variables: {
        projectId: urlParams.projectId,
        userAuth0Id: userIdToRemove,
      },
    })
      .then(() => {
        refetch()
          .then(() => {
            setIsLoading(false);
            setIsConfirmationModalRemoveUserOpen(false);
          })
          .catch((e: ApolloError) => {
            setIsLoading(false);
            setIsConfirmationModalRemoveUserOpen(false);
          });
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
        setIsConfirmationModalRemoveUserOpen(false);
      });
  };

  const getUserRow = (user: ProjectUser) => {
    return [
      <UserAvatar user={{ picture: user.avatar }} />,
      user.name,
      user.email,
      user.group,
      <>
        <TableInLineButton
          onClick={(): void => {
            navigate(`/user-tasks/${user.id}`);
          }}
        >
          SHOW ASSIGNED TASKS
        </TableInLineButton>
        <TableInLineButton
          onClick={(): void => {
            setSelectedUser({ id: user.id, name: user.name, role: user.role });
            setIsPathAccessManagementModal(true);
          }}
        >
          ASSIGN PATHS
        </TableInLineButton>
      </>,
      <RedTableInLineButton
        onClick={(): void => {
          setSelectedUser({ id: user.id, name: user.name, role: user.role });
          setIsConfirmationModalRemoveUserOpen(true);
        }}
      >
        REMOVE
      </RedTableInLineButton>,
    ];
  };

  const projectName = getProjectName(project as unknown as Project);
  const pageTitle = projectName
    ? `Project Users for ${projectName}`
    : "Project Users";

  return (
    <LayoutDefault pageTitle={pageTitle}>
      <ManageTeamContainer>
        <ContentSection
          title="Users with access to the project"
          stepNumber={1}
          wrapCardsCallback={() => {}}
          buttons={[]}
          fullWidth
        >
          <StyledHeader>
            <div>Team members ({projectUsers.length})</div>
            <StyledButton
              label="ADD USERS TO THIS PROJECT"
              onClick={(): void => {
                setIsInviteTeamMemberFormOpen(true);
              }}
            />
            {isInviteTeamMemberFormOpen && (
              <AddTeamMemberModal
                isOpen={isInviteTeamMemberFormOpen}
                onAccept={onInviteNewMember}
                onClose={(): void => setIsInviteTeamMemberFormOpen(false)}
              />
            )}
            {isConfirmationModalRemoveUserOpen && (
              <ConfirmationModal
                isOpen={isConfirmationModalRemoveUserOpen}
                message={`Are you sure you want to remove ${selectedUser.name} from this project?`}
                title="Confirmation"
                yesButtonText="Remove this user"
                closeModal={(): void =>
                  setIsConfirmationModalRemoveUserOpen(false)
                }
                acceptModal={(): void => {
                  removeUserFromProjectCallback(selectedUser.id);
                }}
                isLoading={isLoading}
              />
            )}
            {isPathAccessManagementModal && (
              <PathAccessManagementModal
                projectId={urlParams.projectId}
                isOpen={isPathAccessManagementModal}
                user={selectedUser}
                onClose={(): void => {
                  setIsPathAccessManagementModal(false);
                }}
              />
            )}
          </StyledHeader>
          <StyledDiv>
            <StyledSearchBar
              key="user-searchbar"
              value={currentSearchTerm}
              placeholder="Search by name or email"
              onChange={(e): void => setCurrentSearchTerm(e.target.value)}
            />
          </StyledDiv>
          <Table
            columns={["", "User", "E-mail", "Group", "Actions", "Remove user"]}
          >
            {searchUsers().map((user: ProjectUser) => getUserRow(user))}
          </Table>
        </ContentSection>
      </ManageTeamContainer>
    </LayoutDefault>
  );
};

type ManageTeamProps = RouteComponentProps;

const StyledSearchBar = styled(BaseInput)`
  width: 205px;
  height: 48px;
  box-shadow: 0 1px 2px 0 rgba(220, 222, 229, 0.3);
  background-color: #ffffff;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  flex-grow: 0;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
  text-align: left;
  color: #232f3c;
`;

const StyledDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 250px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #3b79d1;
`;

const ManageTeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;
