import React from "react";
import styled, { css } from "styled-components";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { ChainIcon } from "../../../../images/icons/ChainIcon";
import { styledTheme } from "../../../../theme/theme";
import { DragIcon } from "../../../../images/icons/DragIcon";
import { ChevronRightIcon } from "../../../../images/icons/ChevronRightIcon";
import { URLIcon } from "../../../../images/icons/URLIcon";
import { CalendarIcon } from "../../../../images/icons/CalendarIcon";
import { MemoIcon } from "../../../../images/icons/MemoIcon";
import { ReportsIcon } from "../../../../images/icons/ReportsIcon";
import { DeleteIcon } from "../../../../images/icons/DeleteIcon";

type StaticBlockProps = {
  blockName: string;
  blockColor?: string;
  className?: string;
  dragHandleVisible?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  copy?: boolean;
  showDeleteIcon?: boolean;
  duration?: number;
  onDeleteIconClick?: () => void;
};

export const StaticBlock = (props: StaticBlockProps): JSX.Element => {
  const {
    blockName,
    blockColor = "#3b79d1",
    className,
    dragHandleVisible,
    dragHandleProps,
    copy = false,
    showDeleteIcon = false,
    duration = 16,
    onDeleteIconClick,
  } = props;

  return (
    <Container copy={copy} className={className}>
      <Header color={blockColor}>
        <HeaderLeftPart>
          <ChainIconContainer>
            <ChainIcon
              width={16}
              height={16}
              secondColor={styledTheme.newColors.grayscale.primaryGray}
            />
          </ChainIconContainer>
          <BlockName>{blockName}</BlockName>
        </HeaderLeftPart>
        <HeaderRightPart>
          <Time>{duration}w</Time>
          {showDeleteIcon && (
            <DeleteIconContainer onClick={onDeleteIconClick}>
              <DeleteIcon />
            </DeleteIconContainer>
          )}
          {dragHandleVisible && (
            <HeaderIconContainer {...dragHandleProps}>
              <DragIcon />
            </HeaderIconContainer>
          )}
        </HeaderRightPart>
      </Header>
      <Content>
        <ShowDetailsContainer>
          <ChevronRightIconContainer>
            <ChevronRightIcon color="#3b79d1" />
          </ChevronRightIconContainer>
          <LabelContainer>SHOW DETAILS</LabelContainer>
          <Line />
        </ShowDetailsContainer>
        <IconsContainer>
          <IconContainer>
            <CalendarIcon color="#e6e6e6" />
          </IconContainer>
          <IconContainer>
            <ReportsIcon color="#e6e6e6" />
          </IconContainer>
          <Value>0</Value>
          <IconContainer>
            <MemoIcon color="#e6e6e6" />
          </IconContainer>
          <Value>0</Value>
          <IconContainer>
            <URLIcon color="#e6e6e6" />
          </IconContainer>
          <Value>0</Value>
        </IconsContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div<{ copy: boolean }>`
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(220, 222, 229, 0.3);
  border: solid 1px #dedede;
  background-color: #ffffff;
  opacity: 1;

  ${(copy) =>
    copy &&
    css`
      ~ div {
        transform: none !important;
      }

      ~ [data-rbd-placeholder-context-id] {
        display: none !important;
      }
    `};
`;

const Header = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const HeaderLeftPart = styled.div`
  display: flex;
`;

const ChainIconContainer = styled.div`
  padding: 12px;
  height: 16px;
  width: 16px;
`;

const HeaderRightPart = styled.div`
  display: flex;
  align-items: center;
`;

const BlockName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
`;

const Time = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 800;
  color: #ffffff;
  margin-right: 4px;
`;

const HeaderIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  cursor: pointer;
`;

const DeleteIconContainer = styled(HeaderIconContainer)`
  margin-right: 0;

  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover > path {
    fill: ${({ theme }): string => theme.newColors.extra.errorHovered};
    transition: fill linear 0.2s;
  }

  & > svg:active > path {
    fill: ${({ theme }): string => theme.newColors.extra.errorActive};
    transition: fill linear 0.2s;
  }
`;

const Content = styled.div`
  padding: 12px;
`;

const ShowDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronRightIconContainer = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.div`
  font-size: 12px;
  line-height: 10px;
  margin-left: 8px;
  color: #3b79d1;
`;

const Line = styled.div`
  height: 1px;
  background-color: #dedede;
  margin-left: 8px;
  width: calc(100% - 100px);
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 12px;
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  padding: 0 2px;
`;

const Value = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #adb1bc;
  padding: 0 2px;
`;
