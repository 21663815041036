import React from "react";
import styled from "styled-components";
import { DragIcon } from "../../../images/icons/DragIcon";

interface BlockHeaderProps {
  title?: string;
  headerComponent?: JSX.Element;
  className?: string;
}

export const BlockHeader = (props: BlockHeaderProps): JSX.Element => {
  const { title, headerComponent, className } = props;

  return (
    <Header className={className}>
      {title && <Title>{title}</Title>}
      {headerComponent && <Title>{headerComponent}</Title>}
      {!title && !headerComponent && <div />}
      <DragIconContainer>
        <DragIcon />
      </DragIconContainer>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border: 1px solid #dedede;
  padding: 16px 24px 12px 24px;
  height: 55px;
  max-height: 55px;
  box-sizing: border-box;
  background-color: white;
  width: 568px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const Title = styled.div`
  font-weight: bold;
`;

const DragIconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;

  &:hover > svg > circle {
    transition: 200ms;
    fill: #1856c0;
  }
`;
