import React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import * as dp from "react-datepicker";
import { CalendarWithBorderIcon } from "../../../images/icons/CalendarWithBorderIcon";

type DatePickerProps = {
  date: Date;
  setDate?: (date: Date) => void;
  editMode: boolean | undefined;
  update?: (destination: string, timestamp: string) => void;
  destinationUpdate?: string;
  showIcon?: boolean;
  selectedDatePickerId: number | string | null;
  setSelectedDatePickerId: React.Dispatch<
    React.SetStateAction<number | string | null>
  >;
  datePickerId: number | string;
  isOpen?: boolean;
  className?: string;
};

export const DatePicker = (props: DatePickerProps): JSX.Element => {
  const {
    date,
    setDate,
    editMode,
    showIcon,
    selectedDatePickerId,
    setSelectedDatePickerId,
    datePickerId,
    isOpen = false,
    className,
  } = props;

  const selected = moment(date).toDate();

  return (
    <Container editMode={editMode} className={className}>
      {showIcon && (
        <IconContainer
          onClick={() => {
            if (selectedDatePickerId && selectedDatePickerId !== datePickerId)
              return setSelectedDatePickerId(datePickerId);

            return setSelectedDatePickerId(
              selectedDatePickerId ? null : datePickerId
            );
          }}
        >
          <CalendarWithBorderIcon />
        </IconContainer>
      )}
      <StyledDatePicker
        id="date-picker"
        selected={selected}
        onChange={(newDate: Date): void => {
          if (setDate) {
            setDate(newDate);
            setSelectedDatePickerId(null);
          }
        }}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dateFormat="d MMMM yyyy"
        dropdownMode="select"
        disabled={!editMode}
        editMode={editMode}
        onInputClick={(): void => {
          if (selectedDatePickerId && selectedDatePickerId !== datePickerId)
            return setSelectedDatePickerId(datePickerId);

          return setSelectedDatePickerId(
            selectedDatePickerId ? null : datePickerId
          );
        }}
        onClickOutside={(ev) => {
          const target = ev.target as HTMLElement;

          if (target.id === "calendar-icon" || target.id === "date-picker") {
            return null;
          }

          return setSelectedDatePickerId(null);
        }}
        shouldCloseOnSelect
        open={datePickerId === selectedDatePickerId || isOpen}
        onKeyDown={(ev): void => ev.preventDefault()}
      />
    </Container>
  );
};

const Container = styled.div<{ editMode: boolean | undefined }>`
  display: flex;
  align-items: center;
  padding: 3px 0;

  .react-datepicker__header {
    background-color: white;
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    background-color: white;
    border: 1px solid #adb1bc;
    box-shadow: 0px 2px 4px rgba(220, 222, 229, 0.6);
    border-radius: 4px;
    padding: 5px;
    margin: 10px;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: inherit;
    color: inherit;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;

const StyledDatePicker = styled(dp.default)<{ editMode: boolean | undefined }>`
  margin-left: 13px;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  background-color: white;
  color: black;
  cursor: auto;
  caret-color: transparent;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  &:focus {
    outline: none;
  }

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `}
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
