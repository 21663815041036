import React, { FunctionComponent } from "react";
import { FieldProps } from "formik";
import { FormGroup } from "../FormGroup";
import { QuestionMetadata } from "../../../../utils/types/QuestionMetadata";
import { ExternalLinks } from "../../../Projects/Configurator/common/ExternalLinks/forPrint/ExternalLinks";
import { GetFieldStatusResultT } from "../../../common/LabelTag/LabelTag";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
}

type OwnProps = FieldProps & Props;

export const LinksField: FunctionComponent<OwnProps> = (props) => {
  const { field, label, description } = props;

  return (
    <FormGroup label={label} description={description}>
      <ExternalLinks linksValue={field.value} />
    </FormGroup>
  );
};
