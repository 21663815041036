import React from "react";
import styled from "styled-components";
import { ProjectHeaderSummary } from "./common/ProjectHeaderSummary";
import { styledTheme } from "../../theme/theme";

export const NewProjectElement = (
  props: NewProjectElementPropsT
): JSX.Element => {
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <PlusContainer>+</PlusContainer>
      <ProjectHeaderSummary name="Add a new project" />
    </Container>
  );
};

type NewProjectElementPropsT = {
  onClick?: () => void;
};

NewProjectElement.defaultProps = {
  onClick: (): void => {},
};

const Container = styled.div`
  width: 276px;
  padding: 24px;
  cursor: pointer;

  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.dropShadows.formCard};
  border-radius: 8px;
  text-align: center;
`;

const PlusContainer = styled.span`
  color: ${styledTheme.newColors.grayscale.tertiaryGray};
  font-size: 80pt;
`;

// const ServiceStatusContainer = styled.div`
//   margin-top: 20px;
//   padding: 12px;
//   border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
//   box-sizing: border-box;
//   border-radius: 4px;
// `;
