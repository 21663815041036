import React from "react";
import styled from "styled-components";
import {
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
} from "@hello-pangea/dnd";
import { styledTheme } from "../../../../theme/theme";
import { DragIcon } from "../../../../images/icons/DragIcon";
import { FormQuestion } from "./FormQuestion";
import { FormQuestions, FormQuestionT } from "./FormQuestions";

const getDraggingQuestionClone =
  (formQuestions: FormQuestionT[]) =>
  (
    provided: DraggableProvided,
    _snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ) => {
    const formQuestion = formQuestions[rubric.source.index];

    return (
      <DraggableFormElementContainer
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={provided.draggableProps.style}
        key={formQuestion.id}
      >
        <FormElementContainer clone>
          {formQuestion.createComponent()}
          <DragIconContainer {...provided.dragHandleProps}>
            <DragIcon />
          </DragIconContainer>
        </FormElementContainer>
      </DraggableFormElementContainer>
    );
  };

export const FormQuestionsContainer = () => {
  const formQuestions = FormQuestions();

  return (
    <>
      <Title>Add new question:</Title>
      <Droppable
        renderClone={getDraggingQuestionClone(formQuestions)}
        droppableId="FormQuestions"
        isDropDisabled
      >
        {(droppableProvided, snapshot) => (
          <FormContainer ref={droppableProvided.innerRef}>
            {formQuestions.map((item, index) => {
              const renderClone = item.id === snapshot.draggingFromThisWith;
              return (
                <React.Fragment key={item.id}>
                  {renderClone ? (
                    <FormQuestion key={item.id}>
                      {item.createComponent()}
                    </FormQuestion>
                  ) : (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                    >
                      {(draggableProvided) => (
                        <DraggableFormElementContainer
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          <FormElementContainer>
                            <FormComponentContainer>
                              {item.createComponent()}
                            </FormComponentContainer>
                            <DragIconContainer
                              {...draggableProvided.dragHandleProps}
                            >
                              <DragIcon />
                            </DragIconContainer>
                          </FormElementContainer>
                        </DraggableFormElementContainer>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            {droppableProvided.placeholder}
          </FormContainer>
        )}
      </Droppable>
    </>
  );
};

const Title = styled.div`
  margin: 0 1rem;
  color: ${(props) => props.theme.newColors.primary.basic};
  font-size: 0.8rem;
`;

const FormContainer = styled.div`
  padding: 0 1rem;
`;

const DraggableFormElementContainer = styled.div``;

const FormElementContainer = styled.div<{ clone?: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  border-bottom: ${(props) =>
    props.clone
      ? "none"
      : `1px solid ${styledTheme.newColors.grayscale.bordersInside}`};
`;

const FormComponentContainer = styled.div`
  max-width: 100%;
  flex-grow: 1;
`;

const DragIconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
`;
