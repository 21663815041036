import * as React from "react";
import { navigate } from "@reach/router";
import { ChevronRightIcon } from "../../images/icons/ChevronRightIcon";
import { styledTheme } from "../../theme/theme";
import { ReportsIcon } from "../../images/icons/ReportsIcon";
import { CalendarIcon } from "../../images/icons/CalendarIcon";
import { MemoIcon } from "../../images/icons/MemoIcon";
import { URLIcon } from "../../images/icons/URLIcon";

export enum DETAIL_GROUPS {
  REPORTS = "Reports",
  MEMOS = "Memos",
  DATES = "Dates",
  RESOURCES = "Resources",
}

export type DetailGroup = {
  name: string;
  items: DetailGroup[];
  height?: number;
  value?: string;
  link?: string;
};
const defaultDetailHeaderHeight = 20;

export function getDetailGroupsHeight(
  detailGroups: DetailGroup[],
  height: number = defaultDetailHeaderHeight
) {
  const totalHeadersHeight = detailGroups.length * height;
  return (
    totalHeadersHeight +
    detailGroups.reduce((a, b) => a + b.items.length, 1) * height
  );
}

export function getDetailStartY(
  d: DetailGroup,
  detailGroups: DetailGroup[] = [],
  height: number
) {
  const index = detailGroups.findIndex((dd) => dd.name === d.name);
  return getDetailGroupsHeight(detailGroups.slice(0, index), height);
}

type DetailsHeaderProps = {
  setShowDetails: (value: ((prevState: boolean) => boolean) | boolean) => void;
  showDetails: boolean;
  ownScale: number;
  width: number;
  zoomedInLevel: number;
};
export const DetailsHeader = (props: DetailsHeaderProps): JSX.Element => {
  const boxIsNarrow = props.width / props.ownScale < 60;
  const boxIsTooNarrowForLine = props.width / props.ownScale < 90;
  const detailsText = boxIsNarrow ? "Det" : "Details";
  return (
    <g
      // onClick={(): void => props.setShowDetails(!props.showDetails)}
      pointerEvents="bounding-box"
    >
      <ChevronRightIcon
        color={styledTheme.newColors.primary.basic}
        width={6 * props.ownScale}
        height={15 * props.ownScale}
        x={9 * props.ownScale}
        y={2.5 * props.ownScale}
      />

      <text
        fill={styledTheme.newColors.primary.basic}
        x={16 * props.ownScale}
        y={15 * props.ownScale}
        fontSize={14 * props.ownScale}
        style={{ cursor: "pointer" }}
      >
        {detailsText}
      </text>

      {boxIsTooNarrowForLine ? null : (
        <line
          x1={65 * props.ownScale}
          x2={props.width - 4}
          y1={11 * props.ownScale}
          y2={11 * props.ownScale}
          style={{
            stroke: styledTheme.colors.athensGray,
            strokeWidth: 1 / props.zoomedInLevel,
          }}
        />
      )}
    </g>
  );
};

export const ServiceInfoIcons = (props: {
  y: number;
  width: number;
  ownScale: number;
}) => {
  return (
    <>
      <CalendarIcon
        y={props.y}
        x={props.width - 80 * props.ownScale - 2}
        height={16 * props.ownScale}
        width={16 * props.ownScale}
      />
      <ReportsIcon
        y={props.y}
        x={props.width - 60 * props.ownScale - 2}
        height={16 * props.ownScale}
        width={16 * props.ownScale}
      />
      <MemoIcon
        y={props.y}
        x={props.width - 40 * props.ownScale - 2}
        height={16 * props.ownScale}
        width={16 * props.ownScale}
      />
      <URLIcon
        y={props.y}
        x={props.width - 20 * props.ownScale - 2}
        height={16 * props.ownScale}
        width={16 * props.ownScale}
      />
    </>
  );
};

export function getGroupIcon(
  detailGroup: DetailGroup,
  scale = 1,
  zoomedInLevel = 1
) {
  switch (detailGroup.name) {
    case DETAIL_GROUPS.DATES:
      return (
        <svg
          width={16 * scale}
          height={16 * scale}
          y={zoomedInLevel ? -8 : -5}
          x={zoomedInLevel ? 10 : 5}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 6.00024H14"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.1429 3.6001H2.85714C2.384 3.6001 2 3.95851 2 4.40012V13.2004C2 13.642 2.384 14.0004 2.85714 14.0004H13.1429C13.616 14.0004 14 13.642 14 13.2004V4.40012C14 3.95851 13.616 3.6001 13.1429 3.6001Z"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.57129 2V3.60005"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M11.4282 2V3.60005"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M8 2V3.60005"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      );

    case DETAIL_GROUPS.RESOURCES:
      return (
        <svg
          width={16 * scale}
          height={16 * scale}
          y={zoomedInLevel ? -8 : -5}
          x={zoomedInLevel ? 10 : 5}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00014 14C9.4203 14 10.5716 11.3137 10.5716 8C10.5716 4.68629 9.4203 2 8.00014 2C6.57998 2 5.42871 4.68629 5.42871 8C5.42871 11.3137 6.57998 14 8.00014 14Z"
            stroke="#3B79D1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 8H14"
            stroke="#3B79D1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
            stroke="#3B79D1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case DETAIL_GROUPS.REPORTS:
      return (
        <svg
          width={16 * scale}
          height={16 * scale}
          y={zoomedInLevel ? -8 : -5}
          x={zoomedInLevel ? 10 : 5}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4.5" y="8" width="1" height="4" fill="#3B79D1" />
          <rect x="6.5" y="4" width="1" height="8" fill="#3B79D1" />
          <rect x="8.5" y="6" width="1" height="6" fill="#3B79D1" />
          <rect x="10.5" y="10" width="1" height="2" fill="#3B79D1" />
          <rect x="2" y="2" width="12" height="12" stroke="#3B79D1" />
        </svg>
      );

    case DETAIL_GROUPS.MEMOS:
    default:
      return (
        <svg
          width={16 * scale}
          height={16 * scale}
          y={zoomedInLevel ? -8 : -5}
          x={zoomedInLevel ? 10 : 5}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.39941 4.39868H9.99942"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M4.39941 6.79956H9.99942"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M4.39941 9.19946H9.99942"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M12.4 10V2H2V12.4C2 13.284 2.716 14 3.6 14M3.6 14C4.484 14 5.2 13.284 5.2 12.4V11.6H14V12C14 13.1048 13.1048 14 12 14H3.6Z"
            stroke="#3B79D1"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      );
  }
}

export const Details = (props: {
  width: number;
  height: number;
  ownScale: number;
  zoomedInLevel: number;
  detailGroups: DetailGroup[];
  detailHeaderHeight: number;
}) => {
  const tooNarrow = props.width / props.ownScale < 250;
  const widthIfIsTooNarrow = tooNarrow ? 120 : props.width;

  return (
    <svg
      fill={styledTheme.newColors.primary.basic}
      width={widthIfIsTooNarrow}
      y={10 * props.ownScale + 5}
      x={-2}
      height={props.height}
    >
      {props.detailGroups.map((detailGroup: DetailGroup) => {
        return (
          <g
            transform={`translate(0, ${
              getDetailStartY(
                detailGroup,
                props.detailGroups,
                props.detailHeaderHeight
              ) - (props.zoomedInLevel ? 0 : 5)
            })`}
          >
            <rect
              fill="#F5F8FD"
              width="100%"
              height={props.detailHeaderHeight}
              x={props.zoomedInLevel ? 1.5 : 0}
              y={-10}
            />
            {getGroupIcon(
              detailGroup,
              props.zoomedInLevel ? 0.4 : 0.6,
              props.zoomedInLevel
            )}
            <text
              fill={styledTheme.newColors.primary.basic}
              fontSize={14 * props.ownScale}
              y={props.zoomedInLevel ? -3 : 2}
              x={20}
            >
              {detailGroup.name}
            </text>
            {detailGroup.items.map((item, i) => {
              return (
                <>
                  {i > 0 ? (
                    <line
                      x1={8}
                      y1={(i + 1) * props.detailHeaderHeight - 10}
                      x2={props.width - 8}
                      y2={(i + 1) * props.detailHeaderHeight - 10}
                      style={{
                        stroke: styledTheme.colors.athensGray,
                        strokeWidth: props.zoomedInLevel ? 0.5 : 1,
                      }}
                    />
                  ) : null}
                  <text
                    fill={styledTheme.newColors.grayscale.primaryGray}
                    fontSize={14 * props.ownScale}
                    y={
                      (i + 1) * props.detailHeaderHeight +
                      (props.zoomedInLevel ? -3 : 2)
                    }
                    x={10}
                    onClick={
                      item.link
                        ? (): void => {
                            navigate(item.link as string);
                          }
                        : undefined
                    }
                    style={item.link ? { cursor: "pointer" } : undefined}
                  >
                    {item.name}
                  </text>
                  {item.value && (
                    <text
                      fill={styledTheme.colors.lightText}
                      fontSize={14 * props.ownScale}
                      y={
                        (i + 1) * props.detailHeaderHeight +
                        (props.zoomedInLevel ? -3 : 2)
                      }
                      x="95%"
                      textAnchor="end"
                    >
                      {item.value}
                    </text>
                  )}
                </>
              );
            })}
          </g>
        );
      })}
    </svg>
  );
};
