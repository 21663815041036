import React from "react";
import { SimpleUnitGroup } from "../../../components/DynamicForm/Elements/SimpleUnitGroup";
import { FomSchemaRendererField } from "../../types/QuestionMetadata";
import { ProteinSequenceRenderer } from "../../../components/DynamicForm/Elements/ProteinSequenceRenderer";

export const getFormElementRenderer = (
  elementKey: string,
  elementData: FomSchemaRendererField,
  isRequired: boolean,
  filledRequiredItemsIds: any,
  readonly?: boolean
) => {
  switch (elementData.renderer) {
    case "QuestionWithUnit":
      return (
        <SimpleUnitGroup
          parentId={elementKey}
          isRequired={isRequired}
          formSchemaItems={elementData.formSchemaItems}
          filledRequiredItemsIds={filledRequiredItemsIds}
          readonly={readonly}
        />
      );

    case "ProteinSequence":
      return (
        <ProteinSequenceRenderer
          parentId={elementKey}
          formSchemaItems={elementData.formSchemaItems}
          isRequired={isRequired}
          filledRequiredItemsIds={filledRequiredItemsIds}
        />
      );

    case "AnotherRenderer":
      return <div>Another Renderer content</div>;

    default:
      return <div>There is no renderer for: {elementData.renderer}</div>;
  }
};
