import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ApolloError } from "apollo-client";
import { Modal } from "../../common/Modal/Modal";
import { ButtonType } from "../../common/Button/ButtonType";
import { Button } from "../../common/Button/Button";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { Option } from "../../../utils/types/QuestionMetadata";
import {
  useEditUserRoleMutation,
  useGetAuth0RolesQuery,
  ListUsersDocument,
} from "../../../generated/graphql";
import { Spinner } from "../../common/Spinner/Spinner";

type RoleAssignModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: { id: string; name: string; roleId: string | undefined } | undefined;
};

export const RoleAssignModal = (props: RoleAssignModalProps): JSX.Element => {
  const { isOpen, onClose, user } = props;

  const [selectedRole, setSelectedRole] = useState<Option | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  if (!user) return <></>;

  const { error, loading, data: rolesData } = useGetAuth0RolesQuery();

  const [editUserRoleMutation] = useEditUserRoleMutation();

  const onAcceptClick = (): void => {
    if (!selectedRole || !user.id || selectedRole.value === user.roleId) {
      onClose();
      return;
    }

    setIsLoading(true);

    editUserRoleMutation({
      variables: {
        auth0UserId: user.id,
        auth0RoleId: selectedRole.value,
      },
      refetchQueries: [
        {
          query: ListUsersDocument,
        },
      ],
    })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
        onClose();
      });
  };

  const ModalButtons = (): JSX.Element => {
    return (
      <>
        <AcceptButton
          disabled={isLoading}
          label="Assign role"
          type={ButtonType.Primary}
          onClick={onAcceptClick}
        />
        <Button
          disabled={isLoading}
          label="Cancel"
          type={ButtonType.Secondary}
          onClick={onClose}
        />
      </>
    );
  };

  useEffect(() => {
    if (rolesData?.GetAuth0Roles) {
      const roleToSet = rolesData.GetAuth0Roles.find(
        (role) => role.id === user.roleId
      );

      if (!roleToSet) return;
      setSelectedRole({ label: roleToSet.name, value: roleToSet.id });
    }
  }, [rolesData]);

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  if (loading || !rolesData) {
    return <Spinner opaque />;
  }

  const getOptions = (): Option[] => {
    return rolesData.GetAuth0Roles.map((role) => ({
      value: role.id,
      label: role.name,
    }));
  };

  if (!isOpen) return <></>;

  return (
    <StyledModal
      id="assignRoleModal"
      title={`Assign role to user: ${user.name}`}
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <StyledDropdown
        closeMenuOnSelect
        value={selectedRole}
        options={getOptions()}
        onChange={(option: Option): void => setSelectedRole(option)}
        placeholder="Select role"
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
