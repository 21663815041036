import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { ServicePageTitle } from "./ServicePageTitle";
import { getWorkflowsArrays } from "./services/getWorkflowsArrays";
import { ServicePacksContainer } from "./ServicePacksContainer";
import { Services } from "./Services";
import { Workflow } from "./helpers/TypesForServicePackages";
import { ServicePackAction } from "../../../../../utils/types/WorkflowMetadata";
import { BaseService, BaseWorkflow } from "../../../../../generated/graphql";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { getSelectedWorkflows } from "./helpers/getSelectedWorkflows";
import { getSelectedServicesInWorkflow } from "./helpers/getSelectedServicesInWorkflow";

export type ServicePackagesConfiguratorProps = {
  projectId: string;
  sampleId: string;
  services: BaseService[];
  workflows: BaseWorkflow[];
  selectedWorkflowsIds?: string[];
  removeDisabled: boolean;
};

type Props = ServicePackagesConfiguratorProps & {
  serviceCardButtonAction: ServicePackAction;
};

export const ServicePackages = (props: Props) => {
  const {
    services,
    workflows,
    serviceCardButtonAction,
    projectId,
    sampleId,
    selectedWorkflowsIds,
    removeDisabled,
  } = props;
  const [selectedServices, setSelectedServices] = useState<string[] | null>(
    null
  );
  const [recommendedWorkflows, setRecommendedWorkflows] = useState<Workflow[]>(
    []
  );
  const [suggestedWorkflows, setSuggestedWorkflows] = useState<Workflow[]>([]);
  const [othersWorkflows, setOthersWorkflows] = useState<Workflow[]>([]);

  const [selectedWorkflows, setSelectedWorkflows] = useState<Workflow[]>(
    getSelectedWorkflows(workflows, selectedWorkflowsIds)
  );

  useEffect(() => {
    const { recommended, suggested, others } = getWorkflowsArrays(
      workflows,
      selectedServices
    );
    setRecommendedWorkflows(recommended);
    setSuggestedWorkflows(suggested);
    setOthersWorkflows(others);
    setSelectedWorkflows(getSelectedWorkflows(workflows, selectedWorkflowsIds));
  }, [selectedServices]);

  useEffect(() => {
    setSelectedServices([
      ...getSelectedServicesInWorkflow(workflows, selectedWorkflowsIds),
    ]);
  }, []);

  const addSelectedService = (value: string) => {
    if (selectedServices) {
      setSelectedServices([...selectedServices, value]);
    }
  };

  const removeSelectedService = (value: string) => {
    if (selectedServices) {
      const array = [...selectedServices];
      const index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
        setSelectedServices(array);
      }
    }
  };

  const onServiceChecked = (e: {
    value: string;
    checked: boolean;
    name: string;
  }) => {
    return e.checked
      ? addSelectedService(e.name)
      : removeSelectedService(e.name);
  };

  return (
    <div>
      <ServicePageTitle />
      <Content>
        {!removeDisabled &&
          (selectedServices ? (
            <StyledServices
              services={services}
              selectedServices={selectedServices}
              onServiceChecked={onServiceChecked}
            />
          ) : (
            <StyledLoader />
          ))}
        <StyledServicePacks
          projectId={projectId}
          sampleId={sampleId}
          workflows={{
            recommendedWorkflows,
            suggestedWorkflows,
            othersWorkflows,
          }}
          selectedWorkflows={selectedWorkflows}
          onAction={serviceCardButtonAction}
          removeDisabled={removeDisabled}
          selectedWorkflowsIds={selectedWorkflowsIds}
        />
      </Content>
    </div>
  );
};

const StyledLoader = styled(Spinner)`
  width: 476px;
`;

const StyledServices = styled(Services)`
  width: 476px;
`;

const StyledServicePacks = styled(ServicePacksContainer)`
  width: 620px;
`;

const Content = styled.div`
  display: flex;
`;
