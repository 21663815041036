import * as React from "react";
import { FunctionComponent } from "react";
import { FieldProps } from "formik";
import styled from "styled-components";
import { FormGroup } from "../FormGroup";
import { QuestionMetadata } from "../../../../utils/types/QuestionMetadata";
import { Files } from "../../../common/FileUploader/forPrint/Files";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
}

type OwnProps = FieldProps & Props;

type FilesT = {
  fileName: string;
  s3FileName: string;
  fileSize: number;
  percent?: number;
};

type FilesToUploadT = {
  fileItem: File;
  id: string;
  generatedFileName: string;
  percent: number;
};

const parseFilesToComponent = (
  files: FilesT[],
  filesToUpload: FilesToUploadT[]
): FilesT[] => {
  const parsedFilesName: string[] = [];
  const parsedFiles = files.map((file) => {
    parsedFilesName.push(file.s3FileName);
    return {
      ...file,
      percent: 100,
    };
  });
  const parsedFilesToUpload = filesToUpload
    .map((fileToUpload) => ({
      fileName: fileToUpload.fileItem.name,
      s3FileName: fileToUpload.generatedFileName,
      fileSize: fileToUpload.fileItem.size,
      percent: fileToUpload.percent,
    }))
    .filter(
      (fileToUpload) => !parsedFilesName.includes(fileToUpload.s3FileName)
    );
  return ([] as FilesT[]).concat(parsedFiles, parsedFilesToUpload);
};

const parseFieldValue = (fieldValue: string): FilesT[] => {
  if (fieldValue?.length) {
    return JSON.parse(fieldValue);
  }
  return [];
};

export const FileField: FunctionComponent<OwnProps> = (props) => {
  const { field, label, description } = props;

  const files: FilesT[] = parseFieldValue(field.value);
  const filesToUpload: FilesToUploadT[] = [];

  return (
    <FormGroup label={label} description={description}>
      <div className="container">
        {files.length > 0 ? (
          <Files files={parseFilesToComponent(files, filesToUpload)} />
        ) : (
          <NoFilesNotification>
            No files have been uploaded.
          </NoFilesNotification>
        )}
      </div>
    </FormGroup>
  );
};

const NoFilesNotification = styled.p`
  color: #a9a9a9;
`;
