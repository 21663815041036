import * as React from "react";
import styled, { css } from "styled-components";
import { FunctionComponent, useState, useEffect } from "react";
import { Tip } from "../Tip/Tip";
import { CheckboxFill } from "./CheckboxFill";

const Root = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;

  &::before {
    content: "";
    border: 1px solid ${(props) =>
      props.theme.newColors.grayscale.tertiaryGray};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 4px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
    }
  }

  ${(props) =>
    props.checked &&
    css`
      &::before {
        background: ${({ theme }) => theme.newColors.primary.basic};
        border: none;
      }
    `}
  
  &:hover {
    &::before {
      background: ${({ theme }) => theme.newColors.grayscale.background};
      border: 1px solid ${({ theme }) =>
        theme.newColors.grayscale.tertiaryGray};
    }
  }
`;

const LabelText = styled.label<{ disabled?: boolean }>`
  margin-left: 41px;
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: ${(props) => props.theme.lineHeights[4]};
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => props.theme.newColors.grayscale.primaryGray};
  user-select: none;

  letter-spacing: -0.01em;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.newColors.grayscale.placeholder};
    `}
`;

const Container = styled.div<{ checked?: boolean; disabled?: boolean }>`
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  background: ${({ checked, theme }) =>
    checked
      ? theme.newColors.primary.lightestTint
      : theme.newColors.grayscale.white};

  box-shadow: ${({ checked, theme }) =>
    checked
      ? theme.shadows.userInputs.activeState
      : theme.shadows.userInputs.basicInside};
  border: 1px solid
    ${({ checked, theme }) =>
      checked
        ? theme.newColors.primary.basic
        : theme.newColors.grayscale.bordersInside};

  ${({ disabled, checked, theme }) =>
    disabled
      ? css`
          background: #e7e7e7;
          color: #adb1bc;
          border: 1px solid #adb1bc;
        `
      : css`
          &:hover {
            border-color: ${!checked && theme.newColors.grayscale.placeholder};
            box-shadow: ${theme.shadows.userInputs.hoveredState};

            & > ${Root} {
              &::before {
                background: ${theme.newColors.grayscale.background};
                border: 1px solid ${theme.newColors.grayscale.tertiaryGray};
              }
            }
          }
        `}
`;

const Fill = styled.div`
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${({ checked }) =>
    checked
      ? css`
          & ~ ${Fill} {
            opacity: 1;
          }
        `
      : ``}
`;

const TipContainer = styled.div`
  margin-top: 8px;
`;

const MainContainer = styled.div``;

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const [checked, setChecked] = useState(props.checked);
  const { value, labelText, name, tip, disabled } = props;

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onChange = disabled
    ? () => {}
    : () => {
        setChecked(!checked);
        props.onChange({ value, checked: !checked, name: labelText });
      };

  return (
    <MainContainer>
      <Container checked={checked} onClick={onChange} disabled={disabled}>
        <Root checked={checked}>
          <Input
            id={value}
            type="checkbox"
            onChange={onChange}
            name={name}
            value={value}
            checked={checked}
            aria-checked={checked}
          />
          <Fill>
            <CheckboxFill />
          </Fill>
          <LabelText onClick={onChange} disabled={disabled}>
            {labelText}
          </LabelText>
        </Root>
      </Container>
      {tip && tip !== "" && (
        <TipContainer>
          <Tip message={tip} color="black" />
        </TipContainer>
      )}
    </MainContainer>
  );
};

export interface CheckboxProps {
  labelText: string;
  value: string;
  tip?: string;
  name?: string;
  onChange?: any;
  checked?: boolean;
  disabled?: boolean;
}
