import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const PlusIcon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.5C5.58579 1.5 5.25 1.83579 5.25 2.25V5.25H2.25C1.83579 5.25 1.5 5.58579 1.5 6C1.5 6.41421 1.83579 6.75 2.25 6.75H5.25V9.75C5.25 10.1642 5.58579 10.5 6 10.5C6.41421 10.5 6.75 10.1642 6.75 9.75V6.75H9.75C10.1642 6.75 10.5 6.41421 10.5 6C10.5 5.58579 10.1642 5.25 9.75 5.25H6.75V2.25C6.75 1.83579 6.41421 1.5 6 1.5Z"
        fill={color}
      />
    </svg>
  );
};
PlusIcon.defaultProps = {
  width: "12",
  height: "12",
  color: styledTheme.newColors.grayscale.white,
};
