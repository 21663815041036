import React from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { GridView2Icon } from "../../../images/icons/GridView2Icon";
import { ListViewIcon } from "../../../images/icons/ListViewIcon";
// import { FilterIcon } from "../../../images/icons/FilterIcon";
// import { SortIcon } from "../../../images/icons/SortIcon";
// import { renderToStaticMarkup } from "react-dom/server";
import { styledTheme } from "../../../theme/theme";
import { ROUTES } from "../../../constants/routeConstants";
// import { TextBox } from "../../common/TextBox/TextBox";
// import { SearchIcon } from "../../../images/icons/SearchIcon";

// TODO when implementing PM view ActionsContainer need to be updated to the current design

export const ProjectsHeader = (props: ProjectsHeaderProps) => {
  const { projectsNumber, selectedView } = props;

  // const svgString = encodeURIComponent(renderToStaticMarkup(<SearchIcon />));

  return (
    <Container>
      <Header>Projects ({projectsNumber})</Header>
      <ActionsContainer>
        {/*  <TextBox placeholder="Search for a project..." svgIcon={svgString} /> */}
        {/*  <VerticalLine margin={8} /> */}
        {/*  <SortContainer> */}
        {/*    <SortIcon color={styledTheme.newColors.grayscale.tertiaryGray} /> */}
        {/*    SORT */}
        {/*  </SortContainer> */}
        {/*  <FilterContainer> */}
        {/*    <FilterIcon color={styledTheme.newColors.grayscale.tertiaryGray} /> */}
        {/*    FILTER */}
        {/*  </FilterContainer> */}
        {/*  <VerticalLine margin={8} /> */}
        <ViewModeContainer>
          <LinkContainer onClick={() => navigate(ROUTES.GRID_OF_PROJECTS)}>
            <GridView2Icon
              color={selectedView === "grid" ? "#4979CB" : undefined}
            />
          </LinkContainer>
          <VerticalLine margin={11} />
          <LinkContainer onClick={() => navigate(ROUTES.LIST_OF_PROJECTS)}>
            <ListViewIcon
              color={selectedView === "list" ? "#4979CB" : undefined}
            />
          </LinkContainer>
        </ViewModeContainer>
      </ActionsContainer>
    </Container>
  );
};

type ProjectsHeaderProps = {
  projectsNumber: number | undefined;
  selectedView?: "grid" | "list" | undefined;
};

ProjectsHeader.defaultProps = {
  projectsNumber: 0,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Header = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
`;

const ActionsContainer = styled.div`
  display: flex;

  input {
    border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
    width: 329px;
  }
`;
//
// const SortContainer = styled.div`
//   background: ${styledTheme.newColors.grayscale.white};
//   border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
//   box-sizing: border-box;
//   border-radius: 4px;
//   padding: 14px;
//   display: flex;
//   align-items: center;
//   margin-right: 8px;
//
//   font-family: Overpass;
//   font-style: normal;
//   font-weight: 800;
//   font-size: 16px;
//   line-height: 24px;
//   text-transform: uppercase;
//   color: ${styledTheme.newColors.grayscale.primaryGray};
//
//   svg {
//     margin-top: -2px;
//     margin-right: 12px;
//   }
// `;
//
// const FilterContainer = styled.div`
//   background: ${styledTheme.newColors.grayscale.white};
//   border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
//   box-sizing: border-box;
//   border-radius: 4px;
//   padding: 14px;
//   display: flex;
//   align-items: center;
//
//   font-family: Overpass;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 24px;
//   text-transform: uppercase;
//   color: #141419;
//
//   svg {
//     margin-top: -2px;
//     margin-right: 11px;
//   }
// `;

const ViewModeContainer = styled.div`
  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px 14px 12px 14px;
  display: flex;
  align-items: center;
`;

const LinkContainer = styled.span`
  cursor: pointer;
`;

const VerticalLine = styled.div<{ margin: number }>`
  font-size: 1px;
  width: 1px;
  height: 24px;
  background-color: ${styledTheme.newColors.grayscale.bordersInside};
  margin-left: ${({ margin }) => margin}px;
  margin-right: ${({ margin }) => margin}px;
`;
