import { hot } from "react-hot-loader";
import * as React from "react";
import { AuthProvider } from "react-use-auth";
import { navigate } from "@reach/router";
import config from "../config";
import "../main.css";
import { Authenticated } from "./Authenticated/Authenticated";

const MainApp = (): JSX.Element => {
  const originalPath = window.location.pathname;
  if (originalPath !== "/auth0_callback") {
    try {
      localStorage.setItem("gQ4QutxXcziDP3e2", originalPath);
    } catch (err) {
      // do nothing
    }
  }

  return (
    <AuthProvider
      navigate={navigate}
      auth0_domain={config.AUTH0_DOMAIN}
      auth0_client_id={config.AUTH0_CLIENT_ID}
      auth0_audience_domain={config.AUTH0_DOMAIN}
      auth0_params={{
        domain: config.AUTH0_DOMAIN,
        clientID: config.AUTH0_CLIENT_ID,
        redirectUri: `${window.location.origin}/auth0_callback`,
        audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
        responseType: "token id_token",
        scope: "openid profile email",
      }}
      customPropertyNamespace=""
    >
      <Authenticated />
    </AuthProvider>
  );
};

declare const module: any;

export default process.env.NODE_ENV !== "production"
  ? hot(module)(MainApp)
  : MainApp;
