/* eslint-disable react/no-array-index-key */
import React from "react";
import styled from "styled-components";
import { ExternalLinksElement } from "./ExternalLinksElement";

const parseValue = (linksValue: string): string[] => {
  if (linksValue && linksValue.length) {
    return JSON.parse(linksValue);
  }
  return [""];
};

export const ExternalLinks = (props: ExternalLinksPropsT) => {
  const { linksValue } = props;
  const links = parseValue(linksValue);

  return (
    <Container>
      <ExternalLinksElementContainer>
        {links.map((link, index) => (
          <ExternalLinksElement
            key={`externalLink${index}`}
            testId="externalLinksElement"
            id={index}
            value={link}
          />
        ))}
      </ExternalLinksElementContainer>
    </Container>
  );
};

type ExternalLinksPropsT = {
  linksValue: string;
};

ExternalLinks.defaultProps = {
  linksValue: "",
};

const Container = styled.div``;

const ExternalLinksElementContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }
`;
