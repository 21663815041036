import React from "react";
import styled from "styled-components";
import { AddIcon } from "../../../../images/icons/AddIcon";
import { styledTheme } from "../../../../theme/theme";

type AddNewSectionProps = {
  addNewQuestionGroup: () => void;
};

export const AddNewSection = ({ addNewQuestionGroup }: AddNewSectionProps) => {
  return (
    <AddNewSectionContainer onClick={addNewQuestionGroup}>
      <AddIconContainer>
        <AddIcon color={styledTheme.newColors.secondary.basic} />
      </AddIconContainer>
      <AddNewSectionText>Add a new section</AddNewSectionText>
    </AddNewSectionContainer>
  );
};

const AddNewSectionContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px;
`;

const AddNewSectionText = styled.div`
  margin-left: 8px;
  color: ${styledTheme.newColors.secondary.basic};
  font-size: 14px;
`;

const AddIconContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
