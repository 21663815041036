import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ActiveIndicator = (props: BaseIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2" height="24" rx="1" fill={color} />
    </svg>
  );
};

ActiveIndicator.defaultProps = {
  width: "2",
  height: "24",
  color: styledTheme.newColors.secondary.basic,
};
