import { InMemoryCache } from "apollo-cache-inmemory";
import { DocumentNode } from "graphql";
import { cloneDeep } from "lodash";
import { ReadQueryResult } from "../types/QueryResult";

export const updateForMutationsWithOnlyServerSideLogic = (
  variables: any,
  query: DocumentNode,
  path: string,
  mutationName: string,
  cache: InMemoryCache,
  data: any,
  parentPath: string
): void => {
  const incomingData = data?.[mutationName];
  const existingQueryResult = cloneDeep(
    cache.readQuery<ReadQueryResult>({
      query,
      variables,
    })?.[path]
  );

  if (parentPath) {
    existingQueryResult[parentPath].push(incomingData);
  }

  if (existingQueryResult && incomingData) {
    cache.writeQuery({
      variables,
      query,
      data: {
        [path]: parentPath ? existingQueryResult : incomingData,
      },
    });
  }
};
