import React from "react";
import { Redirect, Router } from "@reach/router";
import { ROUTES } from "../constants/routeConstants";
import { Welcome } from "../components/Welcome/Welcome";
import { Login } from "../components/Login/Login";
import { AfterLogin } from "../components/AfterLogin/AfterLogin";
import { WhatIsLP } from "../components/WhatIsLP/WhatIsLp";
import { Introduction } from "../components/Introduction/Introduction";
import { Projects } from "../components/Projects/Projects";
import { ProjectsList } from "../components/Projects/ProjectsList/ProjectsList";
import { ProjectConfigurator } from "../containers/ProjectConfigurator";
import { SampleConfigurator } from "../containers/SampleConfigurator";
import { ProjectTimeline } from "../components/Projects/Timeline/ProjectTimeline";
import { ResultsList } from "../components/Projects/Results/ResultsList";
import { Result } from "../components/Projects/Results/Result";
import { SamplesList } from "../components/Projects/Configurator/Samples/SamplesList";
import Auth0CallbackPage from "../components/Auth0Callback/Auth0Callback";
import Healthcheck from "../containers/Healthcheck";
import { SingleProject } from "../components/Projects/SingleProject/SingleProject";
import { Reports } from "../components/Reports/Reports";
import { SingleReportContainer } from "../containers/SingleReport";
import { ManageTeam } from "../components/ManageTeam/ManageTeam";
import { ManageTeams } from "../components/ManageTeam/ManageTeams";
import { ManageGroups } from "../components/ManageGroups/ManageGroups";
import { TasksAssignedToUser } from "../components/TasksAssignedTo/TasksAssignedToUser";
import { ManageGroupMembers } from "../components/ManageGroups/ManageGroupMembers";
import { ManageUsers } from "../components/ManageUsers/ManageUsers";
import { TasksAssignedToGroup } from "../components/TasksAssignedTo/TasksAssignedToGroup";
import { ProtectedRoute } from "../components/common/ProtectedRoute/ProtectedRoute";
import { Dashboard } from "../components/Dashboard/Dashboard";
import { ProjectTasks } from "../components/ProjectsTasks/ProjectTasks/ProjectTasks";
import { ProjectsTasks } from "../components/ProjectsTasks/ProjectsTasks";

export const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <Welcome path={ROUTES.WELCOME} default />
      <ProjectTimeline path={ROUTES.PROJECT_TIMELINE} />
      <ResultsList path={ROUTES.PROJECT_LIST_OF_RESULTS} />
      <Result path={ROUTES.PROJECT_RESULT} />
      <SamplesList path={ROUTES.LIST_OF_SAMPLES} />
      <Auth0CallbackPage path={ROUTES.AUTH0_CALLBACK} />
      <Login path={ROUTES.LOGIN} />
      <AfterLogin path={ROUTES.AFTER_LOGIN} />
      <WhatIsLP path={ROUTES.WHAT_IS_LP} />
      <Introduction path={ROUTES.INTRODUCTION} />
      <Projects path={ROUTES.GRID_OF_PROJECTS} />
      <ProjectsList path={ROUTES.LIST_OF_PROJECTS} />
      <SingleProject path={ROUTES.PROJECT_TIMELINE_WILDCARD} />
      <Dashboard path={ROUTES.DASHBOARD} />
      <ProjectsTasks path={ROUTES.TASKS} />
      <ProjectTasks path={ROUTES.TASKS_TABLE} />
      <Redirect
        noThrow
        path={ROUTES.SAMPLE}
        from={ROUTES.SAMPLE}
        to={ROUTES.SAMPLE_CONFIGURATOR}
      />
      <ProtectedRoute
        route={ProjectConfigurator}
        path={ROUTES.PROJECT_CONFIGURATOR}
      />
      <ProtectedRoute
        route={SampleConfigurator}
        path={ROUTES.SAMPLE_CONFIGURATOR}
      />
      <Healthcheck path={ROUTES.HEALTHCHECK} />
      <ProtectedRoute route={Reports} path={ROUTES.REPORTS} />
      <ProtectedRoute route={SingleReportContainer} path={ROUTES.REPORT} />
      <ProtectedRoute route={ManageTeams} path={ROUTES.MANAGE_TEAMS} />
      <ProtectedRoute route={ManageTeam} path={ROUTES.MANAGE_TEAM} />
      <ProtectedRoute route={TasksAssignedToUser} path={ROUTES.USER_TASKS} />
      <ProtectedRoute route={TasksAssignedToGroup} path={ROUTES.GROUP_TASKS} />
      <ProtectedRoute route={ManageGroups} path={ROUTES.MANAGE_GROUPS} />
      <ProtectedRoute
        route={ManageGroupMembers}
        path={ROUTES.MANAGE_GROUP_MEMBERS}
      />
      <ProtectedRoute route={ManageUsers} path={ROUTES.MANAGE_USERS} />
    </Router>
  );
};
