import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Field, FieldAttributes } from "formik";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { styledTheme } from "../../../theme/theme";
import {
  FomSchemaRendererField,
  FormSchemaField,
  Option,
  QuestionMetadata,
} from "../../../utils/types/QuestionMetadata";
import { AnswerType } from "../../../generated/graphql";
import { TextBox } from "../../common/TextBox/TextBox";
import { FormGroup } from "./FormGroup";
import {
  getFieldStatus,
  GetFieldStatusResultT,
  LabelTagTypes,
} from "../../common/LabelTag/LabelTag";

type SimpleUnitGroupFieldStatusT = {
  updateStatus?: (status: GetFieldStatusResultT) => void;
};

const TextContainer = (
  props: FormSchemaField & FieldAttributes<any> & SimpleUnitGroupFieldStatusT
) => {
  const { field, answerQuestionCallback, metadata, updateStatus, disabled } =
    props;

  const [fieldValue, setFieldValue] = useState(field.value);
  useEffect(() => {
    updateStatus(getFieldStatus(false, `${field.value}`, `${fieldValue}`));
  }, [fieldValue, field.value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(event.target.value);
    answerQuestionCallback(metadata, parseFloat(event.target.value));
  };

  return (
    <TextBox
      placeholder=""
      type="number"
      value={fieldValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

TextContainer.defaultProps = {
  updateStatus: () => {},
};

const DropdownContainer = (
  props: FormSchemaField & FieldAttributes<any> & SimpleUnitGroupFieldStatusT
) => {
  const {
    field,
    options,
    answerQuestionCallback,
    metadata,
    updateStatus,
    disabled,
  } = props;

  const [fieldValue, setFieldValue] = useState(field.value);
  useEffect(() => {
    updateStatus(getFieldStatus(false, field.value, fieldValue));
  }, [fieldValue, field.value]);

  const onChange = (option: Option) => {
    setFieldValue(option.value);
    answerQuestionCallback(metadata, option.value);
  };

  if (options.length > 1) {
    return (
      <StyledDropdown
        options={options}
        placeholder=""
        value={
          options.find((option: Option) => option.value === field.value) || ""
        }
        onChange={onChange}
        components={{
          IndicatorSeparator: null,
        }}
        isDisabled={disabled}
      />
    );
  }

  return <OneValueContainer>{options[0].label}</OneValueContainer>;
};

const StyledDropdown = styled(Dropdown)`
  min-width: 92px;
`;

DropdownContainer.defaultProps = {
  updateStatus: () => {},
};

type GetContainerPropsT = {
  name: string;
  label: string;
  placeholder?: string | null;
  description?: string | null;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  options?: Option[];
  disabled?: boolean;
};

const getContainerProps = (
  elementName: string,
  elementSchema: FormSchemaField
): GetContainerPropsT => ({
  name: elementName,
  label: elementSchema.label,
  options: elementSchema.options,
  description: elementSchema.description,
  placeholder: elementSchema.placeholder,
  answerQuestionCallback: elementSchema.answerQuestionCallback,
  metadata: elementSchema.metadata,
  required: elementSchema.required,
  disabled: elementSchema.disabled,
});

export const SimpleUnitGroup = (
  props: SimpleUnitGroupPropsT & SimpleUnitGroupFieldStatusT
) => {
  const {
    parentId,
    isRequired,
    filledRequiredItemsIds,
    updateStatus: updateStatusToParent,
  } = props;
  const [fieldStatus, setFieldStatus] = useState<GetFieldStatusResultT>();

  const textElementId = props.formSchemaItems.findIndex(
    (formSchemaItem) =>
      formSchemaItem?.type === AnswerType.TextField ||
      formSchemaItem?.type === AnswerType.Number
  );
  const dropdownElementId = props.formSchemaItems.findIndex(
    (formSchemaItem) => formSchemaItem?.type === AnswerType.OneOfMany
  );

  const updateStatus = (status: GetFieldStatusResultT) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    updateStatusToParent(status);
    setFieldStatus(status);
  };

  const textElementProps: GetContainerPropsT & SimpleUnitGroupFieldStatusT =
    getContainerProps(`${textElementId}`, props.formSchemaItems[textElementId]);
  textElementProps.updateStatus = updateStatus;

  const dropdownElementProps: GetContainerPropsT & SimpleUnitGroupFieldStatusT =
    getContainerProps(
      `${dropdownElementId}`,
      props.formSchemaItems[dropdownElementId]
    );
  dropdownElementProps.updateStatus = updateStatus;

  const statusValue = filledRequiredItemsIds.includes(parentId)
    ? "filled"
    : undefined;
  let status;
  if (fieldStatus?.type === LabelTagTypes.SAVING) {
    status = fieldStatus;
  } else {
    status = getFieldStatus(isRequired, statusValue, statusValue);
  }

  return (
    <FormGroup
      label={textElementProps.label}
      description={textElementProps.description || ""}
      status={isRequired ? status : undefined}
    >
      <Container>
        <Field component={TextContainer} {...textElementProps} />
        <Field component={DropdownContainer} {...dropdownElementProps} />
      </Container>
    </FormGroup>
  );
};

SimpleUnitGroup.defaultProps = {
  updateStatus: () => {},
  readonly: false,
};

type SimpleUnitGroupPropsT = {
  parentId: string;
  isRequired: boolean;
  formSchemaItems: FomSchemaRendererField["formSchemaItems"];
  filledRequiredItemsIds: string[];
  readonly: boolean;
};

const Container = styled.div`
  display: flex;

  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  border-radius: 4px;
  padding-left: 4px;

  >input: first-child {
    border: 0;
    border-right: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
    border-radius: 0;
  }

  > div:last-child {
    width: 110px;
  }

  .Select__control,
  .Select__control:focus {
    border: 0;
    box-shadow: none;
    height: 50px;
  }
`;

const OneValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
