import React from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { Header } from "../Header/Header";
import { Centered } from "./Centered";
import { SideBar } from "../SideBar/SideBar";

interface LayoutDefaultProps {
  pageTitle?: string;
  tabs?: React.ReactNode;
}

export const LayoutDefault: React.FC<LayoutDefaultProps> = ({
  children,
  pageTitle = "Welcome",
  tabs,
}) => (
  <Wrapper>
    <SideBar />
    <Main>
      <Header pageTitle={pageTitle} />
      <NavWrapper>{tabs && <NavigationTabs>{tabs}</NavigationTabs>}</NavWrapper>
      <Content id="children">
        <Centered>{children}</Centered>
      </Content>
    </Main>
  </Wrapper>
);

const Wrapper = styled(Flex)`
  min-height: 100vh;
`;

const NavWrapper = styled.div`
  min-height: 36px;
  width: 100%;
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const NavigationTabs = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(p): string => p.theme.maxContentWidth};
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const Main = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
`;

const Content = styled(Flex)`
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
  padding-top: ${(p): string => p.theme.space[4]};
`;
