import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "@reach/router";
import { FileLabel } from "./FileLabel";
import {
  DeleteStatus,
  useDeleteUploadedFileMutation,
  useDownloadLinkLazyQuery,
} from "../../../generated/graphql";

const FilesContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 5px 5px 0;
  }
`;

export const Files = (props: FilesProps) => {
  const { files, onDeleteFile } = props;

  if (files.length === 0) {
    return <></>;
  }

  const navigate = useNavigate();

  const [getDownloadLink, { data: downloadLinkQueryData }] =
    useDownloadLinkLazyQuery();

  const [deleteUploadedFile, { data: deleteUploadedFileMutationData }] =
    useDeleteUploadedFileMutation();

  React.useEffect(() => {
    if (downloadLinkQueryData && downloadLinkQueryData.DownloadLink) {
      navigate(downloadLinkQueryData.DownloadLink);
    }
  }, [JSON.stringify(downloadLinkQueryData)]);

  React.useEffect(() => {
    const deleteUploadedFileResult =
      deleteUploadedFileMutationData?.DeleteUploadedFile;
    if (deleteUploadedFileResult) {
      if (deleteUploadedFileResult.deleteStatus === DeleteStatus.Ok) {
        onDeleteFile(deleteUploadedFileResult.fileName);
      }
    }
  }, [JSON.stringify(deleteUploadedFileMutationData)]);

  return (
    <FilesContainer>
      {files.map((file) => (
        <FileLabel
          key={file.s3FileName}
          name={file.fileName}
          size={file.fileSize}
          percent={file.percent !== undefined ? file.percent : 100}
          onGetFileClick={(): void =>
            getDownloadLink({
              variables: {
                fileName: file.s3FileName,
                originFileName: file.fileName,
              },
            })
          }
          onDeleteFileClick={(): void => {
            deleteUploadedFile({ variables: { fileName: file.s3FileName } });
          }}
        />
      ))}
    </FilesContainer>
  );
};

Files.defaultProps = {
  onDeleteFile: () => {},
};

interface FilesProps {
  files: FileT[];
  onDeleteFile: (fileName: string) => void;
}

type FileT = {
  fileName: string;
  s3FileName: string;
  fileSize: number;
  percent?: number;
};
