import React from "react";
import styled from "styled-components";
import { styledTheme } from "../../../theme/theme";

export const SpinnerElement = (props: SpinnerElementPropsT) => {
  const { size, color, width, testId } = props;
  return (
    <StyledSpinner
      viewBox="0 0 50 50"
      size={size}
      color={color}
      data-testid={testId}
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth={width}
      />
    </StyledSpinner>
  );
};

type SpinnerElementPropsT = {
  size: number;
  color: string;
  width: number;
  testId?: string | undefined;
};

SpinnerElement.defaultProps = {
  size: 16,
  color: styledTheme.newColors.primary.basic,
  width: 2,
};

const StyledSpinner = styled.svg<{ size: number; color: string }>`
  animation: rotate 1s linear infinite;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  & .path {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
