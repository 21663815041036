import * as React from "react";
import { FieldProps } from "formik";
import { FormGroup } from "../FormGroup";
import { RadioGroup } from "../../../common/Radio/RadioGroup";
import { QuestionMetadata } from "../../../../utils/types/QuestionMetadata";
import { GetFieldStatusResultT } from "../../../common/LabelTag/LabelTag";

export const RadioGroupField = ({ field, ...props }: SelectFieldProps) => {
  const { options, label } = props;

  return (
    <FormGroup label={label}>
      <RadioGroup
        {...field}
        {...props}
        options={options.map((option) => ({
          labelText: option.label,
          value: option.value,
          disabled: true,
        }))}
        selectedValue={
          (options
            ? options.find((option) => option.value === field.value)?.value
            : "") as any
        }
        onClickRadioButton={() => null}
      />
    </FormGroup>
  );
};

type SelectFieldProps = FieldProps & {
  label: string;
  options: { value: string; label: string }[];
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled?: boolean;
};
