import React from "react";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import { ChevronDownIcon } from "../../../images/icons/ChevronDownIcon";
import { ProjectHeaderSummary } from "../common/ProjectHeaderSummary";
import { ServiceStatus } from "../common/ServiceStatus";
import { styledTheme } from "../../../theme/theme";
import {
  ProjectsListSampleBar,
  ProjectsListSampleBarProps,
} from "./ProjectsListSampleBar";
import { EntityStatus } from "../../../generated/graphql";
import { ProjectListElementTimeline } from "./ProjectListElementTimeline";

export const ProjectsListElement = (props: ProjectsListElementPropsT) => {
  const {
    projectId,
    projectName,
    projectLastActivity,
    samples,
    isOpen,
    onClick,
  } = props;

  const isDataContent = (samples && samples.length) || false;

  const [shouldRenderTimeline, setShouldRenderTimeline] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(isOpen ? -1 : 0);

  const toggleContent = () => {
    if (isDataContent) {
      setContentHeight(contentHeight === 0 ? -1 : 0);
    }
  };

  return (
    <Container>
      <Card onClick={onClick || toggleContent}>
        <IconContainer expanded={contentHeight === -1}>
          <ChevronDownIcon color={styledTheme.newColors.primary.basic} />
        </IconContainer>
        <ProjectHeaderSummary
          name={projectName}
          lastActivity={projectLastActivity}
        />
        {isDataContent ? (
          <ServiceStatusContainer>
            <ServiceStatus
              name="Protein production optimization"
              sampleName={samples[0].name}
              remaining="2w remaining"
            />
          </ServiceStatusContainer>
        ) : null}
      </Card>
      {isDataContent ? (
        <AnimateHeight
          duration={500}
          height={contentHeight === -1 ? "auto" : contentHeight}
          onAnimationEnd={() => setShouldRenderTimeline(!shouldRenderTimeline)}
        >
          <ContentContainer>
            <SamplesContainer>
              <LabelContainer>PATHS</LabelContainer>
              {samples
                .filter((sample) => sample.status === EntityStatus.Ongoing)
                .map((sample) => (
                  <ProjectsListSampleBar
                    key={sample.id}
                    id={sample.id}
                    name={sample.name}
                    percent={sample.percent}
                    status={sample.status}
                  />
                ))}
              {/* <LabelContainer>SAMPLES TO RUN</LabelContainer> */}
              {samples
                .filter((sample) => sample.status !== EntityStatus.Ongoing)
                .map((sample) => (
                  <ProjectsListSampleBar
                    key={sample.id}
                    id={sample.id}
                    name={sample.name}
                    percent={sample.percent}
                    status={sample.status}
                  />
                ))}
            </SamplesContainer>
            <TimelineContainer>
              {shouldRenderTimeline ? (
                <ProjectListElementTimeline projectId={projectId} />
              ) : null}
            </TimelineContainer>
          </ContentContainer>
        </AnimateHeight>
      ) : null}
    </Container>
  );
};

type SampleBarDataT = ProjectsListSampleBarProps & {
  id: string;
};

type ProjectsListElementPropsT = {
  projectId: string;
  projectName: string;
  projectLastActivity: string;
  samples: SampleBarDataT[];
  isOpen?: boolean;
  onClick?: () => void | undefined;
};

ProjectsListElement.defaultProps = {
  isOpen: false,
  onClick: undefined,
};

const Container = styled.div``;

const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;

  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.dropShadows.formCard};
  border-radius: 4px;
  cursor: pointer;
`;

const IconContainer = styled.div<{ expanded: boolean | undefined }>`
  width: 32px;
  height: 32px;
  min-width: 32px;
  background: ${styledTheme.newColors.primary.lightestTint};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;

  svg {
    ${({ expanded }) =>
      expanded
        ? "transform: rotate(-90deg); margin-left: 2px;"
        : "transform: rotate(0deg); margin-top: 1px;"}
  }
`;

const ServiceStatusContainer = styled.div`
  margin-left: auto;
  width: 260px;
  padding: 12px;
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 4px;
`;

const ContentContainer = styled.div`
  display: flex;
  margin: 16px 0;
`;

const SamplesContainer = styled.div`
  width: 376px;
  margin-right: 24px;
  padding-top: 64px;

  > div {
    margin-top: 8px;
  }

  > div:first-child {
    margin-top: 0;
  }
`;

const LabelContainer = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.grayscale.tertiaryGray};
`;

const TimelineContainer = styled.div`
  background-color: ${styledTheme.newColors.grayscale.white};
  flex-grow: 1;
`;
