import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const CloseFileLabelIcon = (props: BaseIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41413 5.00001L9.70713 1.70701C10.0981 1.31601 10.0981 0.684006 9.70713 0.293006C9.31613 -0.0979941 8.68413 -0.0979941 8.29313 0.293006L5.00013 3.58601L1.70713 0.293006C1.31613 -0.0979941 0.684128 -0.0979941 0.293128 0.293006C-0.0978721 0.684006 -0.0978721 1.31601 0.293128 1.70701L3.58613 5.00001L0.293128 8.29301C-0.0978721 8.68401 -0.0978721 9.31601 0.293128 9.70701C0.488128 9.90201 0.744128 10 1.00013 10C1.25613 10 1.51213 9.90201 1.70713 9.70701L5.00013 6.41401L8.29313 9.70701C8.48813 9.90201 8.74413 10 9.00013 10C9.25613 10 9.51213 9.90201 9.70713 9.70701C10.0981 9.31601 10.0981 8.68401 9.70713 8.29301L6.41413 5.00001Z"
        fill={color}
      />
    </svg>
  );
};

CloseFileLabelIcon.defaultProps = {
  width: "10",
  height: "10",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};

export default CloseFileLabelIcon;
