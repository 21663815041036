import React, { useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Spinner } from "../common/Spinner/Spinner";
import ServerError from "../ServerError";
import {
  useNewProjectMutation,
  UserPlainProjectsWithSamplesDocument,
  useUserPlainProjectsWithSamplesQuery,
} from "../../generated/graphql";
import { ROUTES } from "../../constants/routeConstants";

export const Welcome = (props: WelcomeProps) => {
  const queryResult = useUserPlainProjectsWithSamplesQuery({
    variables: {},
  });

  const [newProjectMutationCalled, setNewProjectMutationCalled] =
    useState(false);

  const [newProjectMutation, newProjectMutationResult] =
    useNewProjectMutation();

  useEffect(() => {
    if (queryResult.data && queryResult.data.Projects.length === 0) {
      if (!(newProjectMutationResult.called || newProjectMutationCalled)) {
        const options = {
          variables: {},
          refetchQueries: [
            {
              query: UserPlainProjectsWithSamplesDocument,
              variables: {},
            },
          ],
          awaitRefetchQueries: true,
        };
        setNewProjectMutationCalled(true);
        newProjectMutation(options);
      }
    }
  });

  if (queryResult.error) {
    return <ServerError />;
  }
  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const projects = queryResult.data.Projects || [];

  if (newProjectMutationResult.called || newProjectMutationCalled) {
    if (newProjectMutationResult.error) {
      return <p>Mutation Error: {newProjectMutationResult.error}</p>;
    }
    if (newProjectMutationResult.loading || !newProjectMutationResult.data) {
      return <Spinner />;
    }
  }

  // if (projects.length === 1) {
  //   if (projectHasOngoingSamples(projects[0])) {
  //     navigate(`/projects/${projects[0].id}`);
  //   } else {
  //     navigate(`/projects/${projects[0].id}/configurator`);
  //   }
  // }

  if (projects.length > 0) {
    navigate(ROUTES.GRID_OF_PROJECTS);
  }

  return <Spinner />;
};

type WelcomeProps = RouteComponentProps;
