import {
  AnswerType,
  MultiAnswerFragment,
  NumericAnswerFragment,
  QuestionGroup,
  TextAnswerFragment,
} from "../../../generated/graphql";
import { QuestionFormData } from "../../types/QuestionMetadata";

export const getFormDataFromQuestionGroup = (questionGroup: QuestionGroup) => {
  const formData: QuestionFormData = {};
  questionGroup.questionSets.forEach((questionSet) => {
    questionSet.questions.forEach((question) => {
      if (question.visibility) {
        const { answer } = question;
        switch (answer.answerType) {
          case AnswerType.Number:
            formData[question.id] =
              (answer as NumericAnswerFragment).numericBody || undefined;
            break;
          case AnswerType.ManyOfMany:
            formData[question.id] =
              (answer as MultiAnswerFragment).multiBody || undefined;
            break;
          default:
            formData[question.id] =
              (answer as TextAnswerFragment).textBody || undefined;
            break;
        }
      }
    });
  });

  return formData;
};
