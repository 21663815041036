import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import { TextBox } from "../../../../../common/TextBox/TextBox";
import { URLIcon } from "../../../../../../images/icons/URLIcon";

const svgString = encodeURIComponent(renderToStaticMarkup(<URLIcon />));

export const ExternalLinksElement = (props: ExternalLinksElementPropsT) => {
  const { value, testId } = props;

  return (
    <Container data-testid={testId}>
      <TextBox
        placeholder="https://www.example.com"
        svgIcon={svgString}
        value={value}
        testId={`${testId}_input`}
        onlyForRead
      />
    </Container>
  );
};

type ExternalLinksElementPropsT = {
  id: number;
  value: string;
  testId?: string;
};

const Container = styled.div`
  display: flex;
`;
