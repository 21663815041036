import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import moment from "moment";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import ServerError from "../ServerError";
import {
  Project,
  useUserPlainProjectsWithSamplesQuery,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { getProjectName } from "../../utils/helpers/getProjectName";
import { ProjectElement } from "../Projects/ProjectElement";
import { ProjectsHeader } from "../Projects/common/ProjectsHeader";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProjectsTasks = (_props: ProjectsTasksProps): JSX.Element => {
  const { error, loading, data } = useUserPlainProjectsWithSamplesQuery();

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  if (error) {
    return <ServerError />;
  }
  if (loading || !data) {
    return <Spinner />;
  }

  const projects = data.Projects || [];
  const projectsNumber = projects.length;

  return (
    <LayoutDefault pageTitle="All projects">
      <ProjectsHeader projectsNumber={projectsNumber} selectedView="grid" />
      <ProjectsContainer id="projects-container">
        {projects.map((project) => {
          const onClickLink = `tasks/${project.id}`;
          const projectLastActivityDateString = moment(
            project.lastActivity
              ? parseInt(project.lastActivity, 10)
              : undefined
          ).format("Do MMMM YYYY");
          return (
            <ProjectElement
              key={project.id}
              projectId={project.id}
              projectLastActivity={projectLastActivityDateString}
              projectName={getProjectName(project as unknown as Project)}
              currentService={project.currentService}
              onClick={(): Promise<void> => navigate(onClickLink)}
            />
          );
        })}
      </ProjectsContainer>
    </LayoutDefault>
  );
};

type ProjectsTasksProps = RouteComponentProps;

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;
