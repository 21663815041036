import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const TickIcon = (props: TickIconProps) => {
  const { width, height, x = 0, y = 0, color, testId } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testId}
    >
      <path
        d="M2 7L5 10L12 2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

TickIcon.defaultProps = {
  width: "14",
  height: "12",
  color: styledTheme.newColors.grayscale.white,
};

type TickIconProps = BaseIconProps & {
  x?: number;
  y?: number;
  testId?: string | undefined;
};
