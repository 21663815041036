import * as React from "react";
import styled from "styled-components";
import { BaseInput } from "../BaseInput";

const Input = styled(BaseInput)``;

export const TextBox = (props: TextBoxProps) => {
  const {
    placeholder,
    disabled,
    error,
    type,
    svgIcon,
    value,
    onChange,
    onBlur,
    onKeyDown,
    testId,
    staticForm,
    onlyForRead,
  } = props;

  const withStaticInput = (children: JSX.Element) => (
    <InputContainer>
      <StaticInput />
      {children}
    </InputContainer>
  );

  const renderInputComponent = () => (
    <Input
      type={type || "text"}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      svgIcon={svgIcon}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      data-testid={testId}
      readOnly={onlyForRead || false}
    />
  );

  return staticForm
    ? withStaticInput(renderInputComponent())
    : renderInputComponent();
};

interface TextBoxProps {
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  type?: string;
  svgIcon?: string;
  value?: string;
  onChange?: any;
  onKeyDown?: any;
  onBlur?: any;
  testId?: string;
  staticForm?: boolean;
  onlyForRead?: boolean;
}

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const StaticInput = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
`;
