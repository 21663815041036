import React, { useState } from "react";
import { navigate } from "@reach/router";
import styled, { css } from "styled-components";
import { toast } from "react-toastify";
import { ChainIcon } from "../../../../images/icons/ChainIcon";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";
import { useTasksTable } from "./useTasksTable";
import { Collaborators, EntityStatus } from "../../../../generated/graphql";
import { StatusDropdown } from "../../../common/StatusDropdown/StatusDropdown";
import { DatePicker } from "../../../common/DatePicker/DatePicker";
import { UsersSelector } from "../../../Projects/SingleProject/EditTask/UsersSelector/UsersSelector";
import { LineWithDot } from "../../../../images/icons/LineWithDot";
import { CollaboratorsEditor } from "./CollaboratorsEditor";
import { CollaboratorOption } from "../../../Projects/SingleProject/Collaborators/types";
import {
  PriceInput,
  PriceInputValue,
} from "../../../common/PriceInput/PriceInput";
import { Spinner } from "../../../common/Spinner/Spinner";

export type SimplifiedTasks = {
  id: string;
  color: string;
  name: string;
  assignee?: {
    id: string;
    name: string;
    picture: string;
  };
  startDate: Date;
  endDate: Date;
  duration: string;
  budget: PriceInputValue;
  collaborators?: Collaborators;
  subtasks: {
    id: string;
    name: string;
    status: EntityStatus;
    assignee?: {
      id: string;
      name: string;
      picture: string;
    };
    startDate: Date;
    endDate: Date;
    duration: string;
    durationLocked: boolean;
    budget: PriceInputValue;
    collaborators?: Collaborators;
  }[];
};

const getTableHeadRow = (budgetIsVisible: boolean): JSX.Element => {
  return (
    <HeadRow key="head-row">
      <th scope="col"> </th>
      <th scope="col">Name</th>
      <th scope="col">Status</th>
      <th scope="col">Assignee</th>
      <th scope="col">Start Date</th>
      <th scope="col">Due Date</th>
      <th scope="col">Duration</th>
      {budgetIsVisible ? <th scope="col">Budget</th> : null}
      <th scope="col">Collaborators</th>
      <TimelineTh scope="col">Timeline</TimelineTh>
    </HeadRow>
  );
};

interface TasksTableProps {
  projectId: string;
  sampleId: string;
  tasks: SimplifiedTasks[];
  setTasks: React.Dispatch<React.SetStateAction<SimplifiedTasks[]>>;
  showSubtasks?: boolean;
  readonly?: boolean;
  reloadTasks?: () => void;
}

export const TasksTable = (props: TasksTableProps): JSX.Element => {
  const {
    projectId,
    sampleId,
    tasks,
    setTasks,
    showSubtasks = true,
    readonly = false,
    reloadTasks,
  } = props;

  const { updateTask, updateSubtask, addSubtask, users, loading, showSpinner } =
    useTasksTable({
      projectId,
      sampleId,
      reloadTasks,
    });

  const { useUserPermissions } = useUserRolesContext();
  const budgetIsVisible = useUserPermissions("seeBudget");

  const [selectedDatePickerId, setSelectedDatePickerId] = useState<
    number | string | null
  >(null);

  const updateState = (
    taskId: string,
    updatables: {
      key: string;
      value:
        | string
        | { groupIds: string[]; userIds: string[] }
        | PriceInputValue
        | Date;
    }[],
    subtaskId?: string
  ): void => {
    if (subtaskId) {
      setTasks((prevTasks) => {
        const newTasks = [...prevTasks];
        const taskIndex = newTasks.findIndex((task) => task.id === taskId);
        const subtaskIndex = newTasks[taskIndex].subtasks.findIndex(
          (subtask) => subtask.id === subtaskId
        );

        updatables.forEach(({ key, value }) => {
          newTasks[taskIndex].subtasks[subtaskIndex][key] = value;
        });

        return newTasks;
      });
      return;
    }

    setTasks((prevTasks) => {
      const newTasks = [...prevTasks];
      const taskIndex = newTasks.findIndex((task) => task.id === taskId);

      updatables.forEach(({ key, value }) => {
        newTasks[taskIndex][key] = value;
      });

      return newTasks;
    });
  };

  const handleNameChange = (
    taskId: string,
    name: string,
    subtaskId?: string
  ): void => {
    if (subtaskId) {
      updateState(taskId, [{ key: "name", value: name }], subtaskId);
      updateSubtask(taskId, subtaskId, "name", name);
      return;
    }

    updateState(taskId, [{ key: "name", value: name }]);
    updateTask(taskId, "name", name);
  };

  const handleSubtaskStatusChange = (
    taskId: string,
    subtaskId: string,
    status: EntityStatus
  ): void => {
    updateState(taskId, [{ key: "status", value: status }], subtaskId);
    updateSubtask(taskId, subtaskId, "status", status);
  };

  const handleSubtaskStartDateChange = (
    taskId: string,
    subtaskId: string,
    startDate: Date,
    oldStartDate: Date,
    endDate: Date,
    lockedDuration?: boolean
  ): void => {
    if (startDate > endDate && !lockedDuration) {
      toast.error("You cannot set the start date later than the end date");
      return;
    }

    const copyOfNewDate = startDate;
    const startDateWithCorrectHours = new Date(
      copyOfNewDate.setHours(0, 0, 0, 0)
    );

    if (lockedDuration) {
      const dateDifference = startDate.valueOf() - oldStartDate.valueOf();

      const newEndValueDate = endDate.valueOf() + dateDifference;
      const newEndDate = new Date(+newEndValueDate);
      const newEndDateWithCorrectHours = new Date(
        newEndDate.setHours(23, 59, 59, 59)
      );

      updateState(
        taskId,
        [
          { key: "startDate", value: startDateWithCorrectHours },
          { key: "endDate", value: newEndDateWithCorrectHours },
        ],
        subtaskId
      );
      updateSubtask(taskId, subtaskId, "lockDate", null, [
        startDateWithCorrectHours.getTime().toString(),
        newEndDateWithCorrectHours.getTime().toString(),
      ]);
    } else {
      updateState(
        taskId,
        [{ key: "startDate", value: startDateWithCorrectHours }],
        subtaskId
      );
      updateSubtask(
        taskId,
        subtaskId,
        "startDate",
        startDateWithCorrectHours.getTime().toString()
      );
    }
  };

  const handleSubtaskEndDateChange = (
    taskId: string,
    subtaskId: string,
    endDate: Date,
    oldEndDate: Date,
    startDate: Date,
    lockedDuration?: boolean
  ): void => {
    if (endDate < startDate && !lockedDuration) {
      toast.error("You cannot set the end date earlier than the start date");
      return;
    }

    const copyOfNewDate = endDate;
    const endDateWithCorrectHours = new Date(
      copyOfNewDate.setHours(23, 59, 59, 59)
    );

    if (lockedDuration) {
      const dateDifference = endDate.valueOf() - oldEndDate.valueOf();

      const newStartValueDate = startDate.valueOf() + dateDifference;
      const newStartDate = new Date(+newStartValueDate);

      const newStartDateWithCorrectHours = new Date(
        newStartDate.setHours(0, 0, 0, 0)
      );

      updateState(
        taskId,
        [
          { key: "startDate", value: newStartDateWithCorrectHours },
          { key: "endDate", value: endDateWithCorrectHours },
        ],
        subtaskId
      );
      updateSubtask(taskId, subtaskId, "lockDate", null, [
        newStartDateWithCorrectHours.getTime().toString(),
        endDateWithCorrectHours.getTime().toString(),
      ]);
    } else {
      updateState(
        taskId,
        [{ key: "endDate", value: endDateWithCorrectHours }],
        subtaskId
      );
      updateSubtask(
        taskId,
        subtaskId,
        "endDate",
        endDateWithCorrectHours.getTime().toString()
      );
    }
  };

  const handleAssigneeChange = (
    taskId: string,
    assignee: string,
    subtaskId?: string
  ): void => {
    if (subtaskId) {
      updateState(taskId, [{ key: "assignee", value: assignee }], subtaskId);
      updateSubtask(taskId, subtaskId, "assignee", assignee);
      return;
    }

    updateState(taskId, [{ key: "assignee", value: assignee }]);
    updateTask(taskId, "assignee", assignee);
  };

  const handleNavigateTo = (taskId: string, subtaskId?: string): void => {
    let url = `/projects/${projectId}/timeline/samples/${sampleId}/workflows/${taskId}`;

    if (subtaskId) {
      url = `${url}/services/${subtaskId}`;
    }

    navigate(url);
  };

  const handleCollaboratorsChange = (
    taskId: string,
    collaborators: CollaboratorOption[],
    subtaskId?: string
  ): void => {
    const updatedCollaborators = {
      groupIds: collaborators
        ? collaborators
            .filter((x) => x.value.type === "group")
            .map((x) => x.value.id)
        : [],
      userIds: collaborators
        ? collaborators
            .filter((x) => x && x.value.type === "user")
            .map((x) => x.value.id)
        : [],
    };

    if (subtaskId) {
      updateState(
        taskId,
        [{ key: "collaborators", value: updatedCollaborators }],
        subtaskId
      );
      updateSubtask(taskId, subtaskId, "collaborators", updatedCollaborators);
      return;
    }

    updateState(taskId, [
      { key: "collaborators", value: updatedCollaborators },
    ]);
    updateTask(taskId, "collaborators", updatedCollaborators);
  };

  const handleBudgetChange = (
    taskId: string,
    price: PriceInputValue,
    subtaskId?: string
  ): void => {
    if (subtaskId) {
      updateState(taskId, [{ key: "budget", value: price }], subtaskId);
      return;
    }

    updateState(taskId, [{ key: "budget", value: price }]);
  };

  const handleBudgetBlur = (
    taskId: string,
    price: PriceInputValue,
    subtaskId?: string
  ): void => {
    try {
      localStorage.setItem("preferredCurrency", price.currency);
    } catch (err) {
      // do nothing
    }

    const priceToSet = {
      price: {
        value: price.price,
      },
      currency: price.currency,
    };

    if (subtaskId) {
      updateState(taskId, [{ key: "budget", value: price }], subtaskId);
      updateSubtask(taskId, subtaskId, "price", priceToSet);
      return;
    }

    updateState(taskId, [{ key: "budget", value: price }]);
    updateTask(taskId, "price", priceToSet);
  };

  const handleAddSubtask = (taskId: string): void => {
    addSubtask(taskId);
  };

  const menuPortalTarget = document.querySelector("#children");

  const budgets: number[] = [];
  tasks.forEach((task) => {
    if (task.budget.price) {
      budgets.push(parseFloat(task.budget.price));
    }

    task.subtasks.forEach((subtask) => {
      if (subtask.budget.price) {
        budgets.push(parseFloat(subtask.budget.price));
      }
    });
  });
  const maxBudgetWidth =
    budgets.length > 0 ? Math.max(...budgets).toString().length : 0;

  return (
    <Container>
      <Table>
        <thead>{getTableHeadRow(budgetIsVisible)}</thead>
        <tbody>
          {tasks.map((task) => (
            <>
              <Row key={task.id}>
                <IconContainer color={task.color}>
                  <ChainIcon color={task.color} />
                </IconContainer>
                <td>
                  <Text
                    contentEditable={!readonly}
                    suppressContentEditableWarning={!readonly}
                    readonly={readonly}
                    spellCheck={false}
                    onBlur={(event): void =>
                      handleNameChange(task.id, event.target.innerText)
                    }
                    onKeyDown={(event): void => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    }}
                  >
                    {task.name}
                  </Text>
                </td>
                <td> </td>
                <td>
                  <StyledUsersSelector
                    users={users}
                    loading={loading}
                    editMode={!readonly}
                    selectedValue={task.assignee?.id}
                    onChange={(assignee: string): void =>
                      handleAssigneeChange(task.id, assignee)
                    }
                    disabled={readonly}
                    smallMenuItems
                    readonly={readonly}
                    menuPortalTarget={menuPortalTarget}
                  />
                </td>
                <DatePickerContainer>
                  <StyledDatePicker
                    date={task.startDate}
                    editMode={false}
                    selectedDatePickerId={selectedDatePickerId}
                    setSelectedDatePickerId={setSelectedDatePickerId}
                    datePickerId={`start-date-${task.id}`}
                    readonly={readonly}
                  />
                </DatePickerContainer>
                <DatePickerContainer>
                  <StyledDatePicker
                    date={task.endDate}
                    editMode={false}
                    selectedDatePickerId={selectedDatePickerId}
                    setSelectedDatePickerId={setSelectedDatePickerId}
                    datePickerId={`end-date-${task.id}`}
                    readonly={readonly}
                  />
                </DatePickerContainer>
                <td>{task.duration}</td>
                {budgetIsVisible && (
                  <PriceContainer>
                    <PriceInput
                      editMode={!readonly}
                      value={task.budget}
                      onChange={(price): void =>
                        handleBudgetChange(task.id, price)
                      }
                      onBlur={(price): void => handleBudgetBlur(task.id, price)}
                      maxWidth={maxBudgetWidth}
                      small
                    />
                  </PriceContainer>
                )}
                <td>
                  <StyledCollaboratorsDropdown
                    projectId={projectId}
                    readonly={readonly}
                    collaborators={task.collaborators}
                    onChange={(collaborators): void =>
                      handleCollaboratorsChange(task.id, collaborators)
                    }
                    menuPortalTarget={menuPortalTarget}
                  />
                </td>
                <LinkContainer>
                  <span
                    aria-hidden
                    onClick={(): void => handleNavigateTo(task.id)}
                  >
                    Show &#10140;
                  </span>
                </LinkContainer>
              </Row>
              {showSubtasks &&
                task.subtasks.map((subtask, subtaskIndex) => (
                  <Row key={subtask.id}>
                    <DotContainer
                      color={task.color}
                      lastSubtask={task.subtasks.length - 1 === subtaskIndex}
                    >
                      <div>
                        <LineWithDot color={task.color} />
                      </div>
                    </DotContainer>
                    <td>
                      <Text
                        contentEditable={!readonly}
                        suppressContentEditableWarning={!readonly}
                        readonly={readonly}
                        spellCheck={false}
                        onBlur={(e): void =>
                          handleNameChange(
                            task.id,
                            e.target.innerText.trim(),
                            subtask.id
                          )
                        }
                        onKeyDown={(event): void => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                          }
                        }}
                      >
                        {subtask.name}
                      </Text>
                    </td>
                    <td>
                      <StyledStatusDropdown
                        onStatusChange={(status: EntityStatus): void =>
                          handleSubtaskStatusChange(task.id, subtask.id, status)
                        }
                        status={subtask.status}
                        readonly={readonly}
                        menuPortalTarget={menuPortalTarget}
                      />
                    </td>
                    <td>
                      <StyledUsersSelector
                        users={users}
                        loading={loading}
                        editMode={!readonly}
                        selectedValue={subtask.assignee?.id || ""}
                        onChange={(value): void =>
                          handleAssigneeChange(task.id, value, subtask.id)
                        }
                        disabled={readonly}
                        readonly={readonly}
                        smallMenuItems
                        menuPortalTarget={menuPortalTarget}
                      />
                    </td>
                    <DatePickerContainer>
                      <StyledDatePicker
                        date={subtask.startDate}
                        setDate={(date): void =>
                          handleSubtaskStartDateChange(
                            task.id,
                            subtask.id,
                            date,
                            subtask.startDate,
                            subtask.endDate,
                            subtask.durationLocked
                          )
                        }
                        editMode={!readonly}
                        selectedDatePickerId={selectedDatePickerId}
                        setSelectedDatePickerId={setSelectedDatePickerId}
                        datePickerId={`start-date-${subtask.id}`}
                        readonly={readonly}
                      />
                    </DatePickerContainer>
                    <DatePickerContainer>
                      <StyledDatePicker
                        date={subtask.endDate}
                        setDate={(date): void =>
                          handleSubtaskEndDateChange(
                            task.id,
                            subtask.id,
                            date,
                            subtask.endDate,
                            subtask.startDate,
                            subtask.durationLocked
                          )
                        }
                        editMode={!readonly}
                        selectedDatePickerId={selectedDatePickerId}
                        setSelectedDatePickerId={setSelectedDatePickerId}
                        datePickerId={`end-date-${subtask.id}`}
                        readonly={readonly}
                      />
                    </DatePickerContainer>
                    <td>{subtask.duration}</td>
                    {budgetIsVisible && (
                      <PriceContainer>
                        <PriceInput
                          editMode={!readonly}
                          value={subtask.budget}
                          onChange={(price): void =>
                            handleBudgetChange(task.id, price, subtask.id)
                          }
                          onBlur={(price): void =>
                            handleBudgetBlur(task.id, price, subtask.id)
                          }
                          maxWidth={maxBudgetWidth}
                          small
                        />
                      </PriceContainer>
                    )}
                    <td>
                      <StyledCollaboratorsDropdown
                        projectId={projectId}
                        readonly={readonly}
                        collaborators={subtask.collaborators}
                        onChange={(collaborators): void =>
                          handleCollaboratorsChange(
                            task.id,
                            collaborators,
                            subtask.id
                          )
                        }
                        menuPortalTarget={menuPortalTarget}
                      />
                    </td>
                    <LinkContainer>
                      <span
                        aria-hidden
                        onClick={(): void =>
                          handleNavigateTo(task.id, subtask.id)
                        }
                      >
                        Show &#10140;
                      </span>
                    </LinkContainer>
                  </Row>
                ))}
              {!readonly && (
                <Row>
                  <ClickableTd
                    aria-hidden
                    colSpan={2}
                    onClick={(): void => {
                      handleAddSubtask(task.id);
                    }}
                  >
                    + Add subtask
                  </ClickableTd>
                </Row>
              )}
            </>
          ))}
        </tbody>
      </Table>
      {showSpinner ? <Spinner opaque /> : null}
    </Container>
  );
};

const Container = styled.div`
  overflow: auto;
  width: 100%;
`;

const ClickableTd = styled.td`
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  font-size: 11px;
  height: 1px;
`;

const HeadRow = styled.tr`
  font-size: 12px;
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  th {
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
  }

  th:first-child {
    padding-left: 12px;
  }

  th:last-child {
    padding-right: 12px;
  }
`;

const TimelineTh = styled.th`
  padding-left: 12px;
`;

const Row = styled.tr`
  height: 100%;
  border-bottom: 0.1px solid #f3efef;

  td {
    padding: 6px;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  td:first-child {
    padding-left: 12px;
  }

  td:last-child {
    padding-right: 12px;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f5f5f5;

    #date-picker {
      background-color: #f5f5f5;
    }
  }
`;

const DotContainer = styled.td<{ color: string; lastSubtask: boolean }>`
  padding-top: 0;
  padding-bottom: 0;
  height: inherit;

  & > div {
    margin-left: 8px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: inherit;

    &:after {
      content: "";
      position: absolute;
      height: calc(100% + 14px);
      top: -7px;
      left: -0.5px;
      border-left: 1px solid
        ${(props): string => (props.color ? props.color : "black")};

      ${({ lastSubtask }) =>
        lastSubtask &&
        css`
          top: -7px;
          height: calc(50% + 7px);
        `}
    }
  }
`;

const Text = styled.div<{
  readonly: boolean;
}>`
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 3px 3px 3px 0;
  overflow-wrap: break-word;
  text-transform: none;
  white-space: pre-wrap;
  position: relative;

  ${({ readonly }) =>
    !readonly &&
    css`
      padding: 3px 18px 3px 3px;

      &:empty::before {
        content: attr(data-placeholder-name);
        color: #ccc;
        font-weight: 400;
      }
      &:empty:focus::before {
        content: "";
      }

      &::after {
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.5 8.75V6.5C0.5 6.30109 0.579018 6.11032 0.71967 5.96967L5.96967 0.71967C6.26256 0.426777 6.73744 0.426777 7.03033 0.71967L9.28033 2.96967C9.57322 3.26256 9.57322 3.73744 9.28033 4.03033L4.03033 9.28033C3.88968 9.42098 3.69891 9.5 3.5 9.5H1.25C0.835786 9.5 0.5 9.16421 0.5 8.75ZM6.5 2.3107L2 6.8107V8.00004H3.18934L7.68934 3.50004L6.5 2.3107Z' fill='gray' /%3E%3C/svg%3E");
        margin-left: 8px;
        width: 10px;
        height: 10px;
        position: absolute;
        cursor: text;
        display: inline;
        white-space: nowrap;
      }

      min-width: calc(100% - 25px);
      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px
          ${(props): string => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `}
`;

const StyledCollaboratorsDropdown = styled(CollaboratorsEditor)`
  max-width: 180px;

  & > div > div:not([class^="Select__control"]) {
    &:not(:last-child) {
      border: 1px solid white;
      border-radius: 50%;
      margin-right: 0;
    }
  }

  .Select__control {
    padding: 0 0 0 3px;
    font-size: 11px;
    line-height: 16px;
  }

  .Select__indicator {
    padding: 2px;
  }
  .Select__value-container .Select__option {
    padding: 6px;
    font-size: 11px;
    line-height: 16px;
  }

  .Select__option {
    font-size: 11px;
    font-weight: normal;
    line-height: 16px;
    padding: 6px;
  }

  .Select__single-value {
    font-size: 11px;
    font-weight: normal;
    line-height: 16px;
  }
`;

const StyledStatusDropdown = styled(StatusDropdown)`
  width: 85px;

  .Select__control {
    padding: 0 0 0 3px;
    font-size: 11px;
    line-height: 16px;
  }

  .Select__indicator {
    padding: 2px;
  }

  .Select__option {
    padding: 8px 8px 8px 6px;
    font-size: 11px;
    line-height: 16px;
  }
`;

const IconContainer = styled.td<{ color: string }>`
  width: 1px;
  white-space: nowrap;
  padding: 0 12px 0 24px;
  position: relative;

  svg {
    margin-top: 4px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
  }

  &:after {
    content: "";
    position: absolute;
    height: 40%;
    top: 61%;
    left: 19.5px;
    border-left: 1px solid
      ${(props): string => (props.color ? props.color : "black")};
  }
`;

const LinkContainer = styled.td`
  span {
    transition: 300ms;
    cursor: pointer;
    color: #3b79d1;
  }

  span:hover {
    transition: 300ms;
    font-weight: bold;
    color: #1f5fbc;
  }
`;

const DatePickerContainer = styled.td`
  width: 1px;
  white-space: nowrap;
`;

const StyledDatePicker = styled(DatePicker)<{ readonly: boolean }>`
  #date-picker {
    margin: 0;
    font-weight: 300;
    font-size: 11px;
    width: 120px;
    text-transform: capitalize;
  }

  #date-picker:hover {
    ${({ readonly }) =>
      !readonly &&
      css`
        font-weight: bold;
        transition: 200ms;
      `}
  }
`;

const PriceContainer = styled.td`
  width: 1px;
  white-space: nowrap;
`;

const StyledUsersSelector = styled(UsersSelector)<{ readonly: boolean }>`
  margin: 0;
  display: block;
  padding: 0;
  height: auto;
  width: max-content;
  min-width: 140px;
  position: initial;

  ${({ readonly }) =>
    readonly &&
    css`
      display: flex;

      & > div {
        max-width: 100px;
      }
    `}

  & > div {
    width: auto;
  }

  .Select__control {
    padding: 0 0 0 4px;
    font-size: 11px;
    line-height: 16px;
  }

  .Select__indicator {
    padding: 2px;
  }

  .Select__option {
    padding: 8px 8px 8px 6px;
    font-size: 11px;
    line-height: 16px;
  }

  .Select__value-container > div > div {
    font-size: 11px;
    margin-left: 5px;
    width: auto;
  }

  .Select__menu-list > div > div {
    margin-left: 5px;
    width: auto;
  }
`;
