import React from "react";
import styled from "styled-components";
import { CheckboxSimple } from "../../common/Checkbox/CheckboxSimple";
import { AssignedSample } from "./getPathsAssignedToUser";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { ROLES } from "../../../constants/roles";
import { Option } from "../../../utils/types/QuestionMetadata";

const dropdownOptions = [
  { value: ROLES.ADMINISTRATOR, label: ROLES.ADMINISTRATOR },
  {
    value: ROLES.ORGANIZATION_ADMINISTRATOR,
    label: ROLES.ORGANIZATION_ADMINISTRATOR,
  },
  { value: ROLES.PROJECT_MANAGER, label: ROLES.PROJECT_MANAGER },
  { value: ROLES.READONLY, label: ROLES.READONLY },
  { value: ROLES.SPECIALIST, label: ROLES.SPECIALIST },
];

interface PathAccessManagementModalBodyProps {
  assignedSamples: AssignedSample[];
  user: { id: string; name: string; role: string };
  onCheckboxChange: (sampleId: string, assigned: boolean) => void;
  onDropdownChange: (sampleId: string, role: string) => void;
  canUserModifyOverview: boolean;
}

export const PathAccessManagementModalBody = (
  props: PathAccessManagementModalBodyProps
): JSX.Element => {
  const {
    assignedSamples,
    onCheckboxChange,
    onDropdownChange,
    user,
    canUserModifyOverview,
  } = props;

  return (
    <Container>
      <Title>Samples</Title>
      <SamplesContainer>
        {assignedSamples.map((assignedSample, index) => {
          const dropdownOption = dropdownOptions.find(
            (dO) => dO.value === assignedSample.role
          );
          const userOptionRoleIndex = dropdownOptions.findIndex(
            (dO) => dO.value === user.role
          );
          const filteredDropdownOptions = dropdownOptions.filter(
            (_, i) => i >= userOptionRoleIndex
          );

          return (
            <Row key={assignedSample.id}>
              <SubContainer>
                <CheckboxSimple
                  checked={assignedSample.assigned}
                  onChange={(): void =>
                    onCheckboxChange(
                      assignedSample.id,
                      !assignedSample.assigned
                    )
                  }
                  disabled={index === 0 && !canUserModifyOverview}
                />
                <SampleName>{assignedSample.name}</SampleName>
              </SubContainer>
              <StyledDropdown
                options={filteredDropdownOptions}
                value={assignedSample.assigned ? dropdownOption : undefined}
                placeholder=""
                onChange={(option: Option): void => {
                  onDropdownChange(assignedSample.id, option.value);
                }}
                isDisabled={
                  (index === 0 && !canUserModifyOverview) ||
                  !assignedSample.assigned
                }
              />
            </Row>
          );
        })}
      </SamplesContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SamplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const SampleName = styled.div`
  margin-top: 1px;
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 260px;
`;
