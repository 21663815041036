/* eslint-disable react/no-array-index-key */
import React from "react";
import styled from "styled-components";
import { AnswerElementList } from "./AnswerElementList";
import { styledTheme } from "../../../../../theme/theme";
import { PlusIcon } from "../../../../../images/icons/PlusIcon";

export const AnswerList = (props: AnswerListPropsT) => {
  const { title, onUpdateFinishCallback, answerValues } = props;
  const [answers, setAnswers] = React.useState(answerValues);

  const updateAnswerList = (list: string[]) => {
    if (onUpdateFinishCallback) {
      onUpdateFinishCallback(list);
    }
  };

  const updateAnswer = (id: number, value: string): void => {
    const answersArray = [...answers];
    answersArray[id] = value;
    setAnswers(answersArray);
  };

  const deleteAnswer = (id: number): void => {
    const answersArray = [...answers];
    answersArray.splice(id, 1);
    setAnswers(answersArray);
    updateAnswerList(answersArray);
  };

  const addAnswer = (): void => {
    const answersArray = [...answers];
    answersArray.push("");
    setAnswers(answersArray);
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <AnswerListElementContainer>
        {answers.map((answer, index) => (
          <AnswerElementList
            key={`answerList${index}`}
            testId="answerListElement"
            id={index}
            value={answer}
            onChange={(answerValue: string): void =>
              updateAnswer(index, answerValue)
            }
            onBlur={() => updateAnswerList(answers)}
            onDelete={(answerId: number): void => deleteAnswer(answerId)}
          />
        ))}
      </AnswerListElementContainer>
      <AddAnswerContainer
        onClick={addAnswer}
        data-testid="answerList_addButton"
      >
        <PlusIcon color={styledTheme.newColors.primary.basic} />
        ADD ANSWER
      </AddAnswerContainer>
    </Container>
  );
};

type AnswerListPropsT = {
  title?: string;
  onUpdateFinishCallback?: (state: string[]) => void;
  answerValues: string[];
};

AnswerList.defaultProps = {
  onUpdateFinishCallback: () => {},
  answerValues: [],
};

const Container = styled.div`
  margin: 16px;
`;

const Title = styled.div`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.newColors.primary.basic};
  font-size: 0.8rem;
`;

const AnswerListElementContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }
`;

const AddAnswerContainer = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.primary.basic};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 180px;

  svg {
    margin-right: 10px;
    transform: scale(1.5);
  }
`;
