import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ChainIcon = (props: ChainIconProps) => {
  const {
    width,
    height,
    x = 0,
    y = 0,
    color,
    secondColor,
    onClick,
    className,
    id,
  } = props;
  return (
    <svg
      id={id}
      x={x}
      y={y}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill={color}
      />
      <g clipPath="url(#clipChain)">
        <path
          d="M10.1549 8.00876C10.1581 6.91776 9.56731 5.90475 8.52609 5.20043C8.84365 4.93466 9.11217 4.63212 9.31692 4.30583C9.63476 3.79941 9.80274 3.23262 9.80274 2.66675H9.17843C9.17843 2.92176 9.1365 3.17227 9.05609 3.41384H6.90253C6.82212 3.17229 6.78019 2.92176 6.78019 2.66675H6.15588C6.15588 3.23262 6.32386 3.79941 6.64169 4.30583C6.84645 4.6321 7.11496 4.93464 7.43255 5.20043C6.39135 5.90475 5.8005 6.91774 5.80372 8.00876C5.80693 9.09433 6.39707 10.1 7.43215 10.8C7.11823 11.0628 6.85236 11.3614 6.64854 11.6835C6.32625 12.1928 6.1559 12.7634 6.1559 13.3334H6.78021C6.78021 13.0862 6.81964 12.8433 6.89512 12.6088H9.06356C9.13904 12.8433 9.17847 13.0862 9.17847 13.3334H9.80278C9.80278 12.7634 9.63243 12.1928 9.31014 11.6835C9.10632 11.3614 8.84045 11.0628 8.52653 10.8C9.56157 10.1 10.1517 9.09433 10.1549 8.00876ZM6.44932 8.31224H9.50929C9.47725 8.55022 9.40895 8.7824 9.30654 9.00487H6.6521C6.54969 8.7824 6.48137 8.55022 6.44932 8.31224ZM9.50934 7.68793H6.44928C6.48135 7.45004 6.54952 7.21782 6.65202 6.9953H9.3066C9.40909 7.21782 9.47727 7.45004 9.50934 7.68793ZM8.74859 4.03815C8.55323 4.33172 8.29455 4.60215 7.97932 4.83874C7.66408 4.60215 7.40541 4.33174 7.21004 4.03815H8.74859ZM7.94361 5.61349L7.97932 5.59168L8.01505 5.61351C8.37153 5.83127 8.67147 6.08757 8.90819 6.37099H7.05043C7.28715 6.08755 7.58711 5.83125 7.94361 5.61349ZM7.19479 11.9845C7.39186 11.6821 7.65572 11.4038 7.97932 11.1611C8.30292 11.4038 8.56677 11.6821 8.76385 11.9845H7.19479ZM7.99426 10.3995C7.98922 10.4025 7.98433 10.4057 7.97932 10.4088C7.9743 10.4057 7.96941 10.4025 7.96436 10.3995C7.59861 10.179 7.29127 9.91829 7.04989 9.6292H8.90873C8.66735 9.91827 8.36 10.179 7.99426 10.3995Z"
          fill={secondColor}
        />
      </g>
      <defs>
        <clipPath id="clipChain">
          <rect
            width="10.6667"
            height="10.6667"
            fill={color}
            transform="translate(2.66663 2.66675)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ChainIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.white,
  secondColor: styledTheme.newColors.grayscale.background,
};

type ChainIconProps = BaseIconProps & {
  secondColor: string;
  className?: string;
  x?: number;
  y?: number;
  id?: string;
};
