import { Service, Workflow } from "../helpers/TypesForServicePackages";

const getSelectedServicesCount = (services: Service[]) => {
  return services.filter((service) => service.selected).length;
};

export const sortWorkflows = (arrayToSort: Workflow[]) => {
  const array = [...arrayToSort];

  const sortedArray = array
    .sort((w1, w2) => {
      const w1ServicesCount = getSelectedServicesCount(w1.services);
      const w2ServicesCount = getSelectedServicesCount(w2.services);

      if (w1ServicesCount < w2ServicesCount) {
        return 1;
      }

      if (w1ServicesCount > w2ServicesCount) {
        return -1;
      }

      return 0;
    })
    .sort((w1, w2) => {
      const w1ServicesCount = getSelectedServicesCount(w1.services);
      const w2ServicesCount = getSelectedServicesCount(w2.services);

      if (
        w1ServicesCount < w2ServicesCount ||
        w1.services.length > w2.services.length
      ) {
        return 1;
      }

      if (
        w1ServicesCount > w2ServicesCount ||
        w1.services.length < w2.services.length
      ) {
        return -1;
      }

      return 0;
    });

  return sortedArray;
};
