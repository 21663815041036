import { Project, Sample } from "../../../generated/graphql";
import { getProjectName } from "../../../utils/helpers/getProjectName";
import { getSampleName } from "../../../utils/helpers/getSampleName";
import {
  convertFractionToPercent,
  getSampleProgress,
} from "../../../utils/helpers/sampleProjectHelper";
import {
  GhostTimelineElements,
  GhostTimelineEvents,
  GhostTimelineServices,
} from "../../../utils/types/GhostTimelineElementsTypes";

export type ProjectEssentialData = {
  id: string;
  name: string;
  samples: {
    id: string;
    name: string;
  }[];
};

export type SamplesDataList = {
  id: string;
  name: string;
  percent: number;
  status: string;
}[];

export const getProjectsList = (
  projects: ProjectEssentialData[]
): ProjectEssentialData[] =>
  projects.map((project) => {
    const { id, samples } = project;
    return {
      id,
      name: getProjectName(project as unknown as Project),
      samples,
    };
  });

export const getSamples = (project: Project | undefined): SamplesDataList => {
  if (!project) return [];

  return project.samples.map((sample: Sample) => {
    const { id, status } = sample;

    const percent = convertFractionToPercent(getSampleProgress(sample));
    return {
      id,
      name: getSampleName(sample),
      percent,
      status,
    };
  });
};

export const getGhostTimelineElements = (
  project: Project
): GhostTimelineElements | undefined => {
  if (!project.visibleTimelineElements?.samples) return undefined;

  const ghostTimelineElements: GhostTimelineElements = {
    ghostServices: [],
    ghostEvents: [],
  };

  project.visibleTimelineElements.samples.forEach((visibleSample) => {
    const projectSample = project.samples.find(
      (sample) => sample.id === visibleSample?.id
    );

    if (!projectSample) return;

    visibleSample?.workflows?.forEach((visibleWorkflow) => {
      const sampleWorkflowIndex = projectSample.enabledWorkflows.findIndex(
        (enabledWorkflow) => enabledWorkflow.id === visibleWorkflow?.id
      );

      if (sampleWorkflowIndex === -1) return;

      const newGhostTimelineServices: GhostTimelineServices = {
        sampleId: projectSample.id,
        sampleName: projectSample.name,
        workflowId: projectSample.enabledWorkflows[sampleWorkflowIndex].id,
        workflowIndex: sampleWorkflowIndex,
        workflowName: projectSample.enabledWorkflows[sampleWorkflowIndex].name,
        services: [],
      };

      visibleWorkflow?.services?.forEach((visibleService) => {
        const workflowService = projectSample.enabledWorkflows[
          sampleWorkflowIndex
        ].services.find((service) => service.id === visibleService);

        if (!workflowService) return;

        newGhostTimelineServices.services.push(workflowService);
      });

      ghostTimelineElements.ghostServices.push(newGhostTimelineServices);
    });

    if (visibleSample?.events) {
      const newGhostTimelineEvents: GhostTimelineEvents = {
        sampleId: projectSample.id,
        sampleName: projectSample.name,
        events: [],
      };

      visibleSample.events?.forEach((visibleEvent) => {
        const eventToAdd = projectSample.events.find(
          (event) => event.id === visibleEvent
        );

        if (!eventToAdd) return;

        newGhostTimelineEvents.events.push(eventToAdd);
      });

      ghostTimelineElements.ghostEvents.push(newGhostTimelineEvents);
    }
  });

  return ghostTimelineElements;
};
