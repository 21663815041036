import * as React from "react";
import styled from "styled-components";
import { useEffect, useRef } from "react";
import { Tab } from "./Tab";
import { TabsSeparator } from "./TabsSeparator";
import { StatusType } from "./getStatusIcon";
import { NewTabButton } from "./NewTabButton";
import { ScrollableContainer } from "./ScrollableContainer";
import { usePrevious } from "../../utils/hooks/usePrevious";

const Container = styled.div`
  display: flex;
  padding: 0 ${(p): string => p.theme.space[3]};
`;

export const Tabs = (props: TabsProps): JSX.Element => {
  const { tabs, onTabClick, activeTab, onNewTabClick, hideNewButton } = props;
  const activeTabRef = useRef<HTMLInputElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const prevTabsCount = usePrevious(tabs.length);

  useEffect(() => {
    if (activeTabRef.current && scrollableRef && scrollableRef.current) {
      activeTabRef.current.scrollIntoView({
        block: "end",
        behavior: "auto",
      });
      scrollableRef.current.scrollLeft += 50;
    }
  }, [activeTab]);

  useEffect(() => {
    if (
      prevTabsCount &&
      prevTabsCount !== tabs.length &&
      scrollableRef &&
      scrollableRef.current
    ) {
      scrollableRef.current.scrollLeft = scrollableRef.current.scrollWidth;
    }
  }, [tabs]);

  return (
    <Container>
      <ScrollableContainer ref={scrollableRef} tabs={tabs}>
        {tabs.map(({ id, label, status }, index) => {
          const lastOne = index === tabs.length - 1;
          const isActive = activeTab === id;
          return (
            <TabContainer ref={isActive ? activeTabRef : null} key={label + id}>
              <Tab
                onClick={(): void => onTabClick(id)}
                label={label}
                isActive={isActive}
                lastOne={lastOne}
                status={status}
              />
              {!isActive && <TabsSeparator />}
            </TabContainer>
          );
        })}
      </ScrollableContainer>
      {!hideNewButton && <NewTabButton onClick={onNewTabClick} />}
    </Container>
  );
};

const TabContainer = styled.div`
  display: flex;
`;

type TabsProps = {
  tabs: { id: string; label: string; status?: StatusType }[];
  onTabClick: (id: string) => void;
  onNewTabClick: () => void;
  activeTab: string;
  hideNewButton?: boolean;
};
