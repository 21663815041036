import * as React from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { Header } from "../Header/Header";
import { Centered } from "./Centered";
import { SideBar } from "../SideBar/SideBar";
import { SideDrawboard } from "../SideDrawboard/SideDrawboard";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { SideDrawboardContent } from "../SideDrawboard/SideDrawboardContent/SideDrawboardContent";

interface LayoutWithSideDrawboardProps {
  pageTitle?: string;
  tabs?: React.ReactNode;
  dropdownData?: any;
  selectedDropdownDataId?: string;
  onDataClick?: any;
  fixedSideDrawboard?: boolean;
}

export const LayoutWithSideDrawboard: React.FC<LayoutWithSideDrawboardProps> =
  ({
    children,
    pageTitle = "Welcome",
    tabs,
    dropdownData,
    selectedDropdownDataId,
    onDataClick,
    fixedSideDrawboard = true,
  }) => {
    const { sideBarOpen } = useSideBarsContext();

    return (
      <Wrapper>
        <SideBar />
        <Main isSideBarOpen={sideBarOpen}>
          <Header pageTitle={pageTitle} />
          <NavWrapper>
            {tabs && <NavigationTabs>{tabs}</NavigationTabs>}
          </NavWrapper>
          <Content>
            <Centered>{children}</Centered>
          </Content>
        </Main>
        <SideDrawboard
          dropdownData={dropdownData}
          selectedDropdownDataId={selectedDropdownDataId}
          onDataClick={onDataClick}
          numberOfExistingWorkflows={0}
          fixedViewMode={fixedSideDrawboard}
        >
          <SideDrawboardContent />
        </SideDrawboard>
      </Wrapper>
    );
  };

const Wrapper = styled(Flex)`
  min-height: 100vh;
`;

const NavWrapper = styled.div`
  min-height: 36px;
  width: 100%;
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const NavigationTabs = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(p): string => p.theme.maxContentWidth};
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const Main = styled(Flex)<{ isSideBarOpen: boolean }>`
  width: ${({ isSideBarOpen }) =>
    isSideBarOpen ? "calc(100% - 184px - 72px);" : "calc(100% - 72px - 72px);"};
  flex-direction: column;
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
`;

const Content = styled(Flex)`
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
`;
