import * as React from "react";
import { MutableRefObject } from "react";
import { minimapScaleX, minimapScaleY } from "./minimapHelpers";
import { styledTheme } from "../../theme/theme";
import { GridIcon } from "../../images/icons/GridIcon";
import { FullscreenIcon } from "../../images/icons/FullscreenIcon";
import { MinusIcon } from "../../images/icons/MinusIcon";
import { PlusIconBig } from "../../images/icons/PlusIconBig";
import { Service } from "../../generated/graphql";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";

interface MinimapI {
  services: Service[];
  axisWidth: any;
  forwardedRef?: MutableRefObject<any> | ((instance: unknown) => void) | null;
  increaseTransform: (delta: number) => void;
  resetView: () => void;
  x: (startDate: number | Date | string) => number;
  baselineY: number;
  baselineHeight: number;
  config: {
    viewHeight: number;
    margin: number;
    viewWidth: number;
    _height: number;
    _width: number;
    controlsHeight: number;
    minimapScale: number;
  };
  currentTransform: {
    x: number;
    y: number;
    k: number;
  };
}
const Minimap = (props: MinimapI) => {
  const { config } = props;
  const { setSideBarOpen, setSideDrawboardOpen, setNavBarOpen } =
    useSideBarsContext();

  const setFullscreen = (): void => {
    setSideBarOpen(false);
    setSideDrawboardOpen(false);
    setNavBarOpen(false);
  };

  return (
    <g id="minimap">
      <rect
        id="minimapBg"
        x={props.axisWidth - config.viewWidth - 3 * config.margin}
        y={
          config._height -
          config.viewHeight -
          3 * config.margin -
          config.controlsHeight
        }
        width={config.viewWidth + 2 * config.margin}
        height={config.viewHeight + 2 * config.margin + config.controlsHeight}
        rx={8}
        ry={8}
        fill={styledTheme.newColors.grayscale.white}
      />

      <svg
        ref={props.forwardedRef}
        id="minimapSVG"
        x={props.axisWidth - config.viewWidth - 2 * config.margin}
        y={
          config._height -
          config.viewHeight -
          2 * config.margin -
          config.controlsHeight
        }
        width={minimapScaleX(config.minimapScale, config)(config._width)}
        height={minimapScaleY(config.minimapScale, config)(config._height)}
        viewBox={[0, 0, config._width, config._height].join(" ")}
        preserveAspectRatio="xMidYMid meet"
      >
        <rect
          id="selection-boundary"
          width={config._width}
          height={config._height}
          fill={styledTheme.newColors.grayscale.white}
          stroke={styledTheme.newColors.grayscale.primaryGray}
          strokeWidth="1px"
          style={{ zIndex: 20 }}
        />

        {props.services.map((service) => (
          <rect
            key={service.id}
            y={props.baselineY - props.baselineHeight * service.baseLine}
            x={props.x(service.startDate)}
            width={Math.max(
              props.x(service.endDate) - props.x(service.startDate),
              20
            )}
            height={10}
            opacity={0.3}
            rx={10}
          />
        ))}
      </svg>

      <line
        y1={config._height - config.controlsHeight - config.margin}
        y2={config._height - config.controlsHeight - config.margin}
        x1={props.axisWidth - config.viewWidth - 3 * config.margin}
        x2={props.axisWidth - config.margin}
        style={{
          stroke: styledTheme.newColors.grayscale.bordersInside,
          strokeWidth: 1,
        }}
      />

      <GridIcon
        x={props.axisWidth - config.viewWidth - 2 * config.margin}
        y={config._height - 2 * config.margin - 12}
        onClick={props.resetView}
        style={{
          cursor: "pointer",
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          pointerEvents: "bounding-box",
        }}
      />

      <line
        x1={props.axisWidth - config.viewWidth - 2 * config.margin + 25}
        y1={config._height - 2 * config.margin - 14}
        x2={props.axisWidth - config.viewWidth - 2 * config.margin + 25}
        y2={config._height - 2 * config.margin + 5}
        style={{
          stroke: styledTheme.newColors.grayscale.bordersInside,
          strokeWidth: 1,
        }}
      />

      <FullscreenIcon
        x={props.axisWidth - config.viewWidth - 2 * config.margin + 35}
        y={config._height - 2 * config.margin - 14}
        onClick={setFullscreen}
        style={{
          cursor: "pointer",
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          pointerEvents: "bounding-box",
        }}
      />

      <MinusIcon
        onClick={() => props.increaseTransform(-0.25)}
        x={props.axisWidth - 5 * config.margin - 38}
        y={config._height - 2 * config.margin - 14}
        style={{
          padding: 10,
          cursor: "pointer",
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          pointerEvents: "bounding-box",
        }}
      />

      <PlusIconBig
        onClick={() => props.increaseTransform(0.25)}
        x={props.axisWidth - 5 * config.margin - 10}
        y={config._height - 2 * config.margin - 3 - 8}
        style={{
          padding: 10,
          cursor: "pointer",
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          pointerEvents: "bounding-box",
        }}
      />

      <text
        x={props.axisWidth - 3 * config.margin}
        y={config._height - 2 * config.margin - 3}
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily="Overpass"
        fontWeight="800"
        fontSize={14}
      >
        {`${Math.round(props.currentTransform.k * 100)}%`}
      </text>
    </g>
  );
};

export default React.forwardRef((props: MinimapI, ref) => (
  <Minimap forwardedRef={ref} {...props} />
));
