import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import {
  Sample,
  useUserProjectQuery,
  Question,
} from "../../../../generated/graphql";
import { getSampleName } from "../../../../utils/helpers/getSampleName";

interface ReturnType {
  samples: Sample[];
  projectQuestions: Question[];
  error: ApolloError | undefined;
  loading: boolean;
}

interface Props {
  projectId: string;
}

export const useBudgetBlock = (props: Props): ReturnType => {
  const { projectId } = props;

  const [{ samples, projectQuestions }, setData] = useState<{
    samples: Sample[];
    projectQuestions: Question[];
  }>({
    samples: [],
    projectQuestions: [],
  });

  const {
    error,
    loading,
    data: dataProject,
  } = useUserProjectQuery({
    variables: {
      id: projectId,
    },
  });

  useEffect(() => {
    if (!dataProject?.Project) return;

    const samplesToUse = dataProject.Project.samples.map((sample) => {
      const sampleName = getSampleName(sample);
      return { ...sample, name: sampleName };
    });

    const budgetProjectQuestions: Question[] = [];

    dataProject.Project.selectedQuestionGroups.forEach((questionGroup) => {
      questionGroup.questionSets.forEach((questionSet) => {
        questionSet.questions.forEach((question) => {
          if (question.id.includes("Budget")) {
            budgetProjectQuestions.push(question);
          }
        });
      });
    });

    setData({
      samples: samplesToUse,
      projectQuestions: budgetProjectQuestions,
    });
  }, [dataProject]);

  return { samples, projectQuestions, error, loading: loading || !samples };
};
