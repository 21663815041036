import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { Flex } from "rebass";
import { GetFieldStatusResultT, LabelTag } from "../LabelTag/LabelTag";
import { Help } from "../Tooltip/Help";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const LabelContainer = styled(Flex)`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  line-height: ${({ theme }) => theme.lineHeights[4]};
  color: ${({ theme }) => theme.newColors.grayscale.primaryGray};
  font-weight: bold;
  letter-spacing: -0.02em;
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeights[3]};
  color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};
  margin-top: ${({ theme }) => theme.space[1]};
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Edit = styled.span`
  color: ${({ theme }) => theme.newColors.secondary.basic};
  font-size: 15px;
  line-height: 26px;
  padding: 0 0.5rem;
  cursor: pointer;

  :hover {
    color: #4e9786;
  }
`;

export const Label: FunctionComponent<LabelProps> = (props) => {
  const {
    children,
    label,
    help,
    htmlFor,
    status,
    edit,
    onEditClick,
    disabled,
  } = props;

  return (
    <Container>
      <TopContainer>
        <LabelContainer className={props.className}>
          <label htmlFor={htmlFor}>{label} </label>
          {help && help !== "" && <Help text={help} place="right" />}
          {edit && <Edit onClick={onEditClick}>EDIT</Edit>}
        </LabelContainer>
        {status && !disabled ? (
          <div>
            <LabelTag name={status.name} type={status.type} />
          </div>
        ) : null}
      </TopContainer>
      <Description>{children}</Description>
    </Container>
  );
};

interface LabelProps {
  htmlFor?: string;
  label: string;
  help?: string;
  className?: string;
  status?: GetFieldStatusResultT;
  edit?: boolean;
  onEditClick?: () => void;
  disabled?: boolean;
}
