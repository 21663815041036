import * as React from "react";
import { Link, useLocation } from "@reach/router";
import { Box } from "rebass";
import styled from "styled-components";
import { ChevronRightIcon } from "../../images/icons/ChevronRightIcon";
import { styledTheme } from "../../theme/theme";

// https://stackoverflow.com/a/55387306
const insertSeparatorsAtEveryOddIndex = (arr: JSX.Element[]): JSX.Element[] =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  ([] as JSX.Element[])
    .concat(
      ...arr.map((n) => [
        n,
        <ArrowContainer key={`arrow_${Math.random()}`}>
          <ChevronRightIcon color={styledTheme.colors.lightText} />
        </ArrowContainer>,
      ])
    )
    .slice(0, -1);

const reducer = (accumulator: string, currentValue: string): string => {
  return `${accumulator}/${currentValue}`;
};

// const camelcaseToSpaces = (s: string) => s.replace(/([A-Z])/g, " $1");

const prepareLinksFromCrumbs = (crumbs: string[]): JSX.Element[] =>
  crumbs.map((c: string, index: number, array: string[]) => (
    <Link
      key={(c + index) as string}
      to={`/${array.slice(0, index + 1).reduce(reducer)}`}
    >
      {c}
    </Link>
  ));

export const Breadcrumbs = (): JSX.Element => {
  const location = useLocation();
  const crumbs = location.pathname.split("/").filter((s) => s !== "");
  const links = prepareLinksFromCrumbs(crumbs);
  const linksWithSeparators = insertSeparatorsAtEveryOddIndex(links);

  return (
    <BreadcrumbsStyled data-testid="breadcrumbs">
      {linksWithSeparators}
    </BreadcrumbsStyled>
  );
};

const BreadcrumbsStyled = styled(Box)`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-size: ${(p): string => p.theme.fontSizes[0]};

  a {
    color: ${(p): string => p.theme.colors.lightText};
    text-decoration: none;
  }
`;

const ArrowContainer = styled.span`
  padding: 0 9px;
`;
