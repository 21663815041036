import React from "react";
import { BaseIconProps } from "./BaseIconProps";

export const SendMessageIcon = (props: BaseIconProps): JSX.Element => {
  const { color, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.195 13.385H2.644A1.652 1.652 0 011 11.73V1.655C1 .742 1.738 0 2.644 0h15.684a1.65 1.65 0 011.644 1.655v9.348a7.033 7.033 0 013.31 5.96A7.046 7.046 0 0116.245 24c-3.88 0-7.038-3.157-7.038-7.038 0-1.306.365-2.527.988-3.577zm6.05 9.115a5.544 5.544 0 005.537-5.538 5.544 5.544 0 00-5.537-5.538 5.544 5.544 0 00-5.538 5.538 5.544 5.544 0 005.538 5.538zm-4.86-10.615a7.008 7.008 0 014.86-1.96c.779 0 1.526.132 2.227.366V3.244l-7.986 6.19L2.5 3.244v8.486c0 .084.066.155.144.155h8.74zM18.274 1.5H2.698l7.788 6.036L18.274 1.5zm-2.806 14.632l-1.798 1.804-1.198-1.149 3.836-3.846 3.834 3.846-1.197 1.15-1.799-1.805v4.353h-1.678v-4.353z"
        clipRule="evenodd"
      />
    </svg>
  );
};

SendMessageIcon.defaultProps = {
  width: "24",
  height: "24",
  color: "#4979CB",
};
