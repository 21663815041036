import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ApolloError } from "apollo-client";
import { useAuth } from "react-use-auth";
import { Modal } from "../../../../common/Modal/Modal";
import { ButtonType } from "../../../../common/Button/ButtonType";
import { Button } from "../../../../common/Button/Button";
import {
  WorkflowsByOrganizationDocument,
  useSaveWorkflowTemplateMutation,
} from "../../../../../generated/graphql";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedWorkflowPath,
} from "../../../../../utils/types/ActionContextTypes";
import { TextBox } from "../../../../common/TextBox/TextBox";
import { isSelectedWorkflowPath } from "../../../../../utils/types/ActionContextGuards";

type SaveAsTemplateModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SaveAsTemplateModal = (
  props: SaveAsTemplateModalProps
): JSX.Element => {
  const { isOpen, onClose } = props;

  const { user } = useAuth();
  const { selectedEntity } = useActionContext();

  const [isLoading, setIsLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (
      selectedEntity &&
      isSelectedWorkflowPath(selectedEntity.selectedElementPath)
    ) {
      const { workflow } = selectedEntity.selectedElementPath;
      setFieldValue(workflow.name);
    }
  }, [selectedEntity]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(event.target.value);
  };

  const [saveWorkflowTemplate] = useSaveWorkflowTemplateMutation();

  if (
    !isOpen ||
    !(selectedEntity?.selectedElementType === SelectedElementType.WORKFLOW)
  )
    return <></>;

  const onAcceptModal = (): void => {
    if (!fieldValue) return;

    setIsLoading(true);

    const { projectId, sampleId, workflowId } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    saveWorkflowTemplate({
      variables: {
        projectSampleWorkflowInput: {
          projectId,
          sampleId,
          workflowId,
        },
        organization: user["https://leaderneplatform.com/organization"],
        templateName: fieldValue,
      },
      refetchQueries: [
        {
          query: WorkflowsByOrganizationDocument,
          variables: {
            organization: user["https://leaderneplatform.com/organization"],
          },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
      });
  };

  const ModalButtons = (): JSX.Element => (
    <>
      <AcceptButton
        disabled={isLoading}
        label="Save Template"
        type={ButtonType.Primary}
        onClick={onAcceptModal}
      />
      <Button
        disabled={isLoading}
        label="Cancel"
        type={ButtonType.Secondary}
        onClick={onClose}
      />
    </>
  );

  return (
    <StyledModal
      id="saveAsTemplateModal"
      title="Save as a Template"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <TextBox value={fieldValue} onChange={onChange} />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;
