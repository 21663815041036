import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ReportsIcon = (props: ReportsIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 2C12.9926 2 14 3.00736 14 4.25V11.75C14 12.9926 12.9926 14 11.75 14H4.25C3.00736 14 2 12.9926 2 11.75V4.25C2 3.00736 3.00736 2 4.25 2H11.75ZM8 4C7.48716 4 7.06449 4.38604 7.00673 4.88338L7 5V11C7 11.5523 7.44772 12 8 12C8.51284 12 8.93551 11.614 8.99327 11.1166L9 11V5C9 4.44772 8.55228 4 8 4ZM11 6C10.4872 6 10.0645 6.38604 10.0067 6.88338L10 7V11C10 11.5523 10.4477 12 11 12C11.5128 12 11.9355 11.614 11.9933 11.1166L12 11V7C12 6.44772 11.5523 6 11 6ZM5 8C4.48716 8 4.06449 8.38604 4.00673 8.88338L4 9V11C4 11.5523 4.44772 12 5 12C5.51284 12 5.93551 11.614 5.99327 11.1166L6 11V9C6 8.44772 5.55228 8 5 8Z"
        fill={color}
      />
    </svg>
  );
};

ReportsIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};

type ReportsIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
