import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";

export const PencilIcon = (props: BaseIconProps): JSX.Element => {
  const { color, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M0.5 8.75V6.5C0.5 6.30109 0.579018 6.11032 0.71967 5.96967L5.96967 0.71967C6.26256 0.426777 6.73744 0.426777 7.03033 0.71967L9.28033 2.96967C9.57322 3.26256 9.57322 3.73744 9.28033 4.03033L4.03033 9.28033C3.88968 9.42098 3.69891 9.5 3.5 9.5H1.25C0.835786 9.5 0.5 9.16421 0.5 8.75ZM6.5 2.3107L2 6.8107V8.00004H3.18934L7.68934 3.50004L6.5 2.3107Z"
      />
    </svg>
  );
};

PencilIcon.defaultProps = {
  width: "10",
  height: "10",
  color: "#6E7D92",
};
