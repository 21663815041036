import * as React from "react";
import { Field } from "formik";
import { getAutoCompleteControl } from "./getAutoCompleteControl";
import { FormElementProps } from "./FormElementProps";
import { TextField } from "../../../components/DynamicForm/Elements/TextField";

export const getTextFieldControl = (
  props: FormElementProps & { type: string }
) => {
  if (props.options && props.options.length > 0) {
    return getAutoCompleteControl(props);
  }

  return <Field component={TextField} {...props} />;
};
