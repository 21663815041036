import { isEqual } from "lodash";
import React from "react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { SelectedWorkflowPath } from "../../../../utils/types/ActionContextTypes";
import {
  UserProjectDocument,
  useUpdateWorkflowFromSampleMutation,
  WorkflowForCustomer,
} from "../../../../generated/graphql";
import { EditTaskCollaborators } from "./EditTaskCollaborators";
import { withCollaboratorSelectables } from "../Collaborators/withCollaboratorSelectables";
import { CollaboratorOption } from "../Collaborators/types";
import { ReadQueryResult } from "../../../../utils/types/QueryResult";

function EditTaskCollaboratorsWrapperPure({
  allCollaboratorsOptions,
  editMode = true,
}: {
  allCollaboratorsOptions: CollaboratorOption[];
  editMode?: boolean;
}): JSX.Element {
  const { selectedEntity } = useActionContext();

  const selectedElementPath =
    selectedEntity?.selectedElementPath as SelectedWorkflowPath;
  const workflowForCustomer =
    selectedElementPath.workflow as WorkflowForCustomer;

  const [updateWorkflow] = useUpdateWorkflowFromSampleMutation({
    refetchQueries: ["UserProject"],
  });
  const { collaborators: currentCollaborators } = workflowForCustomer;

  const callUpdateWorkflow = (
    collaboratorOptions: CollaboratorOption[]
  ): any => {
    const updatedCollaborators = {
      groupIds: collaboratorOptions
        .filter((x) => x.value.type === "group")
        .map((x) => x.value.id),
      userIds: collaboratorOptions
        .filter((x) => x && x.value.type === "user")
        .map((x) => x.value.id),
    };

    if (!isEqual(updatedCollaborators, currentCollaborators)) {
      return updateWorkflow({
        variables: {
          projectSampleInput: {
            projectId: selectedElementPath.projectId,
            sampleId: selectedElementPath.sampleId,
          },
          workflowUpdatables: {
            id: workflowForCustomer.id,
            collaborators: updatedCollaborators,
          },
        },
        update(cache: InMemoryCache, { data }: any) {
          const incomingData = data?.["UpdateWorkflowFromSample"];
          const existingQueryResult = cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: selectedElementPath.projectId },
          })?.["Project"];

          if (existingQueryResult && incomingData) {
            const samples = [...existingQueryResult.samples];
            const sample = samples.find(
              (s) => s.id === selectedElementPath.sampleId
            );

            if (sample) {
              const workflowIndex = sample.enabledWorkflows.findIndex(
                (w) => w.id === workflowForCustomer.id
              );

              if (workflowIndex !== -1) {
                sample.enabledWorkflows[workflowIndex] = incomingData;

                const sampleIndex = samples.findIndex(
                  (s) => s.id === selectedElementPath.sampleId
                );
                samples[sampleIndex] = { ...sample };

                cache.writeQuery({
                  variables: { id: selectedElementPath.projectId },
                  query: UserProjectDocument,
                  data: {
                    Project: {
                      ...existingQueryResult,
                      samples,
                    },
                  },
                });
              }
            }
          }
        },
      });
    }
    return true;
  };

  return (
    <EditTaskCollaborators
      allCollaborators={allCollaboratorsOptions}
      currentCollaborators={
        [
          ...(currentCollaborators?.groupIds || []),
          ...(currentCollaborators?.userIds || []),
        ]
          .map((eId) =>
            allCollaboratorsOptions?.find((cg) => cg.value.id === eId)
          )
          .filter(Boolean) as CollaboratorOption[]
      }
      updateWorkflow={callUpdateWorkflow}
      workflowId={selectedElementPath.workflowId}
      editMode={editMode}
    />
  );
}

export const EditTaskCollaboratorsWrapper = withCollaboratorSelectables(
  EditTaskCollaboratorsWrapperPure
);
