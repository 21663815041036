import React from "react";
import { Currency } from "generated/graphql";
import { useBudgetBlock } from "./useBudgetBlock";
import { BlockWrapper } from "../BlockWrapper";
import { BudgetChart } from "./BudgetChart";

interface BudgetBlockProps {
  projectId: string;
  currency: Currency;
}

export const BudgetBlock = (props: BudgetBlockProps): JSX.Element => {
  const { projectId, currency } = props;

  const { error, loading, samples, projectQuestions } = useBudgetBlock({
    projectId,
  });

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  return (
    <BlockWrapper title={`Budget in ${currency}`} loading={loading}>
      <BudgetChart
        samples={samples}
        projectQuestions={projectQuestions}
        currency={currency}
      />
    </BlockWrapper>
  );
};
