// @flow
import * as React from "react";
import styled from "styled-components";
import { Label } from "../../../../common/Label/Label";
import { LabelTag } from "../../../../common/LabelTag/LabelTag";

type Props = {
  title: string;
  description: string;
  selectedCount: number;
};
export const ServicePackHeader = (props: Props) => {
  const { title, description, selectedCount } = props;
  return (
    <Container>
      <Label label={title}>{description}</Label>
      <LabelTagStyled>
        <LabelTag name={`${selectedCount} Selected`} />
      </LabelTagStyled>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const LabelTagStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
