import * as React from "react";
import {
  UserProjectDocument,
  useSelectWorkflowInProjectSampleMutation,
  useUnselectWorkflowInProjectSampleMutation,
} from "../generated/graphql";
import {
  ServicePackages,
  ServicePackagesConfiguratorProps,
} from "../components/Projects/Configurator/Samples/ServicePackages/ServicePackages";
import { ServicePackAction } from "../utils/types/WorkflowMetadata";
import { Spinner } from "../components/common/Spinner/Spinner";

export const ServicePackagesConfigurator = (
  props: ServicePackagesConfiguratorProps & {
    workflowAction: (id: string, unselect: boolean) => void;
  }
) => {
  const [showSpinner, setShowSpinner] = React.useState(false);
  const selectWorkflowInProjectSampleMutation =
    useSelectWorkflowInProjectSampleMutation()[0];
  const unselectWorkflowInProjectSampleMutation =
    useUnselectWorkflowInProjectSampleMutation()[0];

  const getMutationForWorkflow = (unSelect: boolean) => {
    if (unSelect) {
      return unselectWorkflowInProjectSampleMutation;
    }
    return selectWorkflowInProjectSampleMutation;
  };

  const workflowAction: ServicePackAction = async (metadata, unselect) => {
    setShowSpinner(true);
    const options = {
      variables: { projectSampleWorkflowSelectInput: metadata },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: {
            id: metadata.projectId,
          },
        },
      ],
      awaitRefetchQueries: true,
    };
    const mutation = getMutationForWorkflow(unselect);
    await mutation(options);
    props.workflowAction(metadata.workflowId, unselect);
    setShowSpinner(false);
  };

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <ServicePackages {...props} serviceCardButtonAction={workflowAction} />
    </>
  );
};
