import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BarChart } from "chartist";
import ChartistTooltip from "chartist-plugin-tooltips-updated";
import "chartist/dist/index.css";
import "chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css";
import {
  Sample,
  ServiceForCustomer,
  Currency,
  Question,
  NumericAnswerFragment,
} from "../../../../generated/graphql";
import { checkIfServiceIsInLockedState } from "../../../../utils/helpers/checkIfServiceIsInLockedState";
import { toInt } from "../../../../utils/helpers/toInt";
import { getSampleName } from "../../../../utils/helpers/getSampleName";

interface BudgetTableProps {
  samples: Sample[];
  projectQuestions: Question[];
  currency: Currency;
}

type FieldWithDesc = { meta: string; value: number };

function transpose(matrix: FieldWithDesc[][]): FieldWithDesc[][] {
  const rows = matrix.length;
  const colsLen = matrix.map((col) => col.length);
  const cols = Math.max(...colsLen);
  const grid = [];
  for (let j = 0; j < cols; j += 1) {
    grid[j] = Array(rows);
  }
  for (let i = 0; i < rows; i += 1) {
    for (let j = 0; j < cols; j += 1) {
      grid[j][i] = matrix[i][j];
    }
  }
  return grid;
}

function addToCategory(
  graphData: FieldWithDesc[],
  category: string,
  priceValue: string
): void {
  const alreadyDefinedCategories = graphData.map((field) => field.meta);
  if (!alreadyDefinedCategories.includes(category)) {
    graphData.push({
      meta: category,
      value: 0,
    });
  }
  const categoryToAddTo = graphData.find((field) => field.meta === category);
  if (categoryToAddTo) {
    categoryToAddTo.value += parseFloat(priceValue);
  }
}

export const BudgetChart = (props: BudgetTableProps): JSX.Element => {
  const { samples, currency, projectQuestions } = props;

  const [label, setLabel] = useState("");

  const options = {
    stackBars: true,
    horizontalBars: true,
    axisX: {
      labelInterpolationFnc: (value: number, index: number): number | null =>
        index % 2 === 0 ? value : null,
    },
    axisY: {
      offset: 60,
    },
    chartPadding: { top: 0, bottom: 0 },
    plugins: [ChartistTooltip({ currency })],
  };

  useEffect(() => {
    const budgetForAllProject: FieldWithDesc[] = [
      {
        meta: "Budget for all project",
        value: 0,
      },
    ];
    const budgetForAllWorkflows = [0];
    const budgetForAllServices = [0];
    const budgetsForAllWorkflows: FieldWithDesc[] = [];
    const budgetsForAllServices: FieldWithDesc[] = [];
    const usedBudget: FieldWithDesc[] = [];
    // const yearsSumsInWorkflows: FieldWithDesc[] = [];
    const yearsSumsInServices: FieldWithDesc[] = [];
    const sumsOfSimilarServices: FieldWithDesc[] = [];
    const sumsOfSimilarServicesInYears: FieldWithDesc[] = [];

    if (projectQuestions.length > 0) {
      projectQuestions.forEach((question) => {
        if (question.id.includes(currency)) {
          const numericAnswer = question.answer as NumericAnswerFragment;
          const value = numericAnswer.numericBody
            ? numericAnswer.numericBody
            : 0;
          budgetForAllProject[0].value = value;
        }
      });
    }

    let sampleToUse = samples.length > 0 && samples[0];

    samples.forEach((sample) => {
      const sampleName = getSampleName(sample);
      const sampleNameLowercase = sampleName.toLowerCase();
      if (
        sampleNameLowercase.includes("budget") ||
        sampleNameLowercase.includes("budżet")
      ) {
        sampleToUse = sample;
      }
    });

    let showSumsOfSimilar = false;

    if (sampleToUse) {
      sampleToUse.enabledWorkflows.forEach((workflow) => {
        JSON.stringify(workflow);
        if (
          workflow.price?.price?.value &&
          workflow.price?.currency === currency
        ) {
          budgetForAllWorkflows[0] += parseFloat(workflow.price.price.value);
          budgetsForAllWorkflows.push({
            meta: workflow.name,
            value: parseFloat(workflow.price.price.value),
          });
        }

        workflow.services.forEach((service) => {
          const serviceMapped = service as ServiceForCustomer;
          if (
            serviceMapped.price?.price?.value &&
            serviceMapped.price?.currency === currency
          ) {
            if (!/^\d+$/.test(service.name)) {
              showSumsOfSimilar = true;
            }
            budgetForAllServices[0] += parseFloat(
              serviceMapped.price.price.value
            );
            budgetsForAllServices.push({
              meta: `${workflow.name}: ${service.name}`,
              value: parseFloat(serviceMapped.price.price.value),
            });

            const serviceStartYear = new Date(toInt(serviceMapped.startDate))
              .getFullYear()
              .toString();
            addToCategory(
              yearsSumsInServices,
              serviceStartYear,
              serviceMapped.price.price.value
            );

            addToCategory(
              sumsOfSimilarServices,
              serviceMapped.name,
              serviceMapped.price.price.value
            );

            addToCategory(
              sumsOfSimilarServicesInYears,
              `${serviceMapped.name} in year ${serviceStartYear}`,
              serviceMapped.price.price.value
            );

            if (
              checkIfServiceIsInLockedState(serviceMapped.status) &&
              serviceMapped.price?.currency === currency
            ) {
              usedBudget.push({
                meta: `${workflow.name}: ${service.name}`,
                value: parseFloat(serviceMapped.price.price.value),
              });
            }
          }
        });
      });
    }

    const labels = ["Used", "In Years"];
    if (showSumsOfSimilar) labels.push("Sums by year");
    if (showSumsOfSimilar) labels.push("Sums of similar");
    labels.push("Subtasks");
    labels.push("Tasks");

    const sourceData = [usedBudget, yearsSumsInServices];
    if (showSumsOfSimilar) sourceData.push(sumsOfSimilarServicesInYears);
    if (showSumsOfSimilar) sourceData.push(sumsOfSimilarServices);
    sourceData.push(budgetsForAllServices);
    sourceData.push(budgetsForAllWorkflows);

    if (budgetForAllProject[0].value !== 0) {
      sourceData.push(budgetForAllProject);
      labels.push("Project");
    }

    const series = transpose(sourceData);

    const data = {
      labels,
      series,
    };
    const thereIsNoDataHere =
      budgetForAllWorkflows[0] === 0 && budgetForAllServices[0] === 0 && budgetForAllProject[0].value === 0;

    if (!thereIsNoDataHere) {
      setLabel("");
      // eslint-disable-next-line no-new
      new BarChart(`.chart${currency}`, data, options);
    } else {
      setLabel("There is no budget data in this currency.");
    }
  }, [samples]);

  return (
    <Chart>
      <ChartContainer isVisible={label.length === 0}>
        <div className={`chart${currency}`} style={{ height: "234px" }} />
      </ChartContainer>
      <Label>{label}</Label>
    </Chart>
  );
};

const Chart = styled.div`
  .ct-bar {
    /* The width of your bars */
    stroke-width: 20px;
  }
`;

const ChartContainer = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => (isVisible ? "" : "display: none;")}
`;

const Label = styled.div`
  font-size: smaller;
`;
