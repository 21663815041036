import * as React from "react";
import styled, { css } from "styled-components";
import { ChainIcon } from "../../../images/icons/ChainIcon";
import { styledTheme } from "../../../theme/theme";
import { TipIcon } from "../../../images/icons/TipIcon";
import { Tooltip } from "../Tooltip/Tooltip";

type Props = {
  name: string;
  tip?: string;
  selected?: boolean;
  disabled?: boolean;
};

export const ServicePill = (props: Props) => {
  const { name, selected, disabled, tip } = props;
  return (
    <ServiceContainer selected={selected} disabled={disabled}>
      <ServicePillContent>
        <ServiceIcon>
          <ChainIcon
            width="20"
            height="20"
            color={
              disabled
                ? styledTheme.newColors.grayscale.disabled
                : styledTheme.newColors.grayscale.primaryGray
            }
            secondColor={
              disabled
                ? styledTheme.newColors.grayscale.placeholder
                : styledTheme.newColors.grayscale.white
            }
          />
        </ServiceIcon>
        <ServiceName>{name}</ServiceName>
        {tip && (
          <ServiceTip>
            <Tooltip text={tip} place="right">
              <TipIcon
                color={
                  disabled
                    ? styledTheme.newColors.grayscale.placeholder
                    : styledTheme.newColors.grayscale.secondaryGray
                }
                width="16"
                height="16"
              />
            </Tooltip>
          </ServiceTip>
        )}
      </ServicePillContent>
    </ServiceContainer>
  );
};

const ServiceContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
  padding: 6px 8px;
  background: ${({ theme, selected }) =>
    selected
      ? theme.newColors.grayscale.background
      : theme.newColors.grayscale.white};
  border-radius: 4px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background: ${theme.newColors.grayscale.white};

      div {
        color: ${theme.newColors.grayscale.placeholder};
      }
    `}
`;

const ServicePillContent = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin: 0 -4px;

  & > div {
    margin: 0 4px;
  }
`;
const ServiceIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ServiceName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.newColors.grayscale.primaryGray};
`;
const ServiceTip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
