import * as React from "react";
import styled, { css } from "styled-components";
import { FunctionComponent, useState, useEffect } from "react";
import { CheckboxFill } from "./CheckboxFill";

interface CheckboxProps {
  onChange?: () => void;
  checked: boolean;
  disabled?: boolean;
  className?: string;
}

export const CheckboxSimple: FunctionComponent<CheckboxProps> = (props) => {
  const { disabled, checked, onChange, className } = props;

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onCheckboxChange = disabled
    ? () => {}
    : () => {
        setIsChecked(!isChecked);
        if (onChange) onChange();
      };

  return (
    <Root checked={isChecked} disabled={disabled} className={className}>
      <Input
        type="checkbox"
        onChange={onCheckboxChange}
        checked={isChecked}
        aria-checked={isChecked}
        disabled={disabled}
      />
      <Fill disabled={disabled}>
        <CheckboxFill />
      </Fill>
    </Root>
  );
};

const Root = styled.div<{ checked?: boolean; disabled?: boolean }>`
  cursor: pointer;
  min-width: 24px;
  height: 24px;
  position: relative;

  &::before {
    content: "";
    border: 1px solid ${(props) => props.theme.newColors.grayscale.tertiaryGray};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 4px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}

  ${(props) =>
    props.checked &&
    css`
      &::before {
        background: ${({ theme }) => theme.newColors.primary.basic};
        border: none;
      }
    `}

  &:hover {
    &::before {
      background: ${({ theme }) => theme.newColors.grayscale.background};
      border: 1px solid ${({ theme }) => theme.newColors.grayscale.tertiaryGray};
    }
  }
`;

const Fill = styled.div<{ disabled?: boolean }>`
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  pointer-events: none;
  z-index: 1;

  ${(props) =>
    props.disabled &&
    css`
      & > svg > path {
        fill: #b6b8ba;
      }
    `}
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;

  &:focus {
    outline: none;
  }

  ${({ checked }) =>
    checked
      ? css`
          & ~ ${Fill} {
            opacity: 1;
          }
        `
      : ``}
`;
