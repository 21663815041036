import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const BellIcon = (props: BaseIconProps): JSX.Element => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00031 20C4.29606 20 3.82505 19.2979 4.06009 18.6586L4.10589 18.5528L5.2613 16.242C5.6987 15.3672 5.94738 14.4115 5.99274 13.4369L6.00031 13.1115V10C6.00031 7.02694 8.1627 4.55893 11.0005 4.08293L11.0003 3C11.0003 2.44772 11.448 2 12.0003 2C12.5526 2 13.0003 2.44772 13.0003 3L13.0011 4.08309C15.7596 4.54627 17.8797 6.89172 17.9953 9.75347L18.0003 10V13.1115C18.0003 14.0895 18.2053 15.0555 18.6005 15.9474L18.7393 16.242L19.8947 18.5528C20.2097 19.1827 19.7923 19.918 19.1154 19.9936L19.0003 20H14.0003C14.0003 21.1046 13.1049 22 12.0003 22C10.8957 22 10.0003 21.1046 10.0003 20H5.00031ZM12.0003 6C9.85812 6 8.10923 7.68397 8.00521 9.80036L8.00031 10V13.1115C8.00031 14.3922 7.72697 15.6569 7.20012 16.8213L7.05016 17.1364L6.61835 18H17.3823L16.9505 17.1364C16.3777 15.9908 16.0566 14.7374 16.0071 13.4603L16.0003 13.1115V10C16.0003 7.79086 14.2095 6 12.0003 6Z"
        fill={color}
      />
    </svg>
  );
};

BellIcon.defaultProps = {
  width: 24,
  height: 24,
  color: styledTheme.newColors.grayscale.background,
};
