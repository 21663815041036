import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ReportsMenuIcon = (props: ReportsMenuIconProps): JSX.Element => {
  const { width, height, color, isActive } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 2C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19Z"
        stroke={isActive ? styledTheme.newColors.grayscale.white : color}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12.5523 6 13 6.44772 13 7V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V7C11 6.44772 11.4477 6 12 6ZM8 14C8.55228 14 9 14.4477 9 15V17C9 17.5523 8.55228 18 8 18C7.44772 18 7 17.5523 7 17V15C7 14.4477 7.44772 14 8 14ZM17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11V17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17V11Z"
        fill={isActive ? styledTheme.newColors.grayscale.white : color}
      />
    </svg>
  );
};

type ReportsMenuIconProps = BaseIconProps & {
  isActive?: boolean;
};

ReportsMenuIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};
