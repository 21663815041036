import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";

export const Calendar2Icon = (props: Calendar2IconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.00037H13"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1429 2.60013H1.85714C1.384 2.60013 1 2.95854 1 3.40015V12.2004C1 12.6421 1.384 13.0005 1.85714 13.0005H12.1429C12.616 13.0005 13 12.6421 13 12.2004V3.40015C13 2.95854 12.616 2.60013 12.1429 2.60013Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.57123 1V2.60005"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.4283 1V2.60005"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M7 1V2.60005"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

Calendar2Icon.defaultProps = {
  width: "14",
  height: "14",
  color: "#6E7D92",
};

type Calendar2IconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
