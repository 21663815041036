import * as React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { Centered } from "../Layouts/Centered";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { ActionBar } from "./ActionBar";
import Heading from "../typography/Heading";

export const Header = ({ pageTitle }: { pageTitle: string }): JSX.Element => {
  return (
    <StyledHeader>
      <Content>
        <Box>
          <Breadcrumbs />
          <Heading>{pageTitle}</Heading>
        </Box>
        <ActionBar />
      </Content>
    </StyledHeader>
  );
};

const StyledHeader = styled(Box)`
  background-color: ${(p) => p.theme.newColors.grayscale.white};
  min-height: 96px;
`;

const Content = styled(Centered)`
  display: flex;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.space[4]};
  padding-top: 0;
  padding-bottom: 0;

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
  }
`;
