import React, { useEffect, useState } from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ReadQueryResult } from "utils/types/QueryResult";
import * as ApolloReactHooks from "@apollo/react-hooks";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";
import { SelectedServicePath } from "../../../../utils/types/ActionContextTypes";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import {
  Currency,
  EntityStatus,
  Priority,
  ProjectUser,
  UpdateServiceFromWorkflowMutation,
  UpdateServiceFromWorkflowMutationVariables,
  useProjectUsersQuery,
  UserProjectDocument,
  useUpdateServiceFromWorkflowMutation,
} from "../../../../generated/graphql";
import { DatePicker } from "../../../common/DatePicker/DatePicker";
import { UsersSelector } from "../EditTask/UsersSelector/UsersSelector";
import { updateServices } from "../../../../utils/helpers/updateServices";
import {
  PriceInput,
  PriceInputValue,
} from "../../../common/PriceInput/PriceInput";
import { PriorityDropdown } from "../../../common/PriorityDropdown/PriorityDropdown";
import { isSelectedServicePath } from "../../../../utils/types/ActionContextGuards";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";
import { ContainerCheckbox } from "../../../common/ContainerCheckbox/ContainerCheckbox";

type EditSubtaskInformationsProps = {
  editMode?: boolean;
  hardcodedUser?: ProjectUser;
};

export const EditSubtaskInformations = (
  props: EditSubtaskInformationsProps
): JSX.Element => {
  const { editMode, hardcodedUser } = props;
  const { selectedEntity, setSelectedEntity } = useActionContext();

  if (!selectedEntity) return <></>;

  const {
    projectId,
    sampleId,
    workflowId,
    service: { id },
  } = selectedEntity?.selectedElementPath as SelectedServicePath;

  const { loading: usersLoading, data: usersData } = useProjectUsersQuery({
    variables: {
      projectId,
    },
    fetchPolicy: "cache-first",
    skip: projectId === "tasksAssignedView",
  });

  const [isDescriptionFieldFocused, setIsDescriptionFieldFocused] =
    useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);
  const [updateServiceFromWorkflow] = useUpdateServiceFromWorkflowMutation();
  const [serviceName, setServiceName] = useState<string>("");
  const [serviceStartDate, setServiceStartDate] = useState<Date>();
  const [serviceDueDate, setServiceDueDate] = useState<Date>();
  const [budget, setBudget] = useState<PriceInputValue>();
  const [serviceDescription, setServiceDescription] = useState<string>("");
  const [totalDays, setTotalDays] = useState<number | null>(null);

  const { useUserPermissions } = useUserRolesContext();
  const budgetIsVisible = useUserPermissions("seeBudget");

  const [selectedDatePickerId, setSelectedDatePickerId] = useState<
    number | null
  >(null);
  const [serviceAssignee, setServiceAssignee] = useState<
    string | undefined | null
  >(hardcodedUser?.id || null);
  const [servicePriority, setServicePriority] = useState<Priority | null>(null);
  const [editModeCalendar, setEditModeCalendar] = useState(editMode);
  const [lockedDuration, setLockedDuration] = useState(false);

  useEffect(() => {
    const { service } =
      selectedEntity.selectedElementPath as SelectedServicePath;

    let preferredCurrency: Currency | string = Currency.Eur;
    try {
      preferredCurrency =
        localStorage.getItem("preferredCurrency") || Currency.Eur;
    } catch (err) {
      // do nothing
    }

    setBudget({
      price: service.price?.price.value || "",
      currency: service.price?.currency || preferredCurrency,
    });
    setServiceDescription(service.description);
    setServiceName(service.name);
    setServiceStartDate(new Date(+service.startDate));
    setServiceDueDate(new Date(+service.endDate));
    setServicePriority(service.priority || null);
    setEditModeCalendar(
      editMode ? service.status === EntityStatus.Pending : false
    );
    setLockedDuration(service.durationLocked || false);
    if (!hardcodedUser) setServiceAssignee(service.assignee || "Unassigned");
  }, [selectedEntity]);

  useEffect(() => {
    if (serviceStartDate && serviceDueDate) {
      const calculatedTotalDays =
        moment(serviceDueDate).diff(moment(serviceStartDate), "days") + 1;

      setTotalDays(calculatedTotalDays);
    }
  }, [serviceStartDate, serviceDueDate, selectedEntity]);

  const updateService = (
    target: string,
    value: string | object | boolean | Priority | null,
    lockedDate?: [string, string]
  ): void => {
    if (!editMode || !isSelectedServicePath) {
      return;
    }

    if (target === "endDate" && serviceStartDate) {
      if (
        typeof value === "string" &&
        parseInt(value, 10) < serviceStartDate.getTime()
      ) {
        return;
      }
    }

    const options: ApolloReactHooks.MutationHookOptions<
      UpdateServiceFromWorkflowMutation,
      UpdateServiceFromWorkflowMutationVariables
    > = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceUpdatables: {
          id,
          ...(lockedDate
            ? {
                startDate: lockedDate[0],
                endDate: lockedDate[1],
              }
            : { [target]: value }),
        },
      },
      update(cache: InMemoryCache, { data }: any): void {
        // TODO: for Apollo Client v3
        // if (target === "assignee") {
        //
        // cache.evict({
        //   query: ServicesAssignedToUserForTimelineDocument,
        //   variables: { userId: value },
        // });
        // cache.gc()
        // }
        const incomingData = data?.["UpdateServiceFromWorkflow"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleHere = samplesHere.find((s) => s.id === sampleId);

          const updatedEnabledWorkflows = sampleHere?.enabledWorkflows.map(
            (workflow) => ({
              ...workflow,
              services: updateServices(workflow.services, incomingData),
            })
          );

          const newSamples = samplesHere.map((sampleLoop) => {
            if (sampleLoop.id === sampleId) {
              return {
                ...sampleLoop,
                enabledWorkflows: updatedEnabledWorkflows,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            variables: { id: projectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: newSamples,
              },
            },
          });
        }
      },
    };

    if (target === "assignee") {
      options.refetchQueries = ["ServicesAssignedToUserForTimeline"];
    }

    const newSelectedEntity = {
      ...selectedEntity,
      selectedElementPath: {
        ...(selectedEntity.selectedElementPath as SelectedServicePath),
        service: {
          ...(selectedEntity.selectedElementPath as SelectedServicePath)
            .service,
          ...(lockedDate
            ? {
                startDate: lockedDate[0],
                endDate: lockedDate[1],
              }
            : { [target]: value }),
        },
      },
    };

    setSelectedEntity(newSelectedEntity);

    updateServiceFromWorkflow(options);
  };

  const setServiceDueDateWithCheck = (newDate: Date): void => {
    if (serviceStartDate && newDate < serviceStartDate && !lockedDuration) {
      toast.error("You cannot set the end date earlier than the start date");
      return;
    }

    const copyOfNewDate = newDate;
    const dueDateWithCorrectHours = new Date(
      copyOfNewDate.setHours(23, 59, 59, 59)
    );

    if (lockedDuration && serviceDueDate && serviceStartDate) {
      const dateDifference = newDate.valueOf() - serviceDueDate.valueOf();

      const newStartValueDate = serviceStartDate.valueOf() + dateDifference;
      const newStartDate = new Date(+newStartValueDate);

      const newStartDateWithCorrectHours = new Date(
        newStartDate.setHours(0, 0, 0, 0)
      );

      updateService("lockDate", null, [
        newStartDateWithCorrectHours.getTime().toString(),
        dueDateWithCorrectHours.getTime().toString(),
      ]);
    } else {
      updateService("endDate", dueDateWithCorrectHours.getTime().toString());
    }
  };

  const handleServiceStartDateChange = (newDate: Date): void => {
    if (serviceDueDate && newDate > serviceDueDate && !lockedDuration) {
      toast.error("You cannot set the start date later than the end date");
      return;
    }

    const copyOfNewDate = newDate;
    const startDateWithCorrectHours = new Date(
      copyOfNewDate.setHours(0, 0, 0, 0)
    );

    if (lockedDuration && serviceDueDate && serviceStartDate) {
      const dateDifference = newDate.valueOf() - serviceStartDate.valueOf();

      const newDueValueDate = serviceDueDate.valueOf() + dateDifference;
      const newDueDate = new Date(+newDueValueDate);
      const newDueDateWithCorrectHours = new Date(
        newDueDate.setHours(23, 59, 59, 59)
      );

      updateService("lockDate", null, [
        startDateWithCorrectHours.getTime().toString(),
        newDueDateWithCorrectHours.getTime().toString(),
      ]);
    } else {
      updateService(
        "startDate",
        startDateWithCorrectHours.getTime().toString()
      );
    }
  };

  const handleAssigneeChange = (value: string): void => {
    setServiceAssignee(value);
    updateService("assignee", value);
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setServiceName(text);
  };

  const handleNameOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateService("name", trimmedText);
    setIsNameFieldFocused(false);
  };

  const handleBudgetChange = (p: PriceInputValue): void => {
    setBudget(p);
  };

  const handlePriorityChange = (priority: Priority | null): void => {
    setServicePriority(priority);
    updateService("priority", priority);
  };

  const handleBudgetOnBlur = (p: PriceInputValue): void => {
    try {
      localStorage.setItem("preferredCurrency", p.currency);
    } catch (err) {
      // do nothing
    }

    updateService("price", {
      price: {
        value: p.price,
      },
      currency: p.currency,
    });
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setServiceDescription(text);
  };

  const handleDescriptionOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateService("description", trimmedText);
    setIsDescriptionFieldFocused(false);
  };

  const handleCheckboxClick = (): void => {
    updateService("durationLocked", !lockedDuration);
    setLockedDuration((prev) => !prev);
  };

  const handleTextWithPencil = (
    flag: boolean,
    text: string,
    readonly?: boolean
  ): string => {
    if (readonly) return text;

    let preparedText = text;

    if (flag) {
      preparedText = text;
    } else if (text.length > 0) {
      preparedText += " ✎";
    } else {
      preparedText = text;
    }

    return preparedText;
  };

  const descriptionText = handleTextWithPencil(
    isDescriptionFieldFocused,
    serviceDescription,
    !editMode
  );
  const preparedServiceName = handleTextWithPencil(
    isNameFieldFocused,
    serviceName,
    !editMode
  );

  return (
    <Container>
      <EditableNameArea
        onChange={handleNameChange}
        onBlur={handleNameOnBlur}
        disabled={!editMode}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        value={preparedServiceName}
        onFocus={() => setIsNameFieldFocused(true)}
        placeholder={`Enter a name...${editMode ? " ✎" : ""}`}
      />
      <ServiceInformationAdditionalMargin editMode={editMode}>
        <ServiceInformationLabel>Assignee</ServiceInformationLabel>
        <UsersSelector
          users={usersData?.ProjectUsers as ProjectUser[]}
          loading={!hardcodedUser && usersLoading}
          editMode={editMode}
          selectedValue={serviceAssignee}
          onChange={handleAssigneeChange}
          hardcodedUser={hardcodedUser}
          disabled={!editMode}
        />
      </ServiceInformationAdditionalMargin>
      <ServiceInformation>
        <ServiceInformationLabel>Start Date</ServiceInformationLabel>
        <ServiceInformationValue editMode={editModeCalendar}>
          <DatePicker
            date={serviceStartDate!}
            setDate={handleServiceStartDateChange}
            editMode={editModeCalendar}
            update={updateService}
            destinationUpdate="startDate"
            showIcon
            selectedDatePickerId={selectedDatePickerId}
            setSelectedDatePickerId={setSelectedDatePickerId}
            datePickerId={1}
          />
        </ServiceInformationValue>
      </ServiceInformation>
      <ServiceInformation>
        <ServiceInformationLabel>Due Date</ServiceInformationLabel>
        <ServiceInformationValue editMode={editModeCalendar}>
          <DatePicker
            date={serviceDueDate!}
            setDate={setServiceDueDateWithCheck}
            editMode={editModeCalendar}
            update={updateService}
            destinationUpdate="endDate"
            showIcon
            selectedDatePickerId={selectedDatePickerId}
            setSelectedDatePickerId={setSelectedDatePickerId}
            datePickerId={2}
          />
        </ServiceInformationValue>
      </ServiceInformation>
      <ServiceInformation>
        <ServiceInformationLabel>Duration</ServiceInformationLabel>
        <ServiceInformationValue editMode={false}>
          <p>
            {totalDays} {totalDays && totalDays > 1 ? "Days" : "Day"}
          </p>
        </ServiceInformationValue>
      </ServiceInformation>
      <ServiceInformation>
        <ServiceInformationLabel>Lock Duration</ServiceInformationLabel>
        <ServiceInformationValue editMode={editMode}>
          <IconContainer>
            <ContainerCheckbox
              id={id}
              onClick={handleCheckboxClick}
              checked={lockedDuration}
              readonly={!editMode}
            />
          </IconContainer>
        </ServiceInformationValue>
      </ServiceInformation>
      {budgetIsVisible ? (
        <ServiceInformation>
          <ServiceInformationLabel>Budget</ServiceInformationLabel>
          <ServiceInformationValue editMode={editMode}>
            <PriceInput
              editMode={editMode}
              value={budget}
              onChange={handleBudgetChange}
              onBlur={handleBudgetOnBlur}
            />
          </ServiceInformationValue>
        </ServiceInformation>
      ) : null}
      <ServiceInformationAdditionalMargin editMode={editMode}>
        <ServiceInformationLabel>Priority</ServiceInformationLabel>
        <ServiceInformationDropdown>
          <PriorityDropdown
            onPriorityChange={handlePriorityChange}
            priority={servicePriority}
            readOnly={!editMode}
          />
        </ServiceInformationDropdown>
      </ServiceInformationAdditionalMargin>
      <ServiceInformationAdditionalMargin editMode={editMode}>
        <ServiceDescriptionInformationLabel>
          Description
        </ServiceDescriptionInformationLabel>
        <EditableDescriptionArea
          onChange={handleDescriptionChange}
          onBlur={handleDescriptionOnBlur}
          disabled={!editMode}
          value={descriptionText}
          onFocus={() => setIsDescriptionFieldFocused(true)}
          placeholder={`Enter a description...${editMode ? " ✎" : ""}`}
        />
      </ServiceInformationAdditionalMargin>
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 24px;
  border-bottom: 1px solid
    ${(props): string => props.theme.newColors.grayscale.bordersInside};
`;

const EditableArea = styled(TextareaAutosize)`
  resize: none;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 6px 3px 6px 3px;
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px ${(props) => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `};

  &:empty::before {
    content: attr(data-placeholder-description);
    color: #ccc;
    font-weight: 400;
  }

  &:empty:focus::before {
    content: "";
  }
`;

const EditableNameArea = styled(EditableArea)`
  min-height: 20px;
  line-height: 20px;
  font-size: 17px;
`;

const EditableDescriptionArea = styled(EditableArea)`
  min-height: 18px;
  line-height: 18px;
  font-size: 14px;
`;

const IconContainer = styled.div`
  margin-left: 30px;
`;

const ServiceInformation = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 5px;
  column-gap: 23px;
`;

const ServiceInformationAdditionalMargin = styled(ServiceInformation)<{
  editMode: boolean | undefined;
}>`
  width: 100%;

  ${({ editMode }) =>
    editMode &&
    css`
      margin: 14px 0;
    `};
`;

const ServiceInformationLabel = styled.div`
  color: ${(props) => props.theme.newColors.primary.basic};
  padding: 3px 6px;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const ServiceDescriptionInformationLabel = styled(ServiceInformationLabel)`
  padding: 4px 6px;
  height: fit-content;
`;

const ServiceInformationValue = styled.div<{ editMode: boolean | undefined }>`
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: absolute;
  margin-left: 100px;

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;

const ServiceInformationDropdown = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: absolute;
  margin-left: 100px;
  height: 2rem;
  width: calc(100% - 100px);
`;
