import React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const RightBlueExpandIcon = (props: BaseIconProps) => {
  const { width, height, color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <defs>
        <filter
          id="filter0_d"
          width="40"
          height="40"
          x="-8"
          y="-4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feColorMatrix values="0 0 0 0 0.168627 0 0 0 0 0.45098 0 0 0 0 0.862745 0 0 0 0.3 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
      <g className="currentLayer">
        <g filter="url(#filter0_d)" opacity="1">
          <circle
            r="12"
            fill={color}
            opacity="1"
            transform="matrix(-1 0 0 1 12 12)"
          />
        </g>
        <path
          stroke="#fff"
          strokeWidth="2"
          d="M10.074 16.036L14 11.963 9.926 8.038"
          className="selected"
        />
      </g>
    </svg>
  );
};

RightBlueExpandIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.primary.basic,
};
