import React from "react";
import { useAuth } from "react-use-auth";
import { RouteComponentProps } from "@reach/router";
import { ROUTES } from "../../../constants/routeConstants";
import { Welcome } from "../../Welcome/Welcome";
import { ROLES } from "../../../constants/roles";

interface ProtectedRouteProps {
  route: React.FC<RouteComponentProps>;
  path: string;
}

const accessPathMap = new Map<string, string[]>([
  [ROLES.ADMINISTRATOR, ["*"]],
  [ROLES.ORGANIZATION_ADMINISTRATOR, ["*"]],
  [ROLES.PROJECT_MANAGER, ["*"]],
  [
    ROLES.SPECIALIST,
    [
      ROUTES.PROJECT_CONFIGURATOR,
      ROUTES.SAMPLE_CONFIGURATOR,
      ROUTES.REPORTS,
      ROUTES.REPORT,
    ],
  ],
  [ROLES.READONLY, []],
]);

export const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const { route: Component, path } = props;

  const { user } = useAuth();
  const userRoles: ROLES[] = user["https://leaderneplatform.com/roles"] || [];

  const availablePaths = userRoles
    .map((role) => accessPathMap.get(role) || [])
    .reduce((accPaths, paths) => [...new Set([...accPaths, ...paths])], []);

  const accessToPath =
    availablePaths?.includes("*") || availablePaths?.includes(path);

  if (accessToPath) {
    return <Component path={path} />;
  }

  return <Welcome path={ROUTES.WELCOME} default />;
};
