let interval: number;

export const startScroll = (
  element: HTMLDivElement,
  direction: string,
  speed: number,
  step: number
) => {
  const elem = element;
  interval = window.setInterval(() => {
    if (direction === "left") {
      elem.scrollLeft -= step;
    } else {
      elem.scrollLeft += step;
    }
  }, speed);
};

export const stopScroll = () => {
  if (interval) {
    window.clearInterval(interval);
  }
};
