import { useAuth } from "react-use-auth";
import { ROLES } from "../../constants/roles";

export const useUserPermissionsSource = (
  permission: string,
  roles: string[] = [],
  role?: ROLES
): boolean => {
  let userRoles: ROLES[] = [];

  if (role) {
    userRoles = [role];
  } else {
    const data = useAuth();
    userRoles = data.user["https://leaderneplatform.com/roles"] || roles;
  }

  if (
    userRoles.includes(ROLES.ADMINISTRATOR) ||
    userRoles.includes(ROLES.ORGANIZATION_ADMINISTRATOR)
  ) {
    return true;
  }

  const permissionsMap = {
    editField: [ROLES.PROJECT_MANAGER],
    editForm: [ROLES.PROJECT_MANAGER],
    deleteForm: [ROLES.PROJECT_MANAGER],
    duplicateTask: [ROLES.PROJECT_MANAGER],
    moveSubtask: [ROLES.PROJECT_MANAGER],
    deleteTasks: [ROLES.PROJECT_MANAGER],
    addNewSection: [ROLES.PROJECT_MANAGER],
    addNewProject: [ROLES.PROJECT_MANAGER],
    editServiceInformations: [ROLES.PROJECT_MANAGER, ROLES.SPECIALIST],
    manageUsers: [ROLES.PROJECT_MANAGER],
    addNewPath: [ROLES.PROJECT_MANAGER],
    goToProjectData: [ROLES.PROJECT_MANAGER, ROLES.SPECIALIST],
    addItemsToTimeline: [ROLES.PROJECT_MANAGER, ROLES.SPECIALIST],
    accessReports: [ROLES.PROJECT_MANAGER, ROLES.SPECIALIST],
    makeElementVisible: [ROLES.PROJECT_MANAGER],
    copyTaskToOtherPath: [ROLES.PROJECT_MANAGER],
    saveAsTemplate: [ROLES.PROJECT_MANAGER],
    seeBudget: [ROLES.PROJECT_MANAGER],
    seeTeam: [ROLES.PROJECT_MANAGER, ROLES.SPECIALIST],
    moveAllServicesToNewStartDate: [ROLES.PROJECT_MANAGER],
    projectDuplication: [ROLES.PROJECT_MANAGER],
  };

  const allowedRoles: ROLES[] = permissionsMap[permission] || [];

  return allowedRoles.some((allowedRole) => userRoles.includes(allowedRole));
};
