import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import { TextBox } from "../../../../common/TextBox/TextBox";
import { URLIcon } from "../../../../../images/icons/URLIcon";
import { styledTheme } from "../../../../../theme/theme";
import { PreviewUrlIcon } from "../../../../../images/icons/PreviewUrlIcon";
import { DeleteIcon } from "../../../../../images/icons/DeleteIcon";

const svgString = encodeURIComponent(renderToStaticMarkup(<URLIcon />));

function isValidUrl(string: string): boolean {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return !!url.protocol;
}

export const ExternalLinksElement = (
  props: ExternalLinksElementPropsT
): JSX.Element => {
  const { id, value, onChange, onDelete, testId, disabled } = props;

  let valueAsUrl = value.trim();
  if (!isValidUrl(valueAsUrl)) {
    valueAsUrl = `https://${valueAsUrl}`;
  }

  return (
    <Container data-testid={testId}>
      <TextBox
        placeholder="https://www.example.com"
        svgIcon={svgString}
        value={value}
        onChange={(input: any) => onChange(input.target.value)}
        testId={`${testId}_input`}
        disabled={disabled}
      />
      {valueAsUrl.length && !disabled ? (
        <>
          <a href={valueAsUrl} target="_blank" rel="noreferrer">
            <Button data-testid={`${testId}_previewButton`}>
              <PreviewUrlIcon />
            </Button>
          </a>
          <Button
            onClick={() => onDelete(id)}
            style={{ cursor: "pointer" }}
            data-testid={`${testId}_deleteButton`}
          >
            <DeleteIcon />
          </Button>
        </>
      ) : null}
    </Container>
  );
};

type ExternalLinksElementPropsT = {
  id: number;
  value: string;
  onChange: any;
  onDelete: any;
  testId?: string;
  disabled?: boolean;
};

const Container = styled.div`
  display: flex;
`;

const Button = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.userInputs.basicInside};
  border-radius: 4px;
  margin-left: 16px;
`;
