import * as React from "react";
import { useReactToPrint } from "react-to-print";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Form } from "./Elements/Form";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { getFormSchemaFromQuestionGroup } from "../../utils/helpers/dynamicForms/getFormSchemaFromQuestionGroup";
import { Button } from "../common/Button/Button";
import { getValidationSchemaFromQuestionGroup } from "../../utils/helpers/dynamicForms/getValidationSchemaFromQuestionGroup";
import { QuestionGroup } from "../../generated/graphql";
import CheckboxElement from "./Elements/CheckboxElement";
import { getFormDataFromQuestionGroup } from "../../utils/helpers/dynamicForms/getFormDataFromQuestionGroup";
import { useActionContext } from "../../utils/hooks/useActionContext";
import {
  FormSchemaField,
  QuestionLevelInput,
} from "../../utils/types/QuestionMetadata";
import { getPrintFormElement } from "../../utils/helpers/dynamicForms/forPrint/getPrintFormElement";

export const DynamicPrintingFormWrapper = (
  props: DynamicPrintingFormWrapperProps
) => {
  const {
    title,
    stepNumber,
    wrapped,
    wrapCardsCallback,
    questionGroup,
    handleOnPrepareForPrinting,
    questionLevelInput,
    answerQuestionCallback,
  } = props;
  const { setSelectedEntity } = useActionContext();

  const [checkedQuestionGroup, setCheckedQuestionGroup] = React.useState<
    boolean[]
  >(() => {
    const questions = questionGroup.questionSets;
    const numberOfQuestions = questions.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.questions.length,
      0
    );

    return new Array(numberOfQuestions).fill(false);
  });

  const formSchema = getFormSchemaFromQuestionGroup(
    questionGroup,
    questionLevelInput,
    answerQuestionCallback
  );

  const getCurrentSchema = (key: string) => {
    const newSchema = formSchema[key] as FormSchemaField;

    newSchema.disabled = true;

    return newSchema;
  };

  const componentRef = React.useRef(null);

  const handleFinishPrintingMode = () => {
    setSelectedEntity(undefined);
    if (handleOnPrepareForPrinting) handleOnPrepareForPrinting("");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleCheckboxClick = (position: number) => {
    const updatedCheckedQuestionGroup = checkedQuestionGroup.map(
      (item, index) => (index === position ? !item : item)
    );

    setCheckedQuestionGroup(updatedCheckedQuestionGroup);
  };

  const isUserSelectAtLeastOneField = (fieldsState: boolean[]) => {
    return fieldsState.some((fieldState) => fieldState === true);
  };

  const showPrintintError = () => {
    return toast.error("You need to select field you want to print.");
  };

  const getSectionButtons = (): React.ReactNode[] => {
    return [
      <Button label="Back to overview" onClick={handleFinishPrintingMode} />,
      <Button
        label="Print"
        onClick={
          isUserSelectAtLeastOneField(checkedQuestionGroup)
            ? handlePrint
            : showPrintintError
        }
      />,
    ];
  };

  return (
    <ContentSection
      title={title}
      stepNumber={stepNumber}
      wrapped={wrapped}
      wrapCardsCallback={wrapCardsCallback}
      buttons={getSectionButtons()}
    >
      <ComponentToPrint ref={componentRef}>
        {checkedQuestionGroup.length > 0 ? (
          <Form
            enableReinitialize
            initialValues={getFormDataFromQuestionGroup(questionGroup)}
            validationSchema={getValidationSchemaFromQuestionGroup(
              questionGroup
            )}
            onSubmit={(values: any) => console.log(values)}
          >
            <Container>
              <FormContainer>
                {Object.keys(formSchema).map((key, id) => {
                  return (
                    <FormElementContainer
                      key={key}
                      isSelected={checkedQuestionGroup[id]}
                    >
                      {getPrintFormElement(
                        key,
                        getCurrentSchema(key) as FormSchemaField
                      )}
                      <IconContainer>
                        {" "}
                        <CheckboxElement
                          id={`${id}`}
                          handleCheckboxClick={handleCheckboxClick}
                          position={id}
                        />
                      </IconContainer>
                    </FormElementContainer>
                  );
                })}
              </FormContainer>
              <ToPrintNotification>Select data to print:</ToPrintNotification>
            </Container>
          </Form>
        ) : (
          <div key={questionGroup.id}>
            Empty Question Group - Edit to add new form elements
          </div>
        )}
      </ComponentToPrint>
    </ContentSection>
  );
};

interface DynamicPrintingFormWrapperProps {
  questionLevelInput: QuestionLevelInput;
  answerQuestionCallback: any;
  title: string;
  stepNumber: number;
  wrapped: boolean;
  questionGroup: QuestionGroup;
  wrapCardsCallback: () => void;
  handleOnPrepareForPrinting?: (questionGroupId: string) => void;
}

const ComponentToPrint = styled.div`
  textarea {
    overflow: hidden;
  }

  @media print {
    display: block;

    @page {
      margin: 80px 40px;
    }
  }
`;

const FormElementContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;

  & div:first-child {
    flex: 1;
  }

  @media print {
    display: ${(props) => (props.isSelected ? "flex" : "none")};
    margin-bottom: 40px;
    page-break-inside: avoid;

    hr {
      display: none;
    }
  }
`;

const IconContainer = styled.div`
  margin-left: 30px;

  @media print {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ToPrintNotification = styled.p`
  position: absolute;
  top: 0;
  right: 0;
  color: #232f3c;
  font-size: 16px;
  font-weight: bold;

  @media print {
    display: none;
  }
`;

const FormContainer = styled.div`
  flex-grow: 1;
  min-width: 500px;
`;
