import * as React from "react";
import { FunctionComponent, useState, useEffect } from "react";
import { FieldProps } from "formik";
import { TextBox } from "../../common/TextBox/TextBox";
import { FormGroup } from "./FormGroup";
import { getFieldStatus, LabelTagTypes } from "../../common/LabelTag/LabelTag";
import { FormElementProps } from "../../../utils/helpers/dynamicForms/FormElementProps";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

type Props = FormElementProps & {
  type: string;
};

type OwnProps = FieldProps & Props;

export const TextField: FunctionComponent<OwnProps> = (props) => {
  const {
    placeholder,
    field,
    label,
    description,
    answerQuestionCallback,
    metadata,
    type,
    required,
    fieldStatus: fieldStatusCallback,
    disabled,
  } = props;
  const [fieldValue, setFieldValue] = useState(field.value);
  const [fieldStatus, setFieldStatus] = useState(
    getFieldStatus(required, field.value, fieldValue)
  );
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  // useEffect(() => {
  //   setFieldValue(field.value);
  // }, [field.value]);

  useEffect(() => {
    const currentStatus = getFieldStatus(required, field.value, fieldValue);
    setFieldStatus(currentStatus);
    fieldStatusCallback(currentStatus);
  }, [fieldValue, field.value]);

  // console.log("PINGWING: 33 valueSaved", field.value === fieldValue); // TODO: signal this state, SAVING for false, SAVED for true

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(event.target.value);
    answerQuestionCallback(metadata, event.target.value);
  };

  const handleOnKeyDown = (event: any) => {
    if (fieldValue === "") {
      setFieldStatus({ type: LabelTagTypes.FAILED, name: "FAILED" });
    }
    const sameSignExp = new RegExp(/^[.,]*$/);

    if (
      (sameSignExp.test(fieldValue) || fieldValue === "") &&
      sameSignExp.test(event.key)
    ) {
      setFieldStatus({ type: LabelTagTypes.FAILED, name: "FAILED" });
      return event.preventDefault();
    }
    const inputNumberExp = new RegExp(
      /(^\d*,?\d*\.?\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|F5)/
    );

    return !event.key.match(inputNumberExp) && event.preventDefault();
  };

  return (
    <FormGroup
      label={label}
      description={description}
      status={fieldStatus}
      disabled={disabled}
    >
      <TextBox
        placeholder={placeholder === null ? undefined : placeholder}
        type={type}
        {...field}
        value={fieldValue}
        onChange={onChange}
        onKeyDown={
          type === "number" ? (event: any) => handleOnKeyDown(event) : undefined
        }
        disabled={disabled}
      />
      {/* {form.touched[field.name] && form.errors[field.name] ? ( */}
      {/*  <div>{form.errors[field.name]}</div> */}
      {/* ) : null} */}
    </FormGroup>
  );
};
