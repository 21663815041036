import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "../Spinner/Spinner";

const modalRoot = document.getElementById("modals");

type ModalProps = {
  id: string;
  children?: JSX.Element;
  footerContent: JSX.Element;
  title: string;
  className?: string;
  dialogClassName?: string;
  onClose: () => void;
  isLoading?: boolean;
};

export const Modal = (props: ModalProps): JSX.Element => {
  const {
    id,
    children,
    footerContent,
    title,
    className,
    dialogClassName,
    onClose,
    isLoading,
  } = props;

  const background = useRef<HTMLDivElement>(null);
  const [fadeType, setFadeType] = useState<"in" | "out">("in");

  useEffect(() => {
    return (): void => {
      setFadeType("out");
    };
  }, []);

  const result: JSX.Element = (
    <>
      {isLoading && <Spinner className="spinner" opaque />}
      <ModalStyled id={id} className={`fade-${fadeType} ${className}`}>
        <div className={`modal-dialog ${dialogClassName}`}>
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-content-footer">
            <div className="modal-content-wrapper">
              <div className="modal-content">{children}</div>
            </div>
            <div className="modal-footer">{footerContent}</div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="background"
          ref={background}
          onClick={onClose}
        />
      </ModalStyled>
    </>
  );

  if (modalRoot) {
    return ReactDOM.createPortal(result, modalRoot);
  }

  return result;
};

const ModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.3s;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.3s;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.3s;
  }

  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 900;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }

  .spinner {
    z-index: 1001;
  }

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: ${(props) => props.theme.newColors.grayscale.background};
    border-radius: 10px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

    .modal-header {
      padding: 0 1rem;
      height: 3.5rem;
      display: flex;
      background-color: ${(props) =>
        props.theme.newColors.grayscale.primaryGray};
      border-radius: 0.5rem 0.5rem 0 0;
      align-items: center;

      .modal-title {
        color: ${(props) => props.theme.newColors.grayscale.white};
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 800;
        margin: 0 0 0 0;
      }
    }

    .modal-content-wrapper {
      padding: 1.5rem 5rem;
      display: flex;
      justify-content: center;
      max-height: 80vh;
    }

    .modal-content {
      min-width: 488px;
      padding: 0.75rem;
    }

    .modal-footer {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid
        ${(props) => props.theme.newColors.grayscale.bordersInside};
      border-radius: 0 0 8px 8px;
    }
  }
`;
