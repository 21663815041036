import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import {
  ContentSection,
  ContentSectionProps,
} from "../../../../common/ContentSection/ContentSection";
import { Button } from "../../../../common/Button/Button";
import { QuestionGroup } from "../../../../../generated/graphql";

type Props = ContentSectionProps & {
  questionGroup: QuestionGroup;
  goToNextGroupCallback?: () => void;
};

export const PackageDescriptionSection = ({
  goToNextGroupCallback,
  questionGroup,
  ...props
}: Props): JSX.Element => {
  const getSectionButtons = (): React.ReactNode[] => {
    if (goToNextGroupCallback) {
      return [
        <Button label="Go to next section" onClick={goToNextGroupCallback} />,
      ];
    }
    return [];
  };

  const packageDescription = get(
    questionGroup,
    "questionSets[0].questions[0].answer.textBody",
    "In preparation"
  );

  const formatPackageDescription = (text: string): JSX.Element[] => {
    return text
      .split("\n")
      .filter((paragraph) => !!paragraph) // remove empty paragraphs
      .map((paragraph) => (
        <StyledParagraph key={`${paragraph.substring(0, 8)}_${Math.random()}`}>
          {paragraph}
        </StyledParagraph>
      ));
  };

  return (
    <ContentSection {...props} buttons={getSectionButtons()}>
      <TextContainer>
        {formatPackageDescription(packageDescription)}
      </TextContainer>
    </ContentSection>
  );
};

const TextContainer = styled.div`
  margin-right: 22px;
`;
const StyledParagraph = styled.p`
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5;
`;
