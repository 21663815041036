import * as React from "react";
import { FunctionComponent } from "react";
import { FieldProps } from "formik";
import { FormGroup } from "../FormGroup";
import { TextArea } from "../../../common/TextArea/TextArea";
import { QuestionMetadata } from "../../../../utils/types/QuestionMetadata";
import { GetFieldStatusResultT } from "../../../common/LabelTag/LabelTag";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled: boolean;
}

type OwnProps = FieldProps & Props;

export const TextAreaField: FunctionComponent<OwnProps> = (props) => {
  const { placeholder, field, label, description } = props;

  return (
    <FormGroup label={label} description={description}>
      <TextArea
        placeholder={placeholder}
        {...field}
        value={field.value}
        // isDisabled={true}
        onlyForRead
        rows={
          field.value && field.value.length > 0
            ? field.value.split("\n").length
            : null
        }
      />
    </FormGroup>
  );
};
