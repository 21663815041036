import React from "react";
import styled from "styled-components";
import { TickIcon } from "../../../images/icons/TickIcon";
import { SpinnerElement } from "../Spinner/SpinnerElement";
import { styledTheme } from "../../../theme/theme";

export enum LabelTagTypes {
  DEFAULT,
  SUCCESS,
  INVERTED,
  SAVING,
  SAVED,
  FAILED,
}

const getColors = (type: LabelTagTypes | undefined): getColorsT => {
  switch (type) {
    case LabelTagTypes.SUCCESS:
    case LabelTagTypes.SAVING:
      return {
        color: styledTheme.newColors.primary.basic,
        backgroundColor: styledTheme.newColors.primary.lightestTint,
      };
    case LabelTagTypes.SAVED:
      return {
        color: styledTheme.newColors.secondary.hover,
        backgroundColor: styledTheme.newColors.secondary.lightTint,
      };
    case LabelTagTypes.INVERTED:
      return {
        color: styledTheme.newColors.grayscale.background,
        backgroundColor: styledTheme.newColors.grayscale.primaryGray,
      };
    case LabelTagTypes.FAILED:
      return {
        color: styledTheme.newColors.grayscale.background,
        backgroundColor: "#de2d26",
      };
    default:
      return {
        color: styledTheme.newColors.grayscale.primaryGray,
        backgroundColor: styledTheme.newColors.grayscale.background,
      };
  }
};

export type GetFieldStatusResultT =
  | {
      type: LabelTagTypes;
      name: string;
    }
  | undefined;

export const getFieldStatus = (
  required: boolean,
  fieldValue: any,
  value: any
): GetFieldStatusResultT => {
  if (typeof fieldValue === "number") {
    // eslint-disable-next-line no-param-reassign
    value = parseFloat(value);
  }

  if ((fieldValue && fieldValue !== value) || (!fieldValue && value)) {
    return { type: LabelTagTypes.SAVING, name: "SAVING..." };
  }

  if (fieldValue && fieldValue === value) {
    return { type: LabelTagTypes.SAVED, name: "SAVED" };
  }

  if (!fieldValue && required) {
    return { type: LabelTagTypes.DEFAULT, name: "REQUIRED" };
  }

  return undefined;
};

type getColorsT = {
  color: string;
  backgroundColor: string;
};

export const LabelTag = (props: LabelTagPropsT) => {
  const { name, type, selected } = props;

  const { color, backgroundColor } = getColors(type);

  return (
    <Container
      color={color}
      backgroundColor={backgroundColor}
      selected={selected}
    >
      {type === LabelTagTypes.SUCCESS || type === LabelTagTypes.SAVED ? (
        <span>
          <TickIcon color={color} testId="labelTagTickIcon" />
        </span>
      ) : null}
      {type === LabelTagTypes.SAVING ? (
        <SpinnerElement size={9} width={8} testId="labelTagSpinner" />
      ) : null}
      {name}
    </Container>
  );
};

type LabelTagPropsT = {
  name: string;
  type?: LabelTagTypes | undefined;
  selected?: boolean;
};

LabelTag.defaultProps = {
  type: LabelTagTypes.DEFAULT,
  selected: false,
};

const Container = styled.div<{
  color: string;
  backgroundColor: string;
  selected: boolean | undefined;
}>`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  ${({ selected }) => (selected ? "word-spacing: 8px;" : "")}
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100px;

  display: inline-flex;
  align-items: center;
  padding: 4px 12px;

  svg {
    margin-right: 10px;
  }

  span > svg {
    transform: scale(0.8);
  }
`;
