import React from "react";
import styled from "styled-components";
import { BaseTextArea } from "../BaseTextArea";

interface TextAreaProps {
  placeholder?: string;
  isDisabled?: boolean;
  onChange?: any;
  error?: boolean;
  value: string;
  staticForm?: boolean;
  onlyForRead?: boolean;
  rows?: number;
}

export const TextArea = (props: TextAreaProps) => {
  const {
    placeholder,
    isDisabled,
    error,
    onChange,
    value,
    staticForm,
    onlyForRead,
    rows,
  } = props;

  return (
    <InputContainer>
      {staticForm && <StaticInput />}
      <BaseTextArea
        placeholder={placeholder}
        disabled={isDisabled}
        error={error}
        onChange={onChange}
        value={value}
        maxRows={rows || 24}
        readOnly={onlyForRead || false}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const StaticInput = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
`;
