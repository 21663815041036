import React, { useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { useAuth } from "react-use-auth";
import { Box } from "rebass";
import { BellIcon } from "../../images/icons/BellIcon";
import { QuestionMarkIcon } from "../../images/icons/QuestionMarkIcon";
import { LogoutIcon } from "../../images/icons/LogoutIcon";
import { MenuDotsHorizontallyIcons } from "../../images/icons/MenuDotsHorizontallyIcons";
import { UserAvatar } from "../common/UserAvatar/UserAvatar";

import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { ChangePasswordModal } from "./ChangePasswordModal";

export const ActionBar = (): JSX.Element => {
  const dropdownButtonRef: any = React.useRef(null);
  const dropdownMenuRef = React.useRef(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [changePasswordModalOpened, setChangePasswordModalOpened] =
    useState(false);

  useOnClickOutside(dropdownMenuRef, (event) => {
    if (!dropdownButtonRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  });

  const {
    // TODO not sure how to handle that types
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    user: { name, clientID, picture } = {
      name: "",
      clientID: "#0000",
      picture: "",
    },
    isAuthenticated,
    logout,
  } = useAuth();

  const openChangePasswordModal = (): void => {
    setChangePasswordModalOpened(true);
  };

  const getUsernameComponent = (): JSX.Element => {
    if (isAuthenticated()) {
      const splittedName = name.split("@");
      return (
        <>
          <UserName>{splittedName[0]}</UserName>@{splittedName[1]}
        </>
      );
    }
    return <></>;
  };

  return (
    <StyledActionBar>
      {isAuthenticated() && (
        <>
          <UserAvatar user={{ picture }} />
          <Flex flexDirection="column" pr="16px" pl="8px">
            <UsernameContainer>{getUsernameComponent()}</UsernameContainer>
            <ClientId>CID {clientID}</ClientId>
          </Flex>
        </>
      )}
      <IconsWrapper>
        <IconContainer>
          <BellIcon />
        </IconContainer>
        <IconContainer>
          <QuestionMarkIcon />
        </IconContainer>
      </IconsWrapper>
      <ActionsContainer>
        <DotsIconContainer
          ref={dropdownButtonRef}
          onClick={(): void => setMenuOpen(!menuOpen)}
        >
          <MenuDotsHorizontallyIcons />
        </DotsIconContainer>
        <Menu ref={dropdownMenuRef} isMenuOpen={menuOpen}>
          <ul>
            <MenuLiItem onClick={logout}>
              <IconContainer>
                <LogoutIcon />
              </IconContainer>
              <p>Logout</p>
            </MenuLiItem>
            <MenuLiItem onClick={openChangePasswordModal}>
              <IconContainer>🔑</IconContainer>
              <p>Change password</p>
            </MenuLiItem>
          </ul>
        </Menu>
      </ActionsContainer>
      {changePasswordModalOpened && (
        <ChangePasswordModal
          isOpen={changePasswordModalOpened}
          onClose={(): void => setChangePasswordModalOpened(false)}
        />
      )}
    </StyledActionBar>
  );
};

const StyledActionBar = styled(Flex)`
  justify-content: flex-end;
  min-width: 250px;
`;

const UsernameContainer = styled(Box)`
  display: flex;
`;

const UserName = styled(Text)`
  white-space: none;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClientId = styled(Text)`
  color: ${(p) => p.theme.colors.lightText};
  font-size: ${(p) => p.theme.fontSizes[0]};
`;

const IconsWrapper = styled(Flex)`
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
  border-right: 1px solid
    ${({ theme }) => theme.newColors.grayscale.bordersInside};
  border-left: 1px solid
    ${({ theme }) => theme.newColors.grayscale.bordersInside};
  flex: 0 0 auto;
`;

const ActionsContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-right: 0.5rem !important;
`;

const IconContainer = styled(Box)`
  min-height: 24px;
  min-width: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DotsIconContainer = styled(IconContainer)`
  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover circle {
    fill: ${({ theme }): string => theme.newColors.grayscale.primaryGray};
    transition: fill linear 0.2s;
  }

  & > svg:active circle {
    fill: ${({ theme }): string => theme.newColors.grayscale.secondaryGray};
    transition: fill linear 0.2s;
  }
`;

const Menu = styled.nav<{ isMenuOpen: boolean }>`
  background: ${({ theme }): string => theme.newColors.grayscale.white};
  border-radius: 0.5rem;
  position: absolute;
  top: 46px;
  right: 0;
  width: 240px;
  box-shadow: ${({ theme }): string => theme.shadows.userInputs.basicInside};
  border: 1px solid
    ${(props): string => props.theme.newColors.grayscale.bordersInside};
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 10;

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & li {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: 6px;
    cursor: pointer;
    transition: 200ms;
  }

  & li > p {
    margin: 0;
    padding-top: 2px;
    line-height: 22px;
    white-space: nowrap;
  }

  & li:hover {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.lightTint};
    transition: 200ms;
  }

  & li:active {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.lightestTint};
    transition: 200ms;
  }

  & li:first-of-type:hover {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  & li:last-of-type:hover {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `}
`;

const MenuLiItem = styled.li`
  display: flex;
  align-items: center;
`;
