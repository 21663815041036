import React from "react";
import styled from "styled-components";
import { SamplePercent } from "./SamplePercent";
import { styledTheme } from "../../../theme/theme";

export const SampleBar = (props: SampleBarPropsT): JSX.Element => {
  const { name, percent, icon } = props;

  return (
    <Container>
      <NameContainer>{name}</NameContainer>
      <SamplePercent percent={percent} />
      {icon !== undefined ? <IconContainer>{icon}</IconContainer> : null}
    </Container>
  );
};

type SampleBarPropsT = {
  name: string;
  percent: number;
  icon?: any;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NameContainer = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 8px;
  border-right: 1px solid #dedede;
  margin-right: 10px;
  flex-grow: 1;
  color: ${styledTheme.newColors.grayscale.primaryGray};
`;

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
  min-width: 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
