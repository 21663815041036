import React from "react";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import styled, { css } from "styled-components";
import { DragIcon } from "../../../../images/icons/DragIcon";

type StaticEventProps = {
  icon: React.ReactNode;
  className?: string;
  dragHandleVisible?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  copy?: boolean;
};

export const StaticEvent = (props: StaticEventProps): JSX.Element => {
  const {
    icon,
    className,
    dragHandleVisible,
    dragHandleProps,
    copy = false,
  } = props;
  return (
    <Container copy={copy} className={className}>
      <IconContainer>{icon}</IconContainer>
      {dragHandleVisible && (
        <DragIconContainer {...dragHandleProps}>
          <DragIcon />
        </DragIconContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{ copy: boolean }>`
  display: flex;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(220, 222, 229, 0.3);
  border: solid 1px #dedede;
  background-color: #ffffff;
  opacity: 1;
  width: fit-content;

  ${(copy) =>
    copy &&
    css`
      ~ div {
        transform: none !important;
      }

      ~ [data-rbd-placeholder-context-id] {
        display: none !important;
      }
    `};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DragIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;
