import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import {
  Group,
  Sample,
  useGroupsQuery,
  useProjectUsersQuery,
  useUserProjectQuery,
  WorkflowForCustomer,
} from "../../../generated/graphql";
import { getSampleName } from "../../../utils/helpers/getSampleName";

type TeamUser = {
  id: string;
  name: string;
  email: string;
  role: string;
  avatar: string;
};

type Option = {
  label: string;
  value: string;
};

interface ReturnType {
  selectedSample: Option;
  setSelectedSample: (option: Option) => void;
  samples: Sample[];
  users: TeamUser[];
  groups: Group[];
  error: ApolloError | undefined;
  loading: boolean;
  workflows: WorkflowForCustomer[];
}

interface Props {
  projectId: string;
}

export const useProjectTasks = (props: Props): ReturnType => {
  const { projectId } = props;

  const [selectedSample, setSelectedSample] = useState<Option>({
    label: "",
    value: "",
  });
  const [samples, setSamples] = useState<Sample[]>([]);
  const [users, setUsers] = useState<TeamUser[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [workflows, setWorkflows] = useState<WorkflowForCustomer[]>([]);

  const {
    error: errorProject,
    loading: loadingProject,
    data: dataProject,
  } = useUserProjectQuery({
    variables: {
      id: projectId,
    },
  });

  const {
    error: errorUsers,
    loading: loadingUsers,
    data: dataUsers,
  } = useProjectUsersQuery({
    variables: { projectId },
  });

  const {
    error: errorGroups,
    loading: loadingGroups,
    data: dataGroups,
  } = useGroupsQuery();

  useEffect(() => {
    if (!dataProject?.Project) return;

    setSamples(dataProject.Project.samples);

    if (dataProject.Project.samples.length > 0 && !selectedSample.value) {
      setSelectedSample({
        label: getSampleName(dataProject.Project.samples[0]),
        value: dataProject.Project.samples[0].id,
      });
    }

    if (dataProject.Project.samples.length > 0 && selectedSample.value) {
      const foundSample = dataProject.Project.samples.find(
        (sample) => sample.id === selectedSample.value
      );

      if (!foundSample) return;

      setWorkflows([...foundSample.enabledWorkflows]);
    }
  }, [dataProject]);

  useEffect(() => {
    if (!dataUsers?.ProjectUsers) return;

    const usersToSet = dataUsers.ProjectUsers.map((user) => {
      return {
        id: user?.id || "",
        name: user?.name || user?.email || "",
        email: user?.email || "",
        role: user?.role || "",
        avatar: user?.avatar || "",
      };
    });

    setUsers(usersToSet);
  }, [dataUsers]);

  useEffect(() => {
    if (!dataGroups?.Groups) return;

    setGroups(dataGroups.Groups);
  }, [dataGroups]);

  useEffect(() => {
    const foundSample = samples.find(
      (sample) => sample.id === selectedSample.value
    );

    if (!foundSample) return;

    setWorkflows(foundSample.enabledWorkflows);
  }, [selectedSample]);

  return {
    selectedSample,
    setSelectedSample,
    samples,
    users,
    groups,
    workflows,
    error: errorProject || errorUsers || errorGroups,
    loading:
      loadingProject ||
      !dataProject ||
      loadingUsers ||
      !dataUsers ||
      loadingGroups ||
      !dataGroups,
  };
};
