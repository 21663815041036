import { useContext } from "react";
import { ActionContext, ActionContextType } from "../../contexts/ActionContext";

export const useActionContext = (): ActionContextType => {
  const context = useContext(ActionContext);

  if (context === undefined) {
    throw new Error("ActionContext not defined");
  }

  return context;
};
