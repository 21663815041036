import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import { useProjectUsersQuery } from "../../../../generated/graphql";

export type TeamUser = {
  id: string;
  name: string;
  email: string;
  role: string;
  avatar: string;
};

interface ReturnType {
  users: TeamUser[];
  error: ApolloError | undefined;
  loading: boolean;
}

interface Props {
  projectId: string;
}

export const useTeamBlock = (props: Props): ReturnType => {
  const { projectId } = props;

  const [users, setUsers] = useState<TeamUser[]>([]);

  const { error, loading, data } = useProjectUsersQuery({
    variables: { projectId },
  });

  useEffect(() => {
    if (!data?.ProjectUsers) return;

    const usersToSet = data.ProjectUsers.map((user) => {
      return {
        id: user?.id || "",
        name: user?.name || user?.email || "",
        email: user?.email || "",
        role: user?.role || "",
        avatar: user?.avatar || "",
      };
    });

    setUsers(usersToSet);
  }, [data]);

  return { users, error, loading: loading || !data };
};
