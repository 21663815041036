import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const MeetingIcon = (props: MeetingIconProps) => {
  const { width, height, x = 0, y = 0, color, secondColor } = props;

  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill={color} />
      <path fill={secondColor} d="M10.333 8.333h1.334V11h2v1.333h-3.334v-4z" />
      <path
        fill={secondColor}
        fillRule="evenodd"
        d="M5 11a6 6 0 1112 0 6 6 0 01-12 0zm1.333 0a4.668 4.668 0 119.335.001A4.668 4.668 0 016.333 11z"
        clipRule="evenodd"
      />
    </svg>
  );
};

MeetingIcon.defaultProps = {
  width: 24,
  height: 24,
  color: "#00715A",
  secondColor: styledTheme.newColors.grayscale.white,
};

type MeetingIconProps = BaseIconProps & {
  secondColor: string;
  x?: number;
  y?: number;
};
