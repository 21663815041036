import React from "react";
import { useDropzone } from "react-dropzone";
import { TextBox } from "../../../common/TextBox/TextBox";
import { TextArea } from "../../../common/TextArea/TextArea";
import { FileDropZone } from "../../../common/FileUploader/FileDropZone";
import { RadioGroup } from "../../../common/Radio/RadioGroup";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { Question } from "../../../../generated/graphql";

export type FormQuestionT = {
  id: string;
  label: string;
  editMode?: boolean;
  createComponent: (props?: Question) => JSX.Element;
};

export const FormQuestions = (): FormQuestionT[] => {
  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
  });

  return [
    {
      id: "TextField",
      label: "Text Field",
      createComponent: (): JSX.Element => (
        <TextBox placeholder="Text Field..." staticForm onChange={() => {}} />
      ),
    },
    {
      id: "TextArea",
      label: "Long Text Field",
      createComponent: (): JSX.Element => (
        <TextArea
          placeholder="Long Text Field..."
          value=""
          staticForm
          onChange={() => {}}
        />
      ),
    },
    {
      id: "File",
      label: "Drop Zone",
      createComponent: (): JSX.Element => (
        <FileDropZone
          rootProps={getRootProps({ isDragAccept })}
          inputProps={getInputProps()}
          open={open}
          horizontally
          staticForm
        />
      ),
    },
    {
      id: "Number",
      label: "Number Field",
      createComponent: (): JSX.Element => (
        <TextBox placeholder="0" type="Number" staticForm onChange={() => {}} />
      ),
    },
    {
      id: "OneOfMany",
      label: "One of many",
      editMode: true,
      createComponent: (question): JSX.Element => {
        if (question && question.choices) {
          if (question.choices.length === 0) {
            return (
              <i>Answers not specified! Go to the edit mode on the right</i>
            );
          }
          if (question.choices.length > 2) {
            const options = question.choices.map((choice) => ({
              value: choice,
              label: choice,
            }));
            return (
              <div style={{ flexGrow: 1 }}>
                <Dropdown options={options} />
              </div>
            );
          }
          const options = question.choices.map((choice) => ({
            value: choice,
            labelText: choice,
          }));
          return (
            <RadioGroup
              selectedValue=""
              onClickRadioButton={() => {}}
              options={options}
            />
          );
        }
        return (
          <RadioGroup
            selectedValue=""
            onClickRadioButton={() => {}}
            options={[{ value: "One of many", labelText: "One of many" }]}
          />
        );
      },
    },
    {
      id: "Links",
      label: "External links",
      createComponent: (): JSX.Element => (
        <TextBox placeholder="Links field..." staticForm onChange={() => {}} />
      ),
    },
  ];
};
