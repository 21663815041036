import { BaseService, Sample } from "../../generated/graphql";

export const getSampleProgress = (sample: Sample): number => {
  const services: BaseService[] = [];
  sample.enabledWorkflows.forEach((workflow) => {
    services.push(...workflow.services);
  });
  const numberOfServices = services.length || 1;
  const finishedServices = services.filter(
    (service) => service.status === "Finished"
  );
  const numberOfFinishedServices = finishedServices.length;

  return numberOfFinishedServices / numberOfServices;
};

export const convertFractionToPercent = (fraction: number): number =>
  Math.ceil(fraction * 100);
