import React, { useState } from "react";
import styled from "styled-components";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { ApolloError } from "apollo-client";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import {
  GroupDocument,
  useAddUsersToGroupMutation,
  useGroupQuery,
  User,
  useRemoveUserFromGroupMutation,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { BaseInput } from "../common/BaseInput";
import { Table } from "../Table/Table";
import { AddTeamMemberModal } from "../ManageTeam/AddTeamMemberModal/AddTeamMemberModal";
import { Button } from "../common/Button/Button";
import { Option } from "../../utils/types/QuestionMetadata";
import { RedTableInLineButton } from "../Table/StyledTableComponents";
import { ConfirmationModal } from "../common/Modal/ConfirmationModal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ManageGroupMembers = (_props: ManageTeamProps): JSX.Element => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [isInviteGroupMemberFormOpen, setIsInviteGroupMemberFormOpen] =
    useState(false);
  const urlParams = useParams();
  const { error, loading, data } = useGroupQuery({
    variables: { groupId: urlParams.groupId },
  });
  const [addUsersToGroup] = useAddUsersToGroupMutation();
  const [removeUserFromGroup] = useRemoveUserFromGroupMutation();
  const [selectedMember, setSelectedMember] =
    useState<Pick<User, "name" | "id" | "email">>();
  const [
    isConfirmationModalForRemoveMemberOpen,
    setIsConfirmationModalForRemoveMemberOpen,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const onAddUserToGroup = (selectedUsers: Option[]): void => {
    const usersIds = selectedUsers.map((usr) => usr.value);
    setIsInviteGroupMemberFormOpen(false);
    addUsersToGroup({
      variables: {
        groupId: urlParams.groupId,
        usersIds,
      },
      refetchQueries: [
        {
          query: GroupDocument,
          variables: { groupId: urlParams.groupId },
        },
        "Groups",
      ],
      awaitRefetchQueries: true,
    });
  };

  if (error) {
    return <p>Query Error: {error}</p>;
  }
  if (loading || !data) {
    return <Spinner />;
  }

  const searchMembers = () => {
    const lowerCasedSearchTerm = currentSearchTerm.toLowerCase();
    return data.Group.members.filter(
      (user) =>
        user?.name.toLowerCase().includes(lowerCasedSearchTerm) ||
        user?.email?.toLowerCase().includes(lowerCasedSearchTerm)
    );
  };

  const getMembersRow = (
    member: Pick<User, "name" | "id" | "email">,
    index: number
  ) => {
    return [
      index + 1,
      member.name,
      member.email,
      <RedTableInLineButton
        onClick={() => {
          setSelectedMember(member);
          setIsConfirmationModalForRemoveMemberOpen(true);
        }}
      >
        REMOVE
      </RedTableInLineButton>,
    ];
  };

  const removeMemberFromGroupCallback = (userId: string): void => {
    setIsLoading(true);

    removeUserFromGroup({
      variables: {
        groupId: urlParams.groupId,
        userId,
      },
      refetchQueries: [
        {
          query: GroupDocument,
          variables: { groupId: urlParams.groupId },
        },
        "Groups",
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setIsLoading(false);
        setIsConfirmationModalForRemoveMemberOpen(false);
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
        setIsConfirmationModalForRemoveMemberOpen(false);
      });
  };

  const groupName = data.Group.name;

  return (
    <LayoutDefault pageTitle="Manage members">
      <ManageTeamContainer>
        <ContentSection
          title={`Manage group members for group ${groupName}`}
          stepNumber={1}
          wrapCardsCallback={() => {}}
          buttons={[]}
          fullWidth
        >
          <StyledHeader>
            <div>Group members ({data.Group.members.length})</div>
            <StyledButton
              label="ADD USERS TO THIS GROUP"
              onClick={() => {
                setIsInviteGroupMemberFormOpen(true);
              }}
            />
            {isInviteGroupMemberFormOpen && (
              <AddTeamMemberModal
                isOpen={isInviteGroupMemberFormOpen}
                onAccept={onAddUserToGroup}
                onClose={() => setIsInviteGroupMemberFormOpen(false)}
              />
            )}
            {isConfirmationModalForRemoveMemberOpen && selectedMember && (
              <ConfirmationModal
                isOpen={isConfirmationModalForRemoveMemberOpen}
                message={`Are you sure you want to remove ${selectedMember.name} from this group?`}
                title="Confirmation"
                yesButtonText="Remove this member"
                closeModal={() =>
                  setIsConfirmationModalForRemoveMemberOpen(false)
                }
                acceptModal={() => {
                  removeMemberFromGroupCallback(selectedMember.id);
                }}
                isLoading={isLoading}
              />
            )}
          </StyledHeader>
          <StyledDiv>
            <StyledSearchBar
              key="user-searchbar"
              value={currentSearchTerm}
              placeholder="Search by name or email"
              onChange={(e) => setCurrentSearchTerm(e.target.value)}
            />
          </StyledDiv>
          <Table columns={["No.", "Name", "E-mail", "Remove user"]}>
            {searchMembers().map((m, idx) => getMembersRow(m, idx))}
          </Table>

          <MarginTop>
            <StyledButton
              label="Go back to groups list"
              onClick={() => {
                navigate(`/manage-groups`);
              }}
            />
          </MarginTop>
        </ContentSection>
      </ManageTeamContainer>
    </LayoutDefault>
  );
};

type ManageTeamProps = RouteComponentProps;

const StyledSearchBar = styled(BaseInput)`
  width: 205px;
  height: 48px;
  box-shadow: 0 1px 2px 0 rgba(220, 222, 229, 0.3);
  background-color: #ffffff;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  flex-grow: 0;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
  text-align: left;
  color: #232f3c;
`;

const StyledDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const MarginTop = styled.div`
  margin-top: 20px;
`;

const ManageTeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;

const StyledButton = styled(Button)`
  width: 250px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #3b79d1;
`;
