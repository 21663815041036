import * as React from "react";
import { useEffect, useRef } from "react";
import * as d3 from "d3";
import styled from "styled-components";

type TextWrappedProps = {
  text: string;
  maxWidth: number;
  dependenciesArray?: any[];
  scale?: number;

  children?: React.ReactNode;
  x?: number;
  y?: number;
  fontSize?: number;
  fill?: string;
  className?: string;
  id?: string;
};

const reformatText = (element: HTMLElement, string: string, width: number) => {
  if (!element) {
    return;
  }
  const self = d3.select(element);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  let textLength = self.text(string).node().getComputedTextLength();

  if (textLength > width && textLength > 0) {
    let text = string;
    for (
      let i = 0;
      text.length > 0 && textLength > width && textLength > 0 && i < 100;
      i += 1
    ) {
      text = text.slice(0, -1);

      self.text(`${text}...`);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      textLength = self.node().getComputedTextLength();
    }
  }
};

export const TextWrapped = ({
  text = "",
  maxWidth = 100,
  dependenciesArray = [],
  scale = 1,

  x = 0,
  y = 0,
  fontSize = 16,
  fill = "black",
  className = undefined,
  id = undefined,
}: TextWrappedProps) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && ref.current) {
      reformatText(ref!.current!, text, maxWidth);
    }
  }, [maxWidth, scale]);

  return (
    <Text
      ref={ref}
      x={x}
      y={y}
      fontSize={fontSize}
      fill={fill}
      className={className}
      id={id}
    >
      {text}
    </Text>
  );
};

const Text = styled.text`
  opacity: 0;
  animation: 1s appear forwards;
  animation-delay: 0.4s;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
