import * as React from "react";
import { RouteComponentProps } from "@reach/router";

export const ProjectTimeline = (props: ProjectTimelineProps) => {
  return <div>Project timeline -&nbsp;{props.projectId}</div>;
};

interface ProjectTimelineProps extends RouteComponentProps {
  projectId?: string;
}
