import { Question, QuestionGroup } from "../../../generated/graphql";
import {
  FormSchema,
  FormSchemaField,
  QuestionLevelInput,
  QuestionMetadata,
} from "../../types/QuestionMetadata";

export const getFormSchemaFromQuestionGroup = (
  questionGroup: QuestionGroup,
  questionLevelInput: QuestionLevelInput,
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void,
  readonly?: boolean
) => {
  const formSchema: FormSchema = {};

  const getFormSchemaForQuestion = (
    question: Question,
    questionSetId: string,
    disabled?: boolean
  ): FormSchemaField => {
    const formSchemaElement: FormSchemaField = {
      type: question.answer.answerType,
      label: question.question,
      description: question.description,
      placeholder: question.placeholder,
      required: question.isObligatory,
      visibility: question.visibility,
      disabled,
      metadata: {
        questionLevelInput,
        answerType: question.answer.answerType,
        questionInput: {
          questionGroupId: questionGroup.id,
          questionSetId,
          questionId: question.id,
        },
      },
      answerQuestionCallback,
    };
    if (question.choices) {
      formSchemaElement.options = question.choices.map((choice) => ({
        label: choice,
        value: choice,
      }));
    }
    return formSchemaElement;
  };

  questionGroup.questionSets.forEach((questionSet) => {
    if (questionSet.renderer) {
      const formSchemaItems: FormSchemaField[] = [];
      questionSet.questions.forEach((question) => {
        formSchemaItems[question.id] = getFormSchemaForQuestion(
          question,
          questionSet.id,
          readonly
        );
      });
      formSchema[questionSet.id] = {
        renderer: questionSet.renderer,
        formSchemaItems,
      };
    } else {
      questionSet.questions.forEach((question) => {
        formSchema[question.id] = getFormSchemaForQuestion(
          question,
          questionSet.id,
          readonly
        );
      });
    }
  });
  return formSchema;
};
