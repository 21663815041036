import {
  SelectedEventPath,
  SelectedServicePath,
  SelectedWorkflowPath,
} from "./ActionContextTypes";

const isUnknownObject = (
  x: unknown
): x is { [key in PropertyKey]: unknown } => {
  return x != null && typeof x === "object";
};

export const isSelectedServicePath = (
  object: unknown
): object is SelectedServicePath => {
  if (isUnknownObject(object)) {
    return "service" in object;
  }

  return false;
};

export const isSelectedEventPath = (
  object: unknown
): object is SelectedEventPath => {
  if (isUnknownObject(object)) {
    return "event" in object;
  }

  return false;
};

export const isSelectedWorkflowPath = (
  object: unknown
): object is SelectedWorkflowPath => {
  if (isUnknownObject(object)) {
    return "workflow" in object;
  }

  return false;
};
