import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const GridIcon = ({ width, height, color, ...props }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H2.5V2.5H0V0ZM0 6.25H2.5V8.75H0V6.25ZM2.5 12.5H0V15H2.5V12.5ZM6.25 12.5H8.75V15H6.25V12.5ZM15 12.5H12.5V15H15V12.5ZM6.25 6.25H8.75V8.75H6.25V6.25ZM15 6.25H12.5V8.75H15V6.25ZM6.25 0H8.75V2.5H6.25V0ZM15 0H12.5V2.5H15V0Z"
        fill="#3B79D1"
      />
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    </svg>
  );
};
GridIcon.defaultProps = {
  width: "20",
  height: "20",
  color: styledTheme.newColors.grayscale.white,
};
