import * as React from "react";
import styled from "styled-components";
import { BaseIconProps } from "../../../images/icons/BaseIconProps";
import {
  getCurrentSize,
  getCurrentEventScale,
  getInsideIconPosition,
  getRXSize,
} from "../axisHelpers";

export const EventIcon = (props: EventIconProps): JSX.Element => {
  const { x = 0, y = 0, color, onClick, className, id, zoom } = props;

  return (
    <SvgWrapper
      id={id}
      x={x}
      y={y}
      className={className}
      width={getCurrentSize(20, zoom)}
      height={getCurrentSize(20, zoom)}
      viewBox={`0 0 ${getCurrentSize(20, zoom)} ${getCurrentSize(20, zoom)}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        width={getCurrentSize(20, zoom)}
        height={getCurrentSize(20, zoom)}
        rx={getRXSize(zoom)}
        fill="transparent"
      />
      <rect
        x={getInsideIconPosition(zoom)}
        y={getInsideIconPosition(zoom)}
        width={getCurrentSize(16, zoom)}
        height={getCurrentSize(16, zoom)}
        rx={getRXSize(zoom)}
        fill={color}
      />
      <g
        clipPath="url(#clip0_727_2)"
        style={{
          transform: `scale(${getCurrentEventScale(zoom)})`,
        }}
      >
        <path
          d="M5 10C5 7.23857 7.23857 5 10 5C12.7614 5 15 7.23857 15 10C15 12.7614 12.7614 15 10 15C7.23857 15 5 12.7614 5 10Z"
          fill="#fff"
        />
        <path
          d="M11.3464 10.0055C11.3484 9.32363 10.9792 8.6905 10.3284 8.2503C10.5269 8.08419 10.6947 7.8951 10.8227 7.69117C11.0214 7.37466 11.1263 7.02042 11.1263 6.66675H10.7362C10.7362 6.82613 10.7099 6.9827 10.6597 7.13368H9.31372C9.26346 6.98271 9.23726 6.82613 9.23726 6.66675H8.84706C8.84706 7.02042 8.95205 7.37466 9.15069 7.69117C9.27867 7.89509 9.44649 8.08418 9.64498 8.2503C8.99423 8.6905 8.62495 9.32362 8.62696 10.0055C8.62897 10.684 8.99781 11.3125 9.64473 11.75C9.44853 11.9143 9.28236 12.1009 9.15497 12.3022C8.95354 12.6205 8.84707 12.9772 8.84707 13.3334H9.23727C9.23727 13.1789 9.26191 13.0271 9.30909 12.8805H10.6644C10.7115 13.0271 10.7362 13.1789 10.7362 13.3334H11.1264C11.1264 12.9772 11.0199 12.6205 10.8185 12.3022C10.6911 12.1009 10.5249 11.9143 10.3287 11.75C10.9756 11.3125 11.3444 10.684 11.3464 10.0055ZM9.03046 10.1952H10.9429C10.9229 10.3439 10.8802 10.489 10.8162 10.6281H9.1572C9.09319 10.489 9.05049 10.3439 9.03046 10.1952ZM10.943 9.80499H9.03044C9.05048 9.6563 9.09309 9.51117 9.15715 9.37209H10.8163C10.8803 9.51117 10.9229 9.6563 10.943 9.80499ZM10.4675 7.52387C10.3454 7.70735 10.1837 7.87637 9.98671 8.02424C9.78969 7.87637 9.62802 7.70737 9.50591 7.52387H10.4675ZM9.96439 8.50846L9.98671 8.49483L10.009 8.50847C10.2318 8.64457 10.4193 8.80476 10.5673 8.9819H9.40615C9.5541 8.80475 9.74158 8.64456 9.96439 8.50846ZM9.49638 12.4903C9.61955 12.3013 9.78446 12.1274 9.98671 11.9757C10.189 12.1274 10.3539 12.3013 10.477 12.4903H9.49638ZM9.99605 11.4997C9.9929 11.5016 9.98984 11.5036 9.98671 11.5055C9.98357 11.5036 9.98052 11.5016 9.97736 11.4997C9.74877 11.3619 9.55668 11.199 9.40582 11.0183H10.5676C10.4167 11.1989 10.2246 11.3619 9.99605 11.4997Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_727_2">
          <rect
            width={10}
            height={10}
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

const SvgWrapper = styled.svg`
  cursor: pointer;
`;

type EventIconProps = BaseIconProps & {
  className?: string;
  x: number;
  y: number;
  id: string;
  zoom: number;
};
