import React from "react";
import styled from "styled-components";
import { Modal } from "./Modal";
import { Button } from "../Button/Button";
import { ButtonType } from "../Button/ButtonType";

type ConfirmationModalProps = {
  isOpen: boolean;
  message: string;
  title: string;
  closeModal: () => void;
  acceptModal: () => void;
  yesButtonText?: string;
  isLoading?: boolean;
};

export const ConfirmationModal = (
  props: ConfirmationModalProps
): JSX.Element => {
  const {
    isOpen,
    closeModal,
    acceptModal,
    message,
    title,
    yesButtonText = "ACCEPT",
    isLoading,
  } = props;

  const ModalButtons = (): JSX.Element => (
    <>
      <AcceptButton
        label={yesButtonText}
        type={ButtonType.Primary}
        onClick={acceptModal}
      />
      <Button label="Cancel" type={ButtonType.Secondary} onClick={closeModal} />
    </>
  );

  if (!isOpen) return <></>;

  return (
    <StyledModal
      footerContent={<ModalButtons />}
      id="ConfirmationModal"
      title={title}
      onClose={closeModal}
      isLoading={isLoading}
    >
      <div>{message}</div>
    </StyledModal>
  );
};

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledModal = styled(Modal)`
  &
    > .modal-dialog
    > .modal-content-footer
    > .modal-content-wrapper
    > .modal-content {
    min-width: 0;
    padding: 0;
  }
`;
