import * as React from "react";
import { FunctionComponent } from "react";
import { Label } from "../../common/Label/Label";
import { Separator } from "./Separator";
import { GetFieldStatusResultT } from "../../common/LabelTag/LabelTag";

export const FormGroup: FunctionComponent<FormGroupProps> = (props) => {
  const {
    label,
    description,
    children,
    status,
    onFormEdit,
    editVisible = false,
    disabled,
  } = props;
  return (
    <div>
      <Label
        htmlFor={label}
        label={label}
        status={status}
        edit={editVisible}
        onEditClick={onFormEdit}
        disabled={disabled}
      >
        {description}
      </Label>
      {children}
      <Separator />
    </div>
  );
};

type FormGroupProps = {
  label: string;
  description?: string | null;
  status?: GetFieldStatusResultT;
  onFormEdit?: () => void;
  editVisible?: boolean;
  disabled?: boolean;
};
