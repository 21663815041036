import React from "react";
import { ValueType } from "react-select";
import styled from "styled-components";
import { useSamplesBlock } from "./useSamplesBlock";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { getSampleName } from "../../../../utils/helpers/getSampleName";
import { BlockHeader } from "../BlockHeader";
import { Tabs } from "./Tabs";
import { Spinner } from "../../../common/Spinner/Spinner";

interface SamplesBlockProps {
  projectId: string;
}

export const SamplesBlock = (props: SamplesBlockProps): JSX.Element => {
  const { projectId } = props;

  const {
    error,
    loading,
    samples,
    setSelectedSample,
    users,
    groups,
    selectedSample,
    workflows,
  } = useSamplesBlock({ projectId });

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  const handleDropdownChange = async (
    selectedValue: ValueType<{ label: string; value: string }, boolean>
  ): Promise<void> => {
    setSelectedSample(selectedValue as { label: string; value: string });
  };

  const samplesDropdown = samples.map((sample) => ({
    value: sample.id,
    label: getSampleName(sample),
  }));

  const getDropdown = (): JSX.Element => (
    <StyledDropdown
      options={samplesDropdown}
      onChange={handleDropdownChange}
      value={selectedSample}
    />
  );

  return (
    <Container>
      <StyledBlockHeader headerComponent={getDropdown()} />
      {samples.length > 0 && (
        <Tabs workflows={workflows} users={users} groups={groups} />
      )}
      {loading && (
        <SpinnerContainer>
          <Spinner containerSize opaque />
        </SpinnerContainer>
      )}
    </Container>
  );
};

const StyledBlockHeader = styled(BlockHeader)`
  padding: 16px 24px 12px 6px;
  box-shadow: 0px 1px 0px #d1d5d8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 260px;
  margin-bottom: 4px;
`;

const Container = styled.div`
  height: 338px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
