import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import moment from "moment";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import ServerError from "../ServerError";
import {
  Project,
  useUserPlainProjectsWithSamplesQuery,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { ProjectElement } from "../Projects/ProjectElement";
import { getProjectName } from "../../utils/helpers/getProjectName";
import { ReportsHeader } from "../Reports/ReportsHeader";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ManageTeams = (_props: ManageTeamsProps): JSX.Element => {
  const queryResult = useUserPlainProjectsWithSamplesQuery();

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  if (queryResult.error) {
    return <ServerError />;
  }

  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const projects = queryResult.data.Projects || [];
  const projectsLength = projects.length;

  return (
    <LayoutDefault pageTitle="Project Users">
      <ReportsHeader projectsNumber={projectsLength} />
      <ManageTeamsContainer>
        {projects.map((project) => {
          const projectLastActivityDateString = moment(
            project.lastActivity
              ? parseInt(project.lastActivity, 10)
              : undefined
          ).format("Do MMMM YYYY");
          return (
            <ProjectElement
              key={project.id}
              projectLastActivity={projectLastActivityDateString}
              projectName={getProjectName(project as unknown as Project)}
              currentService={project.currentService}
              onClick={() => {
                navigate(`/manage-team/${project.id}/`);
              }}
            />
          );
        })}
      </ManageTeamsContainer>
    </LayoutDefault>
  );
};

type ManageTeamsProps = RouteComponentProps;

const ManageTeamsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;
