import React from "react";
import styled from "styled-components";
import { RouteComponentProps, useParams } from "@reach/router";
import { cloneDeep } from "lodash";
import {
  ProjectUser,
  useListUsersQuery,
  useServicesAssignedToUserForTimelineQuery,
  WorkflowForCustomer,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { Timeline } from "../Timeline/Timeline";
import { LayoutWithFullWidth } from "../Layouts/LayoutWithFullWidth";
import { Label } from "../common/Label/Label";
import { SideDrawboard } from "../SideDrawboard/SideDrawboard";
import { useActionContext } from "../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedServicePath,
} from "../../utils/types/ActionContextTypes";
import { EditTask } from "../Projects/SingleProject/EditTask/EditTask";
import { EditSubtask } from "../Projects/SingleProject/EditSubtask/EditSubtask";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";

export const TasksAssignedToUser = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: ManageTeamUserProps
): JSX.Element => {
  const urlParams = useParams();
  const { selectedEntity } = useActionContext();
  const { sideDrawboardOpen } = useSideBarsContext();

  const { error, loading, data } = useServicesAssignedToUserForTimelineQuery({
    variables: { userId: urlParams.userId },
  });
  const {
    error: allUsersError,
    loading: allUsersLoading,
    data: allUsersData,
  } = useListUsersQuery();

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  if (allUsersError) {
    return <p>Query Error: {allUsersError}</p>;
  }

  if (loading || !data || allUsersLoading || !allUsersData) {
    return <Spinner />;
  }

  const allUsers: ProjectUser[] =
    (allUsersData.ListUsers as unknown as ProjectUser[]) || [];
  const userAssigned = cloneDeep(
    allUsers.find((user) => user?.id === urlParams.userId)
  );

  const sample = data.ServicesAssignedToUserForTimeline;
  if (!sample) return <></>;

  let sideDrawboardContent = <></>;

  if (
    selectedEntity &&
    selectedEntity.selectedElementType === SelectedElementType.SERVICE
  ) {
    const parentEnabledWorkflow = sample.enabledWorkflows.find(
      (w: WorkflowForCustomer) =>
        w.id ===
        (selectedEntity.selectedElementPath as SelectedServicePath).workflowId
    );
    sideDrawboardContent = (
      <EditSubtask
        parentEnabledWorkflow={parentEnabledWorkflow}
        editMode={false}
        hardcodedUser={userAssigned}
      />
    );
  } else if (
    selectedEntity &&
    selectedEntity.selectedElementType === SelectedElementType.WORKFLOW
  ) {
    sideDrawboardContent = (
      <EditTask editMode={false} hardcodedUser={userAssigned} />
    );
  }

  return (
    <LayoutWithFullWidth pageTitle={`Tasks assigned to ${userAssigned?.name}`}>
      <ManageTeamUserContainer>
        {sample.enabledWorkflows.length > 0 ? (
          <StyledTimeline
            sample={sample}
            projectId="tasksAssignedView"
            hideAddIcon
            height="calc(100vh - 96px - 36px - 9px)"
            width={`calc(100% - ${sideDrawboardOpen ? "337px" : "72px"})`}
            disableDnD
          />
        ) : (
          <UserIsNotAssigned>
            <Label label="Selected user is currently not assigned to any task" />
          </UserIsNotAssigned>
        )}
        <StyledSideDrawboard>{sideDrawboardContent}</StyledSideDrawboard>
      </ManageTeamUserContainer>
    </LayoutWithFullWidth>
  );
};

type ManageTeamUserProps = RouteComponentProps;

const ManageTeamUserContainer = styled.div`
  display: flex;
`;

const StyledTimeline = styled(Timeline)`
  border-top: 2px solid #dedede;
`;

const UserIsNotAssigned = styled.div`
  padding: 2rem;
`;

const StyledSideDrawboard = styled(SideDrawboard)`
  border-top: 2px solid #dedede;
`;
