import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

interface BaseTextAreaProps {
  error?: boolean;
}

export const BaseTextArea = styled(TextareaAutosize)<BaseTextAreaProps>`
  width: 100%;
  min-height: 95px;
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: ${(props) => props.theme.lineHeights[4]};
  resize: none;
  padding: 12px 16px;
  border: 1px solid ${(props) => props.theme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 4px;

  :hover {
    border: 1px solid ${(props) => props.theme.newColors.grayscale.placeholder};
    box-shadow: ${({ theme }) => theme.shadows.userInputs.hoveredState};
    outline: none;
  }

  :focus {
    border: 1px solid ${(props) => props.theme.newColors.primary.basic};
    box-shadow: ${({ theme }) => theme.shadows.userInputs.activeState};
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.newColors.grayscale.placeholder};
  }
  :-ms-input-placeholder {
    color: ${(props) => props.theme.newColors.grayscale.placeholder};
  }

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.newColors.grayscale.placeholder};
      background: ${({ theme }) => theme.newColors.grayscale.disabled};
      box-shadow: none;
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${({ theme }) => theme.newColors.extra.errorState};
      box-shadow: ${({ theme }) => theme.shadows.userInputs.errorState};
    `}
`;
