import React from "react";
import styled from "styled-components";
import { ProjectHeaderSummary } from "./common/ProjectHeaderSummary";
import { ServiceStatus } from "./common/ServiceStatus";
import { ImageMountainIcon } from "../../images/icons/ImageMountainIcon";
import { styledTheme } from "../../theme/theme";
import { CurrentService } from "../../generated/graphql";

export const ProjectElement = (props: ProjectElementPropsT) => {
  const {
    projectName,
    projectLastActivity,
    currentService,
    onClick,
    projectId,
  } = props;
  return (
    <Container onClick={onClick}>
      <ImageMountainIcon />
      <ProjectHeaderSummary
        name={projectName}
        lastActivity={projectLastActivity}
        projectId={projectId}
      />
      {currentService && (
        <ServiceStatusContainer>
          <ServiceStatus
            name={currentService.serviceName}
            sampleName={currentService.sampleName}
            remaining={`${currentService.weekRemaining}w remaining`}
          />
        </ServiceStatusContainer>
      )}
    </Container>
  );
};

type ProjectElementPropsT = {
  projectId?: string;
  projectName: string;
  projectLastActivity: string;
  currentService?: CurrentService | null;
  onClick?: () => void;
};

ProjectElement.defaultProps = {
  onClick: () => {},
};

const Container = styled.div`
  width: 276px;
  padding: 24px;
  cursor: pointer;

  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.dropShadows.formCard};
  border-radius: 8px;

  > svg {
    margin-bottom: 16px;
  }
`;

const ServiceStatusContainer = styled.div`
  margin-top: 20px;
  padding: 12px;
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 4px;
`;
