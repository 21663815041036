// @ts-nocheck
import * as React from "react";
import { useState } from "react";
import * as d3 from "d3";
import get from "lodash/get";
import flatMap from "lodash/flatMap";
import useDimensions from "react-use-dimensions";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import "./timeline.css";
import moment from "moment/moment";
import {
  formatMonthsAxis,
  formatQuarterAxis,
  getMonthsAxis,
  getQuarterAxis,
} from "./axisHelpers";
import { Service, Workflow } from "../../generated/graphql";
import { TodayLine } from "./TodayLine";
import { ServiceBlockMini } from "./ServiceBlockMini";

const svgId = "timeline-svg";

const timelinePadding = 30 * 24 * 60 * 60 * 1000; // 30days
const rowHeight = 75;

let dates = null;
let canvasStartDate = 0;
let canvasEndDate = 0;
let worldEndDate = 20000101;

export const MiniTimeline = (props) => {
  const { samples, axisHeight } = props;
  const worldHeight = parseInt(props.height || window.innerHeight, 10);

  const workflows: Workflow = flatMap(samples, (sample) =>
    get(sample, "enabledWorkflows", [])
  );

  let services: Service[] = [];
  workflows.forEach((workflow) => {
    services = services.concat(workflow.services);
  });

  services.sort((serviceA, serviceB) => {
    const serviceALength = serviceA.endDate - serviceA.startDate;
    const serviceBLength = serviceB.endDate - serviceB.startDate;
    return serviceALength - serviceBLength;
  });

  if (!dates) {
    // run this calculation only once
    dates = flatMap(services, (s) => [
      parseFloat(s.startDate),
      parseFloat(s.endDate),
    ]);
    canvasStartDate = d3.min(dates) - timelinePadding;
    canvasEndDate = canvasStartDate + 7 * timelinePadding;
    worldEndDate = d3.max(dates) + timelinePadding;
  }
  const [ref, { width, height }] = useDimensions();
  const wrapperSVGRef = React.useRef(null);
  const axisWrapperGroupRef = React.useRef(null);
  const timelineElementsGroupRef = React.useRef(null);
  const todayGroupRef = React.useRef(null);

  const axisDomain = [new Date(canvasStartDate), new Date(canvasEndDate)];
  const axisWidth = width;
  const x = d3.scaleTime().domain(axisDomain).range([0, axisWidth]);
  const [currentTransform, setCurrentTransform] = useState(
    d3.zoomIdentity.translate(0, 0).scale(1)
  );

  const baselineHeight = worldHeight / 2 / 4;

  const draw = () => {
    const axisWrapperElement = d3.select(axisWrapperGroupRef.current);
    const todayLine = d3.select(todayGroupRef.current);

    axisWrapperElement.selectAll("*").remove();

    const timelineElementsGroup = d3
      .select(timelineElementsGroupRef.current)
      .attr("cursor", "grab");

    // axis background
    axisWrapperElement
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", axisWidth)
      .attr("height", axisHeight)
      .style("fill", "#ffffff");

    const mAxis = getMonthsAxis(x);
    const monthsAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-month-axis")
      .attr("transform", "translate(0,45)")
      .call(mAxis);
    formatMonthsAxis(monthsAxisHandler);

    const qAxis = getQuarterAxis(x);
    const quarterAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-quarter-axis")
      .attr("transform", `translate(0,${axisHeight})`)
      .call(qAxis);
    formatQuarterAxis(quarterAxisHandler);

    const zoomed = (transformToSet): void => {
      let transform = transformToSet;
      if (d3.event && d3.event.transform) {
        transform = d3.event.transform;
      }
      transform.k = 1;
      transform.y = 0;
      timelineElementsGroup.attr("transform", transform);

      const updatedX = transform.rescaleX(x);
      const updatedMAxis = getMonthsAxis(updatedX);
      const updatedMonthsAxisHandler = monthsAxisHandler.call(updatedMAxis);
      formatMonthsAxis(updatedMonthsAxisHandler);

      const updatedQAxis = getQuarterAxis(updatedX);
      const updatedQuarterAxisHandler = quarterAxisHandler.call(updatedQAxis);
      formatQuarterAxis(updatedQuarterAxisHandler);

      todayLine.attr("transform", transform);
      setCurrentTransform(transform);
    };

    // eslint-disable-next-line consistent-return

    const zoom = d3.zoom().on("zoom", zoomed);

    const todayLineXPosition =
      x(new Date().valueOf()) * currentTransform.k + currentTransform.x;
    d3.select(wrapperSVGRef.current).call(
      zoom.translateTo,
      todayLineXPosition,
      0
    );

    d3.select(wrapperSVGRef.current).call(zoom);
  };

  const drawLines = () => {
    const lines = [];
    const monthStart = moment(canvasStartDate).startOf("month");
    const monthStartTimestamp = monthStart.valueOf();
    const lastWeek = moment(worldEndDate).endOf("month");
    const numberOfMonths = lastWeek.diff(monthStart, "month");
    const numberOfMonthsToDraw = numberOfMonths + 1;

    for (let week = 0; week < numberOfMonthsToDraw; week += 1) {
      const xPos =
        x(moment(monthStartTimestamp).add(week, "month").valueOf()) + 0.5;
      lines.push(
        <line
          x1={xPos}
          x2={xPos}
          y1={axisHeight}
          y2={2000}
          stroke="#c0c0c0"
          strokeWidth="0.5"
        />
      );
    }
    return lines;
  };

  React.useEffect(() => {
    draw();
  }, [axisWidth, height, width]);

  return (
    <TimelineContainer ref={ref} width={props.width} height={props.height}>
      {axisWidth && (
        <StyledSVG id={svgId} ref={wrapperSVGRef}>
          <line
            x1={0}
            x2={x(canvasEndDate)}
            y1={axisHeight}
            y2={axisHeight}
            stroke="#c0c0c0"
            strokeWidth="1"
            // strokeDasharray="1, 12"
          />
          <TodayLine
            x={
              x(new Date().valueOf()) * currentTransform.k + currentTransform.x
            }
            y={axisHeight}
            height={height}
          />
          <g
            id="timeline"
            width={width}
            height={height}
            ref={timelineElementsGroupRef}
          >
            {drawLines()}
            {samples.map((sample, sampleIndex) => {
              const sampleWorkflows: Workflow = get(
                sample,
                "enabledWorkflows",
                []
              );
              let sampleServices: Service[] = [];
              sampleWorkflows.forEach((workflow) => {
                sampleServices = sampleServices.concat(workflow.services);
              });
              return sampleServices.map((service) => {
                return (
                  <ServiceBlockMini
                    baselineHeight={baselineHeight}
                    {...service}
                    y={axisHeight + sampleIndex * rowHeight + 40}
                    x={x(service.startDate)}
                    width={x(service.endDate) - x(service.startDate)}
                    currentTransform={currentTransform}
                    key={service.id}
                  />
                );
              });
            })}
          </g>

          <g
            id="axis"
            width={width}
            height={height}
            ref={axisWrapperGroupRef}
          />
        </StyledSVG>
      )}
    </TimelineContainer>
  );
};

MiniTimeline.defaultProps = {
  axisHeight: 68,
};

const TimelineContainer = styled(Box)`
  width: ${({ width }) => width || "100vw"};
  height: ${({ height }) => height || "100vh"};
`;

const StyledSVG = styled.svg`
  width: calc(100%);
  height: calc(100%);
  max-width: 100%;
  max-height: 100%;
  background-color: white;
`;
