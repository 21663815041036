import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};













export enum AnswerType {
  TextField = 'TextField',
  Number = 'Number',
  OneOfMany = 'OneOfMany',
  TextArea = 'TextArea',
  ManyOfMany = 'ManyOfMany',
  File = 'File',
  Date = 'Date',
  DateTime = 'DateTime',
  Currency = 'Currency',
  Links = 'Links'
}

export enum Currency {
  Pln = 'PLN',
  Eur = 'EUR',
  Usd = 'USD',
  Cny = 'CNY'
}

export enum Unit {
  Mg = 'mg',
  G = 'g',
  Kg = 'kg'
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
};

export type CurrencyValue = {
  __typename?: 'CurrencyValue';
  value: Scalars['String'];
};

export type CurrencyValueInput = {
  value: Scalars['String'];
};

export type OfferedEntityPrice = {
  __typename?: 'OfferedEntityPrice';
  minPrice: CurrencyValue;
  maxPrice: CurrencyValue;
  currency: Currency;
};

export type ActualEntityPrice = {
  __typename?: 'ActualEntityPrice';
  price: CurrencyValue;
  currency: Currency;
};

export type ActualEntityPriceInput = {
  price: CurrencyValueInput;
  currency: Currency;
};

export enum EntityStatus {
  Finished = 'Finished',
  Ongoing = 'Ongoing',
  Approved = 'Approved',
  Pending = 'Pending',
  Cancelled = 'Cancelled'
}

export enum Priority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export enum EventPriority {
  Critical = 'Critical',
  Urgent = 'Urgent',
  Informational = 'Informational',
  Warning = 'Warning'
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  priority: EventPriority;
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  baseLineSize: Scalars['Int'];
  baseLine: Scalars['Int'];
  assignee?: Maybe<Scalars['ID']>;
  collaborators?: Maybe<Collaborators>;
  milestone?: Maybe<Scalars['Boolean']>;
};

export type Collaborators = {
  __typename?: 'Collaborators';
  userIds: Array<Scalars['ID']>;
  groupIds: Array<Scalars['ID']>;
};

export type UploadLinksInput = {
  fileId: Scalars['String'];
  fileName: Scalars['String'];
  fileType: Scalars['String'];
};

export type UploadLinksResponse = {
  __typename?: 'UploadLinksResponse';
  fileId: Scalars['String'];
  link: Scalars['String'];
};

export enum DeleteStatus {
  Ok = 'OK',
  Failed = 'FAILED'
}

export type DeleteStatusResponse = {
  __typename?: 'DeleteStatusResponse';
  deleteStatus: DeleteStatus;
  fileName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  UploadLinks: Array<UploadLinksResponse>;
  DownloadLink: Scalars['String'];
  Groups: Array<Group>;
  Group: GroupMembers;
  ServicesAssignedToGroupForTimeline: Sample;
  healthcheck: Scalars['String'];
  Projects: Array<Project>;
  Project: Project;
  ProjectUsers: Array<Maybe<ProjectUser>>;
  ProjectWithAllSamples: Project;
  QuestionGroups: Array<QuestionGroup>;
  Questions: Array<Question>;
  Services: Array<Service>;
  ListUsers?: Maybe<Array<Auth0User>>;
  SearchUsers?: Maybe<Array<Auth0User>>;
  ServicesAssignedToUserForTimeline: Sample;
  GetAuth0Roles: Array<Auth0Role>;
  Workflows: Array<Workflow>;
  WorkflowsByOrganization: Array<Maybe<Workflow>>;
};


export type QueryUploadLinksArgs = {
  fileInput: Array<UploadLinksInput>;
};


export type QueryDownloadLinkArgs = {
  fileName: Scalars['String'];
  originFileName: Scalars['String'];
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryServicesAssignedToGroupForTimelineArgs = {
  groupId: Scalars['ID'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProjectUsersArgs = {
  id: Scalars['ID'];
};


export type QueryProjectWithAllSamplesArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  filter?: Maybe<QuestionFilterQueryInput>;
};


export type QuerySearchUsersArgs = {
  searchText: Scalars['String'];
};


export type QueryServicesAssignedToUserForTimelineArgs = {
  userId: Scalars['ID'];
};


export type QueryWorkflowsByOrganizationArgs = {
  organization: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  DeleteUploadedFile: DeleteStatusResponse;
  AddGroup?: Maybe<Group>;
  RemoveGroup?: Maybe<Array<Maybe<Group>>>;
  AddUsersToGroup?: Maybe<Group>;
  RemoveUserFromGroup?: Maybe<Group>;
  EditGroupName?: Maybe<Group>;
  AddNotification: Scalars['Boolean'];
  AddProject: Project;
  AnswerProjectQuestion: Project;
  AnswerProjectNumericQuestion: Project;
  AnswerProjectMultiQuestion: Project;
  AnswerProjectSampleQuestion: Project;
  AnswerProjectSampleNumericQuestion: Project;
  AnswerProjectSampleMultiQuestion: Project;
  AddSampleToProject: Sample;
  SetProjectName: Project;
  SetProjectSampleName: Project;
  SetProjectSampleStatus: Project;
  EnableWorkflowInProjectSample: Project;
  SelectWorkflowInProjectSample: Project;
  UnselectWorkflowInProjectSample: Project;
  SetPriceOfProjectSampleEnabledWorkflow: Project;
  SetProjectSampleWorkflowServiceBaseline: Project;
  SetProjectSampleWorkflowServiceBaselineSize: Project;
  SetProjectSampleWorkflowEntityStatus: Project;
  SetProjectSampleWorkflowServicePrice: Project;
  SetProjectSampleWorkflowServiceVendor: Project;
  SetProjectSampleWorkflowServiceTimeline: Project;
  AnswerProjectSampleWorkflowServiceQuestion: Project;
  AnswerProjectSampleWorkflowServiceNumericQuestion: Project;
  AnswerProjectSampleWorkflowServiceMultiQuestion: Project;
  AddProjectSampleEvent: Project;
  SetProjectSampleEventTimeline: Project;
  SetProjectSampleEventBaseline: Project;
  SetProjectSampleEventBaselineSize: Project;
  AddQuestionGroupToSample: QuestionGroup;
  AddQuestionGroupToProject: Array<QuestionGroup>;
  AddQuestionGroupToWorkflow: QuestionGroup;
  RemoveQuestionGroupFromSample: Sample;
  RemoveQuestionGroupFromProject: Array<QuestionGroup>;
  RemoveQuestionGroupFromWorkflow: Workflow;
  AddQuestionsToSample: Sample;
  AddQuestionToSample: Sample;
  RemoveQuestionsFromSample: Sample;
  InsertQuestionsIntoProject: QuestionSet;
  RemoveQuestionsFromProject: QuestionGroup;
  InsertQuestionsIntoWorkflow: QuestionSet;
  RemoveQuestionsFromWorkflow: Workflow;
  AddServiceToWorkflow: WorkflowForCustomer;
  AddWorkflowToSample: Sample;
  AddEventToSample: Sample;
  RemoveEventFromSample: Sample;
  RemoveServiceFromWorkflow: WorkflowForCustomer;
  UpdateServiceFromWorkflow: Array<ServiceForCustomer>;
  UpdateEventFromSample: Event;
  UpdateQuestionGroupFromSample: QuestionGroup;
  UpdateQuestionInQuestionGroupFromSample: Question;
  UpdateWorkflowFromSample: WorkflowForCustomer;
  MoveAllWorkflowsInSample: Array<Maybe<WorkflowForCustomer>>;
  RemoveWorkflowFromSample: Sample;
  DuplicateWorkflowInSample: Sample;
  DuplicateSampleInProject: Sample;
  DuplicateProject: Project;
  SetProjectSampleWorkflowServiceCollaborators: Project;
  AssignUsersToProject: Scalars['Boolean'];
  RemoveUserFromProject: Scalars['Boolean'];
  RemoveProject: Scalars['Boolean'];
  RemoveSampleFromProject: Array<Sample>;
  UpdateUserSamplesAssignmentInProject: Array<Sample>;
  MoveServiceToChosenWorkflow: Sample;
  CopyTaskToChosenSample: Sample;
  MakeTimelineElementVisible: Project;
  UpdateSampleTimelineLine: Sample;
  AnswerTextQuestion: Question;
  AnswerNumericQuestion: Question;
  AnswerMultiQuestion: Question;
  EditUserName: Auth0User;
  EditUserRole: Auth0Role;
  ChangePassword: Scalars['String'];
  SaveWorkflowTemplate: Workflow;
  RemoveWorkflowTemplate: Scalars['Boolean'];
};


export type MutationDeleteUploadedFileArgs = {
  fileName: Scalars['String'];
};


export type MutationAddGroupArgs = {
  name: Scalars['String'];
};


export type MutationRemoveGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationAddUsersToGroupArgs = {
  groupId: Scalars['ID'];
  usersIds: Array<Scalars['ID']>;
};


export type MutationRemoveUserFromGroupArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationEditGroupNameArgs = {
  groupId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationAddNotificationArgs = {
  notificationInput?: Maybe<NewNotificationInput>;
};


export type MutationAddProjectArgs = {
  projectInput?: Maybe<NewProjectInput>;
};


export type MutationAnswerProjectQuestionArgs = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Scalars['String'];
};


export type MutationAnswerProjectNumericQuestionArgs = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Scalars['Float'];
};


export type MutationAnswerProjectMultiQuestionArgs = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Array<Scalars['String']>;
};


export type MutationAnswerProjectSampleQuestionArgs = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Scalars['String'];
};


export type MutationAnswerProjectSampleNumericQuestionArgs = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Scalars['Float'];
};


export type MutationAnswerProjectSampleMultiQuestionArgs = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Array<Scalars['String']>;
};


export type MutationAddSampleToProjectArgs = {
  projectSampleInput: ProjectInput;
};


export type MutationSetProjectNameArgs = {
  projectNameInput: ProjectNameInput;
};


export type MutationSetProjectSampleNameArgs = {
  projectSampleNameInput: ProjectSampleNameInput;
};


export type MutationSetProjectSampleStatusArgs = {
  projectSampleStatusInput: ProjectSampleStatusInput;
};


export type MutationEnableWorkflowInProjectSampleArgs = {
  projectSampleWorkflowEnableInput: ProjectSampleWorkflowInput;
};


export type MutationSelectWorkflowInProjectSampleArgs = {
  projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput;
};


export type MutationUnselectWorkflowInProjectSampleArgs = {
  projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput;
};


export type MutationSetPriceOfProjectSampleEnabledWorkflowArgs = {
  projectSampleWorkflowPriceInput: ProjectSampleWorkflowPriceInput;
};


export type MutationSetProjectSampleWorkflowServiceBaselineArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  baseLine: Scalars['Int'];
};


export type MutationSetProjectSampleWorkflowServiceBaselineSizeArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  baseLineSize: Scalars['Int'];
};


export type MutationSetProjectSampleWorkflowEntityStatusArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  status: EntityStatus;
};


export type MutationSetProjectSampleWorkflowServicePriceArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  servicePrice: PriceInput;
};


export type MutationSetProjectSampleWorkflowServiceVendorArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  vendor: Scalars['ID'];
};


export type MutationSetProjectSampleWorkflowServiceTimelineArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  timeline: TimelineInput;
};


export type MutationAnswerProjectSampleWorkflowServiceQuestionArgs = {
  projectSampleWorkflowServiceQuestionInput: ProjectSampleWorkflowServiceQuestionInput;
  answer: Scalars['String'];
};


export type MutationAnswerProjectSampleWorkflowServiceNumericQuestionArgs = {
  projectSampleWorkflowServiceQuestionInput: ProjectSampleWorkflowServiceQuestionInput;
  answer: Scalars['Float'];
};


export type MutationAnswerProjectSampleWorkflowServiceMultiQuestionArgs = {
  projectSampleWorkflowServiceQuestionInput: ProjectSampleWorkflowServiceQuestionInput;
  answer: Array<Scalars['String']>;
};


export type MutationAddProjectSampleEventArgs = {
  projectSampleInput: ProjectSampleInput;
  eventInput: EventInput;
};


export type MutationSetProjectSampleEventTimelineArgs = {
  projectSampleEventInput: ProjectSampleEventInput;
  timeline: TimelineInput;
};


export type MutationSetProjectSampleEventBaselineArgs = {
  projectSampleEventInput: ProjectSampleEventInput;
  baseLine: Scalars['Int'];
};


export type MutationSetProjectSampleEventBaselineSizeArgs = {
  projectSampleEventInput: ProjectSampleEventInput;
  baseLineSize: Scalars['Int'];
};


export type MutationAddQuestionGroupToSampleArgs = {
  projectSampleInput: ProjectSampleInput;
};


export type MutationAddQuestionGroupToProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationAddQuestionGroupToWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
};


export type MutationRemoveQuestionGroupFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
};


export type MutationRemoveQuestionGroupFromProjectArgs = {
  projectId: Scalars['ID'];
  questionGroupId: Scalars['ID'];
};


export type MutationRemoveQuestionGroupFromWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  questionGroupId: Scalars['ID'];
};


export type MutationAddQuestionsToSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionIds: Array<Scalars['ID']>;
};


export type MutationAddQuestionToSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};


export type MutationRemoveQuestionsFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationInsertQuestionsIntoProjectArgs = {
  projectId: Scalars['ID'];
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionIds: Array<Scalars['ID']>;
};


export type MutationRemoveQuestionsFromProjectArgs = {
  projectId: Scalars['ID'];
  questionGroupId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationInsertQuestionsIntoWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowDesignation: Scalars['String'];
  workflowId: Scalars['ID'];
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationRemoveQuestionsFromWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  questionGroupId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationAddServiceToWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceId: Scalars['ID'];
  startDate: Scalars['String'];
  baseline?: Maybe<Scalars['Int']>;
};


export type MutationAddWorkflowToSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowId: Scalars['ID'];
  startDate: Scalars['String'];
  serviceBaseline?: Maybe<Scalars['Int']>;
};


export type MutationAddEventToSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  eventId: Scalars['ID'];
  startDate: Scalars['String'];
  baseline?: Maybe<Scalars['Int']>;
};


export type MutationRemoveEventFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  eventId: Scalars['ID'];
};


export type MutationRemoveServiceFromWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceId: Scalars['ID'];
};


export type MutationUpdateServiceFromWorkflowArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceUpdatables: ServiceInput;
};


export type MutationUpdateEventFromSampleArgs = {
  projectSampleEventInput: ProjectSampleEventInput;
  eventUpdatables: EventUpdatables;
};


export type MutationUpdateQuestionGroupFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionGroupUpdatables: UpdateQuestionGroupInput;
};


export type MutationUpdateQuestionInQuestionGroupFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  questionInput: QuestionInput;
  questionUpdatables: UpdateQuestionInput;
};


export type MutationUpdateWorkflowFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowUpdatables: UpdateWorkflowInput;
};


export type MutationMoveAllWorkflowsInSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  newStartDate: Scalars['String'];
};


export type MutationRemoveWorkflowFromSampleArgs = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
};


export type MutationDuplicateWorkflowInSampleArgs = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
};


export type MutationDuplicateSampleInProjectArgs = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
};


export type MutationDuplicateProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationSetProjectSampleWorkflowServiceCollaboratorsArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  collaborators: CollaboratorsInput;
};


export type MutationAssignUsersToProjectArgs = {
  projectId: Scalars['ID'];
  usersAuth0Ids: Array<Scalars['String']>;
};


export type MutationRemoveUserFromProjectArgs = {
  projectId: Scalars['ID'];
  userAuth0Id: Scalars['String'];
};


export type MutationRemoveProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationRemoveSampleFromProjectArgs = {
  projectSampleInput?: Maybe<ProjectSampleInput>;
};


export type MutationUpdateUserSamplesAssignmentInProjectArgs = {
  updateUserSamplesAssignmentInProjectInput: UpdateUserSamplesAssignmentInProjectInput;
};


export type MutationMoveServiceToChosenWorkflowArgs = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  destinationWorkflowId: Scalars['ID'];
};


export type MutationCopyTaskToChosenSampleArgs = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
  destinationSampleId: Scalars['ID'];
};


export type MutationMakeTimelineElementVisibleArgs = {
  timelineElementVisibleInput: TimelineElementVisibleInput;
};


export type MutationUpdateSampleTimelineLineArgs = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  line: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationAnswerTextQuestionArgs = {
  questionId: Scalars['ID'];
  answer: Scalars['String'];
};


export type MutationAnswerNumericQuestionArgs = {
  questionId: Scalars['ID'];
  answer: Scalars['Float'];
};


export type MutationAnswerMultiQuestionArgs = {
  questionId: Scalars['ID'];
  answer: Array<Scalars['String']>;
};


export type MutationEditUserNameArgs = {
  auth0UserId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationEditUserRoleArgs = {
  auth0UserId: Scalars['ID'];
  auth0RoleId: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationSaveWorkflowTemplateArgs = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
  organization: Scalars['String'];
  templateName: Scalars['String'];
};


export type MutationRemoveWorkflowTemplateArgs = {
  templateId: Scalars['ID'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<Scalars['ID']>;
  organization: Scalars['String'];
};

export type GroupMembers = {
  __typename?: 'GroupMembers';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<User>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  organization: Scalars['String'];
  preferences: UserPreferences;
  schedule?: Maybe<Schedule>;
};

export type Sample = {
  __typename?: 'Sample';
  id: Scalars['ID'];
  name: Scalars['String'];
  suggestedWorkflows: Array<Workflow>;
  selectedWorkflows: Array<Workflow>;
  enabledWorkflows: Array<WorkflowForCustomer>;
  selectedQuestionGroups: Array<QuestionGroup>;
  events: Array<Event>;
  status: EntityStatus;
  assignedUsers?: Maybe<Array<Maybe<SampleUser>>>;
  timelineLines?: Maybe<Array<Maybe<TimelineLine>>>;
};

export enum NotificationStatus {
  Sent = 'Sent',
  Waiting = 'Waiting',
  Failed = 'Failed'
}

export enum NotificationType {
  AssignedToSubtask = 'AssignedToSubtask',
  UnassignedFromSubtask = 'UnassignedFromSubtask',
  AssignedToProject = 'AssignedToProject',
  UnassignedFromProject = 'UnassignedFromProject',
  SubtaskIsAboutToBegin = 'SubtaskIsAboutToBegin',
  SubtaskWillEndIn2weeks = 'SubtaskWillEndIn2weeks',
  SubtaskWillEndIn1week = 'SubtaskWillEndIn1week'
}

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  notificationKey: Scalars['String'];
  message: Scalars['String'];
  messageHtml: Scalars['String'];
  notificationStatus: NotificationStatus;
  recipients: Array<Scalars['String']>;
  type: NotificationType;
  createdAt: Scalars['String'];
  toBeSentAfter: Scalars['String'];
  sentAt?: Maybe<Scalars['String']>;
};

export type NewNotificationInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
  recipients: Array<Scalars['String']>;
  toBeSentAfter: Scalars['String'];
};

export type CurrentService = {
  __typename?: 'CurrentService';
  serviceName: Scalars['String'];
  sampleName: Scalars['String'];
  weekRemaining: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  name: Scalars['String'];
  samples: Array<Sample>;
  selectedQuestionGroups: Array<QuestionGroup>;
  users: Array<User>;
  currentService?: Maybe<CurrentService>;
  visibleTimelineElements?: Maybe<VisibleTimelineElements>;
  lastActivity?: Maybe<Scalars['String']>;
};

export type SimplifiedWorkflow = {
  __typename?: 'SimplifiedWorkflow';
  id: Scalars['ID'];
  services?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type SimplifiedSample = {
  __typename?: 'SimplifiedSample';
  id: Scalars['ID'];
  workflows?: Maybe<Array<Maybe<SimplifiedWorkflow>>>;
  events?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type VisibleTimelineElements = {
  __typename?: 'VisibleTimelineElements';
  samples?: Maybe<Array<Maybe<SimplifiedSample>>>;
};

export type ProjectFilterQueryInput = {
  userId: Scalars['ID'];
};

export type QuestionInput = {
  questionGroupId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  questionId: Scalars['ID'];
  fromReport?: Maybe<Scalars['Boolean']>;
  workflowId?: Maybe<Scalars['ID']>;
  serviceId?: Maybe<Scalars['ID']>;
};

export type ProjectQuestionInput = {
  projectId: Scalars['ID'];
  questionInput: QuestionInput;
};

export type ProjectSampleQuestionInput = {
  projectSampleInput: ProjectSampleInput;
  questionInput: QuestionInput;
};

export type ProjectSampleWorkflowServiceQuestionInput = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  questionInput: QuestionInput;
};

export type ProjectInput = {
  projectId: Scalars['ID'];
};

export type NewProjectInput = {
  userId: Scalars['ID'];
};

export type ProjectNameInput = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
  questionInput: QuestionInput;
};

export type WorkflowInput = {
  workflowDesignation: Scalars['String'];
  workflowId: Scalars['ID'];
};

export type ProjectSampleInput = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  fromReport?: Maybe<Scalars['Boolean']>;
  workflowId?: Maybe<Scalars['ID']>;
  serviceId?: Maybe<Scalars['ID']>;
};

export type ProjectSampleNameInput = {
  projectSampleInput: ProjectSampleInput;
  name: Scalars['String'];
  questionInput: QuestionInput;
};

export type ProjectSampleStatusInput = {
  projectSampleInput: ProjectSampleInput;
  status: EntityStatus;
};

export type ProjectSampleEventInput = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  eventId: Scalars['ID'];
};

export type ProjectSampleWorkflowInput = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  workflowId: Scalars['ID'];
};

export type PriceInput = {
  price: Scalars['String'];
  currency: Currency;
};

export type ProjectSampleWorkflowServiceInput = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  workflowId: Scalars['ID'];
  serviceId: Scalars['ID'];
};

export type TimelineInput = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type ProjectSampleWorkflowPriceInput = {
  workflowInput: ProjectSampleWorkflowInput;
  priceInput: PriceInput;
};

export type EventInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  priority: EventPriority;
};

export type EventUpdatables = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  baseLine?: Maybe<Scalars['Int']>;
  milestone?: Maybe<Scalars['Boolean']>;
};

export type TimelineElementVisibleInput = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  removeVisibility?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['ID']>;
  workflowId?: Maybe<Scalars['ID']>;
  serviceId?: Maybe<Scalars['ID']>;
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  id: Scalars['ID'];
  name: Scalars['String'];
  avatar: Scalars['String'];
  email: Scalars['String'];
  role: Scalars['String'];
  group: Scalars['String'];
};

export type ServiceInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  serviceTemplate?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  baseLineSize?: Maybe<Scalars['Int']>;
  baseLine?: Maybe<Scalars['Int']>;
  status?: Maybe<EntityStatus>;
  price?: Maybe<ActualEntityPriceInput>;
  vendor?: Maybe<Scalars['String']>;
  report?: Maybe<ServiceReportInput>;
  assignee?: Maybe<Scalars['ID']>;
  collaborators?: Maybe<CollaboratorsInput>;
  priority?: Maybe<Priority>;
  durationLocked?: Maybe<Scalars['Boolean']>;
};

export type UpdateWorkflowInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['ID']>;
  collaborators?: Maybe<CollaboratorsInput>;
  price?: Maybe<ActualEntityPriceInput>;
  description?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type UpdateQuestionGroupInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type CollaboratorsInput = {
  userIds: Array<Scalars['ID']>;
  groupIds: Array<Scalars['ID']>;
};

export type UpdateQuestionInput = {
  id: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isObligatory?: Maybe<Scalars['Boolean']>;
  choices?: Maybe<Array<Scalars['String']>>;
};

export type SampleAssignment = {
  sampleId: Scalars['ID'];
  role: Scalars['String'];
};

export type UpdateUserSamplesAssignmentInProjectInput = {
  projectId: Scalars['ID'];
  sampleAssignments?: Maybe<Array<SampleAssignment>>;
  userId: Scalars['String'];
};

export type QuestionGroup = {
  __typename?: 'QuestionGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  questionSets: Array<QuestionSet>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  question: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Scalars['String']>>;
  order: Scalars['Int'];
  questionLevel: QuestionLevel;
  isObligatory: Scalars['Boolean'];
  answer: BaseAnswer;
  visibility: Scalars['Boolean'];
  visibilityPreconditions: Array<QuestionVisibilityPrecondition>;
  placeholder?: Maybe<Scalars['String']>;
};

export type QuestionSet = {
  __typename?: 'QuestionSet';
  id: Scalars['ID'];
  questions: Array<Question>;
  apiLink?: Maybe<Scalars['String']>;
  renderer?: Maybe<Scalars['String']>;
};

export type Workflow = BaseWorkflow & {
  __typename?: 'Workflow';
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<Service>;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
  questionGroupsToAddToSample: Array<QuestionGroup>;
  organization?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type WorkflowForCustomer = BaseWorkflow & {
  __typename?: 'WorkflowForCustomer';
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<ServiceForCustomer>;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
  price?: Maybe<ActualEntityPrice>;
  description?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['ID']>;
  collaborators?: Maybe<Collaborators>;
};

export type ServiceReport = {
  __typename?: 'ServiceReport';
  selectedQuestionGroups: Array<QuestionGroup>;
};

export type Service = BaseService & {
  __typename?: 'Service';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  baseLineSize: Scalars['Int'];
  baseLine: Scalars['Int'];
  status: EntityStatus;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
  report?: Maybe<ServiceReport>;
  vendors: Array<Vendor>;
  serviceType: Scalars['String'];
  order: Scalars['Int'];
  hiddenOnTheList?: Maybe<Scalars['Boolean']>;
  serviceTemplate?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['ID']>;
  priority?: Maybe<Priority>;
  durationLocked?: Maybe<Scalars['Boolean']>;
};

export type ServiceForCustomer = BaseService & {
  __typename?: 'ServiceForCustomer';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  baseLineSize: Scalars['Int'];
  baseLine: Scalars['Int'];
  status: EntityStatus;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
  report?: Maybe<ServiceReport>;
  scientists?: Maybe<Array<User>>;
  equipment?: Maybe<Array<Equipment>>;
  price?: Maybe<ActualEntityPrice>;
  vendor?: Maybe<Vendor>;
  serviceType: Scalars['String'];
  order: Scalars['Int'];
  hiddenOnTheList?: Maybe<Scalars['Boolean']>;
  serviceTemplate?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['ID']>;
  collaborators?: Maybe<Collaborators>;
  priority?: Maybe<Priority>;
  durationLocked?: Maybe<Scalars['Boolean']>;
};

export type ServiceReportInput = {
  selectedQuestionGroups: Array<QuestionGroupInput>;
};

export type QuestionGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  questionSets: Array<QuestionSetInput>;
};

export type QuestionSetInput = {
  id: Scalars['ID'];
  questions: Array<QuestionInput>;
  apiLink?: Maybe<Scalars['String']>;
  renderer?: Maybe<Scalars['String']>;
};

export enum QuestionLevel {
  Offer = 'Offer',
  PreProject = 'PreProject',
  Report = 'Report',
  Project = 'Project',
  Sample = 'Sample',
  Admin = 'Admin',
  ProjectManager = 'ProjectManager',
  Scientist = 'Scientist',
  CustomQuestion = 'CustomQuestion'
}

export type TextAnswer = {
  __typename?: 'TextAnswer';
  body?: Maybe<Scalars['String']>;
  answerType: AnswerType;
};

export type NumericAnswer = {
  __typename?: 'NumericAnswer';
  body?: Maybe<Scalars['Float']>;
  answerType: AnswerType;
};

export type MultiAnswer = {
  __typename?: 'MultiAnswer';
  body?: Maybe<Array<Scalars['String']>>;
  answerType: AnswerType;
};

export type BaseAnswer = TextAnswer | NumericAnswer | MultiAnswer;

export type QuestionVisibilityPrecondition = {
  __typename?: 'QuestionVisibilityPrecondition';
  questionId: Scalars['ID'];
  expectedAnswer?: Maybe<BaseAnswer>;
  isSatisfied: Scalars['Boolean'];
};

export type QuestionFilterQueryInput = {
  isObligatory?: Maybe<Scalars['Boolean']>;
  questionLevel?: Maybe<QuestionLevel>;
  answerType?: Maybe<AnswerType>;
};

export type SampleUser = {
  __typename?: 'SampleUser';
  id: Scalars['ID'];
  role: Scalars['String'];
};

export type TimelineLine = {
  __typename?: 'TimelineLine';
  line: Scalars['Int'];
  name: Scalars['String'];
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['ID'];
  name: Scalars['String'];
  schedule?: Maybe<Schedule>;
};

export type BaseService = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  baseLineSize: Scalars['Int'];
  baseLine: Scalars['Int'];
  status: EntityStatus;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
  report?: Maybe<ServiceReport>;
  serviceType: Scalars['String'];
  order: Scalars['Int'];
  hiddenOnTheList?: Maybe<Scalars['Boolean']>;
  serviceTemplate?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<Scalars['ID']>;
  priority?: Maybe<Priority>;
  durationLocked?: Maybe<Scalars['Boolean']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  preferredCurrency?: Maybe<Currency>;
};

export type Auth0Role = {
  __typename?: 'Auth0Role';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type Auth0User = {
  __typename?: 'Auth0User';
  id: Scalars['ID'];
  name: Scalars['String'];
  avatar: Scalars['String'];
  email: Scalars['String'];
  organization: Scalars['String'];
  role?: Maybe<Auth0Role>;
};

export type BaseWorkflow = {
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<BaseService>;
  offerPrice?: Maybe<Array<OfferedEntityPrice>>;
};

export type AdditionalEntityFields = {
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MyQueryQueryVariables = {};


export type MyQueryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'healthcheck'>
);

export type UpdateEventFromSampleMutationVariables = {
  projectSampleEventInput: ProjectSampleEventInput;
  eventUpdatables: EventUpdatables;
};


export type UpdateEventFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { UpdateEventFromSample: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
    & { collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'userIds' | 'groupIds'>
    )> }
  ) }
);

export type DeleteUploadedFileMutationVariables = {
  fileName: Scalars['String'];
};


export type DeleteUploadedFileMutation = (
  { __typename?: 'Mutation' }
  & { DeleteUploadedFile: (
    { __typename?: 'DeleteStatusResponse' }
    & Pick<DeleteStatusResponse, 'deleteStatus' | 'fileName'>
  ) }
);

export type AddGroupMutationVariables = {
  name: Scalars['String'];
};


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'members' | 'name'>
  )> }
);

export type AddUsersToGroupMutationVariables = {
  groupId: Scalars['ID'];
  usersIds: Array<Scalars['ID']>;
};


export type AddUsersToGroupMutation = (
  { __typename?: 'Mutation' }
  & { AddUsersToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'members' | 'name'>
  )> }
);

export type EditGroupNameMutationVariables = {
  groupId: Scalars['ID'];
  name: Scalars['String'];
};


export type EditGroupNameMutation = (
  { __typename?: 'Mutation' }
  & { EditGroupName?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'members' | 'name'>
  )> }
);

export type RemoveGroupMutationVariables = {
  groupId: Scalars['ID'];
};


export type RemoveGroupMutation = (
  { __typename?: 'Mutation' }
  & { RemoveGroup?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'members' | 'name'>
  )>>> }
);

export type RemoveUserFromGroupMutationVariables = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RemoveUserFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { RemoveUserFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'members' | 'name'>
  )> }
);

export type NewProjectMutationVariables = {};


export type NewProjectMutation = (
  { __typename?: 'Mutation' }
  & { AddProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )> }
  ) }
);

export type AddSampleToProjectMutationVariables = {
  projectSampleInput: ProjectInput;
};


export type AddSampleToProjectMutation = (
  { __typename?: 'Mutation' }
  & { AddSampleToProject: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'order' | 'durationLocked'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'durationLocked' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'order'>
        & { price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type ProjectWithMultiAnswerMutationVariables = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Array<Scalars['String']>;
};


export type ProjectWithMultiAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectMultiQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type ProjectWithNumericAnswerMutationVariables = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Scalars['Float'];
};


export type ProjectWithNumericAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectNumericQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type ProjectWithTextAnswerMutationVariables = {
  projectQuestionInput: ProjectQuestionInput;
  answer: Scalars['String'];
};


export type ProjectWithTextAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type ProjectSampleWithMultiAnswerMutationVariables = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Array<Scalars['String']>;
};


export type ProjectSampleWithMultiAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectSampleMultiQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type ProjectSampleWithNumericAnswerMutationVariables = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Scalars['Float'];
};


export type ProjectSampleWithNumericAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectSampleNumericQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type ProjectSampleWithTextAnswerMutationVariables = {
  projectSampleQuestionInput: ProjectSampleQuestionInput;
  answer: Scalars['String'];
};


export type ProjectSampleWithTextAnswerMutation = (
  { __typename?: 'Mutation' }
  & { AnswerProjectSampleQuestion: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type AssignUsersToProjectMutationVariables = {
  projectId: Scalars['ID'];
  usersAuth0Ids: Array<Scalars['String']>;
};


export type AssignUsersToProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignUsersToProject'>
);

export type DuplicateProjectMutationVariables = {
  projectId: Scalars['ID'];
};


export type DuplicateProjectMutation = (
  { __typename?: 'Mutation' }
  & { DuplicateProject: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )>, visibleTimelineElements?: Maybe<(
      { __typename?: 'VisibleTimelineElements' }
      & { samples?: Maybe<Array<Maybe<(
        { __typename?: 'SimplifiedSample' }
        & Pick<SimplifiedSample, 'id' | 'events'>
        & { workflows?: Maybe<Array<Maybe<(
          { __typename?: 'SimplifiedWorkflow' }
          & Pick<SimplifiedWorkflow, 'id' | 'services'>
        )>>> }
      )>>> }
    )> }
  ) }
);

export type MakeTimelineElementVisibleMutationVariables = {
  timelineElementVisibleInput: TimelineElementVisibleInput;
};


export type MakeTimelineElementVisibleMutation = (
  { __typename?: 'Mutation' }
  & { MakeTimelineElementVisible: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )>, visibleTimelineElements?: Maybe<(
      { __typename?: 'VisibleTimelineElements' }
      & { samples?: Maybe<Array<Maybe<(
        { __typename?: 'SimplifiedSample' }
        & Pick<SimplifiedSample, 'id' | 'events'>
        & { workflows?: Maybe<Array<Maybe<(
          { __typename?: 'SimplifiedWorkflow' }
          & Pick<SimplifiedWorkflow, 'id' | 'services'>
        )>>> }
      )>>> }
    )> }
  ) }
);

export type RemoveProjectMutationVariables = {
  projectId: Scalars['ID'];
};


export type RemoveProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveProject'>
);

export type RemoveUserFromProjectMutationVariables = {
  projectId: Scalars['ID'];
  userAuth0Id: Scalars['String'];
};


export type RemoveUserFromProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveUserFromProject'>
);

export type SetProjectSampleWorkflowServiceTimelineMutationVariables = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  timeline: TimelineInput;
};


export type SetProjectSampleWorkflowServiceTimelineMutation = (
  { __typename?: 'Mutation' }
  & { SetProjectSampleWorkflowServiceTimeline: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type AddEventToSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  eventId: Scalars['ID'];
  startDate: Scalars['String'];
  baseline?: Maybe<Scalars['Int']>;
};


export type AddEventToSampleMutation = (
  { __typename?: 'Mutation' }
  & { AddEventToSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type AddQuestionGroupToSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
};


export type AddQuestionGroupToSampleMutation = (
  { __typename?: 'Mutation' }
  & { AddQuestionGroupToSample: (
    { __typename?: 'QuestionGroup' }
    & Pick<QuestionGroup, 'id' | 'name'>
    & { questionSets: Array<(
      { __typename?: 'QuestionSet' }
      & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
        & { answer: (
          { __typename?: 'TextAnswer' }
          & Pick<TextAnswer, 'answerType'>
          & { textBody: TextAnswer['body'] }
        ) | (
          { __typename?: 'NumericAnswer' }
          & Pick<NumericAnswer, 'answerType'>
          & { numericBody: NumericAnswer['body'] }
        ) | (
          { __typename?: 'MultiAnswer' }
          & Pick<MultiAnswer, 'answerType'>
          & { multiBody: MultiAnswer['body'] }
        ), visibilityPreconditions: Array<(
          { __typename?: 'QuestionVisibilityPrecondition' }
          & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
          & { expectedAnswer?: Maybe<(
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type AddQuestionToSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};


export type AddQuestionToSampleMutation = (
  { __typename?: 'Mutation' }
  & { AddQuestionToSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name'>
    & { enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
        & { scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'groupIds' | 'userIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type AddQuestionsToSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId?: Maybe<Scalars['ID']>;
  questionIds: Array<Scalars['ID']>;
};


export type AddQuestionsToSampleMutation = (
  { __typename?: 'Mutation' }
  & { AddQuestionsToSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type AddWorkflowToSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowId: Scalars['ID'];
  startDate: Scalars['String'];
  serviceBaseline?: Maybe<Scalars['Int']>;
};


export type AddWorkflowToSampleMutation = (
  { __typename?: 'Mutation' }
  & { AddWorkflowToSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type CopyTaskToChosenSampleMutationVariables = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
  destinationSampleId: Scalars['ID'];
};


export type CopyTaskToChosenSampleMutation = (
  { __typename?: 'Mutation' }
  & { CopyTaskToChosenSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type DuplicateSampleInProjectMutationVariables = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
};


export type DuplicateSampleInProjectMutation = (
  { __typename?: 'Mutation' }
  & { DuplicateSampleInProject: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type EnableWorkflowInProjectSampleMutationVariables = {
  projectSampleWorkflowEnableInput: ProjectSampleWorkflowInput;
};


export type EnableWorkflowInProjectSampleMutation = (
  { __typename?: 'Mutation' }
  & { EnableWorkflowInProjectSample: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, selectedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'priority' | 'order'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, collaborators?: Maybe<(
            { __typename?: 'Collaborators' }
            & Pick<Collaborators, 'userIds' | 'groupIds'>
          )>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )> }
  ) }
);

export type RemoveEventFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  eventId: Scalars['ID'];
};


export type RemoveEventFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { RemoveEventFromSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type RemoveQuestionGroupFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
};


export type RemoveQuestionGroupFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { RemoveQuestionGroupFromSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name'>
    & { enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
        & { scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'groupIds' | 'userIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type RemoveQuestionsFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionGroupId: Scalars['ID'];
  questionSetId: Scalars['ID'];
  questionIds?: Maybe<Array<Scalars['ID']>>;
};


export type RemoveQuestionsFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { RemoveQuestionsFromSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name'>
    & { enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
        & { scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'groupIds' | 'userIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type RemoveSampleFromProjectMutationVariables = {
  projectSampleInput: ProjectSampleInput;
};


export type RemoveSampleFromProjectMutation = (
  { __typename?: 'Mutation' }
  & { RemoveSampleFromProject: Array<(
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  )> }
);

export type RemoveWorkflowFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
};


export type RemoveWorkflowFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { RemoveWorkflowFromSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type SelectWorkflowInProjectSampleMutationVariables = {
  projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput;
};


export type SelectWorkflowInProjectSampleMutation = (
  { __typename?: 'Mutation' }
  & { SelectWorkflowInProjectSample: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type UnselectWorkflowInProjectSampleMutationVariables = {
  projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput;
};


export type UnselectWorkflowInProjectSampleMutation = (
  { __typename?: 'Mutation' }
  & { UnselectWorkflowInProjectSample: (
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
  ) }
);

export type UpdateQuestionGroupFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionGroupUpdatables: UpdateQuestionGroupInput;
};


export type UpdateQuestionGroupFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { UpdateQuestionGroupFromSample: (
    { __typename?: 'QuestionGroup' }
    & Pick<QuestionGroup, 'id' | 'name'>
    & { questionSets: Array<(
      { __typename?: 'QuestionSet' }
      & Pick<QuestionSet, 'id' | 'apiLink' | 'renderer'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
        & { answer: (
          { __typename?: 'TextAnswer' }
          & Pick<TextAnswer, 'answerType'>
          & { textBody: TextAnswer['body'] }
        ) | (
          { __typename?: 'NumericAnswer' }
          & Pick<NumericAnswer, 'answerType'>
          & { numericBody: NumericAnswer['body'] }
        ) | (
          { __typename?: 'MultiAnswer' }
          & Pick<MultiAnswer, 'answerType'>
          & { multiBody: MultiAnswer['body'] }
        ), visibilityPreconditions: Array<(
          { __typename?: 'QuestionVisibilityPrecondition' }
          & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
          & { expectedAnswer?: Maybe<(
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type UpdateQuestionInQuestionGroupFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  questionInput: QuestionInput;
  questionUpdatables: UpdateQuestionInput;
};


export type UpdateQuestionInQuestionGroupFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { UpdateQuestionInQuestionGroupFromSample: (
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
    & { answer: (
      { __typename?: 'TextAnswer' }
      & Pick<TextAnswer, 'answerType'>
      & { textBody: TextAnswer['body'] }
    ) | (
      { __typename?: 'NumericAnswer' }
      & Pick<NumericAnswer, 'answerType'>
      & { numericBody: NumericAnswer['body'] }
    ) | (
      { __typename?: 'MultiAnswer' }
      & Pick<MultiAnswer, 'answerType'>
      & { multiBody: MultiAnswer['body'] }
    ), visibilityPreconditions: Array<(
      { __typename?: 'QuestionVisibilityPrecondition' }
      & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
      & { expectedAnswer?: Maybe<(
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'answerType'>
        & { textBody: TextAnswer['body'] }
      ) | (
        { __typename?: 'NumericAnswer' }
        & Pick<NumericAnswer, 'answerType'>
        & { numericBody: NumericAnswer['body'] }
      ) | (
        { __typename?: 'MultiAnswer' }
        & Pick<MultiAnswer, 'answerType'>
        & { multiBody: MultiAnswer['body'] }
      )> }
    )> }
  ) }
);

export type UpdateSampleTimelineLineMutationVariables = {
  projectId: Scalars['ID'];
  sampleId: Scalars['ID'];
  line: Scalars['Int'];
  name: Scalars['String'];
};


export type UpdateSampleTimelineLineMutation = (
  { __typename?: 'Mutation' }
  & { UpdateSampleTimelineLine: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type UpdateUserSamplesAssignmentInProjectMutationVariables = {
  updateUserSamplesAssignmentInProjectInput: UpdateUserSamplesAssignmentInProjectInput;
};


export type UpdateUserSamplesAssignmentInProjectMutation = (
  { __typename?: 'Mutation' }
  & { UpdateUserSamplesAssignmentInProject: Array<(
    { __typename?: 'Sample' }
    & Pick<Sample, 'id'>
    & { assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>> }
  )> }
);

export type AddServiceToWorkflowMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceId: Scalars['ID'];
  startDate: Scalars['String'];
  baseline?: Maybe<Scalars['Int']>;
};


export type AddServiceToWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { AddServiceToWorkflow: (
    { __typename?: 'WorkflowForCustomer' }
    & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
    & { price?: Maybe<(
      { __typename?: 'ActualEntityPrice' }
      & Pick<ActualEntityPrice, 'currency'>
      & { price: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>, collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'userIds' | 'groupIds'>
    )>, offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, services: Array<(
      { __typename?: 'ServiceForCustomer' }
      & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order' | 'assignee' | 'priority'>
      & { vendor?: Maybe<(
        { __typename?: 'Vendor' }
        & Pick<Vendor, 'id' | 'name'>
      )>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, scientists?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'organization'>
        & { preferences: (
          { __typename?: 'UserPreferences' }
          & Pick<UserPreferences, 'preferredCurrency'>
        ), schedule?: Maybe<(
          { __typename?: 'Schedule' }
          & Pick<Schedule, 'id'>
        )> }
      )>>, report?: Maybe<(
        { __typename?: 'ServiceReport' }
        & { selectedQuestionGroups: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )> }
  ) }
);

export type RemoveServiceFromWorkflowMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceId: Scalars['ID'];
};


export type RemoveServiceFromWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { RemoveServiceFromWorkflow: (
    { __typename?: 'WorkflowForCustomer' }
    & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
    & { offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, services: Array<(
      { __typename?: 'ServiceForCustomer' }
      & Pick<ServiceForCustomer, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order' | 'assignee' | 'priority'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, vendor?: Maybe<(
        { __typename?: 'Vendor' }
        & Pick<Vendor, 'id' | 'name'>
      )>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, scientists?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'organization'>
        & { preferences: (
          { __typename?: 'UserPreferences' }
          & Pick<UserPreferences, 'preferredCurrency'>
        ), schedule?: Maybe<(
          { __typename?: 'Schedule' }
          & Pick<Schedule, 'id'>
        )> }
      )>>, report?: Maybe<(
        { __typename?: 'ServiceReport' }
        & { selectedQuestionGroups: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )> }
      )> }
    )>, price?: Maybe<(
      { __typename?: 'ActualEntityPrice' }
      & Pick<ActualEntityPrice, 'currency'>
      & { price: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>, collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'userIds' | 'groupIds'>
    )> }
  ) }
);

export type UpdateServiceFromWorkflowMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowInput: WorkflowInput;
  serviceUpdatables: ServiceInput;
};


export type UpdateServiceFromWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { UpdateServiceFromWorkflow: Array<(
    { __typename?: 'ServiceForCustomer' }
    & Pick<ServiceForCustomer, 'id' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'status' | 'serviceType' | 'durationLocked' | 'order' | 'hiddenOnTheList' | 'assignee' | 'priority'>
    & { price?: Maybe<(
      { __typename?: 'ActualEntityPrice' }
      & Pick<ActualEntityPrice, 'currency'>
      & { price: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>, offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'userIds' | 'groupIds'>
    )>, scientists?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ), schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )> }
    )>>, vendor?: Maybe<(
      { __typename?: 'Vendor' }
      & Pick<Vendor, 'id' | 'name'>
    )>, report?: Maybe<(
      { __typename?: 'ServiceReport' }
      & { selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ChangePasswordMutationVariables = {
  newPassword: Scalars['String'];
};


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChangePassword'>
);

export type EditUserNameMutationVariables = {
  auth0UserId: Scalars['ID'];
  name: Scalars['String'];
};


export type EditUserNameMutation = (
  { __typename?: 'Mutation' }
  & { EditUserName: (
    { __typename?: 'Auth0User' }
    & Pick<Auth0User, 'id' | 'name' | 'avatar' | 'email' | 'organization'>
    & { role?: Maybe<(
      { __typename?: 'Auth0Role' }
      & Pick<Auth0Role, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type EditUserRoleMutationVariables = {
  auth0UserId: Scalars['ID'];
  auth0RoleId: Scalars['ID'];
};


export type EditUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { EditUserRole: (
    { __typename?: 'Auth0Role' }
    & Pick<Auth0Role, 'id' | 'name' | 'description'>
  ) }
);

export type DuplicateWorkflowInSampleMutationVariables = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
};


export type DuplicateWorkflowInSampleMutation = (
  { __typename?: 'Mutation' }
  & { DuplicateWorkflowInSample: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type MoveAllWorkflowsInSampleMutationMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  newStartDate: Scalars['String'];
};


export type MoveAllWorkflowsInSampleMutationMutation = (
  { __typename?: 'Mutation' }
  & { MoveAllWorkflowsInSample: Array<Maybe<(
    { __typename?: 'WorkflowForCustomer' }
    & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
    & { collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'userIds' | 'groupIds'>
    )>, offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, price?: Maybe<(
      { __typename?: 'ActualEntityPrice' }
      & Pick<ActualEntityPrice, 'currency'>
      & { price: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>, services: Array<(
      { __typename?: 'ServiceForCustomer' }
      & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, scientists?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'organization'>
        & { preferences: (
          { __typename?: 'UserPreferences' }
          & Pick<UserPreferences, 'preferredCurrency'>
        ), schedule?: Maybe<(
          { __typename?: 'Schedule' }
          & Pick<Schedule, 'id'>
        )> }
      )>>, vendor?: Maybe<(
        { __typename?: 'Vendor' }
        & Pick<Vendor, 'id' | 'name'>
      )>, report?: Maybe<(
        { __typename?: 'ServiceReport' }
        & { selectedQuestionGroups: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )> }
      )> }
    )> }
  )>> }
);

export type MoveServiceToChosenWorkflowMutationVariables = {
  projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput;
  destinationWorkflowId: Scalars['ID'];
};


export type MoveServiceToChosenWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { MoveServiceToChosenWorkflow: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )>, scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type RemoveWorkflowTemplateMutationVariables = {
  templateId: Scalars['ID'];
};


export type RemoveWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveWorkflowTemplate'>
);

export type SaveWorkflowTemplateMutationVariables = {
  projectSampleWorkflowInput: ProjectSampleWorkflowInput;
  organization: Scalars['String'];
  templateName: Scalars['String'];
};


export type SaveWorkflowTemplateMutation = (
  { __typename?: 'Mutation' }
  & { SaveWorkflowTemplate: (
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'name' | 'organization' | 'description'>
    & { offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order' | 'assignee' | 'priority'>
      & { vendors: Array<(
        { __typename?: 'Vendor' }
        & Pick<Vendor, 'id' | 'name'>
      )>, report?: Maybe<(
        { __typename?: 'ServiceReport' }
        & { selectedQuestionGroups: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )> }
      )>, offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>> }
    )>, questionGroupsToAddToSample: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type UpdateWorkflowFromSampleMutationVariables = {
  projectSampleInput: ProjectSampleInput;
  workflowUpdatables: UpdateWorkflowInput;
};


export type UpdateWorkflowFromSampleMutation = (
  { __typename?: 'Mutation' }
  & { UpdateWorkflowFromSample: (
    { __typename?: 'WorkflowForCustomer' }
    & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
    & { offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, price?: Maybe<(
      { __typename?: 'ActualEntityPrice' }
      & Pick<ActualEntityPrice, 'currency'>
      & { price: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>, services: Array<(
      { __typename?: 'ServiceForCustomer' }
      & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
      & { price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, scientists?: Maybe<Array<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'name' | 'organization'>
        & { preferences: (
          { __typename?: 'UserPreferences' }
          & Pick<UserPreferences, 'preferredCurrency'>
        ), schedule?: Maybe<(
          { __typename?: 'Schedule' }
          & Pick<Schedule, 'id'>
        )> }
      )>>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )>, vendor?: Maybe<(
        { __typename?: 'Vendor' }
        & Pick<Vendor, 'id' | 'name'>
      )>, report?: Maybe<(
        { __typename?: 'ServiceReport' }
        & { selectedQuestionGroups: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )> }
      )> }
    )>, collaborators?: Maybe<(
      { __typename?: 'Collaborators' }
      & Pick<Collaborators, 'groupIds' | 'userIds'>
    )> }
  ) }
);

export type DownloadLinkQueryVariables = {
  fileName: Scalars['String'];
  originFileName: Scalars['String'];
};


export type DownloadLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DownloadLink'>
);

export type UploadLinksQueryVariables = {
  fileInput: Array<UploadLinksInput>;
};


export type UploadLinksQuery = (
  { __typename?: 'Query' }
  & { UploadLinks: Array<(
    { __typename?: 'UploadLinksResponse' }
    & Pick<UploadLinksResponse, 'fileId' | 'link'>
  )> }
);

export type GroupQueryVariables = {
  groupId: Scalars['ID'];
};


export type GroupQuery = (
  { __typename?: 'Query' }
  & { Group: (
    { __typename?: 'GroupMembers' }
    & Pick<GroupMembers, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email'>
    )> }
  ) }
);

export type GroupsQueryVariables = {};


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { Groups: Array<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'members' | 'organization'>
  )> }
);

export type ServicesAssignedToGroupForTimelineQueryVariables = {
  groupId: Scalars['ID'];
};


export type ServicesAssignedToGroupForTimelineQuery = (
  { __typename?: 'Query' }
  & { ServicesAssignedToGroupForTimeline: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'endDate' | 'startDate' | 'baseLine' | 'baseLineSize' | 'name' | 'description'>
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'groupIds' | 'userIds'>
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'groupIds' | 'userIds'>
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type ProjectUsersQueryVariables = {
  projectId: Scalars['ID'];
};


export type ProjectUsersQuery = (
  { __typename?: 'Query' }
  & { ProjectUsers: Array<Maybe<(
    { __typename?: 'ProjectUser' }
    & Pick<ProjectUser, 'id' | 'name' | 'avatar' | 'email' | 'group' | 'role'>
  )>> }
);

export type ProjectWithAllSamplesQueryVariables = {
  id: Scalars['ID'];
};


export type ProjectWithAllSamplesQuery = (
  { __typename?: 'Query' }
  & { ProjectWithAllSamples: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, selectedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, price?: Maybe<(
            { __typename?: 'ActualEntityPrice' }
            & Pick<ActualEntityPrice, 'currency'>
            & { price: (
              { __typename?: 'CurrencyValue' }
              & Pick<CurrencyValue, 'value'>
            ) }
          )>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )>, collaborators?: Maybe<(
            { __typename?: 'Collaborators' }
            & Pick<Collaborators, 'userIds' | 'groupIds'>
          )> }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'SampleUser' }
        & Pick<SampleUser, 'id' | 'role'>
      )>>>, timelineLines?: Maybe<Array<Maybe<(
        { __typename?: 'TimelineLine' }
        & Pick<TimelineLine, 'line' | 'name'>
      )>>> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )> }
  ) }
);

export type ServicesQueryVariables = {};


export type ServicesQuery = (
  { __typename?: 'Query' }
  & { Services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'status' | 'serviceType' | 'durationLocked' | 'order' | 'hiddenOnTheList'>
    & { offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>> }
  )> }
);

export type MultiAnswerFragment = (
  { __typename?: 'MultiAnswer' }
  & Pick<MultiAnswer, 'answerType'>
  & { multiBody: MultiAnswer['body'] }
);

export type TextAnswerFragment = (
  { __typename?: 'TextAnswer' }
  & Pick<TextAnswer, 'answerType'>
  & { textBody: TextAnswer['body'] }
);

export type NumericAnswerFragment = (
  { __typename?: 'NumericAnswer' }
  & Pick<NumericAnswer, 'answerType'>
  & { numericBody: NumericAnswer['body'] }
);

export type UserProjectsQueryVariables = {};


export type UserProjectsQuery = (
  { __typename?: 'Query' }
  & { Projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'lastActivity'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, selectedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'assignee' | 'priority' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, collaborators?: Maybe<(
            { __typename?: 'Collaborators' }
            & Pick<Collaborators, 'userIds' | 'groupIds'>
          )>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'groupIds' | 'userIds'>
        )> }
      )>, assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'SampleUser' }
        & Pick<SampleUser, 'id' | 'role'>
      )>>>, timelineLines?: Maybe<Array<Maybe<(
        { __typename?: 'TimelineLine' }
        & Pick<TimelineLine, 'line' | 'name'>
      )>>> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )> }
  )> }
);

export type UserPlainProjectsQueryVariables = {};


export type UserPlainProjectsQuery = (
  { __typename?: 'Query' }
  & { Projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & { questions: Array<(
          { __typename?: 'Question' }
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name'>
      & { assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'SampleUser' }
        & Pick<SampleUser, 'id' | 'role'>
      )>>> }
    )> }
  )> }
);

export type UserPlainProjectsWithSamplesQueryVariables = {};


export type UserPlainProjectsWithSamplesQuery = (
  { __typename?: 'Query' }
  & { Projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'lastActivity'>
    & { currentService?: Maybe<(
      { __typename?: 'CurrentService' }
      & Pick<CurrentService, 'serviceName' | 'sampleName' | 'weekRemaining'>
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & { services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'status'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & { questions: Array<(
            { __typename?: 'Question' }
            & { answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'SampleUser' }
        & Pick<SampleUser, 'id' | 'role'>
      )>>> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & { questions: Array<(
          { __typename?: 'Question' }
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type UserProjectQueryVariables = {
  id: Scalars['ID'];
};


export type UserProjectQuery = (
  { __typename?: 'Query' }
  & { Project: (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ), visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )> }
        )> }
      )> }
    )>, samples: Array<(
      { __typename?: 'Sample' }
      & Pick<Sample, 'id' | 'name' | 'status'>
      & { events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
        & { collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, selectedQuestionGroups: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, suggestedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, selectedWorkflows: Array<(
        { __typename?: 'Workflow' }
        & Pick<Workflow, 'name' | 'id'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, questionGroupsToAddToSample: Array<(
          { __typename?: 'QuestionGroup' }
          & Pick<QuestionGroup, 'id' | 'name'>
          & { questionSets: Array<(
            { __typename?: 'QuestionSet' }
            & Pick<QuestionSet, 'id' | 'renderer'>
            & { questions: Array<(
              { __typename?: 'Question' }
              & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
              & { visibilityPreconditions: Array<(
                { __typename?: 'QuestionVisibilityPrecondition' }
                & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                & { expectedAnswer?: Maybe<(
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                )> }
              )>, answer: (
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              ) }
            )> }
          )> }
        )>, services: Array<(
          { __typename?: 'Service' }
          & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
          & { vendors: Array<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )>, enabledWorkflows: Array<(
        { __typename?: 'WorkflowForCustomer' }
        & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee' | 'description'>
        & { offerPrice?: Maybe<Array<(
          { __typename?: 'OfferedEntityPrice' }
          & Pick<OfferedEntityPrice, 'currency'>
          & { maxPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ), minPrice: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>>, price?: Maybe<(
          { __typename?: 'ActualEntityPrice' }
          & Pick<ActualEntityPrice, 'currency'>
          & { price: (
            { __typename?: 'CurrencyValue' }
            & Pick<CurrencyValue, 'value'>
          ) }
        )>, services: Array<(
          { __typename?: 'ServiceForCustomer' }
          & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
          & { scientists?: Maybe<Array<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'name' | 'organization'>
            & { preferences: (
              { __typename?: 'UserPreferences' }
              & Pick<UserPreferences, 'preferredCurrency'>
            ), schedule?: Maybe<(
              { __typename?: 'Schedule' }
              & Pick<Schedule, 'id'>
            )> }
          )>>, price?: Maybe<(
            { __typename?: 'ActualEntityPrice' }
            & Pick<ActualEntityPrice, 'currency'>
            & { price: (
              { __typename?: 'CurrencyValue' }
              & Pick<CurrencyValue, 'value'>
            ) }
          )>, vendor?: Maybe<(
            { __typename?: 'Vendor' }
            & Pick<Vendor, 'id' | 'name'>
          )>, report?: Maybe<(
            { __typename?: 'ServiceReport' }
            & { selectedQuestionGroups: Array<(
              { __typename?: 'QuestionGroup' }
              & Pick<QuestionGroup, 'id' | 'name'>
              & { questionSets: Array<(
                { __typename?: 'QuestionSet' }
                & Pick<QuestionSet, 'id' | 'renderer'>
                & { questions: Array<(
                  { __typename?: 'Question' }
                  & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                  & { visibilityPreconditions: Array<(
                    { __typename?: 'QuestionVisibilityPrecondition' }
                    & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                    & { expectedAnswer?: Maybe<(
                      { __typename?: 'TextAnswer' }
                      & Pick<TextAnswer, 'answerType'>
                      & { textBody: TextAnswer['body'] }
                    ) | (
                      { __typename?: 'NumericAnswer' }
                      & Pick<NumericAnswer, 'answerType'>
                      & { numericBody: NumericAnswer['body'] }
                    ) | (
                      { __typename?: 'MultiAnswer' }
                      & Pick<MultiAnswer, 'answerType'>
                      & { multiBody: MultiAnswer['body'] }
                    )> }
                  )>, answer: (
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  ) }
                )> }
              )> }
            )> }
          )>, collaborators?: Maybe<(
            { __typename?: 'Collaborators' }
            & Pick<Collaborators, 'userIds' | 'groupIds'>
          )> }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'userIds' | 'groupIds'>
        )> }
      )>, assignedUsers?: Maybe<Array<Maybe<(
        { __typename?: 'SampleUser' }
        & Pick<SampleUser, 'id' | 'role'>
      )>>>, timelineLines?: Maybe<Array<Maybe<(
        { __typename?: 'TimelineLine' }
        & Pick<TimelineLine, 'line' | 'name'>
      )>>> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'organization'>
      & { schedule?: Maybe<(
        { __typename?: 'Schedule' }
        & Pick<Schedule, 'id'>
      )>, preferences: (
        { __typename?: 'UserPreferences' }
        & Pick<UserPreferences, 'preferredCurrency'>
      ) }
    )>, visibleTimelineElements?: Maybe<(
      { __typename?: 'VisibleTimelineElements' }
      & { samples?: Maybe<Array<Maybe<(
        { __typename?: 'SimplifiedSample' }
        & Pick<SimplifiedSample, 'id' | 'events'>
        & { workflows?: Maybe<Array<Maybe<(
          { __typename?: 'SimplifiedWorkflow' }
          & Pick<SimplifiedWorkflow, 'id' | 'services'>
        )>>> }
      )>>> }
    )> }
  ) }
);

export type WorkflowQueryVariables = {};


export type WorkflowQuery = (
  { __typename?: 'Query' }
  & { Workflows: Array<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'name'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'status' | 'serviceType' | 'durationLocked' | 'order'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>> }
    )>, offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, questionGroupsToAddToSample: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
    )> }
  )> }
);

export type GetAuth0RolesQueryVariables = {};


export type GetAuth0RolesQuery = (
  { __typename?: 'Query' }
  & { GetAuth0Roles: Array<(
    { __typename?: 'Auth0Role' }
    & Pick<Auth0Role, 'id' | 'name' | 'description'>
  )> }
);

export type ListUsersQueryVariables = {};


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { ListUsers?: Maybe<Array<(
    { __typename?: 'Auth0User' }
    & Pick<Auth0User, 'id' | 'email' | 'name' | 'avatar' | 'organization'>
    & { role?: Maybe<(
      { __typename?: 'Auth0Role' }
      & Pick<Auth0Role, 'id' | 'name' | 'description'>
    )> }
  )>> }
);

export type SearchUsersQueryVariables = {
  searchText: Scalars['String'];
};


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { SearchUsers?: Maybe<Array<(
    { __typename?: 'Auth0User' }
    & Pick<Auth0User, 'id' | 'email' | 'name' | 'avatar' | 'organization'>
    & { role?: Maybe<(
      { __typename?: 'Auth0Role' }
      & Pick<Auth0Role, 'id' | 'name' | 'description'>
    )> }
  )>> }
);

export type ServicesAssignedToUserForTimelineQueryVariables = {
  userId: Scalars['ID'];
};


export type ServicesAssignedToUserForTimelineQuery = (
  { __typename?: 'Query' }
  & { ServicesAssignedToUserForTimeline: (
    { __typename?: 'Sample' }
    & Pick<Sample, 'id' | 'name' | 'status'>
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'priority' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'assignee' | 'milestone'>
      & { collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'userIds' | 'groupIds'>
      )> }
    )>, selectedQuestionGroups: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
      & { questionSets: Array<(
        { __typename?: 'QuestionSet' }
        & Pick<QuestionSet, 'id' | 'renderer'>
        & { questions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
          & { visibilityPreconditions: Array<(
            { __typename?: 'QuestionVisibilityPrecondition' }
            & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
            & { expectedAnswer?: Maybe<(
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            )> }
          )>, answer: (
            { __typename?: 'TextAnswer' }
            & Pick<TextAnswer, 'answerType'>
            & { textBody: TextAnswer['body'] }
          ) | (
            { __typename?: 'NumericAnswer' }
            & Pick<NumericAnswer, 'answerType'>
            & { numericBody: NumericAnswer['body'] }
          ) | (
            { __typename?: 'MultiAnswer' }
            & Pick<MultiAnswer, 'answerType'>
            & { multiBody: MultiAnswer['body'] }
          ) }
        )> }
      )> }
    )>, suggestedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, selectedWorkflows: Array<(
      { __typename?: 'Workflow' }
      & Pick<Workflow, 'name' | 'id'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, questionGroupsToAddToSample: Array<(
        { __typename?: 'QuestionGroup' }
        & Pick<QuestionGroup, 'id' | 'name'>
        & { questionSets: Array<(
          { __typename?: 'QuestionSet' }
          & Pick<QuestionSet, 'id' | 'renderer'>
          & { questions: Array<(
            { __typename?: 'Question' }
            & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
            & { visibilityPreconditions: Array<(
              { __typename?: 'QuestionVisibilityPrecondition' }
              & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
              & { expectedAnswer?: Maybe<(
                { __typename?: 'TextAnswer' }
                & Pick<TextAnswer, 'answerType'>
                & { textBody: TextAnswer['body'] }
              ) | (
                { __typename?: 'NumericAnswer' }
                & Pick<NumericAnswer, 'answerType'>
                & { numericBody: NumericAnswer['body'] }
              ) | (
                { __typename?: 'MultiAnswer' }
                & Pick<MultiAnswer, 'answerType'>
                & { multiBody: MultiAnswer['body'] }
              )> }
            )>, answer: (
              { __typename?: 'TextAnswer' }
              & Pick<TextAnswer, 'answerType'>
              & { textBody: TextAnswer['body'] }
            ) | (
              { __typename?: 'NumericAnswer' }
              & Pick<NumericAnswer, 'answerType'>
              & { numericBody: NumericAnswer['body'] }
            ) | (
              { __typename?: 'MultiAnswer' }
              & Pick<MultiAnswer, 'answerType'>
              & { multiBody: MultiAnswer['body'] }
            ) }
          )> }
        )> }
      )>, services: Array<(
        { __typename?: 'Service' }
        & Pick<Service, 'id' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'name' | 'serviceType' | 'durationLocked' | 'order'>
        & { vendors: Array<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    )>, enabledWorkflows: Array<(
      { __typename?: 'WorkflowForCustomer' }
      & Pick<WorkflowForCustomer, 'name' | 'id' | 'assignee'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>>, price?: Maybe<(
        { __typename?: 'ActualEntityPrice' }
        & Pick<ActualEntityPrice, 'currency'>
        & { price: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>, services: Array<(
        { __typename?: 'ServiceForCustomer' }
        & Pick<ServiceForCustomer, 'id' | 'name' | 'assignee' | 'priority' | 'startDate' | 'endDate' | 'baseLine' | 'baseLineSize' | 'status' | 'description' | 'serviceType' | 'durationLocked' | 'order'>
        & { scientists?: Maybe<Array<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'name' | 'organization'>
          & { preferences: (
            { __typename?: 'UserPreferences' }
            & Pick<UserPreferences, 'preferredCurrency'>
          ), schedule?: Maybe<(
            { __typename?: 'Schedule' }
            & Pick<Schedule, 'id'>
          )> }
        )>>, vendor?: Maybe<(
          { __typename?: 'Vendor' }
          & Pick<Vendor, 'id' | 'name'>
        )>, report?: Maybe<(
          { __typename?: 'ServiceReport' }
          & { selectedQuestionGroups: Array<(
            { __typename?: 'QuestionGroup' }
            & Pick<QuestionGroup, 'id' | 'name'>
            & { questionSets: Array<(
              { __typename?: 'QuestionSet' }
              & Pick<QuestionSet, 'id' | 'renderer'>
              & { questions: Array<(
                { __typename?: 'Question' }
                & Pick<Question, 'id' | 'question' | 'description' | 'choices' | 'order' | 'questionLevel' | 'isObligatory' | 'visibility' | 'placeholder'>
                & { visibilityPreconditions: Array<(
                  { __typename?: 'QuestionVisibilityPrecondition' }
                  & Pick<QuestionVisibilityPrecondition, 'isSatisfied' | 'questionId'>
                  & { expectedAnswer?: Maybe<(
                    { __typename?: 'TextAnswer' }
                    & Pick<TextAnswer, 'answerType'>
                    & { textBody: TextAnswer['body'] }
                  ) | (
                    { __typename?: 'NumericAnswer' }
                    & Pick<NumericAnswer, 'answerType'>
                    & { numericBody: NumericAnswer['body'] }
                  ) | (
                    { __typename?: 'MultiAnswer' }
                    & Pick<MultiAnswer, 'answerType'>
                    & { multiBody: MultiAnswer['body'] }
                  )> }
                )>, answer: (
                  { __typename?: 'TextAnswer' }
                  & Pick<TextAnswer, 'answerType'>
                  & { textBody: TextAnswer['body'] }
                ) | (
                  { __typename?: 'NumericAnswer' }
                  & Pick<NumericAnswer, 'answerType'>
                  & { numericBody: NumericAnswer['body'] }
                ) | (
                  { __typename?: 'MultiAnswer' }
                  & Pick<MultiAnswer, 'answerType'>
                  & { multiBody: MultiAnswer['body'] }
                ) }
              )> }
            )> }
          )> }
        )>, collaborators?: Maybe<(
          { __typename?: 'Collaborators' }
          & Pick<Collaborators, 'groupIds' | 'userIds'>
        )> }
      )>, collaborators?: Maybe<(
        { __typename?: 'Collaborators' }
        & Pick<Collaborators, 'groupIds' | 'userIds'>
      )> }
    )>, assignedUsers?: Maybe<Array<Maybe<(
      { __typename?: 'SampleUser' }
      & Pick<SampleUser, 'id' | 'role'>
    )>>>, timelineLines?: Maybe<Array<Maybe<(
      { __typename?: 'TimelineLine' }
      & Pick<TimelineLine, 'line' | 'name'>
    )>>> }
  ) }
);

export type WorkflowsByOrganizationQueryVariables = {
  organization: Scalars['String'];
};


export type WorkflowsByOrganizationQuery = (
  { __typename?: 'Query' }
  & { WorkflowsByOrganization: Array<Maybe<(
    { __typename?: 'Workflow' }
    & Pick<Workflow, 'id' | 'name'>
    & { services: Array<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name' | 'description' | 'startDate' | 'endDate' | 'baseLineSize' | 'baseLine' | 'status' | 'serviceType' | 'durationLocked' | 'order'>
      & { offerPrice?: Maybe<Array<(
        { __typename?: 'OfferedEntityPrice' }
        & Pick<OfferedEntityPrice, 'currency'>
        & { minPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ), maxPrice: (
          { __typename?: 'CurrencyValue' }
          & Pick<CurrencyValue, 'value'>
        ) }
      )>> }
    )>, offerPrice?: Maybe<Array<(
      { __typename?: 'OfferedEntityPrice' }
      & Pick<OfferedEntityPrice, 'currency'>
      & { minPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ), maxPrice: (
        { __typename?: 'CurrencyValue' }
        & Pick<CurrencyValue, 'value'>
      ) }
    )>>, questionGroupsToAddToSample: Array<(
      { __typename?: 'QuestionGroup' }
      & Pick<QuestionGroup, 'id' | 'name'>
    )> }
  )>> }
);

export const MultiAnswerFragmentDoc = gql`
    fragment MultiAnswer on MultiAnswer {
  multiBody: body
  answerType
}
    `;
export const TextAnswerFragmentDoc = gql`
    fragment TextAnswer on TextAnswer {
  textBody: body
  answerType
}
    `;
export const NumericAnswerFragmentDoc = gql`
    fragment NumericAnswer on NumericAnswer {
  numericBody: body
  answerType
}
    `;
export const MyQueryDocument = gql`
    query MyQuery {
  healthcheck
}
    `;
export type MyQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MyQueryQuery, MyQueryQueryVariables>, 'query'>;

    export const MyQueryComponent = (props: MyQueryComponentProps) => (
      <ApolloReactComponents.Query<MyQueryQuery, MyQueryQueryVariables> query={MyQueryDocument} {...props} />
    );
    

/**
 * __useMyQueryQuery__
 *
 * To run a query within a React component, call `useMyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyQueryQuery, MyQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<MyQueryQuery, MyQueryQueryVariables>(MyQueryDocument, baseOptions);
      }
export function useMyQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyQueryQuery, MyQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyQueryQuery, MyQueryQueryVariables>(MyQueryDocument, baseOptions);
        }
export type MyQueryQueryHookResult = ReturnType<typeof useMyQueryQuery>;
export type MyQueryLazyQueryHookResult = ReturnType<typeof useMyQueryLazyQuery>;
export type MyQueryQueryResult = ApolloReactCommon.QueryResult<MyQueryQuery, MyQueryQueryVariables>;
export const UpdateEventFromSampleDocument = gql`
    mutation UpdateEventFromSample($projectSampleEventInput: ProjectSampleEventInput!, $eventUpdatables: EventUpdatables!) {
  UpdateEventFromSample(
    projectSampleEventInput: $projectSampleEventInput
    eventUpdatables: $eventUpdatables
  ) {
    id
    priority
    name
    description
    startDate
    endDate
    baseLineSize
    baseLine
    assignee
    collaborators {
      userIds
      groupIds
    }
    milestone
  }
}
    `;
export type UpdateEventFromSampleMutationFn = ApolloReactCommon.MutationFunction<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables>;
export type UpdateEventFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables>, 'mutation'>;

    export const UpdateEventFromSampleComponent = (props: UpdateEventFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables> mutation={UpdateEventFromSampleDocument} {...props} />
    );
    

/**
 * __useUpdateEventFromSampleMutation__
 *
 * To run a mutation, you first call `useUpdateEventFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventFromSampleMutation, { data, loading, error }] = useUpdateEventFromSampleMutation({
 *   variables: {
 *      projectSampleEventInput: // value for 'projectSampleEventInput'
 *      eventUpdatables: // value for 'eventUpdatables'
 *   },
 * });
 */
export function useUpdateEventFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables>(UpdateEventFromSampleDocument, baseOptions);
      }
export type UpdateEventFromSampleMutationHookResult = ReturnType<typeof useUpdateEventFromSampleMutation>;
export type UpdateEventFromSampleMutationResult = ApolloReactCommon.MutationResult<UpdateEventFromSampleMutation>;
export type UpdateEventFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventFromSampleMutation, UpdateEventFromSampleMutationVariables>;
export const DeleteUploadedFileDocument = gql`
    mutation DeleteUploadedFile($fileName: String!) {
  DeleteUploadedFile(fileName: $fileName) {
    deleteStatus
    fileName
  }
}
    `;
export type DeleteUploadedFileMutationFn = ApolloReactCommon.MutationFunction<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables>;
export type DeleteUploadedFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables>, 'mutation'>;

    export const DeleteUploadedFileComponent = (props: DeleteUploadedFileComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables> mutation={DeleteUploadedFileDocument} {...props} />
    );
    

/**
 * __useDeleteUploadedFileMutation__
 *
 * To run a mutation, you first call `useDeleteUploadedFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadedFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadedFileMutation, { data, loading, error }] = useDeleteUploadedFileMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useDeleteUploadedFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables>(DeleteUploadedFileDocument, baseOptions);
      }
export type DeleteUploadedFileMutationHookResult = ReturnType<typeof useDeleteUploadedFileMutation>;
export type DeleteUploadedFileMutationResult = ApolloReactCommon.MutationResult<DeleteUploadedFileMutation>;
export type DeleteUploadedFileMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUploadedFileMutation, DeleteUploadedFileMutationVariables>;
export const AddGroupDocument = gql`
    mutation AddGroup($name: String!) {
  AddGroup(name: $name) {
    id
    members
    name
  }
}
    `;
export type AddGroupMutationFn = ApolloReactCommon.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;
export type AddGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddGroupMutation, AddGroupMutationVariables>, 'mutation'>;

    export const AddGroupComponent = (props: AddGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AddGroupMutation, AddGroupMutationVariables> mutation={AddGroupDocument} {...props} />
    );
    

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, baseOptions);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = ApolloReactCommon.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const AddUsersToGroupDocument = gql`
    mutation AddUsersToGroup($groupId: ID!, $usersIds: [ID!]!) {
  AddUsersToGroup(groupId: $groupId, usersIds: $usersIds) {
    id
    members
    name
  }
}
    `;
export type AddUsersToGroupMutationFn = ApolloReactCommon.MutationFunction<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>;
export type AddUsersToGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>, 'mutation'>;

    export const AddUsersToGroupComponent = (props: AddUsersToGroupComponentProps) => (
      <ApolloReactComponents.Mutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables> mutation={AddUsersToGroupDocument} {...props} />
    );
    

/**
 * __useAddUsersToGroupMutation__
 *
 * To run a mutation, you first call `useAddUsersToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToGroupMutation, { data, loading, error }] = useAddUsersToGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      usersIds: // value for 'usersIds'
 *   },
 * });
 */
export function useAddUsersToGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>(AddUsersToGroupDocument, baseOptions);
      }
export type AddUsersToGroupMutationHookResult = ReturnType<typeof useAddUsersToGroupMutation>;
export type AddUsersToGroupMutationResult = ApolloReactCommon.MutationResult<AddUsersToGroupMutation>;
export type AddUsersToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUsersToGroupMutation, AddUsersToGroupMutationVariables>;
export const EditGroupNameDocument = gql`
    mutation EditGroupName($groupId: ID!, $name: String!) {
  EditGroupName(groupId: $groupId, name: $name) {
    id
    members
    name
  }
}
    `;
export type EditGroupNameMutationFn = ApolloReactCommon.MutationFunction<EditGroupNameMutation, EditGroupNameMutationVariables>;
export type EditGroupNameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditGroupNameMutation, EditGroupNameMutationVariables>, 'mutation'>;

    export const EditGroupNameComponent = (props: EditGroupNameComponentProps) => (
      <ApolloReactComponents.Mutation<EditGroupNameMutation, EditGroupNameMutationVariables> mutation={EditGroupNameDocument} {...props} />
    );
    

/**
 * __useEditGroupNameMutation__
 *
 * To run a mutation, you first call `useEditGroupNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupNameMutation, { data, loading, error }] = useEditGroupNameMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditGroupNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditGroupNameMutation, EditGroupNameMutationVariables>) {
        return ApolloReactHooks.useMutation<EditGroupNameMutation, EditGroupNameMutationVariables>(EditGroupNameDocument, baseOptions);
      }
export type EditGroupNameMutationHookResult = ReturnType<typeof useEditGroupNameMutation>;
export type EditGroupNameMutationResult = ApolloReactCommon.MutationResult<EditGroupNameMutation>;
export type EditGroupNameMutationOptions = ApolloReactCommon.BaseMutationOptions<EditGroupNameMutation, EditGroupNameMutationVariables>;
export const RemoveGroupDocument = gql`
    mutation RemoveGroup($groupId: ID!) {
  RemoveGroup(groupId: $groupId) {
    id
    members
    name
  }
}
    `;
export type RemoveGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveGroupMutation, RemoveGroupMutationVariables>;
export type RemoveGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveGroupMutation, RemoveGroupMutationVariables>, 'mutation'>;

    export const RemoveGroupComponent = (props: RemoveGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveGroupMutation, RemoveGroupMutationVariables> mutation={RemoveGroupDocument} {...props} />
    );
    

/**
 * __useRemoveGroupMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMutation, { data, loading, error }] = useRemoveGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useRemoveGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveGroupMutation, RemoveGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveGroupMutation, RemoveGroupMutationVariables>(RemoveGroupDocument, baseOptions);
      }
export type RemoveGroupMutationHookResult = ReturnType<typeof useRemoveGroupMutation>;
export type RemoveGroupMutationResult = ApolloReactCommon.MutationResult<RemoveGroupMutation>;
export type RemoveGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveGroupMutation, RemoveGroupMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation RemoveUserFromGroup($groupId: ID!, $userId: ID!) {
  RemoveUserFromGroup(groupId: $groupId, userId: $userId) {
    id
    members
    name
  }
}
    `;
export type RemoveUserFromGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export type RemoveUserFromGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>, 'mutation'>;

    export const RemoveUserFromGroupComponent = (props: RemoveUserFromGroupComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables> mutation={RemoveUserFromGroupDocument} {...props} />
    );
    

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, baseOptions);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = ApolloReactCommon.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const NewProjectDocument = gql`
    mutation NewProject {
  AddProject {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        services {
          id
          name
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          description
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      status
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
  }
}
    `;
export type NewProjectMutationFn = ApolloReactCommon.MutationFunction<NewProjectMutation, NewProjectMutationVariables>;
export type NewProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<NewProjectMutation, NewProjectMutationVariables>, 'mutation'>;

    export const NewProjectComponent = (props: NewProjectComponentProps) => (
      <ApolloReactComponents.Mutation<NewProjectMutation, NewProjectMutationVariables> mutation={NewProjectDocument} {...props} />
    );
    

/**
 * __useNewProjectMutation__
 *
 * To run a mutation, you first call `useNewProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newProjectMutation, { data, loading, error }] = useNewProjectMutation({
 *   variables: {
 *   },
 * });
 */
export function useNewProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewProjectMutation, NewProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<NewProjectMutation, NewProjectMutationVariables>(NewProjectDocument, baseOptions);
      }
export type NewProjectMutationHookResult = ReturnType<typeof useNewProjectMutation>;
export type NewProjectMutationResult = ApolloReactCommon.MutationResult<NewProjectMutation>;
export type NewProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<NewProjectMutation, NewProjectMutationVariables>;
export const AddSampleToProjectDocument = gql`
    mutation AddSampleToProject($projectSampleInput: ProjectInput!) {
  AddSampleToProject(projectSampleInput: $projectSampleInput) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        order
        durationLocked
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      description
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        durationLocked
        price {
          currency
          price {
            value
          }
        }
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type AddSampleToProjectMutationFn = ApolloReactCommon.MutationFunction<AddSampleToProjectMutation, AddSampleToProjectMutationVariables>;
export type AddSampleToProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddSampleToProjectMutation, AddSampleToProjectMutationVariables>, 'mutation'>;

    export const AddSampleToProjectComponent = (props: AddSampleToProjectComponentProps) => (
      <ApolloReactComponents.Mutation<AddSampleToProjectMutation, AddSampleToProjectMutationVariables> mutation={AddSampleToProjectDocument} {...props} />
    );
    

/**
 * __useAddSampleToProjectMutation__
 *
 * To run a mutation, you first call `useAddSampleToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSampleToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSampleToProjectMutation, { data, loading, error }] = useAddSampleToProjectMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *   },
 * });
 */
export function useAddSampleToProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSampleToProjectMutation, AddSampleToProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSampleToProjectMutation, AddSampleToProjectMutationVariables>(AddSampleToProjectDocument, baseOptions);
      }
export type AddSampleToProjectMutationHookResult = ReturnType<typeof useAddSampleToProjectMutation>;
export type AddSampleToProjectMutationResult = ApolloReactCommon.MutationResult<AddSampleToProjectMutation>;
export type AddSampleToProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSampleToProjectMutation, AddSampleToProjectMutationVariables>;
export const ProjectWithMultiAnswerDocument = gql`
    mutation ProjectWithMultiAnswer($projectQuestionInput: ProjectQuestionInput!, $answer: [String!]!) {
  AnswerProjectMultiQuestion(
    projectQuestionInput: $projectQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectWithMultiAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables>;
export type ProjectWithMultiAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables>, 'mutation'>;

    export const ProjectWithMultiAnswerComponent = (props: ProjectWithMultiAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables> mutation={ProjectWithMultiAnswerDocument} {...props} />
    );
    

/**
 * __useProjectWithMultiAnswerMutation__
 *
 * To run a mutation, you first call `useProjectWithMultiAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectWithMultiAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectWithMultiAnswerMutation, { data, loading, error }] = useProjectWithMultiAnswerMutation({
 *   variables: {
 *      projectQuestionInput: // value for 'projectQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectWithMultiAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables>(ProjectWithMultiAnswerDocument, baseOptions);
      }
export type ProjectWithMultiAnswerMutationHookResult = ReturnType<typeof useProjectWithMultiAnswerMutation>;
export type ProjectWithMultiAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectWithMultiAnswerMutation>;
export type ProjectWithMultiAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectWithMultiAnswerMutation, ProjectWithMultiAnswerMutationVariables>;
export const ProjectWithNumericAnswerDocument = gql`
    mutation ProjectWithNumericAnswer($projectQuestionInput: ProjectQuestionInput!, $answer: Float!) {
  AnswerProjectNumericQuestion(
    projectQuestionInput: $projectQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectWithNumericAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables>;
export type ProjectWithNumericAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables>, 'mutation'>;

    export const ProjectWithNumericAnswerComponent = (props: ProjectWithNumericAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables> mutation={ProjectWithNumericAnswerDocument} {...props} />
    );
    

/**
 * __useProjectWithNumericAnswerMutation__
 *
 * To run a mutation, you first call `useProjectWithNumericAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectWithNumericAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectWithNumericAnswerMutation, { data, loading, error }] = useProjectWithNumericAnswerMutation({
 *   variables: {
 *      projectQuestionInput: // value for 'projectQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectWithNumericAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables>(ProjectWithNumericAnswerDocument, baseOptions);
      }
export type ProjectWithNumericAnswerMutationHookResult = ReturnType<typeof useProjectWithNumericAnswerMutation>;
export type ProjectWithNumericAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectWithNumericAnswerMutation>;
export type ProjectWithNumericAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectWithNumericAnswerMutation, ProjectWithNumericAnswerMutationVariables>;
export const ProjectWithTextAnswerDocument = gql`
    mutation ProjectWithTextAnswer($projectQuestionInput: ProjectQuestionInput!, $answer: String!) {
  AnswerProjectQuestion(
    projectQuestionInput: $projectQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectWithTextAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables>;
export type ProjectWithTextAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables>, 'mutation'>;

    export const ProjectWithTextAnswerComponent = (props: ProjectWithTextAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables> mutation={ProjectWithTextAnswerDocument} {...props} />
    );
    

/**
 * __useProjectWithTextAnswerMutation__
 *
 * To run a mutation, you first call `useProjectWithTextAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectWithTextAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectWithTextAnswerMutation, { data, loading, error }] = useProjectWithTextAnswerMutation({
 *   variables: {
 *      projectQuestionInput: // value for 'projectQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectWithTextAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables>(ProjectWithTextAnswerDocument, baseOptions);
      }
export type ProjectWithTextAnswerMutationHookResult = ReturnType<typeof useProjectWithTextAnswerMutation>;
export type ProjectWithTextAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectWithTextAnswerMutation>;
export type ProjectWithTextAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectWithTextAnswerMutation, ProjectWithTextAnswerMutationVariables>;
export const ProjectSampleWithMultiAnswerDocument = gql`
    mutation ProjectSampleWithMultiAnswer($projectSampleQuestionInput: ProjectSampleQuestionInput!, $answer: [String!]!) {
  AnswerProjectSampleMultiQuestion(
    projectSampleQuestionInput: $projectSampleQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectSampleWithMultiAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables>;
export type ProjectSampleWithMultiAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables>, 'mutation'>;

    export const ProjectSampleWithMultiAnswerComponent = (props: ProjectSampleWithMultiAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables> mutation={ProjectSampleWithMultiAnswerDocument} {...props} />
    );
    

/**
 * __useProjectSampleWithMultiAnswerMutation__
 *
 * To run a mutation, you first call `useProjectSampleWithMultiAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSampleWithMultiAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSampleWithMultiAnswerMutation, { data, loading, error }] = useProjectSampleWithMultiAnswerMutation({
 *   variables: {
 *      projectSampleQuestionInput: // value for 'projectSampleQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectSampleWithMultiAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables>(ProjectSampleWithMultiAnswerDocument, baseOptions);
      }
export type ProjectSampleWithMultiAnswerMutationHookResult = ReturnType<typeof useProjectSampleWithMultiAnswerMutation>;
export type ProjectSampleWithMultiAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectSampleWithMultiAnswerMutation>;
export type ProjectSampleWithMultiAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectSampleWithMultiAnswerMutation, ProjectSampleWithMultiAnswerMutationVariables>;
export const ProjectSampleWithNumericAnswerDocument = gql`
    mutation ProjectSampleWithNumericAnswer($projectSampleQuestionInput: ProjectSampleQuestionInput!, $answer: Float!) {
  AnswerProjectSampleNumericQuestion(
    projectSampleQuestionInput: $projectSampleQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectSampleWithNumericAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables>;
export type ProjectSampleWithNumericAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables>, 'mutation'>;

    export const ProjectSampleWithNumericAnswerComponent = (props: ProjectSampleWithNumericAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables> mutation={ProjectSampleWithNumericAnswerDocument} {...props} />
    );
    

/**
 * __useProjectSampleWithNumericAnswerMutation__
 *
 * To run a mutation, you first call `useProjectSampleWithNumericAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSampleWithNumericAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSampleWithNumericAnswerMutation, { data, loading, error }] = useProjectSampleWithNumericAnswerMutation({
 *   variables: {
 *      projectSampleQuestionInput: // value for 'projectSampleQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectSampleWithNumericAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables>(ProjectSampleWithNumericAnswerDocument, baseOptions);
      }
export type ProjectSampleWithNumericAnswerMutationHookResult = ReturnType<typeof useProjectSampleWithNumericAnswerMutation>;
export type ProjectSampleWithNumericAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectSampleWithNumericAnswerMutation>;
export type ProjectSampleWithNumericAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectSampleWithNumericAnswerMutation, ProjectSampleWithNumericAnswerMutationVariables>;
export const ProjectSampleWithTextAnswerDocument = gql`
    mutation ProjectSampleWithTextAnswer($projectSampleQuestionInput: ProjectSampleQuestionInput!, $answer: String!) {
  AnswerProjectSampleQuestion(
    projectSampleQuestionInput: $projectSampleQuestionInput
    answer: $answer
  ) {
    id
  }
}
    `;
export type ProjectSampleWithTextAnswerMutationFn = ApolloReactCommon.MutationFunction<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables>;
export type ProjectSampleWithTextAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables>, 'mutation'>;

    export const ProjectSampleWithTextAnswerComponent = (props: ProjectSampleWithTextAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables> mutation={ProjectSampleWithTextAnswerDocument} {...props} />
    );
    

/**
 * __useProjectSampleWithTextAnswerMutation__
 *
 * To run a mutation, you first call `useProjectSampleWithTextAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSampleWithTextAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSampleWithTextAnswerMutation, { data, loading, error }] = useProjectSampleWithTextAnswerMutation({
 *   variables: {
 *      projectSampleQuestionInput: // value for 'projectSampleQuestionInput'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useProjectSampleWithTextAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables>(ProjectSampleWithTextAnswerDocument, baseOptions);
      }
export type ProjectSampleWithTextAnswerMutationHookResult = ReturnType<typeof useProjectSampleWithTextAnswerMutation>;
export type ProjectSampleWithTextAnswerMutationResult = ApolloReactCommon.MutationResult<ProjectSampleWithTextAnswerMutation>;
export type ProjectSampleWithTextAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectSampleWithTextAnswerMutation, ProjectSampleWithTextAnswerMutationVariables>;
export const AssignUsersToProjectDocument = gql`
    mutation AssignUsersToProject($projectId: ID!, $usersAuth0Ids: [String!]!) {
  AssignUsersToProject(projectId: $projectId, usersAuth0Ids: $usersAuth0Ids)
}
    `;
export type AssignUsersToProjectMutationFn = ApolloReactCommon.MutationFunction<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables>;
export type AssignUsersToProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables>, 'mutation'>;

    export const AssignUsersToProjectComponent = (props: AssignUsersToProjectComponentProps) => (
      <ApolloReactComponents.Mutation<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables> mutation={AssignUsersToProjectDocument} {...props} />
    );
    

/**
 * __useAssignUsersToProjectMutation__
 *
 * To run a mutation, you first call `useAssignUsersToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUsersToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUsersToProjectMutation, { data, loading, error }] = useAssignUsersToProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      usersAuth0Ids: // value for 'usersAuth0Ids'
 *   },
 * });
 */
export function useAssignUsersToProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables>(AssignUsersToProjectDocument, baseOptions);
      }
export type AssignUsersToProjectMutationHookResult = ReturnType<typeof useAssignUsersToProjectMutation>;
export type AssignUsersToProjectMutationResult = ApolloReactCommon.MutationResult<AssignUsersToProjectMutation>;
export type AssignUsersToProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignUsersToProjectMutation, AssignUsersToProjectMutationVariables>;
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($projectId: ID!) {
  DuplicateProject(projectId: $projectId) {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        services {
          id
          name
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          description
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      status
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
    visibleTimelineElements {
      samples {
        id
        workflows {
          id
          services
        }
        events
      }
    }
  }
}
    `;
export type DuplicateProjectMutationFn = ApolloReactCommon.MutationFunction<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export type DuplicateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>, 'mutation'>;

    export const DuplicateProjectComponent = (props: DuplicateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicateProjectMutation, DuplicateProjectMutationVariables> mutation={DuplicateProjectDocument} {...props} />
    );
    

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDuplicateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument, baseOptions);
      }
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>;
export type DuplicateProjectMutationResult = ApolloReactCommon.MutationResult<DuplicateProjectMutation>;
export type DuplicateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateProjectMutation, DuplicateProjectMutationVariables>;
export const MakeTimelineElementVisibleDocument = gql`
    mutation MakeTimelineElementVisible($timelineElementVisibleInput: TimelineElementVisibleInput!) {
  MakeTimelineElementVisible(
    timelineElementVisibleInput: $timelineElementVisibleInput
  ) {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        services {
          id
          name
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          description
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      status
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
    visibleTimelineElements {
      samples {
        id
        workflows {
          id
          services
        }
        events
      }
    }
  }
}
    `;
export type MakeTimelineElementVisibleMutationFn = ApolloReactCommon.MutationFunction<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables>;
export type MakeTimelineElementVisibleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables>, 'mutation'>;

    export const MakeTimelineElementVisibleComponent = (props: MakeTimelineElementVisibleComponentProps) => (
      <ApolloReactComponents.Mutation<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables> mutation={MakeTimelineElementVisibleDocument} {...props} />
    );
    

/**
 * __useMakeTimelineElementVisibleMutation__
 *
 * To run a mutation, you first call `useMakeTimelineElementVisibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeTimelineElementVisibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeTimelineElementVisibleMutation, { data, loading, error }] = useMakeTimelineElementVisibleMutation({
 *   variables: {
 *      timelineElementVisibleInput: // value for 'timelineElementVisibleInput'
 *   },
 * });
 */
export function useMakeTimelineElementVisibleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables>(MakeTimelineElementVisibleDocument, baseOptions);
      }
export type MakeTimelineElementVisibleMutationHookResult = ReturnType<typeof useMakeTimelineElementVisibleMutation>;
export type MakeTimelineElementVisibleMutationResult = ApolloReactCommon.MutationResult<MakeTimelineElementVisibleMutation>;
export type MakeTimelineElementVisibleMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeTimelineElementVisibleMutation, MakeTimelineElementVisibleMutationVariables>;
export const RemoveProjectDocument = gql`
    mutation RemoveProject($projectId: ID!) {
  RemoveProject(projectId: $projectId)
}
    `;
export type RemoveProjectMutationFn = ApolloReactCommon.MutationFunction<RemoveProjectMutation, RemoveProjectMutationVariables>;
export type RemoveProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveProjectMutation, RemoveProjectMutationVariables>, 'mutation'>;

    export const RemoveProjectComponent = (props: RemoveProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveProjectMutation, RemoveProjectMutationVariables> mutation={RemoveProjectDocument} {...props} />
    );
    

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProjectMutation, RemoveProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveProjectMutation, RemoveProjectMutationVariables>(RemoveProjectDocument, baseOptions);
      }
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = ApolloReactCommon.MutationResult<RemoveProjectMutation>;
export type RemoveProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProjectMutation, RemoveProjectMutationVariables>;
export const RemoveUserFromProjectDocument = gql`
    mutation RemoveUserFromProject($projectId: ID!, $userAuth0Id: String!) {
  RemoveUserFromProject(projectId: $projectId, userAuth0Id: $userAuth0Id)
}
    `;
export type RemoveUserFromProjectMutationFn = ApolloReactCommon.MutationFunction<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables>;
export type RemoveUserFromProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables>, 'mutation'>;

    export const RemoveUserFromProjectComponent = (props: RemoveUserFromProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables> mutation={RemoveUserFromProjectDocument} {...props} />
    );
    

/**
 * __useRemoveUserFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromProjectMutation, { data, loading, error }] = useRemoveUserFromProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userAuth0Id: // value for 'userAuth0Id'
 *   },
 * });
 */
export function useRemoveUserFromProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables>(RemoveUserFromProjectDocument, baseOptions);
      }
export type RemoveUserFromProjectMutationHookResult = ReturnType<typeof useRemoveUserFromProjectMutation>;
export type RemoveUserFromProjectMutationResult = ApolloReactCommon.MutationResult<RemoveUserFromProjectMutation>;
export type RemoveUserFromProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserFromProjectMutation, RemoveUserFromProjectMutationVariables>;
export const SetProjectSampleWorkflowServiceTimelineDocument = gql`
    mutation SetProjectSampleWorkflowServiceTimeline($projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput!, $timeline: TimelineInput!) {
  SetProjectSampleWorkflowServiceTimeline(
    projectSampleWorkflowServiceInput: $projectSampleWorkflowServiceInput
    timeline: $timeline
  ) {
    id
  }
}
    `;
export type SetProjectSampleWorkflowServiceTimelineMutationFn = ApolloReactCommon.MutationFunction<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables>;
export type SetProjectSampleWorkflowServiceTimelineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables>, 'mutation'>;

    export const SetProjectSampleWorkflowServiceTimelineComponent = (props: SetProjectSampleWorkflowServiceTimelineComponentProps) => (
      <ApolloReactComponents.Mutation<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables> mutation={SetProjectSampleWorkflowServiceTimelineDocument} {...props} />
    );
    

/**
 * __useSetProjectSampleWorkflowServiceTimelineMutation__
 *
 * To run a mutation, you first call `useSetProjectSampleWorkflowServiceTimelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectSampleWorkflowServiceTimelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectSampleWorkflowServiceTimelineMutation, { data, loading, error }] = useSetProjectSampleWorkflowServiceTimelineMutation({
 *   variables: {
 *      projectSampleWorkflowServiceInput: // value for 'projectSampleWorkflowServiceInput'
 *      timeline: // value for 'timeline'
 *   },
 * });
 */
export function useSetProjectSampleWorkflowServiceTimelineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables>) {
        return ApolloReactHooks.useMutation<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables>(SetProjectSampleWorkflowServiceTimelineDocument, baseOptions);
      }
export type SetProjectSampleWorkflowServiceTimelineMutationHookResult = ReturnType<typeof useSetProjectSampleWorkflowServiceTimelineMutation>;
export type SetProjectSampleWorkflowServiceTimelineMutationResult = ApolloReactCommon.MutationResult<SetProjectSampleWorkflowServiceTimelineMutation>;
export type SetProjectSampleWorkflowServiceTimelineMutationOptions = ApolloReactCommon.BaseMutationOptions<SetProjectSampleWorkflowServiceTimelineMutation, SetProjectSampleWorkflowServiceTimelineMutationVariables>;
export const AddEventToSampleDocument = gql`
    mutation AddEventToSample($projectSampleInput: ProjectSampleInput!, $eventId: ID!, $startDate: String!, $baseline: Int) {
  AddEventToSample(
    projectSampleInput: $projectSampleInput
    eventId: $eventId
    startDate: $startDate
    baseline: $baseline
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        price {
          currency
          price {
            value
          }
        }
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type AddEventToSampleMutationFn = ApolloReactCommon.MutationFunction<AddEventToSampleMutation, AddEventToSampleMutationVariables>;
export type AddEventToSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddEventToSampleMutation, AddEventToSampleMutationVariables>, 'mutation'>;

    export const AddEventToSampleComponent = (props: AddEventToSampleComponentProps) => (
      <ApolloReactComponents.Mutation<AddEventToSampleMutation, AddEventToSampleMutationVariables> mutation={AddEventToSampleDocument} {...props} />
    );
    

/**
 * __useAddEventToSampleMutation__
 *
 * To run a mutation, you first call `useAddEventToSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventToSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventToSampleMutation, { data, loading, error }] = useAddEventToSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      eventId: // value for 'eventId'
 *      startDate: // value for 'startDate'
 *      baseline: // value for 'baseline'
 *   },
 * });
 */
export function useAddEventToSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEventToSampleMutation, AddEventToSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<AddEventToSampleMutation, AddEventToSampleMutationVariables>(AddEventToSampleDocument, baseOptions);
      }
export type AddEventToSampleMutationHookResult = ReturnType<typeof useAddEventToSampleMutation>;
export type AddEventToSampleMutationResult = ApolloReactCommon.MutationResult<AddEventToSampleMutation>;
export type AddEventToSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddEventToSampleMutation, AddEventToSampleMutationVariables>;
export const AddQuestionGroupToSampleDocument = gql`
    mutation AddQuestionGroupToSample($projectSampleInput: ProjectSampleInput!) {
  AddQuestionGroupToSample(projectSampleInput: $projectSampleInput) {
    id
    name
    questionSets {
      id
      apiLink
      renderer
      questions {
        id
        question
        description
        choices
        order
        questionLevel
        isObligatory
        answer {
          ... on MultiAnswer {
            multiBody: body
            answerType
          }
          ... on TextAnswer {
            textBody: body
            answerType
          }
          ... on NumericAnswer {
            numericBody: body
            answerType
          }
        }
        visibility
        visibilityPreconditions {
          isSatisfied
          questionId
          expectedAnswer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
        placeholder
      }
    }
  }
}
    `;
export type AddQuestionGroupToSampleMutationFn = ApolloReactCommon.MutationFunction<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables>;
export type AddQuestionGroupToSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables>, 'mutation'>;

    export const AddQuestionGroupToSampleComponent = (props: AddQuestionGroupToSampleComponentProps) => (
      <ApolloReactComponents.Mutation<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables> mutation={AddQuestionGroupToSampleDocument} {...props} />
    );
    

/**
 * __useAddQuestionGroupToSampleMutation__
 *
 * To run a mutation, you first call `useAddQuestionGroupToSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionGroupToSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionGroupToSampleMutation, { data, loading, error }] = useAddQuestionGroupToSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *   },
 * });
 */
export function useAddQuestionGroupToSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables>(AddQuestionGroupToSampleDocument, baseOptions);
      }
export type AddQuestionGroupToSampleMutationHookResult = ReturnType<typeof useAddQuestionGroupToSampleMutation>;
export type AddQuestionGroupToSampleMutationResult = ApolloReactCommon.MutationResult<AddQuestionGroupToSampleMutation>;
export type AddQuestionGroupToSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddQuestionGroupToSampleMutation, AddQuestionGroupToSampleMutationVariables>;
export const AddQuestionToSampleDocument = gql`
    mutation AddQuestionToSample($projectSampleInput: ProjectSampleInput!, $questionGroupId: ID!, $questionSetId: ID, $questionId: ID!, $order: Int) {
  AddQuestionToSample(
    projectSampleInput: $projectSampleInput
    questionGroupId: $questionGroupId
    questionSetId: $questionSetId
    questionId: $questionId
    order: $order
  ) {
    id
    name
    enabledWorkflows {
      name
      id
      assignee
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    selectedQuestionGroups {
      id
      questionSets {
        id
        apiLink
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
        }
      }
    }
  }
}
    `;
export type AddQuestionToSampleMutationFn = ApolloReactCommon.MutationFunction<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables>;
export type AddQuestionToSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables>, 'mutation'>;

    export const AddQuestionToSampleComponent = (props: AddQuestionToSampleComponentProps) => (
      <ApolloReactComponents.Mutation<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables> mutation={AddQuestionToSampleDocument} {...props} />
    );
    

/**
 * __useAddQuestionToSampleMutation__
 *
 * To run a mutation, you first call `useAddQuestionToSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionToSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionToSampleMutation, { data, loading, error }] = useAddQuestionToSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionGroupId: // value for 'questionGroupId'
 *      questionSetId: // value for 'questionSetId'
 *      questionId: // value for 'questionId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAddQuestionToSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables>(AddQuestionToSampleDocument, baseOptions);
      }
export type AddQuestionToSampleMutationHookResult = ReturnType<typeof useAddQuestionToSampleMutation>;
export type AddQuestionToSampleMutationResult = ApolloReactCommon.MutationResult<AddQuestionToSampleMutation>;
export type AddQuestionToSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddQuestionToSampleMutation, AddQuestionToSampleMutationVariables>;
export const AddQuestionsToSampleDocument = gql`
    mutation AddQuestionsToSample($projectSampleInput: ProjectSampleInput!, $questionGroupId: ID!, $questionSetId: ID, $questionIds: [ID!]!) {
  AddQuestionsToSample(
    projectSampleInput: $projectSampleInput
    questionGroupId: $questionGroupId
    questionSetId: $questionSetId
    questionIds: $questionIds
  ) {
    id
    name
    selectedQuestionGroups {
      id
      questionSets {
        id
        apiLink
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
        }
      }
    }
  }
}
    `;
export type AddQuestionsToSampleMutationFn = ApolloReactCommon.MutationFunction<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables>;
export type AddQuestionsToSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables>, 'mutation'>;

    export const AddQuestionsToSampleComponent = (props: AddQuestionsToSampleComponentProps) => (
      <ApolloReactComponents.Mutation<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables> mutation={AddQuestionsToSampleDocument} {...props} />
    );
    

/**
 * __useAddQuestionsToSampleMutation__
 *
 * To run a mutation, you first call `useAddQuestionsToSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsToSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsToSampleMutation, { data, loading, error }] = useAddQuestionsToSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionGroupId: // value for 'questionGroupId'
 *      questionSetId: // value for 'questionSetId'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useAddQuestionsToSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables>(AddQuestionsToSampleDocument, baseOptions);
      }
export type AddQuestionsToSampleMutationHookResult = ReturnType<typeof useAddQuestionsToSampleMutation>;
export type AddQuestionsToSampleMutationResult = ApolloReactCommon.MutationResult<AddQuestionsToSampleMutation>;
export type AddQuestionsToSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddQuestionsToSampleMutation, AddQuestionsToSampleMutationVariables>;
export const AddWorkflowToSampleDocument = gql`
    mutation AddWorkflowToSample($projectSampleInput: ProjectSampleInput!, $workflowId: ID!, $startDate: String!, $serviceBaseline: Int) {
  AddWorkflowToSample(
    projectSampleInput: $projectSampleInput
    workflowId: $workflowId
    startDate: $startDate
    serviceBaseline: $serviceBaseline
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      description
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        price {
          currency
          price {
            value
          }
        }
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type AddWorkflowToSampleMutationFn = ApolloReactCommon.MutationFunction<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables>;
export type AddWorkflowToSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables>, 'mutation'>;

    export const AddWorkflowToSampleComponent = (props: AddWorkflowToSampleComponentProps) => (
      <ApolloReactComponents.Mutation<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables> mutation={AddWorkflowToSampleDocument} {...props} />
    );
    

/**
 * __useAddWorkflowToSampleMutation__
 *
 * To run a mutation, you first call `useAddWorkflowToSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkflowToSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkflowToSampleMutation, { data, loading, error }] = useAddWorkflowToSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowId: // value for 'workflowId'
 *      startDate: // value for 'startDate'
 *      serviceBaseline: // value for 'serviceBaseline'
 *   },
 * });
 */
export function useAddWorkflowToSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables>(AddWorkflowToSampleDocument, baseOptions);
      }
export type AddWorkflowToSampleMutationHookResult = ReturnType<typeof useAddWorkflowToSampleMutation>;
export type AddWorkflowToSampleMutationResult = ApolloReactCommon.MutationResult<AddWorkflowToSampleMutation>;
export type AddWorkflowToSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWorkflowToSampleMutation, AddWorkflowToSampleMutationVariables>;
export const CopyTaskToChosenSampleDocument = gql`
    mutation CopyTaskToChosenSample($projectSampleWorkflowInput: ProjectSampleWorkflowInput!, $destinationSampleId: ID!) {
  CopyTaskToChosenSample(
    projectSampleWorkflowInput: $projectSampleWorkflowInput
    destinationSampleId: $destinationSampleId
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type CopyTaskToChosenSampleMutationFn = ApolloReactCommon.MutationFunction<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables>;
export type CopyTaskToChosenSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables>, 'mutation'>;

    export const CopyTaskToChosenSampleComponent = (props: CopyTaskToChosenSampleComponentProps) => (
      <ApolloReactComponents.Mutation<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables> mutation={CopyTaskToChosenSampleDocument} {...props} />
    );
    

/**
 * __useCopyTaskToChosenSampleMutation__
 *
 * To run a mutation, you first call `useCopyTaskToChosenSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTaskToChosenSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTaskToChosenSampleMutation, { data, loading, error }] = useCopyTaskToChosenSampleMutation({
 *   variables: {
 *      projectSampleWorkflowInput: // value for 'projectSampleWorkflowInput'
 *      destinationSampleId: // value for 'destinationSampleId'
 *   },
 * });
 */
export function useCopyTaskToChosenSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables>(CopyTaskToChosenSampleDocument, baseOptions);
      }
export type CopyTaskToChosenSampleMutationHookResult = ReturnType<typeof useCopyTaskToChosenSampleMutation>;
export type CopyTaskToChosenSampleMutationResult = ApolloReactCommon.MutationResult<CopyTaskToChosenSampleMutation>;
export type CopyTaskToChosenSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyTaskToChosenSampleMutation, CopyTaskToChosenSampleMutationVariables>;
export const DuplicateSampleInProjectDocument = gql`
    mutation DuplicateSampleInProject($projectId: ID!, $sampleId: ID!) {
  DuplicateSampleInProject(projectId: $projectId, sampleId: $sampleId) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type DuplicateSampleInProjectMutationFn = ApolloReactCommon.MutationFunction<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables>;
export type DuplicateSampleInProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables>, 'mutation'>;

    export const DuplicateSampleInProjectComponent = (props: DuplicateSampleInProjectComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables> mutation={DuplicateSampleInProjectDocument} {...props} />
    );
    

/**
 * __useDuplicateSampleInProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateSampleInProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSampleInProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSampleInProjectMutation, { data, loading, error }] = useDuplicateSampleInProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      sampleId: // value for 'sampleId'
 *   },
 * });
 */
export function useDuplicateSampleInProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables>(DuplicateSampleInProjectDocument, baseOptions);
      }
export type DuplicateSampleInProjectMutationHookResult = ReturnType<typeof useDuplicateSampleInProjectMutation>;
export type DuplicateSampleInProjectMutationResult = ApolloReactCommon.MutationResult<DuplicateSampleInProjectMutation>;
export type DuplicateSampleInProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateSampleInProjectMutation, DuplicateSampleInProjectMutationVariables>;
export const EnableWorkflowInProjectSampleDocument = gql`
    mutation EnableWorkflowInProjectSample($projectSampleWorkflowEnableInput: ProjectSampleWorkflowInput!) {
  EnableWorkflowInProjectSample(
    projectSampleWorkflowEnableInput: $projectSampleWorkflowEnableInput
  ) {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          assignee
          priority
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      selectedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          assignee
          priority
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        assignee
        collaborators {
          userIds
          groupIds
        }
        services {
          id
          name
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          description
          serviceType
          durationLocked
          priority
          collaborators {
            userIds
            groupIds
          }
          order
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      status
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
  }
}
    `;
export type EnableWorkflowInProjectSampleMutationFn = ApolloReactCommon.MutationFunction<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables>;
export type EnableWorkflowInProjectSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables>, 'mutation'>;

    export const EnableWorkflowInProjectSampleComponent = (props: EnableWorkflowInProjectSampleComponentProps) => (
      <ApolloReactComponents.Mutation<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables> mutation={EnableWorkflowInProjectSampleDocument} {...props} />
    );
    

/**
 * __useEnableWorkflowInProjectSampleMutation__
 *
 * To run a mutation, you first call `useEnableWorkflowInProjectSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableWorkflowInProjectSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableWorkflowInProjectSampleMutation, { data, loading, error }] = useEnableWorkflowInProjectSampleMutation({
 *   variables: {
 *      projectSampleWorkflowEnableInput: // value for 'projectSampleWorkflowEnableInput'
 *   },
 * });
 */
export function useEnableWorkflowInProjectSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables>(EnableWorkflowInProjectSampleDocument, baseOptions);
      }
export type EnableWorkflowInProjectSampleMutationHookResult = ReturnType<typeof useEnableWorkflowInProjectSampleMutation>;
export type EnableWorkflowInProjectSampleMutationResult = ApolloReactCommon.MutationResult<EnableWorkflowInProjectSampleMutation>;
export type EnableWorkflowInProjectSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableWorkflowInProjectSampleMutation, EnableWorkflowInProjectSampleMutationVariables>;
export const RemoveEventFromSampleDocument = gql`
    mutation RemoveEventFromSample($projectSampleInput: ProjectSampleInput!, $eventId: ID!) {
  RemoveEventFromSample(
    projectSampleInput: $projectSampleInput
    eventId: $eventId
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type RemoveEventFromSampleMutationFn = ApolloReactCommon.MutationFunction<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables>;
export type RemoveEventFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables>, 'mutation'>;

    export const RemoveEventFromSampleComponent = (props: RemoveEventFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables> mutation={RemoveEventFromSampleDocument} {...props} />
    );
    

/**
 * __useRemoveEventFromSampleMutation__
 *
 * To run a mutation, you first call `useRemoveEventFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventFromSampleMutation, { data, loading, error }] = useRemoveEventFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRemoveEventFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables>(RemoveEventFromSampleDocument, baseOptions);
      }
export type RemoveEventFromSampleMutationHookResult = ReturnType<typeof useRemoveEventFromSampleMutation>;
export type RemoveEventFromSampleMutationResult = ApolloReactCommon.MutationResult<RemoveEventFromSampleMutation>;
export type RemoveEventFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveEventFromSampleMutation, RemoveEventFromSampleMutationVariables>;
export const RemoveQuestionGroupFromSampleDocument = gql`
    mutation RemoveQuestionGroupFromSample($projectSampleInput: ProjectSampleInput!, $questionGroupId: ID!) {
  RemoveQuestionGroupFromSample(
    projectSampleInput: $projectSampleInput
    questionGroupId: $questionGroupId
  ) {
    id
    name
    enabledWorkflows {
      name
      id
      assignee
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    selectedQuestionGroups {
      id
      questionSets {
        id
        apiLink
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
        }
      }
    }
  }
}
    `;
export type RemoveQuestionGroupFromSampleMutationFn = ApolloReactCommon.MutationFunction<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables>;
export type RemoveQuestionGroupFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables>, 'mutation'>;

    export const RemoveQuestionGroupFromSampleComponent = (props: RemoveQuestionGroupFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables> mutation={RemoveQuestionGroupFromSampleDocument} {...props} />
    );
    

/**
 * __useRemoveQuestionGroupFromSampleMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionGroupFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionGroupFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionGroupFromSampleMutation, { data, loading, error }] = useRemoveQuestionGroupFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionGroupId: // value for 'questionGroupId'
 *   },
 * });
 */
export function useRemoveQuestionGroupFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables>(RemoveQuestionGroupFromSampleDocument, baseOptions);
      }
export type RemoveQuestionGroupFromSampleMutationHookResult = ReturnType<typeof useRemoveQuestionGroupFromSampleMutation>;
export type RemoveQuestionGroupFromSampleMutationResult = ApolloReactCommon.MutationResult<RemoveQuestionGroupFromSampleMutation>;
export type RemoveQuestionGroupFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveQuestionGroupFromSampleMutation, RemoveQuestionGroupFromSampleMutationVariables>;
export const RemoveQuestionsFromSampleDocument = gql`
    mutation RemoveQuestionsFromSample($projectSampleInput: ProjectSampleInput!, $questionGroupId: ID!, $questionSetId: ID!, $questionIds: [ID!]) {
  RemoveQuestionsFromSample(
    projectSampleInput: $projectSampleInput
    questionGroupId: $questionGroupId
    questionSetId: $questionSetId
    questionIds: $questionIds
  ) {
    id
    name
    enabledWorkflows {
      name
      id
      assignee
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    selectedQuestionGroups {
      id
      questionSets {
        id
        apiLink
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
        }
      }
    }
  }
}
    `;
export type RemoveQuestionsFromSampleMutationFn = ApolloReactCommon.MutationFunction<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables>;
export type RemoveQuestionsFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables>, 'mutation'>;

    export const RemoveQuestionsFromSampleComponent = (props: RemoveQuestionsFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables> mutation={RemoveQuestionsFromSampleDocument} {...props} />
    );
    

/**
 * __useRemoveQuestionsFromSampleMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionsFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionsFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionsFromSampleMutation, { data, loading, error }] = useRemoveQuestionsFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionGroupId: // value for 'questionGroupId'
 *      questionSetId: // value for 'questionSetId'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useRemoveQuestionsFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables>(RemoveQuestionsFromSampleDocument, baseOptions);
      }
export type RemoveQuestionsFromSampleMutationHookResult = ReturnType<typeof useRemoveQuestionsFromSampleMutation>;
export type RemoveQuestionsFromSampleMutationResult = ApolloReactCommon.MutationResult<RemoveQuestionsFromSampleMutation>;
export type RemoveQuestionsFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveQuestionsFromSampleMutation, RemoveQuestionsFromSampleMutationVariables>;
export const RemoveSampleFromProjectDocument = gql`
    mutation RemoveSampleFromProject($projectSampleInput: ProjectSampleInput!) {
  RemoveSampleFromProject(projectSampleInput: $projectSampleInput) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type RemoveSampleFromProjectMutationFn = ApolloReactCommon.MutationFunction<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables>;
export type RemoveSampleFromProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables>, 'mutation'>;

    export const RemoveSampleFromProjectComponent = (props: RemoveSampleFromProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables> mutation={RemoveSampleFromProjectDocument} {...props} />
    );
    

/**
 * __useRemoveSampleFromProjectMutation__
 *
 * To run a mutation, you first call `useRemoveSampleFromProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSampleFromProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSampleFromProjectMutation, { data, loading, error }] = useRemoveSampleFromProjectMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *   },
 * });
 */
export function useRemoveSampleFromProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables>(RemoveSampleFromProjectDocument, baseOptions);
      }
export type RemoveSampleFromProjectMutationHookResult = ReturnType<typeof useRemoveSampleFromProjectMutation>;
export type RemoveSampleFromProjectMutationResult = ApolloReactCommon.MutationResult<RemoveSampleFromProjectMutation>;
export type RemoveSampleFromProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSampleFromProjectMutation, RemoveSampleFromProjectMutationVariables>;
export const RemoveWorkflowFromSampleDocument = gql`
    mutation RemoveWorkflowFromSample($projectSampleInput: ProjectSampleInput!, $workflowInput: WorkflowInput!) {
  RemoveWorkflowFromSample(
    projectSampleInput: $projectSampleInput
    workflowInput: $workflowInput
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type RemoveWorkflowFromSampleMutationFn = ApolloReactCommon.MutationFunction<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables>;
export type RemoveWorkflowFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables>, 'mutation'>;

    export const RemoveWorkflowFromSampleComponent = (props: RemoveWorkflowFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables> mutation={RemoveWorkflowFromSampleDocument} {...props} />
    );
    

/**
 * __useRemoveWorkflowFromSampleMutation__
 *
 * To run a mutation, you first call `useRemoveWorkflowFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkflowFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkflowFromSampleMutation, { data, loading, error }] = useRemoveWorkflowFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowInput: // value for 'workflowInput'
 *   },
 * });
 */
export function useRemoveWorkflowFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables>(RemoveWorkflowFromSampleDocument, baseOptions);
      }
export type RemoveWorkflowFromSampleMutationHookResult = ReturnType<typeof useRemoveWorkflowFromSampleMutation>;
export type RemoveWorkflowFromSampleMutationResult = ApolloReactCommon.MutationResult<RemoveWorkflowFromSampleMutation>;
export type RemoveWorkflowFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveWorkflowFromSampleMutation, RemoveWorkflowFromSampleMutationVariables>;
export const SelectWorkflowInProjectSampleDocument = gql`
    mutation SelectWorkflowInProjectSample($projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput!) {
  SelectWorkflowInProjectSample(
    projectSampleWorkflowSelectInput: $projectSampleWorkflowSelectInput
  ) {
    id
  }
}
    `;
export type SelectWorkflowInProjectSampleMutationFn = ApolloReactCommon.MutationFunction<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables>;
export type SelectWorkflowInProjectSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables>, 'mutation'>;

    export const SelectWorkflowInProjectSampleComponent = (props: SelectWorkflowInProjectSampleComponentProps) => (
      <ApolloReactComponents.Mutation<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables> mutation={SelectWorkflowInProjectSampleDocument} {...props} />
    );
    

/**
 * __useSelectWorkflowInProjectSampleMutation__
 *
 * To run a mutation, you first call `useSelectWorkflowInProjectSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectWorkflowInProjectSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectWorkflowInProjectSampleMutation, { data, loading, error }] = useSelectWorkflowInProjectSampleMutation({
 *   variables: {
 *      projectSampleWorkflowSelectInput: // value for 'projectSampleWorkflowSelectInput'
 *   },
 * });
 */
export function useSelectWorkflowInProjectSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables>(SelectWorkflowInProjectSampleDocument, baseOptions);
      }
export type SelectWorkflowInProjectSampleMutationHookResult = ReturnType<typeof useSelectWorkflowInProjectSampleMutation>;
export type SelectWorkflowInProjectSampleMutationResult = ApolloReactCommon.MutationResult<SelectWorkflowInProjectSampleMutation>;
export type SelectWorkflowInProjectSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<SelectWorkflowInProjectSampleMutation, SelectWorkflowInProjectSampleMutationVariables>;
export const UnselectWorkflowInProjectSampleDocument = gql`
    mutation UnselectWorkflowInProjectSample($projectSampleWorkflowSelectInput: ProjectSampleWorkflowInput!) {
  UnselectWorkflowInProjectSample(
    projectSampleWorkflowSelectInput: $projectSampleWorkflowSelectInput
  ) {
    id
  }
}
    `;
export type UnselectWorkflowInProjectSampleMutationFn = ApolloReactCommon.MutationFunction<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables>;
export type UnselectWorkflowInProjectSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables>, 'mutation'>;

    export const UnselectWorkflowInProjectSampleComponent = (props: UnselectWorkflowInProjectSampleComponentProps) => (
      <ApolloReactComponents.Mutation<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables> mutation={UnselectWorkflowInProjectSampleDocument} {...props} />
    );
    

/**
 * __useUnselectWorkflowInProjectSampleMutation__
 *
 * To run a mutation, you first call `useUnselectWorkflowInProjectSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnselectWorkflowInProjectSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unselectWorkflowInProjectSampleMutation, { data, loading, error }] = useUnselectWorkflowInProjectSampleMutation({
 *   variables: {
 *      projectSampleWorkflowSelectInput: // value for 'projectSampleWorkflowSelectInput'
 *   },
 * });
 */
export function useUnselectWorkflowInProjectSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables>(UnselectWorkflowInProjectSampleDocument, baseOptions);
      }
export type UnselectWorkflowInProjectSampleMutationHookResult = ReturnType<typeof useUnselectWorkflowInProjectSampleMutation>;
export type UnselectWorkflowInProjectSampleMutationResult = ApolloReactCommon.MutationResult<UnselectWorkflowInProjectSampleMutation>;
export type UnselectWorkflowInProjectSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<UnselectWorkflowInProjectSampleMutation, UnselectWorkflowInProjectSampleMutationVariables>;
export const UpdateQuestionGroupFromSampleDocument = gql`
    mutation UpdateQuestionGroupFromSample($projectSampleInput: ProjectSampleInput!, $questionGroupUpdatables: UpdateQuestionGroupInput!) {
  UpdateQuestionGroupFromSample(
    projectSampleInput: $projectSampleInput
    questionGroupUpdatables: $questionGroupUpdatables
  ) {
    id
    name
    questionSets {
      id
      apiLink
      renderer
      questions {
        id
        question
        description
        choices
        order
        questionLevel
        isObligatory
        answer {
          ... on MultiAnswer {
            multiBody: body
            answerType
          }
          ... on TextAnswer {
            textBody: body
            answerType
          }
          ... on NumericAnswer {
            numericBody: body
            answerType
          }
        }
        visibility
        visibilityPreconditions {
          isSatisfied
          questionId
          expectedAnswer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
        placeholder
      }
    }
  }
}
    `;
export type UpdateQuestionGroupFromSampleMutationFn = ApolloReactCommon.MutationFunction<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables>;
export type UpdateQuestionGroupFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables>, 'mutation'>;

    export const UpdateQuestionGroupFromSampleComponent = (props: UpdateQuestionGroupFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables> mutation={UpdateQuestionGroupFromSampleDocument} {...props} />
    );
    

/**
 * __useUpdateQuestionGroupFromSampleMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionGroupFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionGroupFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionGroupFromSampleMutation, { data, loading, error }] = useUpdateQuestionGroupFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionGroupUpdatables: // value for 'questionGroupUpdatables'
 *   },
 * });
 */
export function useUpdateQuestionGroupFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables>(UpdateQuestionGroupFromSampleDocument, baseOptions);
      }
export type UpdateQuestionGroupFromSampleMutationHookResult = ReturnType<typeof useUpdateQuestionGroupFromSampleMutation>;
export type UpdateQuestionGroupFromSampleMutationResult = ApolloReactCommon.MutationResult<UpdateQuestionGroupFromSampleMutation>;
export type UpdateQuestionGroupFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuestionGroupFromSampleMutation, UpdateQuestionGroupFromSampleMutationVariables>;
export const UpdateQuestionInQuestionGroupFromSampleDocument = gql`
    mutation UpdateQuestionInQuestionGroupFromSample($projectSampleInput: ProjectSampleInput!, $questionInput: QuestionInput!, $questionUpdatables: UpdateQuestionInput!) {
  UpdateQuestionInQuestionGroupFromSample(
    projectSampleInput: $projectSampleInput
    questionInput: $questionInput
    questionUpdatables: $questionUpdatables
  ) {
    id
    question
    description
    choices
    order
    questionLevel
    isObligatory
    answer {
      ... on MultiAnswer {
        multiBody: body
        answerType
      }
      ... on TextAnswer {
        textBody: body
        answerType
      }
      ... on NumericAnswer {
        numericBody: body
        answerType
      }
    }
    visibility
    visibilityPreconditions {
      isSatisfied
      questionId
      expectedAnswer {
        ... on MultiAnswer {
          multiBody: body
          answerType
        }
        ... on TextAnswer {
          textBody: body
          answerType
        }
        ... on NumericAnswer {
          numericBody: body
          answerType
        }
      }
    }
    placeholder
  }
}
    `;
export type UpdateQuestionInQuestionGroupFromSampleMutationFn = ApolloReactCommon.MutationFunction<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables>;
export type UpdateQuestionInQuestionGroupFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables>, 'mutation'>;

    export const UpdateQuestionInQuestionGroupFromSampleComponent = (props: UpdateQuestionInQuestionGroupFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables> mutation={UpdateQuestionInQuestionGroupFromSampleDocument} {...props} />
    );
    

/**
 * __useUpdateQuestionInQuestionGroupFromSampleMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionInQuestionGroupFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionInQuestionGroupFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionInQuestionGroupFromSampleMutation, { data, loading, error }] = useUpdateQuestionInQuestionGroupFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      questionInput: // value for 'questionInput'
 *      questionUpdatables: // value for 'questionUpdatables'
 *   },
 * });
 */
export function useUpdateQuestionInQuestionGroupFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables>(UpdateQuestionInQuestionGroupFromSampleDocument, baseOptions);
      }
export type UpdateQuestionInQuestionGroupFromSampleMutationHookResult = ReturnType<typeof useUpdateQuestionInQuestionGroupFromSampleMutation>;
export type UpdateQuestionInQuestionGroupFromSampleMutationResult = ApolloReactCommon.MutationResult<UpdateQuestionInQuestionGroupFromSampleMutation>;
export type UpdateQuestionInQuestionGroupFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateQuestionInQuestionGroupFromSampleMutation, UpdateQuestionInQuestionGroupFromSampleMutationVariables>;
export const UpdateSampleTimelineLineDocument = gql`
    mutation UpdateSampleTimelineLine($projectId: ID!, $sampleId: ID!, $line: Int!, $name: String!) {
  UpdateSampleTimelineLine(
    projectId: $projectId
    sampleId: $sampleId
    line: $line
    name: $name
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      description
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        price {
          currency
          price {
            value
          }
        }
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type UpdateSampleTimelineLineMutationFn = ApolloReactCommon.MutationFunction<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables>;
export type UpdateSampleTimelineLineComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables>, 'mutation'>;

    export const UpdateSampleTimelineLineComponent = (props: UpdateSampleTimelineLineComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables> mutation={UpdateSampleTimelineLineDocument} {...props} />
    );
    

/**
 * __useUpdateSampleTimelineLineMutation__
 *
 * To run a mutation, you first call `useUpdateSampleTimelineLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSampleTimelineLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSampleTimelineLineMutation, { data, loading, error }] = useUpdateSampleTimelineLineMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      sampleId: // value for 'sampleId'
 *      line: // value for 'line'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSampleTimelineLineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables>(UpdateSampleTimelineLineDocument, baseOptions);
      }
export type UpdateSampleTimelineLineMutationHookResult = ReturnType<typeof useUpdateSampleTimelineLineMutation>;
export type UpdateSampleTimelineLineMutationResult = ApolloReactCommon.MutationResult<UpdateSampleTimelineLineMutation>;
export type UpdateSampleTimelineLineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSampleTimelineLineMutation, UpdateSampleTimelineLineMutationVariables>;
export const UpdateUserSamplesAssignmentInProjectDocument = gql`
    mutation UpdateUserSamplesAssignmentInProject($updateUserSamplesAssignmentInProjectInput: UpdateUserSamplesAssignmentInProjectInput!) {
  UpdateUserSamplesAssignmentInProject(
    updateUserSamplesAssignmentInProjectInput: $updateUserSamplesAssignmentInProjectInput
  ) {
    id
    assignedUsers {
      id
      role
    }
  }
}
    `;
export type UpdateUserSamplesAssignmentInProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables>;
export type UpdateUserSamplesAssignmentInProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables>, 'mutation'>;

    export const UpdateUserSamplesAssignmentInProjectComponent = (props: UpdateUserSamplesAssignmentInProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables> mutation={UpdateUserSamplesAssignmentInProjectDocument} {...props} />
    );
    

/**
 * __useUpdateUserSamplesAssignmentInProjectMutation__
 *
 * To run a mutation, you first call `useUpdateUserSamplesAssignmentInProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSamplesAssignmentInProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSamplesAssignmentInProjectMutation, { data, loading, error }] = useUpdateUserSamplesAssignmentInProjectMutation({
 *   variables: {
 *      updateUserSamplesAssignmentInProjectInput: // value for 'updateUserSamplesAssignmentInProjectInput'
 *   },
 * });
 */
export function useUpdateUserSamplesAssignmentInProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables>(UpdateUserSamplesAssignmentInProjectDocument, baseOptions);
      }
export type UpdateUserSamplesAssignmentInProjectMutationHookResult = ReturnType<typeof useUpdateUserSamplesAssignmentInProjectMutation>;
export type UpdateUserSamplesAssignmentInProjectMutationResult = ApolloReactCommon.MutationResult<UpdateUserSamplesAssignmentInProjectMutation>;
export type UpdateUserSamplesAssignmentInProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSamplesAssignmentInProjectMutation, UpdateUserSamplesAssignmentInProjectMutationVariables>;
export const AddServiceToWorkflowDocument = gql`
    mutation AddServiceToWorkflow($projectSampleInput: ProjectSampleInput!, $workflowInput: WorkflowInput!, $serviceId: ID!, $startDate: String!, $baseline: Int) {
  AddServiceToWorkflow(
    projectSampleInput: $projectSampleInput
    workflowInput: $workflowInput
    serviceId: $serviceId
    startDate: $startDate
    baseline: $baseline
  ) {
    name
    id
    assignee
    price {
      currency
      price {
        value
      }
    }
    collaborators {
      userIds
      groupIds
    }
    description
    offerPrice {
      currency
      maxPrice {
        value
      }
      minPrice {
        value
      }
    }
    services {
      id
      name
      startDate
      endDate
      baseLine
      baseLineSize
      status
      description
      serviceType
      durationLocked
      order
      vendor {
        id
        name
      }
      price {
        currency
        price {
          value
        }
      }
      scientists {
        id
        name
        preferences {
          preferredCurrency
        }
        organization
        schedule {
          id
        }
      }
      report {
        selectedQuestionGroups {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
      }
      assignee
      priority
      collaborators {
        userIds
        groupIds
      }
    }
  }
}
    `;
export type AddServiceToWorkflowMutationFn = ApolloReactCommon.MutationFunction<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables>;
export type AddServiceToWorkflowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables>, 'mutation'>;

    export const AddServiceToWorkflowComponent = (props: AddServiceToWorkflowComponentProps) => (
      <ApolloReactComponents.Mutation<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables> mutation={AddServiceToWorkflowDocument} {...props} />
    );
    

/**
 * __useAddServiceToWorkflowMutation__
 *
 * To run a mutation, you first call `useAddServiceToWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceToWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceToWorkflowMutation, { data, loading, error }] = useAddServiceToWorkflowMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowInput: // value for 'workflowInput'
 *      serviceId: // value for 'serviceId'
 *      startDate: // value for 'startDate'
 *      baseline: // value for 'baseline'
 *   },
 * });
 */
export function useAddServiceToWorkflowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables>) {
        return ApolloReactHooks.useMutation<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables>(AddServiceToWorkflowDocument, baseOptions);
      }
export type AddServiceToWorkflowMutationHookResult = ReturnType<typeof useAddServiceToWorkflowMutation>;
export type AddServiceToWorkflowMutationResult = ApolloReactCommon.MutationResult<AddServiceToWorkflowMutation>;
export type AddServiceToWorkflowMutationOptions = ApolloReactCommon.BaseMutationOptions<AddServiceToWorkflowMutation, AddServiceToWorkflowMutationVariables>;
export const RemoveServiceFromWorkflowDocument = gql`
    mutation RemoveServiceFromWorkflow($projectSampleInput: ProjectSampleInput!, $workflowInput: WorkflowInput!, $serviceId: ID!) {
  RemoveServiceFromWorkflow(
    projectSampleInput: $projectSampleInput
    workflowInput: $workflowInput
    serviceId: $serviceId
  ) {
    name
    id
    offerPrice {
      currency
      maxPrice {
        value
      }
      minPrice {
        value
      }
    }
    services {
      id
      name
      startDate
      endDate
      baseLine
      baseLineSize
      status
      description
      serviceType
      durationLocked
      order
      assignee
      priority
      collaborators {
        userIds
        groupIds
      }
      vendor {
        id
        name
      }
      price {
        currency
        price {
          value
        }
      }
      scientists {
        id
        name
        preferences {
          preferredCurrency
        }
        organization
        schedule {
          id
        }
      }
      report {
        selectedQuestionGroups {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
      }
    }
    assignee
    description
    price {
      currency
      price {
        value
      }
    }
    collaborators {
      userIds
      groupIds
    }
  }
}
    `;
export type RemoveServiceFromWorkflowMutationFn = ApolloReactCommon.MutationFunction<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables>;
export type RemoveServiceFromWorkflowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables>, 'mutation'>;

    export const RemoveServiceFromWorkflowComponent = (props: RemoveServiceFromWorkflowComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables> mutation={RemoveServiceFromWorkflowDocument} {...props} />
    );
    

/**
 * __useRemoveServiceFromWorkflowMutation__
 *
 * To run a mutation, you first call `useRemoveServiceFromWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveServiceFromWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeServiceFromWorkflowMutation, { data, loading, error }] = useRemoveServiceFromWorkflowMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowInput: // value for 'workflowInput'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useRemoveServiceFromWorkflowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables>(RemoveServiceFromWorkflowDocument, baseOptions);
      }
export type RemoveServiceFromWorkflowMutationHookResult = ReturnType<typeof useRemoveServiceFromWorkflowMutation>;
export type RemoveServiceFromWorkflowMutationResult = ApolloReactCommon.MutationResult<RemoveServiceFromWorkflowMutation>;
export type RemoveServiceFromWorkflowMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveServiceFromWorkflowMutation, RemoveServiceFromWorkflowMutationVariables>;
export const UpdateServiceFromWorkflowDocument = gql`
    mutation UpdateServiceFromWorkflow($projectSampleInput: ProjectSampleInput!, $workflowInput: WorkflowInput!, $serviceUpdatables: ServiceInput!) {
  UpdateServiceFromWorkflow(
    projectSampleInput: $projectSampleInput
    workflowInput: $workflowInput
    serviceUpdatables: $serviceUpdatables
  ) {
    id
    name
    price {
      price {
        value
      }
      currency
    }
    description
    startDate
    endDate
    baseLineSize
    baseLine
    status
    serviceType
    durationLocked
    order
    hiddenOnTheList
    offerPrice {
      minPrice {
        value
      }
      maxPrice {
        value
      }
      currency
    }
    assignee
    priority
    collaborators {
      userIds
      groupIds
    }
    scientists {
      id
      name
      preferences {
        preferredCurrency
      }
      organization
      schedule {
        id
      }
    }
    vendor {
      id
      name
    }
    report {
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateServiceFromWorkflowMutationFn = ApolloReactCommon.MutationFunction<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables>;
export type UpdateServiceFromWorkflowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables>, 'mutation'>;

    export const UpdateServiceFromWorkflowComponent = (props: UpdateServiceFromWorkflowComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables> mutation={UpdateServiceFromWorkflowDocument} {...props} />
    );
    

/**
 * __useUpdateServiceFromWorkflowMutation__
 *
 * To run a mutation, you first call `useUpdateServiceFromWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceFromWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceFromWorkflowMutation, { data, loading, error }] = useUpdateServiceFromWorkflowMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowInput: // value for 'workflowInput'
 *      serviceUpdatables: // value for 'serviceUpdatables'
 *   },
 * });
 */
export function useUpdateServiceFromWorkflowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables>(UpdateServiceFromWorkflowDocument, baseOptions);
      }
export type UpdateServiceFromWorkflowMutationHookResult = ReturnType<typeof useUpdateServiceFromWorkflowMutation>;
export type UpdateServiceFromWorkflowMutationResult = ApolloReactCommon.MutationResult<UpdateServiceFromWorkflowMutation>;
export type UpdateServiceFromWorkflowMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateServiceFromWorkflowMutation, UpdateServiceFromWorkflowMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($newPassword: String!) {
  ChangePassword(newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export type ChangePasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangePasswordMutation, ChangePasswordMutationVariables>, 'mutation'>;

    export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> mutation={ChangePasswordDocument} {...props} />
    );
    

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const EditUserNameDocument = gql`
    mutation EditUserName($auth0UserId: ID!, $name: String!) {
  EditUserName(auth0UserId: $auth0UserId, name: $name) {
    id
    name
    avatar
    email
    organization
    role {
      id
      name
      description
    }
  }
}
    `;
export type EditUserNameMutationFn = ApolloReactCommon.MutationFunction<EditUserNameMutation, EditUserNameMutationVariables>;
export type EditUserNameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserNameMutation, EditUserNameMutationVariables>, 'mutation'>;

    export const EditUserNameComponent = (props: EditUserNameComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserNameMutation, EditUserNameMutationVariables> mutation={EditUserNameDocument} {...props} />
    );
    

/**
 * __useEditUserNameMutation__
 *
 * To run a mutation, you first call `useEditUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserNameMutation, { data, loading, error }] = useEditUserNameMutation({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditUserNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserNameMutation, EditUserNameMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserNameMutation, EditUserNameMutationVariables>(EditUserNameDocument, baseOptions);
      }
export type EditUserNameMutationHookResult = ReturnType<typeof useEditUserNameMutation>;
export type EditUserNameMutationResult = ApolloReactCommon.MutationResult<EditUserNameMutation>;
export type EditUserNameMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserNameMutation, EditUserNameMutationVariables>;
export const EditUserRoleDocument = gql`
    mutation EditUserRole($auth0UserId: ID!, $auth0RoleId: ID!) {
  EditUserRole(auth0UserId: $auth0UserId, auth0RoleId: $auth0RoleId) {
    id
    name
    description
  }
}
    `;
export type EditUserRoleMutationFn = ApolloReactCommon.MutationFunction<EditUserRoleMutation, EditUserRoleMutationVariables>;
export type EditUserRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EditUserRoleMutation, EditUserRoleMutationVariables>, 'mutation'>;

    export const EditUserRoleComponent = (props: EditUserRoleComponentProps) => (
      <ApolloReactComponents.Mutation<EditUserRoleMutation, EditUserRoleMutationVariables> mutation={EditUserRoleDocument} {...props} />
    );
    

/**
 * __useEditUserRoleMutation__
 *
 * To run a mutation, you first call `useEditUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserRoleMutation, { data, loading, error }] = useEditUserRoleMutation({
 *   variables: {
 *      auth0UserId: // value for 'auth0UserId'
 *      auth0RoleId: // value for 'auth0RoleId'
 *   },
 * });
 */
export function useEditUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserRoleMutation, EditUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserRoleMutation, EditUserRoleMutationVariables>(EditUserRoleDocument, baseOptions);
      }
export type EditUserRoleMutationHookResult = ReturnType<typeof useEditUserRoleMutation>;
export type EditUserRoleMutationResult = ApolloReactCommon.MutationResult<EditUserRoleMutation>;
export type EditUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserRoleMutation, EditUserRoleMutationVariables>;
export const DuplicateWorkflowInSampleDocument = gql`
    mutation DuplicateWorkflowInSample($projectSampleWorkflowInput: ProjectSampleWorkflowInput!) {
  DuplicateWorkflowInSample(
    projectSampleWorkflowInput: $projectSampleWorkflowInput
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type DuplicateWorkflowInSampleMutationFn = ApolloReactCommon.MutationFunction<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables>;
export type DuplicateWorkflowInSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables>, 'mutation'>;

    export const DuplicateWorkflowInSampleComponent = (props: DuplicateWorkflowInSampleComponentProps) => (
      <ApolloReactComponents.Mutation<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables> mutation={DuplicateWorkflowInSampleDocument} {...props} />
    );
    

/**
 * __useDuplicateWorkflowInSampleMutation__
 *
 * To run a mutation, you first call `useDuplicateWorkflowInSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateWorkflowInSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateWorkflowInSampleMutation, { data, loading, error }] = useDuplicateWorkflowInSampleMutation({
 *   variables: {
 *      projectSampleWorkflowInput: // value for 'projectSampleWorkflowInput'
 *   },
 * });
 */
export function useDuplicateWorkflowInSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables>(DuplicateWorkflowInSampleDocument, baseOptions);
      }
export type DuplicateWorkflowInSampleMutationHookResult = ReturnType<typeof useDuplicateWorkflowInSampleMutation>;
export type DuplicateWorkflowInSampleMutationResult = ApolloReactCommon.MutationResult<DuplicateWorkflowInSampleMutation>;
export type DuplicateWorkflowInSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<DuplicateWorkflowInSampleMutation, DuplicateWorkflowInSampleMutationVariables>;
export const MoveAllWorkflowsInSampleMutationDocument = gql`
    mutation MoveAllWorkflowsInSampleMutation($projectSampleInput: ProjectSampleInput!, $newStartDate: String!) {
  MoveAllWorkflowsInSample(
    projectSampleInput: $projectSampleInput
    newStartDate: $newStartDate
  ) {
    name
    id
    assignee
    collaborators {
      userIds
      groupIds
    }
    offerPrice {
      currency
      maxPrice {
        value
      }
      minPrice {
        value
      }
    }
    price {
      currency
      price {
        value
      }
    }
    services {
      id
      name
      assignee
      priority
      collaborators {
        userIds
        groupIds
      }
      scientists {
        id
        name
        preferences {
          preferredCurrency
        }
        organization
        schedule {
          id
        }
      }
      startDate
      endDate
      baseLine
      baseLineSize
      status
      description
      serviceType
      durationLocked
      order
      vendor {
        id
        name
      }
      report {
        selectedQuestionGroups {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type MoveAllWorkflowsInSampleMutationMutationFn = ApolloReactCommon.MutationFunction<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables>;
export type MoveAllWorkflowsInSampleMutationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables>, 'mutation'>;

    export const MoveAllWorkflowsInSampleMutationComponent = (props: MoveAllWorkflowsInSampleMutationComponentProps) => (
      <ApolloReactComponents.Mutation<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables> mutation={MoveAllWorkflowsInSampleMutationDocument} {...props} />
    );
    

/**
 * __useMoveAllWorkflowsInSampleMutationMutation__
 *
 * To run a mutation, you first call `useMoveAllWorkflowsInSampleMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveAllWorkflowsInSampleMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveAllWorkflowsInSampleMutationMutation, { data, loading, error }] = useMoveAllWorkflowsInSampleMutationMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      newStartDate: // value for 'newStartDate'
 *   },
 * });
 */
export function useMoveAllWorkflowsInSampleMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables>(MoveAllWorkflowsInSampleMutationDocument, baseOptions);
      }
export type MoveAllWorkflowsInSampleMutationMutationHookResult = ReturnType<typeof useMoveAllWorkflowsInSampleMutationMutation>;
export type MoveAllWorkflowsInSampleMutationMutationResult = ApolloReactCommon.MutationResult<MoveAllWorkflowsInSampleMutationMutation>;
export type MoveAllWorkflowsInSampleMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveAllWorkflowsInSampleMutationMutation, MoveAllWorkflowsInSampleMutationMutationVariables>;
export const MoveServiceToChosenWorkflowDocument = gql`
    mutation MoveServiceToChosenWorkflow($projectSampleWorkflowServiceInput: ProjectSampleWorkflowServiceInput!, $destinationWorkflowId: ID!) {
  MoveServiceToChosenWorkflow(
    projectSampleWorkflowServiceInput: $projectSampleWorkflowServiceInput
    destinationWorkflowId: $destinationWorkflowId
  ) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      collaborators {
        userIds
        groupIds
      }
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        collaborators {
          userIds
          groupIds
        }
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type MoveServiceToChosenWorkflowMutationFn = ApolloReactCommon.MutationFunction<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables>;
export type MoveServiceToChosenWorkflowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables>, 'mutation'>;

    export const MoveServiceToChosenWorkflowComponent = (props: MoveServiceToChosenWorkflowComponentProps) => (
      <ApolloReactComponents.Mutation<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables> mutation={MoveServiceToChosenWorkflowDocument} {...props} />
    );
    

/**
 * __useMoveServiceToChosenWorkflowMutation__
 *
 * To run a mutation, you first call `useMoveServiceToChosenWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveServiceToChosenWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveServiceToChosenWorkflowMutation, { data, loading, error }] = useMoveServiceToChosenWorkflowMutation({
 *   variables: {
 *      projectSampleWorkflowServiceInput: // value for 'projectSampleWorkflowServiceInput'
 *      destinationWorkflowId: // value for 'destinationWorkflowId'
 *   },
 * });
 */
export function useMoveServiceToChosenWorkflowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables>(MoveServiceToChosenWorkflowDocument, baseOptions);
      }
export type MoveServiceToChosenWorkflowMutationHookResult = ReturnType<typeof useMoveServiceToChosenWorkflowMutation>;
export type MoveServiceToChosenWorkflowMutationResult = ApolloReactCommon.MutationResult<MoveServiceToChosenWorkflowMutation>;
export type MoveServiceToChosenWorkflowMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveServiceToChosenWorkflowMutation, MoveServiceToChosenWorkflowMutationVariables>;
export const RemoveWorkflowTemplateDocument = gql`
    mutation RemoveWorkflowTemplate($templateId: ID!) {
  RemoveWorkflowTemplate(templateId: $templateId)
}
    `;
export type RemoveWorkflowTemplateMutationFn = ApolloReactCommon.MutationFunction<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables>;
export type RemoveWorkflowTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables>, 'mutation'>;

    export const RemoveWorkflowTemplateComponent = (props: RemoveWorkflowTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables> mutation={RemoveWorkflowTemplateDocument} {...props} />
    );
    

/**
 * __useRemoveWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useRemoveWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkflowTemplateMutation, { data, loading, error }] = useRemoveWorkflowTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useRemoveWorkflowTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables>(RemoveWorkflowTemplateDocument, baseOptions);
      }
export type RemoveWorkflowTemplateMutationHookResult = ReturnType<typeof useRemoveWorkflowTemplateMutation>;
export type RemoveWorkflowTemplateMutationResult = ApolloReactCommon.MutationResult<RemoveWorkflowTemplateMutation>;
export type RemoveWorkflowTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveWorkflowTemplateMutation, RemoveWorkflowTemplateMutationVariables>;
export const SaveWorkflowTemplateDocument = gql`
    mutation SaveWorkflowTemplate($projectSampleWorkflowInput: ProjectSampleWorkflowInput!, $organization: String!, $templateName: String!) {
  SaveWorkflowTemplate(
    projectSampleWorkflowInput: $projectSampleWorkflowInput
    organization: $organization
    templateName: $templateName
  ) {
    id
    name
    offerPrice {
      currency
      maxPrice {
        value
      }
      minPrice {
        value
      }
    }
    services {
      id
      name
      startDate
      endDate
      baseLine
      baseLineSize
      status
      description
      serviceType
      durationLocked
      order
      vendors {
        id
        name
      }
      report {
        selectedQuestionGroups {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
      }
      assignee
      priority
      vendors {
        id
        name
      }
      offerPrice {
        minPrice {
          value
        }
        maxPrice {
          value
        }
        currency
      }
    }
    questionGroupsToAddToSample {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    organization
    description
  }
}
    `;
export type SaveWorkflowTemplateMutationFn = ApolloReactCommon.MutationFunction<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables>;
export type SaveWorkflowTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables>, 'mutation'>;

    export const SaveWorkflowTemplateComponent = (props: SaveWorkflowTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables> mutation={SaveWorkflowTemplateDocument} {...props} />
    );
    

/**
 * __useSaveWorkflowTemplateMutation__
 *
 * To run a mutation, you first call `useSaveWorkflowTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkflowTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkflowTemplateMutation, { data, loading, error }] = useSaveWorkflowTemplateMutation({
 *   variables: {
 *      projectSampleWorkflowInput: // value for 'projectSampleWorkflowInput'
 *      organization: // value for 'organization'
 *      templateName: // value for 'templateName'
 *   },
 * });
 */
export function useSaveWorkflowTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables>(SaveWorkflowTemplateDocument, baseOptions);
      }
export type SaveWorkflowTemplateMutationHookResult = ReturnType<typeof useSaveWorkflowTemplateMutation>;
export type SaveWorkflowTemplateMutationResult = ApolloReactCommon.MutationResult<SaveWorkflowTemplateMutation>;
export type SaveWorkflowTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveWorkflowTemplateMutation, SaveWorkflowTemplateMutationVariables>;
export const UpdateWorkflowFromSampleDocument = gql`
    mutation UpdateWorkflowFromSample($projectSampleInput: ProjectSampleInput!, $workflowUpdatables: UpdateWorkflowInput!) {
  UpdateWorkflowFromSample(
    projectSampleInput: $projectSampleInput
    workflowUpdatables: $workflowUpdatables
  ) {
    name
    id
    assignee
    description
    offerPrice {
      currency
      maxPrice {
        value
      }
      minPrice {
        value
      }
    }
    price {
      currency
      price {
        value
      }
    }
    services {
      id
      name
      assignee
      priority
      price {
        currency
        price {
          value
        }
      }
      scientists {
        id
        name
        preferences {
          preferredCurrency
        }
        organization
        schedule {
          id
        }
      }
      startDate
      endDate
      baseLine
      baseLineSize
      status
      description
      serviceType
      durationLocked
      collaborators {
        userIds
        groupIds
      }
      order
      vendor {
        id
        name
      }
      report {
        selectedQuestionGroups {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    collaborators {
      groupIds
      userIds
    }
  }
}
    `;
export type UpdateWorkflowFromSampleMutationFn = ApolloReactCommon.MutationFunction<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables>;
export type UpdateWorkflowFromSampleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables>, 'mutation'>;

    export const UpdateWorkflowFromSampleComponent = (props: UpdateWorkflowFromSampleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables> mutation={UpdateWorkflowFromSampleDocument} {...props} />
    );
    

/**
 * __useUpdateWorkflowFromSampleMutation__
 *
 * To run a mutation, you first call `useUpdateWorkflowFromSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkflowFromSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkflowFromSampleMutation, { data, loading, error }] = useUpdateWorkflowFromSampleMutation({
 *   variables: {
 *      projectSampleInput: // value for 'projectSampleInput'
 *      workflowUpdatables: // value for 'workflowUpdatables'
 *   },
 * });
 */
export function useUpdateWorkflowFromSampleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables>(UpdateWorkflowFromSampleDocument, baseOptions);
      }
export type UpdateWorkflowFromSampleMutationHookResult = ReturnType<typeof useUpdateWorkflowFromSampleMutation>;
export type UpdateWorkflowFromSampleMutationResult = ApolloReactCommon.MutationResult<UpdateWorkflowFromSampleMutation>;
export type UpdateWorkflowFromSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWorkflowFromSampleMutation, UpdateWorkflowFromSampleMutationVariables>;
export const DownloadLinkDocument = gql`
    query DownloadLink($fileName: String!, $originFileName: String!) {
  DownloadLink(fileName: $fileName, originFileName: $originFileName)
}
    `;
export type DownloadLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DownloadLinkQuery, DownloadLinkQueryVariables>, 'query'> & ({ variables: DownloadLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DownloadLinkComponent = (props: DownloadLinkComponentProps) => (
      <ApolloReactComponents.Query<DownloadLinkQuery, DownloadLinkQueryVariables> query={DownloadLinkDocument} {...props} />
    );
    

/**
 * __useDownloadLinkQuery__
 *
 * To run a query within a React component, call `useDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadLinkQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      originFileName: // value for 'originFileName'
 *   },
 * });
 */
export function useDownloadLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DownloadLinkQuery, DownloadLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<DownloadLinkQuery, DownloadLinkQueryVariables>(DownloadLinkDocument, baseOptions);
      }
export function useDownloadLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DownloadLinkQuery, DownloadLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DownloadLinkQuery, DownloadLinkQueryVariables>(DownloadLinkDocument, baseOptions);
        }
export type DownloadLinkQueryHookResult = ReturnType<typeof useDownloadLinkQuery>;
export type DownloadLinkLazyQueryHookResult = ReturnType<typeof useDownloadLinkLazyQuery>;
export type DownloadLinkQueryResult = ApolloReactCommon.QueryResult<DownloadLinkQuery, DownloadLinkQueryVariables>;
export const UploadLinksDocument = gql`
    query UploadLinks($fileInput: [UploadLinksInput!]!) {
  UploadLinks(fileInput: $fileInput) {
    fileId
    link
  }
}
    `;
export type UploadLinksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UploadLinksQuery, UploadLinksQueryVariables>, 'query'> & ({ variables: UploadLinksQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UploadLinksComponent = (props: UploadLinksComponentProps) => (
      <ApolloReactComponents.Query<UploadLinksQuery, UploadLinksQueryVariables> query={UploadLinksDocument} {...props} />
    );
    

/**
 * __useUploadLinksQuery__
 *
 * To run a query within a React component, call `useUploadLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadLinksQuery({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *   },
 * });
 */
export function useUploadLinksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UploadLinksQuery, UploadLinksQueryVariables>) {
        return ApolloReactHooks.useQuery<UploadLinksQuery, UploadLinksQueryVariables>(UploadLinksDocument, baseOptions);
      }
export function useUploadLinksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UploadLinksQuery, UploadLinksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UploadLinksQuery, UploadLinksQueryVariables>(UploadLinksDocument, baseOptions);
        }
export type UploadLinksQueryHookResult = ReturnType<typeof useUploadLinksQuery>;
export type UploadLinksLazyQueryHookResult = ReturnType<typeof useUploadLinksLazyQuery>;
export type UploadLinksQueryResult = ApolloReactCommon.QueryResult<UploadLinksQuery, UploadLinksQueryVariables>;
export const GroupDocument = gql`
    query Group($groupId: ID!) {
  Group(id: $groupId) {
    id
    name
    members {
      id
      name
      email
    }
  }
}
    `;
export type GroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupQuery, GroupQueryVariables>, 'query'> & ({ variables: GroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupComponent = (props: GroupComponentProps) => (
      <ApolloReactComponents.Query<GroupQuery, GroupQueryVariables> query={GroupDocument} {...props} />
    );
    

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
      }
export function useGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = ApolloReactCommon.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  Groups {
    id
    name
    members
    organization
  }
}
    `;
export type GroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupsQuery, GroupsQueryVariables>, 'query'>;

    export const GroupsComponent = (props: GroupsComponentProps) => (
      <ApolloReactComponents.Query<GroupsQuery, GroupsQueryVariables> query={GroupsDocument} {...props} />
    );
    

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
      }
export function useGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = ApolloReactCommon.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const ServicesAssignedToGroupForTimelineDocument = gql`
    query ServicesAssignedToGroupForTimeline($groupId: ID!) {
  ServicesAssignedToGroupForTimeline(groupId: $groupId) {
    id
    name
    events {
      id
      priority
      endDate
      startDate
      baseLine
      baseLineSize
      name
      description
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type ServicesAssignedToGroupForTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>, 'query'> & ({ variables: ServicesAssignedToGroupForTimelineQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ServicesAssignedToGroupForTimelineComponent = (props: ServicesAssignedToGroupForTimelineComponentProps) => (
      <ApolloReactComponents.Query<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables> query={ServicesAssignedToGroupForTimelineDocument} {...props} />
    );
    

/**
 * __useServicesAssignedToGroupForTimelineQuery__
 *
 * To run a query within a React component, call `useServicesAssignedToGroupForTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesAssignedToGroupForTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesAssignedToGroupForTimelineQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useServicesAssignedToGroupForTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>(ServicesAssignedToGroupForTimelineDocument, baseOptions);
      }
export function useServicesAssignedToGroupForTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>(ServicesAssignedToGroupForTimelineDocument, baseOptions);
        }
export type ServicesAssignedToGroupForTimelineQueryHookResult = ReturnType<typeof useServicesAssignedToGroupForTimelineQuery>;
export type ServicesAssignedToGroupForTimelineLazyQueryHookResult = ReturnType<typeof useServicesAssignedToGroupForTimelineLazyQuery>;
export type ServicesAssignedToGroupForTimelineQueryResult = ApolloReactCommon.QueryResult<ServicesAssignedToGroupForTimelineQuery, ServicesAssignedToGroupForTimelineQueryVariables>;
export const ProjectUsersDocument = gql`
    query ProjectUsers($projectId: ID!) {
  ProjectUsers(id: $projectId) {
    id
    name
    avatar
    email
    group
    role
  }
}
    `;
export type ProjectUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectUsersQuery, ProjectUsersQueryVariables>, 'query'> & ({ variables: ProjectUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectUsersComponent = (props: ProjectUsersComponentProps) => (
      <ApolloReactComponents.Query<ProjectUsersQuery, ProjectUsersQueryVariables> query={ProjectUsersDocument} {...props} />
    );
    

/**
 * __useProjectUsersQuery__
 *
 * To run a query within a React component, call `useProjectUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUsersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectUsersQuery, ProjectUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectUsersQuery, ProjectUsersQueryVariables>(ProjectUsersDocument, baseOptions);
      }
export function useProjectUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectUsersQuery, ProjectUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectUsersQuery, ProjectUsersQueryVariables>(ProjectUsersDocument, baseOptions);
        }
export type ProjectUsersQueryHookResult = ReturnType<typeof useProjectUsersQuery>;
export type ProjectUsersLazyQueryHookResult = ReturnType<typeof useProjectUsersLazyQuery>;
export type ProjectUsersQueryResult = ApolloReactCommon.QueryResult<ProjectUsersQuery, ProjectUsersQueryVariables>;
export const ProjectWithAllSamplesDocument = gql`
    query ProjectWithAllSamples($id: ID!) {
  ProjectWithAllSamples(id: $id) {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      selectedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        assignee
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        description
        services {
          id
          name
          assignee
          priority
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          price {
            price {
              value
            }
            currency
          }
          description
          serviceType
          durationLocked
          order
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
          collaborators {
            userIds
            groupIds
          }
        }
        collaborators {
          userIds
          groupIds
        }
      }
      status
      assignedUsers {
        id
        role
      }
      timelineLines {
        line
        name
      }
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
  }
}
    `;
export type ProjectWithAllSamplesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>, 'query'> & ({ variables: ProjectWithAllSamplesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectWithAllSamplesComponent = (props: ProjectWithAllSamplesComponentProps) => (
      <ApolloReactComponents.Query<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables> query={ProjectWithAllSamplesDocument} {...props} />
    );
    

/**
 * __useProjectWithAllSamplesQuery__
 *
 * To run a query within a React component, call `useProjectWithAllSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectWithAllSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectWithAllSamplesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectWithAllSamplesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>(ProjectWithAllSamplesDocument, baseOptions);
      }
export function useProjectWithAllSamplesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>(ProjectWithAllSamplesDocument, baseOptions);
        }
export type ProjectWithAllSamplesQueryHookResult = ReturnType<typeof useProjectWithAllSamplesQuery>;
export type ProjectWithAllSamplesLazyQueryHookResult = ReturnType<typeof useProjectWithAllSamplesLazyQuery>;
export type ProjectWithAllSamplesQueryResult = ApolloReactCommon.QueryResult<ProjectWithAllSamplesQuery, ProjectWithAllSamplesQueryVariables>;
export const ServicesDocument = gql`
    query Services {
  Services {
    id
    name
    description
    startDate
    endDate
    baseLineSize
    baseLine
    status
    serviceType
    durationLocked
    order
    hiddenOnTheList
    offerPrice {
      minPrice {
        value
      }
      maxPrice {
        value
      }
      currency
    }
  }
}
    `;
export type ServicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServicesQuery, ServicesQueryVariables>, 'query'>;

    export const ServicesComponent = (props: ServicesComponentProps) => (
      <ApolloReactComponents.Query<ServicesQuery, ServicesQueryVariables> query={ServicesDocument} {...props} />
    );
    

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        return ApolloReactHooks.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, baseOptions);
      }
export function useServicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, baseOptions);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = ApolloReactCommon.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const UserProjectsDocument = gql`
    query UserProjects {
  Projects {
    id
    name
    lastActivity
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          assignee
          priority
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      selectedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          assignee
          priority
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        assignee
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        services {
          id
          name
          assignee
          priority
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          description
          serviceType
          durationLocked
          collaborators {
            userIds
            groupIds
          }
          order
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
          collaborators {
            groupIds
            userIds
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      status
      assignedUsers {
        id
        role
      }
      timelineLines {
        line
        name
      }
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
  }
}
    `;
export type UserProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProjectsQuery, UserProjectsQueryVariables>, 'query'>;

    export const UserProjectsComponent = (props: UserProjectsComponentProps) => (
      <ApolloReactComponents.Query<UserProjectsQuery, UserProjectsQueryVariables> query={UserProjectsDocument} {...props} />
    );
    

/**
 * __useUserProjectsQuery__
 *
 * To run a query within a React component, call `useUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
      }
export function useUserProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
        }
export type UserProjectsQueryHookResult = ReturnType<typeof useUserProjectsQuery>;
export type UserProjectsLazyQueryHookResult = ReturnType<typeof useUserProjectsLazyQuery>;
export type UserProjectsQueryResult = ApolloReactCommon.QueryResult<UserProjectsQuery, UserProjectsQueryVariables>;
export const UserPlainProjectsDocument = gql`
    query UserPlainProjects {
  Projects {
    id
    name
    selectedQuestionGroups {
      questionSets {
        questions {
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    samples {
      id
      name
      assignedUsers {
        id
        role
      }
    }
  }
}
    `;
export type UserPlainProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>, 'query'>;

    export const UserPlainProjectsComponent = (props: UserPlainProjectsComponentProps) => (
      <ApolloReactComponents.Query<UserPlainProjectsQuery, UserPlainProjectsQueryVariables> query={UserPlainProjectsDocument} {...props} />
    );
    

/**
 * __useUserPlainProjectsQuery__
 *
 * To run a query within a React component, call `useUserPlainProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlainProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlainProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPlainProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>(UserPlainProjectsDocument, baseOptions);
      }
export function useUserPlainProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>(UserPlainProjectsDocument, baseOptions);
        }
export type UserPlainProjectsQueryHookResult = ReturnType<typeof useUserPlainProjectsQuery>;
export type UserPlainProjectsLazyQueryHookResult = ReturnType<typeof useUserPlainProjectsLazyQuery>;
export type UserPlainProjectsQueryResult = ApolloReactCommon.QueryResult<UserPlainProjectsQuery, UserPlainProjectsQueryVariables>;
export const UserPlainProjectsWithSamplesDocument = gql`
    query UserPlainProjectsWithSamples {
  Projects {
    id
    name
    lastActivity
    currentService {
      serviceName
      sampleName
      weekRemaining
    }
    samples {
      id
      name
      status
      enabledWorkflows {
        services {
          id
          status
        }
      }
      selectedQuestionGroups {
        questionSets {
          questions {
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      assignedUsers {
        id
        role
      }
    }
    selectedQuestionGroups {
      questionSets {
        questions {
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    users {
      id
    }
  }
}
    `;
export type UserPlainProjectsWithSamplesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>, 'query'>;

    export const UserPlainProjectsWithSamplesComponent = (props: UserPlainProjectsWithSamplesComponentProps) => (
      <ApolloReactComponents.Query<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables> query={UserPlainProjectsWithSamplesDocument} {...props} />
    );
    

/**
 * __useUserPlainProjectsWithSamplesQuery__
 *
 * To run a query within a React component, call `useUserPlainProjectsWithSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlainProjectsWithSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlainProjectsWithSamplesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPlainProjectsWithSamplesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>(UserPlainProjectsWithSamplesDocument, baseOptions);
      }
export function useUserPlainProjectsWithSamplesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>(UserPlainProjectsWithSamplesDocument, baseOptions);
        }
export type UserPlainProjectsWithSamplesQueryHookResult = ReturnType<typeof useUserPlainProjectsWithSamplesQuery>;
export type UserPlainProjectsWithSamplesLazyQueryHookResult = ReturnType<typeof useUserPlainProjectsWithSamplesLazyQuery>;
export type UserPlainProjectsWithSamplesQueryResult = ApolloReactCommon.QueryResult<UserPlainProjectsWithSamplesQuery, UserPlainProjectsWithSamplesQueryVariables>;
export const UserProjectDocument = gql`
    query UserProject($id: ID!) {
  Project(id: $id) {
    id
    name
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
          questionLevel
          isObligatory
          visibility
          placeholder
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
    }
    samples {
      id
      name
      events {
        id
        priority
        name
        description
        startDate
        endDate
        baseLineSize
        baseLine
        assignee
        collaborators {
          userIds
          groupIds
        }
        milestone
      }
      selectedQuestionGroups {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      suggestedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      selectedWorkflows {
        name
        id
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        questionGroupsToAddToSample {
          id
          name
          questionSets {
            id
            renderer
            questions {
              id
              question
              description
              choices
              order
              questionLevel
              isObligatory
              visibility
              visibilityPreconditions {
                isSatisfied
                questionId
                expectedAnswer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
              placeholder
              answer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
          }
        }
        services {
          id
          startDate
          endDate
          vendors {
            id
            name
          }
          baseLine
          baseLineSize
          status
          description
          name
          serviceType
          durationLocked
          order
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
        }
      }
      enabledWorkflows {
        name
        id
        assignee
        offerPrice {
          currency
          maxPrice {
            value
          }
          minPrice {
            value
          }
        }
        price {
          currency
          price {
            value
          }
        }
        description
        services {
          id
          name
          assignee
          priority
          scientists {
            id
            name
            preferences {
              preferredCurrency
            }
            organization
            schedule {
              id
            }
          }
          startDate
          endDate
          baseLine
          baseLineSize
          status
          price {
            price {
              value
            }
            currency
          }
          description
          serviceType
          durationLocked
          order
          vendor {
            id
            name
          }
          report {
            selectedQuestionGroups {
              id
              name
              questionSets {
                id
                renderer
                questions {
                  id
                  question
                  description
                  choices
                  order
                  questionLevel
                  isObligatory
                  visibility
                  visibilityPreconditions {
                    isSatisfied
                    questionId
                    expectedAnswer {
                      ... on MultiAnswer {
                        multiBody: body
                        answerType
                      }
                      ... on TextAnswer {
                        textBody: body
                        answerType
                      }
                      ... on NumericAnswer {
                        numericBody: body
                        answerType
                      }
                    }
                  }
                  placeholder
                  answer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
              }
            }
          }
          collaborators {
            userIds
            groupIds
          }
        }
        collaborators {
          userIds
          groupIds
        }
      }
      status
      assignedUsers {
        id
        role
      }
      timelineLines {
        line
        name
      }
    }
    users {
      schedule {
        id
      }
      id
      name
      organization
      preferences {
        preferredCurrency
      }
    }
    visibleTimelineElements {
      samples {
        id
        workflows {
          id
          services
        }
        events
      }
    }
  }
}
    `;
export type UserProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProjectQuery, UserProjectQueryVariables>, 'query'> & ({ variables: UserProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProjectComponent = (props: UserProjectComponentProps) => (
      <ApolloReactComponents.Query<UserProjectQuery, UserProjectQueryVariables> query={UserProjectDocument} {...props} />
    );
    

/**
 * __useUserProjectQuery__
 *
 * To run a query within a React component, call `useUserProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProjectQuery, UserProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProjectQuery, UserProjectQueryVariables>(UserProjectDocument, baseOptions);
      }
export function useUserProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProjectQuery, UserProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProjectQuery, UserProjectQueryVariables>(UserProjectDocument, baseOptions);
        }
export type UserProjectQueryHookResult = ReturnType<typeof useUserProjectQuery>;
export type UserProjectLazyQueryHookResult = ReturnType<typeof useUserProjectLazyQuery>;
export type UserProjectQueryResult = ApolloReactCommon.QueryResult<UserProjectQuery, UserProjectQueryVariables>;
export const WorkflowDocument = gql`
    query Workflow {
  Workflows {
    id
    name
    services {
      id
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      status
      serviceType
      durationLocked
      order
      offerPrice {
        minPrice {
          value
        }
        maxPrice {
          value
        }
        currency
      }
    }
    offerPrice {
      minPrice {
        value
      }
      maxPrice {
        value
      }
      currency
    }
    questionGroupsToAddToSample {
      id
      name
    }
  }
}
    `;
export type WorkflowComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WorkflowQuery, WorkflowQueryVariables>, 'query'>;

    export const WorkflowComponent = (props: WorkflowComponentProps) => (
      <ApolloReactComponents.Query<WorkflowQuery, WorkflowQueryVariables> query={WorkflowDocument} {...props} />
    );
    

/**
 * __useWorkflowQuery__
 *
 * To run a query within a React component, call `useWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkflowQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkflowQuery, WorkflowQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkflowQuery, WorkflowQueryVariables>(WorkflowDocument, baseOptions);
      }
export function useWorkflowLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkflowQuery, WorkflowQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkflowQuery, WorkflowQueryVariables>(WorkflowDocument, baseOptions);
        }
export type WorkflowQueryHookResult = ReturnType<typeof useWorkflowQuery>;
export type WorkflowLazyQueryHookResult = ReturnType<typeof useWorkflowLazyQuery>;
export type WorkflowQueryResult = ApolloReactCommon.QueryResult<WorkflowQuery, WorkflowQueryVariables>;
export const GetAuth0RolesDocument = gql`
    query GetAuth0Roles {
  GetAuth0Roles {
    id
    name
    description
  }
}
    `;
export type GetAuth0RolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>, 'query'>;

    export const GetAuth0RolesComponent = (props: GetAuth0RolesComponentProps) => (
      <ApolloReactComponents.Query<GetAuth0RolesQuery, GetAuth0RolesQueryVariables> query={GetAuth0RolesDocument} {...props} />
    );
    

/**
 * __useGetAuth0RolesQuery__
 *
 * To run a query within a React component, call `useGetAuth0RolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuth0RolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuth0RolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuth0RolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>(GetAuth0RolesDocument, baseOptions);
      }
export function useGetAuth0RolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>(GetAuth0RolesDocument, baseOptions);
        }
export type GetAuth0RolesQueryHookResult = ReturnType<typeof useGetAuth0RolesQuery>;
export type GetAuth0RolesLazyQueryHookResult = ReturnType<typeof useGetAuth0RolesLazyQuery>;
export type GetAuth0RolesQueryResult = ApolloReactCommon.QueryResult<GetAuth0RolesQuery, GetAuth0RolesQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers {
  ListUsers {
    id
    email
    name
    avatar
    organization
    role {
      id
      name
      description
    }
  }
}
    `;
export type ListUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListUsersQuery, ListUsersQueryVariables>, 'query'>;

    export const ListUsersComponent = (props: ListUsersComponentProps) => (
      <ApolloReactComponents.Query<ListUsersQuery, ListUsersQueryVariables> query={ListUsersDocument} {...props} />
    );
    

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
      }
export function useListUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = ApolloReactCommon.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($searchText: String!) {
  SearchUsers(searchText: $searchText) {
    id
    email
    name
    avatar
    organization
    role {
      id
      name
      description
    }
  }
}
    `;
export type SearchUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchUsersQuery, SearchUsersQueryVariables>, 'query'> & ({ variables: SearchUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchUsersComponent = (props: SearchUsersComponentProps) => (
      <ApolloReactComponents.Query<SearchUsersQuery, SearchUsersQueryVariables> query={SearchUsersDocument} {...props} />
    );
    

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const ServicesAssignedToUserForTimelineDocument = gql`
    query ServicesAssignedToUserForTimeline($userId: ID!) {
  ServicesAssignedToUserForTimeline(userId: $userId) {
    id
    name
    events {
      id
      priority
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      assignee
      collaborators {
        userIds
        groupIds
      }
      milestone
    }
    selectedQuestionGroups {
      id
      name
      questionSets {
        id
        renderer
        questions {
          id
          question
          description
          choices
          order
          questionLevel
          isObligatory
          visibility
          visibilityPreconditions {
            isSatisfied
            questionId
            expectedAnswer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
          placeholder
          answer {
            ... on MultiAnswer {
              multiBody: body
              answerType
            }
            ... on TextAnswer {
              textBody: body
              answerType
            }
            ... on NumericAnswer {
              numericBody: body
              answerType
            }
          }
        }
      }
    }
    suggestedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    selectedWorkflows {
      name
      id
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      questionGroupsToAddToSample {
        id
        name
        questionSets {
          id
          renderer
          questions {
            id
            question
            description
            choices
            order
            questionLevel
            isObligatory
            visibility
            visibilityPreconditions {
              isSatisfied
              questionId
              expectedAnswer {
                ... on MultiAnswer {
                  multiBody: body
                  answerType
                }
                ... on TextAnswer {
                  textBody: body
                  answerType
                }
                ... on NumericAnswer {
                  numericBody: body
                  answerType
                }
              }
            }
            placeholder
            answer {
              ... on MultiAnswer {
                multiBody: body
                answerType
              }
              ... on TextAnswer {
                textBody: body
                answerType
              }
              ... on NumericAnswer {
                numericBody: body
                answerType
              }
            }
          }
        }
      }
      services {
        id
        startDate
        endDate
        vendors {
          id
          name
        }
        baseLine
        baseLineSize
        status
        description
        name
        serviceType
        durationLocked
        order
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
      }
    }
    enabledWorkflows {
      name
      id
      assignee
      offerPrice {
        currency
        maxPrice {
          value
        }
        minPrice {
          value
        }
      }
      price {
        currency
        price {
          value
        }
      }
      services {
        id
        name
        assignee
        priority
        scientists {
          id
          name
          preferences {
            preferredCurrency
          }
          organization
          schedule {
            id
          }
        }
        startDate
        endDate
        baseLine
        baseLineSize
        status
        description
        serviceType
        durationLocked
        order
        vendor {
          id
          name
        }
        report {
          selectedQuestionGroups {
            id
            name
            questionSets {
              id
              renderer
              questions {
                id
                question
                description
                choices
                order
                questionLevel
                isObligatory
                visibility
                visibilityPreconditions {
                  isSatisfied
                  questionId
                  expectedAnswer {
                    ... on MultiAnswer {
                      multiBody: body
                      answerType
                    }
                    ... on TextAnswer {
                      textBody: body
                      answerType
                    }
                    ... on NumericAnswer {
                      numericBody: body
                      answerType
                    }
                  }
                }
                placeholder
                answer {
                  ... on MultiAnswer {
                    multiBody: body
                    answerType
                  }
                  ... on TextAnswer {
                    textBody: body
                    answerType
                  }
                  ... on NumericAnswer {
                    numericBody: body
                    answerType
                  }
                }
              }
            }
          }
        }
        collaborators {
          groupIds
          userIds
        }
      }
      collaborators {
        groupIds
        userIds
      }
    }
    status
    assignedUsers {
      id
      role
    }
    timelineLines {
      line
      name
    }
  }
}
    `;
export type ServicesAssignedToUserForTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>, 'query'> & ({ variables: ServicesAssignedToUserForTimelineQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ServicesAssignedToUserForTimelineComponent = (props: ServicesAssignedToUserForTimelineComponentProps) => (
      <ApolloReactComponents.Query<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables> query={ServicesAssignedToUserForTimelineDocument} {...props} />
    );
    

/**
 * __useServicesAssignedToUserForTimelineQuery__
 *
 * To run a query within a React component, call `useServicesAssignedToUserForTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesAssignedToUserForTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesAssignedToUserForTimelineQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useServicesAssignedToUserForTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>(ServicesAssignedToUserForTimelineDocument, baseOptions);
      }
export function useServicesAssignedToUserForTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>(ServicesAssignedToUserForTimelineDocument, baseOptions);
        }
export type ServicesAssignedToUserForTimelineQueryHookResult = ReturnType<typeof useServicesAssignedToUserForTimelineQuery>;
export type ServicesAssignedToUserForTimelineLazyQueryHookResult = ReturnType<typeof useServicesAssignedToUserForTimelineLazyQuery>;
export type ServicesAssignedToUserForTimelineQueryResult = ApolloReactCommon.QueryResult<ServicesAssignedToUserForTimelineQuery, ServicesAssignedToUserForTimelineQueryVariables>;
export const WorkflowsByOrganizationDocument = gql`
    query WorkflowsByOrganization($organization: String!) {
  WorkflowsByOrganization(organization: $organization) {
    id
    name
    services {
      id
      name
      description
      startDate
      endDate
      baseLineSize
      baseLine
      status
      serviceType
      durationLocked
      order
      offerPrice {
        minPrice {
          value
        }
        maxPrice {
          value
        }
        currency
      }
    }
    offerPrice {
      minPrice {
        value
      }
      maxPrice {
        value
      }
      currency
    }
    questionGroupsToAddToSample {
      id
      name
    }
  }
}
    `;
export type WorkflowsByOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>, 'query'> & ({ variables: WorkflowsByOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const WorkflowsByOrganizationComponent = (props: WorkflowsByOrganizationComponentProps) => (
      <ApolloReactComponents.Query<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables> query={WorkflowsByOrganizationDocument} {...props} />
    );
    

/**
 * __useWorkflowsByOrganizationQuery__
 *
 * To run a query within a React component, call `useWorkflowsByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowsByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowsByOrganizationQuery({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useWorkflowsByOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>(WorkflowsByOrganizationDocument, baseOptions);
      }
export function useWorkflowsByOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>(WorkflowsByOrganizationDocument, baseOptions);
        }
export type WorkflowsByOrganizationQueryHookResult = ReturnType<typeof useWorkflowsByOrganizationQuery>;
export type WorkflowsByOrganizationLazyQueryHookResult = ReturnType<typeof useWorkflowsByOrganizationLazyQuery>;
export type WorkflowsByOrganizationQueryResult = ApolloReactCommon.QueryResult<WorkflowsByOrganizationQuery, WorkflowsByOrganizationQueryVariables>;