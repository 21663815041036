import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { useAuth } from "react-use-auth";
import { ApolloProvider } from "react-apollo";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../../constants/routeConstants";
import { Spinner } from "../common/Spinner/Spinner";
import { getApolloClient } from "../apolloClient";
import { styledTheme } from "../../theme/theme";
import { AppRoutes } from "../../routing/Routes";
import { UserRolesContextProvider } from "../../contexts/UserRolesContext";
import { SideBarsContextProvider } from "../../contexts/SideBarsContext";
import { ActionContextProvider } from "../../contexts/ActionContext";
import { NoOrganizationAssignMessageBox } from "../NoOrganizationAssignMessageBox/NoOrganizationAssignMessageBox";

export const Authenticated = (): JSX.Element => {
  const { isAuthenticated, login, authResult, user } = useAuth();

  const runLoginAfter3s = (): void => {
    setTimeout(login, 3000);
  };

  const SimpleToastContainer = (): JSX.Element => (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
  );

  const weAreOnTheAuth0Callback =
    window &&
    window.location &&
    (window.location.pathname === ROUTES.AUTH0_CALLBACK ||
      window.location.pathname === ROUTES.HEALTHCHECK);

  if (isAuthenticated() || weAreOnTheAuth0Callback) {
    if (weAreOnTheAuth0Callback) {
      return (
        <ApolloProvider client={getApolloClient("")}>
          <ThemeProvider theme={styledTheme}>
            <UserRolesContextProvider>
              <SideBarsContextProvider>
                <ActionContextProvider>
                  <SimpleToastContainer />
                  <AppRoutes />
                </ActionContextProvider>
              </SideBarsContextProvider>
            </UserRolesContextProvider>
          </ThemeProvider>
        </ApolloProvider>
      );
    }

    if (!authResult) {
      return <Spinner />;
    }

    const organization = user["https://leaderneplatform.com/organization"];
    if (!organization) {
      return <NoOrganizationAssignMessageBox />;
    }

    return (
      <ApolloProvider
        client={getApolloClient(authResult.accessToken as string)}
      >
        <ThemeProvider theme={styledTheme}>
          <UserRolesContextProvider>
            <SideBarsContextProvider>
              <ActionContextProvider>
                <SimpleToastContainer />
                <AppRoutes />
              </ActionContextProvider>
            </SideBarsContextProvider>
          </UserRolesContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    );
  }

  return (
    <>
      <RedirectingMessage>
        You are not logged in, redirecting to the login page...
        {runLoginAfter3s()}
      </RedirectingMessage>
      <Spinner />
    </>
  );
};

const RedirectingMessage = styled.div`
  margin: 20px;
`;
