import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";
import { ReadQueryResult } from "../../types/QueryResult";
import { UserProjectDocument } from "../../../generated/graphql";

export const projectSampleCacheUpdater = (
  mutationName: string,
  cache: InMemoryCache,
  mutationResult: FetchResult,
  projectId: string,
  sampleId: string
): boolean => {
  const incomingData = mutationResult?.data?.[mutationName];
  const existingData = cache.readQuery<ReadQueryResult>({
    query: UserProjectDocument,
    variables: { id: projectId },
  })?.["Project"];

  if (existingData && incomingData) {
    const sampleIndex = existingData.samples.findIndex(
      (s) => s.id === sampleId
    );
    const samples = [...existingData.samples];
    if (sampleIndex >= 0) {
      samples[sampleIndex] = {
        ...samples[sampleIndex],
        ...incomingData,
      };
      cache.writeQuery({
        variables: { id: projectId },
        query: UserProjectDocument,
        data: {
          Project: {
            ...existingData,
            samples,
          },
        },
      });
      return true;
    }
  }
  return false;
};
