import * as React from "react";
import styled from "styled-components";

type TabsSeparatorProps = {};

const SVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1px;
`;

export const TabsSeparator = (props: TabsSeparatorProps) => {
  return (
    <SVGWrapper>
      <svg
        width="1"
        height="20"
        viewBox="0 0 1 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1" height="20" fill="#e8eaee" />
      </svg>
    </SVGWrapper>
  );
};
