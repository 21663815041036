import * as React from "react";
import { FieldProps } from "formik";
import { useState, useEffect } from "react";

import { Dropdown } from "../../common/Dropdown/Dropdown";
import { FormGroup } from "./FormGroup";
import {
  Option,
  QuestionMetadata,
} from "../../../utils/types/QuestionMetadata";
import {
  getFieldStatus,
  GetFieldStatusResultT,
} from "../../common/LabelTag/LabelTag";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

// const ErrorMessage = styled.div`
//   color: red;
// `;

export const SelectField = ({
  field,
  // form: { touched, errors, setFieldValue },
  ...props
}: SelectFieldProps) => {
  const {
    options,
    label,
    answerQuestionCallback,
    metadata,
    required,
    fieldStatus: fieldStatusCallback,
    disabled,
    isSearchable,
  } = props;

  const [fieldValue, setFieldValue] = useState(field.value);
  const [fieldStatus, setFieldStatus] = useState(
    getFieldStatus(required, field.value, fieldValue)
  );
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  useEffect(() => {
    setFieldValue(field.value);
  }, [field.value]);

  useEffect(() => {
    const currentStatus = getFieldStatus(required, field.value, fieldValue);
    setFieldStatus(currentStatus);
    fieldStatusCallback(currentStatus);
  }, [fieldValue, field.value]);

  const onChange = (option: any) => {
    setFieldValue(option.value);
    answerQuestionCallback(metadata, (option as Option).value);
  };

  return (
    <FormGroup label={label} status={fieldStatus}>
      <Dropdown
        {...field}
        {...props}
        options={options}
        value={
          (options
            ? options.find((option) => option.value === field.value)
            : "") as any
        }
        onChange={onChange}
        isDisabled={disabled}
        isSearchable={isSearchable}
      />
      {/* {touched[field.name] && errors[field.name] && ( */}
      {/*  <ErrorMessage>{errors[field.name]}</ErrorMessage> */}
      {/* )} */}
    </FormGroup>
  );
};

type SelectFieldProps = FieldProps & {
  label: string;
  options: Option[];
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled?: boolean;
  isSearchable?: boolean;
};
