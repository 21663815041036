import React, { ComponentType } from "react";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { SelectedWorkflowPath } from "../../../../utils/types/ActionContextTypes";
import {
  useGroupsQuery,
  useProjectUsersQuery,
} from "../../../../generated/graphql";
import { Spinner } from "../../../common/Spinner/Spinner";
import { CollaboratorOption } from "./types";

export function withCollaboratorSelectables<T>(Component: ComponentType<T>) {
  return (hocProps: Omit<T, "allCollaboratorsOptions">) => {
    const { selectedEntity } = useActionContext();
    const selectedElementPath =
      selectedEntity?.selectedElementPath as SelectedWorkflowPath;
    const { data: usersData, loading: usersLoading } = useProjectUsersQuery({
      skip:
        !selectedElementPath?.projectId ||
        selectedElementPath?.projectId === "tasksAssignedView",
      variables: {
        projectId: selectedElementPath.projectId,
      },
      fetchPolicy: "cache-first",
    });
    const { data: groupsData, loading: groupsLoading } = useGroupsQuery({
      fetchPolicy: "cache-first",
    });
    if (groupsLoading || usersLoading || !selectedEntity) {
      return <Spinner opaque />;
    }
    const allGroupOptions = groupsData?.Groups.map((group) => ({
      label: `Group: ${group.name}`,
      value: { id: group.id, name: group.name, type: "group", details: group },
    })) as CollaboratorOption[];
    const allUserOptions = usersData?.ProjectUsers.map((user) => ({
      label: user?.name,
      value: { id: user?.id, name: user?.name, type: "user", details: user },
    })) as CollaboratorOption[];
    const allCollaboratorsOptions = [
      ...(allGroupOptions ?? []),
      ...(allUserOptions ?? []),
    ];
    return (
      <Component
        {...(hocProps as T)}
        allCollaboratorsOptions={allCollaboratorsOptions}
      />
    );
  };
}
