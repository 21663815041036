import React, { useEffect } from "react";
import { Option } from "../../../utils/types/QuestionMetadata";
import { Priority } from "../../../generated/graphql";
import { DropdownWithColoredOptions } from "../DropdownWithColoredOptions/DropdownWithColoredOptions";

const dropdownOptions = [
  { value: "None", label: "None" },
  { value: Priority.High, label: "High" },
  { value: Priority.Medium, label: "Medium" },
  { value: Priority.Low, label: "Low" },
];

interface PriorityDropdownProps {
  readOnly?: boolean;
  priority: Priority | null;
  onPriorityChange: (priority: Priority | null) => void;
  className?: string;
}

export const PriorityDropdown = (props: PriorityDropdownProps): JSX.Element => {
  const { readOnly = false, priority, onPriorityChange, className } = props;

  const [selectedOption, setSelectedOption] = React.useState<Option>(
    dropdownOptions[0]
  );

  const onChange = (option: Option): void => {
    const newPriority = option.value === "None" ? null : option.value;
    onPriorityChange((newPriority as Priority) || null);
    // return (newPriority as Priority) || null;
  };

  const handleColorType = (colorValue: string): string => {
    switch (colorValue) {
      case Priority.High:
        return "background-color: #3b79d1;";
      case Priority.Medium:
        return "background-color: #91a9e3;";
      case Priority.Low:
        return "background-color: #d4dbf4;";
      default:
        return "";
    }
  };

  useEffect(() => {
    const option =
      dropdownOptions.find(
        (dropdownOption) => dropdownOption.value === priority
      ) || dropdownOptions[0];
    setSelectedOption(option);
  }, [priority]);

  return (
    <DropdownWithColoredOptions
      dropdownOptions={dropdownOptions}
      editMode={!readOnly}
      selectedOption={selectedOption}
      handleColorType={handleColorType}
      onChange={onChange}
      className={className}
    />
  );
};
