import React from "react";
import styled from "styled-components";
import { Spinner } from "../../common/Spinner/Spinner";
import { BlockHeader } from "./BlockHeader";

interface BlockWrapperProps {
  title?: string;
  children: React.ReactNode;
  loading?: boolean;
  headerComponent?: JSX.Element;
}

export const BlockWrapper = (props: BlockWrapperProps): JSX.Element => {
  const { title, children, loading, headerComponent } = props;

  return (
    <Container>
      <StyledBlockHeader title={title} headerComponent={headerComponent} />
      <ChildrenContainer>{children}</ChildrenContainer>
      {loading && (
        <SpinnerContainer>
          <Spinner containerSize opaque />
        </SpinnerContainer>
      )}
    </Container>
  );
};

const StyledBlockHeader = styled(BlockHeader)`
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Container = styled.div`
  width: 570px;
  height: 339px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #d1d5d8;
  border-radius: 8px;
  position: relative;
  border: 1px solid #dedede;
`;

const ChildrenContainer = styled.div`
  padding: 12px 20px;
  height: 234px;
  overflow: auto;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
