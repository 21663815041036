import * as React from "react";
import { RouteComponentProps } from "@reach/router";

export const ResultsList = (props: ResultsListProps) => {
  return <div>List of Results for project - {props.projectId}</div>;
};

interface ResultsListProps extends RouteComponentProps {
  projectId?: string;
}
