import React from "react";
import { defaultAxisHeight } from "./axisHelpers";
import { styledTheme } from "../../theme/theme";

interface DropLineProps {
  x: number;
  y?: number;
  height: number;
}

export const DropLine = ({
  x,
  y = defaultAxisHeight,
  height,
}: DropLineProps): JSX.Element => (
  <g id="dropLine" key="dropLine">
    <text x={x} y={y}>
      Hello There!
    </text>
    <line
      x1={x}
      x2={x}
      y1={y}
      y2={height}
      style={{ stroke: styledTheme.newColors.primary.basic, strokeWidth: 2 }}
    />
    <Triangle x={x} y={y} />
  </g>
);

const Triangle = ({ x, y }: { x: number; y: number }): JSX.Element => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    x={x - 6}
    y={y}
  >
    <path
      d="M5.34535 5.43305C5.72112 5.75848 6.27888 5.75848 6.65465 5.43305L10.9006 1.75593C11.6005 1.14979 11.1719 0 10.246 0H1.75402C0.828135 0 0.399465 1.14979 1.09937 1.75593L5.34535 5.43305Z"
      fill={styledTheme.newColors.primary.basic}
    />
  </svg>
);
