import React from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { styledTheme } from "../../../theme/theme";
import { useActionContext } from "../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedEventPath,
  SelectedOneOfManyPath,
  SelectedQuestionGroupPath,
  SelectedQuestionPath,
  SelectedServicePath,
  SelectedWorkflowPath,
} from "../../../utils/types/ActionContextTypes";
import { ColorChooser } from "./ColorChooser";
import { DotsDropdownMenu } from "./DotsDropdownMenu/DotsDropdownMenu";
import { ServiceForCustomer } from "../../../generated/graphql";
import { useUserRolesContext } from "../../../utils/hooks/useUserRolesContext";
import { ROLES } from "../../../constants/roles";

interface SmallInformationsContainerProps {
  numberOfExistingWorkflows?: number;
  mainQuestionGroupId?: string;
  userRoleInSample?: ROLES;
}

type BudgetData = { [key: string]: number };

export const SmallInformationsContainer = (
  props: SmallInformationsContainerProps
): JSX.Element => {
  const { numberOfExistingWorkflows, mainQuestionGroupId, userRoleInSample } =
    props;
  const { selectedEntity } = useActionContext();

  const { useUserPermissions } = useUserRolesContext();
  const budgetIsVisible = useUserPermissions("seeBudget", userRoleInSample);

  if (
    !selectedEntity &&
    numberOfExistingWorkflows &&
    numberOfExistingWorkflows > 0
  ) {
    return (
      <ColorChooser numberOfExistingWorkflows={numberOfExistingWorkflows} />
    );
  }

  if (!selectedEntity) {
    return <></>;
  }

  const calculateUsedBudgetInThisWorkflow = (): BudgetData => {
    const currentWorkflow = (
      selectedEntity.selectedElementPath as SelectedWorkflowPath
    ).workflow;
    const budgetSumInCurrencies = {};
    currentWorkflow.services.forEach((service) => {
      const priceData = (service as ServiceForCustomer).price;
      if (priceData) {
        if (!budgetSumInCurrencies[priceData.currency]) {
          budgetSumInCurrencies[priceData.currency] = 0;
        }
        budgetSumInCurrencies[priceData.currency] += parseFloat(
          priceData.price.value
        );
      }
    });

    return budgetSumInCurrencies;
  };

  const calculateRemainingBudget = (budgetData: BudgetData): BudgetData => {
    const remainingBudgetInCurrencies = {};
    const currentWorkflow = (
      selectedEntity.selectedElementPath as SelectedWorkflowPath
    ).workflow;
    const budgetCurrency = currentWorkflow.price?.currency;
    const budgetValue = currentWorkflow.price?.price.value;
    if (budgetCurrency && budgetValue) {
      remainingBudgetInCurrencies[budgetCurrency] = parseFloat(budgetValue);
    }
    Object.keys(budgetData).forEach((currency) => {
      if (!remainingBudgetInCurrencies[currency]) {
        remainingBudgetInCurrencies[currency] = 0;
      }
      remainingBudgetInCurrencies[currency] -= budgetData[currency];
    });

    return remainingBudgetInCurrencies;
  };

  const formatBudgetDataToString = (
    budgetSumInCurrencies: BudgetData
  ): string => {
    const outputArray: string[] = [];

    Object.keys(budgetSumInCurrencies).forEach((currency) => {
      const sumOfBudgets = budgetSumInCurrencies[currency];
      outputArray.push(`${sumOfBudgets}${currency}`);
    });

    return outputArray.join(", ");
  };

  let budgetData = {};
  let remainingBudget = {};
  let itemName = "";
  let headerContent = "";
  let headerAreaContent: JSX.Element | null = null;

  switch (selectedEntity.selectedElementType) {
    case SelectedElementType.QUESTION_GROUP:
      itemName = (
        selectedEntity.selectedElementPath as SelectedQuestionGroupPath
      ).questionGroupName;

      headerContent = "You edit";
      break;
    case SelectedElementType.QUESTION:
      itemName = (selectedEntity.selectedElementPath as SelectedQuestionPath)
        .questionName;

      headerContent = "You edit";
      break;
    case SelectedElementType.ONE_OF_MANY:
      itemName = (selectedEntity.selectedElementPath as SelectedOneOfManyPath)
        .questionName;

      headerContent = "Answers of";
      break;
    case SelectedElementType.SERVICE:
      itemName = (selectedEntity.selectedElementPath as SelectedServicePath)
        .workflowName;

      headerContent = "Part of";
      break;
    case SelectedElementType.WORKFLOW:
      if (budgetIsVisible) {
        budgetData = calculateUsedBudgetInThisWorkflow();
        remainingBudget = calculateRemainingBudget(budgetData);

        headerAreaContent = (
          <>
            <Header>BUDGET USED BY SUBTASKS</Header>
            <Content>{formatBudgetDataToString(budgetData)}</Content>
            <Header>BUDGET REMAINING</Header>
            <Content>{formatBudgetDataToString(remainingBudget)}</Content>
          </>
        );
      }

      break;
    case SelectedElementType.EVENT:
      itemName = (selectedEntity.selectedElementPath as SelectedEventPath).event
        .name;

      headerContent = "You edit";
      break;
    default:
      break;
  }

  return (
    <>
      <Container>
        {headerAreaContent || (
          <>
            <Header>{headerContent}</Header>
            <Content>{itemName}</Content>
          </>
        )}
      </Container>
      <IconsContainer>
        <DotsDropdownMenu
          isMenuOpen={false}
          mainQuestionGroupId={mainQuestionGroupId}
          userRoleInSample={userRoleInSample}
        />
      </IconsContainer>
    </>
  );
};

const Container = styled(Box)`
  margin-top: 21px;
  font-weight: bold;
`;

const Header = styled(Box)`
  font-size: 14px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.primary.basic};
`;

const Content = styled(Box)`
  font-size: 12px;
  text-transform: uppercase;
`;

const IconsContainer = styled(Flex)`
  position: absolute;
  bottom: 1rem;
  right: 2rem;
`;
