import * as React from "react";
import styled from "styled-components";
import { Flex } from "rebass";
import { ActiveIndicator } from "../../images/icons/ActiveIndicator";

const Container = styled(Flex)<{ isSideBarOpen: boolean }>`
  width: ${({ isSideBarOpen }) => (isSideBarOpen ? "184px" : "72px")};
  height: 40px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const ContentContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  margin-left: ${({ isActive }) => (isActive ? "24px" : "26px")};
  justify-content: center;
  align-items: center;

  font-weight: ${({ isActive }) => (isActive ? "800" : "normal")};
  font-size: 16px;
  line-height: 24px;
  color: ${({ isActive }) => (isActive ? "#FFFFFF" : "#6E7D92")};
`;

const IconContainer = styled.div`
  display: flex;
  margin: 0 18px 0 0;

  // & > svg:hover,
  // & > svg > path:hover {
  //   fill: ${({ theme }) => theme.newColors.grayscale.tertiaryGray};
  // }
`;

const NameContainer = styled.div``;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SideBarAction = (props: SideBarActionProps) => {
  const { icon, name, isActive, onClick, isSideBarOpen } = props;
  return (
    <Container onClick={onClick} isSideBarOpen={isSideBarOpen}>
      {isActive && (
        <Indicator>
          <ActiveIndicator />
        </Indicator>
      )}
      <ContentContainer isActive={isActive}>
        <IconContainer>{icon}</IconContainer>
        {isSideBarOpen && <NameContainer>{name}</NameContainer>}
      </ContentContainer>
    </Container>
  );
};

type SideBarActionProps = {
  name: string;
  isSideBarOpen: boolean;
  icon: React.ReactNode;
  isActive?: boolean;
  onClick?: any;
};
