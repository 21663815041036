import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const RightBigArrowIcon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.99975 17.9998C1.25575 17.9998 1.51175 17.9018 1.70675 17.7068L9.70675 9.70676C10.0978 9.31576 10.0978 8.68376 9.70675 8.29276L1.70675 0.292762C1.31575 -0.0982383 0.68375 -0.0982383 0.29275 0.292762C-0.0982494 0.683762 -0.0982494 1.31576 0.29275 1.70676L7.58575 8.99976L0.29275 16.2928C-0.0982494 16.6838 -0.0982494 17.3158 0.29275 17.7068C0.48775 17.9018 0.743751 17.9998 0.99975 17.9998Z"
        fill={color}
      />
    </svg>
  );
};
RightBigArrowIcon.defaultProps = {
  width: "10",
  height: "18",
  color: styledTheme.newColors.secondary.basic,
};
