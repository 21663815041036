import * as React from "react";

import { useAuth } from "react-use-auth";
import { RouteComponentProps } from "@reach/router";
import { Spinner } from "../common/Spinner/Spinner";

export const Auth0Callback = (props: RouteComponentProps) => {
  const { handleAuthentication } = useAuth();

  React.useEffect(() => {
    let postLoginRoute = "/";
    try {
      postLoginRoute = localStorage.getItem("gQ4QutxXcziDP3e2") || "/";
    } catch (err) {
      // do nothing
    }
    try {
      localStorage.setItem("gQ4QutxXcziDP3e2", "");
    } catch (err) {
      // do nothing
    }

    handleAuthentication({ postLoginRoute });
  }, []);

  return <Spinner />;
};

export default Auth0Callback;
