import React from "react";

type TextLinesProps = {
  text: string;
  maxChars: number;
  scale?: number;
  maxLines?: number;
};

export const TextLines = ({
  text = "",
  maxChars = 29,
  scale = 1,
  maxLines,
}: TextLinesProps): JSX.Element => {
  const tokens = text.split(" ");
  const lines: [string[]] = [[]];
  tokens.forEach((t) => {
    const lastLine = lines[lines.length - 1];
    const lastLineLength = lastLine.join(" ").length;
    if (lastLineLength > maxChars) {
      lines.push([t]);
    } else {
      lastLine.push(t);
    }
  });
  let linesFormatted = lines.map((line) => line.join(" "));
  if (maxLines && linesFormatted.length > maxLines) {
    linesFormatted = linesFormatted.slice(0, maxLines);
    linesFormatted[linesFormatted.length - 1] = `${
      linesFormatted[linesFormatted.length - 1]
    }…`;
  }
  return (
    <>
      {linesFormatted.map((fragment, i) => (
        <text
          key={fragment}
          y={i * 25 * scale + 10 * scale}
          fontSize={13 * scale}
        >
          {fragment}
        </text>
      ))}
    </>
  );
};
