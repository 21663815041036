import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ChevronDownIcon = (props: BaseIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16795 4.75192C3.56377 5.34566 4.43623 5.34566 4.83205 4.75192L6.96353 1.5547C7.40657 0.890145 6.93018 0 6.13148 0H1.86852C1.06982 0 0.59343 0.890145 1.03647 1.5547L3.16795 4.75192Z"
        fill={color}
      />
    </svg>
  );
};
ChevronDownIcon.defaultProps = {
  width: "8",
  height: "6",
  color: styledTheme.newColors.grayscale.primaryGray,
};
