import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "../../common/Modal/Modal";
import { ButtonType } from "../../common/Button/ButtonType";
import { Button } from "../../common/Button/Button";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { useSearchUsersQuery } from "../../../generated/graphql";
import { Option } from "../../../utils/types/QuestionMetadata";

type AddTeamMemberModalProps = {
  isOpen: boolean;
  onAccept: (selectedUsers: Option[]) => void;
  onClose: () => void;
};

export const AddTeamMemberModal = (
  props: AddTeamMemberModalProps
): JSX.Element => {
  const { isOpen, onAccept, onClose } = props;

  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<Option[]>([]);

  const { data, loading } = useSearchUsersQuery({
    fetchPolicy: "cache-first",
    variables: { searchText },
  });

  const closeModal = (): void => {
    setSearchText("");
    onClose();
  };

  const invite = (): void => {
    setSearchText("");
    setIsLoading(true);
    onAccept(selected);
  };

  const ModalButtons = (): JSX.Element => {
    return (
      <>
        <AcceptButton
          disabled={isLoading}
          label="Add Users"
          type={ButtonType.Primary}
          onClick={invite}
        />
        <Button
          disabled={isLoading}
          label="Cancel"
          type={ButtonType.Secondary}
          onClick={closeModal}
        />
      </>
    );
  };

  const onMenuClose = (): void => {
    setSearchText("");
  };

  const onChange = (options: Option[]): void => {
    setSelected(options);
  };

  const onInputChange = (value: string): void => {
    if (value.length >= 3) {
      setTimeout(() => {
        setSearchText(value);
      }, 1000);
    }
  };

  const getOptions = (): Option[] => {
    return data && data.SearchUsers
      ? data.SearchUsers.map((el) => ({
          value: el.id,
          label: `${el.name} - ${el.email}`,
        }))
      : [];
  };

  if (!isOpen) return <></>;

  return (
    <Modal
      id="addTeamMember"
      title="Add users to this Project"
      footerContent={<ModalButtons />}
      dialogClassName="customModalDialog"
      onClose={closeModal}
      isLoading={isLoading}
    >
      <StyledDropdown
        closeMenuOnSelect={false}
        isMulti
        isLoading={loading}
        isSearchable
        value={selected}
        options={getOptions()}
        noOptionsMessage={(): string =>
          "Search by name or email, type at least 3 letters"
        }
        placeholder="Search by name or email, type at least 3 letters"
        onInputChange={onInputChange}
        onChange={onChange}
        onMenuClose={onMenuClose}
      />
    </Modal>
  );
};

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;
