import React from "react";
import { EditEventInformations } from "./EditEventInformations";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { isSelectedEventPath } from "../../../../utils/types/ActionContextGuards";

type EditEventProps = {
  editMode?: boolean;
};

export const EditEvent = (props: EditEventProps): JSX.Element => {
  const { editMode = true } = props;
  const { selectedEntity } = useActionContext();

  if (
    !selectedEntity ||
    !isSelectedEventPath(selectedEntity.selectedElementPath)
  )
    return <></>;

  const { sampleId, originPathId } = selectedEntity.selectedElementPath;

  let edit = editMode;
  if (sampleId !== originPathId) edit = false;

  return <EditEventInformations editMode={edit} />;
};
