import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

type Props = BaseIconProps & {};

export const ServiceIcon = (props: Props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.151171 0.151171C0.352732 -0.0503902 0.679526 -0.0503902 0.881087 0.151171L8.25806 7.52815V0.516129C8.25806 0.231079 8.48914 7.69092e-09 8.77419 7.69092e-09C9.05924 7.69092e-09 9.29032 0.231079 9.29032 0.516129V8.77369C9.29032 8.78194 9.29013 8.79071 9.28973 8.79895C9.28692 8.85787 9.27406 8.9164 9.25116 8.97176C9.22597 9.03263 9.18864 9.08967 9.13915 9.13915C9.08967 9.18864 9.03263 9.22597 8.97176 9.25116C8.9118 9.27602 8.84614 9.28991 8.77729 9.29031L8.77419 9.29032H0.516129C0.231079 9.29032 7.69092e-09 9.05924 7.69092e-09 8.77419C7.69092e-09 8.48914 0.231079 8.25806 0.516129 8.25806H7.52815L0.151171 0.881087C-0.0503902 0.679526 -0.0503902 0.352732 0.151171 0.151171Z"
        fill={color}
      />
      <path
        d="M23.2258 9.29032L23.2227 9.29031C23.1539 9.28991 23.0882 9.27602 23.0282 9.25116C22.9674 9.22597 22.9103 9.18864 22.8608 9.13915C22.8114 9.08967 22.774 9.03263 22.7488 8.97176C22.7259 8.9164 22.7131 8.85787 22.7103 8.79895C22.7099 8.79071 22.7097 8.78245 22.7097 8.77419V0.516129C22.7097 0.231079 22.9408 7.69092e-09 23.2258 7.69092e-09C23.5109 7.69092e-09 23.7419 0.231079 23.7419 0.516129V7.52815L31.1189 0.151171C31.3205 -0.0503902 31.6473 -0.0503902 31.8488 0.151171C32.0504 0.352732 32.0504 0.679526 31.8488 0.881087L24.4719 8.25806H31.4839C31.7689 8.25806 32 8.48914 32 8.77419C32 9.05924 31.7689 9.29032 31.4839 9.29032H23.2258Z"
        fill={color}
      />
      <path
        d="M0.151171 31.1189L7.52815 23.7419H0.516129C0.231079 23.7419 7.69092e-09 23.5109 7.69092e-09 23.2258C7.69092e-09 22.9408 0.231079 22.7097 0.516129 22.7097H8.77419C8.84418 22.7097 8.9109 22.7236 8.97176 22.7488C9.03263 22.774 9.08967 22.8114 9.13915 22.8608C9.18864 22.9103 9.22597 22.9674 9.25116 23.0282C9.27406 23.0836 9.28692 23.1421 9.28973 23.201C9.29013 23.2093 9.29032 23.2176 9.29032 23.2258V31.4839C9.29032 31.7689 9.05924 32 8.77419 32C8.48914 32 8.25806 31.7689 8.25806 31.4839V24.4719L0.881087 31.8488C0.679526 32.0504 0.352732 32.0504 0.151171 31.8488C-0.0503902 31.6473 -0.0503902 31.3205 0.151171 31.1189Z"
        fill={color}
      />
      <path
        d="M31.8488 31.1189L24.4719 23.7419H31.4839C31.7689 23.7419 32 23.5109 32 23.2258C32 22.9408 31.7689 22.7097 31.4839 22.7097H23.2258C23.2176 22.7097 23.2093 22.7099 23.201 22.7103C23.1421 22.7131 23.0836 22.7259 23.0282 22.7488C22.9674 22.774 22.9103 22.8114 22.8608 22.8608C22.8114 22.9103 22.774 22.9674 22.7488 23.0282C22.7236 23.0891 22.7097 23.1558 22.7097 23.2258V31.4839C22.7097 31.7689 22.9408 32 23.2258 32C23.5109 32 23.7419 31.7689 23.7419 31.4839V24.4719L31.1189 31.8488C31.3205 32.0504 31.6473 32.0504 31.8488 31.8488C32.0504 31.6473 32.0504 31.3205 31.8488 31.1189Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 10.3226C12.8644 10.3226 10.3226 12.8644 10.3226 16C10.3226 19.1356 12.8644 21.6774 16 21.6774C19.1356 21.6774 21.6774 19.1356 21.6774 16C21.6774 12.8644 19.1356 10.3226 16 10.3226ZM11.3548 16C11.3548 13.4345 13.4345 11.3548 16 11.3548C18.5655 11.3548 20.6452 13.4345 20.6452 16C20.6452 18.5655 18.5655 20.6452 16 20.6452C13.4345 20.6452 11.3548 18.5655 11.3548 16Z"
        fill={color}
      />
    </svg>
  );
};

ServiceIcon.defaultProps = {
  width: "32",
  height: "32",
  color: styledTheme.newColors.grayscale.primaryGray,
};
