import { ApolloLink, FetchResult, Observable, Operation } from "apollo-link";
import type { OperationDefinitionNode } from "graphql";
import { Kind } from "graphql/language";

export default class TimeoutLink extends ApolloLink {
  private readonly timeout: number;

  constructor(timeout: number) {
    super();
    this.timeout = timeout;
  }

  request(
    operation: Operation,
    forward: (operation: Operation) => Observable<FetchResult>
  ): Observable<FetchResult> | null {
    const { operation: operationType } = operation.query.definitions.find(
      (definition) => definition.kind === Kind.OPERATION_DEFINITION
    ) as OperationDefinitionNode;

    if (operationType === "subscription") {
      // timeout should not apply to subscriptions
      return forward(operation);
    }

    // timeout value can be specified through the `context` object in useQuery/useMutation
    // value is in milliseconds and defaults to 30,000ms
    const requestTimeout = operation.getContext().timeout || this.timeout;

    if (operationType === "mutation") {
      return new Observable((subscriber) => {
        let timeoutId: number;

        const forwardSubscription = forward(operation).subscribe({
          next: (data) => {
            subscriber.next(data);
          },
          complete: () => {
            window.clearTimeout(timeoutId);
            subscriber.complete();
          },
          error: (err) => {
            window.clearTimeout(timeoutId);
            subscriber.error(err);
          },
        });

        timeoutId = window.setTimeout(() => {
          forwardSubscription.unsubscribe();
          subscriber.error(new Error("Timeout"));
        }, requestTimeout);

        return (): void => {
          window.clearTimeout(timeoutId);
          forwardSubscription.unsubscribe();
        };
      });
    }

    return forward(operation);
  }
}
