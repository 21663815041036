import React from "react";
import styled from "styled-components";
import { styledTheme } from "../../../theme/theme";

export const SamplePercent = (props: SamplePercentProps) => {
  const { percent, lineWidth, containerWidth, radius, color } = props;

  const position = radius + lineWidth / 2;
  const containerSize = radius * 2 + lineWidth;
  const circumference = radius * 2 * Math.PI;
  const percentOffset = circumference - (percent / 100) * circumference;

  return (
    <Container containerWidth={containerWidth}>
      <SvgContainer
        containerSize={containerSize}
        circumference={circumference}
        percentOffset={percentOffset}
        color={color}
      >
        <circle
          strokeWidth={`${lineWidth}px`}
          fill="transparent"
          r={`${radius}px`}
          cx={`${position}px`}
          cy={`${position}px`}
        />
        <circle
          strokeWidth={`${lineWidth}px`}
          fill="transparent"
          r={`${radius}px`}
          cx={`${position}px`}
          cy={`${position}px`}
        />
      </SvgContainer>
      <PercentContainer>{percent}%</PercentContainer>
    </Container>
  );
};

type SamplePercentProps = {
  percent: number;
  lineWidth: number;
  containerWidth: number | null;
  radius: number;
  color: string;
};

SamplePercent.defaultProps = {
  percent: 0,
  lineWidth: 3,
  containerWidth: 62,
  radius: 10,
  color: "#141418",
};

const Container = styled.div<{ containerWidth: number | null }>`
  display: flex;
  align-items: center;
  width: ${({ containerWidth }) => containerWidth}px;
  min-width: ${({ containerWidth }) => containerWidth}px;
  justify-content: space-between;
`;

const SvgContainer = styled.svg<{
  containerSize: number;
  circumference: number;
  percentOffset: number;
  color: string;
}>`
  width: ${(props) =>
    props.containerSize ? `${props.containerSize}px` : "unset"};
  height: ${(props) =>
    props.containerSize ? `${props.containerSize}px` : "unset"};

  circle:nth-child(1) {
    stroke: #eef1f5;
  }

  circle:nth-child(2) {
    stroke-dasharray: ${(props) =>
      `${props.circumference} ${props.circumference}`};
    stroke-dashoffset: ${(props) => props.percentOffset};

    // axis compensation
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: ${({ color }) => color};
  }
`;

const PercentContainer = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  color: ${styledTheme.newColors.grayscale.primaryGray};
`;
