import * as React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import _uniqueId from "lodash/uniqueId";

type Props = TooltipProps & {
  children: React.ReactNode;
};

export type TooltipProps = {
  text: string;
  place: "bottom" | "top" | "right" | "left";
};

export const Tooltip = (props: Props) => {
  const { children, text, place } = props;
  const id = _uniqueId();
  return (
    <>
      <Icon data-tip data-for={id} data-event="click focus">
        {children}
      </Icon>
      <TooltipStyled
        id={id}
        globalEventOff="click"
        place={place}
        effect="solid"
      >
        <TipText>{text}</TipText>
      </TooltipStyled>
    </>
  );
};

const TipText = styled.div`
  width: 200px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ theme }) => theme.space[2]};
  cursor: pointer;
`;

const TooltipStyled = styled(ReactTooltip)`
  color: #232f3c !important;
  background-color: ${({ theme }) =>
    theme.newColors.grayscale.white} !important;
  border: 1px solid ${({ theme }) => theme.newColors.grayscale.bordersInside} !important;
  box-sizing: border-box !important;
  box-shadow: ${({ theme }) =>
    theme.shadows.userInputs.hoveredState} !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;

  letter-spacing: -0.01em !important;

  &.place-top {
    &:before {
      bottom: -10px !important;
      border-top: 10px solid transparent !important;
      border-top-color: ${({ theme }) =>
        theme.newColors.grayscale.bordersInside} !important;
    }

    &:after {
      bottom: -9px !important;
      border-top: 9px solid transparent !important;
      border-top-color: ${({ theme }) =>
        theme.newColors.grayscale.white} !important;
    }
  }

  &.place-bottom {
    &:before {
      top: -20px !important;
      border: 10px solid transparent !important;
      border-bottom-color: ${({ theme }) =>
        theme.newColors.grayscale.bordersInside} !important;
    }

    &:after {
      top: -18px !important;
      left: 49.5% !important;
      border: 9px solid transparent !important;
      border-bottom-color: ${({ theme }) =>
        theme.newColors.grayscale.white} !important;
    }
  }

  &.place-right {
    &:before {
      left: -10px !important;
      border-right: 10px solid transparent !important;
      border-right-color: ${({ theme }) =>
        theme.newColors.grayscale.bordersInside} !important;
    }

    &:after {
      left: -8px !important;
      border-right: 9px solid transparent !important;
      border-right-color: ${({ theme }) =>
        theme.newColors.grayscale.white} !important;
    }
  }

  &.place-left {
    &:before {
      right: -10px !important;
      border-left: 10px solid transparent !important;
      border-left-color: ${({ theme }) =>
        theme.newColors.grayscale.bordersInside} !important;
    }

    &:after {
      right: -8px !important;
      border-left: 9px solid transparent !important;
      border-left-color: ${({ theme }) =>
        theme.newColors.grayscale.white} !important;
    }
  }
`;
