import React from "react";
import styled, { css } from "styled-components";
import { DragIcon } from "../../../../images/icons/DragIcon";
import { styledTheme } from "../../../../theme/theme";

type FormQuestionProps = {
  children: React.ReactNode;
  copy?: boolean;
};

export const FormQuestion = (props: FormQuestionProps) => {
  const { children, copy } = props;

  return (
    <FormElementContainer copy={copy || false}>
      {children}
      <DragIconContainer>
        <DragIcon />
      </DragIconContainer>
    </FormElementContainer>
  );
};

const FormElementContainer = styled.div<{ copy: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  width: 100%;

  ${(copy) =>
    copy &&
    css`
      opacity: 0.5;

      ~ div {
        transform: none !important;
      }

      ~ [data-rbd-placeholder-context-id] {
        display: none !important;
      }
    `}
`;

const DragIconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
`;
