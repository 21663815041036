export interface ITheme {
  newColors: { [key: string]: { [key: string]: string } };
  shadows: { [key: string]: { [key: string]: string } };
  colors: { [key: string]: string };
  fontSizes: string[];
  lineHeights: string[];
  space: string[];
  maxContentWidth: string;
  radius: { [key: string]: string };
  fonts: { [key: string]: string };
  fontWeights: { [key: string]: string | number };
}

export const styledTheme: ITheme = {
  newColors: {
    primary: {
      basic: "#3B79D1",
      hover: "#1F5FBC",
      darkestTint: "#204B88",
      darkTint: "#2A62B2",
      lightTint: "#EBF2FA",
      lightestTint: "#F5F8FD",
    },
    secondary: {
      basic: "#52B79F",
      hover: "#43A28B",
      darkestTint: "#347E6C",
      darkTint: "#43A28B",
      lightTint: "#EEF8F5",
      lightestTint: "#F6FBFA",
    },
    grayscale: {
      primaryGray: "#232F3C",
      secondaryGray: "#77808E",
      tertiaryGray: "#6E7D92",
      placeholder: "#ADB1BC",
      disabled: "#E7E7E7",
      bordersInside: "#DEDEDE",
      bordersOutside: "#D1D5D8",
      background: "#E8EAEE",
      hoverAreaOnDark: "rgba(255, 255, 255, 0.05)",
      white: "#FFFFFF",
    },
    extra: {
      tertiaryButtonBG: "#F9FAFC",
      successBasic: "#31A354",
      successArea: "#EDF8E9",
      errorState: "#DE2D26",
      errorHovered: "#CC1A1F",
      errorActive: "#AA1A1F",
      warningState: "#EAB838",
      selectedTaskOverlay: "rgba(59, 121, 209, 0.2)",
      selectedTaskBorder: "rgba(59, 121, 209, 0.75)",
      selectedBlock: "#D7E4F6",
    },
  },
  shadows: {
    dropShadows: {
      modal: "0px 4px 8px rgba(43, 115, 220, 0.3)",
      formCard: "0px 1px 0px #D1D5D8",
      expander: "0px 4px 8px rgba(43, 115, 220, 0.3)",
    },
    userInputs: {
      basicInside: "0px 1px 2px rgba(220, 222, 229, 0.3)",
      basicOutside: "0px 1px 2px rgba(218, 220, 226, 0.7)",
      activeState: "0px 1px 2px rgba(59, 121, 209, 0.2)",
      hoveredState: "0px 2px 4px rgba(220, 222, 229, 0.6)",
      errorState: "0px 1px 2px rgba(222, 45, 38, 0.2)",
    },
    sideDrawboard: {
      basicLeftOutisde: "-2px 1px 2px rgba(218, 220, 226, 0.7)",
    },
    icons: {
      expandIcon: "0 4px 8px 0 rgba(43, 115, 220, 0.3)",
    },
    dropdowns: {
      menu: "0 1px 8px rgba(0, 0, 0, 0.3)",
    },
  },
  colors: {
    gray05: "#E4E4E4",
    white: "#fff",
    whiteBg: "#fff",
    lightBg: "#E8EAEE",
    darkBg: "#232F3C",
    blackBg: "#18202e",
    lightText: "#9D9EA3",
    athensGray: "#f0f3f6",
    frenchGray: "#c5c6cb",
    inputError: "#DE1C22",
    inputDisabled: "#e4e4e4",
    selectHover: "#888",
    lightGreen: "#52b79f",
    greySecondary: "#6C727F",
    tertiaryGray: "#6E7D92",
  },
  lineHeights: ["12px", "14px", "16px", "20px", "24px"],
  fontSizes: [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "32px",
    "48px",
    "64px",
  ],
  space: ["0px", "4px", "8px", "16px", "32px", "64px", "128px", "256px"],
  maxContentWidth: "1208px",
  radius: {
    default: "8px",
  },
  fonts: {
    body: "Overpass, sans-serif",
    heading: "inherit",
  },
  fontWeights: {
    body: "normal",
    heading: 800,
  },
};
