import React from "react";
import styled from "styled-components";
import { RouteComponentProps } from "@reach/router";
import moment from "moment";

import { LayoutDefault } from "../../Layouts/LayoutDefault";
import { ProjectsHeader } from "../common/ProjectsHeader";
import { ProjectsListElement } from "./ProjectsListElement";
import { styledTheme } from "../../../theme/theme";
import ServerError from "../../ServerError";
// import {
//   // projectsHaveOngoingSamples,
//   projectHasOngoingSamples,
// } from "../../../utils/helpers/projectHelper";
import {
  Project,
  Sample,
  useUserPlainProjectsWithSamplesQuery,
} from "../../../generated/graphql";
import { Spinner } from "../../common/Spinner/Spinner";
import {
  convertFractionToPercent,
  getSampleProgress,
} from "../../../utils/helpers/sampleProjectHelper";
import { getProjectName } from "../../../utils/helpers/getProjectName";
import { getSampleName } from "../../../utils/helpers/getSampleName";

export const ProjectsList = (props: ProjectsListProps) => {
  const queryResult = useUserPlainProjectsWithSamplesQuery({
    variables: {},
  });

  // useEffect(() => {
  //   const projects = (queryResult.data && queryResult.data.Projects) || [];
  //   if (projects.length && !projectsHaveOngoingSamples(projects)) {
  //     navigate(`/projects/${projects[0].id}/configurator`);
  //   }
  // }, [JSON.stringify(queryResult.data)]);

  if (queryResult.error) {
    return <ServerError />;
  }
  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const projects = queryResult.data.Projects || [];
  const projectsNumber = projects.length;

  return (
    <LayoutDefault pageTitle="All projects">
      <ProjectsHeader projectsNumber={projectsNumber} selectedView="list" />
      <ProjectsContainer>
        {projects.map((project) => {
          const samples = project.samples.map((sample) => ({
            ...sample,
            name: getSampleName(sample as unknown as Sample),
            percent: convertFractionToPercent(
              getSampleProgress(sample as unknown as Sample)
            ),
          }));

          // const projectOnClick = !projectHasOngoingSamples(project)
          //   ? (): void => {
          //       navigate(`/projects/${project.id}/configurator`);
          //     }
          //   : undefined;

          const projectOnClick = undefined;

          const projectLastActivityDateString = moment(
            project.lastActivity
              ? parseInt(project.lastActivity, 10)
              : undefined
          ).format("Do MMMM YYYY");

          return (
            <ProjectsListElement
              key={project.id}
              samples={samples}
              projectLastActivity={projectLastActivityDateString}
              projectId={project.id}
              projectName={getProjectName(project as unknown as Project)}
              onClick={projectOnClick}
            />
          );
        })}
      </ProjectsContainer>
    </LayoutDefault>
  );
};

type ProjectsListProps = RouteComponentProps;

const ProjectsContainer = styled.div`
  > div {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  }

  > div:last-child {
    border-bottom: 0;
  }
`;
