import React from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { SendMessageIcon } from "../../../../images/icons/SendMessageIcon";
import { UserAvatar } from "../../../common/UserAvatar/UserAvatar";
import { TeamUser } from "./useTeamBlock";

interface TeamTableProps {
  users: TeamUser[];
}

export const TeamTable = (props: TeamTableProps): JSX.Element => {
  const { users } = props;

  return (
    <Table>
      <tbody>
        {users.map((user) => {
          return (
            <Row key={user.id}>
              <UserCell>
                <div>
                  <SendMessageContainer
                    aria-hidden
                    onClick={(): void => {
                      window.location.href = `mailto:${user.email}`;
                    }}
                  >
                    <SendMessageIcon />
                  </SendMessageContainer>
                  <StyledAvatar user={{ picture: user.avatar }} />
                  {user.name}
                </div>
              </UserCell>
              <RoleCell>{user.role}</RoleCell>
              <SeeTasksCell
                onClick={(): void => {
                  navigate(`/user-tasks/${user.id}`);
                }}
              >
                See tasks &#10140;
              </SeeTasksCell>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr:last-child {
    td {
      padding-bottom: 0;
    }
  }
`;

const Row = styled.tr`
  gap: 12px;
  font-size: 12px;

  td {
    padding-bottom: 16px;
  }
`;

const UserCell = styled.td`
  white-space: nowrap;
  width: 1%;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StyledAvatar = styled(UserAvatar)`
  margin-right: 12px;
`;

const SendMessageContainer = styled.div`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dedede;
  padding-right: 4px;
  cursor: pointer;

  &:hover > svg > path {
    transition: 200ms;
    fill: #1856c0;
  }
`;

const RoleCell = styled.td`
  text-align: center;
  line-height: 32px;
`;

const SeeTasksCell = styled.td`
  cursor: pointer;
  color: #4979cb;
  padding-bottom: 6px;
  padding-right: 4px;
  text-decoration: underline;
  text-underline-offset: 6px;
  white-space: nowrap;
  width: 1%;

  &:hover {
    transition: 200ms;
    color: #1856c0;
  }
`;
