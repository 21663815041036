import * as React from "react";
import { useState } from "react";
import {
  ContentSection,
  ContentSectionProps,
} from "../../../../common/ContentSection/ContentSection";
import { ServicePackagesConfigurator } from "../../../../../containers/ServicePackagesConfigurator";
import { Button } from "../../../../common/Button/Button";
import { ServicePackagesConfiguratorProps } from "../ServicePackages/ServicePackages";
import { EnableWorkflowAction } from "../../../../../utils/types/WorkflowMetadata";
import { Spinner } from "../../../../common/Spinner/Spinner";

type Props = ContentSectionProps &
  ServicePackagesConfiguratorProps & {
    enableWorkflowsForProjectSample?: EnableWorkflowAction;
    servicePackagesAlreadyAdded: boolean;
    goToNextGroupCallback?: () => void;
    removeDisabled: boolean;
  };

export const ServicePackagesSection = ({
  enableWorkflowsForProjectSample,
  ...props
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedWorkflowsIds, setSelectedWorkflowsIds] = useState<string[]>(
    props.selectedWorkflowsIds || []
  );

  const selectWorkflow = (id: string) => {
    setSelectedWorkflowsIds([...selectedWorkflowsIds, id]);
  };

  const removeWorkflow = (id: string) => {
    const array = [...selectedWorkflowsIds];
    const index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedWorkflowsIds(array);
    }
  };

  const workflowAction = (id: string, unselect: boolean) => {
    if (unselect) {
      removeWorkflow(id);
    } else {
      selectWorkflow(id);
    }
  };

  const enableWorkflowsForProjectSampleAndGoToNextCard =
    async (): Promise<void> => {
      setShowSpinner(true);
      if (enableWorkflowsForProjectSample) {
        await enableWorkflowsForProjectSample(selectedWorkflowsIds);
      }
      if (props.goToNextGroupCallback) {
        props.goToNextGroupCallback();
      }
      setShowSpinner(false);
    };

  const getServiceConfiguratorSectionButtons = (): React.ReactNode[] => {
    const buttonAction = props.servicePackagesAlreadyAdded
      ? props.goToNextGroupCallback
      : enableWorkflowsForProjectSampleAndGoToNextCard;

    const buttonArray: JSX.Element[] = [];

    if (props.goToNextGroupCallback) {
      buttonArray.push(
        <Button
          label="Go to next section"
          onClick={props.goToNextGroupCallback}
        />
      );
    }

    buttonArray.push(
      <Button
        label="Configure your service packages"
        onClick={buttonAction}
        disabled={selectedWorkflowsIds.length === 0}
      />
    );

    return buttonArray;
  };

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <ContentSection
        {...props}
        buttons={getServiceConfiguratorSectionButtons()}
      >
        <ServicePackagesConfigurator
          workflowAction={workflowAction}
          selectedWorkflowsIds={selectedWorkflowsIds}
          {...props}
        />
      </ContentSection>
    </>
  );
};
