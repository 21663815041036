import * as React from "react";
import styled from "styled-components";
import { getStatusIcon, StatusType } from "./getStatusIcon";
import { EventHandlerProps } from "../../utils/types/EventHandlerPropsType";

export const Tab = (props: TabProps) => {
  const { isActive, label, onClick, status } = props;
  return (
    <Container onClick={onClick} isActive={isActive}>
      <Content>
        <Statuses>{status !== undefined ? getStatusIcon(status) : ""}</Statuses>
        <Label>{label}</Label>
      </Content>
    </Container>
  );
};

type TabProps = EventHandlerProps & {
  label: string;
  isActive?: boolean;
  lastOne?: boolean;
  status?: StatusType;
};

Tab.defaultProps = {
  label: "Project data",
  isActive: false,
  status: undefined,
};

const Container = styled.div<{ isActive?: boolean }>`
  width: auto;
  display: inline-block;
  height: 36px;
  background: ${(props) =>
    props.isActive ? props.theme.newColors.grayscale.background : "none"};
  border-radius: 4px 4px 0 0;
  font-weight: ${(props) => (props.isActive ? "800" : "normal")};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.newColors.grayscale.background};
    border-radius: 4px 4px 0px 0px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

const Statuses = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
`;

const Label = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.newColors.grayscale.primaryGray};
  white-space: nowrap;
`;
