
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "BaseAnswer",
        "possibleTypes": [
          {
            "name": "TextAnswer"
          },
          {
            "name": "NumericAnswer"
          },
          {
            "name": "MultiAnswer"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseService",
        "possibleTypes": [
          {
            "name": "Service"
          },
          {
            "name": "ServiceForCustomer"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BaseWorkflow",
        "possibleTypes": [
          {
            "name": "Workflow"
          },
          {
            "name": "WorkflowForCustomer"
          }
        ]
      }
    ]
  }
};
      export default result;
    