import React from "react";
import { isEqual } from "lodash";
import { InMemoryCache } from "apollo-cache-inmemory";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import {
  Collaborators,
  UserProjectDocument,
  useUpdateServiceFromWorkflowMutation,
  WorkflowForCustomer,
} from "../../../../generated/graphql";
import { SelectedServicePath } from "../../../../utils/types/ActionContextTypes";
import { withCollaboratorSelectables } from "../Collaborators/withCollaboratorSelectables";
import { CollaboratorOption } from "../Collaborators/types";
import { EditSubtaskCollaborators } from "./EditSubtaskCollaborators";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";
import { ReadQueryResult } from "../../../../utils/types/QueryResult";
import { updateServices } from "../../../../utils/helpers/updateServices";

function EditSubtaskCollaboratorsWrapperPure({
  allCollaboratorsOptions,
  parentEnabledWorkflow,
  editMode = true,
}: {
  allCollaboratorsOptions: CollaboratorOption[];
  parentEnabledWorkflow: WorkflowForCustomer | undefined;
  editMode: boolean;
}): JSX.Element {
  const { selectedEntity } = useActionContext();
  const { useUserPermissions } = useUserRolesContext();

  const selectedElementPath =
    selectedEntity?.selectedElementPath as SelectedServicePath;

  const [updateServiceFromWorkflow] = useUpdateServiceFromWorkflowMutation({
    refetchQueries: ["UserProject"],
  });
  const currentWorkflowCollaborators = parentEnabledWorkflow?.collaborators;
  const currentServiceCollaborators = selectedElementPath.service.collaborators;

  const editServiceInformationsIsEnabled = useUserPermissions(
    "editServiceInformations"
  );

  const callUpdateServiceFromWorkflow = (
    collaboratorOptions: CollaboratorOption[]
  ): void => {
    if (!editServiceInformationsIsEnabled) {
      return;
    }

    const updatedCollaborators = {
      groupIds: collaboratorOptions
        .filter((x) => x.value.type === "group")
        .map((x) => x.value.id),
      userIds: collaboratorOptions
        .filter((x) => x && x.value.type === "user")
        .map((x) => x.value.id),
    };
    if (!isEqual(updatedCollaborators, currentServiceCollaborators)) {
      updateServiceFromWorkflow({
        variables: {
          projectSampleInput: {
            projectId: selectedElementPath.projectId,
            sampleId: selectedElementPath.sampleId,
          },
          workflowInput: {
            workflowId: selectedElementPath.workflowId,
            workflowDesignation: "enabledWorkflows",
          },
          serviceUpdatables: {
            id: selectedElementPath.service.id,
            collaborators: updatedCollaborators,
          },
        },
        refetchQueries: ["ServicesAssignedToGroupForTimeline"],
        update(cache: InMemoryCache, { data }: any) {
          const incomingData = data?.["UpdateServiceFromWorkflow"];
          const existingQueryResult = cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: selectedElementPath.projectId },
          })?.["Project"];

          if (existingQueryResult && incomingData) {
            const samplesHere = [...existingQueryResult.samples];
            const sampleHere = samplesHere.find(
              (s) => s.id === selectedElementPath.sampleId
            );

            const updatedEnabledWorkflows = sampleHere?.enabledWorkflows.map(
              (workflow) => ({
                ...workflow,
                services: updateServices(workflow.services, incomingData),
              })
            );

            const newSamples = samplesHere.map((sampleLoop) => {
              if (sampleLoop.id === selectedElementPath.sampleId) {
                return {
                  ...sampleLoop,
                  enabledWorkflows: updatedEnabledWorkflows,
                };
              }
              return sampleLoop;
            });

            cache.writeQuery({
              variables: { id: selectedElementPath.projectId },
              query: UserProjectDocument,
              data: {
                Project: {
                  ...existingQueryResult,
                  samples: newSamples,
                },
              },
            });
          }
        },
      });
    }
  };

  const getCurrentCollaborators = (
    serviceCollaborators: Collaborators | null | undefined,
    workflowCollaborators: Collaborators | null | undefined
  ): CollaboratorOption[] => {
    let entityToUse;
    if (
      serviceCollaborators &&
      (serviceCollaborators.userIds.length ||
        serviceCollaborators.groupIds.length)
    ) {
      entityToUse = serviceCollaborators;
    } else {
      entityToUse = workflowCollaborators || { userIds: [], groupIds: [] };
    }
    return [...(entityToUse?.groupIds || []), ...(entityToUse?.userIds || [])]
      .map((eId) => allCollaboratorsOptions?.find((cg) => cg.value.id === eId))
      .filter(Boolean) as CollaboratorOption[];
  };

  return (
    <EditSubtaskCollaborators
      updateServiceFromWorkflow={callUpdateServiceFromWorkflow}
      allCollaboratorsOptions={allCollaboratorsOptions}
      currentCollaborators={getCurrentCollaborators(
        currentServiceCollaborators,
        currentWorkflowCollaborators
      )}
      serviceId={selectedElementPath.service.id}
      editMode={editMode}
    />
  );
}

export const EditSubtaskCollaboratorsWrapper = withCollaboratorSelectables(
  EditSubtaskCollaboratorsWrapperPure
);
