import * as React from "react";
import styled from "styled-components";
import { EventHandlerProps } from "../../utils/types/EventHandlerPropsType";
import { styledTheme } from "../../theme/theme";

type Props = EventHandlerProps & {};
export const NewTabButton = (props: Props): JSX.Element => {
  return (
    <Container onClick={props.onClick}>
      <AddIcon /> <Label>Add new path</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  user-select: none;

  :hover {
    font-weight: 800;
  }
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }): string => theme.newColors.primary.basic};
  margin-left: 8px;
  min-width: 110px;
`;

const AddIcon = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        fill={styledTheme.newColors.primary.lightestTint}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8888 6.6665H11.111V11.1108H6.6665V12.8886H11.111V17.3332H12.8888V12.8886H17.3332V11.1108H12.8888V6.6665Z"
        fill={styledTheme.newColors.primary.basic}
      />
    </svg>
  );
};
