import {
  Event,
  ServiceForCustomer,
  WorkflowForCustomer,
} from "generated/graphql";

export type SelectedElementPath =
  | SelectedServicePath
  | SelectedWorkflowPath
  | SelectedQuestionPath
  | SelectedReportQuestionPath
  | SelectedOneOfManyPath
  | SelectedEventPath
  | SelectedQuestionGroupPath
  | SelectedReportQuestionGroupPath;

export type SelectedServicePath = {
  sampleId: string;
  projectId: string;
  service: ServiceForCustomer; // TODO WILK verify with Pingwin :)
  workflowId: string;
  workflowName: string;
  visibleOnAllPaths?: boolean;
  originPathId?: string;
};

export type SelectedWorkflowPath = {
  sampleId: string;
  projectId: string;
  workflow: WorkflowForCustomer;
  workflowId: string;
  workflowName: string;
};

export type SelectedQuestionPath = {
  projectId: string;
  sampleId: string;
  questionGroupId: string;
  questionId: string;
  questionSetId: string;
  questionName: string;
  questionDescription: string;
  questionIsObligatory: boolean;
};

export type SelectedReportQuestionPath = {
  projectId: string;
  sampleId: string;
  questionGroupId: string;
  questionId: string;
  questionSetId: string;
  questionName: string;
  questionDescription: string;
  fromReport: boolean;
  workflowId: string;
  serviceId: string;
};

export type SelectedOneOfManyPath = {
  projectId: string;
  sampleId: string;
  questionGroupId: string;
  questionId: string;
  questionSetId: string;
  questionName: string;
  questionChoices: string[];
};

export type SelectedQuestionGroupPath = {
  questionGroupId: string;
  questionGroupName: string;
  projectId: string;
  sampleId: string;
};

export type SelectedReportQuestionGroupPath = {
  questionGroupId: string;
  questionGroupName: string;
  projectId: string;
  sampleId: string;
  fromReport: boolean;
  workflowId: string;
  serviceId: string;
};

export type SelectedEventPath = {
  sampleId: string;
  projectId: string;
  event: Event;
  visibleOnAllPaths?: boolean;
  originPathId?: string;
};

export enum SelectedElementType {
  SERVICE = "service",
  WORKFLOW = "workflow",
  QUESTION = "question",
  ONE_OF_MANY = "oneOfMany",
  EVENT = "event",
  QUESTION_GROUP = "questionGroup",
}

export type SelectedEntity =
  | {
      selectedElementType:
        | SelectedElementType.SERVICE
        | SelectedElementType.WORKFLOW
        | SelectedElementType.QUESTION
        | SelectedElementType.ONE_OF_MANY
        | SelectedElementType.EVENT
        | SelectedElementType.QUESTION_GROUP;
      selectedElementPath: SelectedElementPath;
      previousSelectedEntity?: SelectedEntity;
    }
  | undefined;
