import styled from "styled-components";

interface MainTableContainerProps {
  scrollable: boolean;
  isEditable?: boolean;
}

export const MainTableContainer = styled.div<MainTableContainerProps>`
  overflow-x: ${(props: MainTableContainerProps) =>
    props.scrollable ? "auto" : "inherit"};
`;

export const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Overpass, serif;
  line-height: 1.33;
  letter-spacing: normal;
`;

export const TableHeader = styled.thead``;

export const TableHeaderCell = styled.th`
  font-weight: bold;
  text-align: left;
  padding: 5px 0;
  font-size: 14px;
`;

export const TableHeaderRow = styled.tr`
  border-bottom: 2px ${(props) => props.theme.newColors.grayscale.primaryGray}
    solid;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  &:hover {
    background-color: ${(props) => props.theme.newColors.grayscale.background};
  }
`;

export const TableCell = styled.td<{
  isEditable: boolean | undefined;
}>`
  &:first-child {
    max-width: ${({ isEditable }) => isEditable && "40px"};
  }
  &:nth-child(2) {
    max-width: ${({ isEditable }) => isEditable && "70px"};
  }

  padding: 8px 3px 5px;
  font-size: 12px;
`;

export const TableInLineButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TableInLineButton = styled.span`
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  color: ${(props) => props.theme.newColors.primary.basic};

  &:hover {
    color: ${(props) => props.theme.newColors.primary.hover};
  }
`;

export const RedTableInLineButton = styled(TableInLineButton)`
  color: ${({ theme }) => theme.newColors.extra.errorState};

  &:hover {
    color: ${({ theme }) => theme.newColors.extra.errorHovered};
  }
`;
