import * as React from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import { useUserProjectQuery } from "../generated/graphql";
import { Spinner } from "../components/common/Spinner/Spinner";
import { SingleReport } from "../components/Reports/SingleReport";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-unused-vars
export const SingleReportContainer = (props: RouteComponentProps) => {
  const urlQueryParams = useParams();
  const queryResult = useUserProjectQuery({
    variables: {
      id: urlQueryParams.projectId,
    },
  });

  if (queryResult.error) {
    return <p>Query Error: {queryResult.error}</p>;
  }
  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const project = queryResult.data.Project;

  if (!project.samples.length) {
    return <p>Query Error: no samples were defined in the project</p>;
  }

  const selectedSample = project.samples.find(
    (sample) => sample.id === urlQueryParams.sampleId
  );

  if (!selectedSample) {
    return <div>Sample not found</div>;
  }

  return (
    <SingleReport
      sample={selectedSample}
      samples={project.samples}
      {...props}
    />
  );
};
