import React from "react";
import { CollaboratorOption } from "./types";
import { CollaboratorAvatar } from "./CollaboratorAvatar";

export const getSelectedCollaboratorsAvatars = (
  selectedCollaborators: CollaboratorOption[]
): JSX.Element | null => {
  return selectedCollaborators && selectedCollaborators.length ? (
    <>
      {selectedCollaborators
        .sort((a) => (a.value.type === "group" ? -1 : 1))
        .map((collaboratorOption) => {
          return (
            <CollaboratorAvatar
              key={collaboratorOption.value.id}
              collaboratorOption={collaboratorOption}
            />
          );
        })}
    </>
  ) : (
    <></>
  );
};
