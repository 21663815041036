import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";

export const MenuDotsIcon = (props: BaseIconProps) => {
  const { color } = props;
  // eslint-disable-next-line radix
  const width =
    typeof props.width === "number" ? props.width : parseInt(props.width, 10);
  const height = 5 * width;
  const r = width / 2;

  const circleParams: any[] = [];

  const generateCircleParams = () => {
    let cy = r;
    for (let i = 1; i <= 3; i += 1) {
      circleParams.push(cy);
      cy += width * 2;
    }
  };

  generateCircleParams();

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={color} r={r} cx={r} cy={circleParams[0]} />
      <circle fill={color} r={r} cx={r} cy={circleParams[1]} />
      <circle fill={color} r={r} cx={r} cy={circleParams[2]} />
    </svg>
  );
};

MenuDotsIcon.defaultProps = {
  width: "4",
  height: "0",
  color: "#9D9EA3",
};
