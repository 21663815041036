import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import {
  Auth0User,
  GroupDocument,
  ProjectUsersDocument,
  useEditUserNameMutation,
  useGroupsQuery,
  useListUsersQuery,
  useUserProjectsQuery,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { Table } from "../Table/Table";
import {
  TableInLineButton,
  TableInLineButtons,
} from "../Table/StyledTableComponents";
import { UserAvatar } from "../common/UserAvatar/UserAvatar";
import { PencilIcon } from "../../images/icons/PencilIcon";
import { RoleAssignModal } from "./RoleAssignModal/RoleAssignModal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ManageUsers = (_props: ManageGroupsProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    | {
        id: string;
        name: string;
        roleId: string | undefined;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  const queryResult = useListUsersQuery();
  const [editUserName] = useEditUserNameMutation();

  const { data: groupsQueryResult } = useGroupsQuery();
  const { data: projectstQueryResult } = useUserProjectsQuery();

  if (queryResult.error) {
    return <p>Query Error: {queryResult.error}</p>;
  }

  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }
  const usersData = (queryResult.data && queryResult.data.ListUsers) || [];

  const editUserNameCallback = (auth0UserId: string, name: string): void => {
    const projectWithUser = projectstQueryResult?.Projects.filter((project) =>
      project.users.find((user) => user.id === auth0UserId)
    );
    const groupsWithUser = groupsQueryResult?.Groups?.filter((group) =>
      group.members.find((member) => member === auth0UserId)
    );
    const queriesGroupMembers =
      groupsWithUser && groupsWithUser.length > 0
        ? groupsWithUser.map((group) => ({
            query: GroupDocument,
            variables: { groupId: group.id },
          }))
        : [];

    const queriesProjectUsers =
      projectWithUser && projectWithUser.length > 0
        ? projectWithUser.map((project) => ({
            query: ProjectUsersDocument,
            variables: { projectId: project.id },
          }))
        : [];

    editUserName({
      variables: { auth0UserId, name },
      refetchQueries: [
        "ListUsers",
        ...queriesGroupMembers,
        ...queriesProjectUsers,
      ],
      awaitRefetchQueries: true,
    });
  };

  const getUserRow = (user: Auth0User): (string | JSX.Element)[] => {
    return [
      <UserAvatar user={{ picture: user.avatar }} />,
      <NameDescriptionTextAreaContainer
        contentEditable
        suppressContentEditableWarning
        editMode
        spellCheck={false}
        onBlur={(e): void => editUserNameCallback(user.id, e.target.innerText)}
        onKeyDown={(event): void => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        data-placeholder-name="Enter a username..."
      >
        {user.name}
      </NameDescriptionTextAreaContainer>,
      user.email,
      <RoleContainer
        onClick={(): void => {
          setSelectedUser({
            id: user.id,
            name: user.name,
            roleId: user.role?.id,
          });
          setIsModalOpen(true);
        }}
      >
        {user.role?.name}
        <PencilIcon />
      </RoleContainer>,
      <TableInLineButtons>
        <TableInLineButton
          onClick={(): void => {
            navigate(`/user-tasks/${user.id}`);
          }}
        >
          SHOW ASSIGNED TASKS
        </TableInLineButton>
      </TableInLineButtons>,
    ];
  };

  if (!usersData) {
    return <Spinner />;
  }

  return (
    <>
      <LayoutDefault pageTitle="Manage Users">
        <Container>
          <ContentSection
            title="All Users"
            stepNumber={1}
            wrapCardsCallback={(): void => {}}
            buttons={[]}
            fullWidth
          >
            <StyledHeader>Number of users: {usersData.length}</StyledHeader>
            <Table
              columns={["", "User", "E-mail", "Role", "Actions"]}
              isEditable
            >
              {usersData.map((user: Auth0User) => getUserRow(user))}
            </Table>
          </ContentSection>
        </Container>
      </LayoutDefault>
      {isModalOpen && (
        <RoleAssignModal
          isOpen={isModalOpen}
          onClose={(): void => setIsModalOpen(false)}
          user={selectedUser}
        />
      )}
    </>
  );
};

type ManageGroupsProps = RouteComponentProps;

const StyledHeader = styled.div`
  width: 400px;
  height: 24px;
  flex-grow: 0;
  font-family: Overpass, serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
  text-align: left;
  color: #232f3c;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;

const RoleContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NameDescriptionTextAreaContainer = styled.div<{
  editMode: boolean | undefined;
}>`
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 3px 18px 3px 3px;
  overflow-wrap: break-word;
  text-transform: none;
  white-space: pre-wrap;

  &:empty::before {
    content: attr(data-placeholder-name);
    color: #ccc;
    font-weight: 400;
  }
  &:empty:focus::before {
    content: "";
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.5 8.75V6.5C0.5 6.30109 0.579018 6.11032 0.71967 5.96967L5.96967 0.71967C6.26256 0.426777 6.73744 0.426777 7.03033 0.71967L9.28033 2.96967C9.57322 3.26256 9.57322 3.73744 9.28033 4.03033L4.03033 9.28033C3.88968 9.42098 3.69891 9.5 3.5 9.5H1.25C0.835786 9.5 0.5 9.16421 0.5 8.75ZM6.5 2.3107L2 6.8107V8.00004H3.18934L7.68934 3.50004L6.5 2.3107Z' fill='gray' /%3E%3C/svg%3E");
    margin-left: 8px;
    width: 10px;
    height: 10px;
    position: absolute;
    cursor: text;
    display: inline;
    white-space: nowrap;
  }

  ${({ editMode }) =>
    editMode &&
    css`
      min-width: calc(100% - 25px);
      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px ${(props) => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `}
`;
