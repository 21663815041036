import React from "react";
import { styledTheme } from "../../theme/theme";

interface DragIconProps {
  spaceBetweenCircles: number;
  color: string;
  strokeColor: string;
  strokeWidth: number;
  diameterOfACircle: number;
}

export const DragIcon = (props: DragIconProps) => {
  const {
    spaceBetweenCircles,
    color,
    strokeColor,
    strokeWidth,
    diameterOfACircle,
  } = props;

  const iconWidth = diameterOfACircle * 2 + spaceBetweenCircles;
  const iconHeight = diameterOfACircle * 3 + spaceBetweenCircles * 2;

  const circleRadius = diameterOfACircle / 2 - strokeWidth / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      fill="none"
      viewBox={`0 0 ${iconWidth} ${iconHeight}`}
    >
      <circle
        cx={diameterOfACircle / 2}
        cy={diameterOfACircle / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={iconWidth - diameterOfACircle / 2}
        cy={diameterOfACircle / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={diameterOfACircle / 2}
        cy={iconHeight / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={iconWidth - diameterOfACircle / 2}
        cy={iconHeight / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={diameterOfACircle / 2}
        cy={iconHeight - diameterOfACircle / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      <circle
        cx={iconWidth - diameterOfACircle / 2}
        cy={iconHeight - diameterOfACircle / 2}
        r={circleRadius}
        fill={color}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

DragIcon.defaultProps = {
  spaceBetweenCircles: 2,
  color: styledTheme.newColors.primary.basic,
  strokeColor: "#fff",
  strokeWidth: 0.2,
  diameterOfACircle: 5,
};

export default DragIcon;
