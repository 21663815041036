import React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const SaveIcon = (props: BaseIconProps): JSX.Element => {
  const { width, height, color } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M3.25 1.5A1.75 1.75 0 001.5 3.25v15.5A1.75 1.75 0 003 20.482V13.25A2.25 2.25 0 015.25 11h11.5A2.25 2.25 0 0119 13.25v7.232a1.75 1.75 0 001.5-1.732V5.786c0-.465-.184-.91-.513-1.238l-2.535-2.535a1.749 1.749 0 00-1.238-.513H16v4.25A2.25 2.25 0 0113.75 8h-6.5A2.25 2.25 0 015 5.75V1.5H3.25zm3.25 0v4.25c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75V1.5h-8zm11 19v-7.25a.75.75 0 00-.75-.75H5.25a.75.75 0 00-.75.75v7.25h13zM0 3.25A3.25 3.25 0 013.25 0h12.965a3.25 3.25 0 012.298.952l2.535 2.535c.61.61.952 1.437.952 2.299V18.75A3.25 3.25 0 0118.75 22H3.25A3.25 3.25 0 010 18.75V3.25z"
      />
    </svg>
  );
};

SaveIcon.defaultProps = {
  width: "22",
  height: "22",
  color: styledTheme.newColors.primary.basic,
};
