import React from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { RightBlueExpandIcon } from "../../images/icons/RightBlueExpandIcon";
import { LeftBlueExpandIcon } from "../../images/icons/LeftBlueExpandIcon";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { styledTheme } from "../../theme/theme";
import { InformationsContainer } from "./InformationsContainer/InformationsContainer";
import { ROLES } from "../../constants/roles";

type SideDrawboardProps = {
  className?: string;
  children?: React.ReactNode;
  dropdownData?: any[];
  selectedDropdownDataId?: string;
  onDataClick?: any;
  numberOfExistingWorkflows?: number;
  fixedViewMode?: boolean;
  userRoleInSample?: ROLES;
};

export const SideDrawboard = (props: SideDrawboardProps): JSX.Element => {
  const {
    className,
    children,
    dropdownData,
    selectedDropdownDataId,
    onDataClick,
    fixedViewMode = true,
    userRoleInSample,
  } = props;
  const { sideDrawboardOpen, setSideDrawboardOpen } = useSideBarsContext();

  return (
    <Container
      open={sideDrawboardOpen}
      fixedViewMode={fixedViewMode}
      className={className}
    >
      <InformationsContainer
        dropdownData={dropdownData}
        onDataClick={onDataClick}
        selectedDropdownDataId={selectedDropdownDataId}
        numberOfExistingWorkflows={props.numberOfExistingWorkflows}
        userRoleInSample={userRoleInSample}
      />
      <ContentContainer>
        {sideDrawboardOpen ? children : <></>}
      </ContentContainer>
      <ExpandIconContainer
        onClick={(): void => setSideDrawboardOpen(!sideDrawboardOpen)}
      >
        {sideDrawboardOpen ? <RightBlueExpandIcon /> : <LeftBlueExpandIcon />}
      </ExpandIconContainer>
    </Container>
  );
};

const Container = styled(Flex)<{ open: boolean; fixedViewMode: boolean }>`
  width: ${({ open }) => (open ? "337px" : "72px")};
  background-color: ${(p) => p.theme.newColors.grayscale.white};
  flex-direction: column;
  border-left: 1px solid
    ${(props) => props.theme.newColors.grayscale.bordersInside};
  position: ${(props) => (props.fixedViewMode ? "fixed" : "sticky")};
  top: 0;
  right: 0;
  height: 100vh;
  box-shadow: ${(props) => props.theme.shadows.sideDrawboard.basicLeftOutisde};
`;

const ContentContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
`;

const ExpandIconContainer = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 2rem;
  position: absolute;
  margin-left: -1rem;
  box-shadow: ${styledTheme.shadows.icons.expandIcon};

  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover circle {
    fill: ${styledTheme.newColors.primary.hover};
    transition: fill linear 0.2s;
  }

  & > svg:active circle {
    fill: ${styledTheme.newColors.primary.darkestTint};
    transition: fill linear 0.2s;
  }
`;
