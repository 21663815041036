import * as React from "react";
import { AnswerType } from "../../../generated/graphql";
import { getTextFieldControl } from "./getTextFieldControl";
import { getOneOfManyControl } from "./getOneOfManyControl";
import { getTextAreaControl } from "./getTextAreaControl";
import { FormSchemaField } from "../../types/QuestionMetadata";
import { getFileFieldControl } from "./getFileFieldControl";
import { GetFieldStatusResultT } from "../../../components/common/LabelTag/LabelTag";
import { FormElementProps } from "./FormElementProps";
import { getLinksFieldControl } from "./getLinksFieldControl";

export const getFormElement = (
  elementName: string,
  elementSchema: FormSchemaField,
  fieldStatus: (status: GetFieldStatusResultT) => void = () => {},
  readonly = false
): JSX.Element => {
  const props: FormElementProps = {
    name: elementName,
    label: elementSchema.label,
    options: elementSchema.options,
    description: elementSchema.description,
    placeholder: elementSchema.placeholder,
    required: elementSchema.required,
    visibility: elementSchema.visibility,
    answerQuestionCallback: elementSchema.answerQuestionCallback,
    metadata: elementSchema.metadata,
    fieldStatus,
    disabled: elementSchema.disabled || readonly,
  };

  if (!props.visibility) {
    return <></>;
  }

  switch (elementSchema.type) {
    case AnswerType.TextField:
      return getTextFieldControl({ ...props, type: "text" });

    case AnswerType.TextArea:
      return getTextAreaControl(props);

    case AnswerType.OneOfMany:
      return getOneOfManyControl(props);

    case AnswerType.File:
      return getFileFieldControl(props);

    case AnswerType.Links:
      return getLinksFieldControl(props);

    case AnswerType.Number:
      return getTextFieldControl({ ...props, type: "number" });
    default:
      return <div>There is no renderer for type: {elementSchema.type}</div>;
  }
};
