import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ListViewIcon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.875 13C17.4963 13 18 13.5596 18 14.25C18 14.9403 17.4963 15.5 16.875 15.5H1.125C0.50368 15.5 0 14.9403 0 14.25C0 13.5596 0.50368 13 1.125 13H16.875ZM16.875 6.74999C17.4963 6.74999 18 7.30964 18 7.99999C18 8.69035 17.4963 9.24999 16.875 9.24999H1.125C0.50368 9.24999 0 8.69035 0 7.99999C0 7.30964 0.50368 6.74999 1.125 6.74999H16.875ZM16.875 0.5C17.4963 0.5 18 1.05964 18 1.75C18 2.44036 17.4963 3 16.875 3H1.125C0.50368 3 0 2.44036 0 1.75C0 1.05964 0.50368 0.5 1.125 0.5H16.875Z"
        fill={color}
      />
    </svg>
  );
};
ListViewIcon.defaultProps = {
  width: "18",
  height: "16",
  color: styledTheme.colors.lightText,
};
