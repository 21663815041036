import * as React from "react";
import styled, { css } from "styled-components";
import { FunctionComponent } from "react";
import { Tip } from "../Tip/Tip";

const Root = styled.div<{ checked?: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;

  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid ${(props) =>
      props.theme.newColors.grayscale.tertiaryGray};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
    }
  }

  ${(props) =>
    props.checked &&
    css`
      &::before {
        background: ${({ theme }) => theme.newColors.primary.basic};
        border: none;
      }
    `}

  &:hover {
    &::before {
      background: ${({ theme }) => theme.newColors.grayscale.background};
      border: 1px solid ${({ theme }) =>
        theme.newColors.grayscale.tertiaryGray};
    }
  }
`;

const Fill = styled.div`
  background: ${(props) => props.theme.newColors.grayscale.white};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: 12px;
    position: absolute;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${(props) => props.theme.newColors.grayscale.primaryGray}
    border-radius: 100%;
  }
`;

const Container = styled.div<{ checked?: boolean }>`
  position: relative;
  height: 48px;
  padding: 12px 16px;
  background: ${({ checked, theme }) =>
    checked
      ? theme.newColors.primary.lightestTint
      : theme.newColors.grayscale.white};

  box-shadow: ${({ checked, theme }) =>
    checked
      ? theme.shadows.userInputs.activeState
      : theme.shadows.userInputs.basicInside};
  border-radius: 4px;
  border: 1px solid
    ${({ checked, theme }) =>
      checked
        ? theme.newColors.primary.basic
        : theme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border-color: ${({ checked, theme }) =>
      !checked && theme.newColors.grayscale.placeholder};

    box-shadow: ${({ checked, theme }) =>
      !checked && theme.shadows.userInputs.hoveredState};

    & > ${Root} {
      &::before {
        background: ${({ theme }) => theme.newColors.grayscale.background};
        border: 1px solid
          ${({ theme }) => theme.newColors.grayscale.tertiaryGray};
      }
    }

    ${Fill} {
      background: ${({ theme }) => theme.newColors.grayscale.tertiaryGray};
      width: 8px;
      height: 8px;
      transition: width 0.2s ease-out, height 0.2s ease-out;
      z-index: 0;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: 8px;
      height: 8px;
      transition: width 0.2s ease-out, height 0.2s ease-out;
      z-index: 0;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

const LabelText = styled.label<{ disabled?: boolean }>`
  margin-left: 41px;
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: ${(props) => props.theme.lineHeights[4]};
  white-space: nowrap;
  cursor: pointer;
  color: ${(props) => props.theme.newColors.grayscale.primaryGray};
  user-select: none;

  letter-spacing: -0.01em;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.newColors.grayscale.placeholder};
    `}
`;
const TipContainer = styled.div`
  margin-top: 8px;
`;

const MainContainer = styled.div`
  flex: 1;
`;

export const RadioButton: FunctionComponent<RadioButtonProps> = (props) => {
  const { onChange, value, labelText, checked, name, tip } = props;

  return (
    <MainContainer>
      <Container checked={checked} onClick={onChange}>
        <Root checked={checked}>
          <Input
            id={value}
            type="radio"
            onChange={onChange}
            name={name}
            value={value}
            checked={checked}
            aria-checked={checked}
          />
          <Fill />
        </Root>
        <LabelText htmlFor={value}>{labelText}</LabelText>
      </Container>
      {tip && tip !== "" && (
        <TipContainer>
          <Tip message={tip} color="black" />
        </TipContainer>
      )}
    </MainContainer>
  );
};

export interface RadioButtonProps {
  labelText: string;
  value: string;
  tip?: string;
  name?: string;
  onChange?: any;
  checked?: boolean;
  disabled?: boolean;
}
