// ========
// MINIMAP prep
// minimap inspired by https://observablehq.com/@rabelais/d3-js-zoom-minimap
// ========
import * as d3 from "d3";

export const trans = (x: number, y: number, k: number) => {
  const coord2d = `translate(${x}, ${y})`;
  if (!k) return coord2d;
  return `${coord2d} scale(${k})`;
};

export const ttrans = (x: number, y: number, k: number) => [
  "transform",
  trans(x, y, k),
];

export const minimapScaleX = (zoomScale: number, config: object) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  d3.scaleLinear([0, config._width], [0, config._width * zoomScale]);

export const minimapScaleY = (zoomScale: number, config: object) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  d3.scaleLinear([0, config._height], [0, config._height * zoomScale]);
