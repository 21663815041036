import { EntityStatus } from "../../generated/graphql";

export const checkIfServiceIsInLockedState = (
  status: EntityStatus
): boolean => {
  return (
    status === EntityStatus.Approved ||
    status === EntityStatus.Ongoing ||
    status === EntityStatus.Finished
  );
};
