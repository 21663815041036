import React from "react";
import styled from "styled-components";
import Link from "./Link";

export const ReturnLink = ({ path = "", label = "" }) => (
  <Container>
    <StyledforLink to={path}>{label} &rarr;</StyledforLink>
  </Container>
);

const Container = styled.div`
  width: 100%;
  text-align: right;
`;

const StyledforLink = styled(Link)`
  width: 100%;
  font-size: 14px;
  padding-right: 16px;
  color: ${({ theme }) => theme.newColors.primary.basic};
  transition: 0.1s transform linear;
  &:visited {
    color: ${({ theme }) => theme.newColors.primary.basic};
  }

  &:hover {
    color: ${({ theme }) => theme.newColors.primary.hover};
  }
`;
