import * as React from "react";
import { Field } from "formik";
import { SelectField } from "../../../../components/DynamicForm/Elements/forPrint/SelectField";
import { RadioGroupField } from "../../../../components/DynamicForm/Elements/forPrint/RadioGroupField";
import { Option, QuestionMetadata } from "../../../types/QuestionMetadata";
import { GetFieldStatusResultT } from "../../../../components/common/LabelTag/LabelTag";

export const getOneOfManyControl = (props: {
  name: string;
  label: string;
  options?: Option[];
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
}) => {
  if (!props.options) {
    throw new Error("Corrupted question");
  }
  if (props.options.length > 2) {
    return <Field component={SelectField} {...props} />;
  }
  return <Field component={RadioGroupField} {...props} />;
};
