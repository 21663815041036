import React from "react";
import { BaseSelect, BaseSelectProps } from "../BaseSelect";
import { styledTheme } from "../../../theme/theme";

const getBackgroundColor = (state: any): string => {
  if (state.isFocused && state.isSelected) {
    return styledTheme.newColors.primary.lightestTint;
  }

  if (state.isSelected) {
    return styledTheme.newColors.primary.lightTint;
  }

  if (state.isFocused) {
    return styledTheme.newColors.primary.lightestTint;
  }

  return "white";
};

const getStyles = () => {
  return {
    control: (provided: any) => ({
      ...provided,
      padding: "0 0 0 3px;",
      fontSize: "11px",
      lineHeight: "16px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: "8px 8px 8px 6px",
      fontSize: "11px",
      lineHeight: "16px",
      background: getBackgroundColor(state),
      color: styledTheme.newColors.grayscale.primaryGray,
    }),
    indicator: (provided: any) => ({
      ...provided,
      padding: "2px",
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      marginBottom: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
  };
};

export const Dropdown = (props: DropdownProps): JSX.Element => {
  const { error, isSearchable, menuPortalTarget } = props;

  return (
    <BaseSelect
      menuPortalTarget={menuPortalTarget}
      classNamePrefix="Select"
      menuPlacement="auto"
      error={error}
      isSearchable={isSearchable || false}
      styles={menuPortalTarget ? getStyles() : undefined}
      {...props}
    />
  );
};

export type DropdownProps = BaseSelectProps;
