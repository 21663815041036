import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Box } from "rebass/styled-components";
import { ValueType } from "react-select";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { Label } from "../../common/Label/Label";
import { useSideBarsContext } from "../../../utils/hooks/useSideBarsState";
import { SmallInformationsContainer } from "./SmallInformationsContainer";
import { getSampleName } from "../../../utils/helpers/getSampleName";
import { ROLES } from "../../../constants/roles";

type InformationsContainerProps = {
  dropdownData?: any[];
  selectedDropdownDataId?: string;
  onDataClick?: any;
  numberOfExistingWorkflows?: number;
  userRoleInSample?: ROLES;
};

export const InformationsContainer = (
  props: InformationsContainerProps
): JSX.Element => {
  const {
    dropdownData,
    selectedDropdownDataId,
    onDataClick,
    userRoleInSample,
  } = props;

  const [dropdownValue, setDropdownValue] = useState<{
    label: string;
    value: string;
  }>({ label: "", value: "" });
  const [mainQuestionGroupId, setMainQuestionGroupId] = useState("");

  const { sideDrawboardOpen } = useSideBarsContext();

  useEffect(() => {
    if (!dropdownData || !selectedDropdownDataId || !onDataClick) return;
    const selectedSample = dropdownData.find(
      (data) => data.id === selectedDropdownDataId
    );

    if (selectedSample) {
      setDropdownValue({
        label: getSampleName(selectedSample),
        value: selectedSample.id,
      });
      if (selectedSample.selectedQuestionGroups.length > 0) {
        setMainQuestionGroupId(selectedSample.selectedQuestionGroups[0].id);
      }
    }
  }, [selectedDropdownDataId]);

  const samplesDropdown = dropdownData?.map((dataItem) => ({
    value: dataItem.id,
    label: getSampleName(dataItem),
  }));

  const handleDropdownChange = (
    selectedValue: ValueType<{ label: string; value: string }, boolean>
  ): void => {
    const { value } = selectedValue as { label: string; value: string };
    if (value) {
      onDataClick(value);
    }
  };

  return (
    <Container open={sideDrawboardOpen}>
      {sideDrawboardOpen ? (
        <>
          {dropdownData && selectedDropdownDataId && onDataClick && (
            <Dropdown
              options={samplesDropdown}
              onChange={handleDropdownChange}
              value={dropdownValue}
            />
          )}

          <SmallInformationsContainer
            numberOfExistingWorkflows={props.numberOfExistingWorkflows}
            mainQuestionGroupId={mainQuestionGroupId}
            userRoleInSample={userRoleInSample}
          />
        </>
      ) : (
        <StyledLabel label={dropdownValue!.label} />
      )}
    </Container>
  );
};

const Container = styled(Box)<{ open: boolean }>`
  padding: ${({ open }) => (open ? "1.5rem 2rem 1rem 2rem" : "0")};
  min-height: 181px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  border-bottom: 1px solid
    ${(props) => props.theme.newColors.grayscale.bordersInside};
  position: relative;

  ${({ open }) =>
    !open &&
    css`
      display: flex;
      align-items: center;
      & > div {
        margin-bottom: 0;
      }
    `}
`;

const StyledLabel = styled(Label)`
  writing-mode: vertical-rl;
  transform: scale(-1);
  width: 18px;

  & label {
    height: 149px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
`;
