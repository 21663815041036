import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ProjectsIcon = (props: ProjectsIconProps): JSX.Element => {
  const { width, height, isActive } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 18V18C3.34315 18 2 16.6569 2 15V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V5"
        stroke={isActive ? "white" : "#6E7D92"}
        strokeWidth="2"
      />
      <path
        d="M7 10C7 8.34315 8.34315 7 10 7H20C21.6569 7 23 8.34315 23 10V20C23 21.6569 21.6569 23 20 23H10C8.34315 23 7 21.6569 7 20V10Z"
        fill={isActive ? "white" : "#6E7D92"}
      />
    </svg>
  );
};

type ProjectsIconProps = BaseIconProps & {
  isActive?: boolean;
};

ProjectsIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};
