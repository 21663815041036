/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import { BaseIconProps } from "./BaseIconProps";

interface Props extends BaseIconProps {
  ownScale?: number;
}

export const MenuDotsHorizontallyIcons = (props: Props): JSX.Element => {
  const {
    color,
    width,
    height,
    id,
    x,
    y,
    style,
    onClick,
    ownScale = 1,
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={+width * ownScale}
      height={+height * ownScale}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
      id={id}
      x={x}
      y={y}
      style={style}
      onClick={onClick}
    >
      <circle cx="2" cy="2" r={2} fill={color} transform="rotate(90 2 2)" />
      <circle cx="10" cy="2" r={2} fill={color} transform="rotate(90 10 2)" />
      <circle cx="18" cy="2" r={2} fill={color} transform="rotate(90 18 2)" />
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    </svg>
  );
};

MenuDotsHorizontallyIcons.defaultProps = {
  onwScale: 1,
  width: "20",
  height: "4",
  color: "#6E7D92",
};
