import * as React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { InMemoryCache } from "apollo-cache-inmemory";
import { useState } from "react";
import { StatusType } from "../../Tabs/getStatusIcon";
import { LayoutWithSideDrawboard } from "../../Layouts/LayoutWithSideDrawboard";
import { Tabs } from "../../Tabs/Tabs";
import {
  Project,
  ProjectWithAllSamplesDocument,
  Sample,
  useAddSampleToProjectMutation,
  useRemoveProjectMutation,
  UserPlainProjectsWithSamplesDocument,
  UserProjectDocument,
} from "../../../generated/graphql";
import { DynamicFormWrapper } from "../../DynamicForm/DynamicFormWrapper";
import {
  QuestionLevelInput,
  QuestionMetadata,
} from "../../../utils/types/QuestionMetadata";
import { getSampleName } from "../../../utils/helpers/getSampleName";
import { getProjectName } from "../../../utils/helpers/getProjectName";
import { shortenedSampleName } from "../../../utils/helpers/shortenedSampleName";
import { updateForMutationsWithOnlyServerSideLogic } from "../../../utils/helpers/updateForMutationsWithOnlyServerSideLogic";
import { ReturnLink } from "../../common/ReturnLink";
import { Spinner } from "../../common/Spinner/Spinner";

const projectDataTab = {
  id: "1",
  label: "Project Data",
  status: StatusType.Editing,
};

const parseProjectSamples = (
  samples: Sample[]
): {
  id: string;
  label: string;
  status: StatusType;
}[] =>
  samples.map((sample: Sample) => ({
    id: sample.id,
    label: shortenedSampleName(getSampleName(sample)),
    status: StatusType.Editing,
  }));

export const ProjectConfigurator = (
  props: ProjectConfiguratorProps
): JSX.Element => {
  const { project, projects, answerProjectQuestion } = props;
  const [selectedProjectId, setSelectedProjectId] = useState(project.id);
  const [showSpinner, setShowSpinner] = useState(false);
  const [removeProject] = useRemoveProjectMutation();

  const removeProjectAction = async (): Promise<void> => {
    const variables = { projectId: selectedProjectId };
    const options = {
      variables,
      refetchQueries: [
        {
          query: UserPlainProjectsWithSamplesDocument,
          variables: {},
        },
      ],
      awaitRefetchQueries: true,
    };

    await removeProject(options);
  };

  const getTabs = (projectId: string, samples: Sample[] = []): JSX.Element => {
    const parsedProjectSamples = parseProjectSamples(samples);
    const tabs = [projectDataTab, ...parsedProjectSamples];
    const [addSampleToProjectMutation] = useAddSampleToProjectMutation();

    const newTabClick = async (): Promise<void> => {
      setShowSpinner(true);
      const variables = { projectSampleInput: { projectId } };

      const options = {
        variables,
        refetchQueries: [
          {
            query: UserProjectDocument,
            variables: { id: projectId },
          },
          {
            query: ProjectWithAllSamplesDocument,
            variables: { id: projectId },
          },
        ],
        awaitRefetchQueries: true,
        update(cache: InMemoryCache, { data }: any): void {
          updateForMutationsWithOnlyServerSideLogic(
            { id: projectId },
            UserProjectDocument,
            "Project",
            "AddSampleToProject",
            cache,
            data,
            "samples"
          );
        },
      };

      await addSampleToProjectMutation(options);
      setShowSpinner(false);
    };

    const selectTab = (sampleId: string): void => {
      if (sampleId === "1") {
        // go to Project data tab
        navigate(`/projects/${projectId}/configurator`);
      } else {
        navigate(
          `/projects/${projectId}/configurator/samples/${sampleId}/configurator`
        );
      }
    };

    return (
      <Tabs
        tabs={tabs}
        onTabClick={selectTab}
        activeTab="1"
        onNewTabClick={newTabClick}
      />
    );
  };

  const questionLevelInput: QuestionLevelInput = {
    projectId: project.id,
  };

  const projectName: string = getProjectName(project);

  const handleProjectClick = (projectId: string): void => {
    setSelectedProjectId(projectId);
    navigate(`/projects/${projectId}/configurator/`);
  };

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <LayoutWithSideDrawboard
        pageTitle={projectName}
        tabs={getTabs(project.id, project.samples)}
        dropdownData={projects}
        selectedDropdownDataId={selectedProjectId}
        onDataClick={(projectId: string): void => handleProjectClick(projectId)}
      >
        <ReturnLink
          path={`/projects/${project.id}/timeline/samples/${project.samples[0].id}`}
          label="Go to the timeline"
        />

        {project.selectedQuestionGroups.map((questionGroup, index) => (
          <DynamicFormWrapper
            title={questionGroup.name}
            key={questionGroup.id}
            stepNumber={index + 1}
            questionLevelInput={questionLevelInput}
            questionGroup={questionGroup}
            wrapped={!!index}
            answerQuestionCallback={answerProjectQuestion}
            wrapCardsCallback={(): void => {}}
            goTo1stSampleCallback={(): void => {
              if (project.samples.length > 0)
                navigate(
                  `/projects/${project.id}/configurator/samples/${project.samples[0].id}`
                );
            }}
            deleteProjectCallback={async (): Promise<void> => {
              await removeProjectAction();
              await navigate("/projects");
            }}
            projectId={project.id}
            sampleId={project.samples[0].id}
          />
        ))}
      </LayoutWithSideDrawboard>
    </>
  );
};

interface ProjectConfiguratorProps extends RouteComponentProps {
  project: Project;
  answerProjectQuestion: (metadata: QuestionMetadata, answer: any) => void;
  projects: any[];
}
