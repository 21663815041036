import * as React from "react";
import styled from "styled-components";
import { Label } from "../../../../common/Label/Label";

export const ServicePageTitle = () => {
  return (
    <Header>
      <Label label="Design a research workflow for a sample by selecting individual services and choose the optimal Leaderna's package">
        <Description>
          If you need any additional help, you can reach out to our team. We
          will be more than happy to suggest our service packages to match the
          exact needs for your research. To ease up selection process, platform
          automatically recommends service packages that suits project & sample
          specification based on your preferences.
        </Description>
      </Label>
    </Header>
  );
};

const Header = styled.div`
  &:after {
    content: " ";
    width: 67%;
    border-top: 1px solid #dedede;
    margin-top: 32px;
    margin-bottom: 32px;
    display: block;
  }
`;

const Description = styled.div`
  width: 60%;
`;
