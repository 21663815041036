import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { SelectedEntity } from "../utils/types/ActionContextTypes";

type DroppedElementCoordinates = {
  date: Date;
  baseline: number;
};

type ElementOnDragAction = {
  type: string;
};

export type ActionContextType = {
  selectedEntity: SelectedEntity;
  setSelectedEntity: (selectedEntity: SelectedEntity) => void;
  saveCurrentSelectedEntityAndSet: (newEntity: SelectedEntity) => void;
  returnToPreviousSelectedEntity: (
    alternativeEntity: SelectedEntity
  ) => SelectedEntity;
  selectedWorkflowIndex: number;
  setSelectedWorkflowIndex: (selectedWorkflowIndex: number) => void;
  openedSampleGroup: number;
  setOpenedSampleGroup: (wrappedGroups: number) => void;
  elementOnDragAction: ElementOnDragAction | null;
  setElementOnDragAction: (
    elementOnDragAction: ElementOnDragAction | null
  ) => void;
  droppedElementCoordinates: DroppedElementCoordinates;
  setDroppedElementCoordinates: (
    droppedElementCoordinates: DroppedElementCoordinates
  ) => void;
};

type ActionContextProviderPropsT = RouteComponentProps & {
  defaultSelectedEntity?: SelectedEntity;
};

export const ActionContext = React.createContext<ActionContextType>({
  get selectedEntity(): SelectedEntity {
    throw new Error("ActionContext not defined");
  },
  setSelectedEntity() {
    throw new Error("ActionContext not defined");
  },
  saveCurrentSelectedEntityAndSet() {
    throw new Error("ActionContext not defined");
  },
  returnToPreviousSelectedEntity() {
    throw new Error("ActionContext not defined");
  },
  get selectedWorkflowIndex(): number {
    throw new Error("ActionContext not defined");
  },
  setSelectedWorkflowIndex() {
    throw new Error("ActionContext not defined");
  },
  get openedSampleGroup(): number {
    throw new Error("ActionContext not defined");
  },
  setOpenedSampleGroup() {
    throw new Error("ActionContext not defined");
  },
  get elementOnDragAction(): ElementOnDragAction {
    throw new Error("ActionContext not defined");
  },
  setElementOnDragAction() {
    throw new Error("ActionContext not defined");
  },
  get droppedElementCoordinates(): DroppedElementCoordinates {
    throw new Error("ActionContext not defined");
  },
  setDroppedElementCoordinates() {
    throw new Error("ActionContext not defined");
  },
});

export const ActionContextProvider: React.FC<ActionContextProviderPropsT> = ({
  children,
  defaultSelectedEntity,
}) => {
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>(
    defaultSelectedEntity
  );
  const [selectedWorkflowIndex, setSelectedWorkflowIndex] = useState<number>(0);
  const [openedSampleGroup, setOpenedSampleGroup] = useState<number>(0);
  const [elementOnDragAction, setElementOnDragAction] =
    useState<ElementOnDragAction | null>(null);
  const [droppedElementCoordinates, setDroppedElementCoordinates] =
    useState<DroppedElementCoordinates>({ date: new Date(), baseline: 0 });

  const saveCurrentSelectedEntityAndSet = (newEntity: SelectedEntity): void => {
    if (newEntity) {
      setSelectedEntity((currentEntity) => ({
        selectedElementType: newEntity.selectedElementType,
        selectedElementPath: newEntity.selectedElementPath,
        previousSelectedEntity: currentEntity,
      }));
    } else {
      setSelectedEntity(undefined);
    }
  };

  const returnToPreviousSelectedEntity = (
    alternativeEntity: SelectedEntity
  ): SelectedEntity => {
    const entity =
      selectedEntity && selectedEntity.previousSelectedEntity
        ? selectedEntity.previousSelectedEntity
        : alternativeEntity;
    if (entity) {
      const e = {
        selectedElementType: entity.selectedElementType,
        selectedElementPath: entity.selectedElementPath,
      };
      setSelectedEntity(e);
      return e;
    }
    setSelectedEntity(undefined);
    return undefined;
  };

  return (
    <ActionContext.Provider
      value={{
        selectedEntity,
        setSelectedEntity,
        saveCurrentSelectedEntityAndSet,
        returnToPreviousSelectedEntity,
        selectedWorkflowIndex,
        setSelectedWorkflowIndex,
        openedSampleGroup,
        setOpenedSampleGroup,
        elementOnDragAction,
        setElementOnDragAction,
        droppedElementCoordinates,
        setDroppedElementCoordinates,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};
