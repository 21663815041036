import * as React from "react";
import styled from "styled-components";
import { bytesToSize } from "../../../utils/helpers/bytesToSize";
import { CloseFileLabelIcon } from "../../../images/icons/CloseFileLabelIcon";
import { styledTheme } from "../../../theme/theme";
import { DeleteIcon } from "../../../images/icons/DeleteIcon";
import { ConfirmationModal } from "../Modal/ConfirmationModal";

const FileContainer = styled.div<{ isLoading: boolean }>`
  width: 312px;
  height: 32px;
  border-radius: 8px 8px 8px 0px;

  background: ${(props) =>
    props.isLoading
      ? props.theme.newColors.primary.lightestTint
      : props.theme.newColors.grayscale.primaryGray};

  .progressBar {
    display: ${(props) => (props.isLoading ? "block;" : "none;")};
  }
`;

const ProgressBar = styled.div<{ percent: number }>`
  height: 2px;
  width: ${(props) => props.percent}%;
  border-radius: 4px;
  background: ${(props) => props.theme.newColors.primary.basic};
`;

const Label = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
`;

const LabelContainer = styled.div`
  display: flex;
  padding: 8px 12px;
`;

const FileName = styled.div<{ isLoading: boolean }>`
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: ${(props) => props.theme.lineHeights[2]};

  letter-spacing: -0.01em;

  color: ${(props) =>
    props.isLoading
      ? props.theme.newColors.grayscale.primaryGray
      : props.theme.newColors.grayscale.white};

  order: 0;
  align-self: center;
  margin-right: 5px;

  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.div<{ isLoading: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  color: ${(props) =>
    props.isLoading
      ? props.theme.newColors.grayscale.secondaryGray
      : props.theme.newColors.grayscale.placeholder};

  order: 1;
  align-self: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  width: 16px;
  height: 16px;
  padding: 0;
  cursor: pointer;
`;

export const FileLabel = (props: FileElementProps) => {
  const {
    name,
    size,
    percent = 100,
    onGetFileClick,
    onDeleteFileClick,
  } = props;
  const isLoading = percent !== 100;
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState<boolean>(false);

  return (
    <>
      <FileContainer isLoading={isLoading}>
        <LabelContainer>
          <Label
            onClick={!isLoading ? onGetFileClick : (): void => {}}
            isLoading={isLoading}
          >
            <FileName isLoading={isLoading}>{name}</FileName>
            <FileSize isLoading={isLoading}>({bytesToSize(size)})</FileSize>
          </Label>
          <CloseButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!isLoading) {
                setOpenConfirmationModal(true);
              }
            }}
          >
            {isLoading ? (
              <CloseFileLabelIcon
                width="10"
                height="16"
                color={styledTheme.newColors.grayscale.tertiaryGray}
              />
            ) : (
              <DeleteIcon
                width="16"
                height="16"
                color={styledTheme.newColors.grayscale.white}
              />
            )}
          </CloseButton>
        </LabelContainer>
        <ProgressBar className="progressBar" percent={percent} />
      </FileContainer>
      <ConfirmationModal
        title="Warning"
        message="Do you want to delete this file?"
        isOpen={openConfirmationModal}
        closeModal={() => setOpenConfirmationModal(false)}
        acceptModal={() => onDeleteFileClick()}
      />
    </>
  );
};

FileLabel.defaultProps = {
  onGetFileClick: () => {},
  onDeleteFileClick: () => {},
};

interface FileElementProps {
  name: string;
  size: number;
  percent: number;
  onGetFileClick: () => void;
  onDeleteFileClick: () => void;
}
