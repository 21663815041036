import * as React from "react";
import styled, { css } from "styled-components";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { UploadFileIcon } from "../../../images/icons/UploadFileIcon";
import { Button } from "../Button/Button";
import { ButtonType } from "../Button/ButtonType";

const DivWithDropZone = styled.div<{
  dragActive: boolean;
  horizontally?: boolean;
}>`
  border: 1px dashed
    ${(props) =>
      props.dragActive
        ? props.theme.newColors.secondary.darkTint
        : props.theme.newColors.secondary.basic};
  box-sizing: border-box;
  background: ${(props) =>
    props.dragActive
      ? props.theme.newColors.secondary.lightTint
      : props.theme.newColors.secondary.lightestTint};
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: ${(props) => props.theme.fontSizes[2]};
  text-align: center;
  padding: 16px;
  position: relative;

  ${({ horizontally }) =>
    horizontally &&
    css`
      display: flex;
      padding-left: 0.5rem;
      width: 100%;
      position: relative;
    `}
`;

const SelectFileButton = styled(Button)`
  margin: auto;
`;

const DivForText = styled.div<{ horizontally?: boolean }>`
  margin-top: 8px;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.newColors.grayscale.primaryGray};

  ${({ horizontally }) =>
    horizontally &&
    css`
      padding-left: 8px;
    `}
`;

const StaticInput = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: default;
  top: 0;
  left: 0;
`;

export const FileDropZone = (props: DropZoneProps): JSX.Element => {
  const { rootProps, inputProps, open, horizontally, staticForm, disabled } =
    props;
  const { isDragAccept } = rootProps;

  return (
    <DivWithDropZone
      dragActive={isDragAccept}
      horizontally={horizontally}
      {...rootProps}
    >
      {(staticForm || disabled) && <StaticInput />}
      <SelectFileButton
        type={ButtonType.Secondary}
        label="Select files"
        icon={<UploadFileIcon />}
        onClick={open}
      />
      <input {...inputProps} />
      <DivForText horizontally={horizontally}>or drop files here</DivForText>
    </DivWithDropZone>
  );
};

FileDropZone.defaultProps = {
  horizontally: false,
};

interface DropZoneProps {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  open: any;
  horizontally?: boolean;
  staticForm?: boolean;
  disabled?: boolean;
}
