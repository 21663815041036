import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate, useMatch } from "@reach/router";
import { Event } from "../../generated/graphql";
import Block, { BlockType } from "./Block";
import { styledTheme } from "../../theme/theme";
import { EventIcon } from "./icons/EventIcon";
import SelectedEventIcon from "./icons/SelectedEventIcon";
import { TextLines } from "./TextLines";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { useActionContext } from "../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedEventPath,
} from "../../utils/types/ActionContextTypes";
import { ROUTES } from "../../constants/routeConstants";
import { getOwnScale } from "./axisHelpers";

type EventBlockType = {
  projectId: string;
  sampleId: string;
  workflowName: string;
  color?: string;
  visibleOnAllPaths?: boolean;
  originPathId?: string;
};

export const EventBlock = ({
  x,
  y,
  currentTransform,
  projectId,
  sampleId,
  disableDnD,
  visibleOnAllPaths,
  originPathId,
  color = styledTheme.newColors.grayscale.primaryGray,
  ...event
}: EventBlockType &
  BlockType &
  Event & { currentTransform: { k: number } }): JSX.Element => {
  const ownScale = getOwnScale(currentTransform.k);
  const matchProjectPath = useMatch(ROUTES.PROJECT_TIMELINE_WILDCARD);

  const selectedElementPath: SelectedEventPath = {
    projectId,
    sampleId,
    event,
    originPathId,
    visibleOnAllPaths,
  };

  const { setSideDrawboardOpen } = useSideBarsContext();
  const { setSelectedEntity, selectedEntity } = useActionContext();
  const [eventIsSelected, setEventIsSelected] = React.useState(false);

  const handleOnEventClick = (): void => {
    setSideDrawboardOpen(true);
    setSelectedEntity({
      selectedElementType: SelectedElementType.EVENT,
      selectedElementPath,
    });

    if (matchProjectPath)
      navigate(
        `/projects/${projectId}/timeline/samples/${sampleId}/events/${event.id}`
      );
  };

  useEffect(() => {
    const isSelected = selectedEntity
      ? (selectedEntity?.selectedElementPath as SelectedEventPath).event?.id ===
        selectedElementPath.event?.id
      : false;

    setEventIsSelected(isSelected);
  }, [selectedEntity]);

  const eventName =
    event.name && event.name.length > 15
      ? `${event.name.slice(0, 15).trim()}…`
      : event.name;

  const width = 20;

  return (
    <EventWrapper>
      <TooltipAngled
        x={x + 15 * ownScale}
        y={y}
        ownScale={ownScale}
        transform={`rotate(-40 ${x + 10 * ownScale} ${y - 5 * ownScale})`}
      >
        {eventName}
      </TooltipAngled>
      {eventIsSelected && !disableDnD ? (
        <SelectedEventIcon
          color={color}
          secondColor={styledTheme.newColors.extra.selectedBlock}
          x={x}
          y={y}
          width={width}
          height={20}
          onClick={handleOnEventClick}
          className="EventDragElement"
          id={event.id}
          zoom={currentTransform.k}
        />
      ) : (
        <EventIcon
          color={color}
          x={x}
          y={y}
          width={width}
          height={20}
          onClick={handleOnEventClick}
          className="EventDragElement"
          id={event.id}
          zoom={currentTransform.k}
        />
      )}
      <g className="tooltip">
        <Block
          name={event.name}
          x={x + 15 * ownScale}
          y={y - 120 * ownScale}
          ownScale={ownScale}
          width={220 * ownScale}
          disableDnD={disableDnD}
          hideTooltip
        >
          <TextLines
            text={event.description}
            maxChars={29}
            scale={ownScale}
            maxLines={3}
          />
        </Block>
      </g>
    </EventWrapper>
  );
};

const EventWrapper = styled.g`
  .tooltip {
    visibility: hidden;
  }

  :hover {
    .tooltip {
      visibility: visible;
    }
  }
`;

EventBlock.defaultProps = {
  ownScale: 1,
  width: 220,
  height: 100,
};

const TooltipAngled = styled.text<{
  ownScale?: number;
  x?: number;
  y?: number;
}>`
  font-size: ${({ ownScale = 1 }): number => ownScale * 20}px;
`;
