export const ROUTES = {
  WELCOME: "/",
  HEALTHCHECK: "/healthcheck",
  LOGIN: "/login",
  AUTH0_CALLBACK: "/auth0_callback",
  AFTER_LOGIN: "/afterlogin",
  WHAT_IS_LP: "/whatislp",
  INTRODUCTION: "/introduction",
  GRID_OF_PROJECTS: "/projects",
  LIST_OF_PROJECTS: "/projects/details",
  PROJECT: "/projects/:projectId",
  PROJECT_CONFIGURATOR: "/projects/:projectId/configurator",
  PROJECT_TIMELINE: "/projects/:projectId/timeline",
  PROJECT_TIMELINE_WILDCARD: "/projects/:projectId/timeline/*",
  PROJECT_TIMELINE_SAMPLE: "/projects/:projectId/timeline/samples/:sampleId",
  PROJECT_TIMELINE_SAMPLE_WORKFLOW:
    "/projects/:projectId/timeline/samples/:sampleId/workflows/:workflowId",
  PROJECT_TIMELINE_SAMPLE_SERVICE:
    "/projects/:projectId/timeline/samples/:sampleId/workflows/:workflowId/services/:serviceId",
  PROJECT_TIMELINE_SAMPLE_EVENT:
    "/projects/:projectId/timeline/samples/:sampleId/events/:eventId",
  PROJECT_LIST_OF_RESULTS: "/projects/:projectId/results",
  PROJECT_RESULT: "/projects/:projectId/results/:resultId",
  LIST_OF_SAMPLES: "/projects/:projectId/configurator/samples",
  SAMPLE: "/projects/:projectId/configurator/samples/:sampleId",
  SAMPLE_CONFIGURATOR:
    "/projects/:projectId/configurator/samples/:sampleId/configurator",
  SAMPLE_TIMELINE:
    "/projects/:projectId/configurator/samples/:sampleId/timeline",
  REPORTS: "/reports",
  REPORT: "/reports/:projectId/:sampleId",
  MANAGE_TEAMS: "/manage-team",
  MANAGE_TEAM: "/manage-team/:projectId",
  USER_TASKS: "/user-tasks/:userId",
  GROUP_TASKS: "/group-tasks/:groupId",
  MANAGE_GROUPS: "/manage-groups",
  MANAGE_GROUP_MEMBERS: "/manage-groups/:groupId",
  MANAGE_USERS: "/manage-users",
  DASHBOARD: "/dashboard",
  TASKS: "/tasks",
  TASKS_TABLE: "/tasks/:projectId",
};
