import * as React from "react";
import styled from "styled-components";
import { getFormElement } from "../../utils/helpers/dynamicForms/getFormElement";
import { Form } from "./Elements/Form";
import {
  FomSchemaRendererField,
  FormSchema,
  FormSchemaField,
  QuestionFormData,
} from "../../utils/types/QuestionMetadata";
import { getFormElementRenderer } from "../../utils/helpers/dynamicForms/getFormElementRenderer";
import { ProgressWidget } from "../common/ProgressWidget/ProgressWidget";
import {
  getRequiredItems,
  getFilledRequiredItemsIds,
} from "./helpers/requiredItemsHelper";

export const DynamicForm = (props: DynamicFormProps) => {
  const { formData, formSchema, validationSchema, onSubmit, readonly } = props;

  const requiredItems = getRequiredItems(formSchema);
  const filledRequiredItems = getFilledRequiredItemsIds(
    requiredItems,
    formData,
    formSchema
  );

  return (
    <Form
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Container>
        <FormContainer>
          {Object.keys(formSchema).map((key) => {
            const formSchemaElement = formSchema[key] as FomSchemaRendererField;
            const isRequired = requiredItems.some(
              (requiredItem) => requiredItem.id === key
            );
            return (
              <React.Fragment key={key}>
                {formSchemaElement.renderer
                  ? getFormElementRenderer(
                      key,
                      formSchema[key] as FomSchemaRendererField,
                      isRequired,
                      filledRequiredItems,
                      readonly
                    )
                  : getFormElement(
                      key,
                      formSchema[key] as FormSchemaField,
                      () => {},
                      readonly
                    )}
              </React.Fragment>
            );
          })}
        </FormContainer>
        {getRequiredItems.length && !readonly ? (
          <WidgetContainer>
            <ProgressWidget
              requiredItems={requiredItems.length}
              filledItems={filledRequiredItems.length}
            />
          </WidgetContainer>
        ) : null}
      </Container>
    </Form>
  );
};

type DynamicFormProps = {
  formData: QuestionFormData;
  validationSchema: any;
  formSchema: FormSchema;
  onSubmit: any;
  readonly?: boolean;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WidgetContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 200px;
`;

const FormContainer = styled.div`
  flex-grow: 1;
  min-width: 500px;
`;
