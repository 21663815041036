import * as React from "react";
import { FunctionComponent, useState, useEffect } from "react";
import { FieldProps } from "formik";
import { FormGroup } from "./FormGroup";
import { TextArea } from "../../common/TextArea/TextArea";
import { QuestionMetadata } from "../../../utils/types/QuestionMetadata";
import {
  getFieldStatus,
  GetFieldStatusResultT,
} from "../../common/LabelTag/LabelTag";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled: boolean;
}

type OwnProps = FieldProps & Props;

export const TextAreaField: FunctionComponent<OwnProps> = (props) => {
  const {
    placeholder,
    field,
    label,
    description,
    answerQuestionCallback,
    metadata,
    required,
    fieldStatus: fieldStatusCallback,
    disabled,
  } = props;

  const [fieldValue, setFieldValue] = useState(field.value);
  const [fieldStatus, setFieldStatus] = useState(
    getFieldStatus(required, field.value, fieldValue)
  );
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  // useEffect(() => {
  //   setFieldValue(field.value);
  // }, [field.value]);
  useEffect(() => {
    const currentStatus = getFieldStatus(required, field.value, fieldValue);
    setFieldStatus(currentStatus);
    fieldStatusCallback(currentStatus);
  }, [fieldValue, field.value]);

  // console.log("PINGWING: 33 valueSaved", field.value === fieldValue); // TODO: signal this state, SAVING for false, SAVED for true
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(event.target.value);
    answerQuestionCallback(metadata, event.target.value);
  };

  return (
    <FormGroup label={label} description={description} status={fieldStatus}>
      <TextArea
        placeholder={placeholder}
        {...field}
        value={fieldValue}
        onChange={onChange}
        isDisabled={disabled}
      />
      {/* {form.touched[field.name] && form.errors[field.name] ? ( */}
      {/*  <div>{form.errors[field.name]}</div> */}
      {/* ) : null} */}
    </FormGroup>
  );
};
