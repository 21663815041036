import React from "react";
import styled from "styled-components";
import { TextBox } from "../../../../common/TextBox/TextBox";
import { styledTheme } from "../../../../../theme/theme";
import { DeleteIcon } from "../../../../../images/icons/DeleteIcon";

export const AnswerElementList = (props: AnswerListElementProps) => {
  const { id, value, onChange, onBlur, onDelete, testId } = props;

  const onBlurEvent = (input: any) => {
    if (input.target.value.length > 0) {
      onBlur(input.target.value);
    } else {
      onDelete(id);
    }
  };

  return (
    <Container data-testid={testId}>
      <TextBox
        placeholder={`Answer number ${id + 1}`}
        value={value}
        onChange={(input: any) => onChange(input.target.value)}
        onBlur={onBlurEvent}
        testId={`${testId}_input`}
      />
      {value.length ? (
        <Button
          onClick={() => onDelete(id)}
          style={{ cursor: "pointer" }}
          data-testid={`${testId}_deleteButton`}
        >
          <DeleteIcon />
        </Button>
      ) : null}
    </Container>
  );
};

type AnswerListElementProps = {
  id: number;
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  onDelete: (id: number) => void;
  testId?: string;
};

const Container = styled.div`
  display: flex;
`;

const Button = styled.div`
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.userInputs.basicInside};
  border-radius: 4px;
  margin-left: 16px;
`;
