import * as React from "react";
import { HelpIcon } from "../../../images/icons/HelpIcon";
import { Tooltip, TooltipProps } from "./Tooltip";

export const Help = (props: TooltipProps) => {
  const { text, place } = props;
  return (
    <Tooltip text={text} place={place}>
      <HelpIcon />
    </Tooltip>
  );
};
