import * as React from "react";
import { navigate, RouteComponentProps, useParams } from "@reach/router";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { useAuth } from "react-use-auth";
import { StatusType } from "../Tabs/getStatusIcon";
import {
  AnswerType,
  EntityStatus,
  QuestionGroup,
  Sample,
  useProjectSampleWithMultiAnswerMutation,
  useProjectSampleWithNumericAnswerMutation,
  useProjectSampleWithTextAnswerMutation,
  UserProjectDocument,
} from "../../generated/graphql";
import { shortenedSampleName } from "../../utils/helpers/shortenedSampleName";
import { getSampleName } from "../../utils/helpers/getSampleName";
import { Tabs } from "../Tabs/Tabs";
import { NoQuestionGroups } from "./NoQuestionGroups";
import { LayoutWithDroppableContext } from "../Layouts/LayoutWithDroppableContext";
import { DynamicFormWrapper } from "../DynamicForm/DynamicFormWrapper";
import { DynamicEditableFormWrapper } from "../DynamicForm/DynamicEditableFormWrapper";
import {
  SelectedElementType,
  SelectedQuestionGroupPath,
} from "../../utils/types/ActionContextTypes";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { QuestionMetadata } from "../../utils/types/QuestionMetadata";
import { ROLES } from "../../constants/roles";

const runQuestionAnswerMutationAfterMs = 1000;
let lastMetadata: null | string = null;

const parseProjectSamples = (
  samples: Sample[]
): {
  id: string;
  label: string;
  status: StatusType;
}[] =>
  samples
    .filter(
      (sample) => sample.enabledWorkflows && sample.enabledWorkflows.length > 0
    )
    .map((sample: Sample) => ({
      id: sample.id,
      label: shortenedSampleName(getSampleName(sample)),
      status: StatusType.Done,
    }));

const getTabs = (
  projectId: string,
  samples: Sample[] = [],
  selectedSampleId = "1"
): JSX.Element => {
  const parsedProjectSamples = parseProjectSamples(samples);
  const tabs = [...parsedProjectSamples];

  const selectTab = (sampleId: string): void => {
    navigate(`/reports/${projectId}/${sampleId}`);
  };

  return (
    <Tabs
      tabs={tabs}
      onTabClick={selectTab}
      activeTab={selectedSampleId}
      onNewTabClick={() => {}}
      hideNewButton
    />
  );
};

type ReportSelectedQuestionGroups = {
  serviceName: string;
  serviceId: string;
  workflowId: string;
  questionGroup: QuestionGroup;
};

const getReportSelectedQuestionGroups = (
  sample: Sample
): ReportSelectedQuestionGroups[] => {
  const questionGroups: ReportSelectedQuestionGroups[] = [];

  for (let i = 0; i < sample.enabledWorkflows.length; i += 1) {
    const workflow = sample.enabledWorkflows[i];
    const services = workflow.services.filter(
      (service) =>
        service && service.report && service.status === EntityStatus.Finished
    );

    if (services.length > 0) {
      services.forEach((service) => {
        const { report } = service;
        if (
          report &&
          report.selectedQuestionGroups &&
          report.selectedQuestionGroups.length > 0
        ) {
          report.selectedQuestionGroups.forEach((questionGroup) =>
            questionGroups.push({
              serviceName: service.name,
              serviceId: service.id,
              workflowId: workflow.id,
              questionGroup: { ...questionGroup },
            })
          );
        }
      });
    }
  }

  return questionGroups;
};

export const SingleReport = (props: SingleReportProps): JSX.Element => {
  const { sample, samples } = props;

  const { projectId, sampleId } = useParams();
  const { selectedEntity } = useActionContext();
  const { user } = useAuth();

  const userRoleInSample = sample?.assignedUsers?.find(
    (assignedUser) => assignedUser?.id === user.sub
  )?.role as ROLES | undefined;

  const projectSampleWithMultiAnswer =
    useProjectSampleWithMultiAnswerMutation()[0];
  const projectSampleWithNumericAnswer =
    useProjectSampleWithNumericAnswerMutation()[0];
  const projectSampleWithTextAnswer =
    useProjectSampleWithTextAnswerMutation()[0];

  const questionGroupId =
    selectedEntity &&
    (selectedEntity.selectedElementPath as SelectedQuestionGroupPath)
      .questionGroupId;

  const sampleName: string = getSampleName(sample);

  const questionGroups = getReportSelectedQuestionGroups(sample);

  const getSelectedQuestionGroupElement = (
    questionGroup: ReportSelectedQuestionGroups,
    index: number
  ): JSX.Element => {
    /* eslint-disable */
    if (
      (selectedEntity?.selectedElementType ===
        SelectedElementType.QUESTION_GROUP ||
        selectedEntity?.selectedElementType === SelectedElementType.QUESTION ||
        selectedEntity?.selectedElementType ===
          SelectedElementType.ONE_OF_MANY) &&
      questionGroup.questionGroup.id === questionGroupId
    ) {
      /* eslint-enable */
      return (
        <DynamicEditableFormWrapper
          key={questionGroup.questionGroup.id}
          title={`${questionGroup.serviceName}: ${questionGroup.questionGroup.name}`}
          stepNumber={index + 1}
          wrapped={false}
          questionGroup={questionGroup.questionGroup}
          wrapCardsCallback={(): void => {}}
          projectId={projectId}
          sampleId={sampleId}
          isReport
          workflowId={questionGroup.workflowId}
          serviceId={questionGroup.serviceId}
        />
      );
    }

    const getMutationBasedOnAnswerType = (answerType: AnswerType) => {
      switch (answerType) {
        case AnswerType.Number:
          return projectSampleWithNumericAnswer;
        case AnswerType.ManyOfMany:
          return projectSampleWithMultiAnswer;
        default:
          return projectSampleWithTextAnswer;
      }
    };

    const answerProjectSampleQuestion = (
      metadata: QuestionMetadata,
      answer: any
    ) => {
      if (!metadata.questionLevelInput.sampleId) {
        throw new Error("Metadata is missing sampleId");
      }
      const options = {
        variables: {
          answer,
          projectSampleQuestionInput: {
            questionInput: {
              ...metadata.questionInput,
              fromReport: true,
              workflowId: questionGroup.workflowId,
              serviceId: questionGroup.serviceId,
            },
            projectSampleInput: {
              projectId: metadata.questionLevelInput.projectId,
              sampleId: metadata.questionLevelInput.sampleId,
            },
          },
        },
        refetchQueries: [
          {
            query: UserProjectDocument,
            variables: {
              id: projectId,
            },
          },
        ],
        awaitRefetchQueries: true,
      };
      const mutation = getMutationBasedOnAnswerType(metadata.answerType);
      if (metadata.answerType === AnswerType.Number) {
        options.variables.answer = parseFloat(options.variables.answer) || 0;
      }
      const mutationPromise: Promise<unknown> = mutation(options);
      mutationPromise.catch((e) => {
        toast.error(e.toString());
      });
    };

    const answerProjectSampleQuestionDebouncedInternal = debounce(
      answerProjectSampleQuestion,
      runQuestionAnswerMutationAfterMs,
      {
        leading: false,
        trailing: true,
      }
    );

    const answerProjectSampleQuestionDebounced = (
      metadata: QuestionMetadata,
      answear: any
    ) => {
      if (lastMetadata !== JSON.stringify(metadata)) {
        lastMetadata = JSON.stringify(metadata);
        answerProjectSampleQuestionDebouncedInternal.flush();
      }
      answerProjectSampleQuestionDebouncedInternal(metadata, answear);
    };

    return (
      <DynamicFormWrapper
        key={questionGroup.questionGroup.id}
        title={`${questionGroup.serviceName}: ${questionGroup.questionGroup.name}`}
        stepNumber={index + 1}
        wrapped={false}
        questionLevelInput={{ projectId, sampleId }}
        questionGroup={questionGroup.questionGroup}
        answerQuestionCallback={answerProjectSampleQuestionDebounced}
        wrapCardsCallback={(): void => {}}
        projectId={projectId}
        sampleId={sampleId}
        isReport
        workflowId={questionGroup.workflowId}
        serviceId={questionGroup.serviceId}
        userRoleInSample={userRoleInSample}
      />
    );
  };

  const handleSampleClick = (id: string): void => {
    navigate(`/reports/${projectId}/${id}`);
  };

  return (
    <LayoutWithDroppableContext
      pageTitle={sampleName}
      tabs={getTabs(projectId, samples, sampleId || "1")}
      dropdownData={samples}
      selectedDropdownDataId={sample.id}
      onDataClick={(id: string): void => handleSampleClick(id)}
      projectId={projectId}
      selectedQuestionGroups={sample.selectedQuestionGroups}
      sample={sample}
      fromReport={questionGroups.length > 0}
      fixedSideDrawboard={false}
    >
      {questionGroups.length > 0 ? (
        questionGroups.map((qg, index) =>
          getSelectedQuestionGroupElement(qg, index)
        )
      ) : (
        <NoQuestionGroups />
      )}
    </LayoutWithDroppableContext>
  );
};

type SingleReportProps = RouteComponentProps & {
  samples: Sample[];
  sample: Sample;
};
