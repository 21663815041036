import * as React from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { InMemoryCache } from "apollo-cache-inmemory";
import { FetchResult } from "apollo-link";
import { Header } from "../Header/Header";
import { Centered } from "./Centered";
import { SideBar } from "../SideBar/SideBar";
import { SideDrawboard } from "../SideDrawboard/SideDrawboard";
import {
  QuestionGroup,
  Sample,
  useAddQuestionToSampleMutation,
} from "../../generated/graphql";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { SelectedQuestionGroupPath } from "../../utils/types/ActionContextTypes";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { SideDrawboardContent } from "../SideDrawboard/SideDrawboardContent/SideDrawboardContent";
import { projectSampleCacheUpdater } from "../../utils/helpers/dynamicForms/projectSampleCacheUpdater";

interface LayoutWithDroppableContextProps {
  pageTitle?: string;
  tabs?: React.ReactNode;
  dropdownData: any;
  selectedDropdownDataId: string;
  onDataClick?: any;
  projectId?: string;
  selectedQuestionGroups: QuestionGroup[];
  sample: Sample;
  fromReport?: boolean;
  fixedSideDrawboard?: boolean;
}

export const LayoutWithDroppableContext: React.FC<LayoutWithDroppableContextProps> =
  ({
    children,
    pageTitle = "Welcome",
    tabs,
    dropdownData,
    selectedDropdownDataId,
    onDataClick,
    projectId,
    sample,
    fromReport,
    fixedSideDrawboard = true,
  }) => {
    const [addQuestionToSampleMutation] = useAddQuestionToSampleMutation();
    const { sideBarOpen } = useSideBarsContext();

    const { selectedEntity } = useActionContext();
    const questionGroupId =
      selectedEntity &&
      (selectedEntity.selectedElementPath as SelectedQuestionGroupPath)
        .questionGroupId;

    const onDragEnd = React.useCallback(
      async (result: DropResult) => {
        const { source, destination, draggableId } = result;

        if (!destination) {
          return;
        }

        const encodedId = destination.droppableId;
        const partsOfEncodedString = encodedId.split("_$$_");
        const workflowIdFromId = partsOfEncodedString[1];
        const serviceIdFromId = partsOfEncodedString[2];

        switch (source.droppableId) {
          case destination.droppableId:
            console.log("Reorder event");
            break;
          case "FormQuestions": {
            const options = {
              variables: {
                projectSampleInput: {
                  projectId: projectId!,
                  sampleId: selectedDropdownDataId,
                  fromReport,
                  workflowId: workflowIdFromId,
                  serviceId: serviceIdFromId,
                },
                questionGroupId: questionGroupId!,
                questionId: draggableId,
                order: destination.index,
              },
              update(cache: InMemoryCache, mutationResult: FetchResult): void {
                projectSampleCacheUpdater(
                  "AddQuestionToSample",
                  cache,
                  mutationResult,
                  projectId || "",
                  selectedDropdownDataId
                );
              },
            };

            addQuestionToSampleMutation(options);

            break;
          }
          default:
            console.log("ReactDnD error");
            break;
        }
      },
      [questionGroupId, sample]
    );

    return (
      <Wrapper>
        <SideBar />
        <DragDropContext onDragEnd={onDragEnd}>
          <Main isSideBarOpen={sideBarOpen}>
            <Header pageTitle={pageTitle} />
            <NavWrapper>
              {tabs && <NavigationTabs>{tabs}</NavigationTabs>}
            </NavWrapper>
            <Content>
              <Centered>{children}</Centered>
            </Content>
          </Main>
          <SideDrawboard
            dropdownData={dropdownData}
            selectedDropdownDataId={selectedDropdownDataId}
            onDataClick={onDataClick}
            numberOfExistingWorkflows={0}
            fixedViewMode={fixedSideDrawboard}
          >
            <SideDrawboardContent />
          </SideDrawboard>
        </DragDropContext>
      </Wrapper>
    );
  };

const Wrapper = styled(Flex)`
  min-height: 100vh;
`;

const NavWrapper = styled.div`
  min-height: 36px;
  width: 100%;
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const NavigationTabs = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${(p): string => p.theme.maxContentWidth};
  background-color: ${(p): string => p.theme.newColors.grayscale.white};
`;

const Main = styled(Flex)<{ isSideBarOpen: boolean }>`
  width: ${({ isSideBarOpen }) =>
    isSideBarOpen ? "calc(100% - 184px - 72px);" : "calc(100% - 72px - 72px);"};
  flex-direction: column;
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
`;

const Content = styled(Flex)`
  background-color: ${(p): string => p.theme.newColors.grayscale.background};
`;
