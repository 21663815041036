import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

type Props = BaseIconProps;
export const RemoveIcon = (props: Props) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 7H14V9H2V7Z" fill={color} />
    </svg>
  );
};

RemoveIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.white,
};
