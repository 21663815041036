import * as React from "react";
import styled from "styled-components";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { TextFieldAutoHight } from "../../../../common/TextFieldAutoHight/TextFieldAutoHight";
import { FormQuestionsContainer } from "../../../../Projects/Configurator/FormQuestionsContainer/FormQuestionsContainer";
import {
  useUpdateQuestionGroupFromSampleMutation,
  UserProjectDocument,
} from "../../../../../generated/graphql";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedQuestionGroupPath,
  SelectedReportQuestionGroupPath,
} from "../../../../../utils/types/ActionContextTypes";
import { ReadQueryResult } from "../../../../../utils/types/QueryResult";

export const QuestionGroupEdit = () => {
  const { selectedEntity, setSelectedEntity } = useActionContext();

  if (
    !selectedEntity ||
    selectedEntity.selectedElementType !== SelectedElementType.QUESTION_GROUP
  )
    return <></>;

  const [questionGroupName, setQuestionGroupName] = React.useState(
    (selectedEntity.selectedElementPath as SelectedQuestionGroupPath)
      .questionGroupName
  );
  const [updateQuestionGroupFromSample] =
    useUpdateQuestionGroupFromSampleMutation();

  const handleNameChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    setQuestionGroupName(e.target.textContent || "");
  };

  const handleNameOnBlur = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (!e.target.textContent) {
      toast.error("You must enter a group name.");
      return;
    }

    if (e.target.textContent === questionGroupName) return;

    const {
      projectId,
      sampleId,
      questionGroupId,
      fromReport,
      workflowId,
      serviceId,
    } = selectedEntity.selectedElementPath as SelectedReportQuestionGroupPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
          fromReport,
          workflowId,
          serviceId,
        },
        questionGroupUpdatables: {
          id: questionGroupId,
          name: e.target.textContent,
        },
      },
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["UpdateQuestionGroupFromSample"];
        const existingQueryResult = cloneDeep(
          cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: projectId },
          })?.["Project"]
        );

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const foundSample = samples.find((s) => s.id === sampleId);

          if (foundSample) {
            let questionGroups;
            if (fromReport && workflowId && serviceId) {
              questionGroups = foundSample.enabledWorkflows
                .find((wf) => wf.id === workflowId)
                ?.services.find((s) => s.id === serviceId)
                ?.report?.selectedQuestionGroups;
            } else {
              questionGroups = foundSample.selectedQuestionGroups;
            }

            if (questionGroups) {
              const questionGroupIndex = questionGroups.findIndex(
                (qg) => qg.id === questionGroupId
              );
              if (questionGroupIndex !== -1) {
                questionGroups[questionGroupIndex] = incomingData;
                const sampleIndex = samples.findIndex((s) => s.id === sampleId);
                samples[sampleIndex] = { ...foundSample };
                existingQueryResult.samples = samples;
                cache.writeQuery({
                  variables: { id: projectId },
                  query: UserProjectDocument,
                  data: {
                    Project: {
                      ...existingQueryResult,
                    },
                  },
                });
              }
            }
          }
        }
      },
    };

    setSelectedEntity({
      selectedElementType: SelectedElementType.QUESTION_GROUP,
      selectedElementPath: {
        ...selectedEntity.selectedElementPath,
        questionGroupName: e.target.textContent,
      },
    });

    updateQuestionGroupFromSample(options);
  };

  return (
    <>
      <StyledTextFieldAutoHight
        title="Change section title:"
        value={questionGroupName || ""}
        onChange={handleNameChange}
        onBlur={handleNameOnBlur}
      />
      <FormQuestionsContainer />
    </>
  );
};

const StyledTextFieldAutoHight = styled(TextFieldAutoHight)`
  & > div {
    margin-bottom: 16px;
  }
`;
