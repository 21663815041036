import React from "react";
import styled from "styled-components";
import { SampleBar } from "../common/SampleBar";
import { MenuDotsIcon } from "../../../images/icons/MenuDotsIcon";
import { styledTheme } from "../../../theme/theme";

export const ProjectsListSampleBar = (props: ProjectsListSampleBarProps) => {
  const { id, name, percent, onClick } = props;

  return (
    <Container onClick={() => onClick(id)}>
      <SampleBar
        name={name}
        percent={percent}
        icon={<MenuDotsIcon color="#9D9EA3" />}
      />
    </Container>
  );
};

export type ProjectsListSampleBarProps = {
  id: string;
  name: string;
  percent: number;
  status: string;
  onClick?: any;
};

ProjectsListSampleBar.defaultProps = {
  active: false,
};

const Container = styled.div`
  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
`;
