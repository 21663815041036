import { css } from "styled-components";
import { styledTheme } from "../../../theme/theme";

export const primaryButton = css<{ disabled?: boolean }>`
  background: ${styledTheme.newColors.primary.basic};
  color: ${styledTheme.newColors.grayscale.white};
  border: 0;

  svg path {
    fill: ${styledTheme.newColors.grayscale.white};
  }

  svg {
    fill: ${styledTheme.newColors.grayscale.white};
  }

  &:hover {
    background: ${styledTheme.newColors.primary.hover};
  }

  &:active {
    background: ${styledTheme.newColors.primary.darkestTint};
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background: ${theme.newColors.grayscale.disabled};
      cursor: default;
      color: ${styledTheme.newColors.grayscale.placeholder};

      &:hover {
        background: ${theme.newColors.grayscale.disabled};
      }

      svg path {
        fill: ${styledTheme.newColors.grayscale.placeholder};
      }

      svg {
        fill: ${styledTheme.newColors.grayscale.placeholder};
      }
    `}
`;
