import { BaseWorkflow } from "../../../../../../generated/graphql";
import { Service, Workflow } from "../helpers/TypesForServicePackages";
import { sortWorkflows } from "./sortWorkflows";

export const getWorkflowsArrays = (
  workflows: BaseWorkflow[],
  selectedServices: string[] | null
) => {
  const recommendedArray: Workflow[] = [];
  const suggestedArray: Workflow[] = [];
  const othersArray: Workflow[] = [];
  if (selectedServices && selectedServices.length !== 0) {
    workflows?.forEach((workflow) => {
      const workflowCommon: Workflow = {
        id: workflow.id,
        name: workflow.name,
        services: [],
      };
      let isRecommended = false;
      let isSuggested = false;
      const matchingServices: string[] = [];
      workflow.services.forEach((service) => {
        let serviceCommon: Service = {
          id: service.id,
          name: service.name,
          tip: service.description,
        };

        if (selectedServices.includes(service.name)) {
          isSuggested = true;
          isRecommended = true;
          serviceCommon = { ...serviceCommon, selected: true };
          if (!matchingServices.includes(service.name)) {
            matchingServices.push(service.name);
          }
        }
        workflowCommon.services.push({ ...serviceCommon });
      });

      if (
        isRecommended &&
        selectedServices.length === matchingServices.length
      ) {
        recommendedArray.push({ ...workflowCommon });
      } else if (isSuggested) {
        suggestedArray.push({ ...workflowCommon });
      } else {
        othersArray.push({ ...workflowCommon });
      }
    });
  }

  return {
    recommended: sortWorkflows(recommendedArray),
    suggested: sortWorkflows(suggestedArray),
    others: sortWorkflows(othersArray),
  };
};
