import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const URLIcon = (props: URLIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 1.6001C11.5346 1.6001 14.4 4.46547 14.4 8.0001C14.4 11.5347 11.5346 14.4001 7.99998 14.4001C4.46535 14.4001 1.59998 11.5347 1.59998 8.0001C1.59998 4.46547 4.46535 1.6001 7.99998 1.6001ZM9.57374 8.80113H6.42626C6.50053 9.92413 6.72897 10.9423 7.06843 11.7061C7.40331 12.4596 7.76115 12.8001 8 12.8001C8.23884 12.8001 8.59668 12.4596 8.93156 11.7061C9.27102 10.9423 9.49947 9.92413 9.57374 8.80113ZM4.82481 8.80127L3.2664 8.80043C3.50438 10.2183 4.36392 11.4253 5.55525 12.1317C5.1654 11.2083 4.9035 10.0617 4.82481 8.80127ZM12.7336 8.80043L11.1752 8.80126C11.0965 10.0617 10.8345 11.2082 10.4443 12.1308C11.636 11.4252 12.4956 10.2182 12.7336 8.80043ZM5.55565 3.86937L5.52901 3.88412C4.35123 4.5927 3.50251 5.7925 3.26635 7.20003L4.82475 7.19974C4.90339 5.93902 5.16537 4.7922 5.55565 3.86937ZM7.99998 3.2001L7.95067 3.20466C7.71353 3.24734 7.38097 3.59088 7.06842 4.29411C6.72888 5.05809 6.5004 6.07655 6.42619 7.19987H9.57378C9.49956 6.07655 9.27109 5.05809 8.93155 4.29411C8.59667 3.54064 8.23883 3.2001 7.99998 3.2001ZM10.4447 3.86845L10.4474 3.87666C10.836 4.79827 11.0968 5.94241 11.1753 7.19985L12.7337 7.20055C12.496 5.78237 11.6363 4.57502 10.4447 3.86845Z"
        fill={color}
      />
    </svg>
  );
};

URLIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};

type URLIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
