import * as React from "react";
import styled from "styled-components";
import { PlatformLogo } from "../../../images/PlatformLogo";
import { SpinnerElement } from "./SpinnerElement";

interface SpinnerProps {
  className?: string;
  opaque?: boolean;
  containerSize?: boolean;
}

export const Spinner = (props: SpinnerProps): JSX.Element => {
  const { className, opaque, containerSize } = props;

  return (
    <Container
      className={className}
      data-testid="spinner"
      opaque={opaque}
      containerSize={containerSize}
    >
      <PlatformLogo color="black" />
      <SpinnerElement size={180} />
    </Container>
  );
};

const Container = styled.div<{
  opaque?: boolean;
  containerSize?: boolean;
}>`
  z-index: 100;
  position: ${({ containerSize }) => (containerSize ? "absolute" : "fixed")};
  left: 0;
  top: 0;
  width: ${({ containerSize }) => (containerSize ? "100%" : "100vw")};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${({ containerSize }) => (containerSize ? "100%" : "100vh")};
  background-color: rgba(
    255,
    255,
    255,
    ${({ opaque }): number => (opaque ? 0.6 : 0)}
  );

  > svg:last-child {
    position: absolute;
  }
`;
