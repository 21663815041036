import * as React from "react";

interface CheckboxFillProps {
  className?: string;
}

export const CheckboxFill = ({ className }: CheckboxFillProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="#3B79D1"
    />
    <path
      d="M7 13L10 16L17 8"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
