import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import moment from "moment/moment";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import {
  Project,
  useUserPlainProjectsWithSamplesQuery,
} from "../../generated/graphql";
import ServerError from "../ServerError";
import { Spinner } from "../common/Spinner/Spinner";
import { ProjectElement } from "../Projects/ProjectElement";
import { ReportsHeader } from "./ReportsHeader";
import { getProjectName } from "../../utils/helpers/getProjectName";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Reports = (_props: RouteComponentProps): JSX.Element => {
  const queryResult = useUserPlainProjectsWithSamplesQuery({
    variables: {},
  });

  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  if (queryResult.error) {
    return <ServerError />;
  }
  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const projects = queryResult.data.Projects || [];
  const projectsLength = projects.length;

  return (
    <LayoutDefault pageTitle="All reports">
      <ReportsHeader projectsNumber={projectsLength} />
      <ProjectsContainer>
        {projects.map((project) => {
          const projectLastActivityDateString = moment(
            project.lastActivity
              ? parseInt(project.lastActivity, 10)
              : undefined
          ).format("Do MMMM YYYY");
          return (
            <ProjectElement
              key={project.id}
              projectLastActivity={projectLastActivityDateString}
              projectName={getProjectName(project as unknown as Project)}
              currentService={project.currentService}
              onClick={() => {
                navigate(`/reports/${project.id}/${project.samples[0].id}`);
              }}
            />
          );
        })}
      </ProjectsContainer>
    </LayoutDefault>
  );
};

const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;
