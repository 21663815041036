import * as React from "react";
import styled from "styled-components";
import { bytesToSize } from "../../../../utils/helpers/bytesToSize";

const FileContainer = styled.div<{ isLoading: boolean }>`
  width: 312px;
  height: 32px;
  border-radius: 8px 8px 8px 0px;

  background: ${(props) =>
    props.isLoading
      ? props.theme.newColors.primary.lightestTint
      : props.theme.newColors.grayscale.primaryGray};

  .progressBar {
    display: ${(props) => (props.isLoading ? "block;" : "none;")};
  }
`;

const Label = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
`;

const LabelContainer = styled.div`
  display: flex;
  padding: 8px 12px;
`;

const FileName = styled.div<{ isLoading: boolean }>`
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: ${(props) => props.theme.lineHeights[2]};

  letter-spacing: -0.01em;

  color: ${(props) =>
    props.isLoading
      ? props.theme.newColors.grayscale.primaryGray
      : props.theme.newColors.grayscale.white};

  order: 0;
  align-self: center;
  margin-right: 5px;

  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.div<{ isLoading: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;

  color: ${(props) =>
    props.isLoading
      ? props.theme.newColors.grayscale.secondaryGray
      : props.theme.newColors.grayscale.placeholder};

  order: 1;
  align-self: center;
`;

export const FileLabel = (props: FileElementProps) => {
  const { name, size, percent = 100 } = props;
  const isLoading = percent !== 100;

  return (
    <>
      <FileContainer isLoading={isLoading}>
        <LabelContainer>
          <Label isLoading={isLoading}>
            <FileName isLoading={isLoading}>{name}</FileName>
            <FileSize isLoading={isLoading}>({bytesToSize(size)})</FileSize>
          </Label>
        </LabelContainer>
      </FileContainer>
    </>
  );
};

interface FileElementProps {
  name: string;
  size: number;
  percent: number;
}
