import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { InMemoryCache } from "apollo-cache-inmemory";
import TextareaAutosize from "react-textarea-autosize";
import {
  SelectedElementType,
  SelectedEventPath,
} from "../../../../utils/types/ActionContextTypes";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { DatePicker } from "../../../common/DatePicker/DatePicker";
import {
  UserProjectDocument,
  useUpdateEventFromSampleMutation,
} from "../../../../generated/graphql";
import { ReadQueryResult } from "../../../../utils/types/QueryResult";
import { isSelectedEventPath } from "../../../../utils/types/ActionContextGuards";
import { ContainerCheckbox } from "../../../common/ContainerCheckbox/ContainerCheckbox";

type EditEventInformationsProps = {
  editMode?: boolean;
};

export const EditEventInformations = (
  props: EditEventInformationsProps
): JSX.Element => {
  const { editMode = true } = props;
  const { setSelectedEntity, selectedEntity } = useActionContext();

  if (
    !selectedEntity ||
    !isSelectedEventPath(selectedEntity.selectedElementPath)
  )
    return <></>;

  const [isDescriptionFieldFocused, setIsDescriptionFieldFocused] =
    useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);
  const [updateEventFromSample] = useUpdateEventFromSampleMutation();
  const [eventDescription, setEventDescription] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date>();
  const [eventMilestoneChecked, setEventMilestoneChecked] =
    useState<boolean>(false);
  const [selectedDatePickerId, setSelectedDatePickerId] = useState(null);

  useEffect(() => {
    const { event } = selectedEntity.selectedElementPath as SelectedEventPath;
    setEventDescription(event.description);
    setEventName(event.name);
    setEventDate(new Date(+event.startDate));
    setEventMilestoneChecked(event.milestone || false);
  }, [selectedEntity]);

  const updateEvent = (target: string, value: string | boolean): void => {
    if (!isSelectedEventPath(selectedEntity.selectedElementPath)) return;

    const {
      projectId,
      sampleId,
      event: { id },
    } = selectedEntity.selectedElementPath;

    const options = {
      variables: {
        projectSampleEventInput: {
          projectId,
          sampleId,
          eventId: id,
        },
        eventUpdatables: {
          [target]: value,
        },
      },
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["UpdateEventFromSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleHere = samplesHere.find((s) => s.id === sampleId);

          const updatedEvents = sampleHere?.events.map((event) => {
            if (event.id === id) {
              return incomingData;
            }
            return event;
          });

          const newSamples = samplesHere.map((sampleLoop) => {
            if (sampleLoop.id === sampleId) {
              return {
                ...sampleLoop,
                events: updatedEvents,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            variables: { id: projectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: newSamples,
              },
            },
          });

          setSelectedEntity({
            selectedElementType: SelectedElementType.EVENT,
            selectedElementPath: {
              projectId,
              sampleId,
              originPathId: sampleId,
              event: incomingData,
            },
          });
        }
      },
    };

    updateEventFromSample(options);
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setEventName(text);
  };

  const handleEventDateChange = (newDate: Date): void => {
    newDate.setHours(12);
    newDate.setMinutes(0);
    updateEvent("startDate", newDate.getTime().toString());
  };

  const handleNameOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateEvent("name", trimmedText);
    setIsNameFieldFocused(false);
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setEventDescription(text);
  };

  const handleDescriptionOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateEvent("description", trimmedText);
    setIsDescriptionFieldFocused(false);
  };

  const handleOnCheckboxClick = (): void => {
    updateEvent("milestone", !eventMilestoneChecked);
    setEventMilestoneChecked((prev) => !prev);
  };

  const handleTextWithPencil = (flag: boolean, text: string): string => {
    let preparedText = text;

    if (flag) {
      preparedText = text;
    } else if (text.length > 0) {
      preparedText += " ✎";
    } else {
      preparedText = text;
    }

    return preparedText;
  };

  const descriptionText = handleTextWithPencil(
    isDescriptionFieldFocused,
    eventDescription
  );

  const preparedEventName = handleTextWithPencil(isNameFieldFocused, eventName);

  return (
    <Container>
      <EditableNameArea
        onChange={handleNameChange}
        onBlur={handleNameOnBlur}
        disabled={!editMode}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        value={preparedEventName}
        onFocus={() => setIsNameFieldFocused(true)}
        placeholder="Enter a name... ✎"
      />
      <EventInformation>
        <EventInformationLabel>Date</EventInformationLabel>
        <EventInformationValue editMode={editMode}>
          <DatePicker
            date={eventDate!}
            setDate={handleEventDateChange}
            editMode={editMode}
            update={updateEvent}
            destinationUpdate="startDate"
            showIcon
            selectedDatePickerId={selectedDatePickerId}
            setSelectedDatePickerId={setSelectedDatePickerId}
            datePickerId={1}
          />
        </EventInformationValue>
      </EventInformation>
      <EventInformationAdditionalMargin editMode={editMode}>
        <EventDescriptionInformationLabel>
          Description
        </EventDescriptionInformationLabel>
        <EditableDescriptionArea
          onChange={handleDescriptionChange}
          onBlur={handleDescriptionOnBlur}
          disabled={!editMode}
          value={descriptionText}
          onFocus={() => setIsDescriptionFieldFocused(true)}
          placeholder="Enter a description... ✎"
        />
      </EventInformationAdditionalMargin>
      <EventInformation>
        <EventInformationLabel>Show milestone line</EventInformationLabel>
        <EventMilestoneValue editMode={editMode}>
          <IconContainer>
            <ContainerCheckbox
              id={selectedEntity.selectedElementPath.event.id}
              onClick={handleOnCheckboxClick}
              checked={eventMilestoneChecked}
              readonly={!editMode}
            />
          </IconContainer>
        </EventMilestoneValue>
      </EventInformation>
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 24px;
  border-bottom: 1px solid
    ${(props) => props.theme.newColors.grayscale.bordersInside};
`;

const IconContainer = styled.div`
  margin-left: 30px;
`;

const EditableArea = styled(TextareaAutosize)`
  resize: none;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 6px 3px 6px 3px;
  position: relative;
  width: 100%;

  &:hover,
  &:active,
  &:focus-visible {
    border: solid 1px ${(props) => props.theme.newColors.primary.basic};
    outline: 0px solid transparent;
  }

  &:empty::before {
    content: attr(data-placeholder-description);
    color: #ccc;
    font-weight: 400;
  }

  &:empty:focus::before {
    content: "";
  }
`;

const EditableNameArea = styled(EditableArea)`
  min-height: 20px;
  line-height: 20px;
  font-size: 17px;
`;

const EditableDescriptionArea = styled(EditableArea)`
  min-height: 18px;
  line-height: 18px;
  font-size: 14px;
`;

const EventInformation = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 5px;
  column-gap: 23px;
  align-items: center;
`;

const EventInformationAdditionalMargin = styled(EventInformation)<{
  editMode: boolean | undefined;
}>`
  ${({ editMode }) =>
    editMode &&
    css`
      margin: 14px 0;
    `};
`;

const EventInformationLabel = styled.div`
  color: ${(props) => props.theme.newColors.primary.basic};
  padding: 3px 6px;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const EventDescriptionInformationLabel = styled(EventInformationLabel)`
  padding: 4px 6px;
  height: fit-content;
`;

const EventInformationValue = styled.div<{ editMode: boolean | undefined }>`
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: absolute;
  margin-left: 100px;

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;

const EventMilestoneValue = styled.div<{ editMode: boolean | undefined }>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 130px;

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;
