import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { navigate, RouteComponentProps } from "@reach/router";
import { ApolloError } from "apollo-client";
import { LayoutDefault } from "../Layouts/LayoutDefault";
import {
  useAddGroupMutation,
  useGroupsQuery,
  useRemoveGroupMutation,
  Group,
  useEditGroupNameMutation,
} from "../../generated/graphql";
import { Spinner } from "../common/Spinner/Spinner";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { Button } from "../common/Button/Button";
import { ButtonType } from "../common/Button/ButtonType";
import { Table } from "../Table/Table";
import {
  RedTableInLineButton,
  TableInLineButton,
} from "../Table/StyledTableComponents";
import { ConfirmationModal } from "../common/Modal/ConfirmationModal";
import { BaseInput } from "../common/BaseInput";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ManageGroups = (_props: ManageGroupsProps): JSX.Element => {
  useEffect(() => {
    document.title = "Leaderna Platform";
  }, []);

  const queryResult = useGroupsQuery();
  const [addGroup, addGroupMutationResult] = useAddGroupMutation({
    refetchQueries: ["Groups"],
  });
  const [removeGroup] = useRemoveGroupMutation();
  const [editGroupName] = useEditGroupNameMutation();

  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] =
    useState<Pick<Group, "id" | "name" | "members">>();
  const [
    isConfirmationModalForRemoveGroupOpen,
    setIsConfirmationModalForRemoveGroupOpen,
  ] = useState(false);
  const [loading, setLoading] = useState(false);

  if (queryResult.error) {
    return <p>Query Error: {queryResult.error}</p>;
  }
  if (queryResult.loading || !queryResult.data) {
    return <Spinner />;
  }

  const groupsData = queryResult.data.Groups;

  const removeGroupCallback = (groupId: string): void => {
    setLoading(true);

    removeGroup({
      variables: { groupId },
      refetchQueries: ["Groups"],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setLoading(false);
        setIsConfirmationModalForRemoveGroupOpen(false);
      })
      .catch((e: ApolloError) => {
        setLoading(false);
        setIsConfirmationModalForRemoveGroupOpen(false);
      });
  };

  const editGroupNameCallback = (groupId: string, name: string): void => {
    editGroupName({
      variables: { groupId, name },
      refetchQueries: ["Groups"],
      awaitRefetchQueries: true,
    });
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLDivElement>,
    groupId: string
  ): void => {
    editGroupNameCallback(groupId, e.target.innerText);
  };

  const getGroupRow = (group: Pick<Group, "id" | "name" | "members">) => {
    return [
      <NameDescriptionTextAreaContainer
        contentEditable
        suppressContentEditableWarning
        editMode
        onBlur={(e) => handleOnBlur(e, group.id)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        data-placeholder-name="Enter a name..."
      >
        {group.name}
      </NameDescriptionTextAreaContainer>,
      group.members.length,
      <>
        <TableInLineButton
          onClick={(): void => {
            navigate(`/manage-groups/${group.id}`);
          }}
        >
          SHOW MEMBERS
        </TableInLineButton>
        <TableInLineButton
          onClick={(): void => {
            navigate(`/group-tasks/${group.id}`);
          }}
        >
          SHOW ASSIGNED TASKS
        </TableInLineButton>
      </>,
      <RedTableInLineButton
        onClick={(): void => {
          setSelectedGroup(group);
          setIsConfirmationModalForRemoveGroupOpen(true);
        }}
      >
        REMOVE
      </RedTableInLineButton>,
    ];
  };

  return (
    <LayoutDefault pageTitle="Manage Teams and Equipment">
      <ManageGroupsContainer>
        <ContentSection
          title="Manage Groups"
          stepNumber={1}
          wrapCardsCallback={() => {}}
          buttons={[]}
          fullWidth
        >
          <StyledHeader>
            Teams and equipment groups ({groupsData.length})
          </StyledHeader>
          <StyledDiv>
            <StyledInput
              key="add-group"
              value={newGroupName}
              placeholder="Name of the new group"
              onChange={(e) => setNewGroupName(e.target.value)}
            />
            <StyledButton
              disabled={!newGroupName.length || addGroupMutationResult.loading}
              label="ADD"
              type={ButtonType.Primary}
              onClick={() => {
                addGroup({ variables: { name: newGroupName } }).then(() =>
                  setNewGroupName("")
                );
              }}
            />
          </StyledDiv>
          {isConfirmationModalForRemoveGroupOpen && selectedGroup && (
            <ConfirmationModal
              isOpen={isConfirmationModalForRemoveGroupOpen}
              message={`Are you sure you want to remove this group? (${selectedGroup.name})`}
              title="Confirmation"
              yesButtonText="Remove this group"
              closeModal={() => setIsConfirmationModalForRemoveGroupOpen(false)}
              acceptModal={() => {
                removeGroupCallback(selectedGroup.id);
              }}
              isLoading={loading}
            />
          )}
          <Table
            columns={["Groups", "Members", "Actions", "Remove group"]}
            isEditable
          >
            {groupsData.map((group) => getGroupRow(group))}
          </Table>
        </ContentSection>
      </ManageGroupsContainer>
    </LayoutDefault>
  );
};

type ManageGroupsProps = RouteComponentProps;

const StyledButton = styled(Button)`
  width: 78px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 42px 205px 2px 24px;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #3b79d1;
`;

const StyledDiv = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StyledInput = styled(BaseInput)`
  width: 205px;
  max-height: 48px;
  margin: 40px 12px 0 0;
  padding: 0 16px;
  box-shadow: 0 1px 2px 0 rgba(220, 222, 229, 0.3);
  background-color: #ffffff;
  font-size: 15px;
  line-height: 15px;
`;

const StyledHeader = styled.div`
  width: 400px;
  height: 24px;
  flex-grow: 0;
  font-family: Overpass, serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.36px;
  text-align: left;
  color: #232f3c;
`;

const ManageGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 24px;
    margin-bottom: 24px;
  }
`;

const NameDescriptionTextAreaContainer = styled.div<{
  editMode: boolean | undefined;
}>`
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 3px 18px 3px 3px;
  overflow-wrap: break-word;
  text-transform: none;
  white-space: pre-wrap;

  &:empty::before {
    content: attr(data-placeholder-name);
    color: #ccc;
    font-weight: 400;
  }

  &:empty:focus::before {
    content: "";
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.5 8.75V6.5C0.5 6.30109 0.579018 6.11032 0.71967 5.96967L5.96967 0.71967C6.26256 0.426777 6.73744 0.426777 7.03033 0.71967L9.28033 2.96967C9.57322 3.26256 9.57322 3.73744 9.28033 4.03033L4.03033 9.28033C3.88968 9.42098 3.69891 9.5 3.5 9.5H1.25C0.835786 9.5 0.5 9.16421 0.5 8.75ZM6.5 2.3107L2 6.8107V8.00004H3.18934L7.68934 3.50004L6.5 2.3107Z' fill='gray' /%3E%3C/svg%3E");
    margin-left: 8px;
    width: 10px;
    height: 10px;
    position: absolute;
    cursor: text;
    display: inline;
    white-space: nowrap;
  }

  ${({ editMode }) =>
    editMode &&
    css`
      min-width: calc(100% - 25px);
      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px ${(props) => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `}
`;
