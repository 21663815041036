import React from "react";
import { useTeamBlock } from "./useTeamBlock";
import { BlockWrapper } from "../BlockWrapper";
import { TeamTable } from "./TeamTable";

interface TeamBlockProps {
  projectId: string;
}

export const TeamBlock = (props: TeamBlockProps): JSX.Element => {
  const { projectId } = props;

  const { error, loading, users } = useTeamBlock({ projectId });

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  return (
    <BlockWrapper title="Team" loading={loading}>
      <TeamTable users={users} />
    </BlockWrapper>
  );
};
