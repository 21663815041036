import * as React from "react";
import styled from "styled-components";
import { TipIcon } from "../../../images/icons/TipIcon";

const Container = styled.div`
  font-size: 12px;
  line-height: 16px;
  display: flex;
  padding: 3px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const Message = styled.div<{ color: string }>`
  color: ${(props) => props.color};
`;

export const Tip = (props: TipProps) => {
  const { message, color } = props;
  return (
    <Container>
      <Icon>
        <TipIcon width="12" height="12" color={color} />
      </Icon>
      <Message color={color}>{message}</Message>
    </Container>
  );
};

interface TipProps {
  message: string;
  color: string;
}
