import * as React from "react";
import styled from "styled-components";

type Props = {
  text: string;
};
export const CardSeparator = (props: Props) => {
  return (
    <Container>
      <Text>{props.text}</Text>
      <SeparatorContainer>
        <Separator />
      </SeparatorContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-right: 8px;

  color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};
`;

const SeparatorContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.hr`
  width: 100%;
  height: 0;

  border-top: 1px solid
    ${({ theme }) => theme.newColors.grayscale.secondaryGray};
`;
