import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { MutationHookOptions } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { Modal } from "../common/Modal/Modal";
import { ButtonType } from "../common/Button/ButtonType";
import { Button } from "../common/Button/Button";
import { TextBox } from "../common/TextBox/TextBox";
import {
  UpdateSampleTimelineLineMutation,
  UpdateSampleTimelineLineMutationVariables,
  UserProjectDocument,
  useUpdateSampleTimelineLineMutation,
} from "../../generated/graphql";
import { ReadQueryResult } from "../../utils/types/QueryResult";

interface ChangePathLineNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  sampleId: string;
  pathLineNameToChange: {
    baseline: number;
    name: string;
  };
}

export const ChangePathLineNameModal = (
  props: ChangePathLineNameModalProps
): JSX.Element => {
  const { isOpen, onClose, projectId, sampleId, pathLineNameToChange } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState(pathLineNameToChange.name);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFieldValue(event.target.value);
  };

  const [updateSampleTimelineLine] = useUpdateSampleTimelineLineMutation();

  if (!isOpen) return <></>;

  const onAcceptModal = (): void => {
    if (fieldValue === pathLineNameToChange.name) {
      onClose();
      return;
    }

    setIsLoading(true);

    const options: MutationHookOptions<
      UpdateSampleTimelineLineMutation,
      UpdateSampleTimelineLineMutationVariables
    > = {
      variables: {
        projectId,
        sampleId,
        line: pathLineNameToChange.baseline,
        name: fieldValue.trim(),
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["UpdateSampleTimelineLine"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesCopy = [...existingQueryResult.samples];
          const sampleToUpdate = samplesCopy.find(
            (sampleCopy) => sampleCopy.id === sampleId
          );

          if (sampleToUpdate) {
            const updatedSamples = samplesCopy.map((sampleCopy) =>
              sampleCopy.id === sampleId ? incomingData : sampleCopy
            );

            cache.writeQuery({
              variables: { id: projectId },
              query: UserProjectDocument,
              data: {
                Project: {
                  ...existingQueryResult,
                  samples: updatedSamples,
                },
              },
            });
          }
        }
      },
    };

    updateSampleTimelineLine(options)
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const ModalButtons = (): JSX.Element => (
    <>
      <AcceptButton
        disabled={isLoading}
        label="Save Path Line Name"
        type={ButtonType.Primary}
        onClick={onAcceptModal}
      />
      <Button
        disabled={isLoading}
        label="Cancel"
        type={ButtonType.Secondary}
        onClick={onClose}
      />
    </>
  );

  return (
    <StyledModal
      id="changePathLineNameModal"
      title="Change Path Line Name"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <TextBox
        value={fieldValue}
        onChange={onChange}
        placeholder="Enter Path Line Name"
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;
