import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import { getProjectName } from "../../utils/helpers/getProjectName";
import { Project, useUserPlainProjectsQuery } from "../../generated/graphql";

type Option = {
  label: string;
  value: string;
};

interface ReturnType {
  selectedProject: Option;
  setSelectedProject: (option: Option) => void;
  projects: Option[];
  error: ApolloError | undefined;
  loading: boolean;
}

export const useDashboard = (): ReturnType => {
  const [selectedProject, setSelectedProject] = useState<Option>({
    label: "",
    value: "",
  });
  const [projects, setProjects] = useState<Option[]>([]);

  const { error, loading, data } = useUserPlainProjectsQuery();

  useEffect(() => {
    if (!data?.Projects) return;

    const projectsToSet = data.Projects.map((project) => ({
      value: project.id,
      label: getProjectName(project as unknown as Project),
    }));
    setProjects(projectsToSet);
    setSelectedProject(projectsToSet[0]);
  }, [data]);

  return {
    selectedProject,
    setSelectedProject,
    projects,
    error,
    loading: loading || !data || projects.length === 0,
  };
};
