import * as React from "react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { TextFieldAutoHight } from "../../../../common/TextFieldAutoHight/TextFieldAutoHight";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedQuestionPath,
  SelectedReportQuestionPath,
} from "../../../../../utils/types/ActionContextTypes";
import {
  UserProjectDocument,
  useUpdateQuestionInQuestionGroupFromSampleMutation,
} from "../../../../../generated/graphql";
import { ReadQueryResult } from "../../../../../utils/types/QueryResult";
import { Checkbox, CheckboxProps } from "../../../../common/Checkbox/Checkbox";

export const QuestionEdit = () => {
  const { selectedEntity, setSelectedEntity } = useActionContext();
  if (
    !selectedEntity ||
    selectedEntity.selectedElementType !== SelectedElementType.QUESTION
  )
    return <></>;

  const [title, setTitle] = React.useState(
    (selectedEntity.selectedElementPath as SelectedQuestionPath).questionName
  );
  const [description, setDescription] = React.useState(
    (selectedEntity.selectedElementPath as SelectedQuestionPath)
      .questionDescription
  );
  const [checked, setChecked] = React.useState(
    (selectedEntity.selectedElementPath as SelectedQuestionPath)
      .questionIsObligatory
  );

  React.useEffect(() => {
    setTitle(
      (selectedEntity.selectedElementPath as SelectedQuestionPath).questionName
    );
    setDescription(
      (selectedEntity.selectedElementPath as SelectedQuestionPath)
        .questionDescription
    );
    setChecked(
      (selectedEntity.selectedElementPath as SelectedQuestionPath)
        .questionIsObligatory
    );
  }, [selectedEntity]);

  const [updateQuestionInQuestionGroupFromSample] =
    useUpdateQuestionInQuestionGroupFromSampleMutation();

  const useQuestionMutation = (
    destination: string,
    value: string | boolean
  ) => {
    const {
      projectId,
      sampleId,
      questionId,
      questionSetId,
      questionGroupId,
      fromReport,
      workflowId,
      serviceId,
    } = selectedEntity.selectedElementPath as SelectedReportQuestionPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        questionInput: {
          questionId,
          questionSetId,
          questionGroupId,
          fromReport,
          workflowId,
          serviceId,
        },
        questionUpdatables: {
          id: questionId,
          [destination]: value,
        },
      },
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["UpdateQuestionInQuestionGroupFromSample"];
        const existingQueryResult = cloneDeep(
          cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: projectId },
          })?.["Project"]
        );

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const foundSample = samples.find((s) => s.id === sampleId);

          if (foundSample) {
            let selectedQuestionGroups;
            if (fromReport && workflowId && serviceId) {
              selectedQuestionGroups = foundSample.enabledWorkflows
                .find((wf) => wf.id === workflowId)
                ?.services.find((s) => s.id === serviceId)
                ?.report?.selectedQuestionGroups;
            } else {
              selectedQuestionGroups = foundSample.selectedQuestionGroups;
            }

            if (selectedQuestionGroups) {
              const questionGroupIndex = selectedQuestionGroups.findIndex(
                (qg) => qg.id === questionGroupId
              );
              if (questionGroupIndex !== -1) {
                const questionSetIndex = selectedQuestionGroups[
                  questionGroupIndex
                ].questionSets.findIndex((qs) => qs.id === questionSetId);

                if (questionSetIndex !== -1) {
                  const questionIndex = selectedQuestionGroups[
                    questionGroupIndex
                  ].questionSets[questionSetIndex].questions.findIndex(
                    (q) => q.id === questionId
                  );

                  if (questionIndex !== -1) {
                    selectedQuestionGroups[questionGroupIndex].questionSets[
                      questionSetIndex
                    ].questions[questionIndex] = incomingData;

                    const sampleIndex = samples.findIndex(
                      (s) => s.id === sampleId
                    );
                    samples[sampleIndex] = { ...foundSample };

                    existingQueryResult.samples = [...samples];

                    cache.writeQuery({
                      variables: { id: projectId },
                      query: UserProjectDocument,
                      data: {
                        Project: { ...existingQueryResult },
                      },
                    });
                  }
                }
              }
            }
          }
        }
      },
    };

    updateQuestionInQuestionGroupFromSample(options);
  };

  const handleOnTitleChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    setTitle(e.target.textContent || "");
  };

  const handleOnTitleBlur = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (!e.target.textContent) {
      toast.error("You must enter a field name.");
      return;
    }

    if (e.target.textContent === title) return;

    useQuestionMutation("question", e.target.textContent);

    setSelectedEntity({
      selectedElementType: SelectedElementType.QUESTION,
      selectedElementPath: {
        ...selectedEntity.selectedElementPath,
        questionName: e.target.textContent,
      },
    });
  };

  const handleOnDescriptionChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    setDescription(e.target.textContent || "");
  };

  const handleOnDescriptionBlur = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (e.target.textContent === description) return;

    useQuestionMutation("description", e.target.textContent || "");

    setSelectedEntity({
      selectedElementType: SelectedElementType.QUESTION,
      selectedElementPath: {
        ...selectedEntity.selectedElementPath,
        questionDescription: e.target.textContent || "",
      },
    });
  };

  const handleOnRequiredChange = (e: CheckboxProps) => {
    const booleanValue = e.checked === true;
    setChecked(booleanValue);

    useQuestionMutation("isObligatory", booleanValue);

    setSelectedEntity({
      selectedElementType: SelectedElementType.QUESTION,
      selectedElementPath: {
        ...selectedEntity.selectedElementPath,
        questionIsObligatory: booleanValue,
      },
    });
  };

  return (
    <>
      <TextFieldAutoHight
        title="Title:"
        value={title}
        onChange={handleOnTitleChange}
        onBlur={handleOnTitleBlur}
      />
      <TextFieldAutoHight
        title="Description:"
        value={description}
        onChange={handleOnDescriptionChange}
        onBlur={handleOnDescriptionBlur}
      />
      <Checkbox
        value="Required"
        checked={checked}
        onChange={handleOnRequiredChange}
        labelText="Required"
        name="options"
      />
    </>
  );
};
