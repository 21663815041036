import React, { useState } from "react";
import styled from "styled-components";
import { ApolloError } from "apollo-client";
import { toast } from "react-toastify";
import { ButtonType } from "../common/Button/ButtonType";
import { Modal } from "../common/Modal/Modal";
import { Button } from "../common/Button/Button";
import { useChangePasswordMutation } from "../../generated/graphql";
import { TextBox } from "../common/TextBox/TextBox";

type ChangePasswordProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChangePasswordModal = (
  props: ChangePasswordProps
): JSX.Element => {
  const { isOpen, onClose } = props;

  const [newPassword1FieldValue, setNewPassword1FieldValue] = useState("");
  const [newPassword2FieldValue, setNewPassword2FieldValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [changePassword] = useChangePasswordMutation();

  const onChange1 = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword1FieldValue(event.target.value);
  };
  const onChange2 = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword2FieldValue(event.target.value);
  };

  const onAcceptModal = (): void => {
    if (newPassword1FieldValue !== newPassword2FieldValue) {
      toast.error("New passwords do not match");
      return;
    }

    setIsLoading(true);

    changePassword({
      variables: {
        newPassword: newPassword1FieldValue,
      },
      awaitRefetchQueries: true,
    })
      .then(() => {
        setIsLoading(false);
        onClose();
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
      });
  };

  const ModalButtons = (): JSX.Element => {
    return (
      <>
        <AcceptButton
          disabled={isLoading}
          label="Change password"
          type={ButtonType.Primary}
          onClick={onAcceptModal}
        />
        <Button
          disabled={isLoading}
          label="Cancel"
          type={ButtonType.Secondary}
          onClick={onClose}
        />
      </>
    );
  };

  if (!isOpen) return <></>;

  return (
    <StyledModal
      id="changePasswordModal"
      title="Change password"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <>
        <TextBox
          type="password"
          placeholder="New password"
          value={newPassword1FieldValue}
          onChange={onChange1}
        />
        <TextBox
          type="password"
          placeholder="Confirm new password"
          value={newPassword2FieldValue}
          onChange={onChange2}
        />
      </>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;
