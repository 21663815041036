import * as React from "react";
import { FunctionComponent } from "react";
import { Formik, Form as FormikForm, FormikValues, FormikConfig } from "formik";
import styled from "styled-components";

const Container = styled.div`
  & div:last-child > hr {
    display: none;
  }
`;

export const Form: FunctionComponent<FormikConfig<FormikValues>> = ({
  children,
  ...rest
}) => {
  return (
    <Formik {...rest}>
      <FormikForm className="needs-validation">
        <Container>{children}</Container>
      </FormikForm>
    </Formik>
  );
};
