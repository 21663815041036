import * as React from "react";
import styled from "styled-components";
import { ImageMountainIcon } from "../../../../../images/icons/ImageMountainIcon";
import { styledTheme } from "../../../../../theme/theme";
import { Label } from "../../../../common/Label/Label";

export const NoServicePacks = (props: { className?: string }) => {
  return (
    <Container className={props.className}>
      <Content>
        <ImageContainer>
          <ImageMountainIcon
            width={48}
            height={48}
            color={styledTheme.newColors.grayscale.white}
            secondColor={styledTheme.colors.gray05}
            thirdColor={styledTheme.colors.lightText}
          />
        </ImageContainer>
        <StyledLabel label="List of service packages will appear here.">
          Once you will select at least one desired service from the list,
          packages will appear below. The more services you select, the closer
          platform suggestions will be.
        </StyledLabel>
      </Content>
    </Container>
  );
};

const StyledLabel = styled(Label)`
  margin: auto !important;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 381px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray05};
  width: 96px;
  height: 96px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
