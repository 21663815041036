import React, { useState } from "react";
import { ContentSection } from "../common/ContentSection/ContentSection";
import { DynamicForm } from "./DynamicForm";
import { getFormSchemaFromQuestionGroup } from "../../utils/helpers/dynamicForms/getFormSchemaFromQuestionGroup";
import {
  ProjectWithAllSamplesDocument,
  QuestionGroup,
  useDuplicateProjectMutation,
  useDuplicateSampleInProjectMutation,
  UserPlainProjectsWithSamplesDocument,
  UserProjectDocument,
} from "../../generated/graphql";
import { getValidationSchemaFromQuestionGroup } from "../../utils/helpers/dynamicForms/getValidationSchemaFromQuestionGroup";
import { getFormDataFromQuestionGroup } from "../../utils/helpers/dynamicForms/getFormDataFromQuestionGroup";
import { QuestionLevelInput } from "../../utils/types/QuestionMetadata";
import { Button } from "../common/Button/Button";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { ButtonType } from "../common/Button/ButtonType";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { SelectedElementType } from "../../utils/types/ActionContextTypes";
import { ConfirmationModal } from "../common/Modal/ConfirmationModal";
import { useUserRolesContext } from "../../utils/hooks/useUserRolesContext";
import { ROLES } from "../../constants/roles";

export const DynamicFormWrapper = (
  props: DynamicFormWrapperProps
): JSX.Element => {
  const {
    questionGroup,
    title,
    stepNumber,
    wrapped,
    questionLevelInput,
    answerQuestionCallback,
    wrapCardsCallback,
    goToNextGroupCallback,
    goTo1stSampleCallback,
    deleteProjectCallback,
    projectId,
    sampleId,
    isReport,
    workflowId,
    serviceId,
    handleOnPrepareForPrinting,
    userRoleInSample,
  } = props;

  const numberOfQuestions = questionGroup.questionSets.reduce(
    (accumulator, currentValue) => accumulator + currentValue.questions.length,
    0
  );

  const { setSideDrawboardOpen } = useSideBarsContext();
  const { setSelectedEntity } = useActionContext();
  const { useUserPermissions } = useUserRolesContext();

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [openDuplicateConfirmationModal, setOpenDuplicateConfirmationModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [duplicateSampleInProjectMutation] =
    useDuplicateSampleInProjectMutation();

  const [duplicateProjectMutation] = useDuplicateProjectMutation();

  const handleEditQuestionGroup = (): void => {
    setSelectedEntity({
      selectedElementType: SelectedElementType.QUESTION_GROUP,
      selectedElementPath: {
        questionGroupId: questionGroup.id,
        questionGroupName: questionGroup.name,
        projectId,
        sampleId,
        fromReport: isReport,
        workflowId,
        serviceId,
      },
    });
    setSideDrawboardOpen(true);
  };

  const handlePrintingQuestionGroup = (): void => {
    if (handleOnPrepareForPrinting)
      handleOnPrepareForPrinting(questionGroup.id);
  };

  const handleDuplicateSample = (): void => {
    setIsLoading(true);

    duplicateSampleInProjectMutation({
      variables: {
        projectId,
        sampleId,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
        {
          query: ProjectWithAllSamplesDocument,
          variables: { id: projectId },
        },
      ],
    })
      .then(() => {
        setIsLoading(false);
        setOpenDuplicateConfirmationModal(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOpenDuplicateConfirmationModal(false);
      });
  };

  const handleDuplicateProject = (): void => {
    setIsLoading(true);

    duplicateProjectMutation({
      variables: {
        projectId,
      },
      refetchQueries: [
        {
          query: UserPlainProjectsWithSamplesDocument,
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setIsLoading(false);
        setOpenDuplicateConfirmationModal(false);
      })
      .catch(() => {
        setIsLoading(false);
        setOpenDuplicateConfirmationModal(false);
      });
  };

  const editIsEnabled = useUserPermissions("editForm", userRoleInSample);
  const deleteIsEnabled = useUserPermissions("deleteForm", userRoleInSample);
  const duplicationIsEnabled = useUserPermissions(
    "projectDuplication",
    userRoleInSample
  );

  const getSectionButtons = (): React.ReactNode[] => {
    const buttonArray: JSX.Element[] = [];

    const isProjectConfigurator = title === "General project description";
    const isSampleConfigurator = title === "General path description";

    if (goToNextGroupCallback) {
      buttonArray.push(
        <Button label="Go to next section" onClick={goToNextGroupCallback} />
      );
    }

    if (goTo1stSampleCallback) {
      buttonArray.push(
        <Button label="Go to 1st sample" onClick={goTo1stSampleCallback} />
      );
    }

    if (
      duplicationIsEnabled &&
      (isProjectConfigurator || isSampleConfigurator)
    ) {
      buttonArray.push(
        <>
          <Button
            label="Duplicate"
            onClick={(): void => setOpenDuplicateConfirmationModal(true)}
          />
          <ConfirmationModal
            isOpen={openDuplicateConfirmationModal}
            message={`Are you sure you want to duplicate this ${
              isProjectConfigurator ? "project" : "path"
            }?`}
            yesButtonText="DUPLICATE"
            title="Confirmation"
            closeModal={(): void => setOpenDuplicateConfirmationModal(false)}
            acceptModal={
              isProjectConfigurator
                ? handleDuplicateProject
                : handleDuplicateSample
            }
            isLoading={isLoading}
          />
        </>
      );
    }

    if (isProjectConfigurator && deleteProjectCallback && deleteIsEnabled) {
      buttonArray.push(
        <Button
          label="Delete Project"
          type={ButtonType.Quaternary}
          onClick={(): void => setOpenDeleteConfirmationModal(true)}
        />,
        <ConfirmationModal
          isOpen={openDeleteConfirmationModal}
          message="Are you sure you want to delete this project?"
          yesButtonText="DELETE PROJECT"
          title="Confirmation"
          closeModal={(): void => setOpenDeleteConfirmationModal(false)}
          acceptModal={deleteProjectCallback}
        />
      );
    }

    if (!isProjectConfigurator && editIsEnabled) {
      buttonArray.push(
        <Button label="Edit" onClick={handleEditQuestionGroup} />
      );
    }

    if (!isProjectConfigurator && handleOnPrepareForPrinting) {
      buttonArray.push(
        <Button
          label="Prepare printout"
          onClick={handlePrintingQuestionGroup}
        />
      );
    }

    return buttonArray;
  };

  return (
    <ContentSection
      title={title}
      stepNumber={stepNumber}
      wrapped={wrapped}
      wrapCardsCallback={wrapCardsCallback}
      buttons={getSectionButtons()}
    >
      {numberOfQuestions > 0 ? (
        <DynamicForm
          formData={getFormDataFromQuestionGroup(questionGroup)}
          validationSchema={getValidationSchemaFromQuestionGroup(questionGroup)}
          formSchema={getFormSchemaFromQuestionGroup(
            questionGroup,
            questionLevelInput,
            answerQuestionCallback
          )}
          onSubmit={(values: any): void => console.log(values)}
          key={questionGroup.id}
          readonly={!editIsEnabled}
        />
      ) : (
        <div key={questionGroup.id}>
          Empty Question Group - Edit to add new form elements
        </div>
      )}
    </ContentSection>
  );
};

interface DynamicFormWrapperProps {
  questionGroup: QuestionGroup;
  questionLevelInput: QuestionLevelInput;
  title: string;
  stepNumber: number;
  wrapped: boolean;
  answerQuestionCallback: any;
  wrapCardsCallback: () => void;
  goToNextGroupCallback?: () => void;
  goTo1stSampleCallback?: () => void;
  deleteProjectCallback?: () => void;
  projectId: string;
  sampleId: string;
  isReport?: boolean;
  workflowId?: string;
  serviceId?: string;
  handleOnPrepareForPrinting?: (questionGroupId: string) => void;
  userRoleInSample?: ROLES;
}
