import { useEffect, useState } from "react";
import { ApolloError } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { MutationHookOptions } from "react-apollo";
import { toast } from "react-toastify";
import {
  Priority,
  ProjectUser,
  UpdateServiceFromWorkflowMutation,
  UpdateServiceFromWorkflowMutationVariables,
  UpdateWorkflowFromSampleMutationVariables,
  useProjectUsersQuery,
  UserProjectDocument,
  useUpdateServiceFromWorkflowMutation,
  useUpdateWorkflowFromSampleMutation,
  UpdateWorkflowFromSampleMutation,
  useAddServiceToWorkflowMutation,
  AddServiceToWorkflowMutation,
  AddServiceToWorkflowMutationVariables,
  WorkflowForCustomer,
} from "../../../../generated/graphql";
import { ReadQueryResult } from "../../../../utils/types/QueryResult";
import { updateServices } from "../../../../utils/helpers/updateServices";

interface ReturnType {
  updateTask: (taskId: string, target: string, value: string | object) => void;
  updateSubtask: (
    taskId: string,
    subtaskId: string,
    target: string,
    value: string | object | boolean | Priority | null,
    lockedDate?: [string, string]
  ) => void;
  addSubtask: (taskId: string) => void;
  users: ProjectUser[];
  error: ApolloError | undefined;
  loading: boolean;
  showSpinner: boolean;
}

interface Props {
  projectId: string;
  sampleId: string;
  reloadTasks?: () => void;
}

export const useTasksTable = (props: Props): ReturnType => {
  const { projectId, sampleId, reloadTasks } = props;

  const [users, setUsers] = useState<ProjectUser[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    loading: usersLoading,
    data: usersData,
    error: usersError,
  } = useProjectUsersQuery({
    variables: {
      projectId,
    },
    fetchPolicy: "cache-first",
  });

  const [updateWorkflowFromSample] = useUpdateWorkflowFromSampleMutation();
  const [updateServiceFromWorkflow] = useUpdateServiceFromWorkflowMutation();
  const [addServiceToWorkflow] = useAddServiceToWorkflowMutation();

  useEffect(() => {
    if (!usersData?.ProjectUsers) return;

    setUsers(usersData.ProjectUsers as ProjectUser[]);
  }, [usersData]);

  const updateTask = (
    taskId: string,
    target: string,
    value: string | object
  ): void => {
    const options: MutationHookOptions<
      UpdateWorkflowFromSampleMutation,
      UpdateWorkflowFromSampleMutationVariables
    > = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowUpdatables: {
          id: taskId,
          [target]: value,
        },
      },
      update(cache: InMemoryCache, { data }) {
        const incomingData = data?.["UpdateWorkflowFromSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const sample = samples.find((s) => s.id === sampleId);

          if (sample) {
            const newSamples = samples.map((sampleLoop) => {
              if (sampleLoop.id === sampleId) {
                const newWorkflows = sampleLoop.enabledWorkflows.map(
                  (workflowLoop) => {
                    if (workflowLoop.id === taskId) {
                      return incomingData;
                    }
                    return workflowLoop;
                  }
                );
                return {
                  ...sampleLoop,
                  enabledWorkflows: newWorkflows,
                };
              }

              return sampleLoop;
            });

            cache.writeQuery({
              variables: { id: projectId },
              query: UserProjectDocument,
              data: {
                Project: {
                  ...existingQueryResult,
                  samples: newSamples,
                },
              },
            });
          }
        }
      },
    };

    updateWorkflowFromSample(options).catch((error) => {
      toast.error(error);

      if (reloadTasks) reloadTasks();
    });
  };

  const updateSubtask = (
    taskId: string,
    subtaskId: string,
    target: string,
    value: string | object | boolean | Priority | null,
    lockedDate?: [string, string]
  ): void => {
    const options: MutationHookOptions<
      UpdateServiceFromWorkflowMutation,
      UpdateServiceFromWorkflowMutationVariables
    > = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId: taskId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceUpdatables: {
          id: subtaskId,
          ...(lockedDate
            ? {
                startDate: lockedDate[0],
                endDate: lockedDate[1],
              }
            : { [target]: value }),
        },
      },
      update(cache: InMemoryCache, { data }): void {
        const incomingData = data?.["UpdateServiceFromWorkflow"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const existingSamples = [...existingQueryResult.samples];
          const foundSample = existingSamples.find((s) => s.id === sampleId);

          const updatedEnabledWorkflows = foundSample?.enabledWorkflows.map(
            (workflow) => ({
              ...workflow,
              services: updateServices(workflow.services, incomingData),
            })
          );

          const samplesToSave = existingSamples?.map((sampleLoop) => {
            if (sampleLoop.id === sampleId) {
              return {
                ...sampleLoop,
                enabledWorkflows: updatedEnabledWorkflows,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            query: UserProjectDocument,
            variables: { id: projectId },
            data: {
              Project: {
                ...existingQueryResult,
                samples: samplesToSave,
              },
            },
          });
        }
      },
    };

    if (target === "assignee") {
      options.refetchQueries = ["ServicesAssignedToUserForTimeline"];
    }

    updateServiceFromWorkflow(options).catch((error) => {
      toast.error(error);
      if (reloadTasks) reloadTasks();
    });
  };

  const addSubtask = (taskId: string): void => {
    setShowSpinner(true);

    const options: MutationHookOptions<
      AddServiceToWorkflowMutation,
      AddServiceToWorkflowMutationVariables
    > = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId: taskId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceId: "NewServiceBlock",
        startDate: Date.now().toString(),
      },
      update(cache: InMemoryCache, { data }): void {
        const incomingData = data?.["AddServiceToWorkflow"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const existingSamples = [...existingQueryResult.samples];
          const foundSample = existingSamples.find((s) => s.id === sampleId);

          if (foundSample) {
            const mappedWorkflows = foundSample.enabledWorkflows.map(
              (workflow) => {
                if (workflow.id === taskId) {
                  return incomingData as WorkflowForCustomer;
                }
                return workflow;
              }
            );

            const samplesToSave = existingSamples?.map((existingSample) => {
              if (existingSample.id === sampleId) {
                return {
                  ...existingSample,
                  enabledWorkflows: mappedWorkflows,
                };
              }
              return existingSample;
            });

            cache.writeQuery({
              query: UserProjectDocument,
              variables: { id: projectId },
              data: {
                Project: {
                  ...existingQueryResult,
                  samples: samplesToSave,
                },
              },
            });
          }
        }

        setShowSpinner(false);
      },
    };

    addServiceToWorkflow(options).catch((error) => {
      toast.error(error);
      if (reloadTasks) reloadTasks();
    });
  };

  return {
    updateTask,
    updateSubtask,
    addSubtask,
    users,
    error: usersError,
    loading: usersLoading || !usersData,
    showSpinner,
  };
};
