import React from "react";
import styled from "styled-components";
import { styledTheme } from "../../../theme/theme";
import { SamplePercent } from "../../Projects/common/SamplePercent";

type ProgressWidgetT = {
  requiredItems: number;
  filledItems: number;
};

export const ProgressWidget = (props: ProgressWidgetT) => {
  const { requiredItems, filledItems } = props;

  const percent = Math.round((filledItems / (requiredItems || 1)) * 100);

  return (
    <Container>
      <SamplePercent
        percent={percent}
        lineWidth={2}
        radius={25}
        containerWidth={null}
        color={styledTheme.newColors.primary.basic}
      />
      <PercentContainer>
        {percent}
        <div>%</div>
      </PercentContainer>
      <LabelContainer>
        <span>{filledItems}</span> out of {requiredItems}
      </LabelContainer>
    </Container>
  );
};

ProgressWidget.defaultProps = {
  requiredItems: 0,
  filledItems: 0,
};

const Container = styled.div`
  margin-left: 20px;
  position: sticky;
  top: 10px;
  max-width: 176px;
  min-width: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
  padding: 24px 0;

  background: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  box-shadow: ${styledTheme.shadows.userInputs.basicInside};
  border-radius: 4px;

  > div:first-child > div {
    display: none;
  }
`;

const PercentContainer = styled.div`
  position: absolute;
  top: 34px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.08em;
  width: 50px;
  height: 50px;
  color: ${styledTheme.newColors.grayscale.primaryGray};

  div {
    font-size: 12px;
    line-height: 12px;
    color: ${styledTheme.newColors.grayscale.secondaryGray};
  }
`;

const LabelContainer = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
  margin-top: 16px;

  span {
    font-weight: 800;
    color: ${styledTheme.newColors.grayscale.primaryGray};
  }
`;
