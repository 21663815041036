import React from "react";
import styled from "styled-components";
import { SideBar } from "../SideBar/SideBar";

export const LayoutWithoutHeader: React.FC = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const ChildrenContainer = styled.div`
  flex-grow: 1;
`;
