import * as React from "react";
import styled, { css } from "styled-components";
import { styledTheme } from "../../theme/theme";
import { ChainIcon } from "../../images/icons/ChainIcon";
import { RectRounded } from "./RectRounded";
import { TextWrapped } from "./TextWrapped";
import { RectArrow } from "./RectArrow";
import { getIsBlockVeryThin, getMaxTextWidth } from "./axisHelpers";

export type BlockType = {
  name: string;
  width: number;
  height: number;
  x: number;
  y: number;
  ownScale: number;
  headerHeight?: number;
  length?: string;
  children?: React.ReactNode;
  showTitleOnHover?: boolean;
  showDetails?: boolean;
  onBlockClick?: any;
  selected?: boolean;
  blockColor?: string;
  blockId?: string;
  disableDnD?: boolean;
  isWorkflow?: boolean;
  serviceIsDelayed?: boolean;
  todayX?: number;
  hideTooltip?: boolean;
};

const Block = ({
  name,
  x,
  y,
  width,
  height,
  length,
  children,
  ownScale = 1,
  showTitleOnHover = false,
  showDetails = false,
  onBlockClick,
  selected = false,
  blockId,
  disableDnD = false,
  blockColor = styledTheme.newColors.grayscale.primaryGray,
  isWorkflow = false,
  serviceIsDelayed = false,
  todayX = 0,
  hideTooltip = false,
}: BlockType): JSX.Element => {
  const topHeight = 40;
  const bottomHeight = height ? height - (showDetails ? 100 : topHeight) : 200;
  const radius = parseInt(styledTheme.radius.default, 10);
  const radiusInScale = radius * ownScale;
  const xWithRadius = x + radiusInScale;
  const widthWithRadius = Math.max(width - radiusInScale, 0.1);
  const selectedArrowWitdth = 12 * ownScale;
  const totalBlockHeight = showDetails
    ? (height + 8) * ownScale + 15 + 30 * ownScale
    : (height + 8) * ownScale;
  const blockIsVeryThin = getIsBlockVeryThin(widthWithRadius);
  const delayedColor = styledTheme.newColors.extra.errorState;
  const delayedBlockX = xWithRadius + widthWithRadius;
  const delayedWidth = todayX - delayedBlockX;
  const delayedString = serviceIsDelayed ? " DELAYED" : "";

  const shortenedBlockName =
    name && name.length > 15 ? `${name.slice(0, 15).trim()}…` : name;

  return (
    <G onClick={onBlockClick} disableDnd={disableDnD}>
      {showTitleOnHover && !blockIsVeryThin ? (
        <Tooltip
          className="tooltip"
          x={x + 10 * ownScale}
          y={y - 15 * ownScale}
          ownScale={ownScale}
        >
          {name + delayedString}
        </Tooltip>
      ) : null}
      {!hideTooltip && blockIsVeryThin ? (
        <TooltipAngled
          x={x + 10 * ownScale}
          y={y - 5 * ownScale}
          ownScale={ownScale}
          transform={`rotate(-40 ${x + 10 * ownScale} ${y - 5 * ownScale})`}
        >
          {shortenedBlockName}
        </TooltipAngled>
      ) : null}
      {serviceIsDelayed ? (
        <>
          <RectRounded
            className="BlockHeader"
            id={blockId}
            type="TOP"
            x={xWithRadius + widthWithRadius}
            y={y}
            height={topHeight * ownScale}
            width={delayedWidth}
            radius={radiusInScale}
            fill={delayedColor}
          />
          <RectRounded
            type="BOTTOM"
            x={xWithRadius + widthWithRadius}
            y={
              showDetails
                ? y - bottomHeight + topHeight * ownScale
                : y - bottomHeight * ownScale + topHeight * ownScale
            }
            height={showDetails ? bottomHeight : bottomHeight * ownScale}
            width={delayedWidth}
            radius={radiusInScale}
            fill={delayedColor}
            ownScale={ownScale}
            showDetails={showDetails}
          />
        </>
      ) : null}
      <RectRounded
        className="BlockHeader"
        id={blockId}
        type="TOP"
        x={xWithRadius}
        y={y}
        height={topHeight * ownScale}
        width={widthWithRadius}
        radius={radiusInScale}
        fill={blockColor}
      />
      <ChainIcon
        secondColor={styledTheme.newColors.grayscale.primaryGray}
        height={(16 * ownScale).toString()}
        width={(16 * ownScale).toString()}
        x={xWithRadius + (blockIsVeryThin ? -6 : 1) * ownScale}
        y={y + (blockIsVeryThin ? 2 : 12) * ownScale}
      />
      <RectRounded
        type="BOTTOM"
        x={xWithRadius}
        y={
          showDetails
            ? y - bottomHeight + topHeight * ownScale
            : y - bottomHeight * ownScale + topHeight * ownScale
        }
        height={showDetails ? bottomHeight : bottomHeight * ownScale}
        width={widthWithRadius}
        radius={radiusInScale}
        fill={styledTheme.newColors.grayscale.white}
        ownScale={ownScale}
        showDetails={showDetails}
      />
      <TextWrapped
        className="BlockHeader"
        id={blockId}
        text={name}
        maxWidth={getMaxTextWidth(ownScale, widthWithRadius)}
        x={xWithRadius + (blockIsVeryThin ? -6 : 22) * ownScale}
        y={y + (blockIsVeryThin ? 34 : 25.5) * ownScale}
        fontSize={16 * ownScale}
        fill={styledTheme.newColors.grayscale.white}
      />
      <text
        textAnchor="end"
        x={xWithRadius + widthWithRadius - (blockIsVeryThin ? 2 : 0) * ownScale}
        y={y + (blockIsVeryThin ? 16 : 25.5) * ownScale}
        fill={styledTheme.newColors.grayscale.white}
        fontSize={16 * ownScale}
        fontWeight="bold"
      >
        {length}
      </text>
      <g
        transform={`translate(${x}, ${
          y + topHeight * ownScale + 10 * ownScale
        })`}
      >
        {children}
      </g>
      {
        // PINGWING svg version of z-index zIndex
        selected && <use xlinkHref={blockId} />
      }
      {selected && isWorkflow ? (
        <RectRounded
          type="BOTTOM"
          x={xWithRadius}
          y={
            showDetails
              ? y - bottomHeight + topHeight * ownScale
              : y - bottomHeight * ownScale + topHeight * ownScale
          }
          height={showDetails ? bottomHeight : bottomHeight * ownScale}
          width={widthWithRadius}
          radius={radiusInScale}
          fill={styledTheme.newColors.extra.selectedTaskOverlay}
        />
      ) : null}
      {selected && !disableDnD && !isWorkflow ? (
        <>
          <RectArrow
            type="LEFT"
            x={xWithRadius}
            y={y}
            height={totalBlockHeight}
            width={selectedArrowWitdth}
            radius={radiusInScale}
            fill={styledTheme.newColors.extra.selectedTaskBorder}
          />
          <RectArrow
            type="RIGHT"
            x={xWithRadius + widthWithRadius - selectedArrowWitdth}
            y={y}
            height={totalBlockHeight}
            width={selectedArrowWitdth}
            radius={radiusInScale}
            fill={styledTheme.newColors.extra.selectedTaskBorder}
          />
        </>
      ) : null}
    </G>
  );
};

const G = styled.g<{ disableDnd?: boolean }>`
  :hover .tooltip {
    visibility: visible;
  }

  ${({ disableDnd }) =>
    disableDnd &&
    css`
      cursor: pointer;
    `}
`;

const Tooltip = styled.text<{ ownScale?: number }>`
  visibility: hidden;
  font-size: ${({ ownScale = 1 }): number => ownScale * 20}px;
`;

const TooltipAngled = styled.text<{
  ownScale?: number;
  x?: number;
  y?: number;
}>`
  font-size: ${({ ownScale = 1 }): number => ownScale * 20}px;
`;

export default Block;

Block.defaultProps = {
  width: 220,
  height: 110,
  x: 100,
  y: 100,
  ownScale: 1,
  headerHeight: 40,
  showTitleOnHover: false,
};
