import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import shortid from "shortid";
import axios from "axios";
import { FieldConfig } from "formik";
import { Files } from "./Files";
import { useUploadLinksLazyQuery } from "../../../generated/graphql";
import { FileDropZone } from "./FileDropZone";

const generateFileName = (fileName: string): string => {
  const extension = fileName.replace(/.*\./, "");
  return `${shortid.generate()}.${extension}`;
};

const parseFilesToQuery = (files: FilesToUploadT[]): UploadLinksT[] =>
  files
    .filter((file) => file.percent === 0)
    .map((file) => ({
      fileId: file.id,
      fileName: file.generatedFileName,
      fileType: file.fileItem.type,
    }));

const parseFilesToComponent = (
  files: FilesT[],
  filesToUpload: FilesToUploadT[]
): FilesT[] => {
  const parsedFilesName: string[] = [];
  const parsedFiles = files.map((file) => {
    parsedFilesName.push(file.s3FileName);
    return {
      ...file,
      percent: 100,
    };
  });
  const parsedFilesToUpload = filesToUpload
    .map((fileToUpload) => ({
      fileName: fileToUpload.fileItem.name,
      s3FileName: fileToUpload.generatedFileName,
      fileSize: fileToUpload.fileItem.size,
      percent: fileToUpload.percent,
    }))
    .filter(
      (fileToUpload) => !parsedFilesName.includes(fileToUpload.s3FileName)
    );
  return ([] as FilesT[]).concat(parsedFiles, parsedFilesToUpload);
};

const parseFieldValue = (fieldValue: string): FilesT[] => {
  if (fieldValue?.length) {
    return JSON.parse(fieldValue);
  }
  return [];
};

export const FileUploader = (props: FileUploaderPropsT): JSX.Element => {
  const { onChange, field, horizontally, disabled } = props;

  const { getRootProps, getInputProps, isDragAccept, open, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
    });

  const [files, setFiles] = useState<FilesT[]>(parseFieldValue(field.value));
  const [filesToUpload, setFilesToUpload] = useState<FilesToUploadT[]>([]);
  const [finishedFilesQueue, setFinishedFilesQueue] = useState<FilesT[]>([]);
  const [saving, setSaving] = useState(false);

  const [getUploadLinksQuery, { data: uploadLinksQueryData }] =
    useUploadLinksLazyQuery();

  useEffect(() => {
    setFiles(parseFieldValue(field.value));
    setSaving(false);
  }, [field.value]);

  useEffect(() => {
    if (!saving) {
      const fileToProceed = finishedFilesQueue.shift();
      if (fileToProceed) {
        setSaving(true);
        setFinishedFilesQueue(finishedFilesQueue);
        onChange(JSON.stringify([...files, fileToProceed]));
      }
    }
  });

  useEffect(() => {
    if (
      uploadLinksQueryData &&
      uploadLinksQueryData.UploadLinks &&
      uploadLinksQueryData.UploadLinks.length
    ) {
      uploadLinksQueryData.UploadLinks.forEach((uploadLink) => {
        const fileItem = filesToUpload.find(
          (file) => file.id === uploadLink.fileId
        );
        if (fileItem) {
          const axiosConfig = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": fileItem.fileItem.type,
            },
            onUploadProgress: (progressEvent: any): void => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              const filesToUploadArray = [...filesToUpload];
              const fileIndex = filesToUploadArray.findIndex(
                (arrayElement) => arrayElement.id === uploadLink.fileId
              );
              if (fileIndex >= 0) {
                filesToUploadArray[fileIndex].percent = percentCompleted;
                setFilesToUpload(filesToUploadArray);
              }
            },
          };
          const finishedFile = {
            fileName: fileItem.fileItem.name,
            s3FileName: fileItem.generatedFileName,
            fileSize: fileItem.fileItem.size,
          };
          axios
            .put(uploadLink.link, fileItem.fileItem, axiosConfig)
            .then(() => {
              const updatedFinishedFilesQueue = [...finishedFilesQueue];
              updatedFinishedFilesQueue.push(finishedFile);
              setFinishedFilesQueue(updatedFinishedFilesQueue);
            })
            .catch((err) => {
              console.log(
                `[ERROR] uploading file ${fileItem.fileItem.name}`,
                err
              );
            })
            .then(() => {
              const updatedFilesToUpload = filesToUpload.filter(
                (fileToUpload) =>
                  fileToUpload.generatedFileName !== fileItem.generatedFileName
              );
              setFilesToUpload(updatedFilesToUpload);
              setFiles([...files, finishedFile]);
            });
        }
      });
    }
  }, [JSON.stringify(uploadLinksQueryData)]);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length) {
      const filesToUploadArray = [...filesToUpload];
      acceptedFiles.forEach((acceptedFile) => {
        const id = shortid.generate();
        const generatedFileName = generateFileName(acceptedFile.name);
        filesToUploadArray.push({
          fileItem: acceptedFile,
          id,
          generatedFileName,
          percent: 0,
        });
      });
      setFilesToUpload(filesToUploadArray);
      getUploadLinksQuery({
        variables: { fileInput: parseFilesToQuery(filesToUploadArray) },
      });
    }
  }, [acceptedFiles.length]);

  const deleteFile = (fileName: string): void => {
    const filesArray = files.filter((file) => file.s3FileName !== fileName);
    onChange(JSON.stringify(filesArray));
    setFilesToUpload(
      filesToUpload.filter((file) => file.generatedFileName !== fileName)
    );
  };

  return (
    <div className="container">
      <Files
        files={parseFilesToComponent(files, filesToUpload)}
        onDeleteFile={deleteFile}
      />
      <FileDropZone
        rootProps={getRootProps({ isDragAccept })}
        inputProps={getInputProps()}
        open={open}
        horizontally={horizontally}
        disabled={disabled}
      />
    </div>
  );
};

FileUploader.defaultProps = {
  horizontally: false,
};

export type FileUploaderPropsT = {
  field: FieldConfig;
  onChange: (uploadedFilesData: string) => void;
  horizontally?: boolean;
  disabled?: boolean;
};

type FilesT = {
  fileName: string;
  s3FileName: string;
  fileSize: number;
  percent?: number;
};

type FilesToUploadT = {
  fileItem: File;
  id: string;
  generatedFileName: string;
  percent: number;
};

type UploadLinksT = {
  fileId: string;
  fileName: string;
  fileType: string;
};
