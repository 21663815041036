import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const PreviewUrlIcon = (props: PreviewUrlIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 0.125H0.75V1.125V14.25V15.25H1.75H14.25H15.25V14.25V10.5H13.25V13.25H2.75V2.125H5.5V0.125H1.75ZM14.25 0.125H8.625V2.125H11.8358L6.66789 7.29289L8.08211 8.70711L13.25 3.53921V6.75H15.25V1.125V0.125H14.25Z"
        fill={color}
      />
    </svg>
  );
};

PreviewUrlIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.primary.basic,
};

type PreviewUrlIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
