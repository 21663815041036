import * as React from "react";
import { styledTheme } from "../../theme/theme";

type RoundedRectProps = {
  x: number;
  y: number;
  height: number;
  width: number;
  radius: number;
  fill: string;
  type: "TOP" | "BOTTOM";
  className?: string;
  id?: string;
  ownScale?: number;
  showDetails?: boolean;
};

export function RectRounded(props: RoundedRectProps) {
  const tooNarrow = props.ownScale ? props.width / props.ownScale < 250 : false;
  const widthIfIsTooNarrow = tooNarrow ? 120 : props.width;
  const widthIfShowDetails = props.showDetails
    ? widthIfIsTooNarrow
    : props.width;

  return props.type === "TOP" ? (
    <path
      className={props.className}
      id={props.id}
      d={`M${props.x},${props.y + props.height} h${props.width} v-${
        props.height - props.radius
      } q${0},-${props.radius} -${props.radius},-${props.radius}  h-${
        props.width - props.radius
      } q-${props.radius},0 ${-props.radius},${props.radius} v${
        props.height - props.radius
      } z`}
      fill={props.fill}
    />
  ) : (
    <path
      className={props.className}
      id={props.id}
      d={`M${props.x},${props.y + props.height} h${widthIfShowDetails} v${
        props.height
      } q${0},${props.radius} -${props.radius},${props.radius}  h-${
        widthIfShowDetails - props.radius
      } q-${props.radius},0 ${-props.radius},${-props.radius} v-${
        props.height
      } z`}
      fill={props.fill}
    />
  );
}
RectRounded.defaultProps = {
  x: 100,
  y: 100,
  height: 100,
  width: 100,
  radius: 100,
  fill: styledTheme.newColors.grayscale.primaryGray,
};
