import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const PlusIconBig = ({
  width,
  height,
  color,
  ...props
}: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1113 0.333252H5.88908V5.88875H0.333496V8.11098H5.88908V13.6666H8.1113V8.11098H13.6668V5.88875H8.1113V0.333252Z"
        fill={styledTheme.newColors.primary.basic}
      />
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    </svg>
  );
};
PlusIconBig.defaultProps = {
  width: "14",
  height: "14",
};
