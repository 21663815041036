import * as React from "react";
import { FunctionComponent } from "react";
import { FieldProps } from "formik";
import { TextBox } from "../../../common/TextBox/TextBox";
import { FormGroup } from "../FormGroup";
import { FormElementProps } from "../../../../utils/helpers/dynamicForms/FormElementProps";

type Props = FormElementProps & {
  type: string;
};

type OwnProps = FieldProps & Props;

export const TextField: FunctionComponent<OwnProps> = (props) => {
  const { placeholder, field, label, description, type } = props;

  return (
    <FormGroup label={label} description={description}>
      <TextBox
        placeholder={placeholder === null ? undefined : placeholder}
        type={type}
        {...field}
        value={field.value}
        onlyForRead
      />
    </FormGroup>
  );
};
