import uniq from "lodash/uniq";
import { getSelectedWorkflows } from "./getSelectedWorkflows";
import { BaseWorkflow } from "../../../../../../generated/graphql";

export const getSelectedServicesInWorkflow = (
  workflows: BaseWorkflow[],
  selectedWorkflowsIds?: string[]
) => {
  const workflowsArray = getSelectedWorkflows(workflows, selectedWorkflowsIds);
  const servicesIds: string[] = [];
  workflowsArray.forEach((wrk) =>
    wrk.services.forEach((service) => servicesIds.push(service.name))
  );

  return uniq(servicesIds);
};
