/* eslint-disable react/no-array-index-key */
import React from "react";
import styled from "styled-components";
import { ExternalLinksElement } from "./ExternalLinksElement";
import { styledTheme } from "../../../../../theme/theme";
import { PlusIcon } from "../../../../../images/icons/PlusIcon";

const parseValue = (linksValue: string): string[] => {
  if (linksValue && linksValue.length) {
    return JSON.parse(linksValue);
  }
  return [""];
};

export const ExternalLinks = (props: ExternalLinksPropsT): JSX.Element => {
  const { onUpdateCallback, linksValue, disabled } = props;
  const [links, setLinks] = React.useState(parseValue(linksValue));

  React.useEffect(() => {
    const linksToUpdate = JSON.stringify(links);
    if (linksToUpdate !== linksValue) {
      onUpdateCallback(linksToUpdate);
    }
  }, [JSON.stringify(links)]);

  const updateLink = (id: number, value: string): void => {
    const linksArray = [...links];
    linksArray[id] = value;
    setLinks(linksArray);
  };

  const deleteLink = (id: number): void => {
    const linksArray = [...links];
    linksArray.splice(id, 1);
    if (linksArray.length === 0) {
      linksArray.push("");
    }
    setLinks(linksArray);
  };

  const addLink = (): void => {
    const linksArray = [...links];
    linksArray.push("");
    setLinks(linksArray);
  };

  return (
    <Container>
      <ExternalLinksElementContainer>
        {links.map((link, index) => (
          <ExternalLinksElement
            key={`externalLink${index}`}
            testId="externalLinksElement"
            id={index}
            value={link}
            onChange={(linkValue: string): void => updateLink(index, linkValue)}
            onDelete={(linkId: number): void => deleteLink(linkId)}
            disabled={disabled}
          />
        ))}
      </ExternalLinksElementContainer>
      {!disabled && (
        <AddLinkContainer
          onClick={addLink}
          data-testid="externalLinks_addButton"
        >
          <PlusIcon color={styledTheme.newColors.primary.basic} />
          ADD ANOTHER LINK
        </AddLinkContainer>
      )}
    </Container>
  );
};

type ExternalLinksPropsT = {
  onUpdateCallback?: any;
  linksValue: string;
  disabled?: boolean;
};

ExternalLinks.defaultProps = {
  onUpdateCallback: () => {},
  linksValue: "",
};

const Container = styled.div``;

const ExternalLinksElementContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }
`;

const AddLinkContainer = styled.div`
  margin-top: 4px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.primary.basic};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 180px;

  svg {
    margin-right: 10px;
    transform: scale(1.5);
  }
`;
