import React, { CSSProperties, useState } from "react";
import styled from "styled-components";
import { useAuth } from "react-use-auth";
import moment from "moment";
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DraggableStyle,
  Droppable,
} from "@hello-pangea/dnd";
import { StaticBlock } from "./StaticBlock";
import { StaticEvent } from "./StaticEvent";
import { ChainIcon } from "../../../../images/icons/ChainIcon";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { definedColors } from "../../../SideDrawboard/InformationsContainer/ColorChooser";
import { MeetingIcon } from "../../../../images/icons/MeetingIcon";
import { styledTheme } from "../../../../theme/theme";
import {
  useRemoveWorkflowTemplateMutation,
  useWorkflowsByOrganizationQuery,
  Workflow,
} from "../../../../generated/graphql";
import { Spinner } from "../../../common/Spinner/Spinner";
import { ConfirmationModal } from "../../../common/Modal/ConfirmationModal";

type Block = {
  id: string;
  element: JSX.Element;
};

const getDefinedBlocks = (numberOfExistingWorkflows: number): Block[] => {
  const { selectedWorkflowIndex } = useActionContext();

  const newSubtaskColor = definedColors[selectedWorkflowIndex];
  const newTaskColorIndex = numberOfExistingWorkflows % definedColors.length;
  const newTaskColor = definedColors[newTaskColorIndex];

  return [
    {
      id: "NewWorkflowBlock",
      element: (
        <StyledStaticBlock
          blockName="New task"
          blockColor={newTaskColor.color}
          dragHandleVisible
        />
      ),
    },
    {
      id: "NewServiceBlock",
      element: (
        <StyledStaticBlock
          blockName="New subtask"
          blockColor={newSubtaskColor.color}
          dragHandleVisible
        />
      ),
    },
  ];
};

const getDefinedEvents = (): Block[] => {
  return [
    {
      id: "NewEventBlock_Meeting",
      element: <StaticEvent icon={<MeetingIcon />} dragHandleVisible />,
    },
    {
      id: "NewEventBlock",
      element: (
        <StaticEvent icon={<ChainIcon color="#00715A" />} dragHandleVisible />
      ),
    },
  ];
};

const getDefinedWorkflowsTemplates = (
  workflows: Workflow[],
  numberOfExistingWorkflows: number,
  onDeleteClick?: (id: string) => void
): Block[] => {
  const newTaskColorIndex = numberOfExistingWorkflows % definedColors.length;
  const newTaskColor = definedColors[newTaskColorIndex];

  return workflows.map((workflow) => {
    const startDates = workflow.services.map((service) => +service.startDate);
    const dueDates = workflow.services.map((service) => +service.endDate);

    const duration = moment(Math.max(...dueDates)).diff(
      moment(Math.min(...startDates)),
      "week"
    );

    return {
      id: workflow.id,
      element: (
        <StyledStaticBlock
          blockName={workflow.name}
          blockColor={newTaskColor.color}
          dragHandleVisible
          duration={duration}
          showDeleteIcon
          onDeleteIconClick={(): void => {
            if (onDeleteClick) onDeleteClick(workflow.id);
          }}
        />
      ),
    };
  });
};

const getDraggableElementStyle = (
  snapshot: DraggableStateSnapshot,
  style?: DraggableStyle
): CSSProperties | undefined => {
  if (!snapshot.isDropAnimating || snapshot.draggingOver !== "TIMELINE") {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
    opacity: 0,
  };
};

const getDraggingBlockClone =
  (numberOfExistingWorkflows: number) =>
  (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ): JSX.Element => {
    const definedBlocks = getDefinedBlocks(numberOfExistingWorkflows);
    const definedBlock = definedBlocks[rubric.source.index];

    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={getDraggableElementStyle(
          snapshot,
          provided.draggableProps.style
        )}
        key={definedBlock.id}
      >
        {React.cloneElement(definedBlock.element, {
          dragHandleProps: provided.dragHandleProps,
        })}
      </div>
    );
  };

const getDraggingEventClone =
  () =>
  (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ): JSX.Element => {
    const definedEvents = getDefinedEvents();
    const definedEvent = definedEvents[rubric.source.index];

    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={getDraggableElementStyle(
          snapshot,
          provided.draggableProps.style
        )}
        key={definedEvent.id}
      >
        {React.cloneElement(definedEvent.element, {
          dragHandleProps: provided.dragHandleProps,
        })}
      </div>
    );
  };

const getDraggingTemplateBlockClone =
  (workflows: Workflow[], numberOfExistingWorkflows: number) =>
  (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric
  ): JSX.Element => {
    const definedBlocks = getDefinedWorkflowsTemplates(
      workflows,
      numberOfExistingWorkflows
    );
    const definedBlock = definedBlocks[rubric.source.index];

    return (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={getDraggableElementStyle(
          snapshot,
          provided.draggableProps.style
        )}
        key={definedBlock.id}
      >
        {React.cloneElement(definedBlock.element, {
          dragHandleProps: provided.dragHandleProps,
        })}
      </div>
    );
  };

interface BlocksAdderProps {
  numberOfExistingWorkflows: number;
  organization?: string;
}

export const BlocksAdder = (props: BlocksAdderProps): JSX.Element => {
  const { numberOfExistingWorkflows, organization } = props;

  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [templateIdToDelete, setTemplateIdToDelete] = useState<
    string | undefined
  >();

  const [removeWorkflowTemplateMutation] = useRemoveWorkflowTemplateMutation();

  const { loading, error, data, refetch } = useWorkflowsByOrganizationQuery({
    variables: {
      organization:
        user["https://leaderneplatform.com/organization"] || organization,
    },
  });

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  if (loading || !data) {
    return <Spinner opaque />;
  }

  const removeWorkflowTemplate = (): void => {
    if (!templateIdToDelete) return;

    setIsLoading(true);

    removeWorkflowTemplateMutation({
      variables: {
        templateId: templateIdToDelete,
      },
    })
      .then(() => {
        refetch();
        setTemplateIdToDelete(undefined);
        setIsLoading(false);
        setIsModalOpen(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onDeleteClick = (id: string): void => {
    setTemplateIdToDelete(id);
    setIsModalOpen(true);
  };

  const definedBlocks = getDefinedBlocks(numberOfExistingWorkflows);
  const definedEvents = getDefinedEvents();
  const definedWorkflowsTemplates = getDefinedWorkflowsTemplates(
    data.WorkflowsByOrganization as Workflow[],
    numberOfExistingWorkflows,
    onDeleteClick
  );

  return (
    <Container>
      <Header>add new block</Header>
      <Droppable
        renderClone={getDraggingBlockClone(numberOfExistingWorkflows)}
        droppableId="BlockAdder"
        isDropDisabled
      >
        {(droppableProvided, snapshot): JSX.Element => (
          <BlocksContainer ref={droppableProvided.innerRef}>
            {definedBlocks.map((item, index) => {
              const renderClone = item.id === snapshot.draggingFromThisWith;

              return (
                <React.Fragment key={item.id}>
                  {renderClone ? (
                    React.cloneElement(item.element, {
                      copy: renderClone,
                      key: item.id,
                    })
                  ) : (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                    >
                      {(draggableProvided): JSX.Element => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          {React.cloneElement(item.element, {
                            dragHandleProps: draggableProvided.dragHandleProps,
                          })}
                        </div>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            <div style={{ display: "none" }}>
              {droppableProvided.placeholder}
            </div>
          </BlocksContainer>
        )}
      </Droppable>
      <Droppable
        renderClone={getDraggingEventClone()}
        droppableId="EventsAdder"
        isDropDisabled
        direction="horizontal"
      >
        {(droppableProvided, snapshot): JSX.Element => (
          <EventsContainer ref={droppableProvided.innerRef}>
            {definedEvents.map((item, index) => {
              const renderClone = item.id === snapshot.draggingFromThisWith;

              return (
                <React.Fragment key={item.id}>
                  {renderClone ? (
                    React.cloneElement(item.element, {
                      copy: renderClone,
                      key: item.id,
                    })
                  ) : (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                    >
                      {(draggableProvided): JSX.Element => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          {React.cloneElement(item.element, {
                            dragHandleProps: draggableProvided.dragHandleProps,
                          })}
                        </div>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            <div style={{ display: "none" }}>
              {droppableProvided.placeholder}
            </div>
          </EventsContainer>
        )}
      </Droppable>
      <Header>add from a template</Header>
      <Droppable
        renderClone={getDraggingTemplateBlockClone(
          data.WorkflowsByOrganization as Workflow[],
          numberOfExistingWorkflows
        )}
        droppableId="WorkflowTemplatesAdder"
        isDropDisabled
      >
        {(droppableProvided, snapshot): JSX.Element => (
          <BlocksContainer ref={droppableProvided.innerRef}>
            {definedWorkflowsTemplates.map((item, index) => {
              const renderClone = item.id === snapshot.draggingFromThisWith;

              return (
                <React.Fragment key={item.id}>
                  {renderClone ? (
                    React.cloneElement(item.element, {
                      copy: renderClone,
                      key: item.id,
                    })
                  ) : (
                    <Draggable
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                    >
                      {(draggableProvided): JSX.Element => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          {React.cloneElement(item.element, {
                            dragHandleProps: draggableProvided.dragHandleProps,
                          })}
                        </div>
                      )}
                    </Draggable>
                  )}
                </React.Fragment>
              );
            })}
            <div style={{ display: "none" }}>
              {droppableProvided.placeholder}
            </div>
          </BlocksContainer>
        )}
      </Droppable>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          message="Do you want to remove this task template?"
          title="Confirmation"
          closeModal={(): void => setIsModalOpen(false)}
          acceptModal={removeWorkflowTemplate}
          isLoading={isLoading}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 21px 32px;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.primary.basic};
`;

const BlocksContainer = styled.div``;

const StyledStaticBlock = styled(StaticBlock)`
  margin-top: 1rem;
`;

const EventsContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
`;
