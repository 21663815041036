import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const DeleteIcon = (props: DeleteIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9v10H8V9h8zm-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
        fill={color}
      />
    </svg>
  );
};

DeleteIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.extra.errorState,
};

type DeleteIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
