import React, { useEffect, useState } from "react";
import { CollaboratorOption } from "../Collaborators/types";
import { getSelectedCollaboratorsAvatars } from "../Collaborators/getSelectedCollaboratorsAvatars";
import {
  CollaboratorsSelection,
  PlusContainer,
  SelectionContainer,
  StyledDropDown,
} from "../Collaborators/CollaboratorsStyledComponents";

export const EditTaskCollaborators = ({
  allCollaborators,
  currentCollaborators,
  updateWorkflow,
  workflowId,
  editMode = true,
}: {
  allCollaborators: CollaboratorOption[];
  currentCollaborators: CollaboratorOption[];
  updateWorkflow: (collaboratorOptions: CollaboratorOption[]) => any;
  workflowId: string;
  editMode: boolean;
}): JSX.Element => {
  const [hideCollaborators, setHideCollaborators] = useState(true);
  const [selectedCollaborators, setCollaborators] = useState<
    CollaboratorOption[]
  >(currentCollaborators || []);

  useEffect(() => {
    setCollaborators(currentCollaborators);
  }, [workflowId]);

  const unselectedOptions = allCollaborators.filter(
    (a) => !selectedCollaborators.find((s) => s.value.id === a.value.id)
  );

  const onSelectBlur = () => {
    if (!hideCollaborators) {
      setHideCollaborators(true);
    }
    return updateWorkflow(selectedCollaborators);
  };

  return (
    <CollaboratorsSelection
      spread={!hideCollaborators}
      onClick={() => {
        if (editMode) setHideCollaborators(false);
      }}
      editMode={editMode}
    >
      {hideCollaborators ? (
        <SelectionContainer>
          {getSelectedCollaboratorsAvatars(selectedCollaborators)}
          {editMode && <PlusContainer>+</PlusContainer>}
        </SelectionContainer>
      ) : (
        <StyledDropDown
          autoFocus
          onBlur={onSelectBlur}
          options={unselectedOptions}
          isMulti
          onChange={(e: CollaboratorOption[]) => {
            setCollaborators(e ?? []);
          }}
          value={selectedCollaborators}
        />
      )}
    </CollaboratorsSelection>
  );
};
