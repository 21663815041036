import { css } from "styled-components";
import { styledTheme } from "../../../theme/theme";

export const quaternaryButton = css<{ disabled?: boolean }>`
  background: ${styledTheme.newColors.extra.errorState};
  color: ${styledTheme.newColors.grayscale.white};
  border: 1px solid ${styledTheme.newColors.extra.errorState};

  svg path {
    fill: ${styledTheme.newColors.grayscale.white};
  }

  svg {
    fill: ${styledTheme.newColors.grayscale.white};
  }

  &:hover {
    background: ${styledTheme.newColors.extra.errorHovered};
    border: 1px solid ${styledTheme.newColors.extra.errorHovered};

    &:active {
      background: ${styledTheme.newColors.extra.errorActive};
      border: 1px solid ${styledTheme.newColors.extra.errorActive};
    }
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background: ${theme.newColors.extra.tertiaryButtonBG};
      cursor: default;
      color: ${theme.newColors.grayscale.disabled};
      border: 1px solid ${theme.newColors.grayscale.disabled};

      &:hover {
        background: ${theme.newColors.extra.tertiaryButtonBG};
        color: ${theme.newColors.grayscale.disabled};
        border: 1px solid ${theme.newColors.grayscale.disabled};

        &:active {
          background: ${theme.newColors.extra.tertiaryButtonBG};
          color: ${theme.newColors.grayscale.disabled};
          border: 1px solid ${theme.newColors.grayscale.disabled};
        }

        svg path {
          fill: ${theme.newColors.grayscale.disabled};
        }

        svg {
          fill: ${theme.newColors.grayscale.disabled};
        }
      }

      svg path {
        fill: ${theme.newColors.grayscale.disabled};
      }

      svg {
        fill: ${theme.newColors.grayscale.disabled};
      }
    `}
`;
