import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ReadQueryResult } from "utils/types/QueryResult";
import TextareaAutosize from "react-textarea-autosize";
import { SelectedWorkflowPath } from "../../../../utils/types/ActionContextTypes";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { DatePicker } from "../../../common/DatePicker/DatePicker";
import {
  Currency,
  ProjectUser,
  useProjectUsersQuery,
  UserProjectDocument,
  useUpdateWorkflowFromSampleMutation,
} from "../../../../generated/graphql";
import { UsersSelector } from "./UsersSelector/UsersSelector";
import {
  PriceInput,
  PriceInputValue,
} from "../../../common/PriceInput/PriceInput";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";

type EditTaskInformationsProps = {
  editMode?: boolean;
  hardcodedUser?: ProjectUser;
};

export const EditTaskInformations = (
  props: EditTaskInformationsProps
): JSX.Element => {
  const { editMode, hardcodedUser } = props;
  const { selectedEntity, setSelectedEntity } = useActionContext();
  if (!selectedEntity) return <></>;

  const { loading: usersLoading, data: usersData } = useProjectUsersQuery({
    variables: {
      projectId: (selectedEntity.selectedElementPath as SelectedWorkflowPath)
        .projectId,
    },
    fetchPolicy: "cache-first",
  });

  const [isDescriptionFieldFocused, setIsDescriptionFieldFocused] =
    useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);
  const [updateWorkflowFromSample] = useUpdateWorkflowFromSampleMutation();
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workflowStartDate, setWorkflowStartDate] = useState<Date>();
  const [workflowDueDate, setWorkflowDueDate] = useState<Date>();
  const [budget, setBudget] = useState<PriceInputValue>();
  const [workflowDescription, setWorkflowDescription] = useState<string>("");
  const [selectedDatePickerId, setSelectedDatePickerId] = useState(null);
  const [workflowAssignee, setWorkflowAssignee] = useState<
    string | null | undefined
  >(hardcodedUser?.id || null);

  const { useUserPermissions } = useUserRolesContext();
  const budgetIsVisible = useUserPermissions("seeBudget");

  useEffect(() => {
    const { workflow } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    let preferredCurrency: Currency | string = Currency.Eur;
    try {
      preferredCurrency =
        localStorage.getItem("preferredCurrency") || Currency.Eur;
    } catch (err) {
      // do nothing
    }

    setBudget({
      price: workflow.price?.price.value || "",
      currency: workflow.price?.currency || preferredCurrency,
    });
    setWorkflowDescription(workflow.description || "");
    setWorkflowName(workflow.name);

    const startDates: number[] = workflow.services.map(
      (service) => +service.startDate
    );

    const dueDates: number[] = workflow.services.map(
      (service) => +service.endDate
    );

    setWorkflowStartDate(new Date(Math.min(...startDates)));
    setWorkflowDueDate(new Date(Math.max(...dueDates)));
    if (!hardcodedUser) setWorkflowAssignee(workflow.assignee || "Unassigned");
  }, [selectedEntity]);

  const { projectId, sampleId, workflowId } =
    selectedEntity?.selectedElementPath as SelectedWorkflowPath;

  const updateWorkflow = async (target: string, value: string | object) => {
    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowUpdatables: {
          id: workflowId,
          [target]: value,
        },
      },
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["UpdateWorkflowFromSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const sample = samples.find((s) => s.id === sampleId);

          if (sample) {
            const workflowIndex = sample.enabledWorkflows.findIndex(
              (w) => w.id === workflowId
            );

            if (workflowIndex !== -1) {
              sample.enabledWorkflows[workflowIndex] = incomingData;

              const sampleIndex = samples.findIndex((s) => s.id === sampleId);
              samples[sampleIndex] = { ...sample };

              cache.writeQuery({
                variables: { id: projectId },
                query: UserProjectDocument,
                data: {
                  Project: {
                    ...existingQueryResult,
                    samples,
                  },
                },
              });
            }
          }
        }
      },
    };
    const newSelectedEntity = {
      ...selectedEntity,
      selectedElementPath: {
        ...(selectedEntity.selectedElementPath as SelectedWorkflowPath),
        workflow: {
          ...(selectedEntity.selectedElementPath as SelectedWorkflowPath)
            .workflow,
          [target]: value,
        },
      },
    };

    setSelectedEntity(newSelectedEntity);

    return updateWorkflowFromSample(options);
  };

  const handleNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setWorkflowName(text);
  };

  const handleAssigneeChange = async (value: string): Promise<unknown> => {
    setWorkflowAssignee(value);
    return updateWorkflow("assignee", value);
  };

  const handleStartDateChange = async (value: Date): Promise<unknown> => {
    setWorkflowStartDate(value);
    return updateWorkflow("startDate", value.getTime().toString());
  };

  const handleEndDateChange = async (value: Date): Promise<unknown> => {
    setWorkflowDueDate(value);
    return updateWorkflow("endDate", value.getTime().toString());
  };

  const handleNameOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateWorkflow("name", trimmedText);
    setIsNameFieldFocused(false);
  };

  const handleBudgetChange = (p: PriceInputValue): void => {
    setBudget(p);
  };

  const handleBudgetOnBlur = (p: PriceInputValue): void => {
    try {
      localStorage.setItem("preferredCurrency", p.currency);
    } catch (err) {
      // do nothing
    }

    updateWorkflow("price", {
      price: {
        value: p.price,
      },
      currency: p.currency,
    });
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    setWorkflowDescription(text);
  };

  const handleDescriptionOnBlur = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const text = e.target.value;
    const trimmedText = text.trim();
    updateWorkflow("description", trimmedText);
    setIsDescriptionFieldFocused(false);
  };

  const handleTextWithPencil = (
    flag: boolean,
    text: string,
    readonly?: boolean
  ): string => {
    if (readonly) return text;

    let preparedText = text;

    if (flag) {
      preparedText = text;
    } else if (text.length > 0) {
      preparedText += " ✎";
    } else {
      preparedText = text;
    }

    return preparedText;
  };

  const descriptionText = handleTextWithPencil(
    isDescriptionFieldFocused,
    workflowDescription,
    !editMode
  );
  const preparedWorkflowName = handleTextWithPencil(
    isNameFieldFocused,
    workflowName,
    !editMode
  );

  return (
    <Container>
      <EditableNameArea
        onChange={handleNameChange}
        onBlur={handleNameOnBlur}
        disabled={!editMode}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        value={preparedWorkflowName}
        onFocus={() => setIsNameFieldFocused(true)}
        placeholder={`Enter a name...${editMode ? " ✎" : ""}`}
      />
      <ServiceInformationAdditionalMargin editMode={editMode}>
        <ServiceInformationLabel>Assignee</ServiceInformationLabel>
        <UsersSelector
          users={usersData?.ProjectUsers as ProjectUser[]}
          loading={usersLoading}
          editMode={editMode}
          selectedValue={workflowAssignee}
          onChange={handleAssigneeChange}
          hardcodedUser={hardcodedUser}
        />
      </ServiceInformationAdditionalMargin>
      <ServiceInformation>
        <ServiceInformationLabel>Start Date</ServiceInformationLabel>
        <ServiceInformationValue editMode={editMode}>
          <DatePicker
            date={workflowStartDate!}
            setDate={handleStartDateChange}
            editMode={editMode}
            update={updateWorkflow}
            destinationUpdate="startDate"
            showIcon
            selectedDatePickerId={selectedDatePickerId}
            setSelectedDatePickerId={setSelectedDatePickerId}
            datePickerId={1}
          />
        </ServiceInformationValue>
      </ServiceInformation>
      <ServiceInformation>
        <ServiceInformationLabel>Due Date</ServiceInformationLabel>
        <ServiceInformationValue editMode={editMode}>
          <DatePicker
            date={workflowDueDate!}
            setDate={handleEndDateChange}
            editMode={editMode}
            update={updateWorkflow}
            destinationUpdate="endDate"
            showIcon
            selectedDatePickerId={selectedDatePickerId}
            setSelectedDatePickerId={setSelectedDatePickerId}
            datePickerId={2}
          />
        </ServiceInformationValue>
      </ServiceInformation>
      {budgetIsVisible ? (
        <ServiceInformation>
          <ServiceInformationLabel>Budget</ServiceInformationLabel>
          <ServiceInformationValue editMode={editMode}>
            <PriceInput
              editMode={editMode}
              value={budget}
              onChange={handleBudgetChange}
              onBlur={handleBudgetOnBlur}
            />
          </ServiceInformationValue>
        </ServiceInformation>
      ) : null}
      <ServiceInformationAdditionalMargin editMode={editMode}>
        <ServiceDescriptionInformationLabel>
          Description
        </ServiceDescriptionInformationLabel>
        <EditableDescriptionArea
          onChange={handleDescriptionChange}
          onBlur={handleDescriptionOnBlur}
          disabled={!editMode}
          value={descriptionText}
          onFocus={() => setIsDescriptionFieldFocused(true)}
          placeholder={`Enter a description...${editMode ? " ✎" : ""}`}
        />
      </ServiceInformationAdditionalMargin>
    </Container>
  );
};

const Container = styled.div`
  padding: 18px 24px;
  border-bottom: 1px solid
    ${(props) => props.theme.newColors.grayscale.bordersInside};
`;

const EditableArea = styled(TextareaAutosize)`
  resize: none;
  border: solid 1px transparent;
  border-radius: 4px;
  padding: 6px 3px 6px 3px;
  position: relative;
  width: 100%;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:active,
      &:focus-visible {
        border: solid 1px ${(props) => props.theme.newColors.primary.basic};
        outline: 0px solid transparent;
      }
    `};

  &:empty::before {
    content: attr(data-placeholder-description);
    color: #ccc;
    font-weight: 400;
  }

  &:empty:focus::before {
    content: "";
  }
`;

const EditableNameArea = styled(EditableArea)`
  min-height: 20px;
  line-height: 20px;
  font-size: 17px;
`;

const EditableDescriptionArea = styled(EditableArea)`
  min-height: 18px;
  line-height: 18px;
  font-size: 14px;
`;

const ServiceInformation = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 5px;
  column-gap: 23px;
  align-items: center;
`;

const ServiceInformationAdditionalMargin = styled(ServiceInformation)<{
  editMode: boolean | undefined;
}>`
  ${({ editMode }) =>
    editMode &&
    css`
      margin: 14px 0;
    `};
`;

const ServiceInformationLabel = styled.div`
  color: ${(props) => props.theme.newColors.primary.basic};
  padding: 3px 6px;
  height: 2rem;
  display: flex;
  align-items: center;
`;

const ServiceDescriptionInformationLabel = styled(ServiceInformationLabel)`
  padding: 4px 6px;
  height: fit-content;
`;

const ServiceInformationValue = styled.div<{
  editMode: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: absolute;
  margin-left: 100px;

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;

// const ServiceInformationDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 3px 0;
//   position: absolute;
//   margin-left: 100px;
//   height: 2rem;
//   width: calc(100% - 100px);
// `;
