import React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const TableIcon = (props: TableIconProps): JSX.Element => {
  const { color, width, height, isActive } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={isActive ? "white" : color}
        d="M19.105 21H4.895a1.78 1.78 0 01-1.339-.587A1.983 1.983 0 013 19V5c0-.55.185-1.021.556-1.413A1.78 1.78 0 014.895 3h14.21c.521 0 .968.196 1.339.587.37.392.556.863.556 1.413v14c0 .55-.185 1.021-.556 1.413a1.78 1.78 0 01-1.339.587zM4.895 8h14.21V5H4.895v3zm2.842 2H4.895v9h2.842v-9zm8.526 0v9h2.842v-9h-2.842zm-1.895 0H9.632v9h4.736v-9z"
      />
    </svg>
  );
};

type TableIconProps = BaseIconProps & {
  isActive?: boolean;
};

TableIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.secondaryGray,
};
