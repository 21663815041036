import React from "react";
import styled from "styled-components";

interface TextBoxProps {
  text: string;
  onClick: () => void;
  ownScale: number;
  readonly?: boolean;
}

export const TextBox = (props: TextBoxProps): JSX.Element => {
  const { text, onClick, ownScale = 1, readonly = false } = props;

  return (
    <foreignObject width={ownScale * 20} height={ownScale * 100}>
      <Text
        readonly={readonly}
        spellCheck={false}
        onClick={onClick}
        ownScale={ownScale}
      >
        {text}
      </Text>
    </foreignObject>
  );
};

const Text = styled.div<{
  readonly?: boolean;
  ownScale?: number;
}>`
  border: none;
  border-radius: ${({ ownScale = 1 }): number => ownScale * 4}px;
  font-size: ${({ ownScale = 1 }): number => ownScale * 13}px;
  width: ${({ ownScale = 1 }): number => ownScale * 20}px;
  height: ${({ ownScale = 1 }): number => ownScale * 100}px;
  writing-mode: vertical-rl;
  transform: scale(-1);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ readonly = false }): string =>
    readonly ? "default" : "pointer"};
  transition: 200ms;

  &:hover {
    font-weight: bold;
    transition: 200ms;
  }
`;
