import * as React from "react";
import { FieldProps } from "formik";
import { useState, useEffect } from "react";
import { FormGroup } from "./FormGroup";
import { RadioGroup } from "../../common/Radio/RadioGroup";
import { QuestionMetadata } from "../../../utils/types/QuestionMetadata";
import {
  getFieldStatus,
  GetFieldStatusResultT,
} from "../../common/LabelTag/LabelTag";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

export const RadioGroupField = ({ field, ...props }: SelectFieldProps) => {
  const {
    options,
    label,
    answerQuestionCallback,
    metadata,
    required,
    disabled,
    fieldStatus: fieldStatusCallback,
  } = props;

  const [fieldValue, setFieldValue] = useState(field.value);
  const [fieldStatus, setFieldStatus] = useState(
    getFieldStatus(required, field.value, fieldValue)
  );
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  useEffect(() => {
    setFieldValue(field.value);
  }, [field.value]);

  useEffect(() => {
    const currentStatus = getFieldStatus(required, field.value, fieldValue);
    setFieldStatus(currentStatus);
    fieldStatusCallback(currentStatus);
  }, [fieldValue, field.value]);

  const onClickRadioButton = (value: string) => {
    setFieldValue(value);
    answerQuestionCallback(metadata, value);
  };

  return (
    <FormGroup label={label} status={fieldStatus}>
      <RadioGroup
        {...field}
        {...props}
        options={options.map((option) => ({
          labelText: option.label,
          value: option.value,
          disabled,
        }))}
        selectedValue={
          (options
            ? options.find((option) => option.value === field.value)?.value
            : "") as any
        }
        onClickRadioButton={onClickRadioButton}
      />
    </FormGroup>
  );
};

type SelectFieldProps = FieldProps & {
  label: string;
  options: { value: string; label: string }[];
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled?: boolean;
};
