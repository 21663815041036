import React, { useState, useEffect, FunctionComponent } from "react";
import { FieldProps } from "formik";
import { FormGroup } from "./FormGroup";
import { QuestionMetadata } from "../../../utils/types/QuestionMetadata";
import { ExternalLinks } from "../../Projects/Configurator/common/ExternalLinks/ExternalLinks";
import {
  getFieldStatus,
  GetFieldStatusResultT,
} from "../../common/LabelTag/LabelTag";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled?: boolean;
}

type OwnProps = FieldProps & Props;

export const LinksField: FunctionComponent<OwnProps> = (props) => {
  const {
    field,
    label,
    description,
    answerQuestionCallback,
    metadata,
    required,
    fieldStatus: fieldStatusCallback,
    disabled,
  } = props;

  const [fieldValue, setFieldValue] = useState(field.value);
  const [fieldStatus, setFieldStatus] = useState(
    getFieldStatus(required, field.value, fieldValue)
  );
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  // useEffect(() => {
  //   setFieldValue(field.value);
  // }, [field.value]);

  useEffect(() => {
    const currentStatus = getFieldStatus(
      required,
      // field.value === '[""]' ? undefined : field.value,
      field.value,
      fieldValue
    );
    setFieldStatus(currentStatus);
    fieldStatusCallback(currentStatus);
  }, [fieldValue, field.value]);

  const onChange = (links: string) => {
    setFieldValue(links);
    answerQuestionCallback(metadata, links);
  };

  return (
    <FormGroup
      label={label}
      description={description}
      status={fieldStatus}
      editVisible={!disabled}
      disabled={disabled}
    >
      <ExternalLinks
        onUpdateCallback={onChange}
        linksValue={fieldValue}
        disabled={disabled}
      />
    </FormGroup>
  );
};
