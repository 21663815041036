import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import Select, { Props } from "react-select";
import { ITheme } from "../../theme/theme";

export const BaseSelect = styled(Select)<BaseSelectProps>`
  .Select__control {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    border: 1px solid #dedede;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 0 5px 16px;
    font-size: ${(props): string => props.theme.fontSizes[2]};
    line-height: ${(props): string => props.theme.lineHeights[4]};
    color: ${(props): string => props.theme.newColors.grayscale.primaryGray};
  }

  .Select__value-container {
    padding: 0;
  }

  .Select__dropdown-indicator svg {
    width: 16px;
    height: 16px;
  }

  .Select__dropdown-indicator svg path {
    d: path(
      "M7.16779 12.0849C7.56361 12.6787 8.43607 12.6787 8.83189 12.0849L12.2967 6.88771C12.7397 6.22315 12.2633 5.33301 11.4647 5.33301H4.53502C3.73633 5.33301 3.25993 6.22315 3.70297 6.88771L7.16779 12.0849Z"
    );
    fill: ${(props): string => props.theme.newColors.grayscale.primaryGray};
  }

  .Select__control--is-disabled .Select__dropdown-indicator svg path {
    fill: ${(props): string => props.theme.newColors.grayscale.placeholder};
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__control::-ms-expand {
    display: none;
    outline: none;
  }

  .Select__control--is-focused {
    outline: none;
    box-shadow: none;
  }

  .Select__control:hover {
    border: 1px solid
      ${(props): string => props.theme.newColors.grayscale.placeholder};
    box-shadow: ${({ theme }): string => theme.shadows.userInputs.hoveredState};
  }

  .Select__control--menu-is-open {
    border: 1px solid ${(props): string => props.theme.newColors.primary.basic};
    box-shadow: ${({ theme }): string => theme.shadows.userInputs.activeState};
    outline: none;
  }

  .Select__control--is-disabled {
    border-radius: 4px;
    border: 1px solid
      ${({ theme }): string => theme.newColors.grayscale.placeholder};
    background: ${({ theme }): string => theme.newColors.grayscale.disabled};
    box-shadow: none;
  }

  .Select__option {
    background: ${(props): string => props.theme.newColors.grayscale.white};
    font-size: ${(props): string => props.theme.fontSizes[2]};
    line-height: ${(props): string => props.theme.lineHeights[4]};
    font-weight: normal;
    cursor: pointer;
    padding: 12px 16px;
  }

  .Select__option--is-selected {
    background: ${(props): string => props.theme.newColors.primary.lightTint};
    color: ${(props): string => props.theme.newColors.grayscale.primaryGray};
  }

  .Select__menu {
    margin-top: 0;
    margin-bottom: 0;
  }

  .Select__menu-list {
    padding: 0;
    border: 1px solid
      ${(props): string => props.theme.newColors.grayscale.bordersInside};
    box-shadow: ${({ theme }): string => theme.shadows.userInputs.hoveredState};
    box-sizing: border-box;
    border-radius: 5px;
  }

  .Select__option:hover {
    background: ${(props): string =>
      props.theme.newColors.primary.lightestTint};
  }

  .Select__option:active {
    background: ${(props): string => props.theme.newColors.primary.lightTint};
  }

  .Select__control:focus {
    outline: none;
  }

  .Select__control::placeholder,
  .Select__control::-webkit-input-placeholder {
    color: ${(props): string => props.theme.newColors.grayscale.placeholder};
  }

  .Select__control:-ms-input-placeholder {
    color: ${(props): string => props.theme.newColors.grayscale.placeholder};
  }

  ${(props): false | FlattenInterpolation<ThemeProps<ITheme>> | undefined =>
    props.error &&
    css`
      .Select__control {
        border: 1px solid
          ${({ theme }): string => theme.newColors.extra.errorState};
        box-shadow: ${({ theme }): string =>
          theme.shadows.userInputs.errorState};
      }
    `}
`;

export interface BaseSelectProps extends Props {
  error?: boolean;
}
