import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject,
} from "apollo-cache-inmemory";
import { toast } from "react-toastify";

import introspectionQueryResultData from "../generated/introspection-result";
import TimeoutLink from "./TimeoutLink";

const QUERY_TIMEOUT = 15000;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// this returns undefined for the cache leaf id which makes the Apollo use the full leaf path as the id, the cache id is longer, but it fixes our problems with duplicated ids on questions
function dataIdFromObject(): undefined {
  return undefined;
}

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject,
});

const timeoutLink = new TimeoutLink(QUERY_TIMEOUT);
const httpLink = new HttpLink({
  uri: `${process.env.HOST}/graphql`,
  credentials: "include",
});
const linkChain = timeoutLink.concat(httpLink);

const authLink = (token: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (!message.includes("jwt")) {
        toast.error(message, { autoClose: 20000 });
      }
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    toast.error(
      "You've been logged out due to inactivity. Please refresh the page and / or try again in a few seconds.",
      { autoClose: 20000 }
    );
    console.log(`[Network error]: ${networkError}`);
  }
});

const linkChainWithErrorHandling = errorLink.concat(linkChain);

export function getApolloClient(
  token: string
): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: authLink(token).concat(linkChainWithErrorHandling),
    cache,
  });
}
