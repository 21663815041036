import * as React from "react";
import styled from "styled-components";
import { ServiceCardTag } from "../../../../common/Services/ServiceCardTag";
import { ServicePacksList } from "./ServicePacksList";
import { Workflow } from "./helpers/TypesForServicePackages";
import { NoServicePacks } from "./NoServicePacks";
import { ServicePackAction } from "../../../../../utils/types/WorkflowMetadata";

type Props = {
  workflows: {
    recommendedWorkflows: Workflow[];
    suggestedWorkflows: Workflow[];
    othersWorkflows: Workflow[];
  };
  selectedWorkflows?: Workflow[];
  projectId: string;
  sampleId: string;
  onAction: ServicePackAction;
  className?: string;
  removeDisabled: boolean;
  selectedWorkflowsIds?: string[];
};
export const ServicePacksContainer = (props: Props): JSX.Element => {
  const { recommendedWorkflows, suggestedWorkflows, othersWorkflows } =
    props.workflows;

  const { selectedWorkflows, removeDisabled, selectedWorkflowsIds } = props;
  const servicePacksProps = {
    projectId: props.projectId,
    sampleId: props.sampleId,
  };
  const isRecommendedEmpty = recommendedWorkflows.length === 0;
  const isSuggestedEmpty = suggestedWorkflows.length === 0;
  const isOthersEmpty = othersWorkflows.length === 0;
  if (
    (isRecommendedEmpty && isSuggestedEmpty && isOthersEmpty) ||
    removeDisabled
  ) {
    if (selectedWorkflows && selectedWorkflows.length > 0) {
      return (
        <Container className={props.className}>
          <ServicePacksList
            {...servicePacksProps}
            workflows={selectedWorkflows}
            title="Selected service packages"
            description="Below you can find list of selected service packages which were selected before."
            onAction={props.onAction}
            selectedWorkflowsIds={selectedWorkflowsIds}
            removeDisabled={removeDisabled}
          />
        </Container>
      );
    }

    return <NoServicePacks className={props.className} />;
  }

  return (
    <Container className={props.className}>
      {!isRecommendedEmpty && (
        <ServicePacksList
          {...servicePacksProps}
          workflows={recommendedWorkflows}
          tag={ServiceCardTag.Recommended}
          title="Recommended service packages"
          description="Below you can find list of service packages recommended for your sample, based on your  choices."
          selectedWorkflowsIds={selectedWorkflowsIds}
          onAction={props.onAction}
          removeDisabled={false}
        />
      )}
      {!isSuggestedEmpty && (
        <ServicePacksList
          {...servicePacksProps}
          workflows={suggestedWorkflows}
          tag={ServiceCardTag.Suggested}
          title="Suggested service packages"
          description="Below you can find the list of service packages that we offer in Leaderna Platform. Adding those packages will create a list of questions that will allow us to offer you a price and time estimates."
          selectedWorkflowsIds={selectedWorkflowsIds}
          onAction={props.onAction}
          removeDisabled={false}
        />
      )}
      {!isOthersEmpty && (
        <ServicePacksList
          {...servicePacksProps}
          workflows={othersWorkflows}
          title="Other service packages"
          description="Below you can find all of the other service packages offered by Leaderna."
          selectedWorkflowsIds={selectedWorkflowsIds}
          onAction={props.onAction}
          removeDisabled={false}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-left: 16px;
`;
