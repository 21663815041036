import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Label } from "../../../../common/Label/Label";
import { ServiceGroup } from "./ServiceGroup";
import { Checkbox } from "../../../../common/Checkbox/Checkbox";
import { BaseService } from "../../../../../generated/graphql";

type Props = {
  services: BaseService[];
  selectedServices: string[];
  onServiceChecked: (e: {
    value: string;
    checked: boolean;
    name?: string;
  }) => void;
  className?: string;
};
export const Services = (props: Props): JSX.Element => {
  const { services, onServiceChecked, selectedServices } = props;

  const servicesGroups: {
    serviceGroupName: string;
    services: BaseService[];
  }[] = [];
  const servicesGroupsNames: string[] = [];
  services.forEach((service) => {
    if (!servicesGroupsNames.includes(service.serviceType)) {
      servicesGroupsNames.push(service.serviceType);
      servicesGroups.push({
        serviceGroupName: service.serviceType,
        services: [],
      });
    }
  });

  services.forEach((service) => {
    const groupIndex = servicesGroups.findIndex(
      (group) => group.serviceGroupName === service.serviceType
    );
    servicesGroups[groupIndex].services.push(service);
  });

  const initialWrappedGroupsState = servicesGroups.map(
    (data, index) => index !== 0
  ); // first question group visible
  const [wrappedGroups, setWrappedGroups] = useState(initialWrappedGroupsState);

  const wrapGroup = (questionGroupIndex: number): void => {
    const initialGroupState = wrappedGroups[questionGroupIndex];
    const groupsWrappedState = servicesGroups.map(() => true);
    groupsWrappedState[questionGroupIndex] = !initialGroupState;
    setWrappedGroups(groupsWrappedState);
  };

  return (
    <div className={props.className}>
      <Label
        label="Pick services for you sample and design your sample workflow"
        help="Platform will recommend service packages based upon your choices."
      />
      <List>
        <Groups>
          {servicesGroups.map((servicesGroup, index) => (
            <ServiceGroup
              title={servicesGroup.serviceGroupName}
              count={servicesGroup.services ? servicesGroup.services.length : 0}
              key={servicesGroup.serviceGroupName}
              wrapped={wrappedGroups[index]}
              wrapGroupCallback={(): void => {
                wrapGroup(index);
              }}
            >
              {servicesGroup.services?.map(
                (service) =>
                  service &&
                  !service.hiddenOnTheList && (
                    <Checkbox
                      key={service.id}
                      labelText={service.name}
                      value={service.id}
                      tip={service.description}
                      checked={selectedServices.includes(service.name)}
                      onChange={onServiceChecked}
                    />
                  )
              )}
            </ServiceGroup>
          ))}
        </Groups>
        <VerticalSeparator />
      </List>
    </div>
  );
};

const VerticalSeparator = styled.hr`
  border: none;
  border-left: 1px solid #dedede;
  width: 1px;
  margin: 0 24px 15px 24px;
`;

const Groups = styled.div`
  flex-grow: 1;
`;

const List = styled.div`
  display: flex;
`;
