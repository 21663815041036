import { BaseWorkflow } from "../../../../../../generated/graphql";

export const getSelectedWorkflows = (
  workflows: BaseWorkflow[],
  selectedWorkflowsIds?: string[]
) =>
  workflows
    .filter((workflow) => selectedWorkflowsIds?.includes(workflow.id))
    .map((workflow) => {
      return {
        id: workflow.id,
        name: workflow.name,
        services: workflow.services.map((service) => {
          return {
            id: service.id,
            name: service.name,
            tip: service.description,
          };
        }),
      };
    });
