import React from "react";
import styled from "styled-components";
import { useMatch } from "@reach/router";
import { Button } from "../../../common/Button/Button";
import { ButtonType } from "../../../common/Button/ButtonType";
import { EditTaskInformations } from "./EditTaskInformations";
import { styledTheme } from "../../../../theme/theme";
import { EditTaskCollaboratorsWrapper } from "./EditTaskCollaboratorsWrapper";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";
import { ROUTES } from "../../../../constants/routeConstants";
import { ProjectUser } from "../../../../generated/graphql";
import { ROLES } from "../../../../constants/roles";

type EditTaskProps = {
  editMode?: boolean;
  hardcodedUser?: ProjectUser;
  userRoleInSample?: ROLES;
};

export const EditTask = (props: EditTaskProps): JSX.Element => {
  const { hardcodedUser, userRoleInSample } = props;
  const { useUserPermissions } = useUserRolesContext();

  let editMode = useUserPermissions(
    "editServiceInformations",
    userRoleInSample
  );

  if (useMatch(ROUTES.USER_TASKS) || useMatch(ROUTES.GROUP_TASKS)) {
    editMode = false;
  }

  return (
    <>
      {editMode && (
        <MarkCompleteContainer>
          <Button label="&#10004; MARK COMPLETE" type={ButtonType.Secondary} />
        </MarkCompleteContainer>
      )}
      <EditTaskInformations editMode={editMode} hardcodedUser={hardcodedUser} />
      <CollaboratorsContainer>
        <CollaboratorsHeader>Collaborators and Groups</CollaboratorsHeader>
        <EditTaskCollaboratorsWrapper editMode={editMode} />
      </CollaboratorsContainer>
    </>
  );
};

const CollaboratorsContainer = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  height: 100px;
`;

const CollaboratorsHeader = styled.div`
  font-family: Overpass;
  font-size: 14px;
  line-height: 16px;
  color: ${styledTheme.newColors.primary.basic};
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-self: flex-start;
`;

const MarkCompleteContainer = styled.div`
  padding: 18px 24px;
  width: 100%;
  border-bottom: 1px solid
    ${(props) => props.theme.newColors.grayscale.bordersInside};
`;
