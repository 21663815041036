import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { styledTheme } from "../../../../theme/theme";
import { CollaboratorOption } from "../../../Projects/SingleProject/Collaborators/types";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { getSelectedCollaboratorsAvatars } from "../../../Projects/SingleProject/Collaborators/getSelectedCollaboratorsAvatars";
import {
  Collaborators,
  useGroupsQuery,
  useProjectUsersQuery,
} from "../../../../generated/graphql";
import { Spinner } from "../../../common/Spinner/Spinner";

interface CollaboratorsEditorProps {
  projectId: string;
  collaborators?: Collaborators;
  readonly?: boolean;
  onChange: (collaborators: CollaboratorOption[]) => void;
  className?: string;
  menuPortalTarget?: HTMLElement | Element | null | undefined;
}

export const CollaboratorsEditor = (
  props: CollaboratorsEditorProps
): JSX.Element => {
  const {
    projectId,
    collaborators,
    readonly,
    onChange,
    className,
    menuPortalTarget,
  } = props;

  const [hideCollaborators, setHideCollaborators] = useState(true);
  const [selectedCollaborators, setCollaborators] = useState<
    CollaboratorOption[]
  >([]);
  const [allCollaboratorsOptions, setAllCollaboratorsOptions] = useState<
    CollaboratorOption[]
  >([]);

  const { data: usersData, loading: usersLoading } = useProjectUsersQuery({
    variables: {
      projectId,
    },
    fetchPolicy: "cache-first",
  });

  const { data: groupsData, loading: groupsLoading } = useGroupsQuery({
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    setCollaborators(
      [...(collaborators?.groupIds || []), ...(collaborators?.userIds || [])]
        .map((eId) =>
          allCollaboratorsOptions?.find((cg) => cg.value.id === eId)
        )
        .filter(Boolean) as CollaboratorOption[]
    );
  }, [collaborators, allCollaboratorsOptions]);

  useEffect(() => {
    const allGroupOptions = groupsData?.Groups.map((group) => ({
      label: `Group: ${group.name}`,
      value: { id: group.id, name: group.name, type: "group", details: group },
    })) as CollaboratorOption[];
    const allUserOptions = usersData?.ProjectUsers.map((user) => ({
      label: user?.name,
      value: { id: user?.id, name: user?.name, type: "user", details: user },
    })) as CollaboratorOption[];
    setAllCollaboratorsOptions([
      ...(allGroupOptions ?? []),
      ...(allUserOptions ?? []),
    ]);
  }, [usersData, groupsData]);

  if (groupsLoading || usersLoading) {
    return <Spinner />;
  }

  const unselectedOptions = allCollaboratorsOptions.filter((x) => {
    if (!selectedCollaborators) return true;
    return !selectedCollaborators.includes(x);
  });

  const onSelectBlur = (): void => {
    if (!hideCollaborators) {
      setHideCollaborators(true);
    }

    onChange(selectedCollaborators);
  };

  return (
    <Container
      spread={!hideCollaborators}
      onClick={(): void => {
        if (!readonly) setHideCollaborators(false);
      }}
      editMode={!readonly}
      className={className}
    >
      {hideCollaborators ? (
        <SelectionContainer>
          {getSelectedCollaboratorsAvatars(selectedCollaborators)}
          {!readonly && <PlusContainer>+</PlusContainer>}
        </SelectionContainer>
      ) : (
        <StyledDropdown
          autoFocus
          onBlur={onSelectBlur}
          options={unselectedOptions}
          isMulti
          onChange={setCollaborators}
          value={selectedCollaborators}
          menuPortalTarget={menuPortalTarget}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ spread: boolean; editMode: boolean }>`
  flex-direction: row;
  align-items: center;
  display: flex;
  cursor: pointer;

  ${({ spread }) =>
    spread &&
    css`
      justify-content: space-between;
    `}

  ${({ editMode }) =>
    !editMode &&
    css`
      cursor: auto;
    `}
`;

const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const PlusContainer = styled.div`
  color: ${styledTheme.newColors.primary.basic};
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  padding-top: 4px;
`;

const StyledDropdown = styled(Dropdown)`
  justify-self: flex-start;
  width: 100%;
`;
