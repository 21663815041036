import * as React from "react";
import { forwardRef, MutableRefObject, useEffect, useState } from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import { LeftBigArrowIcon } from "../../images/icons/LeftBigArrowIcon";
import { styledTheme } from "../../theme/theme";
import { RightBigArrowIcon } from "../../images/icons/RightBigArrowIcon";
import { startScroll, stopScroll } from "../../utils/helpers/sideScroll";
import { StatusType } from "./getStatusIcon";

type Props = {
  children: React.ReactNode;
  tabs: { id: string; label: string; status?: StatusType }[];
};

export const ScrollableContainer = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { children, tabs } = props;
    const scrollableRef = ref as MutableRefObject<HTMLDivElement>;

    const isScrollVisible = () => {
      return (
        scrollableRef &&
        scrollableRef.current &&
        scrollableRef.current.scrollWidth - scrollableRef.current.clientWidth >
          1
      );
    };

    const [scrollVisible, setScrollVisible] = useState(isScrollVisible());

    useEffect(() => {
      const visible = isScrollVisible();
      setScrollVisible(visible);
    }, [tabs]);

    useEffect(() => {
      const debounceHandleResize = debounce(() => {
        setScrollVisible(isScrollVisible());
      }, 700);

      window.addEventListener("resize", debounceHandleResize);

      return () => window.removeEventListener("resize", debounceHandleResize);
    });

    const onMouseDown = (direction: string) => {
      if (scrollableRef && scrollableRef.current) {
        startScroll(scrollableRef.current, direction, 50, 25);
      }
    };

    const onMouseUp = () => {
      stopScroll();
    };

    return (
      <Scrollable ref={scrollableRef}>
        {scrollVisible && (
          <ScrollLeftBtn
            onMouseDown={() => onMouseDown("left")}
            onMouseUp={onMouseUp}
          >
            <LeftBigArrowIcon
              color={styledTheme.newColors.grayscale.primaryGray}
            />
          </ScrollLeftBtn>
        )}
        {children}
        {scrollVisible && (
          <ScrollRightBtn
            onMouseDown={() => onMouseDown("right")}
            onMouseUp={onMouseUp}
          >
            <RightBigArrowIcon
              color={styledTheme.newColors.grayscale.primaryGray}
            />
          </ScrollRightBtn>
        )}
      </Scrollable>
    );
  }
);

const ScrollButton = styled.div`
  position: sticky;
  justify-content: center;
  align-items: center;
  height: 36px;
  display: flex;
  z-index: 10;
  cursor: pointer;
  min-width: 20px;
  border-radius: 4px 4px 0 0;
  background-color: ${({ theme }) => theme.newColors.grayscale.background};

  :hover {
    opacity: 100%;
    background-color: ${({ theme }) => theme.newColors.grayscale.placeholder};
  }
`;

const ScrollLeftBtn = styled(ScrollButton)`
  left: 0;
`;

const ScrollRightBtn = styled(ScrollButton)`
  right: -1px;
`;

const Scrollable = styled.div`
  position: relative;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
`;
