import React from "react";
import styled from "styled-components";
import { RightBigArrowIcon } from "../../../images/icons/RightBigArrowIcon";
import { SampleBar } from "../common/SampleBar";
import { styledTheme } from "../../../theme/theme";

const icon = (
  <RightBigArrowIcon color={styledTheme.newColors.primary.basic} width="9" />
);

export const SinglePageSampleBar = (props: SinglePageSampleBarProps) => {
  const { id, name, percent, active, onClick } = props;

  return (
    <Container active={active} onClick={() => onClick(id)}>
      <SampleBar name={name} percent={percent} icon={icon} />
    </Container>
  );
};

export type SinglePageSampleBarProps = {
  id: string;
  name: string;
  percent: number;
  status: string;
  active?: boolean | undefined;
  onClick?: any;
};

SinglePageSampleBar.defaultProps = {
  active: false,
};

const Container = styled.div<{ active: boolean | undefined }>`
  ${(props) => {
    if (props.active) {
      return `
  background: ${styledTheme.newColors.primary.lightestTint};
  border: 2px solid ${styledTheme.newColors.primary.basic};
  > div > div:first-child {
    font-weight: 800;
  }
          `;
    }
    return `
  background: ${styledTheme.newColors.grayscale.white};
  border: 2px solid ${styledTheme.newColors.grayscale.bordersInside};
      `;
  }}
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
`;
