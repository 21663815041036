import React from "react";
import { Box } from "rebass";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { styledTheme } from "../../../theme/theme";
import { CheckMarkIcon } from "../../../images/icons/CheckMarkIcon";
import { useActionContext } from "../../../utils/hooks/useActionContext";

type ColorType = {
  id: number;
  color: string;
};

export const definedColors: ColorType[] = [
  { id: 0, color: "#232f3c" },
  { id: 1, color: "#3b79d1" },
  { id: 2, color: "#186a58" },
  { id: 3, color: "#853b49" },
  { id: 4, color: "#997c15" },
  { id: 5, color: "#52b79b" },
  { id: 6, color: "#e8bd25" },
  { id: 7, color: "#c1475f" },
];

type ColorProps = {
  id: number;
  color: string;
  setSelected: (index: number) => void;
  selected?: boolean;
  disabled: boolean;
};

const Color = (props: ColorProps): JSX.Element => {
  const { id, color, setSelected, selected, disabled } = props;

  return (
    <ColorRing
      color={color}
      onClick={(): void => {
        if (!disabled) {
          setSelected(id);
        }
      }}
      selected={selected}
      disabled={disabled}
    >
      {selected && <CheckMarkIcon height={16} width={16} />}
    </ColorRing>
  );
};

export const ColorChooser = (props: {
  numberOfExistingWorkflows: number;
}): JSX.Element => {
  const { selectedWorkflowIndex, setSelectedWorkflowIndex } =
    useActionContext();

  return (
    <>
      <Header>Select Task / Phase</Header>
      <ColorsContainer>
        {definedColors.map((mappedColor, colorIndex) => (
          <Color
            key={mappedColor.id}
            id={mappedColor.id}
            color={mappedColor.color}
            setSelected={setSelectedWorkflowIndex}
            selected={mappedColor.id === selectedWorkflowIndex}
            disabled={colorIndex + 1 > props.numberOfExistingWorkflows}
          />
        ))}
      </ColorsContainer>
    </>
  );
};

const Header = styled.div`
  margin-top: 21px;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: ${styledTheme.newColors.primary.basic};
`;

const ColorsContainer = styled(Box)`
  margin: 1rem 0 !important;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ColorRing = styled(Box)<{
  color: string;
  selected?: boolean;
  disabled: boolean;
}>`
  background-color: ${({ color }): string => color};
  height: 30px;
  width: calc(100% / 4 - 6px);
  box-shadow: 0 4px 8px 0 rgba(141, 211, 199, 0.3);
  border: solid 1px #dedede;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${({ disabled }): string => (disabled ? "default" : "pointer")};

  ${({ selected }): false | undefined | FlattenSimpleInterpolation =>
    selected &&
    css`
      border: 3px solid #3267cf;
    `}

  & > svg {
    margin-top: 4px;
  }
`;
