import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const MoveIcon = (props: MoveIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M16.5 17l-1.41-1.41L17.67 13H8v-2h9.67l-2.58-2.59L16.5 7l5 5-5 5z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M5 3h11c1.1 0 2 .9 2 2H5v14h13c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2z"
      />
    </svg>
  );
};

MoveIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.primary.basic,
};

type MoveIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
