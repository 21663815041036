import * as React from "react";
import { useActionContext } from "../../../utils/hooks/useActionContext";
import { SelectedElementType } from "../../../utils/types/ActionContextTypes";
import { QuestionEdit } from "./Content/QuestionEdit/QuestionEdit";
import { QuestionGroupEdit } from "./Content/QuestionGroupEdit/QuestionGroupEdit";
import { OneOfManyEdit } from "./Content/OneOfManyEdit/OneOfManyEdit";

export const SideDrawboardContent = (): JSX.Element => {
  const { selectedEntity } = useActionContext();

  switch (selectedEntity?.selectedElementType) {
    case SelectedElementType.QUESTION:
      return <QuestionEdit />;
    case SelectedElementType.ONE_OF_MANY:
      return <OneOfManyEdit />;
    case SelectedElementType.QUESTION_GROUP:
      return <QuestionGroupEdit />;
    default:
      return <></>;
  }
};
