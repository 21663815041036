import * as React from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { navigate } from "@reach/router";
import { PlatformLogo } from "../../images/PlatformLogo";
import { SideBarActions } from "./SideBarActions";
import { RightBlueExpandIcon } from "../../images/icons/RightBlueExpandIcon";
import { LeftBlueExpandIcon } from "../../images/icons/LeftBlueExpandIcon";
import { PlatformSign } from "../../images/PlatformSign";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { styledTheme } from "../../theme/theme";

export const SideBar = () => {
  const { sideBarOpen, setSideBarOpen } = useSideBarsContext();

  const navigateToProjects = () => navigate(`/projects/`);

  return (
    <Container open={sideBarOpen}>
      <LogoContainer>
        {sideBarOpen ? (
          <PlatformLogo onClick={navigateToProjects} />
        ) : (
          <PlatformSign onClick={navigateToProjects} />
        )}
      </LogoContainer>
      <MenuContainer>
        <SideBarActions isSideBarOpen={sideBarOpen} />
      </MenuContainer>
      <IconContainer
        onClick={() => setSideBarOpen(!sideBarOpen)}
        open={sideBarOpen}
      >
        {sideBarOpen ? <LeftBlueExpandIcon /> : <RightBlueExpandIcon />}
      </IconContainer>
    </Container>
  );
};

const Container = styled(Flex)<{ open: boolean }>`
  width: ${({ open }) => (open ? "184px" : "72px")};
  min-width: ${({ open }) => (open ? "184px" : "72px")};
  background-color: ${(p) => p.theme.newColors.grayscale.primaryGray};
  flex-direction: column;
`;

const LogoContainer = styled(Box)`
  height: 120px;
  width: 100%;
  padding: 24px;
  text-align: center;

  svg {
    cursor: pointer;
  }
`;

const MenuContainer = styled(Box)`
  padding: 14px 0;
  width: 100%;
  color: ${({ theme }) => theme.newColors.grayscale.white};
`;

const IconContainer = styled.div<{ open: boolean }>`
  height: 24px;
  width: 24px;
  cursor: pointer;
  top: 7.5rem;
  left: ${({ open }) => (open ? "184px" : "72px")};
  position: absolute;
  margin-left: -0.8rem;
  border-radius: 50%;
  box-shadow: ${styledTheme.shadows.icons.expandIcon};
  z-index: 1;

  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover circle {
    fill: ${({ theme }) => theme.newColors.primary.hover};
    transition: fill linear 0.2s;
  }

  & > svg:active circle {
    fill: ${styledTheme.newColors.primary.darkestTint};
    transition: fill linear 0.2s;
  }
`;
