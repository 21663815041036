import React from "react";
import styled from "styled-components";
import { ChainIcon } from "../../../../images/icons/ChainIcon";
import { UserAvatar } from "../../../common/UserAvatar/UserAvatar";

export type SimplifiedTasks = {
  id: string;
  color: string;
  name: string;
  subtasks: {
    id: string;
    name: string;
    assignee?: string;
    collaborators?: { id: string; name: string; picture: string }[];
  }[];
};

interface TasksTableProps {
  tasks: SimplifiedTasks[];
}

export const TasksTable = (props: TasksTableProps): JSX.Element => {
  const { tasks } = props;

  const getGroupInitials = (groupName: string): string =>
    groupName
      .split(" ")
      .map((text) => text[0])
      .join("")
      .slice(0, 2)
      .toUpperCase();

  return (
    <Table>
      <tbody>
        {tasks.map((task) =>
          task.subtasks.map((subtask) => (
            <Row key={subtask.id}>
              <IconContainer>
                <ChainIcon color={task.color} />
              </IconContainer>
              <td>{task.name}</td>
              <TaskNowContainer>{subtask.name}</TaskNowContainer>
              <UsersContainer>
                <div>
                  {subtask.assignee && (
                    <StyledAvatar user={{ picture: subtask.assignee }} />
                  )}
                  {subtask.collaborators &&
                    subtask.collaborators.map((collaborator, index) => (
                      <GroupAvatar
                        style={{
                          zIndex: 99 - index,
                        }}
                      >
                        {getGroupInitials(collaborator.name)}
                      </GroupAvatar>
                    ))}
                </div>
              </UsersContainer>
            </Row>
          ))
        )}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Row = styled.tr`
  font-size: 12px;
  height: 55px;
`;

const TaskNowContainer = styled.td`
  div {
    display: flex;
    flex-direction: column;

    span {
      font-size: 10px;
      color: #9d9ea3;
    }
  }
`;

const UsersContainer = styled.td`
  white-space: nowrap;
  width: 1%;

  & > div {
    display: flex;
    margin-right: 10px;
  }
`;

const StyledAvatar = styled(UserAvatar)`
  margin-right: -10px;
  border: 1px solid white;
  z-index: 100;
`;

const IconContainer = styled.td`
  width: 1px;
  white-space: nowrap;
  padding: 0 12px 0 8px;

  svg {
    margin-top: 4px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
  }
`;

const GroupAvatar = styled.div`
  width: ${({ theme }) => theme.space[4]};
  min-width: ${({ theme }) => theme.space[4]};
  height: ${({ theme }) => theme.space[4]};
  border-radius: 50%;
  background-color: ${(props) => props.theme.newColors.grayscale.secondaryGray};
  color: white;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: -10px;
  border: 1px solid white;
`;
