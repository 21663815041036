import * as React from "react";
import { FieldProps } from "formik";
import { Dropdown } from "../../../common/Dropdown/Dropdown";
import { FormGroup } from "../FormGroup";
import {
  Option,
  QuestionMetadata,
} from "../../../../utils/types/QuestionMetadata";
import { GetFieldStatusResultT } from "../../../common/LabelTag/LabelTag";

export const SelectField = ({ field, ...props }: SelectFieldProps) => {
  const { options, label, isSearchable } = props;

  return (
    <FormGroup label={label}>
      <Dropdown
        {...field}
        {...props}
        options={options}
        value={
          (options
            ? options.find((option) => option.value === field.value)
            : "") as any
        }
        isDisabled
        isSearchable={isSearchable}
      />
    </FormGroup>
  );
};

type SelectFieldProps = FieldProps & {
  label: string;
  options: Option[];
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  required: boolean;
  fieldStatus: (status: GetFieldStatusResultT) => void;
  disabled?: boolean;
  isSearchable?: boolean;
};
