import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const CalendarWithBorderIcon = (props: CalendarWithBorderIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" stroke={color} />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M10 14h12m-.857-2.4H10.857c-.473 0-.857.359-.857.8v8.8c0 .442.384.8.857.8h10.286c.473 0 .857-.358.857-.8v-8.8c0-.441-.384-.8-.857-.8z"
      />
      <path
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M12.571 10v1.6m6.858-1.6v1.6M16 10v1.6"
      />
      <rect width="100%" height="100%" fill="transparent" id="calendar-icon" />
    </svg>
  );
};

CalendarWithBorderIcon.defaultProps = {
  width: "32",
  height: "32",
  color: styledTheme.newColors.grayscale.bordersInside,
};

type CalendarWithBorderIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
