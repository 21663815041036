import get from "lodash/get";
import { Sample } from "../../generated/graphql";

export const getSampleName = (sample: Sample) => {
  return get(
    sample,
    "selectedQuestionGroups[0].questionSets[0].questions[0].answer.textBody", // first question (Project codename) answer
    sample.name
  );
};
