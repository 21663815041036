import React, { useState } from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { toast } from "react-toastify";
import { InMemoryCache } from "apollo-cache-inmemory";
import { cloneDeep } from "lodash";
import { ApolloError } from "apollo-client";
import { FetchResult } from "apollo-link";
import { useMatch, navigate } from "@reach/router";
import { DeleteIcon } from "../../../../../images/icons/DeleteIcon";
import { CopyIcon } from "../../../../../images/icons/CopyIcon";
import { DuplicateIcon } from "../../../../../images/icons/DuplicateIcon";
import { MoveIcon } from "../../../../../images/icons/MoveIcon";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  useDuplicateWorkflowInSampleMutation,
  useRemoveEventFromSampleMutation,
  useRemoveServiceFromWorkflowMutation,
  useRemoveWorkflowFromSampleMutation,
  useRemoveQuestionGroupFromSampleMutation,
  UserProjectDocument,
  useRemoveSampleFromProjectMutation,
  useMakeTimelineElementVisibleMutation,
  TimelineElementVisibleInput,
} from "../../../../../generated/graphql";
import {
  SelectedElementType,
  SelectedEventPath,
  SelectedReportQuestionPath,
  SelectedServicePath,
  SelectedWorkflowPath,
} from "../../../../../utils/types/ActionContextTypes";
import { ReadQueryResult } from "../../../../../utils/types/QueryResult";
import { useSideBarsContext } from "../../../../../utils/hooks/useSideBarsState";
import { projectSampleCacheUpdater } from "../../../../../utils/helpers/dynamicForms/projectSampleCacheUpdater";
import { ConfirmationModal } from "../../../../common/Modal/ConfirmationModal";
import { useUserRolesContext } from "../../../../../utils/hooks/useUserRolesContext";
import { ROUTES } from "../../../../../constants/routeConstants";
import { MoveSubtaskToOtherTaskModal } from "./MoveSubtaskToOtherTaskModal";
import { VisibilityIcon } from "../../../../../images/icons/VisibilityIcon";
import { CopyTaskToOtherPathModal } from "./CopyTaskToOtherPathModal";
import {
  isSelectedServicePath,
  isSelectedEventPath,
} from "../../../../../utils/types/ActionContextGuards";
import { MoveAllServicesToNewStartDateModal } from "./MoveAllServicesToNewStartDateModal";
import { SaveIcon } from "../../../../../images/icons/SaveIcon";
import { SaveAsTemplateModal } from "./SaveAsTemplateModal";
import { ROLES } from "../../../../../constants/roles";

interface DotsDropdownMenuContentProps {
  closeMenu: () => void;
  mainQuestionGroupId?: string;
  userRoleInSample?: ROLES;
}

export const DotsDropdownMenuContent = (
  props: DotsDropdownMenuContentProps
): JSX.Element => {
  const { closeMenu, mainQuestionGroupId, userRoleInSample } = props;

  const [
    isConfirmationModalForRemoveOpen,
    setIsConfirmationModalForRemoveOpen,
  ] = useState(false);
  const [
    isConfirmationModalForRemoveSampleOpen,
    setIsConfirmationModalForRemoveSampleOpen,
  ] = useState(false);
  const [
    isConfirmationModalForDuplicateOpen,
    setIsConfirmationModalForDuplicateOpen,
  ] = useState(false);
  const [isMoveSubtaskToTaskModalOpen, setIsMoveSubtaskToTaskModalOpen] =
    useState(false);
  const [
    isConfirmationModalForMakeElementVisible,
    setIsConfirmationModalForMakeElementVisible,
  ] = useState(false);
  const [isCopyTaskToOtherPathModalOpen, setIsCopyTaskToOtherPathModalOpen] =
    useState(false);
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] =
    useState(false);
  const [
    isMoveAllServicesToNewStartDateModalVisible,
    setIsMoveAllServicesToNewStartDateModalVisible,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { selectedEntity, setSelectedEntity } = useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();
  const { useUserPermissions } = useUserRolesContext();

  const [removeServiceFromWorkflow] = useRemoveServiceFromWorkflowMutation();
  const [removeWorkflowFromSample] = useRemoveWorkflowFromSampleMutation();
  const [removeEventFromSample] = useRemoveEventFromSampleMutation();
  const [duplicateWorkflowInSampleMutation] =
    useDuplicateWorkflowInSampleMutation();
  const [removeQuestionGroupFromSampleMutation] =
    useRemoveQuestionGroupFromSampleMutation();
  const [makeTimelineElementVisibleMutation] =
    useMakeTimelineElementVisibleMutation();

  let deleteIsEnabled = useUserPermissions("deleteTasks", userRoleInSample);
  let moveSubtaskIsEnabled = useUserPermissions(
    "moveSubtask",
    userRoleInSample
  );
  let duplicateIsEnabled = useUserPermissions(
    "duplicateTask",
    userRoleInSample
  );
  let copyTaskToOtherPathIsEnabled = useUserPermissions(
    "copyTaskToOtherPath",
    userRoleInSample
  );
  let saveAsTemplateIsEnabled = useUserPermissions(
    "saveAsTemplate",
    userRoleInSample
  );
  let makeElementVisible = useUserPermissions(
    "makeElementVisible",
    userRoleInSample
  );
  let moveAllServicesToNewStartDateIsEnabled = useUserPermissions(
    "moveAllServicesToNewStartDate",
    userRoleInSample
  );

  const [removeSampleFromProjectMutation] =
    useRemoveSampleFromProjectMutation();

  const removeSampleFromProject = async (): Promise<void> => {
    if (!selectedEntity || !deleteIsEnabled) return;

    setIsLoading(true);

    const { projectId, sampleId } =
      selectedEntity.selectedElementPath as SelectedReportQuestionPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
      },
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["RemoveSampleFromProject"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];
        if (existingQueryResult && incomingData) {
          navigate(
            `/projects/${projectId}/configurator/samples/${incomingData[0].id}/configurator`
          );

          cache.writeQuery({
            variables: { id: projectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: incomingData,
              },
            },
          });

          setIsLoading(false);
        }
      },
    };
    await removeSampleFromProjectMutation(options);
  };

  const removeQuestionGroupFromSample = (): void => {
    if (!selectedEntity || !deleteIsEnabled) return;

    const {
      projectId,
      sampleId,
      serviceId,
      workflowId,
      questionGroupId,
      fromReport,
    } = selectedEntity.selectedElementPath as SelectedReportQuestionPath;

    setSelectedEntity(undefined);
    setSideDrawboardOpen(false);

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
          fromReport,
          workflowId,
          serviceId,
        },
        questionGroupId,
      },
      update(cache: InMemoryCache, mutationResult: FetchResult): void {
        projectSampleCacheUpdater(
          "RemoveQuestionGroupFromSample",
          cache,
          mutationResult,
          projectId,
          sampleId
        );
      },
    };

    removeQuestionGroupFromSampleMutation(options);
  };

  if (
    useMatch(ROUTES.USER_TASKS) ||
    useMatch(ROUTES.GROUP_TASKS) ||
    (selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.originPathId !==
        selectedEntity.selectedElementPath.sampleId) ||
    (selectedEntity &&
      isSelectedEventPath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.originPathId !==
        selectedEntity.selectedElementPath.sampleId)
  ) {
    deleteIsEnabled = false;
    duplicateIsEnabled = false;
    copyTaskToOtherPathIsEnabled = false;
    saveAsTemplateIsEnabled = false;
    moveSubtaskIsEnabled = false;
    makeElementVisible = false;
    moveAllServicesToNewStartDateIsEnabled = false;
  }

  const deleteServiceFromWorkflow = (): void => {
    if (!selectedEntity || !deleteIsEnabled) return;

    setIsLoading(true);

    const { projectId, sampleId, service, workflowId } =
      selectedEntity.selectedElementPath as SelectedServicePath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceId: service.id,
      },
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["RemoveServiceFromWorkflow"];
        const existingQueryResult = cloneDeep(
          cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: projectId },
          })?.["Project"]
        );

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const foundSample = samples.find((s) => s.id === sampleId);
          if (foundSample) {
            const workflowIndex = foundSample.enabledWorkflows.findIndex(
              (ew) => ew.id === workflowId
            );

            if (workflowIndex !== -1) {
              foundSample.enabledWorkflows[workflowIndex] = incomingData;

              const sampleIndex = samples.findIndex((s) => s.id === sampleId);
              samples[sampleIndex] = { ...foundSample };
              existingQueryResult.samples = [...samples];
              cache.writeQuery({
                variables: { id: projectId },
                query: UserProjectDocument,
                data: {
                  Project: {
                    ...existingQueryResult,
                  },
                },
              });
            }
          }

          setSelectedEntity(undefined);
          setSideDrawboardOpen(false);
          setIsLoading(false);
          setIsConfirmationModalForRemoveOpen(false);
        }
      },
    };

    removeServiceFromWorkflow(options).catch((e: ApolloError) => {
      setIsLoading(false);
      setIsConfirmationModalForRemoveOpen(false);
    });
  };

  const deleteWorkflowFromSample = (): void => {
    if (!selectedEntity || !deleteIsEnabled) return;

    setIsLoading(true);

    const { projectId, sampleId, workflowId } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId,
          workflowDesignation: "enabledWorkflows",
        },
      },
      update(cache: InMemoryCache, mutationResult: FetchResult): void {
        const sampleUpdated = projectSampleCacheUpdater(
          "RemoveWorkflowFromSample",
          cache,
          mutationResult,
          projectId,
          sampleId
        );
        if (sampleUpdated) {
          setSelectedEntity(undefined);
          setSideDrawboardOpen(false);
          setIsLoading(false);
          setIsConfirmationModalForRemoveOpen(false);
        }
      },
    };

    removeWorkflowFromSample(options).catch((e: ApolloError) => {
      setIsLoading(false);
    });
  };

  const deleteEventFromWorkflow = (): void => {
    if (!selectedEntity || !deleteIsEnabled) return;

    setIsLoading(true);

    const { projectId, sampleId, event } =
      selectedEntity.selectedElementPath as SelectedEventPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        eventId: event.id,
      },
      update(cache: InMemoryCache, mutationResult: FetchResult): void {
        const sampleUpdated = projectSampleCacheUpdater(
          "RemoveEventFromSample",
          cache,
          mutationResult,
          projectId,
          sampleId
        );
        if (sampleUpdated) {
          setSelectedEntity(undefined);
          setSideDrawboardOpen(false);
          setIsLoading(false);
          setIsConfirmationModalForRemoveOpen(false);
        }
      },
    };

    removeEventFromSample(options).catch((e: ApolloError) => {
      setIsLoading(false);
      setIsConfirmationModalForRemoveOpen(false);
    });
  };

  const onDeleteClick = (): void => {
    if (!selectedEntity || !deleteIsEnabled) return;

    if (selectedEntity.selectedElementType === SelectedElementType.WORKFLOW) {
      deleteWorkflowFromSample();
      return;
    }

    if (selectedEntity.selectedElementType === SelectedElementType.SERVICE) {
      deleteServiceFromWorkflow();
      return;
    }

    if (selectedEntity.selectedElementType === SelectedElementType.EVENT) {
      deleteEventFromWorkflow();
    }
  };

  const handleCopyUrl = (): void => {
    const pageUrl = window.location.href;
    try {
      navigator.clipboard.writeText(pageUrl).then(
        () => {
          toast.info("Copied link to the clipboard");
        },
        () => {
          toast.error(
            "Couldn't copy the link to the clipboard, check your browser settings"
          );
        }
      );
    } catch (error) {
      toast.error(
        "Couldn't copy the link to the clipboard, check your browser settings"
      );
    }
    closeMenu();
  };

  const handleDuplicatePath = (): void => {
    if (!selectedEntity || !duplicateIsEnabled) return;

    setIsLoading(true);

    const { projectId, sampleId, workflowId } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    duplicateWorkflowInSampleMutation({
      variables: {
        projectSampleWorkflowInput: {
          projectId,
          sampleId,
          workflowId,
        },
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
      ],
    })
      .then(() => {
        setIsLoading(false);
        setIsConfirmationModalForDuplicateOpen(false);
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
        setIsConfirmationModalForDuplicateOpen(false);
      });
  };

  const handleMakeElementVisible = (): void => {
    if (!selectedEntity || !makeElementVisible) return;

    let timelineElementVisibleInput: TimelineElementVisibleInput | undefined;

    if (isSelectedServicePath(selectedEntity.selectedElementPath)) {
      const {
        projectId,
        sampleId,
        workflowId,
        service: { id: serviceId },
        visibleOnAllPaths,
      } = selectedEntity.selectedElementPath;

      timelineElementVisibleInput = {
        projectId,
        sampleId,
        workflowId,
        serviceId,
        removeVisibility: visibleOnAllPaths,
      };
    }

    if (isSelectedEventPath(selectedEntity.selectedElementPath)) {
      const {
        projectId,
        sampleId,
        event: { id: eventId },
        visibleOnAllPaths,
      } = selectedEntity.selectedElementPath;

      timelineElementVisibleInput = {
        projectId,
        sampleId,
        eventId,
        removeVisibility: visibleOnAllPaths,
      };
    }

    if (!timelineElementVisibleInput) return;

    setIsLoading(true);

    makeTimelineElementVisibleMutation({
      variables: {
        timelineElementVisibleInput,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: timelineElementVisibleInput.projectId },
        },
      ],
    })
      .then(() => {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
        setIsConfirmationModalForMakeElementVisible(false);
        setIsLoading(false);
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
        setIsConfirmationModalForMakeElementVisible(false);
      });
  };

  const conditionallyShowSectionDelete = () => {
    if (!selectedEntity || !deleteIsEnabled || !mainQuestionGroupId)
      return false;

    const { questionGroupId } =
      selectedEntity.selectedElementPath as SelectedReportQuestionPath;

    if (
      selectedEntity.selectedElementType !==
        SelectedElementType.QUESTION_GROUP ||
      questionGroupId === mainQuestionGroupId
    )
      return false;

    return true;
  };

  const visibleText =
    selectedEntity &&
    (selectedEntity.selectedElementPath as SelectedServicePath)
      .visibleOnAllPaths
      ? "unvisible"
      : "visible";

  return (
    <>
      <List>
        <li role="presentation" onClick={handleCopyUrl} aria-hidden="true">
          <IconContainer>
            <CopyIcon />
          </IconContainer>
          <p>Copy URL</p>
        </li>
        {moveSubtaskIsEnabled &&
          selectedEntity?.selectedElementType ===
            SelectedElementType.SERVICE && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsMoveSubtaskToTaskModalOpen(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <MoveIcon />
              </IconContainer>
              <p>Move to other Task</p>
            </li>
          )}
        {makeElementVisible &&
          (selectedEntity?.selectedElementType ===
            SelectedElementType.SERVICE ||
            selectedEntity?.selectedElementType ===
              SelectedElementType.EVENT) && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsConfirmationModalForMakeElementVisible(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <VisibilityIcon />
              </IconContainer>
              <p>{`Make it ${visibleText} on all Paths`}</p>
            </li>
          )}

        {moveAllServicesToNewStartDateIsEnabled &&
          (selectedEntity?.selectedElementType ===
            SelectedElementType.SERVICE ||
            selectedEntity?.selectedElementType ===
              SelectedElementType.WORKFLOW ||
            selectedEntity?.selectedElementType ===
              SelectedElementType.EVENT) && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsMoveAllServicesToNewStartDateModalVisible(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <VisibilityIcon />
              </IconContainer>
              <p>Move all services to a new start date</p>
            </li>
          )}

        {duplicateIsEnabled &&
          selectedEntity?.selectedElementType ===
            SelectedElementType.WORKFLOW && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsConfirmationModalForDuplicateOpen(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <DuplicateIcon />
              </IconContainer>
              <p>Duplicate</p>
            </li>
          )}
        {copyTaskToOtherPathIsEnabled &&
          selectedEntity?.selectedElementType ===
            SelectedElementType.WORKFLOW && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsCopyTaskToOtherPathModalOpen(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <DuplicateIcon />
              </IconContainer>
              <p>Copy Task to other Path</p>
            </li>
          )}
        {saveAsTemplateIsEnabled &&
          selectedEntity?.selectedElementType ===
            SelectedElementType.WORKFLOW && (
            <li
              role="presentation"
              onClick={() => {
                closeMenu();
                setIsSaveAsTemplateModalOpen(true);
              }}
              aria-hidden="true"
            >
              <IconContainer>
                <SaveIcon />
              </IconContainer>
              <p>Save as a Template</p>
            </li>
          )}
        {deleteIsEnabled &&
          (selectedEntity?.selectedElementType ===
            SelectedElementType.WORKFLOW ||
            selectedEntity?.selectedElementType ===
              SelectedElementType.SERVICE ||
            selectedEntity?.selectedElementType ===
              SelectedElementType.EVENT) && (
            <DeleteLi
              onClick={() => {
                closeMenu();
                setIsConfirmationModalForRemoveOpen(true);
              }}
            >
              <DeleteIconContainer>
                <DeleteIcon />
              </DeleteIconContainer>
              <p>Delete</p>
            </DeleteLi>
          )}
        {conditionallyShowSectionDelete() && (
          <DeleteLi
            onClick={() => {
              closeMenu();
              setIsConfirmationModalForRemoveOpen(true);
            }}
          >
            <DeleteIconContainer>
              <DeleteIcon />
            </DeleteIconContainer>
            <p>Delete Form Section</p>
          </DeleteLi>
        )}
        {deleteIsEnabled &&
          selectedEntity?.selectedElementType ===
            SelectedElementType.QUESTION_GROUP && (
            <DeleteLi
              onClick={() => {
                closeMenu();
                setIsConfirmationModalForRemoveSampleOpen(true);
              }}
            >
              <DeleteIconContainer>
                <DeleteIcon />
              </DeleteIconContainer>
              <DeletePathText>Delete the whole Path</DeletePathText>
            </DeleteLi>
          )}
      </List>
      {isConfirmationModalForRemoveOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.WORKFLOW && (
          <ConfirmationModal
            isOpen={isConfirmationModalForRemoveOpen}
            message="Are you sure you want to remove this task?"
            title="Confirmation"
            yesButtonText="Remove task"
            closeModal={() => setIsConfirmationModalForRemoveOpen(false)}
            acceptModal={onDeleteClick}
            isLoading={isLoading}
          />
        )}
      {isConfirmationModalForRemoveOpen &&
        selectedEntity?.selectedElementType === SelectedElementType.SERVICE && (
          <ConfirmationModal
            isOpen={isConfirmationModalForRemoveOpen}
            message="Are you sure you want to remove this subtask?"
            title="Confirmation"
            yesButtonText="Remove subtask"
            closeModal={() => setIsConfirmationModalForRemoveOpen(false)}
            acceptModal={onDeleteClick}
            isLoading={isLoading}
          />
        )}
      {isConfirmationModalForRemoveOpen &&
        selectedEntity?.selectedElementType === SelectedElementType.EVENT && (
          <ConfirmationModal
            isOpen={isConfirmationModalForRemoveOpen}
            message="Are you sure you want to remove this event?"
            title="Confirmation"
            yesButtonText="Remove event"
            closeModal={() => setIsConfirmationModalForRemoveOpen(false)}
            acceptModal={onDeleteClick}
            isLoading={isLoading}
          />
        )}
      {isConfirmationModalForDuplicateOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.WORKFLOW && (
          <ConfirmationModal
            isOpen={isConfirmationModalForDuplicateOpen}
            message="Are you sure you want to duplicate this task?"
            title="Confirmation"
            yesButtonText="Duplicate task"
            closeModal={() => setIsConfirmationModalForDuplicateOpen(false)}
            acceptModal={handleDuplicatePath}
            isLoading={isLoading}
          />
        )}
      {isConfirmationModalForMakeElementVisible &&
        (selectedEntity?.selectedElementType === SelectedElementType.SERVICE ||
          selectedEntity?.selectedElementType ===
            SelectedElementType.EVENT) && (
          <ConfirmationModal
            isOpen={isConfirmationModalForMakeElementVisible}
            message={`Are you sure you want to make it ${visibleText} on all paths?`}
            title="Confirmation"
            yesButtonText={`Make it ${visibleText}`}
            closeModal={() =>
              setIsConfirmationModalForMakeElementVisible(false)
            }
            acceptModal={handleMakeElementVisible}
            isLoading={isLoading}
          />
        )}
      {isMoveSubtaskToTaskModalOpen &&
        selectedEntity?.selectedElementType === SelectedElementType.SERVICE && (
          <MoveSubtaskToOtherTaskModal
            isOpen={isMoveSubtaskToTaskModalOpen}
            onClose={() => setIsMoveSubtaskToTaskModalOpen(false)}
          />
        )}
      {isCopyTaskToOtherPathModalOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.WORKFLOW && (
          <CopyTaskToOtherPathModal
            isOpen={isCopyTaskToOtherPathModalOpen}
            onClose={() => setIsCopyTaskToOtherPathModalOpen(false)}
          />
        )}
      {isSaveAsTemplateModalOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.WORKFLOW && (
          <SaveAsTemplateModal
            isOpen={isSaveAsTemplateModalOpen}
            onClose={() => setIsSaveAsTemplateModalOpen(false)}
          />
        )}
      {isMoveAllServicesToNewStartDateModalVisible && (
        <MoveAllServicesToNewStartDateModal
          isOpen={isMoveAllServicesToNewStartDateModalVisible}
          onClose={() => setIsMoveAllServicesToNewStartDateModalVisible(false)}
        />
      )}
      {isConfirmationModalForRemoveOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.QUESTION_GROUP && (
          <ConfirmationModal
            isOpen={isConfirmationModalForRemoveOpen}
            message="Do you want to delete this section?"
            title="Confirmation"
            closeModal={() => setIsConfirmationModalForRemoveOpen(false)}
            acceptModal={() => removeQuestionGroupFromSample()}
            isLoading={isLoading}
          />
        )}
      {isConfirmationModalForRemoveSampleOpen &&
        selectedEntity?.selectedElementType ===
          SelectedElementType.QUESTION_GROUP && (
          <ConfirmationModal
            isOpen={isConfirmationModalForRemoveSampleOpen}
            message="Do you want to delete this path?"
            title="Confirmation"
            yesButtonText="DELETE PATH"
            closeModal={() => setIsConfirmationModalForRemoveSampleOpen(false)}
            acceptModal={() => removeSampleFromProject()}
            isLoading={isLoading}
          />
        )}
    </>
  );
};

const IconContainer = styled(Box)`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 0.25rem !important;
`;

const DeleteIconContainer = styled(IconContainer)`
  & > svg circle {
    transition: fill linear 0.2s;
  }

  & > svg:hover > path {
    fill: ${({ theme }): string => theme.newColors.extra.errorHovered};
    transition: fill linear 0.2s;
  }

  & > svg:active > path {
    fill: ${({ theme }): string => theme.newColors.extra.errorActive};
    transition: fill linear 0.2s;
  }
`;

const DeleteLi = styled.li`
  display: flex;
  justify-content: start;
  padding: 0.8rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }): string => theme.newColors.primary.lightTint};
  }

  &:active {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.lightestTint};
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    line-height: 1rem;
    gap: 6px;
    cursor: pointer;
    transition: 200ms;
  }

  & li > p {
    margin: 0;
    padding-top: 2px;
    line-height: 22px;
    white-space: nowrap;
  }

  & li:first-of-type:hover {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  & li:last-of-type:hover {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  & li:hover {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.lightTint};
    transition: 200ms;
  }

  & li:active {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.lightestTint};
    transition: 200ms;
  }
`;

const DeletePathText = styled.p`
  color: #f40505;
`;
