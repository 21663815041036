import React from "react";
import {
  MainTableContainer,
  TableContainer,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderRow,
  TableHeaderCell,
} from "./StyledTableComponents";

export const Table = (props: TableProps): JSX.Element => {
  const { children, columns, scrollable = true, isEditable } = props;

  return (
    <MainTableContainer scrollable={scrollable}>
      <TableContainer>
        <TableHeader>
          <TableHeaderRow>
            {columns.map((c) => (
              <TableHeaderCell key={c}>{c}</TableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {children.map((row) => (
            <TableRow key={`row_${Math.random()}`}>
              {row.map((cell: React.ReactNode) => (
                <TableCell
                  key={`cell_${Math.random()}`}
                  isEditable={isEditable}
                >
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </MainTableContainer>
  );
};

type TableProps = {
  children: any[];
  columns: string[];
  scrollable?: boolean;
  isEditable?: boolean;
};
