import React from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import {
  AnswerType,
  useProjectWithMultiAnswerMutation,
  useProjectWithNumericAnswerMutation,
  useProjectWithTextAnswerMutation,
  UserPlainProjectsWithSamplesDocument,
  UserProjectDocument,
  useUserPlainProjectsQuery,
  useUserProjectQuery,
} from "../generated/graphql";
import { Spinner } from "../components/common/Spinner/Spinner";
import { ProjectConfigurator as ProjectConfiguratorComponent } from "../components/Projects/Configurator/ProjectConfigurator";
import { QuestionMetadata } from "../utils/types/QuestionMetadata";

const runQuestionAnswerMutationAfterMs = 1000;
let lastMetadata: null | string = null;

export const ProjectConfigurator = (props: RouteComponentProps) => {
  const urlQueryParams = useParams();

  const queryResult = useUserProjectQuery({
    variables: {
      id: urlQueryParams.projectId,
    },
  });

  const queryProjectsResult = useUserPlainProjectsQuery({
    variables: {},
  });

  const projectWithMultiAnswer = useProjectWithMultiAnswerMutation()[0];
  const projectWithNumericAnswer = useProjectWithNumericAnswerMutation()[0];
  const projectWithTextAnswer = useProjectWithTextAnswerMutation()[0];

  const getMutationBasedOnAnswerType = (answerType: AnswerType) => {
    switch (answerType) {
      case AnswerType.Number:
        return projectWithNumericAnswer;
      case AnswerType.ManyOfMany:
        return projectWithMultiAnswer;
      default:
        return projectWithTextAnswer;
    }
  };

  const answerProjectQuestion = (
    metadata: QuestionMetadata,
    answer: any
  ): void => {
    const options = {
      variables: {
        answer,
        projectQuestionInput: {
          questionInput: metadata.questionInput,
          projectId: metadata.questionLevelInput.projectId,
        },
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: {
            id: metadata.questionLevelInput.projectId,
          },
        },
      ],
      awaitRefetchQueries: true,
    };
    if (metadata?.questionInput?.questionId === "TEMP_PROJECT_Q_ID_name") {
      options.refetchQueries.push({
        query: UserPlainProjectsWithSamplesDocument,
        variables: { id: "" },
      });
    }
    const mutation = getMutationBasedOnAnswerType(metadata.answerType);
    if (metadata.answerType === AnswerType.Number) {
      options.variables.answer = parseFloat(options.variables.answer) || 0;
    }
    const mutationPromise: Promise<unknown> = mutation(options);
    mutationPromise.catch((e) => {
      toast.error(e.toString());
    });
  };

  if (queryResult.error) {
    return <p>Query Error: {queryResult.error}</p>;
  }

  if (queryProjectsResult.error) {
    return <p>Query Error: {queryProjectsResult.error}</p>;
  }

  if (queryResult.loading || !queryResult.data || !queryResult.data.Project) {
    return <Spinner />;
  }

  if (
    queryProjectsResult.loading ||
    !queryProjectsResult.data ||
    !queryProjectsResult.data.Projects
  ) {
    return <Spinner />;
  }

  const answerProjectQuestionDebouncedInternal = debounce(
    answerProjectQuestion,
    runQuestionAnswerMutationAfterMs,
    {
      leading: false,
      trailing: true,
    }
  );

  const answerProjectQuestionDebounced = (
    metadata: QuestionMetadata,
    answear: any
  ) => {
    if (lastMetadata !== JSON.stringify(metadata)) {
      lastMetadata = JSON.stringify(metadata);
      answerProjectQuestionDebouncedInternal.flush();
    }
    answerProjectQuestionDebouncedInternal(metadata, answear);
  };

  return (
    <ProjectConfiguratorComponent
      project={queryResult.data.Project}
      projects={queryProjectsResult.data.Projects}
      answerProjectQuestion={answerProjectQuestionDebounced}
      {...props}
    />
  );
};
