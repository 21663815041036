import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useAuth } from "react-use-auth";
import { Modal } from "../../common/Modal/Modal";
import { ButtonType } from "../../common/Button/ButtonType";
import { Button } from "../../common/Button/Button";
import { Spinner } from "../../common/Spinner/Spinner";
import {
  useUpdateUserSamplesAssignmentInProjectMutation,
  useProjectWithAllSamplesQuery,
  UserProjectDocument,
} from "../../../generated/graphql";
import { PathAccessManagementModalBody } from "./PathAccessManagementModalBody";
import {
  AssignedSample,
  getPathsAssignedToUser,
} from "./getPathsAssignedToUser";
import { ROLES } from "../../../constants/roles";

interface PathAccessManagementModalProps {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  user: { id: string; name: string; role: string };
}

export const PathAccessManagementModal = (
  props: PathAccessManagementModalProps
): JSX.Element => {
  const { projectId, isOpen, onClose, user } = props;

  const { user: authUser } = useAuth();

  const [assignedSamples, setAssignedSamples] = useState<AssignedSample[]>([]);
  const [canUserModifyOverview, setCanUserModifyOverview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { error, loading, data, refetch } = useProjectWithAllSamplesQuery({
    variables: {
      id: projectId,
    },
  });

  const [updateUserSamplesAssignmentInProjectMutation] =
    useUpdateUserSamplesAssignmentInProjectMutation();

  useEffect(() => {
    if (data?.ProjectWithAllSamples) {
      setAssignedSamples(
        getPathsAssignedToUser(data.ProjectWithAllSamples, user.id, user.role)
      );
    }
  }, [data]);

  useEffect(() => {
    if (data?.ProjectWithAllSamples) {
      const userRoles = authUser["https://leaderneplatform.com/roles"];

      if (
        userRoles.includes(ROLES.ADMINISTRATOR) ||
        userRoles.includes(ROLES.ORGANIZATION_ADMINISTRATOR)
      ) {
        setCanUserModifyOverview(true);
        return;
      }

      const test = getPathsAssignedToUser(
        data.ProjectWithAllSamples,
        authUser.sub || "",
        userRoles[0]
      );

      if (
        test.length > 0 &&
        (!test[0].assigned ||
          test[0].role === ROLES.READONLY ||
          test[0].role === ROLES.SPECIALIST)
      ) {
        setCanUserModifyOverview(false);
        return;
      }

      setCanUserModifyOverview(true);
    }
  }, [data, user.id]);

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  if (loading || !data) {
    return <Spinner opaque />;
  }

  const onCheckboxChange = (sampleId: string, assigned: boolean): void => {
    setAssignedSamples((prevAssignedSamples: AssignedSample[]) =>
      prevAssignedSamples.map((prevAssignedSample: AssignedSample) => {
        if (prevAssignedSample.id === sampleId) {
          return { ...prevAssignedSample, assigned };
        }
        return prevAssignedSample;
      })
    );
  };

  const onDropdownChange = (sampleId: string, role: string): void => {
    setAssignedSamples((prevAssignedSamples: AssignedSample[]) =>
      prevAssignedSamples.map((prevAssignedSample: AssignedSample) => {
        if (prevAssignedSample.id === sampleId) {
          return { ...prevAssignedSample, role };
        }
        return prevAssignedSample;
      })
    );
  };

  const onAcceptClick = (): void => {
    const sampleAssignments = assignedSamples
      .filter((assignedSample) => assignedSample.assigned)
      .map((filteredAssignedSample) => {
        return {
          sampleId: filteredAssignedSample.id,
          role: filteredAssignedSample.role,
        };
      });

    if (!sampleAssignments.length) {
      toast.error("User must be assigned to at least one sample");
      return;
    }

    setIsLoading(true);

    updateUserSamplesAssignmentInProjectMutation({
      variables: {
        updateUserSamplesAssignmentInProjectInput: {
          projectId,
          sampleAssignments,
          userId: user.id,
        },
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
      ],
    })
      .then(() => {
        refetch()
          .then(() => {
            setIsLoading(false);
            onClose();
          })
          .catch((e) => {
            setIsLoading(false);
            toast.error(e);
          });
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const ModalButtons = (): JSX.Element => {
    return (
      <>
        <AcceptButton
          label="Save"
          type={ButtonType.Primary}
          onClick={onAcceptClick}
        />
        <Button label="Cancel" type={ButtonType.Secondary} onClick={onClose} />
      </>
    );
  };

  if (!isOpen) return <></>;

  return (
    <Modal
      id="assignPathsToUser"
      title={`Assign paths to user: ${user.name}`}
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <PathAccessManagementModalBody
        assignedSamples={assignedSamples}
        onCheckboxChange={onCheckboxChange}
        onDropdownChange={onDropdownChange}
        user={user}
        canUserModifyOverview={canUserModifyOverview}
      />
    </Modal>
  );
};

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;
