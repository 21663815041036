import * as React from "react";
import styled from "styled-components";

export const ServiceGroup: React.FunctionComponent<{
  title: string;
  count: number;
  wrapped: boolean;
  wrapGroupCallback: () => void;
}> = (props) => {
  const { children, title, count, wrapped, wrapGroupCallback } = props;
  const groupMarker = wrapped ? "▾" : "▸";
  return (
    <ServiceGroupContainer>
      <Title onClick={wrapGroupCallback}>
        {groupMarker} {title} • {count}
      </Title>
      {!wrapped ? <Elements>{children}</Elements> : null}
    </ServiceGroupContainer>
  );
};

const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  cursor: pointer;
  font-weight: bold;

  color: ${({ theme }): string => theme.newColors.grayscale.primaryGray};
`;

const Elements = styled.div`
  & > div {
    margin: 6px 0;
  }
`;

const ServiceGroupContainer = styled.div`
  margin-bottom: 12px;
`;
