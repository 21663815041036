import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ChainedIcon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.31449 7.94749L3.20964 6.05235C3.4713 5.79068 3.89554 5.79068 4.15721 6.05235C4.40018 6.29532 4.41754 6.67848 4.20927 6.94148L4.15721 6.99992L2.26206 8.89506C1.47707 9.68005 1.47707 10.9528 2.26206 11.7378C3.01902 12.4947 4.2295 12.5218 5.01885 11.8189L5.10478 11.7378L6.99992 9.84263C7.26158 9.58097 7.68582 9.58097 7.94749 9.84263C8.19046 10.0856 8.20782 10.4688 7.99956 10.7318L7.94749 10.7902L6.05235 12.6853C4.74403 13.9937 2.62282 13.9937 1.31449 12.6853C0.0406009 11.4115 0.00707746 9.36689 1.21392 8.05244L1.31449 7.94749ZM7.94752 1.31449C9.25584 0.00617141 11.3771 0.00617141 12.6854 1.31449C13.9937 2.62282 13.9937 4.74403 12.6854 6.05235L10.7902 7.94749C10.5286 8.20915 10.1043 8.20915 9.84266 7.94749C9.581 7.68582 9.581 7.26158 9.84266 6.99992L11.7378 5.10478C12.5228 4.31978 12.5228 3.04706 11.7378 2.26206C10.9528 1.47707 9.68008 1.47707 8.89509 2.26206L6.99995 4.15721C6.73828 4.41887 6.31404 4.41887 6.05238 4.15721C5.79071 3.89554 5.79071 3.4713 6.05238 3.20964L7.94752 1.31449ZM7.94753 5.10478C8.20919 4.84311 8.63344 4.84311 8.8951 5.10478C9.15676 5.36644 9.15676 5.79068 8.8951 6.05235L6.05239 8.89506C5.79072 9.15672 5.36648 9.15672 5.10482 8.89506C4.84315 8.6334 4.84315 8.20915 5.10482 7.94749L7.94753 5.10478Z"
        fill={color}
      />
    </svg>
  );
};
ChainedIcon.defaultProps = {
  width: "14",
  height: "14",
  color: styledTheme.colors.lightText,
};
