import React, { useEffect, useState } from "react";
import { navigate, useMatch } from "@reach/router";
import { Workflow } from "../../generated/graphql";
import Block, { BlockType } from "./Block";
import { getWorkflowLengthInWeeks } from "./helpers";
import { styledTheme } from "../../theme/theme";
import {
  SelectedElementType,
  SelectedWorkflowPath,
} from "../../utils/types/ActionContextTypes";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { ROUTES } from "../../constants/routeConstants";

type WorkflowBlockType = {
  projectId: string;
  sampleId: string;
};

export const WorkflowBlock = ({
  projectId,
  sampleId,
  ...workflow
}: BlockType & Workflow & WorkflowBlockType): JSX.Element => {
  const { setSideDrawboardOpen } = useSideBarsContext();
  const { setSelectedEntity, selectedEntity } = useActionContext();
  const [serviceIsSelected, setServiceIsSelected] = useState(false);
  const matchProjectPath = useMatch(ROUTES.PROJECT_TIMELINE_WILDCARD);

  const headerHeight = 40;

  const selectedElementPath = {
    projectId,
    sampleId,
    workflowName: workflow.name,
    workflowId: workflow.id,
    workflow,
  } as SelectedWorkflowPath;

  const handleOnBlockClick = async (): Promise<void> => {
    setSideDrawboardOpen(true);
    setSelectedEntity({
      selectedElementType: SelectedElementType.WORKFLOW,
      selectedElementPath,
    });

    if (matchProjectPath)
      await navigate(
        `/projects/${projectId}/timeline/samples/${sampleId}/workflows/${workflow.id}`
      );
  };

  useEffect(() => {
    const isSelected = selectedEntity
      ? JSON.stringify(selectedEntity.selectedElementPath) ===
        JSON.stringify(selectedElementPath)
      : false;

    setServiceIsSelected(isSelected);
  }, [selectedEntity]);

  return (
    <Block
      headerHeight={headerHeight}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      length={`${getWorkflowLengthInWeeks(workflow)}w`}
      {...workflow}
      height={workflow.services.length * 20 + 150}
      blockColor={workflow.blockColor}
      onBlockClick={handleOnBlockClick}
      selected={serviceIsSelected}
      disableDnD={workflow.disableDnD}
      isWorkflow
    >
      <text fill={styledTheme.colors.lightText} x={0} y={9}>
        SUBTASKS
      </text>

      {/* <line */}
      {/*  x1="165" */}
      {/*  y1="4" */}
      {/*  x2={workflow.width - 10} */}
      {/*  y2="4" */}
      {/*  style={{ stroke: styledTheme.colors.athensGray, strokeWidth: 3 }} */}
      {/* /> */}
      {workflow.services.map((service, i) => (
        <text y={i * 20 + 30} key={service.id}>
          {service.name}
        </text>
      ))}
    </Block>
  );
};

WorkflowBlock.defaultProps = {
  width: 220,
  height: 100,
};
