// @ts-nocheck
import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { get, flatMap, throttle } from "lodash";
import useDimensions from "use-react-dimensions";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { useMatch } from "@reach/router";
import "./timeline.css";
import moment from "moment/moment";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toast } from "react-toastify";
import { useAuth } from "react-use-auth";
import { ServiceBlock } from "./ServiceBlock";
import {
  defaultAxisHeight,
  formatMonthsAxis,
  formatQuarterAxis,
  formatWeekAxis,
  formatYearAxis,
  getBlockHeight,
  getMonthsAxis,
  getQuarterAxis,
  getWeekAxis,
  getYearAxis,
  monthAxisDisplayByScale,
  quarterAxisDisplayByScale,
  weekAxisDisplayByScale,
  getOwnScale,
  getCurrentSize,
  ZOOM_LEVELS,
  formatDayAxis,
  getDayAxis,
  dayAxisDisplayByScale,
  getProjectStartWeekAxis,
} from "./axisHelpers";
import { WorkflowBlock } from "./WorkflowBlock";
import { getWorkflowRange, addAlphaToColor } from "./helpers";
import {
  ActualEntityPriceInput,
  CollaboratorsInput,
  EntityStatus,
  Event,
  Maybe,
  Scalars,
  Service,
  ServiceReportInput,
  UserProjectDocument,
  useUpdateServiceFromWorkflowMutation,
  useUpdateEventFromSampleMutation,
  Workflow,
  EventUpdatables,
} from "../../generated/graphql";
import Minimap from "./Minimap";
import { TodayLine } from "./TodayLine";
import { EventLine } from "./EventLine";
import { DropLine } from "./DropLine";
import { EventBlock } from "./EventBlock";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { AddIcon } from "../../images/icons/AddIcon";
import { useActionContext } from "../../utils/hooks/useActionContext";
import { definedColors } from "../SideDrawboard/InformationsContainer/ColorChooser";
import { ReadQueryResult } from "../../utils/types/QueryResult";
import {
  SelectedElementType,
  SelectedEntity,
} from "../../utils/types/ActionContextTypes";
import { updateServices } from "../../utils/helpers/updateServices";
import { useUserRolesContext } from "../../utils/hooks/useUserRolesContext";
import { styledTheme } from "../../theme/theme";
import {
  isSelectedServicePath,
  isSelectedEventPath,
} from "../../utils/types/ActionContextGuards";
import {
  GhostTimelineServices,
  GhostTimelineEvents,
} from "../../utils/types/GhostTimelineElementsTypes";
import { ROUTES } from "../../constants/routeConstants";
import { MenuDotsHorizontallyIcons } from "../../images/icons/MenuDotsHorizontallyIcons";
import { TextBox } from "./TextBox";
import { ChangePathLineNameModal } from "./ChangePathLineNameModal";

const zoomThreshold = 0.9;
const svgId = "timeline-svg";

const year = 365 * 24 * 60 * 60 * 1000; // 1 year

let dates = null;
let sampleId = null;
let canvasStartDate = 0;
let canvasEndDate = 0;

function getColorFromIndex(index: number): string {
  const colorIndex = index % definedColors.length;
  const color = definedColors[colorIndex];
  return color.color;
}

type ServiceUpdatables = {
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  serviceTemplate?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  baseLineSize?: Maybe<Scalars["Int"]>;
  baseLine?: Maybe<Scalars["Int"]>;
  status?: Maybe<EntityStatus>;
  price?: Maybe<ActualEntityPriceInput>;
  vendor?: Maybe<Scalars["String"]>;
  report?: Maybe<ServiceReportInput>;
  assignee?: Maybe<Scalars["ID"]>;
  collaborators?: Maybe<CollaboratorsInput>;
};

export const Timeline = (props): JSX.Element => {
  const { sample, projectId, hideAddIcon = false, disableDnD = false } = props;

  const zoomedFunction = React.useRef(null);
  const {
    selectedEntity,
    setSelectedEntity,
    elementOnDragAction,
    setDroppedElementCoordinates,
  } = useActionContext();
  const { user } = useAuth();
  const { useUserPermissions } = useUserRolesContext();

  const workflows: Workflow = get(sample, "enabledWorkflows", []);

  let services: Service[] = [];
  workflows.forEach((workflow, workflowIndex) => {
    const servicesToAdd = workflow.services.map((service) => {
      const serviceExtended = { ...service };
      serviceExtended.workflowId = workflow.id;
      serviceExtended.workflowName = workflow.name;
      serviceExtended.colorIndex = workflowIndex % definedColors.length;
      serviceExtended.color = getColorFromIndex(workflowIndex);
      return serviceExtended;
    });
    services = services.concat(servicesToAdd);
  });

  services.sort((serviceA, serviceB) => {
    const serviceALength = serviceA.endDate - serviceA.startDate;
    const serviceBLength = serviceB.endDate - serviceB.startDate;
    return serviceALength - serviceBLength;
  });
  services.reverse();

  const events: Event[] = get(sample, "events", []);

  if (!dates || (sample && sampleId !== sample.id)) {
    // run this calculation only once or after changing the sample
    dates = flatMap(services, (s) => [
      parseFloat(s.startDate),
      parseFloat(s.endDate),
    ]);
    const eventsDates = flatMap(events, (e) => [
      parseFloat(e.startDate),
      parseFloat(e.endDate),
    ]);
    dates = dates.concat(eventsDates);
    const canvasMin = d3.min(dates);
    const canvasMax = d3.max(dates);
    const canvasDiff = canvasMax - canvasMin;
    canvasStartDate = canvasMin - canvasDiff * 0.1;
    canvasEndDate = canvasMax + canvasDiff * 0.1;
    sampleId = sample.id;
  }

  const {
    ref,
    dimensions: { width, height },
  } = useDimensions({});

  const wrapperSVGRef = React.useRef(null);
  const axisWrapperGroupRef = React.useRef(null);
  const timelineElementsGroupRef = React.useRef(null);
  const todayGroupRef = React.useRef(null);
  const svgMinimapRef = React.useRef(null);

  const { setSideDrawboardOpen } = useSideBarsContext();

  const [axisWidth, setAxisWidth] = useState(width);

  const [pathLineNameToChange, setPathLineNameToChange] = useState<{
    baseline: string;
    name: string;
  } | null>(null);
  const [isChangePathLineNameModalOpen, setIsChangePathLineNameModalOpen] =
    useState(false);
  const [dropPlace, setDropPlace] = useState<{
    date: Date;
    baseline: number;
    yPosition: number;
  } | null>(null);

  const [selectedElementD3Data, setSelectedElementD3Data] = useState({
    isDragged: false,
    mousePosition: null,
    mouseYDragStartPos: null,
    previousBaseline: null,
  });

  useEffect(() => {
    if (width !== undefined) {
      setAxisWidth(width + 300);
    }
  }, [width]);

  function showBaselines(): void {
    d3.selectAll(".baselines").style("visibility", "visible");
  }

  function hideBaselines(): void {
    d3.selectAll(".baselines").style("visibility", "hidden");
  }

  function changeBaselineStroke(): void {
    d3.select(this).attr("stroke", "#c0c0c0");
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function clearBaselines(baselinesToClear: any): void {
    baselinesToClear.each(changeBaselineStroke);
  }

  const baselines = d3.selectAll(".baselines");

  useEffect(() => {
    if (elementOnDragAction) {
      showBaselines();
      return;
    }

    hideBaselines();
    setDropPlace(null);
    clearBaselines(baselines);
  }, [elementOnDragAction]);

  const matchWorkflowPath = useMatch(ROUTES.PROJECT_TIMELINE_SAMPLE_WORKFLOW);

  const [zoomLevel, setTimelineZoomLevel] = React.useState(
    matchWorkflowPath ? ZOOM_LEVELS.HIGHER : ZOOM_LEVELS.DEFAULT
  );

  const axisDomain = [new Date(canvasStartDate), new Date(canvasEndDate)];

  const x = d3.scaleTime().domain(axisDomain).range([0, axisWidth]);

  const [currentTransform, setCurrentTransform] = useState(
    d3.zoomIdentity.translate(0, 0).scale(1)
  );

  const baselineY = window.innerHeight / 2;
  const baselineHeightBaseValue = 150;
  const baselineHeight =
    baselineHeightBaseValue * getOwnScale(currentTransform.k);
  const workflowsBaselineHeight = 200;

  //= =============================================================
  // MINIMAP prep
  // minimap inspired by https://observablehq.com/@rabelais/d3-js-zoom-minimap
  //= =============================================================

  const _config = {
    margin: 20,
    // TODO this should be devised based on the project extent and its visible width on the screen
    _width: width,
    _height: height,
    viewWidth: 240,
    controlsHeight: 50,
  };

  const config = {
    ..._config,
    clippedWidth: _config._width - _config.margin * 2,
    clippedHeight: _config._height - _config.margin * 2,
    minimapScale: _config.viewWidth / _config._width,
    viewHeight: Math.min(
      500,
      Math.max(100, _config._height * (_config.viewWidth / _config._width))
    ),
  };

  const minimapScaleX = (zoomScale: number): unknown =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    d3.scaleLinear([0, config._width], [0, config._width * zoomScale]);

  const minimapScaleY = (zoomScale: number): unknown =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    d3.scaleLinear([0, config._height], [0, config._height * zoomScale]);
  //= =============================================================

  let firstGhostEventStartDate;
  if (
    props.ghostTimelineElements &&
    props.ghostTimelineElements.ghostEvents &&
    props.ghostTimelineElements.ghostEvents.length > 0
  ) {
    props.ghostTimelineElements.ghostEvents.forEach((pathEvents) => {
      pathEvents.events.forEach((pathEvent) => {
        const startDateInt = parseInt(pathEvent.startDate, 10);
        if (!firstGhostEventStartDate) {
          firstGhostEventStartDate = startDateInt;
        }
        if (startDateInt < firstGhostEventStartDate) {
          firstGhostEventStartDate = startDateInt;
        }
      });
    });
  }

  const draw = (): void => {
    const axisWrapperElement = d3.select(axisWrapperGroupRef.current);
    const todayLine = d3.select(todayGroupRef.current);

    axisWrapperElement.selectAll("*").remove();

    const timelineElementsGroup = d3.select(timelineElementsGroupRef.current);
    // .attr("cursor", "grab");

    // axis background
    axisWrapperElement
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", axisWidth)
      .attr("height", defaultAxisHeight)
      .style("fill", "#ffffff");

    const mAxis = getMonthsAxis(x);
    const monthsAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-month-axis")
      .attr("transform", "translate(0,75)")
      .call(mAxis);
    formatMonthsAxis(monthsAxisHandler);

    const wAxis = getWeekAxis(x);
    const weekAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-week-axis")
      .attr("transform", "translate(0,94)")
      .call(wAxis);
    formatWeekAxis(weekAxisHandler);

    const dAxis = getDayAxis(x);
    const dayAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-day-axis")
      .attr("transform", "translate(0,103)")
      .call(dAxis);
    formatDayAxis(dayAxisHandler);

    const qAxis = getQuarterAxis(x);
    const quarterAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-quarter-axis")
      .attr("transform", `translate(0,${defaultAxisHeight})`)
      .call(qAxis);
    formatQuarterAxis(quarterAxisHandler);

    const yAxis = getYearAxis(x);
    const yearAxisHandler = axisWrapperElement
      .append("g")
      .attr("class", "timeline-year-axis")
      .attr("transform", `translate(0,${defaultAxisHeight})`)
      .call(yAxis);
    formatYearAxis(yearAxisHandler);

    let projectStartWeekAxisHandler;
    if (firstGhostEventStartDate) {
      const projectStartWeekAxis = getProjectStartWeekAxis(
        x,
        firstGhostEventStartDate
      );
      projectStartWeekAxisHandler = axisWrapperElement
        .append("g")
        .attr("class", "timeline-week-axis")
        .attr("transform", "translate(0,115)")
        .call(projectStartWeekAxis);
      formatWeekAxis(projectStartWeekAxisHandler);
    }

    const updateXAxis = (transform): void => {
      const updatedX = transform.rescaleX(x as d3.ZoomScale);

      const updatedMAxis = getMonthsAxis(updatedX);
      const updatedMonthsAxisHandler = monthsAxisHandler.call(updatedMAxis);
      formatMonthsAxis(updatedMonthsAxisHandler);

      const updatedQAxis = getQuarterAxis(updatedX);
      const updatedQuarterAxisHandler = quarterAxisHandler.call(updatedQAxis);
      formatQuarterAxis(updatedQuarterAxisHandler);

      const updatedYAxis = getYearAxis(updatedX);
      const updatedYearAxisHandler = yearAxisHandler.call(updatedYAxis);
      formatYearAxis(updatedYearAxisHandler);

      const updatedWAxis = getWeekAxis(updatedX);
      weekAxisHandler.call(updatedWAxis);
      formatWeekAxis(weekAxisHandler);

      const updatedDAxis = getDayAxis(updatedX);
      dayAxisHandler.call(updatedDAxis);
      formatDayAxis(dayAxisHandler);

      if (firstGhostEventStartDate) {
        const updatedProjectStartWAxis = getProjectStartWeekAxis(
          updatedX,
          firstGhostEventStartDate
        );
        projectStartWeekAxisHandler.call(updatedProjectStartWAxis);
        formatWeekAxis(projectStartWeekAxisHandler);
      }
    };

    const timelineWidth = canvasEndDate - canvasStartDate;
    const scaleNominator = (100000000 * width) / timelineWidth;

    const setDayAxisVisibility = (transform): void => {
      const scale = scaleNominator * transform.k;
      if (
        scale < dayAxisDisplayByScale &&
        !dayAxisHandler.classed("timeline-day-axis-hide")
      ) {
        dayAxisHandler.classed("timeline-day-axis-hide", true);
      }
      if (
        scale >= dayAxisDisplayByScale &&
        dayAxisHandler.classed("timeline-day-axis-hide")
      ) {
        dayAxisHandler.classed("timeline-day-axis-hide", false);
      }
    };

    const setWeekAxisVisibility = (transform): void => {
      const scale = scaleNominator * transform.k;
      if (
        scale < weekAxisDisplayByScale &&
        !weekAxisHandler.classed("timeline-week-axis-hide")
      ) {
        weekAxisHandler.classed("timeline-week-axis-hide", true);
        if (firstGhostEventStartDate) {
          projectStartWeekAxisHandler.classed("timeline-week-axis-hide", true);
        }
      }
      if (
        scale >= weekAxisDisplayByScale &&
        weekAxisHandler.classed("timeline-week-axis-hide")
      ) {
        weekAxisHandler.classed("timeline-week-axis-hide", false);
        if (firstGhostEventStartDate) {
          projectStartWeekAxisHandler.classed("timeline-week-axis-hide", false);
        }
      }
    };

    const setMonthAxisVisibility = (transform): void => {
      const scale = scaleNominator * transform.k;
      if (
        scale < monthAxisDisplayByScale &&
        !monthsAxisHandler.classed("timeline-month-axis-hide")
      ) {
        monthsAxisHandler.classed("timeline-month-axis-hide", true);
      }
      if (
        scale >= monthAxisDisplayByScale &&
        monthsAxisHandler.classed("timeline-month-axis-hide")
      ) {
        monthsAxisHandler.classed("timeline-month-axis-hide", false);
      }
    };

    const switchQuarterAxisToYearAxis = (transform): void => {
      const scale = scaleNominator * transform.k;
      if (scale < quarterAxisDisplayByScale) {
        if (!quarterAxisHandler.classed("timeline-quarter-axis-hide"))
          quarterAxisHandler.classed("timeline-quarter-axis-hide", true);
        if (yearAxisHandler.classed("timeline-year-axis-hide"))
          yearAxisHandler.classed("timeline-year-axis-hide", false);
      }
      if (scale >= quarterAxisDisplayByScale) {
        if (quarterAxisHandler.classed("timeline-quarter-axis-hide"))
          quarterAxisHandler.classed("timeline-quarter-axis-hide", false);
        if (!yearAxisHandler.classed("timeline-year-axis-hide"))
          yearAxisHandler.classed("timeline-year-axis-hide", true);
      }
    };

    const zoomed = (transform): void => {
      timelineElementsGroup.attr("transform", transform);

      setDayAxisVisibility(transform);
      setWeekAxisVisibility(transform);
      setMonthAxisVisibility(transform);
      switchQuarterAxisToYearAxis(transform);
      updateXAxis(transform);
      todayLine.attr("transform", transform);
      setCurrentTransform(transform);
    };

    let transformData;

    const updateMinimap = (transform): void => {
      const scaleX = minimapScaleX(transform.k);
      const scaleY = minimapScaleY(transform.k);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      brush.move(gBrush, [
        [scaleX.invert(-transform.x), scaleY.invert(-transform.y)],
        [
          scaleX.invert(-transform.x + config._width),
          scaleY.invert(-transform.y + config._height),
        ],
      ]);
    };

    // eslint-disable-next-line consistent-return
    function onZoom(transform): void {
      if (transform.k < zoomThreshold) {
        setTimelineZoomLevel(ZOOM_LEVELS.HIGHER);
      } else {
        setTimelineZoomLevel(ZOOM_LEVELS.DEFAULT);
      }

      updateMinimap(transform);
      zoomed(transform);
    }

    const onZoomThrottled = throttle(onZoom, 100, {
      leading: true,
      trailing: true,
    });

    function getMaxWorldHeightMargin(transformK): number {
      return window.innerHeight * transformK - config.viewHeight;
    }

    function checkIfUserViewIsOutside(
      transformK,
      transformY,
      maxHeight
    ): boolean {
      const isOutside = transformK >= zoomThreshold && transformY < -maxHeight;

      return isOutside;
    }

    function onZoomThrottledCall(): null {
      const oldK = transformData && transformData.k;
      transformData = d3.event.transform;

      const maxWorldHeightMargin = getMaxWorldHeightMargin(transformData.k);

      const isUserViewOutside = checkIfUserViewIsOutside(
        transformData.k,
        transformData.y,
        maxWorldHeightMargin
      );

      if (isUserViewOutside) {
        transformData.y = -maxWorldHeightMargin;
      }

      setCurrentTransform(transformData);

      if (oldK !== transformData.k) {
        onZoomThrottled(transformData);
      } else {
        onZoom(transformData);
      }

      return null;
    }

    const worldWidth = x(canvasEndDate);
    let worldHeight = window.innerHeight;

    if (zoomLevel === ZOOM_LEVELS.HIGHER) {
      const lastIndexOfWorkflowsArray = workflows.length - 1;
      const positionOfLastWorkflow =
        lastIndexOfWorkflowsArray * workflowsBaselineHeight;
      worldHeight = positionOfLastWorkflow;
    }

    const translatedExtent = [
      [x(moment(canvasStartDate)), 0],
      [worldWidth, worldHeight],
    ];

    const zoom = d3
      .zoom()
      .scaleExtent([0.65, 50]) // smaller front, larger latter
      .translateExtent(translatedExtent)
      .extent([
        [0, 0],
        [config.viewWidth, config.viewHeight],
      ]) // viewport extent
      .on("zoom", onZoomThrottledCall)
      .filter(() => {
        // return true/false in filter means that action can (or not) be triggered on zoom event
        const isDoubleClick = d3.event.type === "dblclick";

        if (!isDoubleClick) return true;

        const targetElement = d3.event.target;

        const isClickedOnTimeline =
          targetElement.parentNode.id === "timeline" ||
          targetElement.id === "timeline-svg" ||
          targetElement.parentNode.id === "today";

        if (isClickedOnTimeline) return true;
        return false;
      });

    // eslint-disable-next-line consistent-return
    function onBrush(): void {
      // prevent zoom invoked event
      // if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") {
      //   return null;
      // }
      if (Array.isArray(d3.event.selection)) {
        const [[brushX, brushY]] = d3.event.selection;
        const zoomScale = d3.zoomTransform(axisWrapperElement.node()).k;

        const scaleX = minimapScaleX(zoomScale);
        const scaleY = minimapScaleY(zoomScale);

        const newTransform = d3.zoomIdentity
          .translate(-scaleX(brushX), -scaleY(brushY))
          .scale(zoomScale);

        const isItEndOfMovingMinimap =
          d3.event.sourceEvent &&
          d3.event.type === "end" &&
          d3.event.sourceEvent.type === "mouseup";

        const maxWorldHeightMargin = getMaxWorldHeightMargin(newTransform.k);

        const isUserViewOutside = checkIfUserViewIsOutside(
          newTransform.k,
          newTransform.y,
          maxWorldHeightMargin
        );

        if (isUserViewOutside) {
          transformData.y = -maxWorldHeightMargin;
        }

        if (isItEndOfMovingMinimap) {
          d3.select(wrapperSVGRef.current).call(zoom.transform, newTransform);
        }

        setCurrentTransform(newTransform);
        zoomed(newTransform);
      }
    }

    const svgMinimap = d3.select(svgMinimapRef.current);
    svgMinimap.select("#gBrush").remove();
    const gBrush = svgMinimap.append("g").attr("id", "gBrush");

    const brush = d3
      .brush()
      .extent([
        [0, 0],
        [config._width, config._height],
      ])
      .on("brush end", onBrush);

    d3.select(wrapperSVGRef.current).call(zoom);

    gBrush.call(brush);
    updateMinimap(currentTransform);
    updateXAxis(currentTransform);

    zoomedFunction.current = onZoom;
  };

  const drawLines = (): JSX.Element[] => {
    const lines = [];
    const firstWeek = moment(canvasStartDate).startOf("isoWeek");
    const firstWeekTimestamp = firstWeek.valueOf();
    const lastWeek = moment(canvasEndDate).endOf("isoWeek");
    const numberOfWeeks = lastWeek.diff(firstWeek, "week");
    const numberOfWeeksToDraw = numberOfWeeks + 100;
    const aWeek = 7 * 24 * 60 * 60 * 1000;
    const x1 = -x(firstWeekTimestamp) + 0.1;

    lines.push(
      <line
        id="wL"
        key={`line-${0}`}
        x1={x(firstWeekTimestamp)}
        x2={x(firstWeekTimestamp)}
        y1={0}
        y2={2000}
        stroke="#c0c0c0"
        strokeWidth="1"
        strokeDasharray="1, 12"
      />
    );

    for (let week = 1; week < numberOfWeeksToDraw; week += 1) {
      lines.push(
        <use
          key={`line-${week}`}
          href="#wL"
          x={x1 + x(firstWeekTimestamp + aWeek * week)}
        />
      );
    }

    return lines;
  };

  function getYValueForBaseline(baseline: number): number {
    const halfBlockHeight = getBlockHeight(currentTransform.k) / 2;
    return baselineY + halfBlockHeight - baselineHeight * baseline;
  }

  const drawBaselineLines = (): JSX.Element[] => {
    const lines = [];
    const firstWeek = moment(canvasStartDate).startOf("week");
    const firstWeekTimestamp = firstWeek.valueOf();
    const lastWeek = moment(canvasEndDate).endOf("week");
    const lastWeekTimestamp = lastWeek.valueOf() + year;
    const numberOfBaselinesToDraw = 50;
    const strokeWidth = currentTransform.k < 5 ? "2" : "0.5";

    for (
      let baseline = -20;
      baseline < numberOfBaselinesToDraw;
      baseline += 1
    ) {
      lines.push(
        <line
          className="baselines"
          data-baseline={baseline}
          style={{ visibility: "hidden", cursor: "row-resize" }}
          id="baselineL"
          key={`baseline${baseline}`}
          x1={x(firstWeekTimestamp)}
          x2={x(lastWeekTimestamp)}
          y1={getYValueForBaseline(baseline)}
          y2={getYValueForBaseline(baseline)}
          stroke="#c0c0c0"
          strokeWidth={strokeWidth}
        />
      );
    }

    return lines;
  };

  const drawChangeLineNamingDots = (readonly = false): JSX.Element[] => {
    const dots = [];
    const numberOfDotsToDraw = 100;
    const ownScale = getOwnScale(currentTransform.k) * 2;
    const xPosition = -currentTransform.x / currentTransform.k + 20 * ownScale;
    const totalHeight = 108 * ownScale;
    const theMiddleOfLine =
      totalHeight / 4 - getCurrentSize(20, currentTransform.k) / 2;

    for (let baseline = -100; baseline < numberOfDotsToDraw; baseline += 1) {
      const foundTimelineLine = sample.timelineLines?.find(
        (timelineLine) => timelineLine.line === baseline
      );

      const yPosition = baselineY - baseline * baselineHeight + theMiddleOfLine;

      if (foundTimelineLine) {
        dots.push(
          <svg
            key={baseline}
            x={xPosition}
            y={yPosition - ownScale * 40}
            style={{
              cursor: "pointer",
            }}
          >
            <TextBox
              text={foundTimelineLine.name}
              onClick={(): void => {
                if (readonly) return;
                setPathLineNameToChange({
                  baseline,
                  name: foundTimelineLine.name,
                });
                setIsChangePathLineNameModalOpen(true);
              }}
              ownScale={ownScale}
              readonly={readonly}
            />
          </svg>
        );
      } else if (!readonly) {
        dots.push(
          <MenuDotsHorizontallyIcons
            onClick={(): void => {
              setPathLineNameToChange({ baseline, name: "" });
              setIsChangePathLineNameModalOpen(true);
            }}
            style={{
              cursor: "pointer",
            }}
            key={baseline}
            x={xPosition}
            y={yPosition + ownScale * 8}
            width={22}
            height={6}
            ownScale={ownScale}
          />
        );
      }
    }

    return dots;
  };

  const handleOnAddIconCLick = (): void => {
    setSelectedEntity(undefined);
    setSideDrawboardOpen(true);
  };

  const [updateServiceFromWorkflow] = useUpdateServiceFromWorkflowMutation();

  const userRoleInSample = sample?.users?.find((u) => u?.id === user.sub)
    ?.role as ROLES | undefined;
  const editServiceInformationsIsEnabled = useUserPermissions(
    "editServiceInformations",
    userRoleInSample
  );
  const addItemsToTimelineIsEnabled = useUserPermissions(
    "addItemsToTimeline",
    userRoleInSample
  );

  const disableDnDToUse =
    !editServiceInformationsIsEnabled ||
    !addItemsToTimelineIsEnabled ||
    disableDnD;

  const hideAddIconToUse =
    !editServiceInformationsIsEnabled ||
    !addItemsToTimelineIsEnabled ||
    hideAddIcon;

  async function updateService(serviceUpdatables: ServiceUpdatables): void {
    if (!editServiceInformationsIsEnabled) {
      return;
    }

    const {
      projectId: selectedProjectId,
      sampleId: selectedSampleId,
      workflowId: selectedWorkflowId,
      service: { id: selectedServiceId },
      service,
    } = selectedEntity.selectedElementPath;

    if (serviceUpdatables.endDate && !serviceUpdatables.startDate) {
      const startDate = parseInt(service.startDate, 10);
      const newEndDate = parseInt(serviceUpdatables.endDate, 10);

      if (newEndDate < startDate) {
        toast.error("You cannot set the end date earlier than the start date");
        return;
      }
    }

    const options = {
      variables: {
        projectSampleInput: {
          projectId: selectedProjectId,
          sampleId: selectedSampleId,
        },
        workflowInput: {
          workflowId: selectedWorkflowId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceUpdatables: {
          id: selectedServiceId,
          ...serviceUpdatables,
        },
      },
      update(cache: InMemoryCache, { data }: unknown): void {
        const incomingData = data?.["UpdateServiceFromWorkflow"];

        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleHere = samplesHere.find((s) => s.id === sampleId);

          const updatedEnabledWorkflows = sampleHere.enabledWorkflows.map(
            (workflow) => ({
              ...workflow,
              services: updateServices(workflow.services, incomingData),
            })
          );

          const newSamples = samplesHere.map((sampleLoop) => {
            if (sampleLoop.id === sampleId) {
              return {
                ...sampleLoop,
                enabledWorkflows: updatedEnabledWorkflows,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            variables: { id: projectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: newSamples,
              },
            },
          });
        }
      },
    };

    const selectedEntityNewObj: SelectedEntity = {
      ...selectedEntity,
      selectedElementPath: {
        ...selectedEntity.selectedElementPath,
        service: {
          ...selectedEntity.selectedElementPath.service,
          ...serviceUpdatables,
        },
      },
    };
    setSelectedEntity(selectedEntityNewObj);

    await updateServiceFromWorkflow(options);
  }

  const [updateEventFromSample] = useUpdateEventFromSampleMutation();

  async function updateEvent(eventUpdatables: EventUpdatables): void {
    const {
      projectId: selectedProjectId,
      sampleId: selectedSampleId,
      event: { id: selectedEventId },
    } = selectedEntity.selectedElementPath;

    const options = {
      variables: {
        projectSampleEventInput: {
          projectId: selectedProjectId,
          sampleId: selectedSampleId,
          eventId: selectedEventId,
        },
        eventUpdatables: {
          ...eventUpdatables,
        },
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["UpdateEventFromSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: selectedProjectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleHere = samplesHere.find((s) => s.id === selectedSampleId);

          const updatedEvents = sampleHere?.events.map((event) => {
            if (event.id === selectedEventId) {
              return incomingData;
            }
            return event;
          });

          const newSamples = samplesHere.map((sampleLoop) => {
            if (sampleLoop.id === selectedSampleId) {
              return {
                ...sampleLoop,
                events: updatedEvents,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            variables: { id: selectedProjectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: newSamples,
              },
            },
          });
        }
      },
    };

    const selectedEntityNewObj = { ...selectedEntity, ...eventUpdatables };
    setSelectedEntity(selectedEntityNewObj);

    await updateEventFromSample(options);
  }

  function getBaselineFromYPos(yPos: number): number | null {
    let closestDistance = 10000000000;
    let selectedBaseline = null;
    for (let baseline = -20; baseline < 20; baseline += 1) {
      const currentBaselineY = getYValueForBaseline(baseline);
      const distanceFromThisBaseline = Math.abs(currentBaselineY - yPos);
      if (distanceFromThisBaseline < closestDistance) {
        closestDistance = distanceFromThisBaseline;
        selectedBaseline = baseline;
      }
    }
    return selectedBaseline;
  }

  const wrapperSvg = d3.select(timelineElementsGroupRef.current);

  function wrapperSvgMouseMove(): void {
    if (!elementOnDragAction) return;

    const xDate = x.invert(d3.mouse(this)[0]);
    const closestBaseline = getBaselineFromYPos(d3.mouse(this)[1]);

    // eslint-disable-next-line func-names
    baselines.each(function () {
      const baseline = d3.select(this).attr("stroke", "#c0c0c0");
      const baselineDataId = baseline.attr("data-baseline");

      if (baselineDataId === closestBaseline.toString()) {
        baseline.attr("stroke", "#3B79D1");
      }
    });

    setDropPlace({
      date: xDate,
      baseline: closestBaseline,
      yPosition: d3.mouse(this)[1] * currentTransform.k + currentTransform.y,
    });
    setDroppedElementCoordinates({ date: xDate, baseline: closestBaseline });
  }

  function wrapperSvgMouseLeave(): void {
    if (!elementOnDragAction) return;

    if (dropPlace) {
      setDropPlace(null);
      clearBaselines(baselines);
    }
  }

  wrapperSvg.on("mousemove", wrapperSvgMouseMove);
  wrapperSvg.on("mouseleave", wrapperSvgMouseLeave);

  let dragStartDateValue = null;
  let blockStartDateBeforeDragging = null;
  let blockEndDateBeforeDragging = null;

  function blockDragStart(): void {
    const blockId = d3.select(this).attr("id");
    const blockClassName = d3.select(this).attr("class");

    const serviceBlockDragged =
      selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath);

    const wrongBlockDragged =
      selectedEntity &&
      serviceBlockDragged &&
      blockId !== selectedEntity.selectedElementPath.service.id &&
      blockClassName !== "leftDragElement" &&
      blockClassName !== "rightDragElement";

    const isNotGhostBlock =
      selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.sampleId ===
        selectedEntity.selectedElementPath.originPathId;

    const canMove =
      selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.service.status ===
        EntityStatus.Pending;

    if (
      selectedEntity &&
      serviceBlockDragged &&
      !wrongBlockDragged &&
      isNotGhostBlock &&
      canMove
    ) {
      showBaselines();
      d3.select("body").style("cursor", "row-resize");
      const mousePosition = d3.mouse(this)[0];
      const draggedToDate2 = x.invert(mousePosition);

      dragStartDateValue = draggedToDate2.valueOf();
      blockStartDateBeforeDragging = parseInt(
        selectedEntity.selectedElementPath.service.startDate,
        10
      );
      blockEndDateBeforeDragging = parseInt(
        selectedEntity.selectedElementPath.service.endDate,
        10
      );
      const mouseYpos = d3.event.y;
      const closestBaseline = getBaselineFromYPos(mouseYpos);
      setSelectedElementD3Data((prev) => {
        const newData = { ...prev };
        newData.isDragged = true;
        newData.mousePosition = mouseYpos;
        newData.mouseYDragStartPos = mouseYpos;
        newData.previousBaseline = closestBaseline;
        return newData;
      });
    }
  }

  function eventDragStart(): void {
    const eventId = d3.select(this).attr("id");

    const eventBlockDragged =
      selectedEntity &&
      selectedEntity.selectedElementType === SelectedElementType.EVENT;

    const wrongBlockDragged =
      selectedEntity &&
      eventBlockDragged &&
      eventId !== selectedEntity.selectedElementPath.event.id;

    const isNotGhostBlock =
      selectedEntity &&
      isSelectedEventPath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.sampleId ===
        selectedEntity.selectedElementPath.originPathId;

    if (
      selectedEntity &&
      eventBlockDragged &&
      !wrongBlockDragged &&
      isNotGhostBlock
    ) {
      showBaselines();
      d3.select("body").style("cursor", "row-resize");
      const mousePosition = d3.event.x;
      const draggedToDate2 = x.invert(mousePosition);
      dragStartDateValue = draggedToDate2.valueOf();
      blockStartDateBeforeDragging = parseInt(
        selectedEntity.selectedElementPath.event.startDate,
        10
      );
      blockEndDateBeforeDragging = parseInt(
        selectedEntity.selectedElementPath.event.endDate,
        10
      );
      const mouseYpos = d3.event.y;
      const closestBaseline = getBaselineFromYPos(mouseYpos);
      setSelectedElementD3Data((prev) => {
        const newData = { ...prev };
        newData.isDragged = true;
        newData.mousePosition = mouseYpos;
        newData.mouseYDragStartPos = mouseYpos;
        newData.previousBaseline = closestBaseline;
        return newData;
      });
    }
  }

  function dateDragStart(): void {
    d3.select("body").style("cursor", "col-resize");
  }

  function dragMoveEndDate(): void {
    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf().toString();
    const selectedEntityNewObj = { ...selectedEntity };
    selectedEntityNewObj.selectedElementPath.service.endDate = newDate;
    setSelectedEntity(selectedEntityNewObj);
  }

  function dragMoveStartDate(): void {
    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf().toString();
    const selectedEntityNewObj = { ...selectedEntity };
    selectedEntityNewObj.selectedElementPath.service.startDate = newDate;
    setSelectedEntity(selectedEntityNewObj);
  }

  function dragMoveStartAndEndDate(): void {
    if (
      !selectedEntity ||
      !blockStartDateBeforeDragging ||
      !blockEndDateBeforeDragging ||
      !dragStartDateValue
    ) {
      return;
    }

    const mouseYpos = d3.event.y;
    setSelectedElementD3Data((prev) => {
      const newData = { ...prev };
      newData.mousePosition = mouseYpos;
      return newData;
    });

    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf();
    const selectedEntityNewObj = { ...selectedEntity };

    const dragDelta = newDate - dragStartDateValue;

    selectedEntityNewObj.selectedElementPath.service.startDate = (
      blockStartDateBeforeDragging + dragDelta
    ).toString();
    selectedEntityNewObj.selectedElementPath.service.endDate = (
      blockEndDateBeforeDragging + dragDelta
    ).toString();
    setSelectedEntity(selectedEntityNewObj);

    // TODO make the whole block being dragged https://stackoverflow.com/questions/16294214/making-a-svg-text-draggable-using-d3-js
    // const blockDragElement = d3.selectAll(".BlockHeader");
    // transX += d3.event.dx;
    // transY += d3.event.dy;
    // blockDragElement.attr("transform", `translate(${transX}, ${transY})`);
  }

  function dragMoveStartAndEndDateEvent(): void {
    if (
      !selectedEntity ||
      !blockStartDateBeforeDragging ||
      !blockEndDateBeforeDragging ||
      !dragStartDateValue
    ) {
      return;
    }
    const mouseYpos = d3.event.y;
    setSelectedElementD3Data((prev) => {
      const newData = { ...prev };
      newData.mousePosition = mouseYpos;
      return newData;
    });

    const mousePosition = d3.event.x;
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf();
    const selectedEntityNewObj = { ...selectedEntity };

    const dragDelta = newDate - dragStartDateValue;
    selectedEntityNewObj.selectedElementPath.event.startDate = (
      blockStartDateBeforeDragging + dragDelta
    ).toString();
    selectedEntityNewObj.selectedElementPath.event.endDate = (
      blockEndDateBeforeDragging + dragDelta
    ).toString();
    setSelectedEntity(selectedEntityNewObj);
  }

  function dragEndLeft(): void {
    d3.select("body").style("cursor", "default");
    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf().toString();
    updateService({ startDate: newDate });
  }

  function dragEndRight(): void {
    d3.select("body").style("cursor", "default");
    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf().toString();
    updateService({ endDate: newDate });
  }

  function dragEndBlock(): void {
    const isNotGhostBlock =
      selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.sampleId ===
        selectedEntity.selectedElementPath.originPathId;

    const canMove =
      selectedEntity &&
      isSelectedServicePath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.service.status ===
        EntityStatus.Pending;

    if (!isNotGhostBlock || !canMove) return;

    hideBaselines();
    d3.select("body").style("cursor", "default");

    const blockId = d3.select(this).attr("id");
    const blockClassName = d3.select(this).attr("class");

    const serviceBlockDragged =
      selectedEntity &&
      selectedEntity.selectedElementType === SelectedElementType.SERVICE;

    const wrongBlockDragged =
      selectedEntity &&
      serviceBlockDragged &&
      blockId !== selectedEntity.selectedElementPath.service.id &&
      blockClassName !== "leftDragElement" &&
      blockClassName !== "rightDragElement";

    // d3.mouse(this)[1] and d3.event.y have both the same value here
    const mouseYpos = d3.event.y;

    if (!selectedEntity || wrongBlockDragged || !serviceBlockDragged) {
      return;
    }

    setSelectedElementD3Data((prev) => {
      const newData = { ...prev };
      newData.isDragged = false;
      newData.mouseYDragStartPos = null;
      newData.mousePosition = null;
      newData.previousBaseline = null;
      return newData;
    });

    const mousePosition = d3.mouse(this)[0];
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf();
    const selectedEntityNewObj = { ...selectedEntity };

    const dragDelta = newDate - dragStartDateValue;
    const newStartDate = (blockStartDateBeforeDragging + dragDelta).toString();

    const newEndDate = (blockEndDateBeforeDragging + dragDelta).toString();

    const closestBaseline = getBaselineFromYPos(mouseYpos);

    selectedEntityNewObj.selectedElementPath.service.startDate = newStartDate;
    selectedEntityNewObj.selectedElementPath.service.endDate = newEndDate;
    selectedEntityNewObj.selectedElementPath.service.baseLine = closestBaseline;
    setSelectedEntity(selectedEntityNewObj);

    updateService({
      baseLine: closestBaseline,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }

  function dragEndEvent(): void {
    const isNotGhostBlock =
      selectedEntity &&
      isSelectedEventPath(selectedEntity.selectedElementPath) &&
      selectedEntity.selectedElementPath.sampleId ===
        selectedEntity.selectedElementPath.originPathId;

    if (!isNotGhostBlock) return;

    hideBaselines();
    d3.select("body").style("cursor", "default");

    const eventId = d3.select(this).attr("id");

    setSelectedElementD3Data((prev) => {
      const newData = { ...prev };
      newData.isDragged = false;
      newData.mouseYDragStartPos = null;
      newData.mousePosition = null;
      newData.previousBaseline = null;
      return newData;
    });

    const eventBlockDragged =
      selectedEntity &&
      selectedEntity.selectedElementType === SelectedElementType.EVENT;

    const wrongBlockDragged =
      selectedEntity &&
      eventBlockDragged &&
      eventId !== selectedEntity.selectedElementPath.event.id;

    // d3.mouse(this)[1] and d3.event.y have both the same value here
    const mouseYpos = d3.event.y;

    if (!selectedEntity || wrongBlockDragged || !eventBlockDragged) {
      return;
    }

    const mousePosition = d3.event.x;
    const draggedToDate2 = x.invert(mousePosition);
    const newDate = draggedToDate2.valueOf();
    const selectedEntityNewObj = { ...selectedEntity };

    const dragDelta = newDate - dragStartDateValue;
    const newStartDate = (blockStartDateBeforeDragging + dragDelta).toString();
    const newEndDate = (blockEndDateBeforeDragging + dragDelta).toString();
    selectedEntityNewObj.selectedElementPath.event.startDate = newStartDate;
    selectedEntityNewObj.selectedElementPath.event.endDate = newEndDate;
    setSelectedEntity(selectedEntityNewObj);

    const closestBaseline = getBaselineFromYPos(mouseYpos);
    updateEvent({
      startDate: newStartDate,
      endDate: newEndDate,
      baseLine: closestBaseline,
    });
  }

  if (!disableDnDToUse) {
    const leftDragHandler = d3
      .drag()
      .on("start", dateDragStart)
      .on("drag", dragMoveStartDate)
      .on("end", dragEndLeft);
    const rightDragHandler = d3
      .drag()
      .on("start", dateDragStart)
      .on("drag", dragMoveEndDate)
      .on("end", dragEndRight);
    const blockDragHandler = d3
      .drag()
      .on("start", blockDragStart)
      .on("drag", dragMoveStartAndEndDate)
      .on("end", dragEndBlock);
    const eventDragHandler = d3
      .drag()
      .on("start", eventDragStart)
      .on("drag", dragMoveStartAndEndDateEvent)
      .on("end", dragEndEvent);
    const leftDragElement = d3.selectAll(".leftDragElement");
    const rightDragElement = d3.selectAll(".rightDragElement");
    const blockDragElement = d3.selectAll(".BlockHeader");
    const eventDragElement = d3.selectAll(".EventDragElement");

    const selectedEntityNewObj = { ...selectedEntity };

    eventDragHandler(eventDragElement);

    const isServiceSelected =
      selectedEntity && selectedEntity.selectedElementType === "service";

    if (isServiceSelected) {
      blockDragHandler(blockDragElement);

      if (selectedEntityNewObj.selectedElementPath.service.durationLocked) {
        blockDragHandler(leftDragElement);
        blockDragHandler(rightDragElement);
      } else {
        leftDragHandler(leftDragElement);
        rightDragHandler(rightDragElement);
      }
    }
  }

  const todayLineX = x(Date.now()) * currentTransform.k + currentTransform.x;
  const todayX = x(Date.now());
  const dropLineX = dropPlace ? x(dropPlace.date) : null;

  useEffect(() => {
    draw();
  }, [axisWidth, height, width, sample.id, zoomLevel]);

  const getEventBlock = (
    event: Event,
    ghostEvents?: GhostTimelineEvents
  ): JSX.Element => {
    const isSelected =
      selectedEntity &&
      selectedEntity.selectedElementType === "event" &&
      selectedEntity.selectedElementPath.event.id === event.id;

    const ownScale = getOwnScale(currentTransform.k);

    const selectedEventPositionY =
      baselineY -
      event.baseLine * baselineHeight +
      (selectedElementD3Data.mousePosition -
        selectedElementD3Data.mouseYDragStartPos);

    const unselectedEventPositionY =
      baselineY - event.baseLine * baselineHeight;

    const eventPositionY =
      isSelected && selectedElementD3Data.isDragged
        ? selectedEventPositionY
        : unselectedEventPositionY;

    const totalHeight = 108 * ownScale;

    const theMiddleOfLine =
      totalHeight / 2 - getCurrentSize(20, currentTransform.k) / 2;

    const eventPositionX = isSelected
      ? x(selectedEntity.selectedElementPath.event.startDate)
      : x(event.startDate);

    const eventWidth = Math.max(x(event.endDate) - x(event.startDate), 10);

    let newColor = styledTheme.newColors.grayscale.primaryGray;
    let newEventName = event.name;
    let originPathId = sample.id;
    let eventDisableDndToUse = disableDnDToUse;

    if (ghostEvents) {
      newColor = addAlphaToColor(
        styledTheme.newColors.grayscale.primaryGray,
        0.5
      );
      newEventName = `${ghostEvents.sampleName} - ${event.name}`;
      originPathId = ghostEvents.sampleId;
      eventDisableDndToUse = true;
    }

    const isVisibleOnAllPaths =
      props.ghostTimelineElements?.ghostEvents.some(
        (ghostEvent) =>
          ghostEvent.sampleId === sample.id &&
          ghostEvent.events.some((ghEvent) => ghEvent.id === event.id)
      ) || false;

    const updatedEvent = {
      ...event,
      name: newEventName,
    };

    const theMiddleOfXLine = getCurrentSize(20, currentTransform.k) / 2;

    return (
      <EventBlock
        {...updatedEvent}
        y={eventPositionY + theMiddleOfLine}
        x={eventPositionX - theMiddleOfXLine}
        width={eventWidth}
        currentTransform={currentTransform}
        key={updatedEvent.id}
        projectId={projectId}
        sampleId={sample.id}
        disableDnD={eventDisableDndToUse}
        color={newColor}
        visibleOnAllPaths={isVisibleOnAllPaths}
        originPathId={originPathId}
      />
    );
  };

  const getServiceBlock = (
    service: Service,
    ghostServices?: GhostTimelineServices
  ): JSX.Element => {
    const isSelected =
      selectedEntity &&
      selectedEntity.selectedElementType === "service" &&
      selectedEntity.selectedElementPath.service.id === service.id;

    const selectedServicePositionY =
      baselineY -
      baselineHeight * service.baseLine +
      (selectedElementD3Data.mousePosition -
        selectedElementD3Data.mouseYDragStartPos);

    const unselectedServicePositionY =
      baselineY - baselineHeight * service.baseLine;

    const servicePositionY =
      isSelected && selectedElementD3Data.isDragged
        ? selectedServicePositionY
        : unselectedServicePositionY;

    const servicePositionX = isSelected
      ? x(selectedEntity.selectedElementPath.service.startDate)
      : x(service.startDate);

    const serviceWidth = isSelected
      ? Math.max(
          x(selectedEntity.selectedElementPath.service.endDate) -
            x(selectedEntity.selectedElementPath.service.startDate),
          1
        )
      : Math.max(x(service.endDate) - x(service.startDate), 1);

    let newColor = service.color;
    let newWorkflowName = service.workflowName;
    let originPathId = sample.id;
    let serviceDisableDndToUse = disableDnDToUse;

    if (ghostServices) {
      newColor = addAlphaToColor(
        getColorFromIndex(ghostServices.workflowIndex),
        0.5
      );
      newWorkflowName = `${ghostServices.sampleName} - ${ghostServices.workflowName}`;
      originPathId = ghostServices.sampleId;
      serviceDisableDndToUse = true;
    }

    if (service.status !== EntityStatus.Pending) {
      serviceDisableDndToUse = true;
    }

    const isVisibleOnAllPaths =
      props.ghostTimelineElements?.ghostServices.some(
        (ghostService) =>
          ghostService.sampleId === sample.id &&
          ghostService.services.some((vs) => vs.id === service.id)
      ) || false;

    return (
      <ServiceBlock
        baselineHeight={baselineHeight}
        projectId={projectId}
        sampleId={sample.id}
        {...service}
        y={servicePositionY}
        x={servicePositionX}
        width={serviceWidth}
        currentTransform={currentTransform}
        key={service.id}
        workflowName={newWorkflowName}
        workflowId={service.workflowId}
        color={newColor}
        disableDnD={serviceDisableDndToUse}
        visibleOnAllPaths={isVisibleOnAllPaths}
        originPathId={originPathId}
        todayX={todayX}
      />
    );
  };

  const getEventLine = (event: Event): JSX.Element => {
    const isSelected =
      selectedEntity &&
      selectedEntity.selectedElementType === "event" &&
      selectedEntity.selectedElementPath.event.id === event.id;

    const eventPositionX = isSelected
      ? x(selectedEntity.selectedElementPath.event.startDate)
      : x(event.startDate);

    if (!event.milestone) return <></>;

    return (
      <EventLine
        x={eventPositionX * currentTransform.k + currentTransform.x}
        height={height}
        key={event.id}
      />
    );
  };

  return (
    <TimelineContainer
      ref={ref}
      width={props.width}
      height={props.height}
      className={props.className}
    >
      {axisWidth && (
        <StyledSVG
          id={svgId}
          ref={wrapperSVGRef}
          style={{
            backgroundColor: "#e8eaee",
          }}
        >
          {events
            .filter((event) => event.milestone)
            .map((event) => getEventLine(event))}
          {props.ghostTimelineElements?.ghostEvents
            .filter((ghostEvent) => ghostEvent.sampleId !== sample.id)
            .filter((ghostEvent) =>
              ghostEvent.events.filter((ghEvent) => ghEvent.milestone)
            )
            .map((ghostEvent) =>
              ghostEvent.events.map((ghEvent) => getEventLine(ghEvent))
            )}
          <TodayLine x={todayLineX} height={height} />
          {elementOnDragAction && dropPlace && dropLineX && (
            <>
              <text
                x={dropLineX * currentTransform.k + currentTransform.x + 20}
                y={
                  dropPlace.yPosition -
                  (elementOnDragAction.type === "event" ? 40 : 20)
                }
              >
                {dropPlace.date.toISOString().split("T")[0]}
              </text>
              <DropLine
                x={dropLineX * currentTransform.k + currentTransform.x}
                height={height}
              />
            </>
          )}
          <g
            id="timeline"
            width={width}
            height={height}
            ref={timelineElementsGroupRef}
          >
            <rect width={width * 100} height={height} fillOpacity="0" />
            {drawLines()}
            {drawBaselineLines()}

            {zoomLevel === ZOOM_LEVELS.DEFAULT &&
              props.ghostTimelineElements?.ghostServices
                .filter((ghostService) => ghostService.sampleId !== sample.id)
                .map((ghostService) =>
                  ghostService.services.map((ghService) =>
                    getServiceBlock(ghService, ghostService)
                  )
                )}
            {zoomLevel === ZOOM_LEVELS.DEFAULT &&
              services.map((service) => getServiceBlock(service))}

            {zoomLevel === ZOOM_LEVELS.DEFAULT &&
              props.ghostTimelineElements?.ghostEvents
                .filter((ghostEvent) => ghostEvent.sampleId !== sample.id)
                .map((ghostEvent) =>
                  ghostEvent.events.map((ghEvent) =>
                    getEventBlock(ghEvent, ghostEvent)
                  )
                )}
            {zoomLevel === ZOOM_LEVELS.DEFAULT &&
              events.map((event) => getEventBlock(event))}

            {zoomLevel === ZOOM_LEVELS.HIGHER &&
              workflows.map((workflow, workflowIndex) => {
                return (
                  <WorkflowBlock
                    {...workflow}
                    projectId={projectId}
                    sampleId={sample.id}
                    y={baselineY + workflowIndex * workflowsBaselineHeight}
                    x={x(getWorkflowRange(workflow).startDate)}
                    width={Math.max(
                      x(getWorkflowRange(workflow).endDate) -
                        x(getWorkflowRange(workflow).startDate),
                      20
                    )}
                    currentTransform={currentTransform}
                    key={workflow.id}
                    blockColor={getColorFromIndex(workflowIndex)}
                    disableDnD={disableDnDToUse}
                  />
                );
              })}

            {zoomLevel === ZOOM_LEVELS.DEFAULT &&
              drawChangeLineNamingDots(disableDnDToUse)}
          </g>
          <g
            id="axis"
            width={axisWidth}
            height={height}
            ref={axisWrapperGroupRef}
          />
          <Minimap
            services={services}
            axisWidth={axisWidth - 300}
            config={config}
            x={x}
            baselineY={baselineY}
            baselineHeight={baselineHeight}
            forwardedRef={svgMinimapRef}
            increaseTransform={(delta): void => {
              const center = [config._width / 2, config._height / 2];
              const newX =
                (currentTransform.x - center[0]) * (1 + delta) + center[0];
              const newY =
                (currentTransform.y - center[1]) * (1 + delta) + center[1];
              const newTransform = d3.zoomIdentity
                .translate(newX, newY)
                .scale(currentTransform.k + currentTransform.k * delta);
              d3.select(wrapperSVGRef.current).call(
                d3.zoom().transform,
                newTransform
              );
              zoomedFunction.current(newTransform);
            }}
            resetView={(): void => {
              const newTransform = d3.zoomIdentity.translate(0, 0).scale(1);
              d3.select(wrapperSVGRef.current).call(
                d3.zoom().transform,
                newTransform
              );
              zoomedFunction.current(newTransform);
            }}
            currentTransform={currentTransform}
          />
        </StyledSVG>
      )}
      {!hideAddIconToUse && (
        <AddIconContainer onClick={handleOnAddIconCLick}>
          <AddIcon height={24} width={24} />
        </AddIconContainer>
      )}
      {isChangePathLineNameModalOpen && (
        <ChangePathLineNameModal
          isOpen={isChangePathLineNameModalOpen}
          pathLineNameToChange={pathLineNameToChange}
          onClose={(): void => {
            setIsChangePathLineNameModalOpen(false);
            setPathLineNameToChange(null);
          }}
          projectId={projectId}
          sampleId={sample.id}
        />
      )}
    </TimelineContainer>
  );
};

const TimelineContainer = styled(Box)`
  width: ${({ width }): string => width || "100vw"};
  height: ${({ height }): string => height || "100vh"};
  position: relative;
  overflow: hidden;
`;

const StyledSVG = styled.svg`
  width: calc(100%);
  height: calc(100%);
  max-width: 100%;
  max-height: 100%;
  shape-rendering: crispEdges;
`;

const AddIconContainer = styled.div`
  width: 48px;
  height: 48px;
  background-color: ${({ theme }): string => theme.newColors.primary.basic};
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: ${({ theme }): string => theme.shadows.icons.expandIcon};
  transition: background-color linear 0.2s;

  :hover {
    background-color: ${({ theme }): string => theme.newColors.primary.hover};
    transition: background-color linear 0.2s;
  }

  :focus,
  :active {
    background-color: ${({ theme }): string =>
      theme.newColors.primary.darkestTint};
    transition: background-color linear 0.2s;
  }
`;
