import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const MinusIcon = ({
  width,
  height,
  color,
  ...props
}: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 8.75H17.5V11.25H2.5V8.75Z"
        fill={styledTheme.newColors.primary.basic}
      />
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    </svg>
  );
};
MinusIcon.defaultProps = {
  width: "20",
  height: "20",
  color: styledTheme.newColors.grayscale.white,
};
