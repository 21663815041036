/* eslint-disable react/prop-types */
import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { RouteComponentProps } from "@reach/router";

export const healthcheckQuery = gql`
  query MyQuery {
    healthcheck
  }
`;

export default (props: RouteComponentProps) => (
  <Query query={healthcheckQuery}>
    {(query: { loading: any; data: any }) => {
      const { loading, data } = query;
      if (loading) {
        return <h1>loading</h1>;
      }
      if (data && data.healthcheck) {
        return <h1>{data && data.healthcheck}</h1>;
      }
      return <h1>:(</h1>;
    }}
  </Query>
);
