import * as Yup from "yup";
import {
  AnswerType,
  Question,
  QuestionGroup,
} from "../../../generated/graphql";

function getValidationSchemaForQuestion(question: Question) {
  const choices = question.choices ? question.choices : [];
  let result;
  switch (question.answer.answerType) {
    case AnswerType.TextField:
    case AnswerType.TextArea:
    case AnswerType.Date:
    case AnswerType.Currency:
    case AnswerType.File:
    case AnswerType.Links:
      result = Yup.string();
      break;
    case AnswerType.Number:
      result = Yup.number();
      break;
    case AnswerType.OneOfMany:
      result = Yup.string().oneOf(choices.map((choice: string) => choice));
      break;
    case AnswerType.ManyOfMany:
      result = Yup.array<string>();
      break;
    default:
      throw new Error("Unsupported answer type");
  }
  if (question.isObligatory) {
    result = result.required(`${question.question} is required`);
  }
  return result;
}

export const getValidationSchemaFromQuestionGroup = (
  questionGroup: QuestionGroup
) => {
  const validationSchema: any = {};
  questionGroup.questionSets.forEach((questionSet) => {
    questionSet.questions.forEach((question) => {
      if (question.visibility) {
        validationSchema[question.id] =
          getValidationSchemaForQuestion(question);
      }
    });
  });

  return validationSchema;
};
