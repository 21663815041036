import * as React from "react";
import { RouteComponentProps } from "@reach/router";

export const SamplesList = (props: SamplesListProps) => {
  return <div>List of Samples for project -{props.projectId}</div>;
};

interface SamplesListProps extends RouteComponentProps {
  projectId?: string;
}
