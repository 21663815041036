import * as React from "react";
import styled from "styled-components";
import { Service } from "../../generated/graphql";
import { styledTheme } from "../../theme/theme";
import { getLengthInWeeksString } from "./helpers";
import { TextWrapped } from "./TextWrapped";

type ServiceBlockType = {
  width: number;
  height: number;
  x: number;
  y: number;
  baselineHeight?: number;
  showDetails?: boolean;
  currentTransform: {
    x: number;
    y: number;
    k: number;
  };
};

const blockHeight = 24;
const padding = 4;
enum Variant {
  CURRENT = "CURRENT",
  FUTURE = "FUTURE",
  PAST = "PAST",
}

const getBlockType = (service: Service) => {
  if (
    parseFloat(service.startDate) - 1000 * 60 * 60 * 24 * 10 >=
    new Date().valueOf()
  ) {
    return Variant.FUTURE;
  }
  if (parseFloat(service.endDate) < new Date().valueOf()) {
    return Variant.PAST;
  }
  return Variant.CURRENT;
};

const getBlockColor = (variant: Variant) => {
  switch (variant) {
    case Variant.FUTURE:
      return styledTheme.newColors.grayscale.white;
    case Variant.PAST:
      return styledTheme.newColors.grayscale.background;
    case Variant.CURRENT:
    default:
      return styledTheme.newColors.primary.basic;
  }
};

const getTextColor = (variant: Variant) => {
  switch (variant) {
    case Variant.CURRENT:
      return styledTheme.newColors.grayscale.white;
    case Variant.FUTURE:
      return styledTheme.colors.lightText;
    case Variant.PAST:
    default:
      return styledTheme.newColors.grayscale.primaryGray;
  }
};

export const ServiceBlockMini = ({
  currentTransform,
  ...service
}: ServiceBlockType & Service) => {
  const { width, endDate, startDate, x, y } = service;
  const blockType = getBlockType(service);
  const textColor = getTextColor(blockType);

  return (
    <G>
      <text className="tooltip" x={x + 5} y={y - 10}>
        {service.name}
      </text>
      <rect
        height={blockHeight}
        width={width}
        x={x}
        y={y}
        rx={8}
        fill={getBlockColor(blockType)}
        {...(blockType === Variant.FUTURE
          ? {
              stroke: styledTheme.colors.athensGray,
              strokeWidth: "1.5",
              strokeDasharray: "8, 10",
            }
          : null)}
      />
      <TextWrapped
        fill={textColor}
        fontSize={10}
        x={x + padding}
        y={y + blockHeight / 2 + padding}
        text={service.name}
        maxWidth={Math.max(width - 2 * padding - 13, 20)}
      />
      <text
        fill={textColor}
        fontSize={10}
        x={x + width - padding}
        y={y + blockHeight / 2 + padding}
        textAnchor="end"
        fontWeight="bold"
      >
        {getLengthInWeeksString(startDate, endDate)}
      </text>
    </G>
  );
};

const G = styled.g`
  .tooltip {
    visibility: hidden;
  }
  :hover .tooltip {
    visibility: visible;
  }
`;

ServiceBlockMini.defaultProps = {
  ownScale: 1,
  width: 220,
  height: 100,
  x: 100,
  y: 100,
};
