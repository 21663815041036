import * as React from "react";
import styled, { css } from "styled-components";
import { useState } from "react";
import { ServiceCardTag } from "./ServiceCardTag";
import { BasicServiceCard, BasicServiceCardProps } from "./BasicServiceCard";
import {
  ServicePackAction,
  WorkflowMetadata,
} from "../../../utils/types/WorkflowMetadata";

type Props = BasicServiceCardProps & TagProp & OnActionProp;

type TagProp = {
  tag?: ServiceCardTag;
};

type OnActionProp = {
  onAction: ServicePackAction;
};

const getWithTag = (
  card: React.ReactElement,
  selected: boolean,
  tag?: ServiceCardTag
) => {
  const realTag = selected ? ServiceCardTag.Added : tag;

  if (!realTag) {
    return card;
  }

  return (
    <Container tag={realTag}>
      <Header>{realTag}</Header>
      {card}
    </Container>
  );
};

export const ServiceCard = ({ tag, ...props }: Props) => {
  const [selected, setSelected] = useState(props.selected || false);

  const onClick = (metaData: WorkflowMetadata) => {
    if (props.onAction) {
      setSelected(!selected);
      props.onAction(metaData, selected);
    }
  };

  return getWithTag(
    <BasicServiceCard {...props} selected={selected} onClick={onClick} />,
    selected,
    tag
  );
};

const Header = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: ${(props) => props.theme.newColors.grayscale.white};
  align-self: center;
  padding: 4px;
`;

const Container = styled.div<TagProp>`
  ${(props) => {
    switch (props.tag) {
      case ServiceCardTag.Recommended:
        return css`
          background: ${props.theme.newColors.grayscale.primaryGray};
          border-radius: 4px;
          height: fit-content;
          & > div:not(:first-child) {
            border: 1px solid ${props.theme.newColors.grayscale.primaryGray};
          }
        `;
      case ServiceCardTag.Suggested:
        return css`
          background: ${props.theme.newColors.grayscale.secondaryGray};
          border-radius: 4px;
          height: fit-content;
          & > div:not(:first-child) {
            border: 1px solid ${props.theme.newColors.grayscale.secondaryGray};
          }
        `;
      case ServiceCardTag.Added:
        return css`
          background: ${props.theme.newColors.primary.basic};
          border-radius: 4px;
          height: fit-content;
          & > div:not(:first-child) {
            border: 1px solid ${props.theme.newColors.primary.basic};
          }
        `;
      default:
        return ``;
    }
  }}
`;
