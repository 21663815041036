import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { navigate, useMatch } from "@reach/router";
import Block from "./Block";
import { EntityStatus, ServiceForCustomer } from "../../generated/graphql";
import {
  DETAIL_GROUPS,
  DetailGroup,
  DetailsHeader,
  getDetailGroupsHeight,
} from "./ServiceBlock.Details";
import { useSideBarsContext } from "../../utils/hooks/useSideBarsState";
import { useActionContext } from "../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedServicePath,
} from "../../utils/types/ActionContextTypes";
import { ROUTES } from "../../constants/routeConstants";
import { getOwnScale, getZoomedInLevel } from "./axisHelpers";
import { checkIfServiceIsInLockedState } from "../../utils/helpers/checkIfServiceIsInLockedState";

type ServiceBlockType = {
  width: number;
  height: number;
  x: number;
  y: number;
  baselineHeight?: number;
  showDetails?: boolean;
  projectId: string;
  sampleId: string;
  currentTransform: {
    x: number;
    y: number;
    k: number;
  };
  workflowName: string;
  workflowId: string;
  color: string;
  disableDnD: boolean;
  visibleOnAllPaths?: boolean;
  originPathId?: string;
  todayX?: number;
};

export const ServiceBlock = ({
  currentTransform,
  projectId,
  sampleId,
  workflowName,
  workflowId,
  color,
  disableDnD,
  visibleOnAllPaths,
  originPathId,
  todayX,
  ...service
}: ServiceBlockType & ServiceForCustomer): JSX.Element => {
  const matchProjectPath = useMatch(ROUTES.PROJECT_TIMELINE_WILDCARD);

  let reports: [{ name: string; link?: string }] = [
    { name: "Not yet available" },
  ];
  if (service.status === EntityStatus.Finished) {
    reports = [
      { name: "Go to reports", link: `/reports/${projectId}/${sampleId}` },
    ];
  }
  // todo get from props
  const detailGroups = [
    {
      name: DETAIL_GROUPS.DATES,
      items: [
        {
          name: "Start date",
          value: moment(new Date(parseInt(service.startDate, 10))).format("L"),
        },
        {
          name: "End date",
          value: moment(new Date(parseInt(service.endDate, 10))).format("L"),
        },
      ],
    },
    {
      name: DETAIL_GROUPS.REPORTS,
      items: reports,
    },
    {
      name: DETAIL_GROUPS.MEMOS,
      items: [],
    },
    {
      name: DETAIL_GROUPS.RESOURCES,
      items: [],
    },
  ] as DetailGroup[];

  const zoomedInLevel = getZoomedInLevel(currentTransform.k);

  const detailHeaderHeight = 20 / zoomedInLevel;

  const filteredDetailGroupsWithHeights = detailGroups
    .filter((g) => g?.items && g.items.length > 0)
    .map((g) => ({
      ...g,
      height: getDetailGroupsHeight([g], detailHeaderHeight),
    })) as DetailGroup[];
  const detailGroupsHeight = getDetailGroupsHeight(
    filteredDetailGroupsWithHeights,
    detailHeaderHeight
  );

  const selectedElementPath: SelectedServicePath = {
    projectId,
    sampleId,
    workflowName,
    workflowId,
    service,
    originPathId,
    visibleOnAllPaths,
  };

  const { setSideDrawboardOpen } = useSideBarsContext();
  const { setSelectedEntity, selectedEntity } = useActionContext();
  const [serviceIsSelected, setServiceIsSelected] = useState(false);

  const handleOnBlockClick = (): void => {
    setSideDrawboardOpen(true);
    setSelectedEntity({
      selectedElementType: SelectedElementType.SERVICE,
      selectedElementPath,
    });

    if (matchProjectPath && !workflowId) {
      navigate(`/projects/${projectId}/timeline/samples/${sampleId}`);
      return;
    }

    if (matchProjectPath) {
      navigate(
        `/projects/${projectId}/timeline/samples/${sampleId}/workflows/${workflowId}/services/${service.id}`
      );
    }
  };

  useEffect(() => {
    const isSelected = selectedEntity
      ? (selectedEntity?.selectedElementPath as SelectedServicePath).service
          ?.id === selectedElementPath.service?.id
      : false;

    setServiceIsSelected(isSelected);
  }, [selectedEntity]);

  const [showDetails, setShowDetails] = useState(!!service.showDetails);
  const ownScale = getOwnScale(currentTransform.k);
  const { width, endDate, startDate } = service;
  const minWidth = 2 * ownScale;
  const widthWithDetailsOpened =
    showDetails && width < minWidth ? minWidth : width;
  const headerHeight = 40 * ownScale;
  const lengthInMs = parseInt(endDate, 10) - parseInt(startDate, 10);
  const lengthInWeeks = lengthInMs / (1000 * 60 * 60 * 24 * 7);
  const lengthInDays = lengthInMs / (1000 * 60 * 60 * 24);
  const lockIcon = checkIfServiceIsInLockedState(service.status) ? "🔒" : "";
  const lengthString =
    lockIcon +
    (lengthInWeeks > 2
      ? `${Math.ceil(lengthInWeeks)}w`
      : `${Math.ceil(lengthInDays)}d`);
  const nowTimestamp = Date.now();
  const serviceIsDelayed =
    service.status === EntityStatus.Ongoing &&
    parseInt(service.endDate, 10) < nowTimestamp;

  return (
    <Block
      blockId={service.id}
      headerHeight={headerHeight}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      length={lengthString}
      {...service}
      width={widthWithDetailsOpened}
      height={service.height + (showDetails ? detailGroupsHeight : 0)}
      ownScale={ownScale}
      showTitleOnHover
      showDetails={showDetails}
      onBlockClick={handleOnBlockClick}
      selected={serviceIsSelected}
      blockColor={color}
      disableDnD={disableDnD}
      serviceIsDelayed={serviceIsDelayed}
      todayX={todayX}
    >
      <DetailsHeader
        setShowDetails={setShowDetails}
        showDetails={showDetails}
        ownScale={ownScale}
        width={widthWithDetailsOpened}
        zoomedInLevel={zoomedInLevel}
      />
      {/* {showDetails ? ( */}
      {/*  <Details */}
      {/*    width={widthWithDetailsOpened} */}
      {/*    ownScale={ownScale} */}
      {/*    zoomedInLevel={zoomedInLevel} */}
      {/*    detailGroups={filteredDetailGroupsWithHeights} */}
      {/*    height={detailGroupsHeight} */}
      {/*    detailHeaderHeight={detailHeaderHeight} */}
      {/*  /> */}
      {/* ) : // <ServiceInfoIcons y={headerHeight} width={width} ownScale={ownScale} /> */}
      {/* null} */}
    </Block>
  );
};

ServiceBlock.defaultProps = {
  ownScale: 1,
  width: 220,
  height: 100,
  x: 100,
  y: 100,
};
