import * as React from "react";
import { Box, Heading, Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";

export type ContentSectionProps = {
  title: string;
  stepNumber?: number;
  children?: React.ReactNode;
  stepIcon?: React.ReactNode;
  wrapped?: boolean;
  buttons?: React.ReactNode[];
  propertyValue?: string;
  wrapCardsCallback: () => void;
  fullWidth?: boolean;
};

export const ContentSection = ({
  title,
  stepNumber = 1,
  children,
  wrapped = false,
  stepIcon,
  buttons,
  propertyValue,
  wrapCardsCallback,
  fullWidth,
}: ContentSectionProps) => (
  <StyledContentSection
    wrapped={wrapped}
    fullWidth={fullWidth}
    onClick={wrapCardsCallback}
  >
    <StyledHeading wrapped={wrapped}>
      <Step wrapped={wrapped}>{stepIcon || stepNumber}</Step>
      <Title wrapped={wrapped}>{title}</Title>
      {!wrapped && buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
      {wrapped && propertyValue !== undefined && (
        <PropertyValueContainer>{propertyValue}</PropertyValueContainer>
      )}
    </StyledHeading>
    {!wrapped && <StyledChildren>{children}</StyledChildren>}
  </StyledContentSection>
);

const PropertyValueContainer = styled.div`
  color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.01em;
`;

const ButtonsContainer = styled.div`
  display: flex;

  button {
    margin-left: 24px;
  }
`;

const StyledContentSection = styled(Box)<{
  wrapped: boolean;
  fullWidth?: boolean;
}>`
  border-radius: ${({ theme }) => theme.radius.default};
  background-color: ${({ theme }) => theme.newColors.grayscale.white};
  margin: 10px 0 44px 0;
  box-shadow: ${({ theme }) => theme.shadows.dropShadows.formCard};
  border: 1px solid ${({ theme }) => theme.newColors.grayscale.bordersInside};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  &:not(:last-of-type) {
    :after {
      position: relative;
      bottom: -${({ theme }) => theme.space[4]};
      left: ${({ theme }) => theme.space[3]};
      height: 10px;
      width: 10px;
      content: url("data:image/svg+xml,%3Csvg width='4' height='20' viewBox='0 0 4 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='18' r='2' fill='%23C5C6CB'/%3E%3Ccircle cx='2' cy='10' r='2' fill='%23C5C6CB'/%3E%3Ccircle cx='2' cy='2' r='2' fill='%23C5C6CB'/%3E%3C/svg%3E");
    }
  }

  &:not(:last-of-type) {
    & > div:last-of-type {
      ${({ wrapped }) =>
        wrapped &&
        css`
          margin-bottom: -20px;
        `};
    }
  }
`;

const StyledHeading = styled(Flex)<{ wrapped?: boolean }>`
  height: 56px;
  background: ${({ theme, wrapped }) =>
    wrapped
      ? theme.newColors.grayscale.white
      : theme.newColors.grayscale.primaryGray};
  border-radius: ${({ theme, wrapped }) =>
    wrapped
      ? `${theme.radius.default}`
      : `${theme.radius.default} ${theme.radius.default} 0 0`};
  align-items: center;
  padding: ${({ theme }) => theme.space[3]};
  justify-content: space-between;
`;

const Step = styled(Flex)<{ wrapped?: boolean }>`
  padding-top: 2px;
  margin-right: ${({ theme }) => theme.space[3]};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.newColors.grayscale.white};
  width: 24px;
  height: 24px;
  border: ${({ theme, wrapped }) =>
    wrapped ? `2px solid ${theme.newColors.grayscale.bordersInside}` : "none"};

  font-size: 14px;
  line-height: 24px;
`;

const Title = styled(Heading)<{ wrapped?: boolean }>`
  margin-top: 3px;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: 24px;
  color: ${({ theme }) => theme.newColors.grayscale.white};
  color: ${({ theme, wrapped }) =>
    wrapped
      ? theme.newColors.grayscale.primaryGray
      : theme.newColors.grayscale.white};
  flex-grow: 1;
`;

const StyledChildren = styled(Box)`
  padding: 27px 36px 27px 56px;
`;
