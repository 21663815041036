import * as React from "react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { cloneDeep } from "lodash";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedOneOfManyPath,
  SelectedReportQuestionPath,
} from "../../../../../utils/types/ActionContextTypes";
import {
  UserProjectDocument,
  useUpdateQuestionInQuestionGroupFromSampleMutation,
} from "../../../../../generated/graphql";
import { ReadQueryResult } from "../../../../../utils/types/QueryResult";
import { AnswerList } from "../../../../Projects/Configurator/common/AnswerList/AnswerList";

export const OneOfManyEdit = () => {
  const { selectedEntity } = useActionContext();

  if (
    !selectedEntity ||
    selectedEntity.selectedElementType !== SelectedElementType.ONE_OF_MANY
  )
    return <></>;

  const [answers, setAnswers] = React.useState(
    (selectedEntity.selectedElementPath as SelectedOneOfManyPath)
      .questionChoices
  );

  const [updateQuestionInQuestionGroupFromSample] =
    useUpdateQuestionInQuestionGroupFromSampleMutation();

  const useQuestionMutation = (
    destination: string,
    value: string | [string]
  ): void => {
    const {
      projectId,
      sampleId,
      questionId,
      questionSetId,
      questionGroupId,
      fromReport,
      workflowId,
      serviceId,
    } = selectedEntity.selectedElementPath as SelectedReportQuestionPath;

    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        questionInput: {
          questionId,
          questionSetId,
          questionGroupId,
          fromReport,
          workflowId,
          serviceId,
        },
        questionUpdatables: {
          id: questionId,
          [destination]: value,
        },
      },
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["UpdateQuestionInQuestionGroupFromSample"];
        const existingQueryResult = cloneDeep(
          cache.readQuery<ReadQueryResult>({
            query: UserProjectDocument,
            variables: { id: projectId },
          })?.["Project"]
        );

        if (existingQueryResult && incomingData) {
          const samples = [...existingQueryResult.samples];
          const foundSample = samples.find((s) => s.id === sampleId);

          if (foundSample) {
            const questionGroupIndex =
              foundSample.selectedQuestionGroups.findIndex(
                (qg) => qg.id === questionGroupId
              );
            if (questionGroupIndex !== -1) {
              const questionSetIndex = foundSample.selectedQuestionGroups[
                questionGroupIndex
              ].questionSets.findIndex((qs) => qs.id === questionSetId);

              if (questionSetIndex !== -1) {
                const questionIndex = foundSample.selectedQuestionGroups[
                  questionGroupIndex
                ].questionSets[questionSetIndex].questions.findIndex(
                  (q) => q.id === questionId
                );

                if (questionIndex !== -1) {
                  foundSample.selectedQuestionGroups[
                    questionGroupIndex
                  ].questionSets[questionSetIndex].questions[questionIndex] =
                    incomingData;

                  const sampleIndex = samples.findIndex(
                    (s) => s.id === sampleId
                  );
                  samples[sampleIndex] = { ...foundSample };

                  existingQueryResult.samples = [...samples];

                  cache.writeQuery({
                    variables: { id: projectId },
                    query: UserProjectDocument,
                    data: {
                      Project: { ...existingQueryResult },
                    },
                  });
                }
              }
            }
          }
        }
      },
    };

    updateQuestionInQuestionGroupFromSample(options);
  };

  const handleOnAnswersChange = (values: [string]) => {
    setAnswers(values);
    useQuestionMutation("choices", values);
  };

  return (
    <>
      <AnswerList
        title="Answers: "
        answerValues={answers}
        onUpdateFinishCallback={handleOnAnswersChange}
      />
    </>
  );
};
