import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const MemoIcon = (props: MemoIconProps) => {
  const { width, height, x = 0, y = 0, color } = props;
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79995 1.6001C9.01212 1.6001 9.21561 1.68438 9.36564 1.83441L12.5656 5.03441C12.7157 5.18444 12.8 5.38792 12.8 5.6001V12.0001C12.8 13.3256 11.7254 14.4001 10.4 14.4001H5.59995C4.27447 14.4001 3.19995 13.3256 3.19995 12.0001V4.0001C3.19995 2.67461 4.27447 1.6001 5.59995 1.6001H8.79995ZM7.99915 3.2001H5.59995C5.15812 3.2001 4.79995 3.55827 4.79995 4.0001V12.0001C4.79995 12.4419 5.15812 12.8001 5.59995 12.8001H10.4C10.8418 12.8001 11.2 12.4419 11.2 12.0001V6.4001H8.79995C8.38968 6.4001 8.05155 6.09127 8.00533 5.69339L7.99995 5.6001L7.99915 3.2001Z"
        fill={color}
      />
    </svg>
  );
};

MemoIcon.defaultProps = {
  width: "16",
  height: "16",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};

type MemoIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
