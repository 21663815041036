import * as React from "react";
import {
  GetFieldStatusResultT,
  LabelTagTypes,
} from "../../components/common/LabelTag/LabelTag";

export const useFieldStatusTimeout = (
  fieldStatus: GetFieldStatusResultT,
  setFieldStatus: React.Dispatch<
    React.SetStateAction<
      | {
          type: LabelTagTypes;
          name: string;
        }
      | undefined
    >
  >,
  delay = 20000
) => {
  const savedFieldStatus = React.useRef(fieldStatus);

  React.useEffect(() => {
    savedFieldStatus.current = fieldStatus;
  }, [fieldStatus]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (savedFieldStatus.current?.type === 3) {
        setFieldStatus({ type: LabelTagTypes.FAILED, name: "FAILED" });
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [savedFieldStatus.current]);
};
