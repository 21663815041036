import * as React from "react";
import styled from "styled-components";
import { ServicePackHeader } from "./ServicePackHeader";
import { ServiceCard } from "../../../../common/Services/ServiceCard";
import { ServiceCardTag } from "../../../../common/Services/ServiceCardTag";
import { Masonry } from "./helpers/Masonry";
import { Workflow } from "./helpers/TypesForServicePackages";
import { ServicePackAction } from "../../../../../utils/types/WorkflowMetadata";

export type ServicePacksProps = {
  projectId: string;
  sampleId: string;
  workflows: Workflow[];
  tag?: ServiceCardTag;
  title: string;
  description: string;
  selectedCount?: number;
  onAction: ServicePackAction;
  selectedWorkflowsIds?: string[];
  removeDisabled: boolean;
};
export const ServicePacksList = (props: ServicePacksProps) => {
  const {
    workflows,
    tag,
    title,
    description,
    projectId,
    sampleId,
    onAction,
    selectedWorkflowsIds,
    removeDisabled,
  } = props;

  const checkSelection = (workflowId: string) => {
    return selectedWorkflowsIds && selectedWorkflowsIds.includes(workflowId);
  };

  const getSelectedCounts = () => {
    return (
      (selectedWorkflowsIds &&
        selectedWorkflowsIds.filter((workflowId) =>
          workflows.map((workflow) => workflow.id).includes(workflowId)
        ).length) ||
      0
    );
  };

  const onClick: ServicePackAction = (metadata, unselect) => {
    onAction(metadata, unselect);
  };
  return (
    <>
      <ServicePackHeader
        title={title}
        description={description}
        selectedCount={getSelectedCounts() || 0}
      />
      <ServicePacks>
        <Masonry>
          {workflows.map(
            (workflow) =>
              workflow && (
                <ServiceCard
                  key={workflow.id}
                  id={workflow.id}
                  title={workflow.name}
                  services={workflow.services}
                  onAction={onClick}
                  metaData={{ projectId, sampleId, workflowId: workflow.id }}
                  tag={tag}
                  selected={checkSelection(workflow.id)}
                  disabled={removeDisabled}
                />
              )
          )}
        </Masonry>
      </ServicePacks>
    </>
  );
};

const ServicePacks = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;
