import React from "react";
import { RouteComponentProps } from "@reach/router";
import { useUserPermissionsSource } from "../utils/hooks/useUserPermissions";
import { ROLES } from "../constants/roles";

export type UserRolesContextType = {
  useUserPermissions: (permission: string, role?: ROLES) => boolean;
};

type UserRolesContextProviderPropsT = RouteComponentProps & {
  roles?: string[];
};

export const UserRolesContext = React.createContext<UserRolesContextType>({
  useUserPermissions() {
    throw new Error("UserRolesContext not defined");
  },
});

export const UserRolesContextProvider: React.FC<UserRolesContextProviderPropsT> =
  ({ children, roles }) => {
    const useUserPermissions = (permission: string, role?: ROLES): boolean =>
      useUserPermissionsSource(permission, roles, role);

    return (
      <UserRolesContext.Provider
        value={{
          useUserPermissions,
        }}
      >
        {children}
      </UserRolesContext.Provider>
    );
  };
