import * as React from "react";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components";

export type ContentSimpleSectionProps = {
  children: React.ReactNode;
  centered?: boolean;
};

export const ContentSimpleSection = ({
  children,
  centered,
}: ContentSimpleSectionProps) => (
  <StyledContentSimpleSection>
    <StyledChildren centered={centered}>{children}</StyledChildren>
  </StyledContentSimpleSection>
);

const StyledContentSimpleSection = styled(Box)`
  border-radius: ${({ theme }) => theme.radius.default};
  background-color: ${({ theme }) => theme.newColors.grayscale.white};
  margin-bottom: 44px;
  box-shadow: ${({ theme }) => theme.shadows.dropShadows.formCard};
  border: 1px solid ${({ theme }) => theme.newColors.grayscale.bordersInside};

  &:not(:last-of-type) {
    :after {
      position: relative;
      bottom: -${({ theme }) => theme.space[4]};
      left: ${({ theme }) => theme.space[3]};
      height: 10px;
      width: 10px;
      content: url("data:image/svg+xml,%3Csvg width='4' height='20' viewBox='0 0 4 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='18' r='2' fill='%23C5C6CB'/%3E%3Ccircle cx='2' cy='10' r='2' fill='%23C5C6CB'/%3E%3Ccircle cx='2' cy='2' r='2' fill='%23C5C6CB'/%3E%3C/svg%3E");
    }
  }
`;

const StyledChildren = styled(Box)<{ centered?: boolean }>`
  padding: 10px 27px;
  display: flex;

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}
`;
