import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const ChevronRightIcon = (props: BaseIconProps) => {
  const { width, height, color, x, y } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75192 4.83205C5.34566 4.43623 5.34566 3.56377 4.75192 3.16795L1.5547 1.03647C0.890145 0.59343 -3.02928e-07 1.06982 -2.68016e-07 1.86852L-8.16755e-08 6.13148C-4.67634e-08 6.93018 0.890145 7.40657 1.5547 6.96353L4.75192 4.83205Z"
        fill={color}
      />
    </svg>
  );
};
ChevronRightIcon.defaultProps = {
  width: "6",
  height: "8",
  color: styledTheme.newColors.grayscale.background,
};
