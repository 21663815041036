import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const LeftBigArrowIcon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8.97211,17.91681c-0.25402,0 -0.50804,-0.09732 -0.70153,-0.29097l-7.93807,-7.94472c-0.38802,-0.3883 -0.38802,-1.01593 0,-1.40422l7.93807,-7.94468c0.38797,-0.3883 1.01508,-0.3883 1.40305,0c0.38797,0.3883 0.38797,1.01593 0,1.40422l-7.23655,7.24257l7.23655,7.24261c0.38797,0.3883 0.38797,1.01593 0,1.40422c-0.19349,0.19365 -0.44751,0.29097 -0.70153,0.29097l0.00001,0z"
        fill={color}
      />
    </svg>
  );
};
LeftBigArrowIcon.defaultProps = {
  width: "10",
  height: "18",
  color: styledTheme.colors.lightGreen,
};
