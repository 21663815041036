import * as React from "react";
import { navigate, RouteComponentProps, useParams } from "@reach/router";
import { useEffect, useState } from "react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { useAuth } from "react-use-auth";
import { StatusType } from "../../../../Tabs/getStatusIcon";
import { Tabs } from "../../../../Tabs/Tabs";
import {
  Project,
  Sample,
  useAddSampleToProjectMutation,
  BaseService,
  BaseWorkflow,
  QuestionGroup,
  UserProjectDocument,
  useAddQuestionGroupToSampleMutation,
  ProjectWithAllSamplesDocument,
  EntityStatus,
  AnswerType,
  useProjectSampleWithMultiAnswerMutation,
  useProjectSampleWithNumericAnswerMutation,
  useProjectSampleWithTextAnswerMutation,
} from "../../../../../generated/graphql";
import { DynamicFormWrapper } from "../../../../DynamicForm/DynamicFormWrapper";
import {
  QuestionLevelInput,
  QuestionMetadata,
} from "../../../../../utils/types/QuestionMetadata";
import { ServicePackagesSection } from "./ServicePackagesSection";
import { EnableWorkflowAction } from "../../../../../utils/types/WorkflowMetadata";
import { getSampleName } from "../../../../../utils/helpers/getSampleName";
import { getProjectName } from "../../../../../utils/helpers/getProjectName";
import { shortenedSampleName } from "../../../../../utils/helpers/shortenedSampleName";
import { PackageDescriptionSection } from "./PackageDescriptionSection";
import { updateForMutationsWithOnlyServerSideLogic } from "../../../../../utils/helpers/updateForMutationsWithOnlyServerSideLogic";
import { ContentSimpleSection } from "../../../../common/ContentSimpleSection/ContentSimpleSection";
import { DynamicEditableFormWrapper } from "../../../../DynamicForm/DynamicEditableFormWrapper";
import { DynamicPrintingFormWrapper } from "../../../../DynamicForm/DynamicPrintingFormWrapper";
import { LayoutWithDroppableContext } from "../../../../Layouts/LayoutWithDroppableContext";
import { AddNewSection } from "../../../../common/ContentSimpleSection/AddNewSection/AddNewSection";
import { ReadQueryResult } from "../../../../../utils/types/QueryResult";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedQuestionGroupPath,
} from "../../../../../utils/types/ActionContextTypes";
import { useSideBarsContext } from "../../../../../utils/hooks/useSideBarsState";
import { ReturnLink } from "../../../../common/ReturnLink";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { useUserRolesContext } from "../../../../../utils/hooks/useUserRolesContext";
import { ROLES } from "../../../../../constants/roles";

const SAMPLE_CONFIGURATOR_QUESTION_GROUP_NAME = "Service Packages";
const PACKAGE_DESCRIPTION_QUESTION_GROUP_NAME = "Package Description";
const NEW_QUESTION_GROUP_NAME = "New Question Group";

type ReportSelectedQuestionGroups = {
  serviceName: string;
  serviceId: string;
  workflowId: string;
  questionGroup: QuestionGroup;
};

const projectDataTab = {
  id: "1",
  label: "Project Data",
  status: StatusType.Editing,
};

const runQuestionAnswerMutationAfterMs = 1000;
let lastMetadata: null | string = null;

const parseProjectSamples = (
  samples: Sample[]
): {
  id: string;
  label: string;
  status: StatusType;
}[] =>
  samples.map((sample: Sample) => ({
    id: sample.id,
    label: shortenedSampleName(getSampleName(sample)),
    status: StatusType.Editing,
  }));

const getReportSelectedQuestionGroups = (
  sample: Sample
): ReportSelectedQuestionGroups[] => {
  const questionGroups: ReportSelectedQuestionGroups[] = [];

  for (let i = 0; i < sample.enabledWorkflows.length; i += 1) {
    const workflow = sample.enabledWorkflows[i];
    const services = workflow.services.filter(
      (service) =>
        service && service.report && service.status === EntityStatus.Approved
    );

    if (services.length > 0) {
      services.forEach((service) => {
        const { report } = service;
        if (
          report &&
          report.selectedQuestionGroups &&
          report.selectedQuestionGroups.length > 0
        ) {
          report.selectedQuestionGroups.forEach((questionGroup) =>
            questionGroups.push({
              serviceName: service.name,
              serviceId: service.id,
              workflowId: workflow.id,
              questionGroup: { ...questionGroup },
            })
          );
        }
      });
    }
  }

  return questionGroups;
};

export const SampleConfigurator = (
  props: SampleConfiguratorProps
): JSX.Element => {
  const {
    project,
    sample,
    answerProjectSampleQuestion,
    enableWorkflowsForProjectSample,
    services,
    workflows,
  } = props;

  const { user } = useAuth();

  const userRoleInSample = sample?.assignedUsers?.find(
    (assignedUser) => assignedUser?.id === user.sub
  )?.role as ROLES | undefined;

  const [addQuestionGroupToSampleMutation] =
    useAddQuestionGroupToSampleMutation();

  const projectSampleWithMultiAnswer =
    useProjectSampleWithMultiAnswerMutation()[0];
  const projectSampleWithNumericAnswer =
    useProjectSampleWithNumericAnswerMutation()[0];
  const projectSampleWithTextAnswer =
    useProjectSampleWithTextAnswerMutation()[0];

  const [
    preparedForPrintingQuestionGroupId,
    setPreparedForPrintingQuestionGroupId,
  ] = useState("");

  const handleOnPrepareForPrinting = (questionGroupId: string): void => {
    setPreparedForPrintingQuestionGroupId(questionGroupId);
  };

  const [isReportEdited, setIsReportEdited] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);
  const { setSideDrawboardOpen } = useSideBarsContext();
  const {
    selectedEntity,
    setSelectedEntity,
    openedSampleGroup,
    setOpenedSampleGroup,
  } = useActionContext();
  const questionGroupId =
    selectedEntity &&
    (selectedEntity.selectedElementPath as SelectedQuestionGroupPath)
      .questionGroupId;

  const { useUserPermissions } = useUserRolesContext();
  const addNewSectionIsEnabled = useUserPermissions(
    "addNewSection",
    userRoleInSample
  );

  const urlParams = useParams();

  useEffect(() => {
    if (!sample.selectedQuestionGroups[openedSampleGroup]) {
      setOpenedSampleGroup(0);
    }
  }, [urlParams.sampleId]);

  useEffect(() => {
    if (preparedForPrintingQuestionGroupId) {
      setPreparedForPrintingQuestionGroupId("");
    }
  }, [openedSampleGroup]);

  const getTabs = (
    projectId: string,
    samples: Sample[] = [],
    selectedSampleId = "1"
  ): JSX.Element => {
    const parsedProjectSamples = parseProjectSamples(samples);
    const tabs = [projectDataTab, ...parsedProjectSamples];
    const [addSampleToProjectMutation] = useAddSampleToProjectMutation();

    const newTabClick = async (): Promise<void> => {
      setShowSpinner(true);
      const options = {
        variables: { projectSampleInput: { projectId } },
        refetchQueries: [
          {
            query: UserProjectDocument,
            variables: { id: projectId },
          },
          {
            query: ProjectWithAllSamplesDocument,
            variables: { id: projectId },
          },
        ],
        awaitRefetchQueries: true,
        update(cache: InMemoryCache, { data }: any): void {
          updateForMutationsWithOnlyServerSideLogic(
            { id: projectId },
            UserProjectDocument,
            "Project",
            "AddSampleToProject",
            cache,
            data,
            "samples"
          );
        },
      };

      await addSampleToProjectMutation(options);
      setShowSpinner(false);
    };

    const selectTab = (sampleId: string): void => {
      if (selectedSampleId !== sampleId) {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
      }

      if (sampleId === "1") {
        // go to Project data tab
        navigate(`/projects/${projectId}/configurator`);
      } else {
        navigate(
          `/projects/${projectId}/configurator/samples/${sampleId}/configurator`
        );
      }
    };

    return (
      <Tabs
        tabs={tabs}
        onTabClick={selectTab}
        activeTab={selectedSampleId}
        onNewTabClick={newTabClick}
      />
    );
  };

  const questionLevelInput: QuestionLevelInput = {
    projectId: project.id,
    sampleId: sample.id,
  };

  const questionGroups = getReportSelectedQuestionGroups(sample);

  const wrapCards = (questionGroupIndex: number): void => {
    if (questionGroupIndex !== openedSampleGroup) {
      setSelectedEntity(undefined);
      setSideDrawboardOpen(false);
    }

    if (
      questionGroupIndex >
      sample.selectedQuestionGroups.length + questionGroups.length
    ) {
      setOpenedSampleGroup(0);
    } else setOpenedSampleGroup(questionGroupIndex);
  };

  const handleSampleClick = (sampleId: string): void => {
    navigate(
      `/projects/${project.id}/configurator/samples/${sampleId}/configurator`
    );
  };

  const projectName: string = getProjectName(project);
  const sampleName: string = getSampleName(sample);
  const pageTitle = `${projectName} > ${shortenedSampleName(sampleName)}`;

  const getReportSelectedQuestionGroupElement = (
    questionGroup: ReportSelectedQuestionGroups,
    index: number
  ): JSX.Element => {
    /* eslint-disable */

    const projectId = project.id;
    const sampleId = sample.id;

    if (
      (selectedEntity?.selectedElementType ===
        SelectedElementType.QUESTION_GROUP ||
        selectedEntity?.selectedElementType === SelectedElementType.QUESTION ||
        selectedEntity?.selectedElementType ===
          SelectedElementType.ONE_OF_MANY) &&
      questionGroup.questionGroup.id === questionGroupId
    ) {
      /* eslint-enable */

      if (!isReportEdited) {
        setIsReportEdited(true);
      }

      return (
        <DynamicEditableFormWrapper
          key={questionGroup.questionGroup.id}
          title={`${questionGroup.serviceName}: ${questionGroup.questionGroup.name}`}
          questionGroup={questionGroup.questionGroup}
          projectId={projectId}
          sampleId={sampleId}
          isReport
          stepNumber={sample.selectedQuestionGroups.length + index + 1}
          wrapped={
            openedSampleGroup !== sample.selectedQuestionGroups.length + index
          }
          wrapCardsCallback={(): void => {
            wrapCards(sample.selectedQuestionGroups.length + index);
          }}
          workflowId={questionGroup.workflowId}
          serviceId={questionGroup.serviceId}
          goToNextGroupCallback={
            questionGroups.length > 1 && questionGroups.length > index + 1
              ? (): void => {
                  if (questionGroups.length > index) {
                    wrapCards(sample.selectedQuestionGroups.length + index + 1);
                  }
                }
              : undefined
          }
        />
      );
    }

    if (!questionGroupId && isReportEdited) {
      setIsReportEdited(false);
    }

    const getMutationBasedOnAnswerType = (answerType: AnswerType) => {
      switch (answerType) {
        case AnswerType.Number:
          return projectSampleWithNumericAnswer;
        case AnswerType.ManyOfMany:
          return projectSampleWithMultiAnswer;
        default:
          return projectSampleWithTextAnswer;
      }
    };

    const reportAnswerProjectSampleQuestion = (
      metadata: QuestionMetadata,
      answer: any
    ) => {
      if (!metadata.questionLevelInput.sampleId) {
        throw new Error("Metadata is missing sampleId");
      }

      const options = {
        variables: {
          answer,
          projectSampleQuestionInput: {
            questionInput: {
              ...metadata.questionInput,
              fromReport: true,
              workflowId: questionGroup.workflowId,
              serviceId: questionGroup.serviceId,
            },
            projectSampleInput: {
              projectId: metadata.questionLevelInput.projectId,
              sampleId: metadata.questionLevelInput.sampleId,
            },
          },
        },
        refetchQueries: [
          {
            query: UserProjectDocument,
            variables: {
              id: projectId,
            },
          },
        ],
        awaitRefetchQueries: true,
      };
      const mutation = getMutationBasedOnAnswerType(metadata.answerType);
      if (metadata.answerType === AnswerType.Number) {
        options.variables.answer = parseFloat(options.variables.answer) || 0;
      }
      const mutationPromise: Promise<unknown> = mutation(options);
      mutationPromise.catch((e) => {
        toast.error(e.toString());
      });
    };

    const answerProjectSampleQuestionDebouncedInternal = debounce(
      reportAnswerProjectSampleQuestion,
      runQuestionAnswerMutationAfterMs,
      {
        leading: false,
        trailing: true,
      }
    );

    const answerProjectSampleQuestionDebounced = (
      metadata: QuestionMetadata,
      answear: any
    ) => {
      if (lastMetadata !== JSON.stringify(metadata)) {
        lastMetadata = JSON.stringify(metadata);
        answerProjectSampleQuestionDebouncedInternal.flush();
      }

      answerProjectSampleQuestionDebouncedInternal(metadata, answear);
    };

    if (questionGroup.questionGroup.id === preparedForPrintingQuestionGroupId) {
      /* eslint-enable */
      return (
        <DynamicPrintingFormWrapper
          title={`${questionGroup.serviceName}: ${questionGroup.questionGroup.name}`}
          answerQuestionCallback={answerProjectSampleQuestionDebounced}
          questionLevelInput={{ projectId, sampleId }}
          key={questionGroup.questionGroup.id}
          stepNumber={sample.selectedQuestionGroups.length + index + 1}
          questionGroup={questionGroup.questionGroup}
          wrapped={
            openedSampleGroup !== sample.selectedQuestionGroups.length + index
          }
          wrapCardsCallback={(): void => {
            wrapCards(sample.selectedQuestionGroups.length + index);
          }}
          handleOnPrepareForPrinting={handleOnPrepareForPrinting}
        />
      );
    }

    return (
      <DynamicFormWrapper
        key={questionGroup.questionGroup.id}
        title={`${questionGroup.serviceName}: ${questionGroup.questionGroup.name}`}
        stepNumber={sample.selectedQuestionGroups.length + index + 1}
        wrapped={
          openedSampleGroup !== sample.selectedQuestionGroups.length + index
        }
        questionLevelInput={{ projectId, sampleId }}
        questionGroup={questionGroup.questionGroup}
        answerQuestionCallback={answerProjectSampleQuestionDebounced}
        wrapCardsCallback={(): void => {
          wrapCards(sample.selectedQuestionGroups.length + index);
        }}
        goToNextGroupCallback={
          questionGroups.length > 1 && questionGroups.length > index + 1
            ? (): void => {
                if (questionGroups.length > index) {
                  wrapCards(sample.selectedQuestionGroups.length + index + 1);
                }
              }
            : undefined
        }
        projectId={projectId}
        sampleId={sampleId}
        workflowId={questionGroup.workflowId}
        serviceId={questionGroup.serviceId}
        isReport
        handleOnPrepareForPrinting={handleOnPrepareForPrinting}
        userRoleInSample={userRoleInSample}
      />
    );
  };

  const addNewQuestionGroup = async (): Promise<void> => {
    setShowSpinner(true);
    const options = {
      variables: {
        projectSampleInput: {
          projectId: project.id,
          sampleId: sample.id,
        },
        name: NEW_QUESTION_GROUP_NAME,
      },
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["AddQuestionGroupToSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: project.id },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const editedSample = existingQueryResult.samples.find(
            (s) => s.id === sample.id
          ) as Sample;

          editedSample.selectedQuestionGroups.push(incomingData);

          cache.writeQuery({
            variables: { id: project.id },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: existingQueryResult.samples,
              },
            },
          });

          wrapCards(editedSample.selectedQuestionGroups.length - 1);
        }
      },
    };

    await addQuestionGroupToSampleMutation(options);
    setShowSpinner(false);
  };

  const getSelectedQuestionGroupElement = (
    questionGroup: QuestionGroup,
    index: number
  ): JSX.Element => {
    if (questionGroup.name === SAMPLE_CONFIGURATOR_QUESTION_GROUP_NAME) {
      // if (!addNewSectionIsEnabled) return <></>;

      return (
        <ServicePackagesSection
          key={questionGroup.id}
          title={questionGroup.name}
          stepNumber={index + 1}
          wrapped={openedSampleGroup !== index}
          wrapCardsCallback={(): void => {
            wrapCards(index);
          }}
          projectId={project.id}
          sampleId={sample.id}
          workflows={workflows}
          services={services}
          selectedWorkflowsIds={sample.selectedWorkflows.map(
            (workflow) => workflow.id
          )}
          enableWorkflowsForProjectSample={enableWorkflowsForProjectSample}
          servicePackagesAlreadyAdded={sample.enabledWorkflows.length > 1}
          goToNextGroupCallback={
            sample.selectedQuestionGroups.length + questionGroups.length > 1 &&
            sample.selectedQuestionGroups.length + questionGroups.length >
              index + 1
              ? (): void => {
                  if (
                    sample.selectedQuestionGroups.length +
                      questionGroups.length >
                    index
                  ) {
                    wrapCards(index + 1);
                  }
                }
              : undefined
          }
          removeDisabled={sample.enabledWorkflows.length > 1}
        />
      );
    }

    if (questionGroup.name === PACKAGE_DESCRIPTION_QUESTION_GROUP_NAME) {
      return (
        <PackageDescriptionSection
          key={questionGroup.id}
          title={questionGroup.name}
          stepNumber={index + 1}
          wrapped={openedSampleGroup !== index}
          wrapCardsCallback={(): void => {
            wrapCards(index);
          }}
          questionGroup={questionGroup}
          goToNextGroupCallback={
            sample.selectedQuestionGroups.length + questionGroups.length > 1 &&
            sample.selectedQuestionGroups.length + questionGroups.length >
              index + 1
              ? (): void => {
                  if (
                    sample.selectedQuestionGroups.length +
                      questionGroups.length >
                    index
                  ) {
                    wrapCards(index + 1);
                  }
                }
              : undefined
          }
        />
      );
    }

    if (questionGroup.id === preparedForPrintingQuestionGroupId) {
      /* eslint-enable */
      return (
        <DynamicPrintingFormWrapper
          title={questionGroup.name}
          answerQuestionCallback={answerProjectSampleQuestion}
          questionLevelInput={questionLevelInput}
          key={questionGroup.id}
          stepNumber={index + 1}
          questionGroup={questionGroup}
          wrapped={openedSampleGroup !== index}
          wrapCardsCallback={(): void => {
            wrapCards(index);
          }}
          handleOnPrepareForPrinting={handleOnPrepareForPrinting}
        />
      );
    }

    /* eslint-disable */
    if (
      (selectedEntity?.selectedElementType ===
        SelectedElementType.QUESTION_GROUP ||
        selectedEntity?.selectedElementType === SelectedElementType.QUESTION ||
        selectedEntity?.selectedElementType ===
          SelectedElementType.ONE_OF_MANY) &&
      questionGroup.id === questionGroupId
    ) {
      /* eslint-enable */
      return (
        <DynamicEditableFormWrapper
          title={questionGroup.name}
          key={questionGroup.id}
          stepNumber={index + 1}
          questionGroup={questionGroup}
          wrapped={openedSampleGroup !== index}
          wrapCardsCallback={(): void => {
            wrapCards(index);
          }}
          goToNextGroupCallback={
            sample.selectedQuestionGroups.length + questionGroups.length > 1 &&
            sample.selectedQuestionGroups.length + questionGroups.length >
              index + 1
              ? (): void => {
                  if (
                    sample.selectedQuestionGroups.length +
                      questionGroups.length >
                    index
                  ) {
                    wrapCards(index + 1);
                  }
                }
              : undefined
          }
          projectId={project.id}
          sampleId={sample.id}
        />
      );
    }

    return (
      <DynamicFormWrapper
        title={questionGroup.name}
        questionLevelInput={questionLevelInput}
        key={questionGroup.id}
        stepNumber={index + 1}
        questionGroup={questionGroup}
        wrapped={openedSampleGroup !== index}
        answerQuestionCallback={answerProjectSampleQuestion}
        wrapCardsCallback={(): void => {
          wrapCards(index);
        }}
        goToNextGroupCallback={
          sample.selectedQuestionGroups.length + questionGroups.length > 1 &&
          sample.selectedQuestionGroups.length + questionGroups.length >
            index + 1
            ? (): void => {
                if (
                  sample.selectedQuestionGroups.length + questionGroups.length >
                  index
                ) {
                  wrapCards(index + 1);
                }
              }
            : undefined
        }
        projectId={project.id}
        sampleId={sample.id}
        handleOnPrepareForPrinting={handleOnPrepareForPrinting}
        userRoleInSample={userRoleInSample}
      />
    );
  };

  return (
    <>
      {showSpinner ? <Spinner opaque /> : null}
      <LayoutWithDroppableContext
        pageTitle={pageTitle}
        tabs={getTabs(project.id, project.samples, urlParams.sampleId || "1")}
        dropdownData={project.samples}
        selectedDropdownDataId={sample.id}
        onDataClick={(sampleId: string): void => handleSampleClick(sampleId)}
        projectId={project.id}
        selectedQuestionGroups={sample.selectedQuestionGroups}
        sample={sample}
        fixedSideDrawboard={false}
        fromReport={isReportEdited}
      >
        <>
          <ReturnLink
            path={`/projects/${urlParams.projectId}/timeline/samples/${
              urlParams.sampleId || "1"
            }`}
            label="Go to the timeline"
          />
          {sample.selectedQuestionGroups.map((questionGroup, index) => {
            return getSelectedQuestionGroupElement(questionGroup, index);
          })}
          {questionGroups.length > 0
            ? questionGroups.map((qg, index) =>
                getReportSelectedQuestionGroupElement(qg, index)
              )
            : null}
          {addNewSectionIsEnabled ? (
            <ContentSimpleSection centered>
              <AddNewSection addNewQuestionGroup={addNewQuestionGroup} />
            </ContentSimpleSection>
          ) : null}
        </>
      </LayoutWithDroppableContext>
    </>
  );
};

interface SampleConfiguratorProps extends RouteComponentProps {
  project: Project;
  sample: Sample;
  services: BaseService[];
  workflows: BaseWorkflow[];
  enableWorkflowsForProjectSample?: EnableWorkflowAction;
  answerProjectSampleQuestion: (
    metadata: QuestionMetadata,
    answer: any
  ) => void;
}
