import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "../../../../common/Modal/Modal";
import { ButtonType } from "../../../../common/Button/ButtonType";
import { Button } from "../../../../common/Button/Button";
import { Dropdown } from "../../../../common/Dropdown/Dropdown";
import {
  useCopyTaskToChosenSampleMutation,
  UserProjectDocument,
  useUserProjectQuery,
} from "../../../../../generated/graphql";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedWorkflowPath,
} from "../../../../../utils/types/ActionContextTypes";
import { Option } from "../../../../../utils/types/QuestionMetadata";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { useSideBarsContext } from "../../../../../utils/hooks/useSideBarsState";
import { getSampleName } from "../../../../../utils/helpers/getSampleName";

type CopyTaskToOtherPathModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CopyTaskToOtherPathModal = (
  props: CopyTaskToOtherPathModalProps
): JSX.Element => {
  const { isOpen, onClose } = props;

  const { selectedEntity, setSelectedEntity } = useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();

  const [selectedPath, setSelectedPath] = useState<Option | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const [copyTaskToChosenSample] = useCopyTaskToChosenSampleMutation();

  if (!(selectedEntity?.selectedElementType === SelectedElementType.WORKFLOW))
    return <></>;

  const userProjectQueryResult = useUserProjectQuery({
    variables: {
      id: selectedEntity.selectedElementPath.projectId,
    },
  });

  if (userProjectQueryResult.error) {
    return <p>Query Error: {userProjectQueryResult.error}</p>;
  }

  if (userProjectQueryResult.loading || !userProjectQueryResult.data) {
    return <Spinner />;
  }

  const onAcceptModal = (): void => {
    if (!selectedPath) return;

    setIsLoading(true);

    const { projectId, sampleId, workflowId } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    copyTaskToChosenSample({
      variables: {
        projectSampleWorkflowInput: {
          projectId,
          sampleId,
          workflowId,
        },
        destinationSampleId: selectedPath.value,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
        setIsLoading(false);
        onClose();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const ModalButtons = (): JSX.Element => (
    <>
      <AcceptButton
        disabled={isLoading}
        label="Move Task"
        type={ButtonType.Primary}
        onClick={onAcceptModal}
      />
      <Button
        disabled={isLoading}
        label="Cancel"
        type={ButtonType.Secondary}
        onClick={onClose}
      />
    </>
  );

  const getOptions = (): Option[] => {
    const { sampleId } =
      selectedEntity.selectedElementPath as SelectedWorkflowPath;

    const availableOptions =
      userProjectQueryResult.data?.Project.samples
        .filter((sample) => sample.id !== sampleId)
        .map((sample) => ({
          value: sample.id,
          label: getSampleName(sample),
        })) || [];

    availableOptions.push({
      value: "CreateNewSample",
      label: "Create a new Path for this Task",
    });

    return availableOptions;
  };

  if (!isOpen) return <></>;

  return (
    <StyledModal
      id="copyTaskToOtherPathModal"
      title="Copy Task to other Path"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <StyledDropdown
        closeMenuOnSelect
        value={selectedPath}
        options={getOptions()}
        onChange={(option: Option): void => setSelectedPath(option)}
        placeholder="Select destination Path"
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  .Select__option:last-child {
    border-top: 1px solid #dedede;
  }

  .Select__option:only-child {
    border-top: transparent;
  }
`;
