import React from "react";
import styled, { css } from "styled-components";
import { ProjectUser } from "../../../../../generated/graphql";
import { Dropdown, DropdownProps } from "../../../../common/Dropdown/Dropdown";
import { UserSelectorOption } from "../UserSelectorOption";
import { SpinnerElement } from "../../../../common/Spinner/SpinnerElement";

const SingleValue = (props: DropdownProps): JSX.Element => {
  const {
    data: { label, avatar, smallMenuItems },
  } = props;

  return (
    <SingleValueContainer smallMenuItems={smallMenuItems} title={label}>
      {avatar && <AssigneeAvatar src={avatar} />}
      <AssigneeSingleValue
        avatarExists={!!avatar}
        smallMenuItems={smallMenuItems}
      >
        {label}
      </AssigneeSingleValue>
    </SingleValueContainer>
  );
};

const Option = (props: DropdownProps): JSX.Element => {
  const {
    data: { label, value, avatar, smallMenuItems },
    setValue,
    isSelected,
    disabled,
  } = props;

  return (
    <OptionContainer
      isSelected={isSelected}
      onClick={() => {
        if (!disabled) {
          setValue(value);
        }
      }}
      title={label}
      smallMenuItems={smallMenuItems}
    >
      {avatar && <AssigneeAvatar src={avatar} />}
      <AssigneeValue avatarExists={!!avatar} smallMenuItems={smallMenuItems}>
        {label}
      </AssigneeValue>
    </OptionContainer>
  );
};

interface UsersSelectorProps {
  users: ProjectUser[];
  loading: boolean;
  editMode?: boolean;
  onChange: (assignee: string) => void;
  selectedValue?: string | null;
  hardcodedUser?: ProjectUser;
  disabled?: boolean;
  className?: string;
  smallMenuItems?: boolean;
  menuPortalTarget?: HTMLElement | Element | null | undefined;
}

export const UsersSelector = (props: UsersSelectorProps): JSX.Element => {
  const {
    loading,
    users,
    onChange,
    editMode,
    selectedValue,
    hardcodedUser,
    disabled,
    className,
    smallMenuItems = false,
    menuPortalTarget,
  } = props;

  if (!hardcodedUser && (loading || !users))
    return (
      <Container>
        <SpinnerElement />
      </Container>
    );

  const renderContent = (): JSX.Element => {
    const defaultValue: UserSelectorOption = {
      value: "",
      label: "Unassigned",
      avatar: "",
      smallMenuItems,
    };

    const usersOptions = !hardcodedUser
      ? (users
          .map((usr) => {
            if (!usr) return undefined;
            return {
              value: usr.id,
              label: usr.name,
              avatar: usr.avatar,
              smallMenuItems,
            };
          })
          .filter((usr) => usr !== undefined) as UserSelectorOption[])
      : [];
    let options = [defaultValue, ...usersOptions];

    if (hardcodedUser) {
      const newUser: UserSelectorOption = {
        value: hardcodedUser.id,
        avatar: hardcodedUser.avatar,
        label: hardcodedUser.name,
        smallMenuItems,
      };

      options = [...options, newUser];
    }

    if (!editMode) {
      const user = options.find((option) => option.value === selectedValue);

      return (
        <>
          {user?.avatar && (
            <AssigneeAvatar title={user?.label || ""} src={user?.avatar} />
          )}
          <AssigneeValue
            title={user?.label || ""}
            smallMenuItems={smallMenuItems}
            avatarExists={!!user?.avatar}
          >
            {user?.label}
          </AssigneeValue>
        </>
      );
    }

    return (
      <StyledDropdown
        defaultValue={options[0]}
        options={options}
        components={{ Option, SingleValue }}
        value={
          options.find((option) => option.value === selectedValue) || options[0]
        }
        onChange={onChange}
        disabled={disabled}
        menuPortalTarget={menuPortalTarget}
      />
    );
  };

  return (
    <Container className={className} editMode={editMode}>
      {renderContent()}
    </Container>
  );
};

const OptionContainer = styled.div<{
  isSelected: boolean;
  smallMenuItems?: boolean;
}>`
  display: flex;
  padding: 5px;
  min-height: 2rem;
  align-items: center;

  ${({ smallMenuItems }) =>
    smallMenuItems &&
    css`
      font-size: 11px !important;
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: ${(props): string => props.theme.newColors.primary.lightTint};
      color: ${(props): string => props.theme.newColors.grayscale.primaryGray};
    `};

  &:hover {
    background: ${(props): string =>
      props.theme.newColors.primary.lightestTint};
  }

  &:active {
    background: ${(props): string => props.theme.newColors.primary.lightTint};
  }
`;

const SingleValueContainer = styled.div<{
  smallMenuItems?: boolean;
}>`
  display: flex;
  min-height: 15px;
  align-items: center;
  font-size: 12px;

  ${({ smallMenuItems }) =>
    smallMenuItems &&
    css`
      font-size: 11px;
      margin-left: 2px;
      padding: 0;
    `};
`;

const StyledDropdown = styled(Dropdown)`
  width: 200px;
`;

const Container = styled.div<{
  editMode?: boolean | undefined;
}>`
  display: flex;
  align-items: center;
  padding: 3px 0;
  position: absolute;
  margin-left: 100px;
  height: 2rem;
  width: calc(100% - 100px);

  ${({ editMode }) =>
    editMode &&
    css`
      cursor: pointer;
    `};
`;

const AssigneeValue = styled.div<{
  smallMenuItems?: boolean;
  avatarExists?: boolean;
}>`
  margin-left: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;

  ${({ smallMenuItems }) =>
    smallMenuItems &&
    css`
      font-size: 11px;
      margin-left: 5px;
      padding: 0;
    `};

  ${({ smallMenuItems, avatarExists }) =>
    smallMenuItems &&
    !avatarExists &&
    css`
      margin-left: 0;
    `};
`;

const AssigneeSingleValue = styled(AssigneeValue)`
  max-width: 75px;
  cursor: default;
`;

const AssigneeAvatar = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
`;
