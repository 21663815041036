import React from "react";
import styled from "styled-components";

interface IUser {
  picture: string;
}

type UserAvatarProps = { user: IUser; className?: string };

export const UserAvatar = (props: UserAvatarProps): JSX.Element => {
  const { user, className } = props;
  return <StyledAvatar className={className} src={user && user.picture} />;
};

const StyledAvatar = styled.div<{ src: string | null }>`
  width: ${({ theme }) => theme.space[4]};
  min-width: ${({ theme }) => theme.space[4]};
  height: ${({ theme }) => theme.space[4]};
  background-color: #4979cb;
  border-radius: 50%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
