import * as React from "react";
import { FunctionComponent } from "react";
import styled from "styled-components";
import { RadioButton, RadioButtonProps } from "./RadioButton";

const Container = styled.div`
  flex: 1;
  display: flex;
  max-width: 625px;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

export const RadioGroup: FunctionComponent<RadioGroupProps> = (props) => {
  const { name, selectedValue, onClickRadioButton, options } = props;
  return (
    <Container>
      {options.map((option) => {
        return (
          <RadioButton
            key={option.value}
            labelText={option.labelText}
            value={option.value}
            tip={option.tip}
            checked={selectedValue === option.value}
            onChange={() => onClickRadioButton(option.value)}
            name={name}
            disabled={option.disabled}
          />
        );
      })}
    </Container>
  );
};

interface RadioGroupProps {
  name?: string;
  selectedValue: string;
  onClickRadioButton: any;
  options: RadioButtonProps[];
}
