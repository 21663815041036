import * as React from "react";
import styled from "styled-components";
import { styledTheme } from "../../theme/theme";
import { Label } from "../common/Label/Label";
import { ReportsIcon } from "../../images/icons/ReportsIcon";

export const NoQuestionGroups = (props: { className?: string }) => {
  return (
    <Container className={props.className}>
      <Content>
        <ImageContainer>
          <ReportsIcon
            width={48}
            height={48}
            color={styledTheme.newColors.grayscale.primaryGray}
          />
        </ImageContainer>
        <StyledLabel label="NO REPORTS YET">
          Here you’ll see reports for finished services. They will contain a
          general status description and a file with the full result of the
          service.
        </StyledLabel>
      </Content>
    </Container>
  );
};

const StyledLabel = styled(Label)``;

const Container = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 381px;
  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.gray05};
  width: 96px;
  height: 96px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
