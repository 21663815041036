import React from "react";
import styled from "styled-components";
import { ProjectUser } from "../../../../generated/graphql";
import { CollaboratorOption, CollaboratorOptionValue } from "./types";

export function CollaboratorAvatar(props: {
  collaboratorOption: CollaboratorOption;
}): JSX.Element {
  const getGroupInitials = (g: CollaboratorOptionValue): string => {
    const initial = g.name;

    if (initial.includes(" ")) {
      return initial
        .split(" ")
        .map((text) => text[0])
        .join("")
        .slice(0, 3)
        .toUpperCase();
    }
    return initial.slice(0, 3).toUpperCase();
  };

  return (
    <AvatarContainer>
      {props.collaboratorOption.value.type === "user" ? (
        <UserAvatar
          src={(props.collaboratorOption.value.details as ProjectUser).avatar}
          title={props.collaboratorOption.label}
        />
      ) : (
        <GroupAvatar title={props.collaboratorOption.label}>
          {getGroupInitials(props.collaboratorOption.value)}
        </GroupAvatar>
      )}
    </AvatarContainer>
  );
}

const AvatarContainer = styled.div`
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserAvatar = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
`;

const GroupAvatar = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.newColors.grayscale.secondaryGray};
  color: white;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
`;
