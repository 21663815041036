import * as React from "react";
import { StatusIcon } from "../../images/icons/StatusIcon";

export enum StatusType {
  Editing,
  Done,
  Warning,
  Error,
}

export const getStatusIcon = (type?: StatusType) => {
  switch (type) {
    case StatusType.Editing:
    default:
      return <StatusIcon editing />;
    case StatusType.Done:
      return <StatusIcon />;
    case StatusType.Error:
      return <StatusIcon color="#DE2D26" />;
    case StatusType.Warning:
      return <StatusIcon color="#EAB838" />;
  }
};
