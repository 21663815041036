const maxNumberOfCharactersInTitle = 30;

export const shortenedSampleName = (text: string | null): string => {
  if (text) {
    return text.length > maxNumberOfCharactersInTitle
      ? `...${text.substr(text.length - maxNumberOfCharactersInTitle)}`
      : text;
  }
  return "";
};
