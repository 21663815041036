import React from "react";
import styled from "styled-components";
import { Option } from "../../../utils/types/QuestionMetadata";
import { Dropdown } from "../Dropdown/Dropdown";

interface DropdownWithColoredOptionsProps {
  editMode: boolean | undefined;
  selectedOption: Option;
  dropdownOptions: Option[];
  handleColorType: (colorValue: string) => string;
  onChange: (option: Option) => void;
  className?: string;
}

export const DropdownWithColoredOptions = (
  props: DropdownWithColoredOptionsProps
): JSX.Element => {
  const {
    editMode,
    selectedOption,
    dropdownOptions,
    handleColorType,
    onChange,
    className,
  } = props;

  const formatOptionLabel = (option: Option): JSX.Element => {
    const { label, value } = option;

    return (
      <Container colorValue={value || ""} handleColorType={handleColorType}>
        {label}
      </Container>
    );
  };

  if (!editMode) {
    return (
      <Container
        colorValue={selectedOption.value}
        handleColorType={handleColorType}
      >
        {selectedOption.label}
      </Container>
    );
  }

  return (
    <StyledDropdown
      options={dropdownOptions}
      formatOptionLabel={formatOptionLabel}
      value={selectedOption}
      onChange={onChange}
      className={className}
    />
  );
};

const StyledDropdown = styled(Dropdown)`
  width: 100%;

  .Select__value-container {
    overflow: unset;
  }
`;

const Container = styled.div<{
  colorValue: string;
  handleColorType: (colorValue: string) => string;
}>`
  padding: ${({ colorValue }) =>
    colorValue !== "None" ? "4px 12px" : "4px 0"};
  border-radius: 29px;
  ${({ colorValue, handleColorType }) => handleColorType(colorValue)};
  color: ${({ colorValue }) => (colorValue !== "None" ? "#ffffff" : "#000000")};
  font-size: 12px;
  vertical-align: middle;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  width: fit-content;
  display: flex;
  justify-content: center;
`;
