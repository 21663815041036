import React, { useState } from "react";
import styled from "styled-components";
import { ApolloError } from "apollo-client";
import { Modal } from "../../../../common/Modal/Modal";
import { ButtonType } from "../../../../common/Button/ButtonType";
import { Button } from "../../../../common/Button/Button";
import { Dropdown } from "../../../../common/Dropdown/Dropdown";
import {
  useMoveServiceToChosenWorkflowMutation,
  UserProjectDocument,
  useUserProjectQuery,
} from "../../../../../generated/graphql";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import {
  SelectedElementType,
  SelectedServicePath,
} from "../../../../../utils/types/ActionContextTypes";
import { Option } from "../../../../../utils/types/QuestionMetadata";
import { Spinner } from "../../../../common/Spinner/Spinner";
import { useSideBarsContext } from "../../../../../utils/hooks/useSideBarsState";

type MoveSubtaskToOtherTaskProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MoveSubtaskToOtherTaskModal = (
  props: MoveSubtaskToOtherTaskProps
): JSX.Element => {
  const { isOpen, onClose } = props;
  const { selectedEntity, setSelectedEntity } = useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();

  const [selectedTask, setSelectedTask] = useState<Option | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  const [moveServiceToChosenWorkflow] =
    useMoveServiceToChosenWorkflowMutation();

  if (!(selectedEntity?.selectedElementType === SelectedElementType.SERVICE))
    return <></>;

  const userProjectQueryResult = useUserProjectQuery({
    variables: {
      id: selectedEntity.selectedElementPath.projectId,
    },
  });

  if (userProjectQueryResult.error) {
    return <p>Query Error: {userProjectQueryResult.error}</p>;
  }
  if (userProjectQueryResult.loading || !userProjectQueryResult.data) {
    return <Spinner />;
  }

  const chosenTask = (option: Option): void => {
    setSelectedTask(option);
  };

  const onAcceptModal = (): void => {
    if (!selectedTask) return;

    setIsLoading(true);

    const {
      projectId,
      sampleId,
      workflowId,
      service: { id },
    } = selectedEntity.selectedElementPath as SelectedServicePath;

    moveServiceToChosenWorkflow({
      variables: {
        projectSampleWorkflowServiceInput: {
          projectId,
          sampleId,
          workflowId,
          serviceId: id,
        },
        destinationWorkflowId: selectedTask.value,
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
        setIsLoading(false);
        onClose();
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
      });
  };

  const ModalButtons = (): JSX.Element => {
    return (
      <>
        <AcceptButton
          disabled={isLoading}
          label="Move Subtask"
          type={ButtonType.Primary}
          onClick={onAcceptModal}
        />
        <Button
          disabled={isLoading}
          label="Cancel"
          type={ButtonType.Secondary}
          onClick={onClose}
        />
      </>
    );
  };

  const getOptions = (): Option[] => {
    const foundSample = userProjectQueryResult.data?.Project.samples.find(
      (sample) => sample.id === selectedEntity.selectedElementPath.sampleId
    );
    const { workflowId } =
      selectedEntity.selectedElementPath as SelectedServicePath;

    const availableOptions =
      foundSample?.enabledWorkflows
        .filter((workflow) => workflow.id !== workflowId)
        .map((workflow) => ({
          value: workflow.id,
          label: `${workflow.name}`,
        })) || [];

    availableOptions.push({
      value: "CreateNewTask",
      label: "Create a new Task for this Subtask",
    });

    return availableOptions;
  };

  if (!isOpen) return <></>;

  return (
    <StyledModal
      id="moveSubtaskToOtherTask"
      title="Move Subtask to other Task"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <StyledDropdown
        closeMenuOnSelect
        value={selectedTask}
        options={getOptions()}
        onChange={chosenTask}
        placeholder="Select destination Task"
      />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  .Select__option:last-child {
    border-top: 1px solid #dedede;
  }

  .Select__option:only-child {
    border-top: transparent;
  }
`;
