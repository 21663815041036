import flatMap from "lodash/flatMap";
import * as d3 from "d3";
import { Service, Workflow } from "../../generated/graphql";

type Range = {
  startDate: number;
  endDate: number;
};

const msToWeeks = (ms: number): number =>
  Math.ceil(ms / (1000 * 60 * 60 * 24 * 7));

export const getWorkflowRange = (workflow: Workflow): Range => {
  const dates = flatMap(workflow.services || [], (s: Service) => [
    parseInt(s.startDate, 10),
    parseInt(s.endDate, 10),
  ]);

  return {
    startDate: d3.min(dates) || 0,
    endDate: d3.max(dates) || 0,
  };
};

export const getWorkflowLength = (workflow: Workflow): number => {
  const range = getWorkflowRange(workflow);

  return range.endDate - range.startDate;
};

export const getWorkflowLengthInWeeks = (workflow: Workflow): number =>
  msToWeeks(getWorkflowLength(workflow));

export const getLengthInWeeksString = (
  startDate: string,
  endDate: string
): string =>
  `${Math.ceil(
    (parseInt(endDate, 10) - parseInt(startDate, 10)) /
      (1000 * 60 * 60 * 24 * 7)
  )}w`;

export const addAlphaToColor = (color: string, opacity: number): string => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};
