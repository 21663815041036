import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const DuplicateIcon = (props: DuplicateIconProps): JSX.Element => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
        fill={color}
      />
    </svg>
  );
};

DuplicateIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.primary.basic,
};

type DuplicateIconProps = BaseIconProps & {
  x?: number;
  y?: number;
};
