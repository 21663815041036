import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const GridView2Icon = ({ width, height, color }: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2.5H7.5V7.5H2.5V2.5ZM0.5 0.5H2.5H7.5H9.5V2.5V7.5V9.5H7.5H2.5H0.5V7.5V2.5V0.5ZM2.5 14.5H7.5V19.5H2.5V14.5ZM0.5 12.5H2.5H7.5H9.5V14.5V19.5V21.5H7.5H2.5H0.5V19.5V14.5V12.5ZM19.5 14.5H14.5V19.5H19.5V14.5ZM14.5 12.5H12.5V14.5V19.5V21.5H14.5H19.5H21.5V19.5V14.5V12.5H19.5H14.5ZM14.5 2.5H19.5V7.5H14.5V2.5ZM12.5 0.5H14.5H19.5H21.5V2.5V7.5V9.5H19.5H14.5H12.5V7.5V2.5V0.5Z"
        fill={color}
      />
    </svg>
  );
};
GridView2Icon.defaultProps = {
  width: "22",
  height: "22",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};
