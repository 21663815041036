import { RouteComponentProps } from "@reach/router";
import React from "react";

type SideBarsContextType = {
  sideBarOpen: boolean;
  setSideBarOpen: (open: boolean) => void;
  sideDrawboardOpen: boolean;
  setSideDrawboardOpen: (open: boolean) => void;
  navBarOpen: boolean;
  setNavBarOpen: (open: boolean) => void;
};

type SideBarsContextProviderPropsT = RouteComponentProps & {
  isSideBarOpen?: boolean;
  isSideDrawboardOpen?: boolean;
  isNavBarOpen?: boolean;
};

export const SideBarsContext = React.createContext<SideBarsContextType>({
  get sideBarOpen(): boolean {
    throw new Error("SideBarsContext not defined");
  },
  setSideBarOpen(open: boolean) {
    throw new Error("SideBarsContext not defined");
  },
  get sideDrawboardOpen(): boolean {
    throw new Error("SideBarsContext not defined");
  },
  setSideDrawboardOpen(open: boolean) {
    throw new Error("SideBarsContext not defined");
  },
  get navBarOpen(): boolean {
    throw new Error("SideBarsContext not defined");
  },
  setNavBarOpen(open: boolean) {
    throw new Error("SideBarsContext not defined");
  },
});

export const SideBarsContextProvider: React.FC<SideBarsContextProviderPropsT> =
  ({ children, isSideBarOpen, isSideDrawboardOpen, isNavBarOpen }) => {
    const [sideBarOpen, setSideBarOpen] = React.useState<boolean>(
      isSideBarOpen !== undefined ? isSideBarOpen : true
    );

    const [sideDrawboardOpen, setSideDrawboardOpen] = React.useState<boolean>(
      isSideDrawboardOpen !== undefined ? isSideDrawboardOpen : false
    );

    const [navBarOpen, setNavBarOpen] = React.useState<boolean>(
      isNavBarOpen !== undefined ? isNavBarOpen : true
    );

    return (
      <SideBarsContext.Provider
        value={{
          sideBarOpen,
          setSideBarOpen,
          sideDrawboardOpen,
          setSideDrawboardOpen,
          navBarOpen,
          setNavBarOpen,
        }}
      >
        {children}
      </SideBarsContext.Provider>
    );
  };
