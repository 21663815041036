import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const FullscreenIcon = ({
  width,
  height,
  color,
  ...props
}: BaseIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.625 8.75L17.5 1.875M17.5 1.875V7.5M17.5 1.875H11.875"
        stroke={styledTheme.newColors.primary.basic}
        strokeWidth="2"
      />
      <path
        d="M2 7V2H6"
        stroke={styledTheme.newColors.primary.basic}
        strokeWidth="2"
      />
      <path
        d="M18 13V18H14"
        stroke={styledTheme.newColors.primary.basic}
        strokeWidth="2"
      />
      <path
        d="M8.75 10.625L1.875 17.5M1.875 17.5V11.875M1.875 17.5H7.5"
        stroke={styledTheme.newColors.primary.basic}
        strokeWidth="2"
      />
      <rect x="0" y="0" width="100%" height="100%" fill="transparent" />
    </svg>
  );
};
FullscreenIcon.defaultProps = {
  width: "20",
  height: "20",
  color: styledTheme.newColors.grayscale.white,
};
