import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InMemoryCache } from "apollo-cache-inmemory";
import { useMatch } from "@reach/router";
import { EditSubtaskInformations } from "./EditSubtaskInformations";
import { useActionContext } from "../../../../utils/hooks/useActionContext";
import { styledTheme } from "../../../../theme/theme";
import { EditSubtaskCollaboratorsWrapper } from "./EditSubtaskCollaboratorsWrapper";
import {
  EntityStatus,
  ProjectUser,
  UserProjectDocument,
  useUpdateServiceFromWorkflowMutation,
  WorkflowForCustomer,
} from "../../../../generated/graphql";
import {
  SelectedEntity,
  SelectedServicePath,
} from "../../../../utils/types/ActionContextTypes";
import { ReadQueryResult } from "../../../../utils/types/QueryResult";
import { updateServices } from "../../../../utils/helpers/updateServices";
import { useUserRolesContext } from "../../../../utils/hooks/useUserRolesContext";
import { ROUTES } from "../../../../constants/routeConstants";
import { StatusDropdown } from "../../../common/StatusDropdown/StatusDropdown";
import { ROLES } from "../../../../constants/roles";

type EditSubtaskProps = {
  parentEnabledWorkflow: WorkflowForCustomer | undefined;
  editMode?: boolean;
  hardcodedUser?: ProjectUser;
  userRoleInSample?: ROLES;
};

export const EditSubtask = (props: EditSubtaskProps): JSX.Element => {
  const { parentEnabledWorkflow, hardcodedUser, userRoleInSample } = props;

  const { selectedEntity, setSelectedEntity } = useActionContext();
  const { useUserPermissions } = useUserRolesContext();
  const [updateServiceFromWorkflow] = useUpdateServiceFromWorkflowMutation();

  if (!selectedEntity) return <></>;

  const [serviceStatus, setServiceStatus] = useState<EntityStatus | null>(null);

  let editMode = useUserPermissions(
    "editServiceInformations",
    userRoleInSample
  );

  if (useMatch(ROUTES.USER_TASKS) || useMatch(ROUTES.GROUP_TASKS)) {
    editMode = false;
  }

  const {
    projectId,
    sampleId,
    workflowId,
    service: { id },
    originPathId,
  } = selectedEntity.selectedElementPath as SelectedServicePath;

  if (sampleId !== originPathId) editMode = false;

  const updateService = (target: string, value: string): void => {
    const options = {
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        workflowInput: {
          workflowId,
          workflowDesignation: "enabledWorkflows",
        },
        serviceUpdatables: {
          id,
          [target]: value,
        },
      },
      update(cache: InMemoryCache, { data }: any): void {
        const incomingData = data?.["UpdateServiceFromWorkflow"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: projectId },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          const samplesHere = [...existingQueryResult.samples];
          const sampleHere = samplesHere.find((s) => s.id === sampleId);

          const updatedEnabledWorkflows = sampleHere?.enabledWorkflows.map(
            (workflow) => ({
              ...workflow,
              services: updateServices(workflow.services, incomingData),
            })
          );

          const newSamples = samplesHere.map((sampleLoop) => {
            if (sampleLoop.id === sampleId) {
              return {
                ...sampleLoop,
                enabledWorkflows: updatedEnabledWorkflows,
              };
            }
            return sampleLoop;
          });

          cache.writeQuery({
            variables: { id: projectId },
            query: UserProjectDocument,
            data: {
              Project: {
                ...existingQueryResult,
                samples: newSamples,
              },
            },
          });
        }
      },
    };

    updateServiceFromWorkflow(options);
  };

  const handleStatusChange = (status: EntityStatus): void => {
    setServiceStatus(status);
    updateService("status", status);

    const newSelectedEntity: SelectedEntity = {
      ...selectedEntity,
    };
    (
      newSelectedEntity.selectedElementPath as SelectedServicePath
    ).service.status = status;

    setSelectedEntity(newSelectedEntity);
  };

  useEffect(() => {
    const { service } =
      selectedEntity.selectedElementPath as SelectedServicePath;

    setServiceStatus(service.status);
  }, [selectedEntity]);

  return (
    <>
      {selectedEntity && (
        <>
          {editMode && (
            <MarkCompleteContainer>
              <StatusDropdown
                onStatusChange={handleStatusChange}
                status={serviceStatus}
                readonly={!editMode}
              />
            </MarkCompleteContainer>
          )}
          <EditSubtaskInformations
            editMode={editMode}
            hardcodedUser={hardcodedUser}
          />
          <CollaboratorsContainer>
            <CollaboratorsHeader>Collaborators and Groups</CollaboratorsHeader>
            <EditSubtaskCollaboratorsWrapper
              parentEnabledWorkflow={parentEnabledWorkflow}
              editMode={editMode}
            />
          </CollaboratorsContainer>
        </>
      )}
    </>
  );
};

const CollaboratorsContainer = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  height: 100px;
`;

const CollaboratorsHeader = styled.div`
  font-family: Overpass;
  font-size: 14px;
  line-height: 16px;
  color: ${styledTheme.newColors.primary.basic};
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-self: flex-start;
`;

const MarkCompleteContainer = styled.div`
  padding: 18px 24px;
  width: 100%;
  border-bottom: 1px solid
    ${(props): string => props.theme.newColors.grayscale.bordersInside};
`;
