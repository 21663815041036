import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Option } from "../../../utils/types/QuestionMetadata";
import { EntityStatus } from "../../../generated/graphql";
import { Dropdown } from "../Dropdown/Dropdown";

const dropdownOptions = [
  { label: "Finished", value: EntityStatus.Finished },
  { label: "Ongoing", value: EntityStatus.Ongoing },
  { label: "Approved", value: EntityStatus.Approved },
  { label: "Pending", value: EntityStatus.Pending },
  { label: "Cancelled", value: EntityStatus.Cancelled },
];

interface StatusDropdownProps {
  readonly?: boolean;
  status: EntityStatus | null;
  onStatusChange: (status: EntityStatus) => void;
  className?: string;
  menuPortalTarget?: HTMLElement | Element | null | undefined;
}

export const StatusDropdown = (props: StatusDropdownProps): JSX.Element => {
  const {
    readonly = false,
    status,
    onStatusChange,
    className,
    menuPortalTarget,
  } = props;

  const [selectedOption, setSelectedOption] = useState<Option>(
    dropdownOptions[3]
  );

  const onChange = (option: Option): void => {
    onStatusChange(option.value as EntityStatus);
  };

  useEffect(() => {
    const option =
      dropdownOptions.find(
        (dropdownOption) => dropdownOption.value === status
      ) || dropdownOptions[3];
    setSelectedOption(option);
  }, [status]);

  if (readonly) {
    return <>{selectedOption.label}</>;
  }

  return (
    <StyledDropdown
      options={dropdownOptions}
      value={selectedOption}
      onChange={onChange}
      className={className}
      menuPortalTarget={menuPortalTarget}
    />
  );
};

const StyledDropdown = styled(Dropdown)`
  width: 200px;
`;
