import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const UploadFileIcon = (props: BaseIconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6C11.5128 6 11.9355 6.38604 11.9933 6.88338L12 7V9C12 10.5977 10.7511 11.9037 9.17627 11.9949L9 12H3C1.40232 12 0.0963391 10.7511 0.00509269 9.17627L0 9V7C0 6.44772 0.447715 6 1 6C1.51284 6 1.93551 6.38604 1.99327 6.88338L2 7V9C2 9.51284 2.38604 9.93551 2.88338 9.99327L3 10H9C9.51284 10 9.93551 9.61396 9.99327 9.11662L10 9V7C10 6.44772 10.4477 6 11 6ZM5.85153 0.0110178L5.90997 0.00399798L6 0L6.07524 0.00278786L6.20073 0.0202401L6.31214 0.0497381L6.42322 0.0936734L6.52071 0.145995L6.62545 0.219689L6.70711 0.292893L8.70711 2.29289C9.09763 2.68342 9.09763 3.31658 8.70711 3.70711C8.31658 4.09763 7.68342 4.09763 7.29289 3.70711L7 3.415V7C7 7.55228 6.55228 8 6 8C5.44772 8 5 7.55228 5 7V3.414L4.70711 3.70711C4.31658 4.09763 3.68342 4.09763 3.29289 3.70711C2.90237 3.31658 2.90237 2.68342 3.29289 2.29289L5.29289 0.292893C5.32829 0.257499 5.36567 0.225313 5.40469 0.196335L5.51594 0.124671L5.62866 0.0712255L5.73401 0.0358451L5.85153 0.0110178Z"
        fill={color}
      />
    </svg>
  );
};

UploadFileIcon.defaultProps = {
  width: "12",
  height: "12",
  color: styledTheme.newColors.grayscale.white,
};
