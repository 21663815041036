import * as React from "react";
import { FunctionComponent } from "react";
import { FieldProps } from "formik";
import { FormGroup } from "./FormGroup";
import { QuestionMetadata } from "../../../utils/types/QuestionMetadata";
import {
  FileUploader,
  FileUploaderPropsT,
} from "../../common/FileUploader/FileUploader";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  description: string;
  answerQuestionCallback: (metadata: QuestionMetadata, answer: any) => void;
  metadata: QuestionMetadata;
  disabled?: boolean;
}

type OwnProps = FieldProps & Props;

export const FileField: FunctionComponent<OwnProps> = (props) => {
  const {
    field,
    label,
    description,
    answerQuestionCallback,
    metadata,
    disabled,
  } = props;

  const onChange: FileUploaderPropsT["onChange"] = (uploadedFilesData) =>
    answerQuestionCallback(metadata, uploadedFilesData);

  return (
    <FormGroup label={label} description={description}>
      <FileUploader field={field} onChange={onChange} disabled={disabled} />
    </FormGroup>
  );
};
