// @flow
import * as React from "react";
import styled from "styled-components";
import { EventHandlerProps } from "../../../utils/types/EventHandlerPropsType";
import { ButtonType } from "./ButtonType";
import { primaryButton } from "./PrimaryButton";
import { secondaryButton } from "./SecondaryButton";
import { tertiaryButton } from "./TertiaryButton";
import { quaternaryButton } from "./QuaternaryButton";

const StyledButton = styled.button<{
  buttonType?: ButtonType;
  disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 6px 16px;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  ${({ theme, buttonType }) => {
    switch (buttonType) {
      default:
      case ButtonType.Primary:
        return primaryButton;
      case ButtonType.Secondary:
        return secondaryButton;
      case ButtonType.Tertiary:
        return tertiaryButton;
      case ButtonType.Quaternary:
        return quaternaryButton;
    }
  }};

  border-radius: 4px;

  &:focus {
    outline: none;
  }

  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const ButtonIcon = styled.div`
  top: 5px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = (props: Props) => {
  const { icon, label, type, disabled, className } = props;

  const onClick = disabled
    ? (): void => {}
    : (event: any): void => {
        // TODO fix any
        event.stopPropagation();
        if (props.onClick) {
          props.onClick(event);
        }
      };

  return (
    <StyledButton
      data-testid="button"
      type="button"
      onClick={onClick}
      buttonType={type}
      disabled={disabled}
      className={className}
    >
      {icon && <ButtonIcon data-testid="button-icon">{icon}</ButtonIcon>}
      <div data-testid="button-label">{label}</div>
    </StyledButton>
  );
};

type Props = EventHandlerProps & {
  icon?: React.ReactNode;
  label: string;
  type: ButtonType;
  disabled?: boolean;
  className?: string;
};

Button.defaultProps = {
  type: ButtonType.Primary,
};
