import React, { useState } from "react";
import styled from "styled-components";
import { ApolloError } from "apollo-client";
import { Modal } from "../../../../common/Modal/Modal";
import { ButtonType } from "../../../../common/Button/ButtonType";
import { Button } from "../../../../common/Button/Button";
import {
  useMoveAllWorkflowsInSampleMutationMutation,
  UserProjectDocument,
} from "../../../../../generated/graphql";
import { useActionContext } from "../../../../../utils/hooks/useActionContext";
import { SelectedElementPath } from "../../../../../utils/types/ActionContextTypes";
import { useSideBarsContext } from "../../../../../utils/hooks/useSideBarsState";
import { DatePicker } from "../../../../common/DatePicker/DatePicker";

type MoveAllServicesToNewStartDateModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const MoveAllServicesToNewStartDateModal = (
  props: MoveAllServicesToNewStartDateModalProps
): JSX.Element => {
  const { isOpen, onClose } = props;

  const { selectedEntity, setSelectedEntity } = useActionContext();
  const { setSideDrawboardOpen } = useSideBarsContext();

  const [newStartDate, setNewStartDate] = useState<Date>(new Date());
  const [selectedDatePickerId, setSelectedDatePickerId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [moveServicesToNewStartDate] =
    useMoveAllWorkflowsInSampleMutationMutation();

  if (!selectedEntity || !selectedEntity.selectedElementPath) return <></>;

  const onAcceptModal = (): void => {
    if (!newStartDate) return;

    setIsLoading(true);

    const { projectId, sampleId } =
      selectedEntity.selectedElementPath as SelectedElementPath;

    const newStartDateWithCorrectHours = new Date(
      newStartDate.setHours(0, 0, 0, 0)
    );

    moveServicesToNewStartDate({
      variables: {
        projectSampleInput: {
          projectId,
          sampleId,
        },
        newStartDate: newStartDateWithCorrectHours.getTime().toString(),
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: { id: projectId },
        },
      ],
      awaitRefetchQueries: true,
    })
      .then(() => {
        setSelectedEntity(undefined);
        setSideDrawboardOpen(false);
        setIsLoading(false);
        onClose();
      })
      .catch((e: ApolloError) => {
        setIsLoading(false);
      });
  };

  const ModalButtons = (): JSX.Element => (
    <>
      <AcceptButton
        disabled={isLoading}
        label="Move All Tasks and Services"
        type={ButtonType.Primary}
        onClick={onAcceptModal}
      />
      <Button
        disabled={isLoading}
        label="Cancel"
        type={ButtonType.Secondary}
        onClick={onClose}
      />
    </>
  );

  if (!isOpen) return <></>;

  return (
    <StyledModal
      id="moveAllServicesToNewStartDate"
      title="Move All Services To New Start Date"
      footerContent={<ModalButtons />}
      onClose={onClose}
      isLoading={isLoading}
    >
      <StyledLineContainer>
        <DatePicker
          date={newStartDate}
          setDate={setNewStartDate}
          editMode
          showIcon
          selectedDatePickerId={selectedDatePickerId}
          setSelectedDatePickerId={setSelectedDatePickerId}
          datePickerId={10}
        />
      </StyledLineContainer>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .customModalDialog {
    max-width: 55%;
  }

  .modal-content-wrapper {
    background: white;
  }
`;

const AcceptButton = styled(Button)`
  margin-right: 10px;
`;

const StyledLineContainer = styled.div`
  width: 180px;
`;

// const StyledDropdown = styled(Dropdown)`
//   width: 100%;
//
//   .Select__option:last-child {
//     border-top: 1px solid #dedede;
//   }
//
//   .Select__option:only-child {
//     border-top: transparent;
//   }
// `;
