import * as React from "react";
import { RouteComponentProps } from "@reach/router";

export const Result = (props: ResultProps) => {
  return (
    <div>
      Result {props.resultId} - for project - {props.projectId}
    </div>
  );
};

interface ResultProps extends RouteComponentProps {
  projectId?: string;
  resultId?: string;
}
