import * as React from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode[];
};
export const Masonry = (props: Props) => {
  const children = [...props.children];

  const firstColumn = children.slice(0, Math.round(children.length / 2));
  const secondColumn = children.slice(
    Math.round(children.length / 2),
    children.length
  );

  return (
    <Container>
      <Column>{firstColumn}</Column>
      <Column>{secondColumn}</Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-left: -16px;
  width: auto;
  flex-wrap: wrap;
`;

const Column = styled.div`
  padding-left: 16px;
  background-clip: padding-box;

  & > div {
    margin-bottom: 17px;
  }
`;
