import styled, { css } from "styled-components";

interface InputProps {
  error?: boolean;
  svgIcon?: string;
  placeholder?: string | null;
}

export const BaseInput = styled.input<InputProps>`
  padding: 12px 16px;
  width: 100%;
  min-width: 0;

  border: 1px solid ${(props) => props.theme.newColors.grayscale.bordersInside};
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.shadows.userInputs.basicInside};

  font-size: ${(props) => props.theme.fontSizes[2]};
  font-style: normal;
  font-weight: normal;
  line-height: ${(props) => props.theme.lineHeights[4]};
  color: ${(props) => props.theme.newColors.grayscale.primaryGray};
  letter-spacing: -0.01em;

  :hover {
    border: 1px solid ${(props) => props.theme.newColors.grayscale.placeholder};
    box-shadow: ${({ theme }) => theme.shadows.userInputs.hoveredState};
    outline: none;
  }

  :focus {
    border: 1px solid ${(props) => props.theme.newColors.primary.basic};
    box-shadow: ${({ theme }) => theme.shadows.userInputs.activeState};
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.newColors.grayscale.placeholder};
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.newColors.grayscale.placeholder};
  }

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${({ theme }) => theme.newColors.extra.errorState};
      box-shadow: ${({ theme }) => theme.shadows.userInputs.errorState};
    `}

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.newColors.grayscale.placeholder};
      background: ${({ theme }) => theme.newColors.grayscale.disabled};
      box-shadow: none;
    `}
    
    ${(props) => {
    if (props.svgIcon) {
      return `
        background-image: url("data:image/svg+xml,${props.svgIcon}");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 18px;
        padding-left: 44px;
        `;
    }
    return "";
  }}
`;
