import React, { useState } from "react";
import styled from "styled-components";
import { FomSchemaRendererField } from "../../../utils/types/QuestionMetadata";
import { FormGroup } from "./FormGroup";
import { getFormElement } from "../../../utils/helpers/dynamicForms/getFormElement";
import { SimpleUnitGroup } from "./SimpleUnitGroup";
import { styledTheme } from "../../../theme/theme";
import { ChainedIcon } from "../../../images/icons/ChainedIcon";
import {
  getFieldStatus,
  GetFieldStatusResultT,
  LabelTagTypes,
} from "../../common/LabelTag/LabelTag";
import { useFieldStatusTimeout } from "../../../utils/hooks/useFieldStatusTimeout";

const chunkArrayInGroups = (arr: any[], size: number) => {
  const myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};

const getFormSchemaElements = (
  formSchema: FomSchemaRendererField["formSchemaItems"],
  indexArray: string[]
): FomSchemaRendererField["formSchemaItems"] => {
  const resultArray: FomSchemaRendererField["formSchemaItems"] = [];
  indexArray.forEach((indexArrayElement) => {
    resultArray[indexArrayElement] = formSchema[indexArrayElement];
  });
  return resultArray;
};

export const ProteinSequenceRenderer = (
  props: ProteinSequenceRendererPropsT
) => {
  const { parentId, formSchemaItems, isRequired, filledRequiredItemsIds } =
    props;

  const [fieldStatus, setFieldStatus] = useState<GetFieldStatusResultT>();
  useFieldStatusTimeout(fieldStatus, setFieldStatus);

  const updateStatus = (status: GetFieldStatusResultT) => {
    setFieldStatus(status);
  };

  const formSchemaItemsKeys = Object.keys(formSchemaItems);

  const mainElementsIndex = formSchemaItemsKeys.slice(0, 2);
  const proteinElementsIndex = chunkArrayInGroups(
    formSchemaItemsKeys.slice(2),
    2
  );
  const proteinElementsIndexLength = proteinElementsIndex.length - 1;
  const firstElementIndex = mainElementsIndex[0];
  const firstElement = formSchemaItems[firstElementIndex];

  const statusValue = filledRequiredItemsIds.includes(parentId)
    ? "filled"
    : undefined;
  let status;
  if (fieldStatus?.type === LabelTagTypes.SAVING) {
    status = fieldStatus;
  } else {
    status = getFieldStatus(isRequired, statusValue, statusValue);
  }

  return (
    <FormGroup
      label={firstElement.label}
      description={firstElement.description || ""}
      status={isRequired ? status : undefined}
    >
      <NoLabelContainer>
        {mainElementsIndex.map((mainElementIndex) =>
          getFormElement(
            `${mainElementIndex}`,
            formSchemaItems[mainElementIndex],
            updateStatus
          )
        )}
      </NoLabelContainer>
      <LineContainer width={32} margin={8}>
        <span>or</span>
      </LineContainer>
      <ProteinsContainer>
        {proteinElementsIndex.map((proteinElementIndex, index) => (
          <>
            <SimpleUnitGroup
              parentId="0"
              isRequired={false}
              formSchemaItems={getFormSchemaElements(
                formSchemaItems,
                proteinElementIndex
              )}
              filledRequiredItemsIds={[]}
              updateStatus={updateStatus}
            />
            {index < proteinElementsIndexLength ? (
              <LineContainer
                width={4}
                margin={5}
                style={{ marginBottom: "18px" }}
              >
                <span>
                  <ChainedIcon />
                </span>
              </LineContainer>
            ) : null}
          </>
        ))}
      </ProteinsContainer>
    </FormGroup>
  );
};

type ProteinSequenceRendererPropsT = {
  parentId: string;
  formSchemaItems: FomSchemaRendererField["formSchemaItems"];
  isRequired: boolean;
  filledRequiredItemsIds: string[];
};

const NoLabelContainer = styled.div`
  > div {
    > div:first-child {
      display: none;
    }

    > hr {
      display: none;
    }
  }
`;

const ProteinsContainer = styled.div`
  display: flex;
  align-items: flex-end;

  margin-top: 20px;
  > div {
    margin-right: 16px;
    margin-left: 16px;

    > hr {
      display: none;
    }
  }

  > div:first-child {
    margin-left: 0;
  }

  > div:last-child {
    margin-right: 0;
  }
`;

const LineContainer = styled.div<{ width: number; margin: number }>`
  margin-top: 20px;
  margin-left: ${({ width, margin }) => width + margin}px;
  margin-right: ${({ width, margin }) => width + margin}px;

  font-family: Bio Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${styledTheme.colors.lightText};

  span {
    display: inline-block;
    position: relative;
  }
  span:before,
  span:after {
    content: "";
    position: absolute;
    height: 8px;
    border-bottom: 1px solid ${styledTheme.colors.lightText};
    top: 0;
    width: ${({ width }) => width}px;
  }
  span:before {
    right: 100%;
    margin-right: ${({ margin }) => margin}px;
  }
  span:after {
    left: 100%;
    margin-left: ${({ margin }) => margin}px;
  }
`;
