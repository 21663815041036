import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import styled from "styled-components";
import { ValueType } from "react-select";
import moment from "moment";
import { useAuth } from "react-use-auth";
import { LayoutDefault } from "../../Layouts/LayoutDefault";
import { Spinner } from "../../common/Spinner/Spinner";
import { Dropdown } from "../../common/Dropdown/Dropdown";
import { useProjectTasks } from "./useProjectTasks";
import { SimplifiedTasks, TasksTable } from "./TasksTable/TasksTable";
import { getSampleName } from "../../../utils/helpers/getSampleName";
import {
  Currency,
  EntityStatus,
  ServiceForCustomer,
  WorkflowForCustomer,
} from "../../../generated/graphql";
import { definedColors } from "../../SideDrawboard/InformationsContainer/ColorChooser";
import { useUserRolesContext } from "../../../utils/hooks/useUserRolesContext";
import { ROLES } from "../../../constants/roles";

const statuses = [
  { label: "Finished", value: EntityStatus.Finished },
  { label: "Ongoing", value: EntityStatus.Ongoing },
  { label: "Approved", value: EntityStatus.Approved },
  { label: "Pending", value: EntityStatus.Pending },
  { label: "Cancelled", value: EntityStatus.Cancelled },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProjectTasks = (_props: ProjectTasksProps): JSX.Element => {
  const { projectId } = useParams();
  const { useUserPermissions } = useUserRolesContext();
  const { user } = useAuth();

  const {
    error,
    loading,
    samples,
    setSelectedSample,
    users,
    selectedSample,
    workflows,
  } = useProjectTasks({ projectId });

  const [tasks, setTasks] = useState<SimplifiedTasks[]>([]);

  if (error) {
    return <p>Query Error: {error}</p>;
  }

  const handleDropdownChange = async (
    selectedValue: ValueType<{ label: string; value: string }, boolean>
  ): Promise<void> => {
    setSelectedSample(selectedValue as { label: string; value: string });
  };

  const samplesDropdown = samples.map((sample) => ({
    value: sample.id,
    label: getSampleName(sample),
  }));

  const getColorFromIndex = (index: number): string => {
    const colorIndex = index % definedColors.length;
    const color = definedColors[colorIndex];

    return color.color;
  };

  const getTasks = (
    workflowsToChange: WorkflowForCustomer[]
  ): SimplifiedTasks[] => {
    let preferredCurrency: Currency | string = Currency.Eur;
    try {
      preferredCurrency =
        localStorage.getItem("preferredCurrency") || Currency.Eur;
    } catch (err) {
      // do nothing
    }

    return workflowsToChange.map((workflow) => {
      const workflowIndex = workflowsToChange.findIndex(
        (enabledWorkflow) => workflow.id === enabledWorkflow.id
      );

      const subtasks = workflow.services.map((service: ServiceForCustomer) => {
        const subtaskStartDate = new Date(+service.startDate).setHours(
          0,
          0,
          0,
          0
        );
        const subtaskEndDate = new Date(+service.endDate).setHours(
          23,
          59,
          59,
          59
        );

        const durationDays =
          moment(subtaskEndDate).diff(moment(subtaskStartDate), "days") + 1;

        const duration = `${durationDays} ${
          durationDays && durationDays > 1 ? "Days" : "Day"
        }`;

        const foundAssignee = users.find((u) => u.id === service.assignee);

        const assignee = foundAssignee
          ? {
              id: foundAssignee.id,
              name: foundAssignee.name,
              picture: foundAssignee.avatar,
            }
          : undefined;

        const budget = {
          price: service.price?.price.value || "",
          currency: service.price?.currency || preferredCurrency,
        };

        return {
          id: service.id,
          name: service.name,
          status:
            statuses.find((status) => status.value === service.status)?.value ||
            statuses[3].value,
          startDate: new Date(subtaskStartDate),
          endDate: new Date(subtaskEndDate),
          duration,
          durationLocked: service.durationLocked || false,
          budget,
          assignee,
          collaborators: service.collaborators || undefined,
        };
      });

      const startDates = workflow.services.map((service) => +service.startDate);

      const endDates = workflow.services.map((service) => +service.endDate);

      const workflowStartDate = new Date(Math.min(...startDates)).setHours(
        0,
        0,
        0,
        0
      );

      const workflowEndDate = new Date(Math.max(...endDates)).setHours(
        23,
        59,
        59,
        59
      );

      const durationDays =
        moment(workflowEndDate).diff(moment(workflowStartDate), "days") + 1;

      const duration = `${durationDays} ${
        durationDays && durationDays > 1 ? "Days" : "Day"
      }`;

      const foundAssignee = users.find((u) => u.id === workflow.assignee);

      const assignee = foundAssignee
        ? {
            id: foundAssignee.id,
            name: foundAssignee.name,
            picture: foundAssignee.avatar,
          }
        : undefined;

      const budget = {
        price: workflow.price?.price.value || "",
        currency: workflow.price?.currency || preferredCurrency,
      };

      return {
        id: workflow.id,
        color: getColorFromIndex(workflowIndex),
        name: workflow.name,
        assignee,
        startDate: new Date(workflowStartDate),
        endDate: new Date(workflowEndDate),
        duration,
        budget,
        subtasks,
        collaborators: workflow.collaborators || undefined,
      };
    });
  };

  const reloadTasks = (): void => {
    setTasks(getTasks(workflows));
  };

  useEffect(() => {
    setTasks(getTasks(workflows));
  }, [workflows]);

  const userRoleInSample = samples
    ?.find((s) => s.id === selectedSample.value)
    ?.assignedUsers?.find((assignedUser) => assignedUser?.id === user.sub)
    ?.role as ROLES | undefined;

  const editServiceAvailable = useUserPermissions(
    "editServiceInformations",
    userRoleInSample
  );

  return (
    <LayoutDefault pageTitle="Project Tasks Table">
      <DropdownContainer>
        <StyledDropdown
          options={samplesDropdown}
          onChange={handleDropdownChange}
          value={selectedSample}
          menuPortalTarget={null}
        />
      </DropdownContainer>
      <TableContainer>
        <TasksTable
          projectId={projectId}
          sampleId={selectedSample.value}
          tasks={tasks}
          setTasks={setTasks}
          reloadTasks={reloadTasks}
          readonly={!editServiceAvailable}
        />
      </TableContainer>
      {loading && (
        <SpinnerContainer>
          <Spinner containerSize opaque />
        </SpinnerContainer>
      )}
    </LayoutDefault>
  );
};

type ProjectTasksProps = RouteComponentProps;

const DropdownContainer = styled.div`
  margin-bottom: 16px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 36px;
  row-gap: 40px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px #d1d5d8;
  border-radius: 8px;
  border: 1px solid #dedede;
`;

const StyledDropdown = styled(Dropdown)`
  padding: 0;
  max-width: 294px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
