import { BaseService, ServiceForCustomer } from "../../generated/graphql";

export const updateServices = (
  servicesArray: BaseService[] & ServiceForCustomer[],
  changedServiceArray: BaseService[] & ServiceForCustomer[]
): BaseService[] & ServiceForCustomer[] => {
  return servicesArray
    .map((service) => {
      const matchingService = changedServiceArray.find(
        (s) => s.id === service.id
      );
      if (matchingService) {
        return {
          ...service,
          ...matchingService,
        };
      }
      return service;
    })
    .sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
};
