import React from "react";
import styled from "styled-components";
import { ChainIcon } from "../../../images/icons/ChainIcon";
import { styledTheme } from "../../../theme/theme";

export const ServiceStatus = (props: ServiceStatusPropsT) => {
  const { name, sampleName, remaining } = props;
  return (
    <Container>
      <ChainIconContainer>
        <ChainIcon
          color="#141419"
          secondColor={styledTheme.newColors.grayscale.white}
          width="16px"
          height="16px"
        />
      </ChainIconContainer>
      <NameContainer>
        <Name>{name}</Name>
        <Info>
          {sampleName} &bull; {remaining}
        </Info>
      </NameContainer>
    </Container>
  );
};

type ServiceStatusPropsT = {
  name: string;
  sampleName: string;
  remaining: string;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const NameContainer = styled.div`
  margin-left: 8px;
`;

const ChainIconContainer = styled.div`
  padding: 0;
  font-size: 1px;
  transform: scale(1.4);
`;

const Name = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${styledTheme.newColors.grayscale.primaryGray};
`;

const Info = styled.div`
  font-family: Overpass;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
`;
