import React from "react";
import styled from "styled-components";
import { styledTheme } from "../../theme/theme";
import { MessageBox } from "../common/MessageBox/MessageBox";

export const NoOrganizationAssignMessageBox = (): JSX.Element => (
  <Container>
    <MessageBox>
      <div>
        You have not been assigned to an organization. Contact us at email{" "}
        <MailContainer
          aria-hidden
          onClick={(): void => {
            window.location.href = "mailto:info@leadernaplatform.com";
          }}
        >
          info@leadernaplatform.com
        </MailContainer>{" "}
        for assignment to an organization
      </div>
    </MessageBox>
  </Container>
);

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MailContainer = styled.span`
  color: ${styledTheme.newColors.primary.basic};
  text-decoration: none;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    color: ${styledTheme.newColors.primary.hover};
    transition: 200ms;
    text-decoration: underline;
  }
`;
