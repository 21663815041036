import styled, { css } from "styled-components";
import { styledTheme } from "../../../../theme/theme";
import { Dropdown } from "../../../common/Dropdown/Dropdown";

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;
export const CollaboratorsSelection = styled.div<{
  spread?: boolean;
  editMode: boolean;
}>`
  font-size: 14px;
  color: ${styledTheme.newColors.grayscale.secondaryGray};
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  ${({ spread }) =>
    spread &&
    css`
      justify-content: space-between;
    `}

  ${({ editMode }) =>
    !editMode &&
    css`
      cursor: auto;
    `}
`;
export const PlusContainer = styled.div`
  color: ${styledTheme.newColors.primary.basic};
  font-size: 24px;
  margin: auto 5px;
  font-weight: bold;
`;
export const StyledDropDown = styled(Dropdown)`
  justify-self: flex-start;
  width: 100%;
`;
