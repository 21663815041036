import React from "react";
import styled from "styled-components";

interface CheckboxElementProps {
  id: string;
  handleCheckboxClick: (position: number) => void;
  position: number;
}

function CheckboxElement({
  id,
  handleCheckboxClick,
  position,
}: CheckboxElementProps) {
  return (
    <CheckboxWrapper onChange={() => handleCheckboxClick(position)}>
      <CheckboxInput type="checkbox" name={id} id={id} />
      <CheckboxLabel htmlFor={id}>
        <svg viewBox="0 0 100 100">
          <path
            className="box"
            d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"
          />
          <polyline className="check" points="25.5,53.5 39.5,67.5 72.5,34.5 " />
        </svg>
      </CheckboxLabel>
    </CheckboxWrapper>
  );
}

export default CheckboxElement;

const CheckboxWrapper = styled.div`
  border-radius: 0.3em;
  width: 35px;
  height: 35px;
  box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.04),
    -7px -7px 5px rgba(255, 255, 255, 0.8);
  border: 2px solid rgb(235, 235, 235);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  font-size: 0.8em;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    stroke: rgb(0, 124, 226);
    stroke-width: 5;
    fill: white;

    .box {
      stroke-dasharray: 320;
      stroke-dashoffset: 0;
      fill: white;
      transition: stroke-dashoffset 0.3s linear;
    }
    .check {
      stroke-dasharray: 70;
      stroke-dashoffset: 70;
      fill: none;
      transition: stroke-dashoffset 0.3s linear;
    }
  }

  span {
    padding-top: 0.3em;
    margin-left: 0.3em;
  }
`;

const CheckboxInput = styled.input`
  display: none;
  pointer-events: none;

  &:checked + label {
    .box {
      stroke-dashoffset: 320;
    }
    .check {
      stroke-dashoffset: 0;
    }
  }
`;
