import { Project } from "../../../generated/graphql";
import { getSampleName } from "../../../utils/helpers/getSampleName";

export type AssignedSample = {
  id: string;
  name: string;
  assigned: boolean;
  userId: string;
  role: string;
};

export const getPathsAssignedToUser = (
  project: Project,
  userId: string,
  userRole: string
): AssignedSample[] => {
  const filteredByUserExistenceInSamples = project.samples.filter((sample) =>
    sample.assignedUsers?.find((user) => user?.id === userId)
  );

  return project.samples.map((sample) => {
    const { id } = sample;
    const name = getSampleName(sample);

    if (!filteredByUserExistenceInSamples.length) {
      return {
        id,
        name,
        assigned: true,
        userId,
        role: userRole,
      };
    }

    if (!sample?.assignedUsers?.length) {
      return {
        id,
        name,
        assigned: false,
        userId,
        role: userRole,
      };
    }

    const foundUser = sample.assignedUsers.find(
      (assignedUser) => assignedUser?.id === userId
    );

    return {
      id,
      name,
      assigned: !!foundUser,
      userId,
      role: foundUser?.role || userRole,
    };
  });
};
