import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const HelpMenuIcon = (props: TeamIconProps): JSX.Element => {
  const { width, height, color, isActive } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35078 18L3.6247 21.7809C2.96993 22.3047 2 21.8385 2 21V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V15C22 16.6569 20.6569 18 19 18H8.35078ZM4 18.9194L7.37531 16.2191C7.55262 16.0773 7.77293 16 8 16H19C19.5523 16 20 15.5523 20 15V5C20 4.44772 19.5523 4 19 4H5C4.44772 4 4 4.44772 4 5V18.9194Z"
        fill={isActive ? "white" : color}
      />
    </svg>
  );
};

type TeamIconProps = BaseIconProps & {
  isActive?: boolean;
};

HelpMenuIcon.defaultProps = {
  width: "24",
  height: "24",
  color: styledTheme.newColors.grayscale.tertiaryGray,
};
