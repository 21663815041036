import { css } from "styled-components";
import { styledTheme } from "../../../theme/theme";

export const secondaryButton = css<{ disabled?: boolean }>`
  background: ${styledTheme.newColors.grayscale.white};
  color: ${styledTheme.newColors.secondary.basic};
  border: 1px solid ${styledTheme.newColors.grayscale.bordersOutside};

  svg path {
    fill: ${styledTheme.newColors.secondary.basic};
  }

  div svg {
    fill: ${styledTheme.newColors.secondary.basic};
  }

  &:hover {
    border: 1px solid ${styledTheme.newColors.grayscale.placeholder};
    color: ${styledTheme.newColors.secondary.darkTint};

    &:active {
      border-style: inset;
    }

    svg path {
      fill: ${styledTheme.newColors.secondary.darkTint};
    }

    svg {
      fill: ${styledTheme.newColors.secondary.darkTint};
    }
  }

  ${({ theme, disabled }) =>
    disabled &&
    css`
      background: ${theme.newColors.extra.tertiaryButtonBG};
      cursor: default;
      color: ${theme.newColors.grayscale.disabled};
      border: 1px solid ${theme.newColors.grayscale.disabled};

      &:hover {
        color: ${theme.newColors.grayscale.disabled};
        border: 1px solid ${theme.newColors.grayscale.disabled};

        &:active {
          border-style: outset;
        }

        svg path {
          fill: ${theme.newColors.grayscale.disabled};
        }

        svg {
          fill: ${theme.newColors.grayscale.disabled};
        }
      }

      svg path {
        fill: ${theme.newColors.grayscale.disabled};
      }

      svg {
        fill: ${theme.newColors.grayscale.disabled};
      }
    `}
`;
