import * as React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

type ImageMountainIconProps = BaseIconProps & {
  secondColor: string;
  thirdColor: string;
};

export const ImageMountainIcon = ({
  width,
  height,
  color,
  secondColor,
  thirdColor,
}: ImageMountainIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 1.79086 1.79086 0 4 0H40C42.2091 0 44 1.79086 44 4V40C44 42.2091 42.2091 44 40 44H4C1.79086 44 0 42.2091 0 40V4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1104 36.6669V31.778L30.3628 18.5255C31.1439 17.7445 32.4102 17.7445 33.1912 18.5255L36.0801 21.4144C36.4552 21.7895 36.6659 22.2982 36.6659 22.8286V34.6669C36.6659 35.7715 35.7705 36.6669 34.6659 36.6669H17.1104Z"
        fill={secondColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2219 17.1113C14.9218 17.1113 17.1108 14.9223 17.1108 12.2224C17.1108 9.5225 14.9218 7.3335 12.2219 7.3335C9.52201 7.3335 7.33301 9.5225 7.33301 12.2224C7.33301 14.9223 9.52201 17.1113 12.2219 17.1113ZM9.04011 34.9597C8.41015 35.5897 8.85632 36.6668 9.74722 36.6668H26.8886L18.525 28.3033C17.7439 27.5222 16.4776 27.5222 15.6966 28.3033L9.04011 34.9597Z"
        fill={thirdColor}
      />
    </svg>
  );
};
ImageMountainIcon.defaultProps = {
  width: "44",
  height: "44",
  color: styledTheme.colors.lightText,
  secondColor: styledTheme.colors.gray05,
  thirdColor: styledTheme.newColors.grayscale.white,
};
