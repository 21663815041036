/* eslint-disable react/prop-types */
import React from "react";
import { RouteComponentProps, useParams } from "@reach/router";
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ReadQueryResult } from "utils/types/QueryResult";
import {
  AnswerType,
  ProjectWithAllSamplesDocument,
  useEnableWorkflowInProjectSampleMutation,
  useProjectSampleWithMultiAnswerMutation,
  useProjectSampleWithNumericAnswerMutation,
  useProjectSampleWithTextAnswerMutation,
  UserProjectDocument,
  useServicesQuery,
  useUserProjectQuery,
} from "../generated/graphql";
import { Spinner } from "../components/common/Spinner/Spinner";
import { SampleConfigurator as SampleConfiguratorComponent } from "../components/Projects/Configurator/Samples/Configurator/SampleConfigurator";
import { QuestionMetadata } from "../utils/types/QuestionMetadata";
import { EnableWorkflowAction } from "../utils/types/WorkflowMetadata";

const runQuestionAnswerMutationAfterMs = 1000;
let lastMetadata: null | string = null;

export const SampleConfigurator = (props: RouteComponentProps) => {
  const urlQueryParams = useParams();
  const queryResult = useUserProjectQuery({
    variables: {
      id: urlQueryParams.projectId,
    },
  });
  const servicesQueryResult = useServicesQuery();
  const projectSampleWithMultiAnswer =
    useProjectSampleWithMultiAnswerMutation()[0];
  const projectSampleWithNumericAnswer =
    useProjectSampleWithNumericAnswerMutation()[0];
  const projectSampleWithTextAnswer =
    useProjectSampleWithTextAnswerMutation()[0];
  const enableWorkflowInProjectSampleMutation =
    useEnableWorkflowInProjectSampleMutation()[0];

  const getMutationBasedOnAnswerType = (answerType: AnswerType) => {
    switch (answerType) {
      case AnswerType.Number:
        return projectSampleWithNumericAnswer;
      case AnswerType.ManyOfMany:
        return projectSampleWithMultiAnswer;
      default:
        return projectSampleWithTextAnswer;
    }
  };

  const answerProjectSampleQuestion = (
    metadata: QuestionMetadata,
    answer: any
  ) => {
    if (!metadata.questionLevelInput.sampleId) {
      throw new Error("Metadata is missing sampleId");
    }

    const options = {
      variables: {
        answer,
        projectSampleQuestionInput: {
          questionInput: metadata.questionInput,
          projectSampleInput: {
            projectId: metadata.questionLevelInput.projectId,
            sampleId: metadata.questionLevelInput.sampleId,
          },
        },
      },
      refetchQueries: [
        {
          query: UserProjectDocument,
          variables: {
            id: urlQueryParams.projectId,
          },
        },
      ],
      awaitRefetchQueries: true,
    };

    if (metadata.questionInput.questionId === "name") {
      options.refetchQueries.push({
        query: ProjectWithAllSamplesDocument,
        variables: { id: metadata.questionLevelInput.projectId },
      });
    }

    const mutation = getMutationBasedOnAnswerType(metadata.answerType);
    if (metadata.answerType === AnswerType.Number) {
      options.variables.answer = parseFloat(options.variables.answer) || 0;
    }
    const mutationPromise: Promise<unknown> = mutation(options);
    mutationPromise.catch((e) => {
      toast.error(e.toString());
    });
  };

  if (queryResult.error || servicesQueryResult.error) {
    const error = queryResult.error || servicesQueryResult.error;
    return <p>Query Error: {error}</p>;
  }
  if (
    queryResult.loading ||
    !queryResult.data ||
    servicesQueryResult.loading ||
    !servicesQueryResult.data
  ) {
    return <Spinner />;
  }

  const project = queryResult.data.Project;
  const services =
    servicesQueryResult.data.Services.sort(
      (service1, service2) => service1.order - service2.order
    ) || [];

  if (!project.samples.length) {
    return <p>Query Error: no samples were defined in the project</p>;
  }

  const selectedSample = project.samples.find(
    (sample) => sample.id === urlQueryParams.sampleId
  );

  if (!selectedSample) {
    return <div>Sample not found</div>;
  }

  const workflows =
    selectedSample.suggestedWorkflows.length > 0
      ? selectedSample.suggestedWorkflows
      : selectedSample.selectedWorkflows || [];

  const answerProjectSampleQuestionDebouncedInternal = debounce(
    answerProjectSampleQuestion,
    runQuestionAnswerMutationAfterMs,
    {
      leading: false,
      trailing: true,
    }
  );

  const answerProjectSampleQuestionDebounced = (
    metadata: QuestionMetadata,
    answear: any
  ) => {
    if (lastMetadata !== JSON.stringify(metadata)) {
      lastMetadata = JSON.stringify(metadata);
      answerProjectSampleQuestionDebouncedInternal.flush();
    }
    answerProjectSampleQuestionDebouncedInternal(metadata, answear);
  };

  const getEnableWorkflowsOptions = (workflowId: string) => {
    return {
      variables: {
        projectSampleWorkflowEnableInput: {
          projectId: project.id,
          sampleId: selectedSample.id,
          workflowId,
        },
      },
      update(cache: InMemoryCache, { data }: any) {
        const incomingData = data?.["EnableWorkflowInProjectSample"];
        const existingQueryResult = cache.readQuery<ReadQueryResult>({
          query: UserProjectDocument,
          variables: { id: project.id },
        })?.["Project"];

        if (existingQueryResult && incomingData) {
          cache.writeQuery({
            variables: { id: project.id },
            query: UserProjectDocument,
            data: {
              Project: incomingData,
            },
          });
        }
      },
    };
  };

  const enableWorkflows: EnableWorkflowAction = async (
    selectedWorkflowsIds
  ) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const workflowId of selectedWorkflowsIds) {
      const options = getEnableWorkflowsOptions(workflowId);

      const mutationPromise: Promise<unknown> =
        enableWorkflowInProjectSampleMutation(options);
      mutationPromise.catch((e) => {
        toast.error(e.toString());
      });
      // eslint-disable-next-line no-await-in-loop
      await mutationPromise;
    }
  };

  return (
    <SampleConfiguratorComponent
      sample={selectedSample}
      project={project}
      answerProjectSampleQuestion={answerProjectSampleQuestionDebounced}
      enableWorkflowsForProjectSample={enableWorkflows}
      services={services}
      workflows={workflows}
      {...props}
    />
  );
};
