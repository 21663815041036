import * as React from "react";
import {
  UserRolesContext,
  UserRolesContextType,
} from "../../contexts/UserRolesContext";

export const useUserRolesContext = (): UserRolesContextType => {
  const context = React.useContext(UserRolesContext);

  if (context === undefined) {
    throw new Error("UserRolesContext not defined");
  }

  return context;
};
