import * as React from "react";
import styled from "styled-components";
import { ServiceIcon } from "../../../images/icons/ServiceIcon";

type Props = {
  title: string;
  description: string;
};
export const ServiceCardHeader = (props: Props) => {
  const { title, description } = props;
  return (
    <Container>
      <Icon>
        <ServiceIcon />
      </Icon>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.div`
  margin-right: 12px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;

  width: 100%;

  color: ${({ theme }) => theme.newColors.grayscale.primaryGray};
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.newColors.grayscale.secondaryGray};
`;
