import React from "react";
import { BaseIconProps } from "./BaseIconProps";
import { styledTheme } from "../../theme/theme";

export const LineWithDot = (props: LineWithDotProps): JSX.Element => {
  const { width, height, color, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
      className={className}
    >
      <path stroke={color} d="M0 3.5L19 3.5" />
      <circle cx="17.5" cy="3.5" r="3.5" fill={color} />
    </svg>
  );
};

LineWithDot.defaultProps = {
  width: "21",
  height: "7",
  color: styledTheme.newColors.primary.basic,
};

type LineWithDotProps = BaseIconProps & {
  x?: number;
  y?: number;
  className?: string;
};
