import * as React from "react";
import { useAuth } from "react-use-auth";
import { RouteComponentProps } from "@reach/router";

export const Login = (props: RouteComponentProps) => {
  const { isAuthenticated, login, logout } = useAuth();
  return isAuthenticated() ? (
    <button type="button" onClick={logout}>
      Logout
    </button>
  ) : (
    <button type="button" onClick={login}>
      Login
    </button>
  );
};
